import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { APPLICANT_FIELDS } from '../../constants/origination';
import { createSelectOption, createSelectOptions } from '../Select/utils';
import { getIndustries } from '../../api/industries';

const Industry = ({ value, handleChange, id = '', validate = true }) => {
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    (async () => {
      const industriesArray = await getIndustries();
      setIndustries(industriesArray);
    })();
  }, []);

  return (
    <Select
      prompt="Industry"
      placeholder="Search..."
      name={APPLICANT_FIELDS.INDUSTRY}
      options={createSelectOptions(industries)}
      onChange={handleChange}
      value={createSelectOption(value)}
      isValid={validate ? value && industries.includes(value) : true}
      showValidation={validate && !!value}
      validationText="Please select a valid industry."
      id={id}
    />
  );
};

export default Industry;

Industry.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  validate: PropTypes.bool
};

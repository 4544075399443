import { FOREIGN_KEYS, GENERAL_FIELDS } from 'woop-shared/db/models';
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getUserReports, postUserReports } from '../api/users';
import { error } from '../utils/logger';
import { getQuoteset } from '../api/quoteset';
import { setError } from '../actions/error';
import { getReport as getReportAction, PREVIEW_REPORT } from '../actions/reports';
import {
  FINALIZE_REPORT,
  getReportsSuccess,
  getReportSuccess,
  GET_REPORT,
  GET_REPORTS,
  SAVE_REPORT
} from '../actions/reports';
import { GENERATE_REPORT } from '../actions/reports';
import { setLoading } from '../actions/loading';
import { finalizeReport, getReport, patchReport, previewReport } from '../api/reports';
import { getUser } from '../actions/users';
import { getPolicy } from '../actions/policies';
import { addIdToRoute, ROUTES } from '../router/routes';
import { setQuoteset } from '../actions/quoteset';

function* handleGetReports(action) {
  const id = action.payload;
  yield put(setLoading(true));
  try {
    const data = yield call(getUserReports, id);
    yield put(getReportsSuccess(data));
  } catch (e) {
    yield put(setError({ message: `Couldn't find that user! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchGetReports() {
  yield takeLatest(GET_REPORTS, handleGetReports);
}

function* handleGenerateReport(action) {
  const { id, policyId, policyType, carrierPolicyId } = action.payload;
  yield put(setLoading(true));
  try {
    const { id: reportId } = yield call(postUserReports, {
      id,
      policyId,
      policyType,
      carrierPolicyId
    });
    yield put(push(addIdToRoute(ROUTES.REPORTS_VIEW, reportId)));
  } catch (e) {
    yield put(setError({ message: `Couldn't find that report! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchGenerateReport() {
  yield takeLatest(GENERATE_REPORT, handleGenerateReport);
}

function* handleGetReport(action) {
  const id = action.payload;
  yield put(setLoading(true));
  try {
    const data = yield call(getReport, id);
    yield put(getUser(data[FOREIGN_KEYS.USER]));
    yield put(getPolicy(data[FOREIGN_KEYS.POLICY]));

    if (data?.[FOREIGN_KEYS.B_QUOTESET]) {
      const response = yield call(getQuoteset, data[FOREIGN_KEYS.B_QUOTESET]);
      yield put(setQuoteset(response));
    }

    yield put(getReportSuccess({ ...data }));
  } catch (e) {
    yield put(setError({ message: `Couldn't find that report! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchGetReport() {
  yield takeLatest(GET_REPORT, handleGetReport);
}

function* handleSaveReport(action) {
  const { report } = yield select(state => state);
  yield put(setLoading(true));
  try {
    yield call(patchReport, report[GENERAL_FIELDS.ID], {
      ...report,
      [FOREIGN_KEYS.USER]: report[FOREIGN_KEYS.USER],
      [FOREIGN_KEYS.POLICY]: report[FOREIGN_KEYS.POLICY],
      [FOREIGN_KEYS.B_QUOTESET]: report[FOREIGN_KEYS.B_QUOTESET]
    });
  } catch (e) {
    yield put(setError({ message: `Couldn't patch the report! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchSaveReport() {
  yield takeLatest(SAVE_REPORT, handleSaveReport);
}

function* handleFinalizeReport(action) {
  const { report } = yield select(state => state);
  yield put(setLoading(true));
  try {
    yield call(handleSaveReport);
    yield call(finalizeReport, report[GENERAL_FIELDS.ID]);
    yield put(getReportAction(report[GENERAL_FIELDS.ID]));
  } catch (e) {
    yield put(setError({ message: `Couldn't finalize the report! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchFinalizeReport() {
  yield takeLatest(FINALIZE_REPORT, handleFinalizeReport);
}

function* handlePreviewReport(action) {
  const { report } = yield select(state => state);
  yield put(setLoading(true));
  try {
    yield call(handleSaveReport);
    const reportId = yield call(previewReport, report[GENERAL_FIELDS.ID]);
    // Open portal in a new tab
    window.open(
      `${process.env.PORTAL_URI}/reports/${reportId}?email=${btoa(process.env.OPS_PORTAL_EMAIL)}`
    );
  } catch (e) {
    yield put(setError({ message: `Couldn't preview the report! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchPreviewReport() {
  yield takeLatest(PREVIEW_REPORT, handlePreviewReport);
}

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const ErrorSection = ({ title, items }) => {
  if (!items.length) return null;

  return (
    <div className={styles.section}>
      <div className={styles.heading}>{title}</div>
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

ErrorSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      Text: PropTypes.string
    })
  )
};

export default ErrorSection;

import axios from 'axios';
import { getQuoteEndpoint } from '../utils/api';

const deleteQuote = async id => {
  const URI = `${getQuoteEndpoint()}/${id}`;
  const { data } = await axios.delete(URI);
  return data;
};

export default {
  deleteQuote
};

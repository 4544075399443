import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import PropTypes from 'prop-types';
import styles from './styles.css';
import Card from '../../../../components/Card';
import Fields from './Fields';
import Buttons from './Buttons';
import { getEditableFields } from './utils';

const EditCard = ({ uiData, journeyId, setEdit }) => {
  const [updates, setUpdates] = useState(getEditableFields(uiData));

  return (
    <Card className={styles.wrapper}>
      <h4>
        Edit Application <MdEdit />
      </h4>
      <Fields updates={updates} setUpdates={setUpdates} />
      <Buttons uiData={uiData} journeyId={journeyId} updates={updates} setEdit={setEdit} />
    </Card>
  );
};

EditCard.propTypes = {
  uiData: PropTypes.object,
  journeyId: PropTypes.string,
  setEdit: PropTypes.func
};

export default EditCard;

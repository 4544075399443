import { createAction } from 'redux-actions';

export const GET_ORIGINATION = 'GET_ORIGINATION';
export const getOrigination = createAction(GET_ORIGINATION);

export const GET_ORIGINATION_SUCCESS = 'GET_ORIGINATION_SUCCESS';
export const getOriginationSuccess = createAction(GET_ORIGINATION_SUCCESS);

export const UPDATE_ORIGINATION = 'UPDATE_ORIGINATION';
export const updateOrigination = createAction(UPDATE_ORIGINATION);

export const SAVE_ORIGINATION = 'SAVE_ORIGINATION';
export const saveOrigination = createAction(SAVE_ORIGINATION);

export const POST_ORIGINATION_SUCCESS = 'POST_ORIGINATION_SUCCESS';
export const postOriginationSuccess = createAction(POST_ORIGINATION_SUCCESS);

export const FINALIZE_ORIGINATION = 'FINALIZE_ORIGINATION';
export const finalizeOrigination = createAction(FINALIZE_ORIGINATION);

export const FINALIZE_ORIGINATION_SUCCESS = 'FINALIZE_ORIGINATION_SUCCESS';
export const finalizeOriginationSuccess = createAction(FINALIZE_ORIGINATION_SUCCESS);

export const CLEAR_ORIGINATION = 'CLEAR_ORIGINATION';
export const clearOrigination = createAction(CLEAR_ORIGINATION);

export const CONVERT_TO_QUOTESET = 'CONVERT_TO_QUOTESET';
export const convertToQuoteset = createAction(CONVERT_TO_QUOTESET);

export const VALIDATE_ORIGINATION = 'VALIDATE_ORIGINATION';
export const validateOrigination = createAction(VALIDATE_ORIGINATION);

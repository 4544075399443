import { FINALIZATION_TYPES } from 'woop-shared/origination/enums';

export const FIELD = {
  EMAIL_APPLICANT: 'shouldEmailApplicant',
  EMAIL_ORIGINATOR: 'shouldEmailOriginator',
  TEXT_APPLICANT: 'shouldTextApplicant',
  TEST: 'sendInternal',
  TEST_PHONE: 'internalPhone',
  TEST_EMAIL: 'internalEmail',
  SAVINGS: 'savings',
  CARRIERS: 'carriers',
  TYPE: 'type'
};

export const TYPE_OPTIONS = [
  { value: FINALIZATION_TYPES.JOURNEY_READY, label: 'Journey ready 🚀' },
  { value: FINALIZATION_TYPES.CAN_IMPROVE, label: `Can beat price 👍` },
  { value: FINALIZATION_TYPES.CANT_IMPROVE, label: `Can't beat price 👎` },
  { value: FINALIZATION_TYPES.CONVERT_TO_QUOTE, label: `Convert to quoteset 📄` }
];

export const TEST_COMMUNICATION_OPTIONS = [
  { value: true, label: 'Test Communication' },
  { value: false, label: 'Real Communication' }
];

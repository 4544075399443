const { createAction } = require('redux-actions');

export const GET_PAYMENTS = 'GET_PAYMENTS';
export const getPayments = createAction(GET_PAYMENTS);

export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const getPaymentsSuccess = createAction(GET_PAYMENTS_SUCCESS);

export const FAIL_PAYMENT = 'FAIL_PAYMENT';
export const failPayment = createAction(FAIL_PAYMENT);

export const PROCESS_PAYMENT = 'PROCESS_PAYMENT';
export const processPayment = createAction(PROCESS_PAYMENT);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FOREIGN_KEYS, QUOTESET_FIELDS } from 'woop-shared/db/models';
import { hasVal } from 'woop-shared/utils';
import { formatAgentOptions } from '../../utils';
import styles from '../../CreateQuoteset.css';
import Select from '../../../../components/Select';
import { getAgents } from '../../../../api/agents';
import Card from '../../../../components/Card';
import Textarea from '../../../../components/Textarea';

const AgentInfo = ({ quoteset, hasErrorState, setAgent, setAgentNotes }) => {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    getAgents().then(data => setAgents(formatAgentOptions(data)));
  }, []);

  return (
    <Card className={styles.form}>
      <h3>Agent</h3>

      <div className={styles.cardFields}>
        <Select
          prompt="Agent name"
          options={agents}
          onChange={(_, value) => setAgent(value)}
          value={agents.find(agent => agent.value === quoteset[FOREIGN_KEYS.AGENT])}
          showValidation={hasErrorState}
          isValid={hasVal(quoteset[FOREIGN_KEYS.AGENT])}
        />

        <Textarea
          prompt={'A note from your agent'}
          name={QUOTESET_FIELDS.AGENT_NOTES}
          value={quoteset[QUOTESET_FIELDS.AGENT_NOTES] || ''}
          onChange={val => setAgentNotes(val)}
          showValidation={hasErrorState}
          isValid={hasVal(quoteset[QUOTESET_FIELDS.AGENT_NOTES])}
        />
      </div>
    </Card>
  );
};

export default AgentInfo;

AgentInfo.propTypes = {
  quoteset: PropTypes.object,
  hasErrorState: PropTypes.bool,
  setAgent: PropTypes.func,
  setAgentNotes: PropTypes.func
};

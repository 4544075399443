import { FOREIGN_KEYS } from 'woop-shared/db/models';

export const formatOriginators = originators => {
  return originators.map(o => ({ label: o.name, value: o.externOriginatorId }));
};

export const getOriginatorsByAccountId = (originators, accountId) => {
  if (!accountId || !originators) return [];
  return originators.filter(o => o[FOREIGN_KEYS.PARTNER_ACCOUNT] === accountId);
};

import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorText.css';

const ErrorText = ({ children }) => <div className={styles.wrapper}>{children}</div>;

export default ErrorText;

ErrorText.propTypes = {
  children: PropTypes.node
};

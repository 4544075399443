import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Button.css';
import Loader from '../Loader';

const cx = classNames.bind(styles);

const Button = ({ children, className, onClick, disabled, loading, title }) => {
  return (
    <button
      tabIndex={0}
      onClick={onClick}
      className={cx('wrapper', className)}
      disabled={disabled}
      title={title}
    >
      {!loading && children}
      {loading && <Loader />}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string
};

export default Button;

import { COVERAGES, COVERAGE_NAMES } from 'woop-shared/canopy/coverages';
import { FIELDS } from 'woop-shared/canopy/enums';
import { FOREIGN_KEYS } from 'woop-shared/db/models';
import { VEHICLE_OWNERSHIP_STATUS } from 'woop-shared/origination/enums';
import { IV_REPORT_FIELDS, VEHICLE_FIELDS } from 'woop-shared/origination/fields';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { findIvSet } from '../../api/iv-sets';
import { convertToCents } from '../Policy/utils';

export const getName = uiData =>
  [uiData?.[PROMPT_NAMES.FIRST_NAME], uiData?.[PROMPT_NAMES.LAST_NAME]].filter(Boolean).join(' ');

/**
 * Check that a report has required coverages.
 *
 * @param {object[]} report
 */
export const hasRequiredCoverages = report => {
  const bodilyInjury = report[IV_REPORT_FIELDS.REQUIRED_COVERAGES].find(
    c => c[FIELDS.COVERAGE.NAME] === COVERAGES.BODILY_INJURY_LIABILITY
  );
  const propertyDamage = report[IV_REPORT_FIELDS.REQUIRED_COVERAGES].find(
    c => c[FIELDS.COVERAGE.NAME] === COVERAGES.PROPERTY_DAMAGE_LIABILITY
  );

  const comprehensive = report[IV_REPORT_FIELDS.REQUIRED_COVERAGES]?.find(
    cov => cov.name === COVERAGES.COMPREHENSIVE
  );

  const collision = report[IV_REPORT_FIELDS.REQUIRED_COVERAGES]?.find(
    cov => cov.name === COVERAGES.COLLISION
  );

  return [
    bodilyInjury?.[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS],
    bodilyInjury?.[FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS],
    propertyDamage?.[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS],
    comprehensive?.[FIELDS.COVERAGE.DEDUCTIBLE_CENTS] !== 0,
    collision?.[FIELDS.COVERAGE.DEDUCTIBLE_CENTS] !== 0
  ].every(Boolean);
};

/**
 * Check that a report has current coverages.
 *
 * @param {object[]} report
 */
const hasCurrentCoverages = report => {
  const bodilyInjury = report[IV_REPORT_FIELDS.COVERAGES].find(
    c => c[FIELDS.COVERAGE.NAME] === COVERAGES.BODILY_INJURY_LIABILITY
  );
  const propertyDamage = report[IV_REPORT_FIELDS.COVERAGES].find(
    c => c[FIELDS.COVERAGE.NAME] === COVERAGES.PROPERTY_DAMAGE_LIABILITY
  );

  return [
    bodilyInjury?.[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS],
    bodilyInjury?.[FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS],
    propertyDamage?.[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS]
  ].every(Boolean);
};

export const hasRequiredFields = report => {
  if (!report) return false;

  return [
    hasRequiredCoverages(report),
    hasCurrentCoverages(report),
    report[IV_REPORT_FIELDS.STATE],
    report[IV_REPORT_FIELDS.VEHICLE]?.[VEHICLE_FIELDS.FINANCING_LIEN_HOLDER],
    report[IV_REPORT_FIELDS.VEHICLE]?.[VEHICLE_FIELDS.VEHICLE_OWNERSHIP_STATUS],
    report[IV_REPORT_FIELDS.VIN_ON_POLICY] !== null
  ].every(Boolean);
};

/**
 * Map an ownership type field to the required values for the IV sets call.
 *
 * @param {string} type
 */
const mapOwnershipType = type => {
  switch (type) {
    case VEHICLE_OWNERSHIP_STATUS.LEASED:
      return 'LEASE';
    case VEHICLE_OWNERSHIP_STATUS.LOANED:
      return 'LOAN';
    default:
      return null;
  }
};

/**
 * Try to fetch required coverages from IV set given report state.
 *
 * @param {object} reportState
 */
export const getRequiredCoverages = async reportState => {
  const financeKey = reportState[IV_REPORT_FIELDS.VEHICLE]?.[VEHICLE_FIELDS.FINANCING_LIEN_HOLDER];
  const financeType = mapOwnershipType(
    reportState[IV_REPORT_FIELDS.VEHICLE]?.[VEHICLE_FIELDS.VEHICLE_OWNERSHIP_STATUS]
  );
  const state = reportState[IV_REPORT_FIELDS.STATE];
  const partnerId = reportState[FOREIGN_KEYS.PARTNER_ACCOUNT];
  if (!financeKey || !financeType || !state || !partnerId) return null;

  const set = await findIvSet({
    state,
    financeKey,
    financeType,
    partnerId
  });

  if (!set) return null;

  return [
    {
      [FIELDS.COVERAGE.NAME]: COVERAGES.BODILY_INJURY_LIABILITY,
      [FIELDS.COVERAGE.FRIENDLY_NAME]: COVERAGE_NAMES.BODILY_INJURY_LIABILITY,
      [FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS]: convertToCents(set.biPerPersonMin),
      [FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS]: convertToCents(set.biPerAccidentMin),
      [FIELDS.COVERAGE.DEDUCTIBLE_CENTS]: null
    },
    {
      [FIELDS.COVERAGE.NAME]: COVERAGES.PROPERTY_DAMAGE_LIABILITY,
      [FIELDS.COVERAGE.FRIENDLY_NAME]: COVERAGE_NAMES.PROPERTY_DAMAGE_LIABILITY,
      [FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS]: null,
      [FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS]: convertToCents(set.pdMin),
      [FIELDS.COVERAGE.DEDUCTIBLE_CENTS]: null
    },
    {
      [FIELDS.COVERAGE.NAME]: COVERAGES.COLLISION,
      [FIELDS.COVERAGE.FRIENDLY_NAME]: COVERAGE_NAMES.COLLISION,
      [FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS]: null,
      [FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS]: null,
      [FIELDS.COVERAGE.DEDUCTIBLE_CENTS]: convertToCents(set.collMax)
    },
    {
      [FIELDS.COVERAGE.NAME]: COVERAGES.COMPREHENSIVE,
      [FIELDS.COVERAGE.FRIENDLY_NAME]: COVERAGE_NAMES.COMPREHENSIVE,
      [FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS]: null,
      [FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS]: null,
      [FIELDS.COVERAGE.DEDUCTIBLE_CENTS]: convertToCents(set.compMax)
    }
  ];
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from '../../../../components/Select';
import { getZywaveCarriers } from '../../../../api/quoteset';
import { createSelectOptions } from '../../../../components/Select/utils';

function formatCarriers(carriers) {
  const carrierNames = _.uniq(carriers.map(carrier => carrier.CompanyName));
  return createSelectOptions(carrierNames);
}

function filterCarriers(carrierNames, carrierOptions) {
  return carrierNames.filter(name =>
    Object.values(carrierOptions)
      .map(o => o.value)
      .includes(name)
  );
}

const ZywaveCarrierSelect = ({ onChange, value, name, lob, prompt, state }) => {
  const [carrierOptions, setCarrierOptions] = useState([]);

  useEffect(() => {
    if (state && !carrierOptions.length) {
      getZywaveCarriers({ lob, state }).then(res => {
        const options = formatCarriers(res);
        setCarrierOptions(options);
        onChange(name, filterCarriers(value, options));
      });
    }
  }, [state, lob]);

  return (
    <div>
      <Select
        prompt={prompt || 'Select carriers'}
        name={name}
        options={carrierOptions}
        isMulti={true}
        value={createSelectOptions(value)}
        onChange={onChange}
      />
    </div>
  );
};

export default ZywaveCarrierSelect;

ZywaveCarrierSelect.propTypes = {
  lob: PropTypes.string,
  name: PropTypes.string,
  prompt: PropTypes.string,
  onChange: PropTypes.func,
  state: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

import React, { useState } from 'react';
import { HiPencil, HiX } from 'react-icons/hi';
import PropTypes from 'prop-types';
import { FIELDS, POLICY_TYPES } from 'woop-shared/canopy/enums';
import styles from './styles.css';
import { useModalControls } from '../../../../hooks/modal-controls';
import EditInsuranceHistory from '../EditInsuranceHistory';
import { deleteClaim, deleteIncident } from '../../../../api/policy';
import { formatCents, getPolicyClaims } from '../../utils';
import { getDate, getDriverName, getSubtype, getType } from './utils';
import { INCIDENT_TYPES } from './constants';
import MaybeError from '../../../../components/MaybeError';

const InsuranceHistory = ({ policy, refreshPolicy }) => {
  const [visible, closing, toggleModal] = useModalControls(false);

  const isAuto = policy?.policy_type === POLICY_TYPES.AUTO;
  const items = getPolicyClaims(policy);

  const [selectedItem, setSelectedItem] = useState();
  const toggleModalForItem = item => () => {
    setSelectedItem(item);
    toggleModal();
  };

  const handleDelete = item => () => {
    const isClaim = !!item?.[FIELDS.CLAIM.CLAIM_ID];
    const confirmed = confirm(
      `Delete ${
        isClaim
          ? 'claim'
          : item?.[FIELDS.DRIVING_RECORD.INCIDENT_TYPE] === INCIDENT_TYPES.VIOLATION
          ? 'violation'
          : 'accident'
      }?`
    );
    if (!confirmed) return;

    if (isClaim) {
      deleteClaim({
        pullId: policy[FIELDS.PULL_ID],
        claimId: item[FIELDS.CLAIM.CLAIM_ID]
      }).then(() => refreshPolicy());
    } else {
      deleteIncident({
        pullId: policy[FIELDS.PULL_ID],
        incidentId: item[FIELDS.DRIVING_RECORD.DRIVING_RECORD_ID]
      }).then(() => refreshPolicy());
    }
  };

  return (
    <div>
      <div className={styles.tableHeader}>
        Claims History{' '}
        <a className={styles.edit} onClick={toggleModalForItem()}>
          + Add a new claim, accident, or violation
        </a>
      </div>
      <p className={styles.explanation}>List of all claims, accidents, and violations</p>
      {!!items.length && (
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.columnHeader}>Type</div>
            <div className={styles.columnHeader}>Subtype</div>
            <div className={styles.columnHeader}>Date</div>
            <div className={styles.columnHeader}>Payout</div>
            <div className={styles.columnHeader}>{isAuto ? 'Driver' : ''}</div>
            <div></div>
          </div>
          {items.map(item => {
            return (
              <div
                className={styles.row}
                key={`item-${
                  item?.[FIELDS.CLAIM.CLAIM_ID] || item?.[FIELDS.DRIVING_RECORD.DRIVING_RECORD_ID]
                }`}
              >
                <div>
                  <MaybeError isValid={!!getType(item)} errorText="Missing type">
                    {getType(item)}
                  </MaybeError>
                </div>
                <div>
                  <MaybeError isValid={!!getSubtype(item)} errorText="Missing subtype">
                    {getSubtype(item)}
                  </MaybeError>
                </div>
                <div>{getDate(item)}</div>
                <div>{formatCents(item?.[FIELDS.CLAIM.PAYOUT_CENTS])}</div>
                <div>{!item?.[FIELDS.CLAIM.CLAIM_ID] && getDriverName(policy, item)}</div>
                <div className={styles.options}>
                  <HiPencil onClick={toggleModalForItem(item)} className={styles.edit} />
                  <HiX onClick={handleDelete(item)} className={styles.delete} />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <EditInsuranceHistory
        policy={policy}
        item={selectedItem}
        refreshPolicy={refreshPolicy}
        visible={visible}
        closing={closing}
        toggleModal={toggleModal}
      />
    </div>
  );
};

InsuranceHistory.propTypes = {
  policy: PropTypes.object,
  refreshPolicy: PropTypes.func
};

export default InsuranceHistory;

import { COVERAGE_FIELDS } from 'woop-shared/quotes/enums';
import { CARRIER_FIELDS, QUOTE_FIELDS } from 'woop-shared/db/models';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { CARRIERS } from 'woop-shared/canopy/carriers';

const CANOPY_CARRIERS = Object.values(CARRIERS);

/**
 *
 * @param {string} carrierCode The fkCarrier from a quote/policy. Sometimes a woop carrierCode, sometimes a canopy name.
 * @param {Array<object>} carriers Array of canopy carrier objects.
 * @returns {object} { <logoUrl: string, name: string> }
 */
export const getCarrierDetails = (carrierCode, carriers) => {
  // For our quotes, we should be using our carriers.
  const woopCarrier = carriers.find(c => c.code === carrierCode);
  if (woopCarrier) {
    return {
      logoUrl: woopCarrier[CARRIER_FIELDS.LOGO_URL],
      name: woopCarrier[CARRIER_FIELDS.NAME]
    };
  }

  // Current policies typically use canopy carriers so check the canopy list.
  const canopyCarrier = CANOPY_CARRIERS.find(c => c.name === carrierCode);
  if (canopyCarrier) return { logoUrl: canopyCarrier.logo, name: canopyCarrier.name };

  // If we couldn't find a carrier, see you later.
  return {};
};

export const formatCoverages = quote => {
  const coverages = quote?.[QUOTE_FIELDS.COVERAGES] || [];
  const formatAmount = n => (n ? `${Math.floor(n / 1000)}k` : 0);

  if (quote[QUOTE_FIELDS.COVERAGE_TYPE] === COVERAGE_TYPES.AUTO) {
    const findCov = type => coverages.find(c => c.type === type) ?? {};
    const { amount: biPerPerson } = findCov(COVERAGE_FIELDS.PER_PERSON_LIABILITY);
    const { amount: biPerAccident } = findCov(COVERAGE_FIELDS.PER_ACCIDENT_LIABILITY);
    const { amount: propertyDamage } = findCov(COVERAGE_FIELDS.PROPERTY_DAMAGE_LIABILITY);
    return biPerPerson && biPerAccident && propertyDamage
      ? `${formatAmount(biPerPerson)}/${formatAmount(biPerAccident)}/${formatAmount(
          propertyDamage
        )}`
      : 'No coverages';
  } else if (quote[QUOTE_FIELDS.COVERAGE_TYPE] === COVERAGE_TYPES.UMBRELLA) {
    return '';
  } else {
    const { amount } = coverages.find(c => c.type === COVERAGE_FIELDS.DWELLING) ?? {};
    return amount ? formatAmount(amount) : 'No coverages';
  }
};

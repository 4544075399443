import { PREMIUM_FIELDS as FIELDS, PREMIUM_FIELDS } from 'woop-shared/quotes/enums';
import { PAYMENT_PLAN_OPTIONS } from './constants';

/**
 * Return an option set of unused payment plans
 *
 * @param {Array} existingPremiums
 * @returns {Array} an array of billing cycle options
 */
export const getBillingCycleOptions = existingPremiums => {
  const usedBillingCycles = existingPremiums?.map(p => p[FIELDS.PAYMENT_PLAN]) || [];
  const options = Object.entries(PAYMENT_PLAN_OPTIONS).map(([value, label]) => ({
    value,
    label
  }));
  return options.filter(o => !usedBillingCycles.includes(o.value));
};

export const newPremiumTemplate = (method, plan) => ({
  [FIELDS.PAYMENT_PLAN]: plan,
  [FIELDS.AMOUNT]: null,
  [FIELDS.DOWN_PAYMENT]: null,
  [FIELDS.NUM_OF_PAYMENTS]: null,
  [FIELDS.PAYMENT_METHOD]: method,
  [FIELDS.ID]: new Date().getTime() + Math.random()
});

/**
 * Given an array of premiums, indicate if the amounts and downpayments match
 *
 * @param {object[]} premiums
 * @returns {boolean}
 */
export const hasEqualAmounts = premiums => {
  const amounts = premiums.map(p => p[PREMIUM_FIELDS.AMOUNT]);
  const downPayments = premiums.map(p => p[PREMIUM_FIELDS.DOWN_PAYMENT]);
  const equalAmounts =
    amounts.length && amounts.every(a => a === premiums[0][PREMIUM_FIELDS.AMOUNT]);
  const equalDownPayments = downPayments.every(a => a === premiums[0][PREMIUM_FIELDS.DOWN_PAYMENT]);
  return equalAmounts && equalDownPayments;
};

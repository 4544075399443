import { connect } from 'react-redux';
import Addresses from '.';
import { addAddress, deleteAddress, updateAddress } from '../../actions/addresses.js';

const mapStateToProps = ({ addresses, error }) => {
  return {
    addresses: Object.values(addresses),
    isValid: !error?.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addAddress: address => dispatch(addAddress(address)),
    deleteAddress: id => dispatch(deleteAddress(id)),
    updateAddress: (id, updateObj) => dispatch(updateAddress({ id, updateObj }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { formatAddress } from 'woop-shared/origination/utils';
import { HiPlusSm, HiX } from 'react-icons/hi';
import { Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { ADDRESS_TYPES } from 'woop-shared/origination/enums';
import { ADDRESS_FIELDS, ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import PropTypes from 'prop-types';
import { getAddressOptions } from '../../../../utils/origination';
import Select from '../../../../components/Select';
import styles from './styles.module.css';
import TextField from '../../../../components/TextField';
import { createOptionsFromEnum, createSelectOption } from '../../../../components/Select/utils';
import AddressField from '../../../../components/AddressField';

const addressTypeOptions = createOptionsFromEnum(ADDRESS_TYPES);

const InlineAddressForm = ({
  prompt = 'Address',
  idField,
  selectedId: initialSelectedId,
  quoteApplication,
  onChange,
  onEntityUpdate,
  isValid,
  showValidation,
  tooltip,
  ...rest
}) => {
  const [showCreator, setShowCreator] = useState(false);
  const [showManualFields, setShowManualFields] = useState(false);
  const [newAddress, setNewAddress] = useState({
    [ORIGINATION_FIELDS.ID]: uuidv4()
  });
  const addresses = quoteApplication[ORIGINATION_FIELDS.ADDRESSES] || [];
  const addressOptions = getAddressOptions(quoteApplication);
  const [selectedId, setSelectedId] = useState(initialSelectedId);

  useEffect(() => {
    setSelectedId(initialSelectedId);
  }, [initialSelectedId]);

  const toggleOpen = e => {
    e?.preventDefault();

    if (showCreator) {
      setShowCreator(false);
      setNewAddress({ [ORIGINATION_FIELDS.ID]: uuidv4() });
    } else setShowCreator(true);
  };

  const handleFieldChange = e => {
    const { name, value } = e.target;
    setNewAddress(v => ({ ...v, [name]: value }));
  };

  const handleSelectChange = (name, value) => {
    setNewAddress(v => ({ ...v, [name]: value }));
  };

  const handleSubmit = () => {
    const composite = formatAddress(newAddress);
    if (!composite) return;

    const address = { ...newAddress, [ADDRESS_FIELDS.COMPOSITE]: composite };
    setSelectedId(address?.[ORIGINATION_FIELDS.ID]);
    onChange(idField, address?.[ORIGINATION_FIELDS.ID]);
    if (composite) onEntityUpdate(ORIGINATION_FIELDS.ADDRESSES, address);
    toggleOpen();
  };

  return (
    <>
      <Select
        prompt={
          <span className={styles.prompt}>
            <span>{prompt}</span>
            <a href="#" onClick={toggleOpen}>
              {showCreator ? (
                <>
                  Cancel <HiX />
                </>
              ) : (
                <>
                  Create new <HiPlusSm />
                </>
              )}
            </a>
          </span>
        }
        name={idField}
        options={addressOptions}
        onChange={(_, value) => {
          if (value === selectedId) return;
          setSelectedId(value);
          onChange(
            idField,
            addresses.find(a => a[ORIGINATION_FIELDS.ID] === value)?.[ORIGINATION_FIELDS.ID]
          );
        }}
        value={addressOptions.find(a => a.value === selectedId) || null}
        isValid={isValid}
        showValidation={showValidation}
        tooltip={tooltip}
        {...rest}
      />

      {showCreator && (
        <div className={styles.fields}>
          <h5>Add a new address</h5>
          <div>
            <Select
              name={ADDRESS_FIELDS.ADDRESS_TYPE}
              prompt="Address Type"
              onChange={handleSelectChange}
              value={createSelectOption(newAddress[ADDRESS_FIELDS.ADDRESS_TYPE])}
              options={addressTypeOptions}
              showValidation={!newAddress[ADDRESS_FIELDS.ADDRESS_TYPE]}
              isValid={!!newAddress[ADDRESS_FIELDS.ADDRESS_TYPE]}
            />
          </div>
          <AddressField
            prompt="Address"
            onChange={addr => setNewAddress(current => ({ ...current, ...addr }))}
            value={
              newAddress[ADDRESS_FIELDS.COMPOSITE] &&
              createSelectOption(newAddress[ADDRESS_FIELDS.COMPOSITE])
            }
            showValidation={!newAddress[ADDRESS_FIELDS.COMPOSITE]}
            isValid={!!newAddress[ADDRESS_FIELDS.COMPOSITE]}
          />

          {showManualFields && (
            <>
              <TextField
                name={ADDRESS_FIELDS.STREET_NUMBER}
                label="Street Number"
                onChange={handleFieldChange}
                value={newAddress[ADDRESS_FIELDS.STREET_NUMBER]}
              />
              <TextField
                name={ADDRESS_FIELDS.STREET_NAME}
                label="Street"
                onChange={handleFieldChange}
                value={newAddress[ADDRESS_FIELDS.STREET_NAME]}
              />
              <TextField
                name={ADDRESS_FIELDS.MUNICIPALITY_LEVEL3}
                label="City"
                onChange={handleFieldChange}
                value={newAddress[ADDRESS_FIELDS.MUNICIPALITY_LEVEL3]}
              />
              <TextField
                name={ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE}
                label="State Abbreviation"
                maxLength={2}
                onChange={handleFieldChange}
                value={newAddress[ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE]}
              />
              <TextField
                name={ADDRESS_FIELDS.POSTAL_CODE}
                label="Zip code"
                maxLength={5}
                onChange={handleFieldChange}
                value={newAddress[ADDRESS_FIELDS.POSTAL_CODE]}
              />
            </>
          )}

          <TextField
            name={ADDRESS_FIELDS.UNIT_NUMBER}
            label="Unit Number"
            onChange={handleFieldChange}
            value={newAddress[ADDRESS_FIELDS.UNIT_NUMBER]}
          />

          <div className={styles.footer}>
            <FormGroup>
              <FormControlLabel
                className={styles.switchLabel}
                control={
                  <Switch
                    checked={showManualFields}
                    onChange={e => setShowManualFields(e.target.checked)}
                  />
                }
                label="Show all fields"
              />
            </FormGroup>

            <Button
              variant="text"
              size="small"
              onClick={handleSubmit}
              disabled={
                !newAddress[ADDRESS_FIELDS.COMPOSITE] || !newAddress[ADDRESS_FIELDS.ADDRESS_TYPE]
              }
            >
              Save address
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

InlineAddressForm.propTypes = {
  prompt: PropTypes.string,
  idField: PropTypes.string.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  quoteApplication: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onEntityUpdate: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  showValidation: PropTypes.bool,
  tooltip: PropTypes.string
};

export default InlineAddressForm;

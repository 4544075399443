import React from 'react';
import PropTypes from 'prop-types';
import {
  ALARM_SIGNAL_TYPES,
  STORM_PROTECTIONS,
  PROPERTY_DISCOUNTS
} from 'woop-shared/origination/enums';
import Select from '../../Select';
import {
  createSelectOptions,
  createSelectOption,
  createMultiSelectValue,
  createOptionsFromEnum
} from '../../Select/utils';
import { PROPERTY_FIELDS as FIELDS } from '../../../constants/origination';
import styles from '../../../pages/CreateOrigination/CreateOrigination.css';
import { SUB_SECTIONS } from '../../../constants/table-of-contents';
import { shouldHideSection } from '../../../utils/table-of-contents';
import Card from '../../Card';

const sectionId = SUB_SECTIONS.PROTECTION;

const PropertyProtection = ({ propertyInfo = {}, updateOrigination, coverageTypes }) => {
  const shouldHide = shouldHideSection(sectionId, coverageTypes);
  return (
    !shouldHide && (
      <Card id={sectionId}>
        <h3 className={styles.heading}>Property Protection</h3>
        <div className={styles.fieldWrapper}>
          <Select
            prompt="Fire Alarm"
            options={createOptionsFromEnum(ALARM_SIGNAL_TYPES)}
            name={FIELDS.FIRE_ALARM}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.FIRE_ALARM])}
          />
          <Select
            prompt="Burglar Alarm"
            options={createOptionsFromEnum(ALARM_SIGNAL_TYPES)}
            name={FIELDS.BURGLAR_ALARM}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.BURGLAR_ALARM])}
          />
          <Select
            prompt={'Property discounts (e.g. fire extinguisher, smoke detector)'}
            name={FIELDS.PROPERTY_DISCOUNTS}
            options={createOptionsFromEnum(PROPERTY_DISCOUNTS)}
            onChange={updateOrigination}
            isMulti={true}
            value={createMultiSelectValue(propertyInfo[FIELDS.PROPERTY_DISCOUNTS])}
            isValid={true}
          />
          <Select
            prompt="Storm Protection"
            isMulti={true}
            options={createSelectOptions(STORM_PROTECTIONS)}
            name={FIELDS.STORM_PROTECTION}
            onChange={updateOrigination}
            isValid={true}
            value={createMultiSelectValue(propertyInfo[FIELDS.STORM_PROTECTION])}
          />
        </div>
      </Card>
    )
  );
};

PropertyProtection.propTypes = {
  coverageTypes: PropTypes.array,
  propertyInfo: PropTypes.object,
  updateOrigination: PropTypes.func
};

export default PropertyProtection;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Radio.css';

const cx = classNames.bind(styles);

const Radio = ({
  name,
  value,
  label,
  selected,
  onChange,
  className,
  disabled,
  children,
  title
}) => {
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    // Keep internal state synced with external prop
    if (isSelected !== selected) setIsSelected(selected);
  }, [selected]);

  const handleClick = (selected, value) => {
    setIsSelected(!selected);
    const returnValue = !selected ? value : undefined;
    onChange(name, returnValue);
  };

  return (
    <button
      tabIndex={0}
      key={label}
      onClick={() => handleClick(isSelected, value)}
      disabled={disabled}
      className={cx('radio-button', { isSelected }, className)}
      title={title}
      name={name}
    >
      {label}
      {children}
    </button>
  );
};

export default Radio;

Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string
};

import { DRIVER_TYPE } from 'woop-shared/origination/enums';
import { ADDRESS_FIELDS, APPLICANT_FIELDS } from 'woop-shared/origination/fields';

export function createYearsOptions() {
  const MAX_YEAR = new Date().getFullYear() + 1;
  const MIN_YEAR = 1770;
  const options = [];
  for (let year = MAX_YEAR; year >= MIN_YEAR; year--) {
    options.push({
      value: year,
      label: year.toString()
    });
  }
  return options;
}

export function createAddressOptions(addresses) {
  return Object.values(addresses).map(a => {
    const id = a[ADDRESS_FIELDS.ADDRESS_TAG];
    return { label: `${a[ADDRESS_FIELDS.ADDRESS_TYPE] || ''} (${id})`, value: id };
  });
}

export function createDriverOptions(applicants) {
  return Object.values(applicants)
    .filter(a => a[APPLICANT_FIELDS.APPLICANT_TYPE]?.includes(DRIVER_TYPE))
    .map(a => {
      const id = a[APPLICANT_FIELDS.TAG];
      return { label: `${a[APPLICANT_FIELDS.FIRST_NAME] || ''} (${id})`, value: id };
    });
}

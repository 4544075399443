import { connect } from 'react-redux';
import PaymentsSearch from './';
import { getPayments } from '../../actions/payments';

const mapStateToProps = ({ loading, error }) => {
  return {
    loading,
    error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPayments: quotesetId => dispatch(getPayments(quotesetId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsSearch);

import { connect } from 'react-redux';
import QuotesetSearch from './';
import { clearOrigination, getOrigination } from '../../actions/originations';

const mapStateToProps = ({ error, loading }) => {
  return {
    error,
    loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrigination: id => dispatch(getOrigination({ id })),
    clearOrigination: () => dispatch(clearOrigination())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotesetSearch);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__result--2u9BH{width:100%;box-sizing:border-box;position:relative}.styles__cardHeader--3zKtU .styles__topRow--2n1ew{font-weight:700}.styles__cardHeader--3zKtU div{margin-bottom:10px}.styles__cardHeader--3zKtU .styles__date--1NomN{font-size:14px}.styles__tableHeader--3qgBL{margin:20px 0 10px;font-weight:var(--fw-semibold);color:var(--color-grey-darker)}.styles__tableHeader--3qgBL:first-of-type{margin-top:0}", ""]);
// Exports
exports.locals = {
	"result": "styles__result--2u9BH",
	"cardHeader": "styles__cardHeader--3zKtU",
	"topRow": "styles__topRow--2n1ew",
	"date": "styles__date--1NomN",
	"tableHeader": "styles__tableHeader--3qgBL"
};
module.exports = exports;

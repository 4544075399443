import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RSelect from 'react-select';
import Prompt from '../Prompt';
import { formatPartners } from './utils';

const PartnerSelect = ({
  onChange,
  value,
  name,
  prompt = 'Select Partner',
  partners = [],
  getPartners,
  isValid,
  showValidation,
  id,
  inputId
}) => {
  const [wasBlurred, setWasBlurred] = useState(false);

  useEffect(() => {
    if (partners.length === 0) getPartners();
  }, []);

  const partnerOptions = formatPartners(partners);
  const selected = partnerOptions.find(p => p.value === value) || null;
  return (
    <div>
      {prompt && <Prompt invalid={(showValidation || wasBlurred) && !isValid}>{prompt}</Prompt>}
      <RSelect
        name={name}
        options={partnerOptions}
        value={selected}
        onChange={o => onChange(name, o?.value)}
        onBlur={() => setWasBlurred(true)}
        id={id || `${name}-container`}
        inputId={inputId || `${name}-select`}
      />
    </div>
  );
};

export default PartnerSelect;

PartnerSelect.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  prompt: PropTypes.string,
  partners: PropTypes.array,
  getPartners: PropTypes.func,
  showValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  id: PropTypes.string,
  inputId: PropTypes.string
};

import { takeLatest, put } from 'redux-saga/effects';
import { GET_ORIGINATORS, getOriginatorsSuccess } from '../actions/originators';
import api from '../api/originators';
import { setError } from '../actions/error';

function* handleGetOriginators() {
  try {
    const originators = yield api.getOriginators();
    yield put(getOriginatorsSuccess(originators));
  } catch {
    yield put(setError({ message: 'Could not load originators.' }));
  }
}

export function* originatorsWatchFinalizeQuoteset() {
  yield takeLatest(GET_ORIGINATORS, handleGetOriginators);
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { stripNonDigits } from 'woop-shared/quotes/utils';
import BaseTextField from '../TextField';
import { formatSSN, formatSSNMasked } from './utils';

const SSNField = ({ name, isValid, validationText, showValidation, id, ...rest }) => {
  const { value = '', onChange } = rest;
  const [maskedValue, setMaskedValue] = useState(value);

  useEffect(() => {
    // Keep internal state synced with external prop
    if (maskedValue !== value) setMaskedValue(value);
  }, [value]);

  const handleInput = e => {
    const inputCharLength = e.target.value?.length;
    if (inputCharLength < value.length) {
      onChange(name, '');
      setMaskedValue('');
      return;
    }
    const inputKey = e.target.value[inputCharLength - 1];
    // If key is anything but a digit this will be empty string
    const cleanedInput = stripNonDigits(inputKey);
    const maxLengthReached = inputCharLength === 12;
    if (maxLengthReached || !cleanedInput) {
      return e.preventDefault();
    }
    setMaskedValue(formatSSNMasked(cleanedInput, value));
    onChange(name, formatSSN(cleanedInput, value));
    e.preventDefault();
  };

  return (
    <BaseTextField
      label={'Social Security Number'}
      name={name}
      value={maskedValue}
      onChange={handleInput}
      isValid={isValid}
      validationText={validationText}
      showValidation={showValidation}
      autoComplete="off"
      id={id}
    />
  );
};

export default SSNField;

SSNField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isValid: PropTypes.bool,
  validationText: PropTypes.string,
  showValidation: PropTypes.bool,
  id: PropTypes.string
};

import React from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './styles.css';

const Tooltip = ({ title, children }) => {
  const Wrapper = React.forwardRef(function Wrapper(props, ref) {
    return (
      <span {...props} ref={ref}>
        {children}
      </span>
    );
  });

  return (
    <MuiTooltip title={title}>
      <Wrapper className={styles.wrapper} />
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node
};

export default Tooltip;

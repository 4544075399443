import { useEffect, useState } from 'react';

/**
 * React Hook for neatly creating a timeout and cleaning it up on unmount.
 *
 * @returns {Function}
 */
export function useTimeout() {
  const [timer, setTimer] = useState();
  const setTimerWrapper = (fn, timeout) => {
    setTimer(setTimeout(fn, timeout));
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return setTimerWrapper;
}

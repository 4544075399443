import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { UPDATE_INCIDENT, ADD_INCIDENT, DELETE_INCIDENT } from '../actions/incidents';
import { INCIDENT_FIELDS } from '../constants/origination';
import { GET_ORIGINATION_SUCCESS } from '../actions/originations';
import { formatDateInputValue } from '../components/DateField/utils';

function incidents(state = {}, action) {
  switch (action.type) {
    case ADD_INCIDENT: {
      const { payload } = action;
      return {
        ...state,
        [payload.id]: payload
      };
    }
    case DELETE_INCIDENT: {
      const { payload: id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case UPDATE_INCIDENT: {
      const { id, updateObj } = action.payload;
      const incidentToUpdate = state[id];
      return { ...state, [id]: { ...incidentToUpdate, ...updateObj } };
    }
    case GET_ORIGINATION_SUCCESS: {
      const incidents =
        action.payload?.originationData?.[ORIGINATION_FIELDS.AUTO_INFO]?.[
          ORIGINATION_FIELDS.INCIDENTS
        ];
      const incidentsWithId = {};
      incidents?.forEach((incident, index) => {
        const id = index + 1; // We don't store incident ids so generate them here.
        incidentsWithId[id] = {
          ...incident,
          [INCIDENT_FIELDS.ID]: id,
          [INCIDENT_FIELDS.INCIDENT_DATE]: formatDateInputValue(
            incident?.[INCIDENT_FIELDS.INCIDENT_DATE]
          )
        };
      });
      return incidentsWithId;
    }
    default:
      return state;
  }
}

export default incidents;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Prompt from '../Prompt';
import styles from './styles.css';

const cx = classNames.bind(styles);

const Textarea = ({
  name,
  prompt,
  placeholder,
  className,
  onChange,
  value,
  maxLength,
  showValidation,
  isValid
}) => {
  const [wasBlurred, setWasBlurred] = useState(false);

  return (
    <div className={cx('wrapper', className)}>
      {prompt && <Prompt invalid={(showValidation || wasBlurred) && !isValid}>{prompt}</Prompt>}

      <textarea
        name={name}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
        maxLength={maxLength}
        onBlur={() => setWasBlurred(showValidation)}
      />
    </div>
  );
};

export default Textarea;

Textarea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  prompt: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  showValidation: PropTypes.bool,
  isValid: PropTypes.bool
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ADDRESS_FIELDS as FIELDS } from 'woop-shared/origination/fields';
import { ADDRESS_TYPES } from 'woop-shared/origination/enums';
import TextField from '../TextField';
import LinkText from '../LinkText';
import styles from '../../pages/CreateOrigination/CreateOrigination.css';
import AddressField from '../AddressField';
import Select from '../Select';
import { createSelectOption, createOptionsFromEnum } from '../Select/utils';
import { ORIGINATION_SECTIONS } from '../../constants/table-of-contents';
import Button from '../Button';

const ADDRESS_PREFIX = 'ADDR';

const Addresses = ({ addresses, isValid, addAddress, deleteAddress, updateAddress }) => {
  const [addressPiecesOpen, setAddressPiecesOpen] = useState(false);
  const [nextTag, setNextTag] = useState(addresses.length + 1);

  const toggleAddressPieces = () => setAddressPiecesOpen(!addressPiecesOpen);

  const createAddress = () => {
    const address = { [FIELDS.ADDRESS_TAG]: ADDRESS_PREFIX + nextTag };
    setNextTag(nextTag + 1);
    return address;
  };

  const handleDelete = address => deleteAddress(address[FIELDS.ADDRESS_TAG]);

  const handleAdd = () => addAddress(createAddress());

  const handleFieldChange = address => e => {
    const { name, value } = e.target;
    const id = address[FIELDS.ADDRESS_TAG];
    updateAddress(id, { [name]: value });
  };

  const handleSelectChange = address => (name, value) => {
    const id = address[FIELDS.ADDRESS_TAG];
    updateAddress(id, { [name]: value });
  };

  const handleAddressChange = address => value => {
    const id = address[FIELDS.ADDRESS_TAG];
    updateAddress(id, value);
  };

  useEffect(() => {
    if (addresses.length < 1) addAddress(createAddress());
  }, []);

  return (
    <div id={ORIGINATION_SECTIONS.ADDRESSES}>
      <h3 className={styles.heading}>Addresses</h3>
      {addresses.map((address, index) => {
        const isInsuredAddress = address[FIELDS.ADDRESS_TYPE] === ADDRESS_TYPES.INSURE;
        return (
          <div key={address[FIELDS.ADDRESS_TAG]} className={styles.fieldWrapper}>
            <div className={styles.labelWrapper}>
              <LinkText onClick={() => handleDelete(address)}>Delete</LinkText>
              <div className={styles.label}>
                {address[FIELDS.ADDRESS_TYPE]?.slice(0, 8)} ({address[FIELDS.ADDRESS_TAG]})
              </div>
            </div>
            <Select
              name={FIELDS.ADDRESS_TYPE}
              prompt={'Address Type*'}
              options={createOptionsFromEnum(ADDRESS_TYPES).sort()}
              onChange={handleSelectChange(address)}
              value={createSelectOption(address[FIELDS.ADDRESS_TYPE])}
              isValid={!!address[FIELDS.ADDRESS_TYPE]}
              showValidation={!isValid}
              id={`address-type-${index}`}
              inputId={`address-type-select-${index}`}
            />
            <AddressField
              onChange={handleAddressChange(address)}
              value={address[FIELDS.COMPOSITE] && createSelectOption(address[FIELDS.COMPOSITE])}
              prompt={`Address${isInsuredAddress ? '*' : ''}`}
              showValidation={!isValid}
              isValid={isInsuredAddress ? !!address[FIELDS.COMPOSITE] : true}
              id={`address-${index}`}
              inputId={`address-select-${index}`}
            />
            <TextField
              name={FIELDS.UNIT_NUMBER}
              label={'Unit Number'}
              onChange={handleFieldChange(address)}
              value={address[FIELDS.UNIT_NUMBER]}
            />
            <Button onClick={toggleAddressPieces}>
              {`${addressPiecesOpen ? 'Close' : 'View'} Address Fields`}
            </Button>
            {addressPiecesOpen && (
              <>
                <TextField
                  name={FIELDS.COMPOSITE}
                  label={'Full address (this is what appears in the UI)'}
                  onChange={handleFieldChange(address)}
                  value={address[FIELDS.COMPOSITE]}
                />
                <TextField
                  name={FIELDS.STREET_NUMBER}
                  label={'Street Number'}
                  onChange={handleFieldChange(address)}
                  value={address[FIELDS.STREET_NUMBER]}
                />
                <TextField
                  name={FIELDS.STREET_NAME}
                  label={'Street'}
                  onChange={handleFieldChange(address)}
                  value={address[FIELDS.STREET_NAME]}
                />
                <TextField
                  name={FIELDS.MUNICIPALITY_LEVEL3}
                  label={'City'}
                  onChange={handleFieldChange(address)}
                  value={address[FIELDS.MUNICIPALITY_LEVEL3]}
                />
                <TextField
                  name={FIELDS.MUNICIPALITY_LEVEL1_CODE}
                  label={'State Abbreviation'}
                  maxLength={2}
                  onChange={handleFieldChange(address)}
                  value={address[FIELDS.MUNICIPALITY_LEVEL1_CODE]}
                />
                <TextField
                  name={FIELDS.POSTAL_CODE}
                  label={'Zip code'}
                  maxLength={5}
                  onChange={handleFieldChange(address)}
                  value={address[FIELDS.POSTAL_CODE]}
                />
              </>
            )}
          </div>
        );
      })}
      <LinkText onClick={handleAdd}>Add Address</LinkText>
    </div>
  );
};

Addresses.propTypes = {
  addAddress: PropTypes.func,
  isValid: PropTypes.bool,
  addresses: PropTypes.array,
  updateAddress: PropTypes.func,
  deleteAddress: PropTypes.func
};

export default Addresses;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__table--1e1h3 .styles-module__row--KM21z:not(.styles-module__autoRow--3tvW4){grid-template-columns:3fr 2fr 2fr 2fr 1fr 5%}.styles-module__table--1e1h3 .styles-module__row--KM21z.styles-module__autoRow--3tvW4{grid-template-columns:2fr 1fr 1fr 1fr 1fr 1fr 5%}.styles-module__delete--28CRZ{border:0;-webkit-appearance:none;appearance:none;background:transparent;cursor:pointer;font-weight:var(--fw-default);margin-left:4px;color:var(--color-red-primary);transition:color .3s}.styles-module__delete--28CRZ:hover{color:var(--color-red-darker)}.styles-module__table--1e1h3{font-size:14px;margin-bottom:30px}.styles-module__table--1e1h3 .styles-module__row--KM21z:nth-child(odd){background-color:var(--color-grey-lighter)}.styles-module__table--1e1h3 .styles-module__row--KM21z{display:grid;grid-template-columns:1fr 1fr;padding:10px 15px;align-items:center;grid-gap:5px;gap:5px}.styles-module__table--1e1h3 .styles-module__row--KM21z input{font-size:var(--fs-sm)}.styles-module__table--1e1h3 .styles-module__row--KM21z .styles-module__row--KM21z:last-child{text-align:right}.styles-module__table--1e1h3 .styles-module__row--KM21z .styles-module__columnHeader--qYVJ_{font-weight:700}.styles-module__actions--2Rzmg{display:flex;align-items:center;grid-gap:4px;gap:4px}.styles-module__actions--2Rzmg svg{flex-shrink:0}.styles-module__actions--2Rzmg span{font-size:var(--fs-xs)}", ""]);
// Exports
exports.locals = {
	"table": "styles-module__table--1e1h3",
	"row": "styles-module__row--KM21z",
	"autoRow": "styles-module__autoRow--3tvW4",
	"delete": "styles-module__delete--28CRZ",
	"columnHeader": "styles-module__columnHeader--qYVJ_",
	"actions": "styles-module__actions--2Rzmg"
};
module.exports = exports;

import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Prompt.css';

const cx = classnames.bind(styles);

const Prompt = ({ children, invalid }) => <p className={cx('wrapper', { invalid })}>{children}</p>;

export default Prompt;

Prompt.propTypes = {
  children: PropTypes.node,
  invalid: PropTypes.bool
};

import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { UPDATE_POOL, ADD_POOL, DELETE_POOL } from '../actions/pools';
import { POOL_FIELDS } from '../constants/origination';
import { GET_ORIGINATION_SUCCESS } from '../actions/originations';

function pools(state = {}, action) {
  switch (action.type) {
    case ADD_POOL: {
      const { payload } = action;
      return {
        ...state,
        [payload.id]: payload
      };
    }
    case DELETE_POOL: {
      const { payload: id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case UPDATE_POOL: {
      const { id, updateObj } = action.payload;
      const poolToUpdate = state[id];
      return { ...state, [id]: { ...poolToUpdate, ...updateObj } };
    }
    case GET_ORIGINATION_SUCCESS: {
      const pools =
        action.payload?.originationData?.[ORIGINATION_FIELDS.PROPERTY_INFO]?.[
          ORIGINATION_FIELDS.POOLS
        ];
      const poolsWithId = {};
      pools?.forEach((pool, index) => {
        const id = index + 1; // We don't store pool ids so generate them here.
        poolsWithId[id] = {
          ...pool,
          [POOL_FIELDS.ID]: id
        };
      });
      return poolsWithId;
    }
    default:
      return state;
  }
}

export default pools;

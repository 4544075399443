import _ from 'lodash';
import { getPrimaryAddress, getPrimaryDriver } from 'woop-shared/origination/utils';
import { APPLICANT_TYPES } from 'woop-shared/origination/enums';
import { ORIGINATION_FIELDS, VEHICLE_FIELDS } from 'woop-shared/origination/fields';
import { APPLICANT_FIELDS } from '../../constants';
import {
  FIELD_VALIDATORS,
  REQUIRED_FIELDS_ADDRESS,
  REQUIRED_FIELDS_APPLICANT,
  REQUIRED_FIELDS_AUTO_COVERAGE,
  REQUIRED_FIELDS_AUTO_INCIDENT,
  REQUIRED_FIELDS_CLAIMS,
  REQUIRED_FIELDS_CO_APPLICANT,
  REQUIRED_FIELDS_DRIVER,
  REQUIRED_FIELDS_EXCLUDED_DRIVER,
  REQUIRED_FIELDS_PROPERTY,
  REQUIRED_FIELDS_VEHICLE
} from './constants';
import { error } from '../../../../utils/logger';
import { ENTITY_CONFIG } from '../../constants/config';
import { getPrimaryApplicant } from '../../../../utils/origination';

function validateField(entity, quoteApp, field) {
  const validator = FIELD_VALIDATORS[field];
  const val = entity[field];

  if (validator) return validator(entity, quoteApp);
  if (typeof val === 'object') return !_.isEmpty(val);
  return !!val || val === false;
}

/**
 *
 * @param {string[]} fields An array of form input keys. ex: ['firstName', 'lastName', ...]
 * @returns {object} A validation state object
 */
function createValidator(fields) {
  return (entity, quoteApp) =>
    fields.reduce(
      (validationState, field) =>
        validateField(entity, quoteApp, field)
          ? {
              ...validationState,
              [field]: { isValid: true }
            }
          : {
              ...validationState,
              [field]: { isValid: false },
              allValid: false
            },
      {
        allValid: true
      }
    );
}

export const validateDriver = createValidator(REQUIRED_FIELDS_DRIVER);
const validateExcludedDriver = createValidator(REQUIRED_FIELDS_EXCLUDED_DRIVER);
const _validateVehicle = createValidator(REQUIRED_FIELDS_VEHICLE);
export const validateProperty = createValidator(REQUIRED_FIELDS_PROPERTY);
const _validateApplicant = createValidator(REQUIRED_FIELDS_APPLICANT);
const validateCoApplicant = createValidator(REQUIRED_FIELDS_CO_APPLICANT);
export const validateClaim = createValidator(REQUIRED_FIELDS_CLAIMS);
export const validateAutoIncident = createValidator(REQUIRED_FIELDS_AUTO_INCIDENT);
export const validateAddress = createValidator(REQUIRED_FIELDS_ADDRESS);

export function validateAutoCoverage(autoCoverage, quoteApp) {
  const primaryDriver = getPrimaryDriver(quoteApp);
  const hasUninsuredReason = !!primaryDriver?.[APPLICANT_FIELDS.UNINSURED_REASON];

  const validationState = { allValid: true };
  for (let field of REQUIRED_FIELDS_AUTO_COVERAGE) {
    const isValid = hasUninsuredReason ? true : !!autoCoverage[field];
    if (!isValid) validationState.allValid = false;
    validationState[field] = { isValid };
  }
  return validationState;
}

export function validateApplicant(row, quoteApp) {
  const appType = row[APPLICANT_FIELDS.APPLICANT_TYPE];

  if (!appType?.length) return { allValid: false, [APPLICANT_FIELDS.APPLICANT_TYPE]: false };

  if (appType.includes(APPLICANT_TYPES.DRIVER) && appType.includes(APPLICANT_TYPES.APPLICANT)) {
    const driverValidation = validateDriver(row, quoteApp);
    const applicantValidation = _validateApplicant(row, quoteApp);
    return {
      ...driverValidation,
      ...applicantValidation,
      allValid: driverValidation.allValid && applicantValidation.allValid
    };
  }

  if (appType.includes(APPLICANT_TYPES.DRIVER))
    return row[APPLICANT_FIELDS.EXCLUDED_REASON]
      ? validateExcludedDriver(row, quoteApp)
      : validateDriver(row, quoteApp);

  if (appType.includes(APPLICANT_TYPES.APPLICANT)) return _validateApplicant(row, quoteApp);

  if (appType.includes(APPLICANT_TYPES.CO_APPLICANT)) return validateCoApplicant(row, quoteApp);

  error(`No validation logic defined for applicant type combo`, { appType });
  return { allValid: true };
}

export function validateVehicle(vehicle, quoteApp) {
  if (vehicle[VEHICLE_FIELDS.EXCLUDED]) {
    return { allValid: true };
  }

  return _validateVehicle(vehicle, quoteApp);
}

/**
 * Checks if every entity in the quote application is valid.
 *
 * @param {object} quoteApp A quote application
 * @returns {boolean}
 */
export function validateQuoteApp(quoteApp) {
  return Object.values(ENTITY_CONFIG).every(config => {
    const entities = config.getter({ quoteApp });
    return entities.every(entity => {
      const validationState = config.validator(entity, quoteApp);
      return validationState.allValid;
    });
  });
}

/**
 * We should use the entity config's validator, not this.
 * This function is a workaround until we identify the actual required fields per entity that ops cannot progress without.
 *
 * @param {*} quoteApp
 * @returns {string}
 */
export function validateCriticalFields(quoteApp) {
  let errors = [];
  if (!getPrimaryAddress(quoteApp)) {
    errors.push(
      'This application is missing a primary address. Make sure there is an address with the "INSURE" address type to continue.'
    );
  }
  if (!getPrimaryApplicant(quoteApp[ORIGINATION_FIELDS.APPLICANTS])) {
    errors.push(
      'This application is missing a primary applicant. Make sure there is an applicant with the "APPLICANT" type to continue.'
    );
  }
  return errors.join('\n\n');
}

import React from 'react';
import PropTypes from 'prop-types';
import { FIELD } from '../constants';
import DecimalMoneyField from '../../DecimalMoneyField';
import CarrierSelect from '../../CarrierSelect/container';

const CanImprove = ({ savings, carrierCodes }) => {
  return (
    <>
      <DecimalMoneyField
        label="Annual Savings Amount"
        name={FIELD.SAVINGS}
        value={savings}
        readOnly={true}
      />
      <CarrierSelect
        prompt={''}
        placeholder={'Select Carrier'}
        name={FIELD.CARRIERS}
        value={carrierCodes}
        isMulti={true}
        isValid={true}
      />
    </>
  );
};

CanImprove.propTypes = {
  savings: PropTypes.number,
  carrierCodes: PropTypes.arrayOf(PropTypes.string)
};

export default CanImprove;

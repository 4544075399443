import axios from 'axios';

const deleteBundledQuote = async id => {
  const URI = `${process.env.QUOTESET_URI}bundle/${id}`;
  const { data } = await axios.delete(URI);
  return data;
};

export default {
  deleteBundledQuote
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--16cjI{display:inline-block;border-radius:25px;color:var(--color-yellow-darkest);background-color:var(--color-yellow-lightest);padding:5px 15px;font-weight:var(--fw-medium);font-size:var(--fs-sm);box-shadow:var(--shadow-xs)}.styles__wrapper--16cjI.styles__complete--16VkY{color:var(--color-green-darkest);background-color:var(--color-green-lightest)}.styles__wrapper--16cjI.styles__iconMode--366jv{padding:5px;display:inline-flex;font-size:var(--fs-default);border-radius:9999px}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--16cjI",
	"complete": "styles__complete--16VkY",
	"iconMode": "styles__iconMode--366jv"
};
module.exports = exports;

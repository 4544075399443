import { FOREIGN_KEYS, REPORT_FIELDS } from 'woop-shared/db/models';
import axios from 'axios';

const RESOURCES = {
  PULLS: 'pulls',
  REPORTS: 'reports'
};

export async function getUser(userId) {
  const uri = `${process.env.AUTH_URI}users/${userId}`;
  const { data } = await axios.get(uri);
  return data;
}

export async function getUserPulls(userId) {
  const uri = `${process.env.AUTH_URI}users/${userId}/${RESOURCES.PULLS}`;
  const { data } = await axios.get(uri);
  return data;
}

export async function getUserReports(userId) {
  const uri = `${process.env.AUTH_URI}users/${userId}/${RESOURCES.REPORTS}`;
  const { data } = await axios.get(uri);
  return data;
}

export async function postUserReports({ id, policyId, policyType, carrierPolicyId }) {
  const uri = `${process.env.AUTH_URI}users/${id}/${RESOURCES.REPORTS}`;
  const { data } = await axios.post(uri, {
    [FOREIGN_KEYS.POLICY]: policyId,
    [REPORT_FIELDS.POLICY_TYPE]: policyType,
    [REPORT_FIELDS.CARRIER_POLICY_ID]: carrierPolicyId
  });
  return data;
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from '../Select';
import { getYears, getMakes, getModels } from '../../api/vehicles';
import { createSelectOptions, createSelectOption } from '../Select/utils';
import { VEHICLE_FIELDS } from '../../constants/origination';

const VehiclePicker = ({ year, make, model, onChange, index, showValidation, autoFocus }) => {
  const [years, setYears] = useState([createSelectOption(year)]);
  const [makes, setMakes] = useState([createSelectOption(make)]);
  const [models, setModels] = useState([createSelectOption(model)]);
  const [hasUserInteraction, setHasUserInteraction] = useState();

  const handleOnChange = (name, value) => {
    const payload = { [name]: value };
    onChange(payload);
    setHasUserInteraction(true);
  };

  // Get years on initial render.
  useEffect(() => {
    (async function getYearsWrapper() {
      return await getYears();
    })().then(opts => setYears(createSelectOptions(opts)));
  }, []);

  // Get makes on year change.
  useEffect(() => {
    let cancel = false;
    if (!year && makes?.length === 0) return;

    const getMakesWrapper = async function () {
      const makes = year ? await getMakes(year) : [];
      if (cancel) return;
      setMakes(createSelectOptions(makes) || []);
    };
    getMakesWrapper();

    return () => (cancel = true);
  }, [year]);

  // Get models on year/make change.
  useEffect(() => {
    let cancel = false;
    if ((!year || !make) && models?.length === 0) return;

    const getModelsWrapper = async function () {
      const models = year && make ? await getModels(year, make) : [];
      if (cancel) return;
      setModels(createSelectOptions(models) || []);
    };
    getModelsWrapper();

    return () => (cancel = true);
  }, [year, make]);

  // Clear values if dependent values change.
  useEffect(() => {
    if (hasUserInteraction) {
      if (make && !makes?.find(opt => opt.value === make)) {
        handleOnChange(VEHICLE_FIELDS.MAKE, '');
      }
      if (model && !models?.find(opt => opt.value === model)) {
        handleOnChange(VEHICLE_FIELDS.MODEL, '');
      }
    }
  }, [year, make, model, makes, models]);

  return (
    <>
      <Select
        autoFocus={autoFocus}
        prompt="Vehicle Year"
        value={createSelectOption(year)}
        name={VEHICLE_FIELDS.YEAR}
        onChange={handleOnChange}
        options={years}
        showValidation={showValidation}
        isValid={!!year}
        id={`vehicle-year-${index}`}
        inputId={`vehicle-year-select-${index}`}
      />
      <Select
        prompt="Vehicle Make"
        value={createSelectOption(make)}
        name={VEHICLE_FIELDS.MAKE}
        onChange={handleOnChange}
        options={makes}
        showValidation={showValidation}
        isValid={!_.isEmpty(make)}
        createable={true}
        id={`vehicle-make-${index}`}
        inputId={`vehicle-make-select-${index}`}
      />
      <Select
        prompt="Vehicle Model"
        value={createSelectOption(model)}
        name={VEHICLE_FIELDS.MODEL}
        onChange={handleOnChange}
        options={models}
        showValidation={showValidation}
        isValid={!_.isEmpty(model)}
        createable={true}
        id={`vehicle-model-${index}`}
        inputId={`vehicle-model-select-${index}`}
      />
    </>
  );
};

export default VehiclePicker;

VehiclePicker.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  make: PropTypes.string,
  model: PropTypes.string,
  onChange: PropTypes.func,
  index: PropTypes.number,
  showValidation: PropTypes.bool,
  autoFocus: PropTypes.bool
};

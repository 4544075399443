import { takeEvery, put, select } from 'redux-saga/effects';
import api from '../api/bundled-quotes';
import { setError } from '../actions/error';
import { setLoading } from '../actions/loading';
import {
  DELETE_BUNDLED_QUOTE,
  deleteBundledQuoteSuccess,
  ADD_BUNDLED_QUOTE
} from '../actions/bundled-quotes';
import { saveQuoteset } from '../actions/quoteset';
import { hasUnsavedQuotes } from '../pages/CreateQuoteset/utils';

function* handleDeleteBundledQuote(action) {
  try {
    yield put(setLoading(true));
    const { id, saved } = action.payload;
    if (saved) {
      yield api.deleteBundledQuote(id);
    }
    yield put(deleteBundledQuoteSuccess(id));
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setError({ message: 'Deletion failed!' }));
  }
}

export function* watchDeleteBundledQuote() {
  yield takeEvery(DELETE_BUNDLED_QUOTE, handleDeleteBundledQuote);
}

function* handleAddBundledQuote() {
  const quotes = yield select(state => Object.values(state.quotes));
  if (hasUnsavedQuotes(quotes)) {
    yield put(saveQuoteset());
  }
}

export function* watchAddBundledQuote() {
  yield takeEvery(ADD_BUNDLED_QUOTE, handleAddBundledQuote);
}

import { YEARS_INSURED_OPTIONS } from 'woop-shared/enums';
import { FiCheckCircle } from 'react-icons/fi';
import { FaArrowAltCircleUp as CircleArrow } from 'react-icons/fa';
import {
  FINALIZATION_FIELDS,
  FOREIGN_KEYS,
  GENERAL_FIELDS,
  PERSONAL_LIABILITY_FIELDS,
  QUOTESET_FIELDS,
  QUOTE_FIELDS
} from 'woop-shared/db/models';
import { ADDRESS_FIELDS } from 'woop-shared/origination/fields';
import PropTypes from 'prop-types';
import { HiArrowRight, HiEye } from 'react-icons/hi';
import { CARRIERS, POLICY_TYPES } from 'woop-shared/canopy/enums';
import { REPORT_FIELDS, SCORE_FIELDS } from 'woop-shared/db/models';
import { formatMoneyWithDecimals } from 'woop-shared/quotes/utils';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../components/Card';
import ErrorText from '../../components/ErrorText';
import LinkText from '../../components/LinkText';
import Table from '../../components/Table';
import styles from './ReportsView.css';
import { addIdToRoute, ROUTES } from '../../router/routes';
import Button from '../../components/Button';
import AddressField from '../../components/AddressField';
import { createOptionsFromEnum, createSelectOption } from '../../components/Select/utils';
import UserTable from '../../components/UserTable';
import {
  areFieldsInvalid,
  calculateRepCostDiff,
  findReplacementCost,
  getQuotesetRows,
  getRecommendationText,
  validatePolicyFields,
  validateReportFields
} from './utils';
import { convertPolicy } from '../../api/quoteset';
import { prettifyDatetime } from '../../utils/date';
import Checkbox from '../../components/Checkbox';
import RadioGroup from '../../components/RadioGroup';
import Prompt from '../../components/Prompt';
import MoneyField from '../../components/MoneyField';
import Select from '../../components/Select';
import { YEARS } from '../../constants/origination';
import TextField from '../../components/TextField';

const yearsInsuredOptions = createOptionsFromEnum(YEARS_INSURED_OPTIONS);

const scoreFields = [
  { value: SCORE_FIELDS.ACCIDENTS, label: 'Accidents' },
  { value: SCORE_FIELDS.CLAIMS, label: 'Claims' },
  { value: SCORE_FIELDS.VIOLATIONS, label: 'Violations' }
];

const ternaryOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'Maybe', value: null }
];

const ReportsView = ({
  report,
  user,
  quoteset,
  quotes,
  policy,
  getReport,
  saveReport,
  finalizeReport,
  previewReport,
  setReportField,
  loading,
  error
}) => {
  const { reportId } = useParams();
  const [showErrors, setShowErrors] = useState(false);
  const isAuto = policy?.policy_type === POLICY_TYPES.AUTO;

  const isQuotesetFinalized = !!quoteset?.[QUOTESET_FIELDS.FINALIZATION];
  const hasQuotes = !!Object.values(quotes)?.filter(
    quote => !quote?.[QUOTE_FIELDS.IS_CURRENT_POLICY]
  ).length;

  useEffect(() => {
    getReport(reportId);
  }, []);

  const handleConvertPolicy = async () => {
    const policyId = policy.policy_id;
    const quotesetId = quoteset[GENERAL_FIELDS.ID];
    await convertPolicy({ policyId, quotesetId });
    alert('Success ✅');
  };

  const validatePage = () => {
    const invalid =
      (!isQuotesetFinalized && hasQuotes) ||
      areFieldsInvalid(validateReportFields(report)) ||
      areFieldsInvalid(validatePolicyFields(policy));
    setShowErrors(invalid);
    return !invalid;
  };

  const handleFinalize = () => {
    const valid = validatePage();
    valid && confirm('Are you sure you want to finalize this report?') && finalizeReport();
  };

  const handlePreview = () => {
    const valid = validatePage();
    valid && previewReport();
  };

  const noData = loading || error?.message;

  const compositeAddress = report?.[REPORT_FIELDS.ADDRESS]?.[ADDRESS_FIELDS.COMPOSITE];

  const currReplacementCost = findReplacementCost(policy);
  const recReplacementCost = report?.[REPORT_FIELDS.REPLACEMENT_COST];
  const percentIncrease = calculateRepCostDiff(currReplacementCost, recReplacementCost);
  const recommendation = getRecommendationText(percentIncrease);

  return noData ? (
    <Card>
      <div>{loading ? `Loading...` : `No data to display`}</div>
      {error.message && <ErrorText>{error.message}</ErrorText>}
    </Card>
  ) : (
    <section className={styles.wrapper}>
      <Card className={styles.card}>
        {report?.[REPORT_FIELDS.FINALIZATION]?.[FINALIZATION_FIELDS.DATE] && (
          <section>
            <div className={styles.finalized}>
              <div>
                <FiCheckCircle className={styles.checkIcon} />
              </div>
              <div>
                Report was finalized on{' '}
                <b>
                  {prettifyDatetime(
                    report?.[REPORT_FIELDS.FINALIZATION]?.[FINALIZATION_FIELDS.DATE]
                  )}
                </b>
                .
              </div>
            </div>
          </section>
        )}

        <section>
          <div className={styles.heading}>
            <h3>User information</h3>
          </div>
          <UserTable user={user} />
        </section>

        {policy?.policy_id && (
          <section>
            <div className={styles.heading}>
              <h3>Policy</h3>

              <LinkText href={addIdToRoute(ROUTES.POLICY, report[FOREIGN_KEYS.POLICY])} external>
                View
              </LinkText>
              {quoteset?.[GENERAL_FIELDS.ID] && (
                <LinkText onClick={handleConvertPolicy}>Convert to current policy</LinkText>
              )}
            </div>

            <p className={styles.textSmall}>
              The policy information will show up on the report. Please make sure it is accurate.
            </p>

            <Table
              spaceBetween={true}
              rows={[
                { label: 'Carrier', value: CARRIERS[policy.carrier_name]?.name },
                { label: 'Coverage type', value: policy.policy_type },
                {
                  label: 'Premium',
                  value: policy.total_premium_cents
                    ? formatMoneyWithDecimals(policy.total_premium_cents / 100)
                    : null,
                  required: true
                }
              ]}
            />
          </section>
        )}

        {quoteset?.[GENERAL_FIELDS.ID] && (
          <section>
            <div className={styles.heading}>
              <h3>Quotes</h3>
              <LinkText href={addIdToRoute(ROUTES.QUOTES_VIEW, quoteset[GENERAL_FIELDS.ID])}>
                View
              </LinkText>
            </div>

            <Table
              spaceBetween={true}
              rows={getQuotesetRows(quotes, report[REPORT_FIELDS.POLICY_TYPE], isQuotesetFinalized)}
            />
          </section>
        )}

        {isAuto && (
          <>
            <div className={styles.field}>
              <div className={styles.heading}>
                <h3>Insurance history</h3>
              </div>

              <p className={styles.text}>
                How many years has the insured been continuously insured?
              </p>

              <Select
                label={'Years Insured'}
                options={yearsInsuredOptions}
                onChange={(_, value) =>
                  setReportField({
                    key: REPORT_FIELDS.INSURANCE_HISTORY,
                    value: { [REPORT_FIELDS.YEARS_CONTINUOUSLY_INSURED]: value }
                  })
                }
                value={yearsInsuredOptions.find(
                  o =>
                    o.value ===
                    report?.[REPORT_FIELDS.INSURANCE_HISTORY]?.[
                      REPORT_FIELDS.YEARS_CONTINUOUSLY_INSURED
                    ]
                )}
              />
            </div>

            <div className={styles.field}>
              <div className={styles.heading}>
                <h3>Vehicles</h3>
              </div>

              <div className={styles.checkbox}>
                <Checkbox
                  name={REPORT_FIELDS.RECENTLY_PURCHASED}
                  label="The insured has purchased a vehicle in the last 60 days"
                  onChange={(_, checked) =>
                    setReportField({
                      key: REPORT_FIELDS.VEHICLES,
                      value: { [REPORT_FIELDS.RECENTLY_PURCHASED]: checked }
                    })
                  }
                  value={report?.[REPORT_FIELDS.VEHICLES]?.[REPORT_FIELDS.RECENTLY_PURCHASED]}
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.heading}>
                <h3>Driving History</h3>
              </div>

              <p className={styles.text}>Mark the fields where data could not be found.</p>

              <div className={styles.checkboxes}>
                {scoreFields.map(field => (
                  <div className={styles.checkbox} key={field.value}>
                    <Checkbox
                      name={field.value}
                      label={field.label}
                      onChange={(_, checked) =>
                        setReportField({
                          key: REPORT_FIELDS.SCORE_OVERRIDES,
                          value: { [field.value]: checked }
                        })
                      }
                      value={report?.[REPORT_FIELDS.SCORE_OVERRIDES]?.[field.value]}
                    />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        <div className={styles.field}>
          <div className={styles.heading}>
            <h3>Address</h3>
          </div>

          <AddressField
            prompt={null}
            onChange={value => setReportField({ key: REPORT_FIELDS.ADDRESS, value })}
            value={compositeAddress && createSelectOption(compositeAddress)}
          />
        </div>

        {!isAuto && (
          <>
            <div className={styles.heading}>
              <h3>Recommended Replacement Cost</h3>
            </div>
            <div className={styles.subHeading}>
              Customer will see recommendation for any increase over 2%.
            </div>
            <div className={styles.repCostWrapper}>
              <div className={styles.moneyField}>
                <MoneyField
                  name="currReplacementCost"
                  label={'Current'}
                  value={currReplacementCost}
                />
              </div>
              <div className={styles.moneyField}>
                <MoneyField
                  name="recReplacementCost"
                  placeholder="$"
                  label={'Recommended'}
                  value={recReplacementCost}
                  onChange={(_, value) =>
                    value &&
                    setReportField({
                      key: REPORT_FIELDS.REPLACEMENT_COST,
                      value: parseInt(value)
                    })
                  }
                  readOnly
                />
              </div>
              <div className={styles.recFieldWrapper}>
                <TextField
                  className={styles.recField}
                  name="recommendation"
                  label={'Recommendation'}
                  value={recommendation}
                  noUnderline={true}
                  readOnly
                />
                {recommendation === 'Increase' && <CircleArrow />}
              </div>
              {percentIncrease && <div className={styles.increase}>{`${percentIncrease}%`}</div>}
            </div>

            <div className={styles.heading}>
              <h3>Are any of the following present at the address?</h3>
            </div>
            <div className={styles.radioGroup}>
              <Prompt>Dogs</Prompt>
              <RadioGroup
                name={'dogs'}
                value={
                  ternaryOptions.find(
                    o =>
                      (report?.[REPORT_FIELDS.PERSONAL_LIABILITY]?.[
                        PERSONAL_LIABILITY_FIELDS.DOG
                      ] ?? null) === o.value
                  ).value
                }
                options={ternaryOptions}
                onChange={(_, value) =>
                  setReportField({
                    key: REPORT_FIELDS.PERSONAL_LIABILITY,
                    value: { [PERSONAL_LIABILITY_FIELDS.DOG]: value }
                  })
                }
              />
            </div>

            <div className={styles.radioGroup}>
              <Prompt>Trampoline</Prompt>
              <RadioGroup
                name={'trampoline'}
                value={
                  ternaryOptions.find(
                    o =>
                      (report?.[REPORT_FIELDS.PERSONAL_LIABILITY]?.[
                        PERSONAL_LIABILITY_FIELDS.TRAMPOLINE
                      ] ?? null) === o.value
                  ).value
                }
                options={ternaryOptions}
                onChange={(_, value) =>
                  setReportField({
                    key: REPORT_FIELDS.PERSONAL_LIABILITY,
                    value: { [PERSONAL_LIABILITY_FIELDS.TRAMPOLINE]: value }
                  })
                }
              />
            </div>

            <div className={styles.radioGroup}>
              <Prompt>Pool</Prompt>
              <RadioGroup
                name={'pool'}
                value={
                  ternaryOptions.find(
                    o =>
                      (report?.[REPORT_FIELDS.PERSONAL_LIABILITY]?.[
                        PERSONAL_LIABILITY_FIELDS.POOL
                      ] ?? null) === o.value
                  ).value
                }
                options={ternaryOptions}
                onChange={(_, value) =>
                  setReportField({
                    key: REPORT_FIELDS.PERSONAL_LIABILITY,
                    value: { [PERSONAL_LIABILITY_FIELDS.POOL]: value }
                  })
                }
              />
            </div>

            <div className={styles.heading}>
              <h3>Year Roof Built or Replaced</h3>
            </div>
            <div className={styles.field}>
              <Select
                name={'yearRoofReplaced'}
                options={YEARS}
                onChange={(_, value) =>
                  setReportField({
                    key: REPORT_FIELDS.YEAR_ROOF_REPLACED,
                    value
                  })
                }
                value={createSelectOption(report?.[REPORT_FIELDS.YEAR_ROOF_REPLACED] ?? null)}
              />
            </div>
          </>
        )}

        {report?.[REPORT_FIELDS.NOTES] && (
          <div className={styles.field}>
            <div className={styles.heading}>
              <h3>Agent notes</h3>
            </div>
            <div>{report?.[REPORT_FIELDS.NOTES]}</div>
          </div>
        )}

        {showErrors && (
          <div className={styles.field}>
            {!isQuotesetFinalized && hasQuotes && (
              <ul className={styles.errors}>
                <div className={styles.errorHeading}>Quoteset errors</div>
                <li>Quoteset is not finalized</li>
              </ul>
            )}

            {areFieldsInvalid(validateReportFields(report)) && (
              <ul className={styles.errors}>
                <div className={styles.errorHeading}>Report errors</div>
                {validateReportFields(report).map(([label, value]) => {
                  if (value) return;
                  return <li key={label}>{label} field is required</li>;
                })}
              </ul>
            )}

            {areFieldsInvalid(validatePolicyFields(policy)) && (
              <>
                <ul className={styles.errors}>
                  <div className={styles.errorHeading}>Policy errors</div>
                  {validatePolicyFields(policy).map(([label, value]) => {
                    if (value) return;
                    return <li key={label}>{label} field is required</li>;
                  })}
                </ul>
                <LinkText href={addIdToRoute(ROUTES.POLICY, report[FOREIGN_KEYS.POLICY])}>
                  Make corrections to policy
                </LinkText>
              </>
            )}
          </div>
        )}

        <div className={styles.buttons}>
          <Button className={styles.buttonOutline} onClick={saveReport}>
            Save
          </Button>
          <Button className={styles.buttonOutline} onClick={handlePreview}>
            Preview <HiEye />
          </Button>
          <Button className={styles.buttonPrimary} onClick={handleFinalize}>
            Finalize <HiArrowRight />
          </Button>
        </div>
      </Card>
    </section>
  );
};

ReportsView.propTypes = {
  error: PropTypes.object,
  finalizeReport: PropTypes.func,
  getReport: PropTypes.func,
  loading: PropTypes.bool,
  policy: PropTypes.object,
  previewReport: PropTypes.func,
  quotes: PropTypes.object,
  quoteset: PropTypes.object,
  report: PropTypes.object,
  saveReport: PropTypes.func,
  setReportField: PropTypes.func,
  user: PropTypes.object
};

export default ReportsView;

import React from 'react';
import PropTypes from 'prop-types';
import {
  DWELLING_TYPES,
  HEATING_SOURCES,
  FOUNDATION_TYPES,
  CONSTRUCTION_TYPES,
  ROOF_TYPES,
  ROOF_SHAPES,
  PROPERTY_UPDATE_TYPE_OPTIONS,
  ADDRESS_TYPES
} from 'woop-shared/origination/enums';
import Select from '../../Select';
import { createSelectOption, createOptionsFromEnum } from '../../Select/utils';
import { PROPERTY_FIELDS as FIELDS, YEARS, YES_NO_OPTIONS } from '../../../constants/origination';
import styles from '../../../pages/CreateOrigination/CreateOrigination.css';
import NumberField from '../../NumberField';
import RadioGroup from '../../RadioGroup';
import { SUB_SECTIONS } from '../../../constants/table-of-contents';
import { shouldHideSection } from '../../../utils/table-of-contents';
import Card from '../../Card';

const sectionId = SUB_SECTIONS.BASIC;

const PropertyBasic = ({ propertyInfo = {}, updateOrigination, addressTags, coverageTypes }) => {
  const defaultAddress = addressTags.find(a => a.label.includes(ADDRESS_TYPES.INSURE));
  const shouldHide = shouldHideSection(sectionId, coverageTypes);
  return (
    !shouldHide && (
      <Card id={sectionId}>
        <h3 className={styles.heading}>Property Basic</h3>
        <div className={styles.fieldWrapper}>
          <Select
            prompt={'Insured Address'}
            name={FIELDS.INSURED_PROPERTY_ADDRESS}
            options={addressTags}
            onChange={updateOrigination}
            isValid={true}
            value={
              addressTags.find(a => a.value === propertyInfo[FIELDS.INSURED_PROPERTY_ADDRESS]) ||
              defaultAddress
            }
            id={'insured-address'}
            inputId={'insured-address-select'}
          />
          <Select
            prompt="Year Built"
            name={FIELDS.YEAR_BUILT}
            options={YEARS}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.YEAR_BUILT])}
          />
          <NumberField
            label="Square Footage"
            name={FIELDS.SQUARE_FOOTAGE}
            value={propertyInfo[FIELDS.SQUARE_FOOTAGE]}
            onChange={updateOrigination}
          />
          <Select
            prompt="Dwelling Type"
            options={createOptionsFromEnum(DWELLING_TYPES)}
            name={FIELDS.DWELLING_TYPE}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.DWELLING_TYPE])}
          />
          <NumberField
            label="Number of Stories"
            name={FIELDS.NUMBER_OF_STORIES}
            value={propertyInfo[FIELDS.NUMBER_OF_STORIES]}
            onChange={updateOrigination}
          />
          <Select
            prompt="Foundation Type"
            options={createOptionsFromEnum(FOUNDATION_TYPES)}
            name={FIELDS.FOUNDATION}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.FOUNDATION])}
          />
          <Select
            prompt="Construction Type"
            options={createOptionsFromEnum(CONSTRUCTION_TYPES)}
            name={FIELDS.CONSTRUCTION_TYPE}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.CONSTRUCTION_TYPE])}
          />
          <Select
            prompt="Roof Type"
            options={createOptionsFromEnum(ROOF_TYPES)}
            name={FIELDS.ROOF_TYPE}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.ROOF_TYPE])}
          />
          <Select
            prompt="Roof Shape"
            options={createOptionsFromEnum(ROOF_SHAPES)}
            name={FIELDS.ROOF_SHAPE}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.ROOF_SHAPE])}
          />
          <Select
            prompt="Year Roof Updated"
            name={FIELDS.ROOF_YEAR}
            options={YEARS}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.ROOF_YEAR])}
          />
          <RadioGroup
            prompt="Roof Update Type"
            name={FIELDS.ROOF_UPDATE_TYPE}
            value={propertyInfo[FIELDS.ROOF_UPDATE_TYPE]}
            onChange={updateOrigination}
            options={PROPERTY_UPDATE_TYPE_OPTIONS}
          />
          <Select
            prompt="Year Plumbing Updated"
            name={FIELDS.YEAR_PLUMBING_UPDATED}
            options={YEARS}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.YEAR_PLUMBING_UPDATED])}
          />
          <RadioGroup
            prompt="Plumbing Update Type"
            name={FIELDS.PLUMBING_UPDATE_TYPE}
            value={propertyInfo[FIELDS.PLUMBING_UPDATE_TYPE]}
            onChange={updateOrigination}
            options={PROPERTY_UPDATE_TYPE_OPTIONS}
          />
          <Select
            prompt="Year Electric Updated"
            name={FIELDS.YEAR_ELECTRIC_UPDATED}
            options={YEARS}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.YEAR_ELECTRIC_UPDATED])}
          />
          <RadioGroup
            prompt="Electric Update Type"
            name={FIELDS.ELECTRIC_UPDATE_TYPE}
            value={propertyInfo[FIELDS.ELECTRIC_UPDATE_TYPE]}
            onChange={updateOrigination}
            options={PROPERTY_UPDATE_TYPE_OPTIONS}
          />
          <Select
            prompt="Primary Heating Type"
            options={createOptionsFromEnum(HEATING_SOURCES)}
            name={FIELDS.PRIMARY_HEATING_SOURCE}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.PRIMARY_HEATING_SOURCE])}
          />
          <Select
            prompt="Year Heating Updated"
            name={FIELDS.YEAR_HEATING_UPDATED}
            options={YEARS}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.YEAR_HEATING_UPDATED])}
          />
          <RadioGroup
            prompt="Heating Update Type"
            name={FIELDS.HEATING_UPDATE_TYPE}
            value={propertyInfo[FIELDS.HEATING_UPDATE_TYPE]}
            onChange={updateOrigination}
            options={PROPERTY_UPDATE_TYPE_OPTIONS}
          />
          <NumberField
            label="Number of fuel tanks on premises"
            name={FIELDS.FUEL_TANKS_ON_PREMISES}
            value={propertyInfo[FIELDS.FUEL_TANKS_ON_PREMISES]}
            onChange={updateOrigination}
          />
          <RadioGroup
            prompt="Has underground fuel tank"
            name={FIELDS.HAS_UNDERGROUND_FUEL_TANK}
            value={propertyInfo[FIELDS.HAS_UNDERGROUND_FUEL_TANK]}
            onChange={updateOrigination}
            options={YES_NO_OPTIONS}
          />
          <RadioGroup
            prompt="Primary fuel tank is above ground"
            name={FIELDS.PRIMARY_TANK_ABOVE_GROUND}
            value={propertyInfo[FIELDS.PRIMARY_TANK_ABOVE_GROUND]}
            onChange={updateOrigination}
            options={YES_NO_OPTIONS}
          />
        </div>
      </Card>
    )
  );
};

PropertyBasic.propTypes = {
  addressTags: PropTypes.array,
  coverageTypes: PropTypes.array,
  propertyInfo: PropTypes.object,
  updateOrigination: PropTypes.func
};

export default PropertyBasic;

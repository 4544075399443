import { createAction } from 'redux-actions';

export const GENERATE_REPORT = 'GENERATE_REPORT';
export const generateReport = createAction(GENERATE_REPORT);

export const GET_REPORT = 'GET_REPORT';
export const getReport = createAction(GET_REPORT);

export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const getReportSuccess = createAction(GET_REPORT_SUCCESS);

export const GET_REPORTS = 'GET_REPORTS';
export const getReports = createAction(GET_REPORTS);

export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const getReportsSuccess = createAction(GET_REPORTS_SUCCESS);

export const SET_REPORT_FIELD = 'SET_REPORT_FIELD';
export const setReportField = createAction(SET_REPORT_FIELD);

export const SAVE_REPORT = 'SAVE_REPORT';
export const saveReport = createAction(SAVE_REPORT);

export const FINALIZE_REPORT = 'FINALIZE_REPORT';
export const finalizeReport = createAction(FINALIZE_REPORT);

export const PREVIEW_REPORT = 'PREVIEW_REPORT';
export const previewReport = createAction(PREVIEW_REPORT);

import { connect } from 'react-redux';
import { createAddressOptions } from '../../../utils/options';
import PropertyBasic from './';

const mapStateToProps = ({ addresses }) => {
  return {
    addressTags: createAddressOptions(addresses)
  };
};

export default connect(mapStateToProps)(PropertyBasic);

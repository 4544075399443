import { GET_REPORTS_SUCCESS } from '../actions/reports';

function reports(state = [], action) {
  switch (action.type) {
    case GET_REPORTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export default reports;

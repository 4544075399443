import React, { useState } from 'react';
import { US_STATES } from 'woop-shared/data/states';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { AiFillMinusCircle } from 'react-icons/ai';
import { MORTGAGE_FIELDS } from 'woop-shared/db/models';
import Select from '../../../../components/Select';
import { createSelectOption } from '../../../../components/Select/utils';
import { setMortgageField } from '../../../../actions/mortgage';
import styles from './styles.css';
import Card from '../../../../components/Card';
import TextField from '../../../../components/TextField';

const cx = classnames.bind(styles);

const MortgageInfo = ({ className }) => {
  const [collapsed, setCollapsed] = useState(true);
  const mortgage = useSelector(state => state.mortgage);
  const dispatch = useDispatch();

  const handleUpdate = (name, value) => {
    dispatch(setMortgageField({ name, value }));
  };

  const handleCollapse = e => {
    e.stopPropagation();
    setCollapsed(true);
  };

  return (
    <Card className={cx(className, 'wrapper', { collapsed })} onClick={() => setCollapsed(false)}>
      <div className={styles.buttons}>
        <AiFillMinusCircle onClick={e => handleCollapse(e)} />
      </div>
      <h3>Mortgage Information</h3>
      <TextField
        label="Company"
        name={MORTGAGE_FIELDS.COMPANY_NAME}
        value={mortgage?.[MORTGAGE_FIELDS.COMPANY_NAME]}
        onChange={e => handleUpdate(MORTGAGE_FIELDS.COMPANY_NAME, e.target.value)}
      />
      <TextField
        label="City"
        name={MORTGAGE_FIELDS.COMPANY_CITY}
        value={mortgage?.[MORTGAGE_FIELDS.COMPANY_CITY]}
        onChange={e => handleUpdate(MORTGAGE_FIELDS.COMPANY_CITY, e.target.value)}
      />
      <Select
        placeholder={'State'}
        name={MORTGAGE_FIELDS.COMPANY_STATE}
        options={US_STATES}
        onChange={handleUpdate}
        value={createSelectOption(mortgage[MORTGAGE_FIELDS.COMPANY_STATE])}
      />
      <TextField
        label="Loan Number"
        name={MORTGAGE_FIELDS.LOAN_NUMBER}
        value={mortgage?.[MORTGAGE_FIELDS.LOAN_NUMBER]}
        onChange={e => handleUpdate(MORTGAGE_FIELDS.LOAN_NUMBER, e.target.value)}
      />
    </Card>
  );
};

export default MortgageInfo;

MortgageInfo.propTypes = {
  className: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Bang from '../assets/icons/exclamation.svg';
import styles from '../css/FloatingLabel.css';

const cx = classNames.bind(styles);

/* Wraps an input component with a floating label */
const WithFloatingLabel = ({
  children,
  hasFocus,
  hasValue,
  label,
  invalid,
  validationText,
  noUnderline
}) => {
  const shouldFloatLabel = hasFocus || hasValue;
  return (
    <div className={cx('wrapper', { noLabel: !label })}>
      {children}
      {invalid && (
        <div className={cx('invalid-icon')}>
          <Bang />
        </div>
      )}
      <label className={cx('floating-label', { floating: shouldFloatLabel })}>{label}</label>
      {!noUnderline && (
        <span className={cx('focus-border', { focus: shouldFloatLabel, invalid })}></span>
      )}
      {invalid && <span className={cx('validation-text')}>{validationText}</span>}
    </div>
  );
};

export default WithFloatingLabel;

WithFloatingLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  hasFocus: PropTypes.bool,
  hasValue: PropTypes.bool,
  invalid: PropTypes.bool,
  validationText: PropTypes.string,
  noUnderline: PropTypes.bool
};

import { createAction } from 'redux-actions';

export const SEARCH_APPLICANTS = 'SEARCH_APPLICANTS';
export const searchApplicants = createAction(SEARCH_APPLICANTS);

export const APPLICANTS_FOUND = 'APPLICANTS_FOUND';
export const applicantsFound = createAction(APPLICANTS_FOUND);

export const GET_JOURNEY = 'GET_JOURNEY';
export const getJourney = createAction(GET_JOURNEY);

export const GET_JOURNEY_SUCCESS = 'GET_JOURNEY_SUCCESS';
export const getJourneySuccess = createAction(GET_JOURNEY_SUCCESS);

export const SAVE_JOURNEY = 'SAVE_JOURNEY';
export const saveJourney = createAction(SAVE_JOURNEY);

export const MAP_CANOPY = 'MAP_CANOPY';
export const mapCanopy = createAction(MAP_CANOPY);

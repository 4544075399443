import axios from 'axios';

const PUBLIC_API_KEY = 'SkzAiK';
const TRACK_ENDPOINT = 'https://a.klaviyo.com/api/track';

const KLAVIYO_EVENTS = {
  MANUAL_QUOTES_READY: 'Ops Triggered Quotes Ready'
};

/**
 * Track an event to Klaviyo
 *
 * @param {string} event An event name
 * @param {object} customerProps { "$email": "abraham.lincoln@klaviyo.com", "$phone_number": "abraham.lincoln@klaviyo.com" }
 * @param {object} eventProps { "item_name": "Boots", "$value": 100 }
 * @returns {object} A response object
 */
const track = (event, customerProps, eventProps) => {
  const data = {
    token: PUBLIC_API_KEY,
    event,
    customer_properties: customerProps,
    properties: eventProps
  };
  return axios.post(TRACK_ENDPOINT, new URLSearchParams({ data: JSON.stringify(data) }));
};

export const triggerQuotesReady = ({ email, isPortalUser, quotesetId }) =>
  track(KLAVIYO_EVENTS.MANUAL_QUOTES_READY, { $email: email }, { isPortalUser, quotesetId });

import PropTypes from 'prop-types';
import React from 'react';
import { ORIGINATION_FIELDS, AUTO_FIELDS as FIELDS } from 'woop-shared/origination/fields';
import Select from '../Select';
import { createSelectOptions, createSelectOption } from '../Select/utils';
import styles from '../../pages/CreateOrigination/CreateOrigination.css';
import NumberField from '../NumberField';
import { AUTO_OTHER_CARRIERS } from '../../constants/carriers';
import DateField from '../DateField';
import RadioGroup from '../RadioGroup';
import { YES_NO_OPTIONS } from '../../constants/origination';
import { ORIGINATION_SECTIONS } from '../../constants/table-of-contents';

const AutoInfo = ({ autoInfo = {}, updateOrigination }) => {
  const handleFieldChange = (name, value) => {
    const updates = { ...autoInfo, [name]: value };
    updateOrigination(ORIGINATION_FIELDS.AUTO_INFO, updates);
  };

  return (
    <div id={ORIGINATION_SECTIONS.AUTO_INFO}>
      <h3 className={styles.heading}>Auto Info</h3>
      <div className={styles.fieldWrapper}>
        <RadioGroup
          prompt="Applicant has resided at primary address for at least 3 years"
          name={FIELDS.RESIDING_AT_PRIMARY_ADDRESS_FOR_MIN_TIME}
          value={autoInfo[FIELDS.RESIDING_AT_PRIMARY_ADDRESS_FOR_MIN_TIME]}
          onChange={handleFieldChange}
          options={YES_NO_OPTIONS}
        />
        <Select
          prompt={'Current Auto Insurer'}
          name={FIELDS.CURRENT_INSURER}
          createable={true}
          options={createSelectOptions(AUTO_OTHER_CARRIERS)}
          onChange={handleFieldChange}
          isValid={true}
          value={createSelectOption(autoInfo[FIELDS.CURRENT_INSURER])}
        />
        <NumberField
          label="Months with current insurer? (Minimum)"
          name={FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER}
          value={autoInfo[FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER]}
          onChange={handleFieldChange}
        />
        <DateField
          name={FIELDS.CURRENT_POLICY_EXPIRATION}
          value={autoInfo[FIELDS.CURRENT_POLICY_EXPIRATION]}
          prompt="Current Auto Policy Expiration"
          onChange={e => handleFieldChange(e.target.name, e.target.value)}
        />
        <RadioGroup
          prompt="Applicant has had a policy cancelled"
          name={FIELDS.POLICY_CANCELED}
          value={autoInfo[FIELDS.POLICY_CANCELED]}
          onChange={handleFieldChange}
          options={YES_NO_OPTIONS}
        />
        <RadioGroup
          prompt="Applicant has filed a recent claim"
          name={FIELDS.RECENT_CLAIM}
          value={autoInfo[FIELDS.RECENT_CLAIM]}
          onChange={handleFieldChange}
          options={YES_NO_OPTIONS}
        />
      </div>
    </div>
  );
};

AutoInfo.propTypes = {
  autoInfo: PropTypes.object,
  updateOrigination: PropTypes.func
};

export default AutoInfo;

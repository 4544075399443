import { createAction } from 'redux-actions';

export const ADD_VEHICLE = 'ADD_VEHICLE';
export const addVehicle = createAction(ADD_VEHICLE);

export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const deleteVehicle = createAction(DELETE_VEHICLE);

export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const updateVehicle = createAction(UPDATE_VEHICLE);

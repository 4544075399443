// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__nav--19HcN{text-align:right}.styles-module__nav--19HcN a{display:inline-flex}", ""]);
// Exports
exports.locals = {
	"nav": "styles-module__nav--19HcN"
};
module.exports = exports;

import _ from 'lodash';
import { QUOTE_REQUEST_FIELDS } from 'woop-shared/db/models';
import { SET_QUOTE_REQUEST, UPDATE_REQUESTS } from '../actions/quote-request';
import { compareRequests } from '../pages/QuoteRequest/utils';

function quoteRequest(state = {}, action) {
  switch (action.type) {
    case SET_QUOTE_REQUEST: {
      const { payload } = action;
      payload?.requests?.sort(compareRequests);
      return payload;
    }
    case UPDATE_REQUESTS: {
      const { payload } = action;

      // Merge requests
      const requests = _.unionBy(payload.requests, state[QUOTE_REQUEST_FIELDS.REQUESTS], 'id');

      // Remove bundles with skipped requests
      const bundles = state[QUOTE_REQUEST_FIELDS.BUNDLED_REQUESTS] || [];
      const bundledRequests = bundles.filter(
        bundle =>
          !bundle.some(id => requests.some(r => r[QUOTE_REQUEST_FIELDS.SKIP] && r.id === id))
      );

      return { ...state, requests, bundledRequests };
    }
    default:
      return state;
  }
}

export default quoteRequest;

import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DataGrid } from '@mui/x-data-grid';
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlinePencilAlt,
  HiOutlineXCircle
} from 'react-icons/hi';
import styles from './styles.module.css';
import BadgeButton from '../BadgeButton';
import Tooltip from '../Tooltip';

const Table2 = ({ columns, rows, onEdit, onDelete, ...props }) => {
  const updatedColumns = columns.map(column => ({
    ...column,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    headerClassName: styles.header,
    cellClassName: styles.cell
  }));

  updatedColumns.push({
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    sortable: false,
    resizable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    headerClassName: styles.header,
    cellClassName: styles.cell,
    renderCell: params => (
      <div className={styles.actions}>
        {onEdit && (
          <BadgeButton
            onClick={() => {
              onEdit(params.row);
            }}
          >
            <HiOutlinePencilAlt />
          </BadgeButton>
        )}
        {onDelete && (
          <BadgeButton
            color="red"
            onClick={() => {
              onDelete(params.row.id);
            }}
          >
            <HiOutlineXCircle />
          </BadgeButton>
        )}
        {
          <Tooltip
            title={
              <div>
                {params.row.valid
                  ? 'All required fields are provided :D'
                  : params.row?.errors?.map(e => <div key={e}>{e}</div>)}
              </div>
            }
          >
            <BadgeButton color={params.row.valid ? 'green' : 'yellow'}>
              {params.row.valid ? <HiOutlineCheckCircle /> : <HiOutlineExclamationCircle />}
            </BadgeButton>
          </Tooltip>
        }
      </div>
    )
  });

  return (
    <DataGrid
      rows={rows}
      getRowId={row => row.id || uuidv4()}
      disableColumnFilter={true}
      disableColumnSelector={true}
      columns={updatedColumns}
      getRowClassName={() => styles.row}
      componentsProps={{
        header: { className: styles.header }
      }}
      hideFooter={true}
      rowSelection={false}
      {...props}
    />
  );
};

Table2.propTypes = {
  columns: PropTypes.array,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  rows: PropTypes.array
};

export default Table2;

import { takeEvery, put, select, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { FOREIGN_KEYS, GENERAL_FIELDS } from 'woop-shared/db/models';
import { setJourneySummary } from '../actions/journey-summary';
import { ROUTES, addIdToRoute } from '../router/routes';
import {
  finalizeQuoteset,
  getQuoteset,
  mapVertafore,
  patchQuoteset,
  postQuoteset,
  syncQuoteset,
  qaQuoteset,
  upload
} from '../api/quoteset';
import { setError } from '../actions/error';
import { setLoading } from '../actions/loading';
import {
  FINALIZE_QUOTESET,
  GET_QUOTESET,
  setQuoteset,
  finalizeQuotesetSuccess,
  SAVE_QUOTESET,
  qaQuotesetSuccess,
  QA_QUOTESET,
  UPLOAD_QUOTES,
  SYNC_QUOTESET,
  MAP_VERTAFORE,
  mapVertaforeSuccess,
  POST_QUOTESET
} from '../actions/quoteset';
import { removeCreateDate } from '../pages/CreateQuoteset/utils';
import { addToRecentQuotes } from '../utils/local-storage';
import { error } from '../utils/logger';

function* handleGetQuoteset(action) {
  try {
    yield put(setLoading(true));
    const { id } = action.payload;
    const response = yield call(getQuoteset, id);
    yield put(setQuoteset(response));
    yield put(
      setJourneySummary({
        coverageTypes: response.coverageTypes,
        consumerInfo: response.consumerInfo,
        address: response.address,
        originationId: response.originationId,
        journeyId: response.journeyId
      })
    );
    yield put(push(addIdToRoute(ROUTES.QUOTES_VIEW, id)));
    yield put(setLoading(false));
    // Add to recent quotes browser storage
    const lastName = response.consumerInfo?.lastName;
    const coverageTypes = response.coverageTypes;
    addToRecentQuotes(id, lastName, coverageTypes);
  } catch (e) {
    yield put(setLoading(false));
    error(e, action);
    yield put(setError({ message: `Couldn't find that quoteset! (${e})` }));
  }
}

export function* watchGetQuoteset() {
  yield takeEvery(GET_QUOTESET, handleGetQuoteset);
}

function* handleFinalizeQuoteset(action) {
  const { id, type, emailData, callback, sendLegacy, compareQuoteIds } = action.payload;
  try {
    // Save quoteset first
    yield call(handleSaveQuoteset);
    // Then finalize
    yield put(setLoading(true));
    yield call(finalizeQuoteset, { id, type, emailData, sendLegacy, compareQuoteIds });
    yield put(finalizeQuotesetSuccess());
    if (callback) callback();
  } catch (e) {
    yield put(setError({ message: 'Could not finalize quoteset :(' }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* quotesetWatchFinalizeQuoteset() {
  yield takeEvery(FINALIZE_QUOTESET, handleFinalizeQuoteset);
}

function* handleQaQuoteset() {
  try {
    // Save first
    yield call(handleSaveQuoteset);

    // Grab data from state.
    const { quotes, quoteset, bundledQuotes, journeySummary, mortgage } = yield select(
      state => state
    );
    const { supplementaryInfo, agentNotes } = quoteset;
    const { coverageTypes } = journeySummary;
    const cleanedQuotes = Object.values(quotes);
    const cleanedBundledQuotes = Object.values(bundledQuotes);

    // Then call QA endpoint.
    yield put(setLoading(true));
    const { quotesReadyUrl } = yield call(qaQuoteset, {
      quotes: cleanedQuotes,
      agentNotes,
      mortgage,
      bundledQuotes: cleanedBundledQuotes,
      supplementaryInfo,
      coverageTypes
    });
    yield put(qaQuotesetSuccess({ quotesReadyUrl }));
  } catch (e) {
    yield put(setError({ message: `Could not successfully create QA quoteset record. ${e}` }));
    error(e);
  }
  yield put(setLoading(false));
}

export function* watchQaQuoteset() {
  yield takeEvery(QA_QUOTESET, handleQaQuoteset);
}

function* handleSaveQuoteset() {
  const { quotes, quoteset, bundledQuotes, mortgage } = yield select(state => state);
  const { supplementaryInfo, agentNotes } = quoteset;
  const id = quoteset[GENERAL_FIELDS.ID];
  const agentId = quoteset[FOREIGN_KEYS.AGENT] || undefined;
  const cleanedQuotes = removeCreateDate(Object.values(quotes));
  const cleanedBundledQuotes = removeCreateDate(Object.values(bundledQuotes));
  yield put(setLoading(true));
  try {
    const response = yield call(patchQuoteset, {
      quotes: cleanedQuotes,
      bundledQuotes: cleanedBundledQuotes,
      supplementaryInfo,
      id,
      mortgage,
      agentNotes,
      agentId
    });
    yield put(setQuoteset(response));
  } catch (e) {
    yield put(setError({ message: 'Could not save quoteset :(' }));
    error(e);
  }
  yield put(setLoading(false));
}

export function* quotesetWatchSaveQuoteset() {
  yield takeEvery(SAVE_QUOTESET, handleSaveQuoteset);
}

function* handleUploadQuotes(action) {
  const { quoteset } = yield select(state => state);
  const id = quoteset[GENERAL_FIELDS.ID];
  yield put(setLoading(true));
  try {
    const response = yield call(upload, id, action.payload);
    yield put(setQuoteset(response));
    const { callback } = action.payload;
    if (callback) callback();
  } catch (e) {
    yield put(setError({ message: 'Error attempting to create quotes from PDFs' }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchUploadQuotes() {
  yield takeLatest(UPLOAD_QUOTES, handleUploadQuotes);
}

function* handleSyncQuoteset(action) {
  const { quotesetId, callback } = action.payload;
  yield put(setLoading(true));
  try {
    yield call(syncQuoteset, quotesetId);
    if (callback) callback();
  } catch (e) {
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchSyncQuoteset() {
  yield takeLatest(SYNC_QUOTESET, handleSyncQuoteset);
}

function* handleMapVertafore(action) {
  const { quotesetId, callback } = action.payload;
  yield put(setLoading(true));
  try {
    const vertaforeUrl = yield call(mapVertafore, quotesetId);
    yield put(mapVertaforeSuccess({ vertaforeUrl }));
    if (callback) callback();
  } catch (e) {
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchMapVertafore() {
  yield takeLatest(MAP_VERTAFORE, handleMapVertafore);
}

function* handleQuotesetPost(action) {
  const { journeyId, originationId, uiData } = yield select(state => state.journey);
  yield put(setLoading(true));
  try {
    const response = yield postQuoteset({
      journeyId,
      originationId,
      uiData,
      notifyConsumer: false
    });

    if (response?.[GENERAL_FIELDS.ID]) {
      yield window.location.assign(addIdToRoute(ROUTES.QUOTES_VIEW, response[GENERAL_FIELDS.ID]));
    }
  } catch (e) {
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchQuotesetPost() {
  yield takeLatest(POST_QUOTESET, handleQuotesetPost);
}

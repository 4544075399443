import { camelToTitleCase } from '../../utils/string';

export function convertToColumn(key) {
  return {
    field: key,
    headerName: camelToTitleCase(key),
    description: null
    // width: 160,
    // valueGetter: params => `${params.row.firstName || ''} ${params.row.lastName || ''}`
  };
}

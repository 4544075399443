import { COVERAGE_TYPES } from 'woop-shared/enums';
import { FIELDS } from 'woop-shared/supplementary-info/fields';
import { FOUNDATION_TYPES, ROOF_TYPES, ROOF_SHAPES } from 'woop-shared/origination/enums';
import Select from '../../Select';
import NumberField from '../../NumberField';
import MoneyField from '../../MoneyField';
import DecimalField from '../../DecimalField';
import { YES_NO_UNKNOWN_OPTIONS, POOL_TYPE_OPTIONS, YES_OPTION, NO_OPTION } from './options';
import { createOptionsFromEnum } from '../../Select/utils';

export const COMPONENT_NAMES = {
  MONEY: 'money',
  DECIMAL: 'decimal',
  NUMBER: 'number',
  SELECT: 'select'
};

export const COMPONENT_MAPPING = {
  [COMPONENT_NAMES.MONEY]: MoneyField,
  [COMPONENT_NAMES.DECIMAL]: DecimalField,
  [COMPONENT_NAMES.NUMBER]: NumberField,
  [COMPONENT_NAMES.SELECT]: Select
};

const NUMBER_OF_UNITS = {
  ...FIELDS.NUMBER_OF_UNITS,
  type: COMPONENT_NAMES.NUMBER
};

const NUMBER_OF_STORIES = {
  ...FIELDS.NUMBER_OF_STORIES,
  type: COMPONENT_NAMES.NUMBER
};

const SQUARE_FOOTAGE = {
  ...FIELDS.SQUARE_FOOTAGE,
  type: COMPONENT_NAMES.NUMBER
};

const FOUNDATION_TYPE = {
  ...FIELDS.FOUNDATION_TYPE,
  type: COMPONENT_NAMES.SELECT,
  default: FOUNDATION_TYPES.BASEMENT
};

const ROOF_TYPE = {
  ...FIELDS.ROOF_TYPE,
  type: COMPONENT_NAMES.SELECT,
  default: ROOF_TYPES.COMPOSITION_SHINGLE
};

const ROOF_SHAPE = {
  ...FIELDS.ROOF_SHAPE,
  type: COMPONENT_NAMES.SELECT,
  default: ROOF_SHAPES.GABLE_OR_HIP
};

const HAS_SWIMMING_POOL = {
  ...FIELDS.HAS_SWIMMING_POOL,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: NO_OPTION.value
};

const POOL_TYPE = {
  ...FIELDS.POOL_TYPE,
  type: COMPONENT_NAMES.SELECT,
  options: POOL_TYPE_OPTIONS,
  conditionals: [
    {
      fieldName: HAS_SWIMMING_POOL.key,
      showValues: ['Yes']
    }
  ]
};

const POOL_HAS_FENCE = {
  ...FIELDS.POOL_HAS_FENCE,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  conditionals: [
    {
      fieldName: HAS_SWIMMING_POOL.key,
      showValues: ['Yes']
    }
  ]
};

const POOL_HAS_SLIDE = {
  ...FIELDS.POOL_HAS_SLIDE,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  conditionals: [
    {
      fieldName: HAS_SWIMMING_POOL.key,
      showValues: ['Yes']
    }
  ]
};

const POOL_HAS_DIVING_BOARD = {
  ...FIELDS.POOL_HAS_DIVING_BOARD,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  conditionals: [
    {
      fieldName: HAS_SWIMMING_POOL.key,
      showValues: ['Yes']
    }
  ]
};

const HEATING_TYPE = {
  ...FIELDS.HEATING_TYPE,
  type: COMPONENT_NAMES.SELECT
};

const ELECTRICAL_BREAKER = {
  ...FIELDS.ELECTRICAL_BREAKER,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: YES_OPTION.value
};

const ELECTRICAL_BREAKER_100_AMPS = {
  ...FIELDS.ELECTRICAL_BREAKER_100,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: YES_OPTION.value
};

const TRAMPOLINE = {
  ...FIELDS.TRAMPOLINE,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: NO_OPTION.value
};

const TRAMPOLINE_HAS_NET = {
  ...FIELDS.TRAMPOLINE_HAS_NET,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  conditionals: [
    {
      fieldName: TRAMPOLINE.key,
      showValues: ['Yes']
    }
  ]
};

const POLICY_CANCELED = {
  ...FIELDS.POLICY_CANCELED,
  type: COMPONENT_NAMES.SELECT,
  options: [NO_OPTION, ...createOptionsFromEnum(COVERAGE_TYPES)],
  default: NO_OPTION.value,
  isMulti: true
};

const INSURANCE_FRAUD = {
  ...FIELDS.INSURANCE_FRAUD,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: NO_OPTION.value
};

const FELON = {
  ...FIELDS.FELON,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: NO_OPTION.value
};

const FORECLOSURE = {
  ...FIELDS.FORECLOSURE,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: NO_OPTION.value
};

const COMPANY_OWNED = {
  ...FIELDS.COMPANY_OWNED,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: NO_OPTION.value
};

const UNDER_CONSTRUCTION = {
  ...FIELDS.UNDER_CONSTRUCTION,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: NO_OPTION.value
};

const VISIBLE_DAMAGE = {
  ...FIELDS.VISIBLE_DAMAGE,
  type: COMPONENT_NAMES.SELECT,
  options: YES_NO_UNKNOWN_OPTIONS,
  default: NO_OPTION.value
};

export const OPTIONAL_SUPPLEMENTARY_INFO_KEYS = [
  POOL_HAS_DIVING_BOARD.key,
  POOL_HAS_FENCE.key,
  POOL_HAS_SLIDE.key,
  POOL_TYPE.key,
  FOUNDATION_TYPE.key,
  TRAMPOLINE_HAS_NET.key,
  POLICY_CANCELED.key
];

export const SUPPLEMENTARY_FIELDS = {
  [COVERAGE_TYPES.HOME]: [
    NUMBER_OF_UNITS,
    NUMBER_OF_STORIES,
    SQUARE_FOOTAGE,
    HEATING_TYPE,
    FOUNDATION_TYPE,
    ELECTRICAL_BREAKER,
    ELECTRICAL_BREAKER_100_AMPS,
    ROOF_TYPE,
    ROOF_SHAPE,
    FORECLOSURE,
    UNDER_CONSTRUCTION,
    VISIBLE_DAMAGE,
    COMPANY_OWNED,
    TRAMPOLINE,
    TRAMPOLINE_HAS_NET,
    HAS_SWIMMING_POOL,
    POOL_HAS_FENCE,
    POOL_HAS_SLIDE,
    POOL_HAS_DIVING_BOARD,
    POOL_TYPE,
    FELON,
    POLICY_CANCELED,
    INSURANCE_FRAUD
  ],
  [COVERAGE_TYPES.RENTERS]: [
    NUMBER_OF_UNITS,
    SQUARE_FOOTAGE,
    HEATING_TYPE,
    HAS_SWIMMING_POOL,
    POOL_HAS_FENCE,
    POOL_HAS_SLIDE,
    POOL_HAS_DIVING_BOARD,
    POOL_TYPE,
    FELON,
    POLICY_CANCELED,
    INSURANCE_FRAUD
  ],
  [COVERAGE_TYPES.CONDO]: [
    NUMBER_OF_UNITS,
    NUMBER_OF_STORIES,
    SQUARE_FOOTAGE,
    FOUNDATION_TYPE,
    ELECTRICAL_BREAKER,
    ELECTRICAL_BREAKER_100_AMPS,
    HEATING_TYPE,
    ROOF_TYPE,
    ROOF_SHAPE,
    FORECLOSURE,
    UNDER_CONSTRUCTION,
    VISIBLE_DAMAGE,
    COMPANY_OWNED,
    TRAMPOLINE,
    TRAMPOLINE_HAS_NET,
    HAS_SWIMMING_POOL,
    POOL_HAS_FENCE,
    POOL_HAS_SLIDE,
    POOL_HAS_DIVING_BOARD,
    POOL_TYPE,
    FELON,
    POLICY_CANCELED,
    INSURANCE_FRAUD
  ],
  [COVERAGE_TYPES.AUTO]: [POLICY_CANCELED, FELON, INSURANCE_FRAUD]
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { stripNonDigits } from 'woop-shared/quotes/utils';
import styles from '../../styles.css';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { patchVehicle, postVehicle } from '../../../../api/policy';
import VehiclePicker from '../../../../components/VehiclePicker';
import TextField from '../../../../components/TextField';

const EditVehicle = ({ policy, vehicle, visible, closing, toggleModal, refreshPolicy }) => {
  const [loading, setLoading] = useState();
  const [vehicleState, setVehicleState] = useState({ ...vehicle });

  const handleChange = update => setVehicleState({ ...vehicleState, ...update });
  const setVin = value => setVehicleState({ ...vehicleState, vin: value });

  useEffect(() => {
    setVehicleState({ ...vehicle });
  }, [vehicle]);

  const save = () => {
    setLoading(true);

    const updates = {
      ...vehicleState,
      year: vehicleState.year && parseInt(stripNonDigits(vehicleState.year))
    };

    if (!vehicle) {
      postVehicle({
        policyId: policy.policy_id,
        updates
      })
        .then(() => refreshPolicy())
        .then(() => {
          setLoading(false);
          toggleModal();
          setVehicleState({});
        });
    } else {
      patchVehicle({
        policyId: policy.policy_id,
        vehicleId: vehicle.vehicle_id,
        updates
      })
        .then(() => refreshPolicy())
        .then(() => {
          setLoading(false);
          toggleModal();
          setVehicleState({});
        });
    }
  };

  return (
    visible && (
      <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
        <div>{vehicle ? 'Edit' : 'Create'} Vehicle</div>
        <div className={styles.form}>
          <VehiclePicker
            year={vehicleState.year}
            make={vehicleState.make}
            model={vehicleState.model}
            onChange={handleChange}
          />
          <TextField
            name={'vin'}
            label={'VIN'}
            value={vehicleState.vin}
            onChange={e => setVin(e.target.value)}
          />
        </div>
        <Button className={styles.modalButton} loading={loading} onClick={save}>
          Save
        </Button>
      </Modal>
    )
  );
};

EditVehicle.propTypes = {
  policy: PropTypes.object,
  vehicle: PropTypes.object,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  refreshPolicy: PropTypes.func
};

export default EditVehicle;

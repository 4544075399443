const { PROMPT_NAMES, PROMPT_GROUP_NAMES } = require('woop-shared/prompts/names');

const ALL_PROMPTS = Object.values(PROMPT_NAMES);

export const DISPLAY_SECTIONS = {
  POLICY: {
    prompts: [
      PROMPT_NAMES.COVERAGE_TYPE,
      PROMPT_NAMES.EFFECTIVE_DATE,
      PROMPT_NAMES.AUTO_EFFECTIVE_DATE
    ]
  },
  PRIMARY_APPLICANT: {
    prompts: [
      PROMPT_NAMES.FIRST_NAME,
      PROMPT_NAMES.LAST_NAME,
      PROMPT_NAMES.EMAIL,
      PROMPT_NAMES.PHONE,
      PROMPT_NAMES.PHONE_TYPE,
      PROMPT_NAMES.TAX_ID,
      PROMPT_NAMES.DOB,
      PROMPT_NAMES.GENDER,
      PROMPT_NAMES.MARITAL_STATUS,
      PROMPT_NAMES.JOB_INDUSTRY,
      PROMPT_NAMES.ROLE,
      PROMPT_NAMES.EDUCATION
    ]
  },
  DRIVERS: {
    promptGroupName: PROMPT_GROUP_NAMES.DRIVERS,
    prompts: [
      PROMPT_NAMES.FIRST_NAME,
      PROMPT_NAMES.LAST_NAME,
      PROMPT_NAMES.RELATIONSHIP_TO_INSURED,
      PROMPT_NAMES.DOB,
      PROMPT_NAMES.GENDER,
      PROMPT_NAMES.EDUCATION,
      PROMPT_NAMES.JOB_INDUSTRY,
      PROMPT_NAMES.ROLE,
      PROMPT_NAMES.DRIVERS_LICENSE_STATE,
      PROMPT_NAMES.DRIVERS_LICENSE_NUMBER,
      PROMPT_NAMES.LICENSE_STATUS,
      PROMPT_NAMES.LICENSE_DATE,
      PROMPT_NAMES.GPA,
      PROMPT_NAMES.DEFENSIVE,
      PROMPT_NAMES.DRIVING_COURSE_DATE,
      PROMPT_NAMES.DRIVING_MONITOR
    ]
  },
  EXCLUDED_DRIVERS: {
    promptGroupName: PROMPT_GROUP_NAMES.EXCLUDED_DRIVERS,
    prompts: [PROMPT_NAMES.NAME, PROMPT_NAMES.EXCLUSION_REASON]
  },
  CO_APPLICANTS: {
    promptGroupName: PROMPT_GROUP_NAMES.PERSON,
    prompts: [
      PROMPT_NAMES.NAME,
      PROMPT_NAMES.FIRST_NAME,
      PROMPT_NAMES.LAST_NAME,
      PROMPT_NAMES.RELATIONSHIP_TO_INSURED,
      PROMPT_NAMES.DOB,
      PROMPT_NAMES.GENDER
    ]
  },
  PROPERTY: {
    prompts: [
      PROMPT_NAMES.ADDRESS,
      PROMPT_NAMES.FLOOR_NUMBER,
      PROMPT_NAMES.UNIT_NUMBER,
      PROMPT_NAMES.PROPERTY_TYPE,
      PROMPT_NAMES.PROPERTY_USAGE,
      PROMPT_NAMES.PRIMARY_RESIDENCE_ADDRESS,
      PROMPT_NAMES.PROPERTY_PURCHASE_DATE,
      PROMPT_NAMES.MOVE_IN_DATE,
      PROMPT_NAMES.CONDO_MASTER_POLICY,
      PROMPT_NAMES.LIVED_AT_PRIMARY_ADDRESS,
      PROMPT_NAMES.PRIOR_ADDRESS,
      PROMPT_NAMES.RESIDENCE_TYPE,
      PROMPT_NAMES.LOAN_AMOUNT,
      PROMPT_NAMES.NUM_OF_MORTGAGES,
      PROMPT_NAMES.SQ_FOOTAGE,
      PROMPT_NAMES.ALREADY_OWN_PROPERTY,
      PROMPT_NAMES.YEAR_BUILT,
      PROMPT_NAMES.YEAR_ROOF_UPDATED,
      PROMPT_NAMES.ROOF_UPDATE_TYPE,
      PROMPT_NAMES.YEAR_HEATING_UPDATED,
      PROMPT_NAMES.HEATING_UPDATE_TYPE,
      PROMPT_NAMES.YEAR_PLUMBING_UPDATED,
      PROMPT_NAMES.PLUMBING_UPDATE_TYPE,
      PROMPT_NAMES.YEAR_ELECTRIC_UPDATED,
      PROMPT_NAMES.ELECTRIC_UPDATE_TYPE,
      PROMPT_NAMES.UNDERGROUND_FUEL_TANK,
      PROMPT_NAMES.BURGLAR_ALARM,
      PROMPT_NAMES.FIRE_ALARM,
      PROMPT_NAMES.PROPERTY_DISCOUNTS
    ]
  },
  PROPERTY_USAGE: {
    prompts: [
      PROMPT_NAMES.BUSINESS_NAME,
      PROMPT_NAMES.BUSINESS_TYPE,
      PROMPT_NAMES.ANIMALS,
      PROMPT_NAMES.ANIMAL_INJURY,
      PROMPT_NAMES.DOG_BREED,
      PROMPT_NAMES.NUM_OF_RESIDENTS,
      PROMPT_NAMES.NUM_OF_CHILDREN,
      PROMPT_NAMES.NUM_OF_CHILDREN_NON_DRIVERS
    ]
  },
  VEHICLES: {
    promptGroupName: PROMPT_GROUP_NAMES.VEHICLES,
    prompts: [
      PROMPT_NAMES.VIN,
      PROMPT_NAMES.VEHICLE_YEAR,
      PROMPT_NAMES.VEHICLE_MAKE,
      PROMPT_NAMES.VEHICLE_MODEL,
      PROMPT_NAMES.VEHICLE_SUBMODEL,
      PROMPT_NAMES.VEHICLE_USE,
      PROMPT_NAMES.VEHICLE_ACTIVITIES,
      PROMPT_NAMES.ANNUAL_MILES,
      PROMPT_NAMES.VEHICLE_OWNERSHIP_STATUS,
      PROMPT_NAMES.VEHICLE_FINANCING_COMPANY,
      PROMPT_NAMES.VEHICLE_PURCHASE_DATE,
      PROMPT_NAMES.PARKED_AT_PRIMARY_ADDRESS,
      PROMPT_NAMES.CAR_ADDRESS,
      PROMPT_NAMES.ASSIGNED_DRIVER,
      PROMPT_NAMES.ADDITIONAL_DRIVER_USAGE
    ]
  },
  DESIRED_VEHICLES: {
    promptGroupName: PROMPT_GROUP_NAMES.DESIRED_VEHICLES,
    prompts: [PROMPT_NAMES.VEHICLE_YEAR, PROMPT_NAMES.VEHICLE_MAKE, PROMPT_NAMES.VEHICLE_MODEL]
  },
  TRADE_INS: {
    promptGroupName: PROMPT_GROUP_NAMES.TRADE_INS,
    prompts: [
      PROMPT_NAMES.VEHICLE_YEAR,
      PROMPT_NAMES.VEHICLE_MAKE,
      PROMPT_NAMES.VEHICLE_MODEL,
      PROMPT_NAMES.VIN
    ]
  },
  INSURANCE_HISTORY_AUTO: {
    prompts: [
      PROMPT_NAMES.CURRENT_AUTO_INSURER,
      PROMPT_NAMES.CURRENT_AUTO_POLICY_EXPIRATION,
      PROMPT_NAMES.YEARS_AUTO_INSURED,
      PROMPT_NAMES.AUTO_POLICY_CANCELED,
      PROMPT_NAMES.RECENT_AUTO_CLAIM
    ]
  },
  INSURANCE_HISTORY_CONDO: {
    prompts: [
      PROMPT_NAMES.CURRENT_CONDO_INSURER,
      PROMPT_NAMES.CURRENT_CONDO_POLICY_EXPIRATION,
      PROMPT_NAMES.YEARS_CONDO_INSURED,
      PROMPT_NAMES.YEARS_CONTINUOUSLY_CONDO_INSURED,
      PROMPT_NAMES.CONDO_POLICY_CANCELED,
      PROMPT_NAMES.RECENT_CONDO_CLAIM
    ]
  },
  INSURANCE_HISTORY_HOME: {
    prompts: [
      PROMPT_NAMES.CURRENT_HOME_INSURER,
      PROMPT_NAMES.CURRENT_HOME_POLICY_EXPIRATION,
      PROMPT_NAMES.YEARS_HOME_INSURED,
      PROMPT_NAMES.YEARS_CONTINUOUSLY_HOME_INSURED,
      PROMPT_NAMES.HOME_POLICY_CANCELED,
      PROMPT_NAMES.RECENT_HOME_CLAIM
    ]
  },
  INSURANCE_HISTORY_RENTERS: {
    prompts: [
      PROMPT_NAMES.CURRENT_RENTERS_INSURER,
      PROMPT_NAMES.CURRENT_RENTERS_POLICY_EXPIRATION,
      PROMPT_NAMES.YEARS_RENTERS_INSURED,
      PROMPT_NAMES.YEARS_CONTINUOUSLY_RENTERS_INSURED,
      PROMPT_NAMES.RENTERS_POLICY_CANCELED,
      PROMPT_NAMES.RECENT_RENTERS_CLAIM
    ]
  },
  PROPERTY_INCIDENTS: {
    promptGroupName: PROMPT_GROUP_NAMES.PROPERTY_INCIDENT,
    prompts: [
      PROMPT_NAMES.INCIDENT_DATE,
      PROMPT_NAMES.INCIDENT_DESCRIPTION,
      PROMPT_NAMES.INCIDENT_AMOUNT,
      PROMPT_NAMES.INCIDENT_DRIVER,
      PROMPT_NAMES.INCIDENT_TYPE
    ]
  },
  VEHICLE_INCIDENTS: {
    promptGroupName: PROMPT_GROUP_NAMES.INCIDENTS,
    prompts: [
      PROMPT_NAMES.INCIDENT_DATE,
      PROMPT_NAMES.INCIDENT_DESCRIPTION,
      PROMPT_NAMES.INCIDENT_AMOUNT,
      PROMPT_NAMES.INCIDENT_DRIVER,
      PROMPT_NAMES.INCIDENT_TYPE
    ]
  },
  UMBRELLA: {
    prompts: [
      PROMPT_NAMES.OWN_OTHER_PROPERTIES,
      PROMPT_NAMES.OWN_OTHER_VEHICLES,
      PROMPT_NAMES.OTHER_VEHICLES_INSURED,
      PROMPT_NAMES.OTHER_VEHICLES_INSURANCE_COMPANIES,
      PROMPT_NAMES.INCLUDE_UNINSURED_COVERAGE
    ]
  }
};

const RENDERED_PROMPTS = Object.values(DISPLAY_SECTIONS).flatMap(section => section.prompts);
const HIDEABLE_PROMPTS = [
  PROMPT_NAMES.ZIP_CODE,
  PROMPT_NAMES.HAS_BUSINESS,
  PROMPT_NAMES.OTHERS_ON_POLICY
];
export const OTHER_PROMPTS = {
  prompts: ALL_PROMPTS.filter(
    promptName => !RENDERED_PROMPTS.includes(promptName) && !HIDEABLE_PROMPTS.includes(promptName)
  )
};

import { connect } from 'react-redux';
import CreateQuoteset from './';
import {
  finalizeQuoteset,
  saveQuoteset,
  setQuotesetField,
  getQuoteset,
  qaQuoteset
} from '../../actions/quoteset';
import { setError } from '../../actions/error';
import { addQuote, updateQuote, deleteQuote } from '../../actions/quotes';
import { addBundledQuote } from '../../actions/bundled-quotes';

const mapStateToProps = ({
  journeySummary,
  loading,
  error,
  quoteset,
  quotes,
  qa,
  bundledQuotes
}) => {
  return {
    journeySummary,
    loading,
    error,
    quotes: Object.values(quotes),
    quoteset,
    qa,
    bundledQuotes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addQuote: quote => {
      dispatch(addQuote(quote));
    },
    addBundle: newBundle => {
      dispatch(addBundledQuote(newBundle));
    },
    deleteQuote: id => {
      dispatch(deleteQuote(id));
    },
    updateQuote: (id, updateObj) => {
      dispatch(updateQuote({ id, updateObj }));
    },
    setQuotesetField: (key, val) => {
      dispatch(setQuotesetField({ key, value: val }));
    },
    finalizeQuoteset: payload => {
      dispatch(finalizeQuoteset(payload));
    },
    saveQuoteset: () => {
      dispatch(saveQuoteset());
    },
    getQuoteset: id => {
      dispatch(getQuoteset({ id }));
    },
    qaQuoteset: () => {
      dispatch(qaQuoteset());
    },
    setError: args => dispatch(setError(args))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuoteset);

import { takeLatest, put, call } from 'redux-saga/effects';
import { setLoading } from '../actions/loading';
import { getTransunion, postTransunion } from '../api/transunion';
import { getTransunionSuccess, GET_TRANSUNION, POST_TRANSUNION } from '../actions/transunion';
import { error, log } from '../utils/logger';
import { formatValidationErrors, parseValidationErrors } from '../utils/api';

function* handleGetTransunion(action) {
  yield put(setLoading(true));
  try {
    const data = yield call(getTransunion, action.payload);
    yield put(getTransunionSuccess(data));
  } catch (e) {
    log(e);
  }
  yield put(setLoading(false));
}

export function* watchGetTransunion() {
  yield takeLatest(GET_TRANSUNION, handleGetTransunion);
}

function* handlePostTransunion(action) {
  yield put(setLoading(true));
  try {
    const data = yield call(postTransunion, action.payload);
    yield put(getTransunionSuccess(data));
  } catch (e) {
    if (e?.response?.status === 404)
      alert('No results were found matching the name and address provided.');
    else if (e?.response?.status === 400) {
      const errors = parseValidationErrors(e.response.data?.errors);
      const formatted = formatValidationErrors(errors);
      alert(`Given data is invalid.\n\n${formatted.join('\n')}`);
    } else {
      alert('Failed to complete the search.');
      error(e);
    }
  }
  yield put(setLoading(false));
}

export function* watchPostTransunion() {
  yield takeLatest(POST_TRANSUNION, handlePostTransunion);
}

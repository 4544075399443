import { COVERAGES } from 'woop-shared/canopy/coverages';
import { PROPERTY_TYPES } from 'woop-shared/origination/enums';
import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { FIELDS } from 'woop-shared/canopy/enums';
import { QUOTE_REQUEST_FIELDS } from 'woop-shared/db/models';
import _ from 'lodash';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { QUOTE_TYPES } from '../constants';
import { PROPERTY_FIELDS } from '../../../constants/origination';
import { VEHICLE_COVERAGES } from '../../../constants/canopy';

export function compareRequests(a, b) {
  return a[QUOTE_REQUEST_FIELDS.LOB] < b[QUOTE_REQUEST_FIELDS.LOB] ? -1 : 1;
}

export function getCoveragesLabel(data) {
  const biCoverage = _.find(data, { name: COVERAGES.BODILY_INJURY_LIABILITY });
  const pdCoverage = _.find(data, { name: COVERAGES.PROPERTY_DAMAGE_LIABILITY });

  const biPerPerson = biCoverage?.[FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS] / 100000;
  const biPerIncident = biCoverage?.[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS] / 100000;
  const pdPerIncident = pdCoverage?.[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS] / 100000;
  if (!(biPerPerson && biPerIncident && pdPerIncident)) return '';
  return `${biPerPerson}K / ${biPerIncident}K / ${pdPerIncident}K`;
}

export function getRequestIcon(type) {
  return type === QUOTE_TYPES.APPLES ? '🍏' : '🛡';
}

export function getQuoteRequestLabel(request) {
  //@todo; solve how to distinguish between multiple quotes of same type...:/
  return `${request[QUOTE_REQUEST_FIELDS.LOB]} ${getRequestIcon(request.type)}`;
}

/**
 * Format a quote request collection for rendering
 *
 * @param {object} quoteRequest
 * @returns {Array}
 */
export function formatQuoteRequest(quoteRequest) {
  // Split by auto + property.
  const [autoRequests, propertyRequests] = _.partition(
    quoteRequest?.[QUOTE_REQUEST_FIELDS.REQUESTS],
    r => r[QUOTE_REQUEST_FIELDS.LOB] === COVERAGE_TYPES.AUTO
  );
  const groupedPropertyRequests = _.groupBy(propertyRequests, 'propertyId');
  return [Object.values(groupedPropertyRequests), autoRequests];
}

function requestIsValid({ type, termLength, effectiveDate, carriers, coverages }) {
  return [type, effectiveDate, termLength, carriers?.length, coverages?.length].every(Boolean);
}

export function getQuoteRequestErrors({ requests = [], bundledRequests = [] }) {
  const hasRequests = !!requests.length;
  const invalidRequests = requests
    .map(req =>
      requestIsValid(req) || req[QUOTE_REQUEST_FIELDS.SKIP]
        ? null
        : `${req[QUOTE_REQUEST_FIELDS.LOB]} ${getRequestIcon(req.type)}`
    )
    .filter(Boolean);
  const bundlesAreValid = bundledRequests.every(b => b?.length && b.length > 1);

  return [
    !hasRequests && 'Must have at least one request',
    ...invalidRequests.map(req => `Request "${req}" is missing required fields`),
    !bundlesAreValid && 'Bundles must have at least two associated requests'
  ].filter(Boolean);
}

export function validateQuoteRequest(quoteRequest) {
  return getQuoteRequestErrors(quoteRequest).length === 0;
}

export function sortPropertyRequests(requestGroup, quoteApplication) {
  if (!quoteApplication || requestGroup.length === 0) return requestGroup;
  const primaryIndex = requestGroup.findIndex(requests => {
    const propertyId = requests[0][QUOTE_REQUEST_FIELDS.PROPERTY_ID];
    const property = quoteApplication?.[ORIGINATION_FIELDS.PROPERTIES]?.find(
      p => p.id === propertyId
    );
    return property?.[PROPERTY_FIELDS.PROPERTY_TYPE] === PROPERTY_TYPES.PRIMARY_RESIDENCE;
  });
  if (primaryIndex < 0) return requestGroup;

  let sorted = [...requestGroup];
  const primaryRequests = _.pullAt(sorted, primaryIndex);
  return [...primaryRequests, ...sorted];
}

/**
 *
 * @param {object[]} coverages Canopy coverages
 * @returns {Array<object[]>} [[vehicle coverages], [policy coverages]]
 */
export function splitCoverages(coverages) {
  return _.partition(coverages, coverage => {
    return !!VEHICLE_COVERAGES[coverage[FIELDS.COVERAGE.NAME]];
  });
}

export function getRandomWaitingQuote() {
  const quotes = [
    '“I have noticed that the people who are late are often so much jollier than the people who have to wait for them.” - E. V. Lucas',
    '“All things come to him who waits - provided he knows what he is waiting for.” - Woodrow T. Wilson',
    '“Patience is not the ability to wait. Patience is to be calm no matter what happens...” - Roy T. Bennett',
    '“Forever is composed of nows.” - Emily Dickinson',
    '“Patience is something you admire in the driver behind you, but not in the one ahead.” - Bill McGlashen',
    '“Of all the hardships a person had to face, none was more punishing than the simple act of waiting.” - Khaled Hosseini',
    '“Things may come to those who wait, but only the things left by those who hustle.” - Sophia Amoruso',
    '“Between the wish and the thing the world lies waiting.” - Cormac McCarthy',
    '“Hurry is beside the point, useless, an obstruction. The thing is to be attentively present. To sit and wait is as important as to move. Patience is as valuable as industry.” - Toni Morrison',
    '“Patience and perseverance have a magical effect before which difficulties disappear and obstacles vanish.” - John Quincy Adams',
    '“Patience is the art of concealing your impatience.” - Guy Kawasaki',
    '“Patience is bitter, but its fruit is sweet.” - Jean-Jacques Rousseau',
    '“Rivers know this: There is no hurry. We shall get there someday.” - A.A. Milne',
    '“Patience is not the ability to wait, but the ability to keep a good attitude while waiting.” - Joyce Meyer'
  ];

  const randomIndex = Math.floor(Math.random() * quotes.length);

  return quotes[randomIndex];
}

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { US_STATES } from 'woop-shared/data/states';
import { UI_ADDRESS_FIELDS } from 'woop-shared/enums';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import { createSelectOption } from '../../../../components/Select/utils';
import TextField from '../../../../components/TextField';
import styles from '../../styles.css';

const EXCLUDED_STATES = new Set(['CA', 'HI', 'NH', 'NY', 'OK', 'PA', 'VA']);

const Form = ({ uiData, loading, submit }) => {
  const [firstName, setFirstName] = useState(uiData?.[PROMPT_NAMES.FIRST_NAME] ?? '');
  const [lastName, setLastName] = useState(uiData?.[PROMPT_NAMES.LAST_NAME] ?? '');
  const [address, setAddress] = useState({ ...uiData?.[PROMPT_NAMES.ADDRESS] });

  const setAddressField = field => event =>
    setAddress({
      ...address,
      [field]: event.target.value
    });

  const setState = (_, value) =>
    setAddress({
      ...address,
      [UI_ADDRESS_FIELDS.STATE]: value
    });

  const isValid = () =>
    [
      firstName,
      lastName,
      address?.[UI_ADDRESS_FIELDS.STREET],
      address?.[UI_ADDRESS_FIELDS.CITY],
      address?.[UI_ADDRESS_FIELDS.STATE],
      address?.[UI_ADDRESS_FIELDS.POSTAL_CODE]
    ].every(Boolean);

  useEffect(() => {
    setFirstName(uiData?.[PROMPT_NAMES.FIRST_NAME] ?? '');
    setLastName(uiData?.[PROMPT_NAMES.LAST_NAME] ?? '');

    const combineStreetPieces = ({
      [UI_ADDRESS_FIELDS.HOUSE_NUMBER]: houseNumber,
      [UI_ADDRESS_FIELDS.STREET]: street
    } = {}) => (houseNumber ? `${houseNumber} ${street}` : street);
    setAddress({
      ...uiData?.[PROMPT_NAMES.ADDRESS],
      [UI_ADDRESS_FIELDS.POSTAL_CODE]:
        uiData?.[PROMPT_NAMES.ADDRESS]?.[UI_ADDRESS_FIELDS.POSTAL_CODE]?.toString(),
      [UI_ADDRESS_FIELDS.STREET]: combineStreetPieces(uiData?.[PROMPT_NAMES.ADDRESS])
    });
  }, [uiData]);

  return (
    <>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>Name</div>
        <TextField
          name="firstName"
          placeholder="First name"
          label="First name"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <TextField
          name="lastName"
          placeholder="Last name"
          label="Last name"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
      </div>

      <div className={styles.section}>
        <div className={styles.sectionHeader}>Address</div>
        <TextField
          name={UI_ADDRESS_FIELDS.STREET}
          label={'Street'}
          onChange={setAddressField(UI_ADDRESS_FIELDS.STREET)}
          value={address[UI_ADDRESS_FIELDS.STREET]}
        />
        <TextField
          name={UI_ADDRESS_FIELDS.CITY}
          label={'City'}
          onChange={setAddressField(UI_ADDRESS_FIELDS.CITY)}
          value={address[UI_ADDRESS_FIELDS.CITY]}
        />
        <Select
          placeholder={'State'}
          name={UI_ADDRESS_FIELDS.STATE}
          options={US_STATES}
          onChange={setState}
          value={createSelectOption(address?.[UI_ADDRESS_FIELDS.STATE])}
        />
        {EXCLUDED_STATES.has(address?.[UI_ADDRESS_FIELDS.STATE]) && (
          <p className={styles.stateErrorMessage}>
            Vehicle prefill data is not available for {address?.[UI_ADDRESS_FIELDS.STATE]}
          </p>
        )}
        <TextField
          name={UI_ADDRESS_FIELDS.POSTAL_CODE}
          label={'Zip code'}
          maxLength={5}
          onChange={setAddressField(UI_ADDRESS_FIELDS.POSTAL_CODE)}
          value={address[UI_ADDRESS_FIELDS.POSTAL_CODE]}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.searchButton}
          onClick={submit({ firstName, lastName, address })}
          disabled={!isValid()}
          loading={loading}
        >
          Search
        </Button>
      </div>
    </>
  );
};

Form.propTypes = {
  uiData: PropTypes.object,
  loading: PropTypes.bool,
  submit: PropTypes.func
};

export default Form;

import { createAction } from 'redux-actions';

export const ADD_POOL = 'ADD_POOL';
export const addPool = createAction(ADD_POOL);

export const DELETE_POOL = 'DELETE_POOL';
export const deletePool = createAction(DELETE_POOL);

export const UPDATE_POOL = 'UPDATE_POOL';
export const updatePool = createAction(UPDATE_POOL);

import React from 'react';
import PropTypes from 'prop-types';
import SelectAsync from 'react-select/async';
import { formatAddressObject } from 'woop-shared/origination/utils';
import { getAddressSuggestions } from '../../api/address';
import { formatAddressSuggestions, getEmptyAddressObject } from './utils';
import Prompt from '../Prompt';

const loadOptions = async (inputValue, callback) => {
  if (inputValue.length < 4) return [];
  const suggestions = await getAddressSuggestions(inputValue);
  const formattedData = formatAddressSuggestions(suggestions);
  callback(formattedData);
};

const AddressField = ({
  name,
  onChange,
  prompt = 'Address',
  showValidation,
  isValid,
  id,
  inputId,
  ...rest
}) => {
  return (
    <div>
      {prompt && <Prompt invalid={showValidation && !isValid}>{prompt}</Prompt>}
      <SelectAsync
        name={name}
        onChange={raw => {
          const addressObject = raw?.value || raw;
          const formattedAddress = addressObject
            ? formatAddressObject(addressObject)
            : getEmptyAddressObject();
          onChange(formattedAddress);
        }}
        placeholder={'Search...'}
        isClearable={true}
        loadOptions={(inputValue, callback) => loadOptions(inputValue, callback)}
        {...rest}
        id={id || `${name}-container`}
        inputId={inputId || `${name}-select`}
      />
    </div>
  );
};

export default AddressField;

AddressField.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  prompt: PropTypes.string,
  showValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  id: PropTypes.string,
  inputId: PropTypes.string
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../../../../components/TextField';
import AddressField from '../../../../../../components/AddressField';
import { CARD_FIELDS } from '../../constants';
import { formatCard, formatExpDate } from './utils';

const CreditCard = ({ data, update }) => {
  const handleAddressChange = value =>
    update({ ...data, [CARD_FIELDS.BILLING_ADDRESS.key]: value });

  const [val, setVal] = useState('');
  const handleInput = field => e => {
    // If removing character(s), do not format.
    if (field === CARD_FIELDS.CARD_NUMBER.key || field === CARD_FIELDS.CARD_EXPIRATION_DATE.key) {
      if (e.target.value?.length < val.length) {
        setVal(e.target.value);
        update({ ...data, [field]: e.target.value });
        return;
      }
      const formattedInput =
        field === CARD_FIELDS.CARD_NUMBER.key
          ? formatCard(e.target.value)
          : formatExpDate(e.target.value);
      setVal(formattedInput);
      update({ ...data, [field]: formattedInput });
      return;
    }
    update({ ...data, [field]: e.target.value });
  };

  return (
    <>
      {[
        CARD_FIELDS.NAME_ON_CARD,
        CARD_FIELDS.CARD_NUMBER,
        CARD_FIELDS.CARD_EXPIRATION_DATE,
        CARD_FIELDS.CARD_CVV
      ].map(({ key, label, validator, validationText, placeholder, maxLength }) => {
        return (
          <TextField
            key={key}
            name={key}
            label={label}
            value={data[key]}
            placeholder={placeholder || ''}
            maxLength={maxLength}
            onChange={handleInput(key)}
            isValid={validator(data[key])}
            validationText={validationText}
          />
        );
      })}

      <AddressField
        name={CARD_FIELDS.BILLING_ADDRESS.key}
        prompt={CARD_FIELDS.BILLING_ADDRESS.label}
        isValid={CARD_FIELDS.BILLING_ADDRESS.validator(data[CARD_FIELDS.BILLING_ADDRESS.key])}
        onChange={handleAddressChange}
      />
    </>
  );
};

CreditCard.propTypes = {
  data: PropTypes.object,
  update: PropTypes.func
};

export default CreditCard;

import { connect } from 'react-redux';
import CarrierSelect from './';
import { getCarriers } from '../../actions/carriers';

const mapStateToProps = ({ carriers }) => ({ carriers });

const mapDispatchToProps = dispatch => ({
  getCarriers: () => dispatch(getCarriers())
});

export default connect(mapStateToProps, mapDispatchToProps)(CarrierSelect);

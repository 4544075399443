import React, { useEffect, useMemo, useState } from 'react';
import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { GENERAL_FIELDS, QUOTE_REQUEST_FIELDS } from 'woop-shared/db/models';
import { useHistory } from 'react-router-dom';

import { HiArrowRight, HiOutlineSave } from 'react-icons/hi';
import { getPrimaryAddress } from 'woop-shared/origination/utils';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FOREIGN_KEYS } from 'woop-shared/db/models';
import Card from '../../components/Card';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import styles from './styles.module.css';
import { useModalControls } from '../../hooks/modal-controls';
import {
  submitQuoteRequest,
  getQuoteRequest,
  patchQuoteRequest,
  getQuoteApplication
} from '../../api/quoteset';
import { setQuoteRequest } from '../../actions/quote-request';
import RequestGroupCard from './components/RequestCard';
import RequestModal from './components/RequestModal';
import BundleManager from './components/BundleManager';
import {
  formatQuoteRequest,
  getQuoteRequestErrors,
  getRandomWaitingQuote,
  sortPropertyRequests,
  validateQuoteRequest
} from './utils';
import { setQuoteApplication } from '../../actions/quote-application';
import { PROPERTY_FIELDS } from '../../constants/origination';
import { snakeToTitleCase } from '../../utils/string';
import { setLoading } from '../../actions/loading';
import Loader from '../../components/Loader';
import { ROUTES, addIdToRoute } from '../../router/routes';
import { getPulls } from '../../actions/pulls';
import QuotingNav from '../../components/QuotingNav';
import LinkText from '../../components/LinkText';

const QuoteRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { quoteRequest, quoteApplication, loading } = useSelector(state => state);
  const [propertyRequests, autoRequests] = useMemo(
    () => formatQuoteRequest(quoteRequest),
    [quoteRequest]
  );
  const [visible, closing, toggleModal] = useModalControls();
  const [, setValid] = useState(false); // @todo: show invalid state before attempting to bridge.
  const [activeRequest, setActiveRequest] = useState();
  const [awaitingResult, setAwaitingResult] = useState();

  const { id } = useParams();

  useEffect(() => {
    // Fetch quote request
    dispatch(setLoading(true));
    getQuoteRequest(id).then(res => {
      dispatch(setQuoteRequest(res));
      // Maybe fetch quote application
      if (!quoteApplication.id) {
        dispatch(setLoading(true));
        getQuoteApplication(res[FOREIGN_KEYS.QUOTE_APP]).then(res => {
          dispatch(setQuoteApplication(res));
          dispatch(setLoading(false));
          if (res[FOREIGN_KEYS.USER]) {
            dispatch(getPulls(res[FOREIGN_KEYS.USER]));
          }
        });
      } else dispatch(setLoading(false));
    });
  }, []);

  useEffect(() => {
    setValid(validateQuoteRequest(quoteRequest));
  }, [quoteRequest]);

  const saveRequest = update => {
    dispatch(setLoading(true));
    return patchQuoteRequest(id, update).then(res => {
      dispatch(setQuoteRequest(res));
      dispatch(setLoading(false));
    });
  };

  const handleSave = () => {
    return saveRequest(quoteRequest);
  };

  const onModalClose = () => {
    toggleModal();
    setActiveRequest();
  };

  const onModalSave = request => {
    const updatedRequests = [...quoteRequest[QUOTE_REQUEST_FIELDS.REQUESTS]];
    const index = updatedRequests.findIndex(r => r.id === request.id);
    // Add or update request
    if (index >= 0) {
      updatedRequests[index] = request;
    } else updatedRequests.push(request);
    // Save
    saveRequest({ ...quoteRequest, requests: updatedRequests });
    onModalClose();
  };

  const handleFinalize = async () => {
    const errors = getQuoteRequestErrors(quoteRequest);
    if (errors.length)
      return alert(
        `The quote request contains the following errors:${errors.map(
          (err, i) => `\n  ${i + 1}. ${err}`
        )}`
      );

    dispatch(setLoading(true));
    await handleSave();
    setAwaitingResult(true);
    submitQuoteRequest(id)
      .then(res => {
        setLoading(false);
        history.push(addIdToRoute(ROUTES.QUOTE_RESULT, res.id));
      })
      .catch(err => {
        setLoading(false);
        setAwaitingResult(false);
        alert(`Could not submit quote request: ${err.message}`);
      });
  };

  const primaryAddress = quoteApplication ? getPrimaryAddress(quoteApplication) : null;

  return (
    <section>
      <Card className={styles.card}>
        <div className={styles.header}>
          <h3>Quote Requests</h3>
          <QuotingNav id={quoteApplication[GENERAL_FIELDS.ID]} />
        </div>
        <BundleManager />
      </Card>
      {autoRequests.length && (
        <RequestGroupCard
          title={'Auto'}
          requests={autoRequests}
          setActiveRequest={setActiveRequest}
          saveRequest={saveRequest}
          toggleModal={toggleModal}
        />
      )}
      {sortPropertyRequests(propertyRequests, quoteApplication).map(requests => {
        const propertyId = requests[0][QUOTE_REQUEST_FIELDS.PROPERTY_ID];
        const property = quoteApplication?.[ORIGINATION_FIELDS.PROPERTIES]?.find(
          p => p.id === propertyId
        );
        const propertyType = (property?.[PROPERTY_FIELDS.PROPERTY_TYPE] || '').split('_')[0];
        return (
          <RequestGroupCard
            key={propertyId}
            title={`PROPERTY - ${snakeToTitleCase(propertyType)}`}
            requests={requests}
            setActiveRequest={setActiveRequest}
            saveRequest={saveRequest}
            toggleModal={toggleModal}
          />
        );
      })}

      <Modal
        visible={visible}
        toggleModal={onModalClose}
        closing={closing}
        className={styles.modal}
      >
        <RequestModal
          request={activeRequest}
          onSave={onModalSave}
          primaryAddress={primaryAddress}
        />
      </Modal>
      <div className={styles.btnWrapper}>
        <Button
          tabIndex={0}
          onClick={handleSave}
          className={styles.saveButton}
          disabled={loading}
          loading={loading}
        >
          Save <HiOutlineSave />
        </Button>
        <Button onClick={handleFinalize} className={styles.quotesButton} loading={loading}>
          Get quotes
          <HiArrowRight />
        </Button>
        {quoteRequest.quoteResId && (
          <LinkText href={addIdToRoute(ROUTES.QUOTE_RESULT, quoteRequest.quoteResId)}>
            SEE LATEST RESULTS -&gt;
          </LinkText>
        )}
      </div>
      <Modal visible={awaitingResult}>
        <div className={styles.loadingModalContent}>
          <Loader className={styles.loader} />
          <div>
            <h2>Awaiting quote results...</h2>
            <p>This might take a minute.</p>
          </div>
          <i>
            <small>{getRandomWaitingQuote()}</small>
          </i>
        </div>
      </Modal>
    </section>
  );
};

export default QuoteRequest;

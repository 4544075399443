import React, { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { BUNDLED_QUOTE_FIELDS, GENERAL_FIELDS } from 'woop-shared/db/models';
import Select from '../../../../components/Select';
import Card from '../../../../components/Card';
import styles from './BundledQuotes.css';
import { getOptions, getQuoteOption, filterOptions } from './utils';
import { createBundledQuote, getBundleId } from '../../utils';
import LinkText from '../../../../components/LinkText';

const cx = classNames.bind(styles);

const BundledQuotes = ({
  bundledQuotes,
  quotes,
  updateBundle,
  deleteBundle,
  addBundle,
  carriers
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleUpdateBundle = (bundle, quoteIndex) => (_, value) => {
    const id = getBundleId(bundle);
    updateBundle(id, quoteIndex, value);
  };

  const handleDelete = bundle => {
    const id = getBundleId(bundle);
    deleteBundle({ id, saved: !!bundle[GENERAL_FIELDS.ID] });
  };

  const handleAdd = () => {
    const newBundle = createBundledQuote();
    addBundle(newBundle);
  };

  return (
    <Card className={cx('wrapper', { collapsed })} onClick={() => setCollapsed(false)}>
      <div className={styles.title}>
        <span>Bundled quotes</span>
        <LinkText icon={<FiPlus />} onClick={handleAdd}>
          Add
        </LinkText>
      </div>
      {bundledQuotes.map((bundle, index) => {
        const bundledQuoteIds = bundle?.[BUNDLED_QUOTE_FIELDS.QUOTE_IDS];
        const quoteId1 = bundledQuoteIds?.[0];
        const quoteId2 = bundledQuoteIds?.[1];
        const quoteId3 = bundledQuoteIds?.[2];

        const options = getOptions(quotes, carriers);
        return (
          <div key={index}>
            <div className={styles.bundleHeading}>
              <b className={styles.bundleLabel}>Bundle {index + 1}</b>
              <a className={styles.delete} onClick={() => handleDelete(bundle)}>
                Delete
              </a>
            </div>
            <div className={styles.selectWrapper}>
              <Select
                placeholder="Select Quote"
                options={filterOptions(options, bundledQuoteIds, quotes)}
                onChange={handleUpdateBundle(bundle, 0)}
                value={getQuoteOption(quoteId1, options)}
              ></Select>
              <Select
                placeholder="Select Quote"
                options={filterOptions(options, bundledQuoteIds, quotes)}
                onChange={handleUpdateBundle(bundle, 1)}
                value={getQuoteOption(quoteId2, options)}
              ></Select>
              <Select
                placeholder="Select Quote"
                options={filterOptions(options, bundledQuoteIds, quotes)}
                onChange={handleUpdateBundle(bundle, 2)}
                value={getQuoteOption(quoteId3, options)}
              ></Select>
            </div>
          </div>
        );
      })}
    </Card>
  );
};

export default BundledQuotes;

BundledQuotes.propTypes = {
  bundledQuotes: PropTypes.array,
  quotes: PropTypes.array,
  deleteBundle: PropTypes.func,
  updateBundle: PropTypes.func,
  addBundle: PropTypes.func,
  carriers: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
    })
  )
};

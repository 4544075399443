import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { APPLICANT_FIELDS, VEHICLE_FIELDS } from 'woop-shared/origination/fields';
import styles from '../../styles.css';
import { formatName, formatVehicle } from '../../utils';

const Table = ({ vehicles, applicants }) => {
  const formatDate = date =>
    date ? dayjs(date.toString().split('T')[0]).format('MM-DD-YYYY') : '';

  return (
    <div>
      <div className={styles.sectionHeader}>Applicants</div>
      <div className={styles.applicantTable}>
        <div className={styles.row}>
          <div className={styles.columnHeader}>First Name</div>
          <div className={styles.columnHeader}>Last Name</div>
          <div className={styles.columnHeader}>Gender</div>
          <div className={styles.columnHeader}>Date of Birth</div>
          <div className={styles.columnHeader}>DL#</div>
          <div className={styles.columnHeader}>DL State</div>
          <div className={styles.columnHeader}>DL Date</div>
          <div className={styles.columnHeader}>Residence Type</div>
          <div className={styles.columnHeader}>Months at Address</div>
        </div>
        {applicants?.map((applicant, i) => {
          return (
            <div className={styles.row} key={`applicant-${i}`}>
              <div>{applicant[APPLICANT_FIELDS.FIRST_NAME] || ''}</div>
              <div>{applicant[APPLICANT_FIELDS.LAST_NAME] || ''}</div>
              <div>{applicant[APPLICANT_FIELDS.GENDER] || ''}</div>
              <div>{formatDate(applicant[APPLICANT_FIELDS.DOB])}</div>
              <div>{applicant[APPLICANT_FIELDS.DRIVERS_LICENSE_NUMBER] || ''}</div>
              <div>{applicant[APPLICANT_FIELDS.DRIVERS_LICENSE_STATE] || ''}</div>
              <div>{formatDate(applicant[APPLICANT_FIELDS.DRIVERS_LICENSE_DATE])}</div>
              <div>{applicant[APPLICANT_FIELDS.RESIDENCE_TYPE] || ''}</div>
              <div>{applicant[APPLICANT_FIELDS.MONTHS_AT_ADDRESS] || ''}</div>
            </div>
          );
        })}
      </div>

      {vehicles?.length > 0 && <div className={styles.sectionHeader}>Vehicles</div>}
      {vehicles?.map((vehicle, i) => {
        return (
          <div key={`vehicle-${i}`}>
            <div className={styles.tableHeader}>{formatVehicle(vehicle)}</div>
            <div className={styles.vehicleTable}>
              <div className={styles.row}>
                <div className={styles.columnHeader}>VIN</div>
                <div>{vehicle[VEHICLE_FIELDS.VIN] || ''}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.columnHeader}>Ownership Status</div>
                <div>{vehicle[VEHICLE_FIELDS.VEHICLE_OWNERSHIP_STATUS] || ''}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.columnHeader}>Financing Company</div>
                <div>{vehicle[VEHICLE_FIELDS.FINANCING_LIEN_HOLDER] || ''}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.columnHeader}>Registered State</div>
                <div>{vehicle[VEHICLE_FIELDS.REGISTERED_STATE] || ''}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.columnHeader}>Vehicle Owner(s)</div>
                <div>{vehicle[VEHICLE_FIELDS.OWNER]?.map(formatName).join(', ')}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Table.propTypes = {
  vehicles: PropTypes.array,
  applicants: PropTypes.array
};

export default Table;

import { connect } from 'react-redux';
import FinalizeOriginationModal from '.';
import { saveQuoteset, uploadQuotes } from '../../actions/quoteset';

const mapStateToProps = ({ error, loading }) => {
  return {
    error,
    loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveQuoteset: () => {
      dispatch(saveQuoteset());
    },
    uploadQuotes: payload => {
      dispatch(uploadQuotes(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalizeOriginationModal);

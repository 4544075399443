import { GET_PARTNER_SUCCESS } from '../actions/partners';

function partner(state = {}, action) {
  switch (action.type) {
    case GET_PARTNER_SUCCESS: {
      return action.payload;
    }
    default:
      return state;
  }
}

export default partner;

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames/bind';
import styles from './styles.module.css';

const cx = classnames.bind(styles);

const BadgeButton = ({ onClick, color = 'neutral', children, disabled }) => {
  return (
    <div className={cx('wrapper', color, { disabled })} onClick={disabled ? () => {} : onClick}>
      {children}
    </div>
  );
};

BadgeButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default BadgeButton;

import {
  HiExternalLink,
  HiOutlineLocationMarker,
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineSave,
  HiOutlineTag,
  HiOutlineTruck,
  HiOutlineUsers
} from 'react-icons/hi';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MdEdit, MdEditOff } from 'react-icons/md';
import {
  getAddressComposite,
  getCoverageTypes,
  getPrimaryFirstName,
  getPrimaryLastName
} from 'woop-shared/utils';
import { PROMPT_GROUP_NAMES, PROMPT_NAMES } from 'woop-shared/prompts/names';
import VertaforeLogo from '../../../../assets/icons/vertafore.svg';
import CanopyLogo from '../../../../assets/icons/small-canopy-logo.svg';
import TransunionLogo from '../../../../assets/icons/transunion-logo.svg';
import styles from './styles.css';
import Card from '../../../../components/Card';
import { addIdToRoute, ROUTES } from '../../../../router/routes';
import { verifyPw } from '../EditCard/utils';
import { ENVS } from '../../../../constants/app';
import { mapVertafore, postQuoteset } from '../../../../actions/quoteset';
import { mapCanopy } from '../../../../actions/journeys';
import VertaforeModal from '../VertaforeModal';
import { useModalControls } from '../../../../hooks/modal-controls';

const SummaryCard = ({
  journeyId,
  uiData,
  quotesetId,
  editable,
  setEdit,
  edit,
  canopy,
  submitted
}) => {
  const [visible, closing, toggleModal] = useModalControls();
  const dispatch = useDispatch();

  const firstName = getPrimaryFirstName(uiData);
  const lastName = getPrimaryLastName(uiData);

  const coverageTypes = getCoverageTypes(uiData).join(' + ');
  const addressComposite = getAddressComposite(uiData?.[PROMPT_NAMES.ADDRESS]);
  const unitNumber = uiData[PROMPT_NAMES.UNIT_NUMBER];

  const submittedDate = submitted && new Date(submitted).toLocaleString();

  const numVehicles = uiData[PROMPT_GROUP_NAMES.VEHICLES]?.length || 0;
  const numDrivers = uiData[PROMPT_GROUP_NAMES.DRIVERS]?.length || 0;
  const hasTradeIn = uiData[PROMPT_GROUP_NAMES.TRADE_INS]?.length || 0;

  function handleEdit() {
    if (edit) return setEdit(!edit); // Cancel edit.

    if (process.env.ENV !== ENVS.PROD) return setEdit(true); // Don't require a PW for non-prod envs.

    const pw = prompt('Password');
    if (!pw) return; // If the user cancels or enters nothing, return.
    if (verifyPw(pw)) return setEdit(true);
    else handleEdit();
  }

  function handleVertafore() {
    quotesetId
      ? dispatch(mapVertafore({ quotesetId, callback: toggleModal }))
      : confirm(
          'This application has no associated quote case. Please note this action will create a new quote case in CRM.'
        ) && dispatch(postQuoteset());
  }

  function handleMapCanopy() {
    if (confirm('Are you sure you want to merge Canopy data into the journey?')) {
      const refresh = () => window.location.reload(true);
      dispatch(
        mapCanopy({
          journeyId,
          callback: refresh
        })
      );
    }
  }

  function handleTransunion() {
    window.open(addIdToRoute(ROUTES.TRANSUNION, journeyId), '_blank');
  }

  return (
    <>
      <Card className={styles.wrapper}>
        <div className={styles.actions}>
          <div className={styles.section}>
            <div className={styles.action} onClick={handleVertafore}>
              <VertaforeLogo />
              <span>Run Vertafore</span>
            </div>

            {!!canopy?.length && (
              <div className={styles.action} onClick={handleMapCanopy}>
                <CanopyLogo className={styles.rounded} />
                <span>Merge Canopy</span>
              </div>
            )}
          </div>

          <div className={styles.section}>
            <div className={styles.action} onClick={handleTransunion}>
              <TransunionLogo />
              <span>View TU data</span>
              <HiExternalLink className={styles.muted} />
            </div>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.heading}>
            {firstName} {lastName}
          </div>

          <div className={styles.data}>
            <div className={styles.title}>Applicant</div>

            <div className={styles.columns}>
              <div className={styles.column}>
                <div className={styles.row}>
                  <HiOutlineLocationMarker title="Full address" />
                  <div>
                    {addressComposite} {unitNumber ? ' - Unit: ' + unitNumber : ''}
                  </div>
                </div>
              </div>

              <div className={styles.column}>
                <div className={styles.row}>
                  <HiOutlineMail title="Email" />
                  <div>{uiData[PROMPT_NAMES.EMAIL]}</div>
                </div>
                <div className={styles.row}>
                  <HiOutlinePhone title="Phone" />
                  <div>{uiData[PROMPT_NAMES.PHONE]}</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.data}>
            <div className={styles.title}>Coverage</div>

            <div className={styles.columns}>
              <div className={styles.column}>
                <div className={styles.row}>
                  <HiOutlineTag title="Coverage types" />
                  <div>{coverageTypes}</div>
                </div>
                <div className={styles.row}>
                  <HiOutlineSave title="Applicant has trade-in?" />
                  <div>{hasTradeIn ? 'Has trade-in' : 'No trade-in'}</div>
                </div>
              </div>

              <div className={styles.column}>
                <div className={styles.row}>
                  <HiOutlineTruck title="Number of vehicles" />
                  <div>
                    {numVehicles} {numVehicles === 1 ? 'vehicle' : 'vehicles'}
                  </div>
                </div>
                <div className={styles.row}>
                  <HiOutlineUsers title="Number of drivers" />
                  <div>
                    {numDrivers} {numDrivers === 1 ? 'driver' : 'drivers'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          <div className={styles.section}>
            <div className={styles.dates}>
              {submittedDate && (
                <div>
                  Submitted: <span>{submittedDate}</span>
                </div>
              )}
            </div>
          </div>

          <div className={styles.section}>
            {editable && (
              <div className={styles.action} onClick={handleEdit}>
                <span>{edit ? 'Discard edits' : 'Edit'}</span>
                {edit ? (
                  <MdEditOff className={styles.muted} />
                ) : (
                  <MdEdit className={styles.muted} />
                )}
              </div>
            )}
          </div>
        </div>
      </Card>

      <VertaforeModal visible={visible} closing={closing} toggleModal={toggleModal} />
    </>
  );
};

SummaryCard.propTypes = {
  journeyId: PropTypes.string,
  uiData: PropTypes.object,
  originationId: PropTypes.any,
  quotesetId: PropTypes.string,
  editable: PropTypes.bool,
  setEdit: PropTypes.func,
  edit: PropTypes.bool,
  canopy: PropTypes.array,
  userId: PropTypes.string,
  ivReportId: PropTypes.string,
  submitted: PropTypes.any
};

export default SummaryCard;

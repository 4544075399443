import { GET_REPORT_SUCCESS, SET_REPORT_FIELD } from '../actions/reports';

function report(state = {}, action) {
  switch (action.type) {
    case GET_REPORT_SUCCESS:
      return action.payload;
    case SET_REPORT_FIELD: {
      const { key, value } = action.payload;
      if (value instanceof Object) {
        return {
          ...state,
          [key]: { ...state[key], ...value }
        };
      }
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
}

export default report;

import axios from 'axios';
import { getIvReportsEndpoint } from '../utils/api';

const IV_REPORTS_URI = getIvReportsEndpoint();

export async function getIvReport({ reportId }) {
  const uri = `${IV_REPORTS_URI}/${reportId}`;
  const { data } = await axios.get(uri);
  return data;
}

export async function patchIvReport({ reportId, reportData }) {
  const uri = `${IV_REPORTS_URI}/${reportId}`;
  const { data } = await axios.patch(uri, reportData);
  return data;
}

export async function sendIvReport({ reportId }) {
  const uri = `${IV_REPORTS_URI}/${reportId}/send`;
  const { data } = await axios.post(uri);
  return data;
}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import { ORIGINATION_FIELDS, CLAIM_FIELDS, PROPERTY_FIELDS } from 'woop-shared/origination/fields';
import {
  CLAIMS_LOB,
  CLAIM_DESCRIPTIONS,
  PROPERTY_INCIDENT_DESCS
} from 'woop-shared/origination/enums';
import { formatAddress } from 'woop-shared/origination/utils';
import Select from '../../../components/Select';
import { createOptionsFromEnum } from '../../../components/Select/utils';
import DateField from '../../../components/DateField';
import MoneyField from '../../../components/MoneyField';
import styles from '../styles.module.css';
import Prompt from '../../../components/Prompt';

const lobOptions = createOptionsFromEnum(CLAIMS_LOB);
const autoClaimOptions = createOptionsFromEnum(CLAIM_DESCRIPTIONS);
const propertyClaimOptions = createOptionsFromEnum(PROPERTY_INCIDENT_DESCS);
const typeOptions = [...autoClaimOptions, ...propertyClaimOptions];

const formatPropertyOptions = (properties, addresses) =>
  properties
    .map(p => {
      const address = addresses?.find(a => a.id === p[PROPERTY_FIELDS.ADDRESS_ID]);

      return address
        ? {
            value: p.id,
            label: formatAddress(address)
          }
        : null;
    })
    .filter(Boolean);

const ClaimsForm = ({
  entity,
  onInput,
  onChange,
  context: { quoteApplication },
  validationState
}) => {
  const claim = entity || {};
  const showValidation = validationState.allValid === false;

  const properties = quoteApplication[ORIGINATION_FIELDS.PROPERTIES];
  const addresses = quoteApplication[ORIGINATION_FIELDS.ADDRESSES];

  const propertyOptions = useMemo(
    () => formatPropertyOptions(properties, addresses),
    [properties, addresses]
  );

  return (
    <>
      <Select
        autoFocus
        prompt={'Line of business'}
        name={CLAIM_FIELDS.LINE_OF_BUSINESS}
        showValidation={showValidation}
        isValid={validationState?.[CLAIM_FIELDS.LINE_OF_BUSINESS]?.isValid}
        options={lobOptions}
        onChange={onChange}
        value={
          lobOptions.find(({ value }) => value === claim[CLAIM_FIELDS.LINE_OF_BUSINESS]) || null
        }
      />

      {claim[CLAIM_FIELDS.LINE_OF_BUSINESS] === CLAIMS_LOB.PROPERTY && (
        <Select
          prompt={'Property'}
          name={CLAIM_FIELDS.PROPERTY_ID}
          showValidation={showValidation}
          isValid={validationState?.[CLAIM_FIELDS.PROPERTY_ID]?.isValid}
          options={propertyOptions}
          onChange={onChange}
          value={
            propertyOptions.find(({ value }) => value === claim[CLAIM_FIELDS.PROPERTY_ID]) || null
          }
        />
      )}

      <DateField
        name={CLAIM_FIELDS.DATE}
        showValidation={showValidation}
        isValid={validationState?.[CLAIM_FIELDS.DATE]?.isValid}
        value={claim[CLAIM_FIELDS.DATE]}
        prompt="Date"
        onChange={onInput}
      />

      <Select
        prompt={'Type'}
        name={CLAIM_FIELDS.TYPE}
        showValidation={showValidation}
        isValid={validationState?.[CLAIM_FIELDS.TYPE]?.isValid}
        options={
          !claim[CLAIM_FIELDS.LINE_OF_BUSINESS]
            ? []
            : claim[CLAIM_FIELDS.LINE_OF_BUSINESS] === CLAIMS_LOB.PROPERTY
            ? propertyClaimOptions
            : autoClaimOptions
        }
        onChange={onChange}
        value={typeOptions.find(({ value }) => value === claim[CLAIM_FIELDS.TYPE]) || null}
      />

      <MoneyField
        label="Amount"
        enforceInteger
        name={CLAIM_FIELDS.AMOUNT}
        showValidation={showValidation}
        isValid={validationState?.[CLAIM_FIELDS.AMOUNT]?.isValid}
        value={claim[CLAIM_FIELDS.AMOUNT]}
        onChange={onChange}
      />

      <div className={styles.checkboxWrapper}>
        <Checkbox
          name={CLAIM_FIELDS.CATASTROPHIC}
          checked={claim[CLAIM_FIELDS.CATASTROPHIC] ?? false}
          onChange={(_, val) => onChange(CLAIM_FIELDS.CATASTROPHIC, val)}
          className={styles.checkboxWrapper}
        />
        <Prompt>Catastrophic</Prompt>
      </div>
    </>
  );
};

ClaimsForm.propTypes = {
  entity: PropTypes.object,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  context: PropTypes.object,
  validationState: PropTypes.object
};

export default ClaimsForm;

import { GET_PULLS_SUCCESS } from '../actions/pulls';

function pulls(state = [], action) {
  switch (action.type) {
    case GET_PULLS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export default pulls;

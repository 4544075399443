import React from 'react';
import PropTypes from 'prop-types';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { COVERAGE_KEYS, COVERAGE_LABELS } from 'woop-shared/quotes/enums';
import { QUOTE_FIELDS } from 'woop-shared/db/models';
import MoneyField from '../MoneyField';
import { getCoverageFields } from './constants';
import Prompt from '../Prompt';
import styles from './styles.css';
import TemplateModal from '../../pages/CreateQuoteset/components/TemplateModal';
import { useModalControls } from '../../hooks/modal-controls';

/**
 *
 * @param {string} type A unique identifier for
 * @param {Array<object>} coverages
 * @returns {string} An amount
 */
function getCoverageAmount(type, coverages) {
  // Find by type enumeration
  let coverage = coverages?.find(c => c?.[COVERAGE_KEYS.TYPE] === type);

  // Legacy fallback, for coverages saved using their labels (Before 02/01/2021) @todo - remove
  if (!coverage) {
    coverage = coverages?.find(cov => {
      const label = cov?.[COVERAGE_KEYS.TYPE];
      // Get type from label
      const _type = Object.entries(COVERAGE_LABELS).find(([, value]) => label === value)?.[0];
      return type === _type;
    });
  }

  return coverage?.[COVERAGE_KEYS.AMOUNT];
}

const CoverageFields = ({ quote, onChange, updateQuote, showValidation, stateAbbrev }) => {
  const [visible, closing, toggleModal] = useModalControls(false);

  const handleFieldChange = index => (covName, value) => {
    const covObject = {
      [COVERAGE_KEYS.TYPE]: covName,
      [COVERAGE_KEYS.AMOUNT]: value
    };
    onChange(index, covObject);
  };

  const coverageHasVal = type =>
    !!quote?.[QUOTE_FIELDS.COVERAGES]?.find(
      coverage => coverage?.[COVERAGE_KEYS.TYPE] === type && coverage?.[COVERAGE_KEYS.AMOUNT] >= 0
    );

  const quoteCovType = quote?.[QUOTE_FIELDS.COVERAGE_TYPE];
  const covFields = getCoverageFields(quoteCovType, stateAbbrev);

  const isUmbrella = quoteCovType === COVERAGE_TYPES.UMBRELLA;

  return (
    covFields.length > 0 && (
      <div className={styles.wrapper}>
        <div className={styles.prompt}>
          <Prompt>Coverages</Prompt>
          {!isUmbrella && (
            <a className={styles.link} onClick={toggleModal}>
              Apply template
            </a>
          )}
        </div>
        {covFields.map((coverageKey, index) => (
          <MoneyField
            key={coverageKey}
            name={coverageKey}
            placeholder="$"
            label={COVERAGE_LABELS[coverageKey]}
            value={getCoverageAmount(coverageKey, quote?.[QUOTE_FIELDS.COVERAGES])}
            onChange={handleFieldChange(index)}
            showValidation={showValidation}
            isValid={coverageHasVal(coverageKey)}
          />
        ))}

        <TemplateModal
          visible={visible}
          closing={closing}
          toggleModal={toggleModal}
          stateAbbrev={stateAbbrev}
          quote={quote}
          updateQuote={updateQuote}
        />
      </div>
    )
  );
};

export default CoverageFields;

CoverageFields.propTypes = {
  quote: PropTypes.object,
  onChange: PropTypes.func,
  updateQuote: PropTypes.func,
  showValidation: PropTypes.bool,
  stateAbbrev: PropTypes.string
};

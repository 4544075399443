// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CarrierFees__wrapper--2fLC4{margin-bottom:40px}.CarrierFees__wrapper--2fLC4>p{margin:20px 0}.CarrierFees__wrapper--2fLC4 .CarrierFees__fees--24ZhM{display:grid;grid-template-columns:1fr 1fr;grid-gap:20px}", ""]);
// Exports
exports.locals = {
	"wrapper": "CarrierFees__wrapper--2fLC4",
	"fees": "CarrierFees__fees--24ZhM"
};
module.exports = exports;

import PropTypes from 'prop-types';
import React from 'react';
import { HiOutlineCheck, HiOutlineExclamationCircle } from 'react-icons/hi';
import classnames from 'classnames/bind';
import styles from './styles.css';

const cx = classnames.bind(styles);

const Badge = ({ complete, iconMode }) => {
  return (
    <div className={cx('wrapper', { complete, iconMode })}>
      {complete ? (
        iconMode ? (
          <HiOutlineCheck title={'Complete'} />
        ) : (
          'Complete'
        )
      ) : iconMode ? (
        <HiOutlineExclamationCircle title={'Incomplete'} />
      ) : (
        'Incomplete'
      )}
    </div>
  );
};

Badge.propTypes = {
  complete: PropTypes.bool,
  iconMode: PropTypes.bool
};

export default Badge;

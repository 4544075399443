import React from 'react';
import { VEHICLE_FIELDS, ADDRESS_FIELDS } from 'woop-shared/origination/fields';
import { getDrivers, getVehicleName } from 'woop-shared/origination/utils';
import { formatMoney } from 'woop-shared/quotes/utils';
import { CARRIERS } from 'woop-shared/canopy/carriers';
import { FiExternalLink } from 'react-icons/fi';
import { FIELDS } from 'woop-shared/canopy/enums';
import { capitalize } from 'woop-shared/utils';
import dayjs from 'dayjs';
import { convertToColumn } from '../../../components/Table2/utils';
import { camelToTitleCase } from '../../../utils/string';
import {
  PROPERTY_FIELDS,
  CLAIM_FIELDS,
  INCIDENT_FIELDS,
  APPLICANT_FIELDS,
  AUTO_FIELDS,
  WITH_CANOPY_POLICY
} from '../constants';
import { getPolicies } from '../../../utils/canopy';
import { ROUTES, addIdToRoute } from '../../../router/routes';
import LinkText from '../../../components/LinkText';

const NAME_COLUMN = {
  field: 'Name',
  headerName: 'Name',
  width: 200,
  valueGetter: params =>
    `${params.row[APPLICANT_FIELDS.FIRST_NAME] || ''} ${
      params.row[APPLICANT_FIELDS.LAST_NAME] || ''
    }`
};

const VEHICLE_NAME_COLUMN = {
  field: 'Name',
  headerName: 'Name',
  width: 200,
  valueGetter: params => getVehicleName(params.row)
};

const CANOPY_POLICY_COLUMN = {
  field: WITH_CANOPY_POLICY.CANOPY_POLICY,
  headerName: 'Canopy Policy',
  width: 300,
  renderCell: params => params.value
};

export function getApplicantColumns() {
  return [
    NAME_COLUMN,
    {
      field: APPLICANT_FIELDS.APPLICANT_TYPE,
      headerName: 'Type',
      width: 200,
      valueGetter: params => params.row[APPLICANT_FIELDS.APPLICANT_TYPE]?.join(', ')
    },
    {
      field: APPLICANT_FIELDS.DOB,
      width: 160,
      valueGetter: params => {
        const date = params.row[APPLICANT_FIELDS.DOB];
        return date ? dayjs(params.row[APPLICANT_FIELDS.DOB]).format('MM/DD/YYYY') : '';
      }
    },
    { field: APPLICANT_FIELDS.RELATIONSHIP, headerName: 'Relationship', width: 160 },
    { field: APPLICANT_FIELDS.GENDER },
    {
      field: APPLICANT_FIELDS.EXCLUDED,
      width: 120,
      valueGetter: params => (params.row[APPLICANT_FIELDS.EXCLUDED_REASON] ? 'YES' : '-')
    }
  ];
}

export function getVehicleColumns() {
  const otherFields = [VEHICLE_FIELDS.VIN].map(convertToColumn);
  return [
    VEHICLE_NAME_COLUMN,
    {
      field: VEHICLE_FIELDS.EXCLUDED,
      width: 160,
      valueGetter: params => (params.row[VEHICLE_FIELDS.EXCLUDED] ? 'YES' : '-')
    },
    ...otherFields
  ];
}

export function getPropertyColumns() {
  return [
    {
      field: PROPERTY_FIELDS.PROPERTY_TYPE,
      headerName: 'Type'
    },
    {
      field: PROPERTY_FIELDS.DISPLAY_ADDRESS,
      headerName: 'Address',
      width: 450
    },
    {
      field: PROPERTY_FIELDS.DISPLAY_UNIT_NUMBER,
      headerName: 'Unit'
    },
    CANOPY_POLICY_COLUMN
  ];
}

export function getAutoCoverageColumns() {
  return [
    {
      field: AUTO_FIELDS.CURRENT_INSURER,
      headerName: 'Current Insurer',
      width: 160,
      valueGetter: params => CARRIERS[params.row[AUTO_FIELDS.CURRENT_INSURER]]?.name
    },
    {
      field: AUTO_FIELDS.CURRENT_POLICY_EXPIRATION,
      headerName: 'Policy Expiration',
      width: 180
    },
    {
      field: AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_PERSON,
      headerName: 'BI Per Person',
      width: 160,
      valueGetter: params => formatMoney(params.row[AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_PERSON])
    },
    {
      field: AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_ACCIDENT,
      headerName: 'BI Per Accident',
      width: 160,
      valueGetter: params => formatMoney(params.row[AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_ACCIDENT])
    },
    CANOPY_POLICY_COLUMN
  ];
}

export function getAutoIncidentColumns() {
  return [
    {
      field: INCIDENT_FIELDS.INCIDENT_DATE,
      headerName: 'Date',
      width: 180
    },
    {
      field: INCIDENT_FIELDS.TYPE,
      headerName: 'Type',
      width: 160
    },
    {
      field: INCIDENT_FIELDS.DRIVER_NAME,
      headerName: 'Driver',
      width: 180
    }
  ];
}

export function getClaimColumns() {
  return [
    {
      field: CLAIM_FIELDS.DATE,
      headerName: 'Date',
      width: 160
    },
    {
      field: CLAIM_FIELDS.LABEL,
      headerName: 'Policy',
      width: 200
    },
    {
      field: CLAIM_FIELDS.TYPE,
      headerName: 'Type',
      width: 160
    },
    {
      field: CLAIM_FIELDS.AMOUNT,
      headerName: 'Amount',
      width: 160,
      valueGetter: params => formatMoney(params.row[CLAIM_FIELDS.AMOUNT])
    }
  ];
}

export function getAddressColumns() {
  return [
    {
      field: ADDRESS_FIELDS.ADDRESS_TYPE,
      headerName: 'Type'
    },
    {
      field: ADDRESS_FIELDS.COMPOSITE,
      headerName: 'Address',
      width: 340
    },
    {
      field: ADDRESS_FIELDS.UNIT_NUMBER,
      headerName: 'Unit'
    }
  ];
}

export function getErrorList(validationState) {
  const list = [];
  Object.entries(validationState).forEach(([key, state]) => {
    if (!state.isValid) list.push(`Missing ${camelToTitleCase(key)}`);
  });
  return list;
}

export function formatName(applicant) {
  return [applicant?.[APPLICANT_FIELDS.FIRST_NAME], applicant?.[APPLICANT_FIELDS.LAST_NAME]]
    .filter(Boolean)
    .join(' ');
}

export function getDriverOptions(quoteApp) {
  return getDrivers(quoteApp).map(d => ({
    value: d.id,
    label: formatName(d)
  }));
}

export function getCurrentPolicy(policyNumber, pulls) {
  return pulls
    ? getPolicies(pulls).find(p => p[FIELDS.POLICY.CARRIER_POLICY_NUMBER] === policyNumber)
    : null;
}

export function getPolicyLink(policyNumber, pulls) {
  const policy = getCurrentPolicy(policyNumber, pulls);

  if (!policy) return null;

  return (
    <LinkText
      href={addIdToRoute(ROUTES.POLICY, policy[FIELDS.POLICY.POLICY_ID])}
      external
      icon={<FiExternalLink />}
    >
      {CARRIERS[policy[FIELDS.POLICY.CARRIER_NAME]]?.name} -{' '}
      {capitalize(policy[FIELDS.POLICY.POLICY_TYPE])}
    </LinkText>
  );
}

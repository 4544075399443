import { connect } from 'react-redux';
import OriginatorSelect from '.';
import { getOriginators } from '../../actions/originators';
import { getOriginatorsByAccountId } from './utils';

const mapStateToProps = ({ originators }, { partnerAccountId }) => {
  const filteredOriginators = getOriginatorsByAccountId(originators, partnerAccountId);
  return {
    originators: filteredOriginators
  };
};

const mapDispatchToProps = dispatch => ({
  getOriginators: () => dispatch(getOriginators())
});

export default connect(mapStateToProps, mapDispatchToProps)(OriginatorSelect);

import { connect } from 'react-redux';
import ApplicantSearch from './';
import { searchApplicants } from '../../actions/journeys';

const mapStateToProps = ({ error, loading }) => {
  return {
    error,
    loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchApplicants: params => dispatch(searchApplicants(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantSearch);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FIELDS, POLICY_TYPES } from 'woop-shared/canopy/enums';
import styles from './styles.css';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import DateField from '../../../../components/DateField';
import Select from '../../../../components/Select';
import { createSelectOption } from '../../../../components/Select/utils';
import { formatDateInputValue } from '../../../../components/DateField/utils';
import { getDate, getDriver, getSubtype, getType } from '../InsuranceHistory/utils';
import { formatCents, getDrivers } from '../../utils';
import MoneyField from '../../../../components/MoneyField';
import {
  createDriverSelectOption,
  getSubtypeOptions,
  INCIDENT_TYPE_OPTIONS,
  updateInsuranceHistory
} from './utils';
import { INCIDENT_TYPES } from '../InsuranceHistory/constants';

const EditInsuranceHistory = ({ policy, item, refreshPolicy, visible, closing, toggleModal }) => {
  const [loading, setLoading] = useState();
  const drivers = getDrivers(policy) || [];
  const findDriver = id => drivers.find(d => d[FIELDS.DRIVER.ID] === id);
  const driverOptions = drivers.map(createDriverSelectOption).filter(Boolean);
  const isAuto = policy?.policy_type === POLICY_TYPES.AUTO;

  const [type, setType] = useState(isAuto ? getType(item) : INCIDENT_TYPES.CLAIM);
  const [subtype, setSubtype] = useState(getSubtype(item));
  const [date, setDate] = useState(item && formatDateInputValue(getDate(item)));
  const [driverId, setDriverId] = useState(getDriver(policy, item)?.[FIELDS.DRIVER.ID]);
  const [payout, setPayout] = useState(formatCents(item?.[FIELDS.CLAIM.PAYOUT_CENTS]));

  useEffect(() => {
    setType(isAuto ? getType(item) : INCIDENT_TYPES.CLAIM);
    setSubtype(getSubtype(item));
    setDate(item && formatDateInputValue(getDate(item)));
    setDriverId(getDriver(policy, item)?.[FIELDS.DRIVER.ID]);
    setPayout(formatCents(item?.[FIELDS.CLAIM.PAYOUT_CENTS]));
  }, [item]);

  const clearFields = () => {
    [setType, setSubtype, setDate, setDriverId, setPayout].forEach(f => f());
    if (!isAuto) setType(INCIDENT_TYPES.CLAIM);
  };

  const handleSetType = (_, value) => {
    if (value === type) return;
    setType(value);
    setSubtype(null);
  };
  const handleSetSubtype = (_, value) => setSubtype(value);
  const handleSetDriverId = (_, value) => setDriverId(value);

  const save = () => {
    if (!subtype) {
      alert('Sub-type is required!');
      return;
    }

    setLoading(true);

    const updates = {
      type,
      subtype,
      date,
      driverId,
      payout
    };

    updateInsuranceHistory({ policy, item, updates })
      .then(() => refreshPolicy())
      .then(() => {
        setLoading(false);
        toggleModal();
        clearFields();
      });
  };

  return (
    visible && (
      <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
        <div>{`${item ? 'Edit' : 'Create'} Claim${isAuto ? ', Accident, or Violation' : ''}`}</div>
        <div className={styles.form}>
          {item || !isAuto ? (
            <>
              <div className={styles.typeLabel}>Type</div>
              <div className={styles.typeValue}>
                {isAuto ? getType(item) : INCIDENT_TYPES.CLAIM}
              </div>
            </>
          ) : (
            <Select
              prompt={'Type'}
              name={'type'}
              placeholder={'Type'}
              value={createSelectOption(type)}
              options={INCIDENT_TYPE_OPTIONS}
              onChange={handleSetType}
            />
          )}
          <Select
            prompt={'Subtype'}
            name={'subtype'}
            placeholder={'Subtype'}
            value={createSelectOption(subtype)}
            options={getSubtypeOptions(type, isAuto)}
            onChange={handleSetSubtype}
          />
          <DateField
            name={'Date'}
            prompt={'Date'}
            onChange={e => setDate(e.target.value)}
            value={date}
          />
          {type === INCIDENT_TYPES.CLAIM ? (
            <MoneyField
              name="payout"
              placeholder="$"
              label={'Payout'}
              value={payout}
              onChange={(_, value) => setPayout(value)}
            />
          ) : (
            isAuto && (
              <Select
                prompt={'Driver'}
                name={'driver'}
                placeholder={'Driver'}
                value={createDriverSelectOption(findDriver(driverId))}
                options={driverOptions}
                onChange={handleSetDriverId}
              />
            )
          )}
        </div>
        <Button className={styles.modalButton} loading={loading} onClick={save}>
          Save
        </Button>
      </Modal>
    )
  );
};

EditInsuranceHistory.propTypes = {
  policy: PropTypes.object,
  item: PropTypes.object,
  refreshPolicy: PropTypes.func,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func
};

export default EditInsuranceHistory;

import { connect } from 'react-redux';
import PropertyIncidents from '.';
import {
  addPropertyIncident,
  deletePropertyIncident,
  updatePropertyIncident
} from '../../actions/property-incidents';

const mapStateToProps = ({ propertyIncidents }) => {
  return {
    propertyIncidents: Object.values(propertyIncidents)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addPropertyIncident: propertyIncident => dispatch(addPropertyIncident(propertyIncident)),
    deletePropertyIncident: id => dispatch(deletePropertyIncident(id)),
    updatePropertyIncident: (id, updateObj) => dispatch(updatePropertyIncident({ id, updateObj }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyIncidents);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.css';
import MaybeError from '../MaybeError';

const cx = classNames.bind(styles);

const Table = ({ rows, className, spaceBetween = false }) => {
  return (
    <div className={cx('table', className, { spaceBetween })}>
      {rows.map((row, index) => {
        const label = row?.label;
        const labelLink = row?.labelLink;
        const value = row?.value;
        const required = !!row?.required;
        return (
          <div key={label + index}>
            <div>
              <span>{label}</span>
              &nbsp;
              {labelLink}
            </div>
            <div>
              <MaybeError
                isValid={!required || !!(required && value)}
                errorText={`Missing ${label.toLowerCase()}`}
              >
                {value}
              </MaybeError>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      required: PropTypes.bool
    })
  ),
  className: PropTypes.string,
  spaceBetween: PropTypes.bool
};

export default Table;

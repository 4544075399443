import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { hasVal } from 'woop-shared/utils';
import WithFloatingLabel from '../../enhancers/WithFloatingLabel';
import Prompt from '../Prompt';
import styles from './TextField.css';

const cx = classnames.bind(styles);

// eslint-disable-next-line react/display-name
const TextField = forwardRef(
  (
    {
      name,
      isValid = true,
      prompt,
      label,
      validationText = 'This field is required.',
      value,
      showValidation,
      noUnderline,
      id,
      ...rest
    },
    ref
  ) => {
    const [wasBlurred, setWasBlurred] = useState(false);
    const [focus, setFocus] = useState(false);
    const hasValue = hasVal(value);
    return (
      <div>
        {prompt && <Prompt>{prompt}</Prompt>}
        <WithFloatingLabel
          label={label}
          hasValue={hasValue}
          invalid={(showValidation || wasBlurred) && !isValid}
          hasFocus={focus}
          validationText={validationText}
          noUnderline={noUnderline}
        >
          <input
            ref={ref}
            type="text"
            name={name}
            className={cx('input', { hidePlaceholder: !!label })}
            value={hasValue ? value : ''}
            onBlur={() => {
              setWasBlurred(true);
              setFocus(false);
            }}
            onFocus={() => setFocus(true)}
            {...rest}
            id={id}
          />
        </WithFloatingLabel>
      </div>
    );
  }
);

export default TextField;

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  prompt: PropTypes.string,
  isValid: PropTypes.bool,
  showValidation: PropTypes.bool,
  noUnderline: PropTypes.bool,
  validationText: PropTypes.string,
  id: PropTypes.string
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PAYMENT_METHODS, PREMIUM_FIELDS } from 'woop-shared/quotes/enums';
import { FOREIGN_KEYS, GENERAL_FIELDS, MORTGAGE_FIELDS, QUOTE_FIELDS } from 'woop-shared/db/models';
import { stripNonDigits } from 'woop-shared/quotes/utils';
import { postBinding } from '../../../../api/bindings';
import styles from '../../CreateQuoteset.css';
import Modal from '../../../../components/Modal';
import Select from '../../../../components/Select';
import { createSelectOptions, createSelectOption } from '../../../../components/Select/utils';
import { getQuoteLabel } from '../../utils';
import { uniqBy } from '../../../Policy/utils';
import Button from '../../../../components/Button';
import CreditCard from './components/CreditCard';
import Escrow from './components/Escrow';
import ACH from './components/ACH';
import { ACH_FIELDS, CARD_FIELDS, ESCROW_FIELDS } from './constants';
import { error } from '../../../../utils/logger';
import { mapAddress } from './utils';

const PaymentModal = ({ visible, closing, toggleModal }) => {
  const { quotesetId } = useParams();
  const { quotes, mortgage, carriers } = useSelector(({ quotes, mortgage, carriers }) => ({
    quotes: Object.values(quotes),
    mortgage,
    carriers
  }));

  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [quote, setQuote] = useState();
  const [paymentPlan, setPaymentPlan] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const [escrowData, setEscrowData] = useState({});

  useEffect(() => {
    setEscrowData({
      [ESCROW_FIELDS.MORTGAGE_COMPANY_NAME.key]: mortgage[MORTGAGE_FIELDS.COMPANY_NAME] || '',
      [ESCROW_FIELDS.MORTGAGE_COMPANY_CITY.key]: mortgage[MORTGAGE_FIELDS.COMPANY_CITY] || '',
      [ESCROW_FIELDS.MORTGAGE_COMPANY_STATE.key]: mortgage[MORTGAGE_FIELDS.COMPANY_STATE] || '',
      [ESCROW_FIELDS.LOAN_NUMBER.key]: mortgage[MORTGAGE_FIELDS.LOAN_NUMBER] || ''
    });
  }, [mortgage]);

  const [achData, setAchData] = useState({
    [ACH_FIELDS.BANK_NAME.key]: '',
    [ACH_FIELDS.ACCOUNT_NUMBER.key]: '',
    [ACH_FIELDS.ROUTING_NUMBER.key]: ''
  });

  const [cardData, setCardData] = useState({
    [CARD_FIELDS.NAME_ON_CARD.key]: '',
    [CARD_FIELDS.CARD_NUMBER.key]: '',
    [CARD_FIELDS.CARD_EXPIRATION_DATE.key]: '',
    [CARD_FIELDS.CARD_CVV.key]: '',
    [CARD_FIELDS.BILLING_ADDRESS.key]: {}
  });

  const validate = () => {
    switch (paymentMethod) {
      case PAYMENT_METHODS.ACH:
        return Object.values(ACH_FIELDS).every(({ key, validator }) => validator(achData[key]));
      case PAYMENT_METHODS.CARD:
        return Object.values(CARD_FIELDS).every(({ key, validator }) => validator(cardData[key]));
      case PAYMENT_METHODS.ESCROW:
        return Object.values(ESCROW_FIELDS).every(({ key, validator }) =>
          validator(escrowData[key])
        );
      default:
        return false;
    }
  };

  useEffect(
    () => setIsValid(validate()),
    [quote, paymentPlan, paymentMethod, achData, cardData, escrowData]
  );

  const handleSetQuote = (_, id) => {
    setQuote(quotes.find(q => q[GENERAL_FIELDS.ID] === id));
    setPaymentPlan('');
    setPaymentMethod('');
  };

  const handleSetPaymentPlan = (_, plan) => {
    setPaymentPlan(plan);
    setPaymentMethod('');
  };

  const createQuoteOption = q => {
    return {
      label: q ? getQuoteLabel(q, carriers) : '',
      value: q?.[GENERAL_FIELDS.ID] || ''
    };
  };
  const createQuoteOptions = () =>
    quotes?.filter(q => !q[QUOTE_FIELDS.IS_CURRENT_POLICY]).map(createQuoteOption) || [];

  const createPlanOptions = quote => {
    const plans = quote?.[QUOTE_FIELDS.PREMIUMS]?.map(p => p[PREMIUM_FIELDS.PAYMENT_PLAN]) || [];
    const uniquePlans = uniqBy(plans, p => p);
    return createSelectOptions(uniquePlans);
  };

  const createMethodOptions = (quote, paymentPlan) => {
    const methods =
      quote?.[QUOTE_FIELDS.PREMIUMS]
        ?.filter(p => p[PREMIUM_FIELDS.PAYMENT_PLAN] === paymentPlan)
        ?.map(p => p[PREMIUM_FIELDS.PAYMENT_METHOD]) || [];
    const uniqueMethods = uniqBy(methods, p => p);
    return createSelectOptions(uniqueMethods);
  };

  const formatBody = () => {
    const data =
      paymentMethod === PAYMENT_METHODS.ACH
        ? { ...achData }
        : paymentMethod === PAYMENT_METHODS.CARD
        ? { ...cardData }
        : paymentMethod === PAYMENT_METHODS.ESCROW
        ? { ...escrowData }
        : null;

    if (paymentMethod === PAYMENT_METHODS.CARD) {
      const [expiryMonth, expiryYear] = data[CARD_FIELDS.CARD_EXPIRATION_DATE.key].split('/');
      data.expiryMonth = expiryMonth;
      data.expiryYear = expiryYear;
      delete data[CARD_FIELDS.CARD_EXPIRATION_DATE.key];

      data[CARD_FIELDS.CARD_NUMBER.key] = stripNonDigits(data[CARD_FIELDS.CARD_NUMBER.key]);
    }

    const mortgage = paymentMethod === PAYMENT_METHODS.ESCROW ? data : {};
    const paymentInfo = paymentMethod !== PAYMENT_METHODS.ESCROW ? data : {};
    const billingInfo =
      paymentMethod === PAYMENT_METHODS.CARD
        ? { billingAddress: mapAddress(data[CARD_FIELDS.BILLING_ADDRESS.key]) }
        : {};
    const premium = quote[QUOTE_FIELDS.PREMIUMS].find(
      p =>
        p[PREMIUM_FIELDS.PAYMENT_PLAN] === paymentPlan &&
        p[PREMIUM_FIELDS.PAYMENT_METHOD] === paymentMethod
    );

    return {
      quotesetId,
      bindings: [
        {
          mortgage,
          quoteId: quote[GENERAL_FIELDS.ID],
          carrierCode: quote[FOREIGN_KEYS.CARRIER_CODE],
          coverageType: quote[QUOTE_FIELDS.COVERAGE_TYPE],
          paymentPlan,
          paymentMethod,
          paymentInfo,
          premium,
          billingInfo
        }
      ],
      notifyConsumer: false
    };
  };

  const submitPayment = () => {
    setLoading(true);
    postBinding({ body: formatBody() })
      .then(() => {
        alert('Successfully submitted payment.');
        toggleModal();
        handleSetQuote();
      })
      .catch(err => {
        error(err);
        alert(`Failed to submit payment.\n${err}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal visible={visible} closing={closing} toggleModal={toggleModal} className={styles.wrapper}>
      <div className={styles.modalContent}>
        <h2>Process Payment</h2>
        <Select
          prompt="Quote"
          placeholder="Select a quote"
          options={createQuoteOptions()}
          name={'quotes'}
          onChange={handleSetQuote}
          value={quote ? createQuoteOption(quote) : null}
        />
        <Select
          prompt="Payment Plan"
          placeholder="Select a payment plan"
          options={createPlanOptions(quote)}
          name={'paymentPlan'}
          onChange={handleSetPaymentPlan}
          value={createSelectOption(paymentPlan)}
        />
        <Select
          prompt="Payment Method"
          placeholder="Select a payment method"
          options={createMethodOptions(quote, paymentPlan)}
          name={'paymentMethod'}
          onChange={(_, value) => setPaymentMethod(value)}
          value={createSelectOption(paymentMethod)}
        />

        {paymentMethod === PAYMENT_METHODS.CARD ? (
          <CreditCard update={setCardData} data={cardData} />
        ) : paymentMethod === PAYMENT_METHODS.ESCROW ? (
          <Escrow update={setEscrowData} data={escrowData} />
        ) : paymentMethod === PAYMENT_METHODS.ACH ? (
          <ACH update={setAchData} data={achData} />
        ) : null}

        <div className={styles.modalButtonWrapper}>
          <Button
            className={styles.submitPaymentButton}
            onClick={submitPayment}
            disabled={!isValid}
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

PaymentModal.propTypes = {
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func
};

export default PaymentModal;

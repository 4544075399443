import { COVERAGE_TYPES } from 'woop-shared/enums';

export const ORIGINATION_SECTIONS = {
  ORIGINATION: 'origination',
  ADDRESSES: 'addresses',
  APPLICANTS: 'applicants',
  PROPERTY: 'property',
  AUTO_INFO: 'auto-info'
};

export const SECTION_LABELS = {
  [ORIGINATION_SECTIONS.ORIGINATION]: 'Origination',
  [ORIGINATION_SECTIONS.ADDRESSES]: 'Addresses',
  [ORIGINATION_SECTIONS.APPLICANTS]: 'Applicants',
  [ORIGINATION_SECTIONS.PROPERTY]: 'Property',
  [ORIGINATION_SECTIONS.AUTO_INFO]: 'Auto',
  [ORIGINATION_SECTIONS.FINALIZE]: 'Finalize'
};

export const SUB_SECTIONS = {
  VEHICLES: 'Vehicles',
  PROPERTY_INCIDENTS: 'Property Incidents',
  BASIC: 'Basic',
  PROTECTION: 'Protection',
  OWNERSHIP: 'Ownership',
  USAGE: 'Usage',
  INSURANCE: 'Insurance',
  POOLS: 'Pools',
  AUTO_INCIDENTS: 'Auto Incidents'
};

export const SECTION_TO_SUBS = {
  [ORIGINATION_SECTIONS.AUTO_INFO]: [SUB_SECTIONS.VEHICLES, SUB_SECTIONS.AUTO_INCIDENTS],
  [ORIGINATION_SECTIONS.PROPERTY]: [
    SUB_SECTIONS.BASIC,
    SUB_SECTIONS.PROTECTION,
    SUB_SECTIONS.OWNERSHIP,
    SUB_SECTIONS.USAGE,
    SUB_SECTIONS.INSURANCE,
    SUB_SECTIONS.POOLS,
    SUB_SECTIONS.PROPERTY_INCIDENTS
  ]
};

/**
 * Map Coverage Types to sections to be hidden
 */
export const SECTIONS_TO_HIDE = {
  [COVERAGE_TYPES.AUTO]: [
    SUB_SECTIONS.BASIC,
    SUB_SECTIONS.INSURANCE,
    SUB_SECTIONS.PROTECTION,
    SUB_SECTIONS.POOLS,
    SUB_SECTIONS.PROPERTY_INCIDENTS
  ]
};

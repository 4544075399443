import { ADDRESS_FIELDS } from 'woop-shared/origination/fields';

/**
 * Take a here maps address object and create a composite address string.
 *
 * @param {object} address contains at least {houseNumber, street, city, state, postalCode}
 * @returns {string} Composite address.
 */
function formatAddress(address) {
  return `${address.houseNumber} ${address.street}, ${address.city}, ${address.state} ${address.postalCode}`;
}

/**
 * Format Geocoder suggestion data into a format that matches react-select's options spec, filters results by zip
 *
 * @param {Array} suggestions an array of suggestions as defined by Geocoder's Autocomplete API spec (https://developer.here.com/documentation/geocoder-autocomplete/topics/resource-type-response-suggest.html)
 * @returns {Array} suggestion data in react-select option format
 */
export function formatAddressSuggestions(suggestions) {
  return suggestions
    .map(({ address }) => {
      const isFullAddress =
        address.houseNumber && address.street && address.city && address.postalCode;
      if (isFullAddress) {
        const formattedAddress = formatAddress(address);
        return {
          value: address,
          label: formattedAddress
        };
      }
    })
    .filter(Boolean);
}

export const getEmptyAddressObject = () => {
  return {
    [ADDRESS_FIELDS.COMPOSITE]: '',
    [ADDRESS_FIELDS.STREET_NUMBER]: '',
    [ADDRESS_FIELDS.STREET_NAME]: '',
    [ADDRESS_FIELDS.MUNICIPALITY_LEVEL3]: '',
    [ADDRESS_FIELDS.MUNICIPALITY_LEVEL2]: '',
    [ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE]: '',
    [ADDRESS_FIELDS.POSTAL_CODE]: ''
  };
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { FiCheck } from 'react-icons/fi';
import styles from './Checkbox.css';
import Prompt from '../Prompt';

const cx = classnames.bind(styles);

const Checkbox = ({ children, label, prompt, name, value, onChange, disabled }) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    // Keep internal state synced with external prop
    if (checked !== value) setChecked(value);
  }, [value]);

  const handleClick = () => {
    setChecked(!checked);
    onChange(name, !checked);
  };
  const handleSpacebar = e => {
    if (e.which === 32) {
      e.preventDefault();
      handleClick();
    }
  };
  return (
    <>
      {prompt && <Prompt>{prompt}</Prompt>}
      <div className={cx('wrapper', { hasPrompt: !!prompt })} disabled={disabled}>
        <div
          className={cx('checkbox', { checked })}
          onClick={handleClick}
          tabIndex={0}
          onKeyDown={handleSpacebar}
        >
          <FiCheck className={cx('check')} />
        </div>
        <p onClick={handleClick}>{children || label}</p>
      </div>
    </>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  prompt: PropTypes.string
};

import { FEE_FIELDS as FIELDS } from 'woop-shared/quotes/enums';

export function newFeeTemplate() {
  return {
    [FIELDS.ACH]: {
      [FIELDS.SERVICE_CHARGE]: 0,
      [FIELDS.INSTALLMENT_FEE]: 0
    },
    [FIELDS.CARD]: {
      [FIELDS.SERVICE_CHARGE]: 0,
      [FIELDS.INSTALLMENT_FEE]: 0
    }
  };
}

/**
 * Get the fees for the given carrier and state.
 *
 * @param {object} carrierObject A carrier.
 * @param {string} stateAbbrev Two-letter state abbreviation.
 * @returns {object} A fee template.
 */
export function getFees(carrierObject, stateAbbrev) {
  const fees = carrierObject?.fees;
  return fees?.[stateAbbrev] || fees?.[FIELDS.DEFAULT] || newFeeTemplate();
}

import { connect } from 'react-redux';
import { getJourney } from '../../actions/journeys';
import ApplicantView from '.';
import { getPulls } from '../../actions/pulls';
import { getReports } from '../../actions/reports';

const mapStateToProps = ({ journey, pulls, reports, error, loading }) => {
  return {
    journey,
    pulls,
    reports,
    error,
    loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getJourney: id => dispatch(getJourney(id)),
    getPulls: id => dispatch(getPulls(id)),
    getReports: id => dispatch(getReports(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantView);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './TableOfContents.css';
import { SECTION_LABELS, SECTION_TO_SUBS } from '../../constants/table-of-contents';
import { shouldHideSection } from '../../utils/table-of-contents';

const TableOfContents = ({ sections, coverageTypes = [] }) => {
  const handleClick = section => {
    document.getElementById(section)?.scrollIntoView();
  };

  return (
    <div className={styles.wrapper}>
      {Object.values(sections).map(sectionId => {
        const subSections = SECTION_TO_SUBS[sectionId];
        return (
          <div className={styles.section} key={sectionId}>
            <a onClick={() => handleClick(sectionId)}>{SECTION_LABELS[sectionId]}</a>
            <div className={styles.subsections}>
              {subSections?.map(subSection => {
                const shouldHide = shouldHideSection(subSection, coverageTypes);
                return (
                  !shouldHide && (
                    <a key={subSection} onClick={() => handleClick(subSection)}>
                      {subSection}
                    </a>
                  )
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

TableOfContents.propTypes = {
  coverageTypes: PropTypes.array,
  sections: PropTypes.object
};

export default TableOfContents;

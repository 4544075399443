import dayjs from 'dayjs';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles.css';
import { convertPremium } from '../../utils';
import DateField from '../../../../components/DateField';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import DecimalMoneyField from '../../../../components/DecimalMoneyField';
import { patchPolicy } from '../../../../api/policy';

const EditPolicyDetails = ({ policy, visible, closing, toggleModal, refreshPolicy }) => {
  const [effectiveDate, setEffectiveDate] = useState(
    policy.effective_date ? dayjs(policy.effective_date).format('YYYY-MM-DD') : ''
  );
  const [expirationDate, setExpirationDate] = useState(
    policy.expiry_date ? dayjs(policy.expiry_date).format('YYYY-MM-DD') : ''
  );
  const [premium, setPremium] = useState(
    policy.total_premium_cents ? convertPremium(policy.total_premium_cents) : ''
  );
  const [loading, setLoading] = useState();

  const save = () => {
    setLoading(true);
    const updates = {
      effective_date: dayjs(effectiveDate).toISOString(),
      expiry_date: dayjs(expirationDate).toISOString(),
      total_premium_cents: premium * 100
    };
    patchPolicy({ policyId: policy.policy_id, updates })
      .then(() => refreshPolicy())
      .then(() => {
        setLoading(false);
        toggleModal();
      });
  };

  return (
    visible && (
      <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
        <div>Edit Policy Information</div>
        <div className={styles.form}>
          <DateField
            name={'effectiveDate'}
            prompt={'Effective Date'}
            onChange={e => setEffectiveDate(e.target.value)}
            value={effectiveDate}
          />
          <DateField
            name={'expirationDate'}
            prompt={'Expiration Date'}
            onChange={e => setExpirationDate(e.target.value)}
            value={expirationDate}
          />
          <DecimalMoneyField
            label="Premium"
            value={premium}
            onChange={(_, value) => setPremium(value)}
          />
        </div>
        <Button className={styles.modalButton} loading={loading} onClick={save}>
          Save
        </Button>
      </Modal>
    )
  );
};

EditPolicyDetails.propTypes = {
  policy: PropTypes.object,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  refreshPolicy: PropTypes.func
};

export default EditPolicyDetails;

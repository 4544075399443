import { ADDRESS_FIELDS, ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { APPLICANT_FIELDS } from 'woop-shared/origination/fields';
import {
  APPLICANT_TYPES,
  CLAIMS_LOB,
  LICENSE_STATUS_OPTIONS,
  MARITAL_STATUSES
} from 'woop-shared/origination/enums';
import { getAddressComposite } from 'woop-shared/utils';
import { formatAddress, getDrivers, getVehicles } from 'woop-shared/origination/utils';
import ApplicantForm from '../components/ApplicantForm';
import VehicleForm from '../components/VehicleForm';
import {
  getApplicantColumns,
  getPropertyColumns,
  formatName,
  getAutoCoverageColumns,
  getAutoIncidentColumns,
  getVehicleColumns,
  getClaimColumns,
  getAddressColumns,
  getPolicyLink
} from '../utils';
import {
  validateAutoCoverage,
  validateAutoIncident,
  validateClaim,
  validateProperty,
  validateVehicle,
  validateApplicant,
  validateAddress
} from '../utils/validation';
import AutoCoverageForm from '../components/AutoCoverageForm';
import PropertyForm from '../components/PropertyForm';
import AutoIncidentsForm from '../components/AutoIncidentsForm';
import ClaimsForm from '../components/ClaimsForm';
import AddressForm from '../components/AddressForm';
import { INCIDENT_FIELDS, CLAIM_FIELDS, PROPERTY_FIELDS, AUTO_FIELDS } from '.';
import { getPrimaryApplicant } from '../../../utils/origination';

export const ENTITIES = {
  APPLICANT: 'applicant',
  VEHICLE: 'vehicle',
  PROPERTY: 'property',
  AUTO_COVERAGE: 'auto coverage',
  AUTO_INCIDENT: 'auto incident',
  CLAIM: 'claim',
  ADDRESS: 'address'
};

export const SECTIONS = {
  APPLICANTS: 'APPLICANTS',
  AUTO: 'AUTO',
  PROPERTY: 'PROPERTY',
  SHARED: 'SHARED'
};

const APPLICANT_CONFIG = {
  section: SECTIONS.APPLICANTS,
  title: 'Applicants',
  form: ApplicantForm,
  key: ORIGINATION_FIELDS.APPLICANTS,
  columns: getApplicantColumns(),
  getter: ({ quoteApp }) => quoteApp[ORIGINATION_FIELDS.APPLICANTS] || [],
  defaults: quoteApp => {
    const defaults = {
      [APPLICANT_FIELDS.LICENSE_STATUS]: LICENSE_STATUS_OPTIONS.ACTIVE,
      [APPLICANT_FIELDS.IMPAIRMENT]: false
    };

    const apps = quoteApp[ORIGINATION_FIELDS.APPLICANTS] || [];

    // If there are no applicants, set the first as an applicant
    const hasApplicants = !!apps.filter(app =>
      app[APPLICANT_FIELDS.APPLICANT_TYPE]?.includes(APPLICANT_TYPES.APPLICANT)
    ).length;
    if (!hasApplicants)
      return { ...defaults, [APPLICANT_FIELDS.APPLICANT_TYPE]: [APPLICANT_TYPES.APPLICANT] };

    // If there is a driver, default subsequent applicants to drivers as well
    const autoExists = !!apps.filter(app =>
      app[APPLICANT_FIELDS.APPLICANT_TYPE]?.includes(APPLICANT_TYPES.DRIVER)
    ).length;
    if (autoExists)
      return { ...defaults, [APPLICANT_FIELDS.APPLICANT_TYPE]: [APPLICANT_TYPES.DRIVER] };
  },
  validator: validateApplicant,
  tableValidator: applicants => {
    let errors = [];

    const primary = getPrimaryApplicant(applicants);
    const isMarried = primary?.[APPLICANT_FIELDS.MARITAL_STATUS] === MARITAL_STATUSES.MARRIED;
    const hasCoApplicant = !!applicants.filter(app =>
      app[APPLICANT_FIELDS.APPLICANT_TYPE]?.includes(APPLICANT_TYPES.CO_APPLICANT)
    ).length;
    if (isMarried && !hasCoApplicant) errors.push('Married applicants must have a co-applicant');

    return errors;
  }
};

const VEHICLE_CONFIG = {
  section: SECTIONS.AUTO,
  title: 'Vehicles',
  form: VehicleForm,
  key: `${ORIGINATION_FIELDS.AUTO_INFO}.${ORIGINATION_FIELDS.VEHICLES}`,
  columns: getVehicleColumns(),
  getter: ({ quoteApp }) => getVehicles(quoteApp),
  defaults: () => ({}),
  validator: validateVehicle
};

const PROPERTY_CONFIG = {
  section: SECTIONS.PROPERTY,
  title: 'Properties',
  form: PropertyForm,
  key: ORIGINATION_FIELDS.PROPERTIES,
  columns: getPropertyColumns(),
  getter: ({ quoteApp, pulls }) => {
    const properties = quoteApp[ORIGINATION_FIELDS.PROPERTIES] || [];
    const addresses = quoteApp[ORIGINATION_FIELDS.ADDRESSES] || [];

    const fields = properties.map(prop => {
      const address = addresses.find(
        addr => addr[ORIGINATION_FIELDS.ID] === prop[PROPERTY_FIELDS.ADDRESS_ID]
      );

      return {
        ...prop,
        [PROPERTY_FIELDS.DISPLAY_ADDRESS]: getAddressComposite(address) || '–',
        [PROPERTY_FIELDS.DISPLAY_UNIT_NUMBER]: address?.[ADDRESS_FIELDS.UNIT_NUMBER] || '–',
        [PROPERTY_FIELDS.CANOPY_POLICY]:
          getPolicyLink(prop[PROPERTY_FIELDS.CURRENT_POLICY_NUMBER], pulls) || '–'
      };
    });

    return fields;
  },
  defaults: () => ({
    [PROPERTY_FIELDS.NUMBER_OF_RESIDENTS]: 1,
    [PROPERTY_FIELDS.FAMILIES_ON_PREMISES]: 1,
    [PROPERTY_FIELDS.NUMBER_OF_SOLAR_PANELS]: 0
  }),
  validator: validateProperty
};

const AUTO_COVERAGE_CONFIG = {
  section: SECTIONS.AUTO,
  title: 'Auto insurance history',
  form: AutoCoverageForm,
  key: `${ORIGINATION_FIELDS.AUTO_INFO}`,
  columns: getAutoCoverageColumns(),
  getter: ({ quoteApp, pulls }) => [
    {
      ...quoteApp[ORIGINATION_FIELDS.AUTO_INFO],
      [AUTO_FIELDS.CANOPY_POLICY]:
        getPolicyLink(
          quoteApp[ORIGINATION_FIELDS.AUTO_INFO]?.[AUTO_FIELDS.CURRENT_POLICY_NUMBER],
          pulls
        ) || '–'
    }
  ],
  defaults: () => ({}),
  validator: validateAutoCoverage,
  disableDelete: true,
  disableAdd: true
};

const AUTO_INCIDENT_CONFIG = {
  section: SECTIONS.AUTO,
  title: 'Accidents & Violations',
  form: AutoIncidentsForm,
  key: `${ORIGINATION_FIELDS.AUTO_INFO}.${ORIGINATION_FIELDS.INCIDENTS}`,
  columns: getAutoIncidentColumns(),
  getter: ({ quoteApp }) => {
    const incidents = quoteApp[ORIGINATION_FIELDS.AUTO_INFO]?.[ORIGINATION_FIELDS.INCIDENTS] || [];
    const drivers = getDrivers(quoteApp);

    const findDriver = id => drivers.find(d => d.id === id);

    return incidents.map(i => ({
      ...i,
      [INCIDENT_FIELDS.DRIVER_NAME]: formatName(findDriver(i[INCIDENT_FIELDS.APPLICANT_ID]))
    }));
  },
  defaults: () => ({}),
  validator: validateAutoIncident
};

const CLAIM_CONFIG = {
  section: SECTIONS.SHARED,
  title: 'Claims',
  form: ClaimsForm,
  key: ORIGINATION_FIELDS.CLAIMS,
  columns: getClaimColumns(),
  getter: ({ quoteApp }) => {
    const claims = quoteApp[ORIGINATION_FIELDS.CLAIMS] || [];
    const properties = quoteApp[ORIGINATION_FIELDS.PROPERTIES] || [];
    const addresses = quoteApp[ORIGINATION_FIELDS.ADDRESSES] || [];

    return claims.map(c => {
      const property = properties.find(p => c[CLAIM_FIELDS.PROPERTY_ID] === p.id) || {};
      const address = addresses.find(a => a.id === property[PROPERTY_FIELDS.ADDRESS_ID]) || {};

      return {
        ...c,
        [CLAIM_FIELDS.LABEL]:
          c[CLAIM_FIELDS.LINE_OF_BUSINESS] === CLAIMS_LOB.AUTO
            ? 'Auto Claim'
            : formatAddress(address)
      };
    });
  },
  defaults: () => ({}),
  validator: validateClaim
};

const ADDRESS_CONFIG = {
  section: SECTIONS.SHARED,
  title: 'Addresses',
  form: AddressForm,
  key: ORIGINATION_FIELDS.ADDRESSES,
  columns: getAddressColumns(),
  getter: ({ quoteApp }) => {
    return (
      quoteApp[ORIGINATION_FIELDS.ADDRESSES]?.map(address => {
        return {
          ...address,
          [ADDRESS_FIELDS.COMPOSITE]: getAddressComposite(address)
        };
      }) || []
    );
  },
  defaults: () => ({}),
  validator: validateAddress
};

export const ENTITY_CONFIG = {
  [ENTITIES.APPLICANT]: APPLICANT_CONFIG,
  [ENTITIES.VEHICLE]: VEHICLE_CONFIG,
  [ENTITIES.PROPERTY]: PROPERTY_CONFIG,
  [ENTITIES.AUTO_COVERAGE]: AUTO_COVERAGE_CONFIG,
  [ENTITIES.AUTO_INCIDENT]: AUTO_INCIDENT_CONFIG,
  [ENTITIES.CLAIM]: CLAIM_CONFIG,
  [ENTITIES.ADDRESS]: ADDRESS_CONFIG
};

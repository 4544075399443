import { createAction } from 'redux-actions';

export const ADD_BUNDLED_QUOTE = 'ADD_BUNDLED_QUOTE';
export const addBundledQuote = createAction(ADD_BUNDLED_QUOTE);

export const DELETE_BUNDLED_QUOTE = 'DELETE_BUNDLED_QUOTE';
export const deleteBundledQuote = createAction(DELETE_BUNDLED_QUOTE);

export const DELETE_BUNDLED_QUOTE_SUCCESS = 'DELETE_BUNDLED_QUOTE_SUCCESS';
export const deleteBundledQuoteSuccess = createAction(DELETE_BUNDLED_QUOTE_SUCCESS);

export const UPDATE_BUNDLED_QUOTE = 'UPDATE_BUNDLED_QUOTE';
export const updateBundledQuote = createAction(UPDATE_BUNDLED_QUOTE);

/**
 * Take a date string and format it to YYYY-MM-DD.
 *
 * @param {string} val Any string value that creates a valid JS date object.
 * @returns {string|void} Date formatted YYYY-MM-DD
 */
export function formatDateInputValue(val) {
  if (!val || isNaN(new Date(val))) return;
  return new Date(val)?.toISOString()?.split('T')[0];
}

import { ORIGINATION_FIELDS as ORIGINATION_MODEL } from 'woop-shared/db/models';
import { ORIGINATION_FIELDS, AUTO_FIELDS } from 'woop-shared/origination/fields';
import {
  UPDATE_ORIGINATION,
  GET_ORIGINATION_SUCCESS,
  POST_ORIGINATION_SUCCESS,
  CLEAR_ORIGINATION,
  FINALIZE_ORIGINATION_SUCCESS
} from '../actions/originations';
import { PROPERTY_FIELDS } from '../constants/origination';
import { formatDateInputValue } from '../components/DateField/utils';

function origination(state = {}, action) {
  switch (action.type) {
    case GET_ORIGINATION_SUCCESS: {
      const {
        originationId,
        accountId,
        originatorId,
        comments,
        finalization,
        quotesets,
        journeys
      } = action.payload;
      const originationData = { ...action.payload.originationData };
      const autoInfo = { ...originationData[ORIGINATION_FIELDS.AUTO_INFO] };
      const propertyInfo = { ...originationData[ORIGINATION_FIELDS.PROPERTY_INFO] };

      // Delete fields
      delete originationData[ORIGINATION_FIELDS.ADDRESSES];
      delete originationData[ORIGINATION_FIELDS.APPLICANTS];
      delete autoInfo[ORIGINATION_FIELDS.VEHICLES];
      delete autoInfo[ORIGINATION_FIELDS.INCIDENTS];
      delete propertyInfo[ORIGINATION_FIELDS.PROPERTY_INCIDENTS];
      delete propertyInfo[ORIGINATION_FIELDS.POOLS];

      return {
        [ORIGINATION_MODEL.FINALIZATION]: finalization,
        [ORIGINATION_FIELDS.ID]: originationId,
        [ORIGINATION_FIELDS.ACCOUNT_ID]: accountId,
        [ORIGINATION_FIELDS.EXTERN_ORIGINATOR_ID]: originatorId,
        [ORIGINATION_MODEL.COMMENTS]: comments,
        ...originationData,
        [ORIGINATION_FIELDS.EFFECTIVE_DATE]: formatDateInputValue(
          originationData?.[ORIGINATION_FIELDS.EFFECTIVE_DATE]
        ),
        [ORIGINATION_FIELDS.AUTO_EFFECTIVE_DATE]: formatDateInputValue(
          originationData?.[ORIGINATION_FIELDS.AUTO_EFFECTIVE_DATE]
        ),
        [ORIGINATION_FIELDS.AUTO_INFO]: {
          ...autoInfo,
          [AUTO_FIELDS.CURRENT_POLICY_EXPIRATION]: formatDateInputValue(
            autoInfo?.[AUTO_FIELDS.CURRENT_POLICY_EXPIRATION]
          )
        },
        [ORIGINATION_FIELDS.PROPERTY_INFO]: {
          ...propertyInfo,
          [PROPERTY_FIELDS.MOVE_IN_DATE]: formatDateInputValue(
            propertyInfo?.[PROPERTY_FIELDS.MOVE_IN_DATE]
          ),
          [PROPERTY_FIELDS.CURRENT_POLICY_EXPIRATION]: formatDateInputValue(
            propertyInfo?.[PROPERTY_FIELDS.CURRENT_POLICY_EXPIRATION]
          )
        },
        quotesets,
        journeys
      };
    }
    case UPDATE_ORIGINATION: {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }
    case POST_ORIGINATION_SUCCESS: {
      const { originationId } = action.payload;
      return {
        ...state,
        [ORIGINATION_FIELDS.ID]: originationId
      };
    }
    case CLEAR_ORIGINATION:
      return {};
    case FINALIZE_ORIGINATION_SUCCESS: {
      const { payload } = action;
      const finalizationData = payload?.finalization || {};
      return {
        ...state,
        finalization: finalizationData
      };
    }
    default:
      return state;
  }
}

export default origination;

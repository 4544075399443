import * as quotesetSagas from '../sagas/quoteset';
import * as quoteSagas from '../sagas/quotes';
import * as bundleSagas from '../sagas/bundled-quotes';
import * as originationSagas from '../sagas/originations';
import * as partnerSagas from '../sagas/partners';
import * as carrierSagas from '../sagas/carriers';
import * as originatorSagas from '../sagas/originators';
import * as journeySagas from '../sagas/journeys';
import * as paymentSagas from '../sagas/payments';
import * as userSagas from '../sagas/users';
import * as reportSagas from '../sagas/reports';
import * as pullSagas from '../sagas/pulls';
import * as policySagas from '../sagas/policies';
import * as transunionSagas from '../sagas/transunion';
import * as appSagas from '../sagas/app';

const sagas = {
  ...quotesetSagas,
  ...quoteSagas,
  ...bundleSagas,
  ...carrierSagas,
  ...originationSagas,
  ...partnerSagas,
  ...originatorSagas,
  ...journeySagas,
  ...paymentSagas,
  ...userSagas,
  ...reportSagas,
  ...pullSagas,
  ...policySagas,
  ...appSagas,
  ...transunionSagas
};

export default function registerSagas(middleware) {
  Object.values(sagas).forEach(saga => middleware.run(saga));
}

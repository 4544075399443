import { GENERAL_FIELDS, QUOTE_FIELDS } from 'woop-shared/db/models';
import { getQuoteLabel } from '../../utils';

/**
 * Create an array of options objects for the bundle dropdowns.
 *
 * @param {Array} quotes Array of quote objects.
 * @param {Array} carriers Array of carrier objects.
 * @returns {Array} An array of dropdown option objects.
 */
export function getOptions(quotes, carriers) {
  return quotes
    .filter(
      quote => quote?.[QUOTE_FIELDS.COVERAGE_TYPE] && !quote?.[QUOTE_FIELDS.IS_CURRENT_POLICY]
    )
    .map(quote => createBundledQuoteOption(quote, carriers));
}

/**
 * Create a {value, label} object for the dropdown.
 *
 * @param {object} quote The quote object.
 * @param {Array} carriers Array of carrier objects.
 * @returns {object} A {value, label} object.
 */
function createBundledQuoteOption(quote, carriers) {
  return {
    value: quote[GENERAL_FIELDS.ID],
    label: getQuoteLabel(quote, carriers)
  };
}

/**
 * Translate a quoteId to a valid {value, label} object for the dropdown.
 *
 * @param {string} quoteId The selected quoteId.
 * @param {Array} options Array of options for the dropdown.
 * @returns {object} A {value, label} object.
 */
export function getQuoteOption(quoteId, options) {
  if (!quoteId) return null;
  return options.find(option => option.value === quoteId);
}

/**
 * Filter the available options for the bundle dropdown.
 *
 * 1. Remove any options that have the same covType as the other option.
 *
 * @param {Array} options The available options to filter. opt.value is the quote's id
 * @param {Array} bundledQuoteIds
 * @param {Array} quotes
 * @returns {Array} Array of {value, label} objects.
 */
export function filterOptions(options, bundledQuoteIds, quotes) {
  if (!bundledQuoteIds?.length) return options;

  const usedCoverageTypes = quotes
    .filter(q => bundledQuoteIds.includes(q[GENERAL_FIELDS.ID]))
    .map(q => q[QUOTE_FIELDS.COVERAGE_TYPE]);

  const badQuoteIds = quotes
    .filter(q => usedCoverageTypes.includes(q[QUOTE_FIELDS.COVERAGE_TYPE]))
    .map(q => q[GENERAL_FIELDS.ID]);

  return options.filter(opt => !badQuoteIds.includes(opt.value));
}

import axios from 'axios';
import { concatUri } from '../utils/url.js';

const getUri = quotesetId => `${process.env.BINDING_URI}?quotesetId=${quotesetId}`;

const getPayments = async quotesetId => {
  const uri = getUri(quotesetId);
  const { data } = await axios.get(uri);
  return data;
};

const failPayment = async id => {
  const uri = concatUri(process.env.BINDING_URI, [id, 'fail']);
  const { data } = await axios.patch(uri);
  return data;
};

const processPayment = async ({ id, effectiveDate, policyNumber }) => {
  const uri = concatUri(process.env.BINDING_URI, [id, 'success']);
  const { data } = await axios.patch(uri, {
    effectiveDate,
    policyNumber
  });
  return data;
};

export default {
  getPayments,
  failPayment,
  processPayment
};

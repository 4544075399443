import React from 'react';
import PropTypes from 'prop-types';
import { FaRegCheckCircle } from 'react-icons/fa';
import { FINALIZATION_TYPES } from 'woop-shared/origination/enums';
import styles from './Finalized.css';
import Card from '../../Card';
import { TYPE_OPTIONS } from '../constants';

const Finalized = ({ finalizationData }) => {
  const option = TYPE_OPTIONS.find(o => o.value === finalizationData.type);
  return (
    <Card className={styles.wrapper}>
      <div>
        <b>{`This origination has been finalized`}</b>
      </div>
      <div>{`Date: ${new Date(finalizationData.date).toLocaleString({
        dateStyle: 'medium',
        timeStyle: 'short'
      })}`}</div>
      <div>{`Type: ${option.label}`}</div>
      {finalizationData.type === FINALIZATION_TYPES.CAN_IMPROVE && (
        <>
          <div>{`Savings: ${finalizationData.savings}`}</div>
          <div>{`Carriers: ${finalizationData?.carriers?.join(' & ')}`}</div>
        </>
      )}
      <FaRegCheckCircle />
    </Card>
  );
};

Finalized.propTypes = {
  finalizationData: PropTypes.object
};

export default Finalized;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { POLICY_TYPES } from 'woop-shared/canopy/enums';
import { getPolicy } from '../../api/policy';
import Card from '../../components/Card';
import Address from './components/Address';
import Coverages from './components/Coverages';
import Drivers from './components/Drivers';
import InsuranceHistory from './components/InsuranceHistory';
import PolicyDetails from './components/PolicyDetails';
import VehicleCoverages from './components/VehicleCoverages';
import Vehicles from './components/Vehicles';
import styles from './styles.css';

const PolicyPage = () => {
  const { policyId } = useParams();
  const [policy, setPolicy] = useState();

  const isAuto = policy?.policy_type === POLICY_TYPES.AUTO;

  const refreshPolicy = async () => getPolicy({ policyId }).then(p => setPolicy(p));

  useEffect(() => {
    refreshPolicy();
  }, []);

  return !policy ? (
    <Card className={styles.wrapper} />
  ) : (
    <Card className={styles.wrapper}>
      <PolicyDetails policy={policy} refreshPolicy={refreshPolicy} />
      {isAuto && (
        <>
          <Drivers policy={policy} refreshPolicy={refreshPolicy} />
          <Vehicles policy={policy} refreshPolicy={refreshPolicy} />
        </>
      )}
      {!isAuto && <Address policy={policy} refreshPolicy={refreshPolicy} />}
      <Coverages policy={policy} refreshPolicy={refreshPolicy} />
      {isAuto && (
        <>
          <div className={styles.tableHeader}>Vehicle Coverages</div>
          {policy.vehicles?.map(vehicle => (
            <VehicleCoverages
              key={`vehicle-${vehicle.vehicle_id}-coverages`}
              policy={policy}
              vehicle={vehicle}
              refreshPolicy={refreshPolicy}
            />
          ))}
        </>
      )}
      <InsuranceHistory policy={policy} refreshPolicy={refreshPolicy} />
    </Card>
  );
};

export default PolicyPage;

import { COVERAGE_TYPES } from 'woop-shared/enums';
import { PAYMENT_PLANS, PAYMENT_METHODS } from 'woop-shared/quotes/enums';

/* @todo share these between UI project */
export const PAYMENT_PLAN_OPTIONS = {
  [PAYMENT_PLANS.ANNUALLY]: 'Annual',
  [PAYMENT_PLANS.SEMI_ANNUALLY]: 'Semi-annual',
  [PAYMENT_PLANS.MONTHLY]: 'Monthly',
  [PAYMENT_PLANS.INSTALLMENT]: 'Installment'
};

const PAYMENT_METHODS_ESCROW = Object.values(PAYMENT_METHODS);
const PAYMENT_METHODS_NO_ESCROW = Object.values(PAYMENT_METHODS).filter(
  pmt => pmt !== PAYMENT_METHODS.ESCROW
);

export const PAYMENT_METHODS_BY_COVERAGE_TYPE = {
  [COVERAGE_TYPES.HOME]: PAYMENT_METHODS_ESCROW,
  [COVERAGE_TYPES.AUTO]: PAYMENT_METHODS_NO_ESCROW,
  [COVERAGE_TYPES.RENTERS]: PAYMENT_METHODS_NO_ESCROW,
  [COVERAGE_TYPES.CONDO]: PAYMENT_METHODS_ESCROW,
  [COVERAGE_TYPES.UMBRELLA]: PAYMENT_METHODS_NO_ESCROW
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--2kcd9{max-height:85vh;overflow-y:auto}.styles__buttonWrapper--2bgnI{display:flex;flex-direction:column;grid-gap:24px;gap:24px}.styles__buttonWrapper--2bgnI>*{margin:0 auto}.styles__buttonWrapper--2bgnI a{font-size:14px}.styles__buttonWrapper--2bgnI button{font-size:16px}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--2kcd9",
	"buttonWrapper": "styles__buttonWrapper--2bgnI"
};
module.exports = exports;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../css/SearchPage.css';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import Card from '../../components/Card';
import ErrorText from '../../components/ErrorText';
import LinkText from '../../components/LinkText';
import { addIdToRoute, ROUTES } from '../../router/routes';
import { getRecentCollection } from '../../utils/local-storage';
import { RECENT_PAYMENTS_KEY } from '../../constants/local-storage';
import CardLabel from '../../components/CardLabel';

const PaymentsSearch = ({ getPayments, error, loading }) => {
  const [quotesetId, setQuotesetId] = useState('');

  const isValid = () => !!quotesetId;

  const handleClick = () => {
    getPayments(quotesetId);
  };

  const handleEnter = e => {
    if (isValid() && e.key === 'Enter') handleClick();
  };

  const recentPayments = getRecentCollection(RECENT_PAYMENTS_KEY);

  return (
    <section onKeyUp={e => handleEnter(e)} className={styles.wrapper}>
      <Card>
        <CardLabel>Payments</CardLabel>
        <TextField
          name="quotesetId"
          placeholder="5e18bb9f8f8b3e93a8157a84"
          label="Quoteset ID"
          value={quotesetId}
          onChange={e => setQuotesetId(e.target.value)}
        />
        {error.message && <ErrorText>{error.message}</ErrorText>}
        <div className={styles.buttonWrapper}>
          <Button onClick={handleClick} disabled={!isValid()} loading={loading}>
            Search
          </Button>
        </div>
      </Card>
      <br />
      <Card>
        <div>Recent Payments</div>
        {recentPayments?.map(p => (
          <div key={p.id} className={styles.recentList}>
            <LinkText href={addIdToRoute(ROUTES.PAYMENTS_VIEW, p.id)}>
              {p.lastName} - {p.coverageTypes} ({p.id})
            </LinkText>
          </div>
        ))}
      </Card>
    </section>
  );
};

PaymentsSearch.propTypes = {
  getPayments: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool
};

export default PaymentsSearch;

import { formatMoney, stripNonDigits } from 'woop-shared/quotes/utils';
import { FIELDS } from 'woop-shared/canopy/enums';
import { AUTO_POLICY_COVERAGES } from '../../../constants/canopy';

export function convertPremium(premium) {
  return premium / 100;
}

/**
 * Converts a number of cents to dollars and formats as a string with "$" and commas. If the input is
 * null or undefined, returns an empty string
 *
 * @param {number?} cents
 * @returns {string}
 */
export function formatCents(cents) {
  if (!cents && cents !== 0) return '';
  return formatMoney(cents / 100);
}

/**
 * Converts a dollar amount string to cents. If amount is falsy and not zero, return null
 *
 * @param {string?} dollars
 * @returns {number|null}
 */
export function convertToCents(dollars) {
  if (!dollars && dollars !== 0) return null;
  const cents = parseInt(stripNonDigits(dollars)) * 100;
  return cents;
}

export function formatVehicle(vehicle) {
  return [vehicle?.year, vehicle?.make, vehicle?.model].filter(Boolean).join(' ');
}

export function uniqBy(array, func) {
  const itemsByKey = array.reduce(
    (items, item) => (items[func(item)] ? items : { [func(item)]: item, ...items }),
    {}
  );
  return Object.values(itemsByKey);
}

/**
 * Gets the unique drivers on a policy
 *
 * @param {object} policy
 * @returns {object[]} unique drivers
 */
export const getDrivers = policy => {
  const vehicles = policy?.vehicles || [];
  const drivers = vehicles.flatMap(vehicle => vehicle?.drivers || []);
  return uniqBy(drivers, driver => driver?.driver_id);
};

export function isAutoPolicyCoverage(coverage) {
  return AUTO_POLICY_COVERAGES.has(coverage?.name);
}

export function formatDriver(driver) {
  return [driver?.first_name, driver?.last_name].filter(Boolean).join(' ');
}

export function getPolicyClaims(policy) {
  const claims = policy?.[FIELDS.CLAIMS] ?? [];
  const incidents = policy?.incidents ?? [];
  return [...claims, ...incidents];
}

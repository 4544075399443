import React from 'react';
import PropTypes from 'prop-types';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { IV_REPORT_FIELDS } from 'woop-shared/origination/fields';
import { formatVehicle } from '../../../Policy/utils';
import Table from '../../../../components/Table';
import styles from '../../styles.css';
import { getName } from '../../utils';

const Summary = ({ uiData, report }) => {
  return (
    <div>
      <Table
        className={styles.table}
        rows={[
          {
            label: 'Name',
            value: getName(uiData)
          },
          {
            label: 'Email',
            value: uiData?.[PROMPT_NAMES.EMAIL]
          },
          {
            label: 'Vehicle',
            value: formatVehicle(report?.[IV_REPORT_FIELDS.VEHICLE])
          }
        ]}
      />
    </div>
  );
};

Summary.propTypes = {
  uiData: PropTypes.object,
  report: PropTypes.object
};

export default Summary;

import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../Select';
import { createSelectOptions, createSelectOption } from '../../Select/utils';
import { PROPERTY_FIELDS as FIELDS, YES_NO_OPTIONS } from '../../../constants/origination';
import styles from '../../../pages/CreateOrigination/CreateOrigination.css';
import NumberField from '../../NumberField';
import { HOME_RENTERS_CONDO_OTHER_CARRIERS } from '../../../constants/carriers';
import DateField from '../../DateField';
import RadioGroup from '../../RadioGroup';
import { SUB_SECTIONS } from '../../../constants/table-of-contents';
import { shouldHideSection } from '../../../utils/table-of-contents';
import Card from '../../Card';

const sectionId = SUB_SECTIONS.INSURANCE;

const PropertyInsurance = ({ propertyInfo = {}, updateOrigination, coverageTypes }) => {
  const shouldHide = shouldHideSection(sectionId, coverageTypes);
  return (
    !shouldHide && (
      <Card id={sectionId}>
        <h3 className={styles.heading}>Property Insurance</h3>
        <div className={styles.fieldWrapper}>
          <Select
            prompt="Current Home/Renters/Condo Insurer"
            name={FIELDS.CURRENT_INSURER}
            createable={true}
            options={createSelectOptions(HOME_RENTERS_CONDO_OTHER_CARRIERS)}
            onChange={updateOrigination}
            isValid={true}
            value={createSelectOption(propertyInfo[FIELDS.CURRENT_INSURER])}
          />
          <DateField
            name={FIELDS.CURRENT_POLICY_EXPIRATION}
            value={propertyInfo[FIELDS.CURRENT_POLICY_EXPIRATION]}
            prompt="Current Home/Renters/Condo Policy Expiration"
            onChange={e => updateOrigination(e.target.name, e.target.value)}
          />
          <RadioGroup
            prompt="Has canceled a Home/Renters/Condo policy recently"
            name={FIELDS.POLICY_CANCELED}
            value={propertyInfo[FIELDS.POLICY_CANCELED]}
            onChange={updateOrigination}
            options={YES_NO_OPTIONS}
          />
          <RadioGroup
            prompt="Has had a recent Home/Renters/Condo claim"
            name={FIELDS.RECENT_CLAIM}
            value={propertyInfo[FIELDS.RECENT_CLAIM]}
            onChange={updateOrigination}
            options={YES_NO_OPTIONS}
          />
          <NumberField
            label="Months with Current Insurer"
            name={FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER}
            value={propertyInfo[FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER]}
            onChange={updateOrigination}
          />
        </div>
      </Card>
    )
  );
};

PropertyInsurance.propTypes = {
  coverageTypes: PropTypes.array,
  propertyInfo: PropTypes.object,
  updateOrigination: PropTypes.func
};

export default PropertyInsurance;

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import TextField from '../../../../../../components/TextField';

const EffectiveDates = ({ updates, updateHandler }) => {
  const covTypes = updates[PROMPT_NAMES.COVERAGE_TYPE] || [];

  const isValid = val => {
    return dayjs(val).isValid() && val === dayjs(val).format('MM/DD/YYYY');
  };

  return (
    <>
      <TextField
        name={PROMPT_NAMES.EFFECTIVE_DATE}
        label={`Effective date`}
        onChange={e => updateHandler(e.target.name, e.target.value)}
        value={updates[PROMPT_NAMES.EFFECTIVE_DATE]}
        isValid={isValid(updates[PROMPT_NAMES.EFFECTIVE_DATE])}
        showValidation={true}
        validationText="MM/DD/YYYY"
      />
      {covTypes.length > 1 && covTypes.includes(COVERAGE_TYPES.AUTO) && (
        <TextField
          name={PROMPT_NAMES.AUTO_EFFECTIVE_DATE}
          label={`Auto effective date`}
          onChange={e => updateHandler(e.target.name, e.target.value)}
          value={updates[PROMPT_NAMES.AUTO_EFFECTIVE_DATE]}
          isValid={isValid(updates[PROMPT_NAMES.AUTO_EFFECTIVE_DATE])}
          showValidation={true}
          validationText="MM/DD/YYYY"
        />
      )}
    </>
  );
};

EffectiveDates.propTypes = {
  updates: PropTypes.object,
  updateHandler: PropTypes.func
};

export default EffectiveDates;

import _ from 'lodash';
import { capitalize } from 'woop-shared/utils';
import { QUOTE_TYPES } from '../QuoteRequest/constants';
import { getRequestIcon } from '../QuoteRequest/utils';

/**
 * Format section title with emoji for display.
 *
 * e.g. "HOME_APPLES_AUTO_RECOMMENDATION" => "Home 🍏 + Auto 🛡️"
 *
 * @param {string} title
 */
export const formatSectionTitle = title => {
  return _.chunk(_.split(title, '_').map(capitalize), 2)
    .map(chunk => chunk.join(' '))
    .join(' + ')
    .replace(/apples/gi, getRequestIcon(QUOTE_TYPES.APPLES))
    .replace(/recommendation/gi, getRequestIcon(QUOTE_TYPES.RECOMMENDATION));
};

/**
 * Get the annual total for a set of results given their term lengths.
 *
 * @param {object[]} results
 */
export const getAnnualizedTotal = results => {
  if (results.some(result => !result.totalPremium)) return null;

  return (
    results.reduce((acc, result) => {
      const amount = result.totalPremium;
      const total = result.termLength === 6 ? amount * 2 : amount;

      return acc + total;
    }, 0) ?? null
  );
};

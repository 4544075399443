export const INCIDENT_TYPES = {
  CLAIM: 'CLAIM',
  ACCIDENT: 'ACCIDENT',
  VIOLATION: 'VIOLATION'
};

/**
 * Constant case enum representing the is_at_fault boolean field of an accident object
 */
export const FAULT_TYPES = {
  AT_FAULT: 'AT_FAULT',
  NOT_AT_FAULT: 'NOT_AT_FAULT'
};

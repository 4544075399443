import { QUOTE_FIELDS } from 'woop-shared/db/models';
import {
  formatAdditionalCoverages,
  formatCoverages,
  getCoverageTemplate
} from '../../../../utils/coverage-template';

/**
 * Get & format the coverages, additional coverages, and deductible from coverage templates.
 *
 * @param {string} covTag The coverage tag, e.g. LOW.
 * @param {string} covType The coverage type, e.g. HOME.
 * @param {string} carrierCode The carrier code.
 * @param {string} stateAbbrev Two-letter state abbreviation.
 * @param {number} replacementCost The replacement cost.
 * @returns {object} { coverages, additionalCoverages, deductible }
 */
export function getCoverages(covTag, covType, carrierCode, stateAbbrev, replacementCost) {
  // Get the coverages from the template.
  const template = getCoverageTemplate(covType, stateAbbrev, covTag, carrierCode);

  const deductible = template[QUOTE_FIELDS.DEDUCTIBLE];

  // Format the additional coverages
  const additionalCoverages = formatAdditionalCoverages(
    template[QUOTE_FIELDS.ADDITIONAL_COVERAGES],
    replacementCost
  );

  // Format the coverages
  const coverages = formatCoverages(template[QUOTE_FIELDS.COVERAGES], replacementCost);

  return {
    coverages,
    additionalCoverages,
    deductible
  };
}

import { takeEvery, put } from 'redux-saga/effects';
import api from '../api/quotes';
import { setError } from '../actions/error';
import { setLoading } from '../actions/loading';
import { DELETE_QUOTE, deleteQuoteSuccess } from '../actions/quotes';

function* handleDeleteQuote(action) {
  try {
    yield put(setLoading(true));
    const { id, saved } = action.payload;
    if (saved) {
      yield api.deleteQuote(id);
    }
    yield put(deleteQuoteSuccess(id));
    yield put(setLoading(false));
    yield put(setError());
  } catch (e) {
    yield put(setLoading(false));
    yield put(setError({ message: 'Deletion failed!' }));
  }
}

export function* watchDeleteQuote() {
  yield takeEvery(DELETE_QUOTE, handleDeleteQuote);
}

import {
  RECENT_QUOTES_KEY,
  RECENT_ORIGINATIONS_KEY,
  RECENT_APPLICANTS_KEY,
  RECENT_PAYMENTS_KEY
} from '../constants/local-storage';

export function getRecentCollection(key) {
  const recentQuotesString = localStorage.getItem(key);
  return recentQuotesString ? JSON.parse(recentQuotesString) : [];
}

function addToRecentCollection(key, newEntry) {
  const recentCollection = getRecentCollection(key);
  const newRecentCollection = [newEntry, ...recentCollection.filter(c => c?.id !== newEntry.id)];
  const stringVal = JSON.stringify(newRecentCollection.slice(0, 10));
  localStorage.setItem(key, stringVal);
}

export function addToRecentQuotes(id, lastName, coverageTypes) {
  const newEntry = { id, lastName, coverageTypes: coverageTypes?.join('+') };
  addToRecentCollection(RECENT_QUOTES_KEY, newEntry);
}

export function addToRecentPayments(id, lastName, coverageTypes) {
  const newEntry = { id, lastName, coverageTypes: coverageTypes?.join('+') };
  addToRecentCollection(RECENT_PAYMENTS_KEY, newEntry);
}

export function addToRecentOriginations(id, lastName, coverageTypes) {
  const newEntry = { id, lastName, coverageTypes: coverageTypes?.join('+') };
  addToRecentCollection(RECENT_ORIGINATIONS_KEY, newEntry);
}

export function addToRecentApplicantSearches(searchCriteria) {
  const id = new Date().getTime();
  addToRecentCollection(RECENT_APPLICANTS_KEY, { id, ...searchCriteria });
}

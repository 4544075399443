import React from 'react';
import PropTypes from 'prop-types';
import { US_STATES } from 'woop-shared/data/states';
import Select from '../../../../../../components/Select';
import { createSelectOption } from '../../../../../../components/Select/utils';
import TextField from '../../../../../../components/TextField';
import { ESCROW_FIELDS } from '../../constants';

const Escrow = ({ data, update }) => {
  const handleChange = field => e => update({ ...data, [field]: e.target.value });
  const validate = ({ key, validator }) => validator(data[key]);

  return (
    <>
      <TextField
        key={ESCROW_FIELDS.MORTGAGE_COMPANY_NAME.key}
        name={ESCROW_FIELDS.MORTGAGE_COMPANY_NAME.key}
        label={ESCROW_FIELDS.MORTGAGE_COMPANY_NAME.label}
        value={data[ESCROW_FIELDS.MORTGAGE_COMPANY_NAME.key]}
        onChange={handleChange(ESCROW_FIELDS.MORTGAGE_COMPANY_NAME.key)}
        isValid={validate(ESCROW_FIELDS.MORTGAGE_COMPANY_NAME)}
      />
      <TextField
        key={ESCROW_FIELDS.MORTGAGE_COMPANY_CITY.key}
        name={ESCROW_FIELDS.MORTGAGE_COMPANY_CITY.key}
        label={ESCROW_FIELDS.MORTGAGE_COMPANY_CITY.label}
        value={data[ESCROW_FIELDS.MORTGAGE_COMPANY_CITY.key]}
        onChange={handleChange(ESCROW_FIELDS.MORTGAGE_COMPANY_CITY.key)}
        isValid={validate(ESCROW_FIELDS.MORTGAGE_COMPANY_CITY)}
      />
      <Select
        placeholder={ESCROW_FIELDS.MORTGAGE_COMPANY_STATE.label}
        options={US_STATES}
        name={ESCROW_FIELDS.MORTGAGE_COMPANY_STATE.key}
        onChange={(_, value) =>
          update({ ...data, [ESCROW_FIELDS.MORTGAGE_COMPANY_STATE.key]: value })
        }
        value={createSelectOption(data[ESCROW_FIELDS.MORTGAGE_COMPANY_STATE.key])}
      />
      <TextField
        key={ESCROW_FIELDS.LOAN_NUMBER.key}
        name={ESCROW_FIELDS.LOAN_NUMBER.key}
        label={ESCROW_FIELDS.LOAN_NUMBER.label}
        value={data[ESCROW_FIELDS.LOAN_NUMBER.key]}
        onChange={handleChange(ESCROW_FIELDS.LOAN_NUMBER.key)}
        isValid={validate(ESCROW_FIELDS.LOAN_NUMBER)}
      />
    </>
  );
};

Escrow.propTypes = {
  data: PropTypes.object,
  update: PropTypes.func
};

export default Escrow;

import dayjs from 'dayjs';
import { CLAIM_TYPES, VIOLATION_TYPES } from 'woop-shared/canopy/claims';
import { FIELDS } from 'woop-shared/canopy/enums';
import { patchClaim, patchIncident, postClaim, postIncident } from '../../../../api/policy';
import { createOptionsFromEnum } from '../../../../components/Select/utils';
import { convertToCents, formatDriver } from '../../utils';
import { FAULT_TYPES, INCIDENT_TYPES } from '../InsuranceHistory/constants';

export const INCIDENT_TYPE_OPTIONS = createOptionsFromEnum(INCIDENT_TYPES);

const SHARED_CLAIM_TYPES = [CLAIM_TYPES.THEFT, CLAIM_TYPES.VANDALISM, CLAIM_TYPES.MEDICAL];

const HOME_ONLY_CLAIM_TYPES = [
  CLAIM_TYPES.WIND_AND_HAIL_DAMAGE,
  CLAIM_TYPES.WATER_DAMAGE_AND_FREEZING,
  CLAIM_TYPES.FIRE_AND_LIGHTNING_DAMAGE,
  CLAIM_TYPES.OTHER_PROPERTY_DAMAGE,
  CLAIM_TYPES.PERSONAL_LIABILITY,
  CLAIM_TYPES.POWER_OUTAGE_OR_SURGE,
  CLAIM_TYPES.WEATHER,
  CLAIM_TYPES.SEWAGE_BACKUP,
  CLAIM_TYPES.HURRICANE_DAMAGE
];

const AUTO_ONLY_CLAIM_TYPES = [
  CLAIM_TYPES.CAR_ACCIDENT,
  CLAIM_TYPES.WINDSHIELD_DAMAGE,
  CLAIM_TYPES.ANIMAL_COLLISION,
  CLAIM_TYPES.EMERGENCY_ROADSIDE_ASSISTANCE,
  CLAIM_TYPES.COMP
];

const buildObject = array =>
  array.reduce((obj, key) => {
    return { ...obj, [key]: key };
  }, {});

const AUTO_CLAIM_TYPE_OPTIONS = createOptionsFromEnum(
  buildObject([...SHARED_CLAIM_TYPES, ...AUTO_ONLY_CLAIM_TYPES])
);
const HOME_CLAIM_TYPE_OPTIONS = createOptionsFromEnum(
  buildObject([...SHARED_CLAIM_TYPES, ...HOME_ONLY_CLAIM_TYPES])
);
const ACCIDENT_TYPE_OPTIONS = createOptionsFromEnum(FAULT_TYPES);
const VIOLATION_TYPE_OPTIONS = createOptionsFromEnum(VIOLATION_TYPES);

/**
 * Gets select options based on the incident type
 *
 * @param {string} type
 * @param {boolean} isAuto
 * @returns {object[]}
 */
export const getSubtypeOptions = (type, isAuto) => {
  if (!isAuto) return HOME_CLAIM_TYPE_OPTIONS;

  switch (type) {
    case INCIDENT_TYPES.CLAIM:
      return AUTO_CLAIM_TYPE_OPTIONS;
    case INCIDENT_TYPES.ACCIDENT:
      return ACCIDENT_TYPE_OPTIONS;
    case INCIDENT_TYPES.VIOLATION:
      return VIOLATION_TYPE_OPTIONS;
    default:
      return [];
  }
};

/**
 * Creates a select option object from a driver object, with label as the driver's formatted name and value as the driver_id
 *
 * @param {object} driver
 * @returns {object?}
 */
export const createDriverSelectOption = driver => {
  return driver
    ? {
        label: formatDriver(driver),
        value: driver[FIELDS.DRIVER.ID]
      }
    : null;
};

/**
 * Formats and filters the fields of the updates object as needed for the given incident type
 *
 * @param {object} updates
 * @param {object} policy
 * @returns {object}
 */
const prepareUpdates = (updates, policy) => {
  switch (updates?.type) {
    case INCIDENT_TYPES.CLAIM:
      return {
        policy_id: policy?.[FIELDS.POLICY.POLICY_ID],
        type: updates?.subtype,
        date_occurred: updates?.date && dayjs(updates.date).toISOString(),
        payout_cents: convertToCents(updates?.payout)
      };
    case INCIDENT_TYPES.ACCIDENT:
      return {
        driver_id: updates?.driverId,
        incident_date: updates?.date && dayjs(updates.date).toISOString(),
        incident_type: INCIDENT_TYPES.ACCIDENT,
        is_at_fault:
          updates?.subtype === FAULT_TYPES.NOT_AT_FAULT
            ? false
            : updates?.subtype === FAULT_TYPES.AT_FAULT
            ? true
            : null
      };
    case INCIDENT_TYPES.VIOLATION:
      return {
        driver_id: updates?.driverId,
        incident_date: updates?.date && dayjs(updates.date).toISOString(),
        incident_type: INCIDENT_TYPES.VIOLATION,
        violation_type: VIOLATION_TYPES[updates?.subtype]
      };
    default:
      return {};
  }
};

/**
 * Makes the appropriate api call for the given incident type
 *
 * @param {object} params
 * @param {object} params.policy
 * @param {object?} params.item
 * @param {object} params.updates
 * @returns {Promise}
 */
export const updateInsuranceHistory = ({ policy, item, updates }) => {
  const { post, patch } =
    updates?.type === INCIDENT_TYPES.CLAIM
      ? {
          post: postClaim,
          patch: patchClaim
        }
      : {
          post: postIncident,
          patch: patchIncident
        };

  const params =
    updates?.type === INCIDENT_TYPES.CLAIM
      ? {
          pullId: policy?.[FIELDS.PULL_ID],
          claimId: item?.[FIELDS.CLAIM.CLAIM_ID],
          updates: prepareUpdates(updates, policy)
        }
      : {
          pullId: policy?.[FIELDS.PULL_ID],
          incidentId: item?.[FIELDS.DRIVING_RECORD.DRIVING_RECORD_ID],
          updates: prepareUpdates(updates)
        };

  return item ? patch(params) : post(params);
};

import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Checkbox } from '@mui/material';
import classnames from 'classnames/bind';
import { HiX } from 'react-icons/hi';
import { INCLUDED_COVERAGES } from 'woop-shared/canopy/coverages';
import { FIELDS } from 'woop-shared/canopy/enums';
import { isValidCoverage } from 'woop-shared/utils/canopy';
import styles from './styles.module.css';
import {
  AUTO_COVERAGE_OPTIONS,
  HOME_COVERAGE_OPTIONS,
  VEHICLE_COVERAGE_OPTIONS
} from '../../../../constants/canopy';
import { centsToDollars } from '../../../../utils/money';
import MoneyField from '../../../../components/MoneyField';
import Select from '../../../../components/Select';
import LinkText from '../../../../components/LinkText';
import Prompt from '../../../../components/Prompt';
import { UNMAPPED_COVERAGES } from './constants';

const cx = classnames.bind(styles);

function getCoverageOptions(isAuto, vehicleIndex) {
  if (isAuto) {
    return Number.isInteger(vehicleIndex) ? VEHICLE_COVERAGE_OPTIONS : AUTO_COVERAGE_OPTIONS;
  } else {
    return HOME_COVERAGE_OPTIONS;
  }
}

const CoverageTable = ({
  coverages = [],
  title,
  isAuto,
  onAdd,
  onCoverageSelect,
  onLimitChange,
  onDelete,
  vehicleIndex,
  clearLimits
}) => {
  const firstInputRef = useRef(null);

  const isAvailableCoverage = coverageOption => {
    return !coverages.some(
      coverage =>
        coverage[FIELDS.COVERAGE.NAME] === coverageOption.value &&
        !UNMAPPED_COVERAGES.includes(coverage[FIELDS.COVERAGE.NAME])
    );
  };
  const noLimits = coverage => {
    return (
      !coverage[FIELDS.COVERAGE.PER_DAY_LIMIT_CENTS] &&
      !coverage[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS] &&
      !coverage[FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS]
    );
  };

  const handleCoverageChange = (index, vehicleIndex) => (key, value) => {
    onCoverageSelect(index, vehicleIndex)(key, value);

    // wait 50 ms for the select to close before focusing the next input
    setTimeout(() => {
      firstInputRef.current?.focus();
    }, 50);
  };

  return (
    <div>
      <Prompt>
        <span>{title}</span>
        <LinkText onClick={onAdd(vehicleIndex)}>Add +</LinkText>
      </Prompt>
      <div className={styles.table}>
        <div className={cx('row', { autoRow: isAuto })}>
          <div className={styles.columnHeader}>Coverage</div>
          <div className={styles.columnHeader}>Per Person</div>
          <div className={styles.columnHeader}>Per Incident</div>
          {isAuto && <div className={styles.columnHeader}>Per Day</div>}
          <div className={styles.columnHeader}>Deductible</div>
          <div className={styles.columnHeader}>Included</div>
          <div></div>
        </div>
        {coverages?.map((coverage, index) => (
          <div
            className={cx('row', { autoRow: isAuto })}
            key={coverage[FIELDS.COVERAGE.NAME] || index}
          >
            <div>
              {coverage.friendly_name ? (
                coverage.friendly_name
              ) : (
                <Select
                  placeholder={'Select coverage...'}
                  options={getCoverageOptions(isAuto, vehicleIndex).filter(isAvailableCoverage)}
                  onChange={handleCoverageChange(index, vehicleIndex)}
                />
              )}
            </div>
            <div>
              <MoneyField
                ref={firstInputRef}
                name={FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS}
                value={centsToDollars(coverage[FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS])}
                onChange={onLimitChange(index, vehicleIndex)}
              />
            </div>
            <div>
              <MoneyField
                name={FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS}
                value={centsToDollars(coverage[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS])}
                onChange={onLimitChange(index, vehicleIndex)}
              />
            </div>
            {isAuto && (
              <div>
                <MoneyField
                  name={FIELDS.COVERAGE.PER_DAY_LIMIT_CENTS}
                  value={centsToDollars(coverage[FIELDS.COVERAGE.PER_DAY_LIMIT_CENTS])}
                  onChange={onLimitChange(index, vehicleIndex)}
                />
              </div>
            )}
            <div>
              <MoneyField
                name={FIELDS.COVERAGE.DEDUCTIBLE_CENTS}
                value={centsToDollars(coverage[FIELDS.COVERAGE.DEDUCTIBLE_CENTS])}
                onChange={onLimitChange(index, vehicleIndex)}
              />
            </div>
            <div>
              {INCLUDED_COVERAGES.has(coverage.name) && (
                <Checkbox
                  checked={INCLUDED_COVERAGES.has(coverage.name) && noLimits(coverage)}
                  onChange={(_, checked) => checked && clearLimits(index, vehicleIndex)()}
                />
              )}
            </div>
            <div className={styles.actions}>
              <button onClick={onDelete(index, vehicleIndex)} className={styles.delete}>
                <HiX />
              </button>
              <span title={'Incomplete'}>{!isValidCoverage(coverage) && `⚠️`}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

CoverageTable.propTypes = {
  coverages: PropTypes.array,
  isAuto: PropTypes.bool,
  onAdd: PropTypes.func,
  onCoverageSelect: PropTypes.func,
  onDelete: PropTypes.func,
  onLimitChange: PropTypes.func,
  title: PropTypes.string,
  vehicleIndex: PropTypes.number,
  clearLimits: PropTypes.func
};

export default CoverageTable;

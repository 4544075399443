import { GENERAL_FIELDS } from 'woop-shared/db/models';
import { ADD_QUOTE, UPDATE_QUOTE, DELETE_QUOTE_SUCCESS } from '../actions/quotes';
import { SET_QUOTESET } from '../actions/quoteset';
import { CREATE_DATE } from '../constants/fields';

function quotes(state = {}, action) {
  switch (action.type) {
    case ADD_QUOTE: {
      const { payload: quote } = action;
      return {
        ...state,
        [quote[CREATE_DATE]]: quote
      };
    }
    case DELETE_QUOTE_SUCCESS: {
      const { payload: id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case UPDATE_QUOTE: {
      const { id, updateObj } = action.payload;
      const quoteToUpdate = state[id];
      return { ...state, [id]: { ...quoteToUpdate, ...updateObj } };
    }
    case SET_QUOTESET: {
      const { allQuotes } = action.payload;
      if (!allQuotes) return state;
      const quotesObj = {};
      allQuotes.forEach(quote => {
        quotesObj[quote[GENERAL_FIELDS.ID]] = quote;
      });
      return quotesObj;
    }
    default:
      return state;
  }
}

export default quotes;

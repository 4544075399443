import { connect } from 'react-redux';
import BundledQuotes from '.';
import {
  addBundledQuote,
  deleteBundledQuote,
  updateBundledQuote
} from '../../../../actions/bundled-quotes';

const mapStateToProps = ({ bundledQuotes, quotes, carriers }) => {
  return {
    bundledQuotes: Object.values(bundledQuotes),
    quotes: Object.values(quotes),
    carriers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addBundle: newBundle => {
      dispatch(addBundledQuote(newBundle));
    },
    deleteBundle: payload => {
      dispatch(deleteBundledQuote(payload));
    },
    updateBundle: (id, quoteIndex, value) => {
      dispatch(updateBundledQuote({ id, quoteIndex, value }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BundledQuotes);

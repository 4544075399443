import axios from 'axios';
import { getIvSetsEndpoint } from '../utils/api';

/**
 * Find an IV set using the interactive IV sets API.
 *
 * @param {object} params
 * @param {object} params.financeKey
 * @param {object} params.state
 * @param {'LOAN'|'LEASE'} params.financeType
 * @param {object} params.partnerId
 * @returns {Promise<Partial<{ biPerAccidentMin: number, biPerPersonMin: number, pdMin: number, compMax: number, collMax: number }>>}
 */
export async function findIvSet({ financeKey, state, financeType, partnerId }) {
  const { data } = await axios.post(getIvSetsEndpoint(), {
    state,
    finco: financeKey,
    fintype: financeType,
    oeKey: partnerId
  });
  return data;
}

import axios from 'axios';
import { concatUri } from '../utils/url.js';

const CARRIERS_URI = concatUri(process.env.DATA_URI, 'carriers');

const getCarriers = async () => {
  const { data } = await axios.get(CARRIERS_URI);
  return data;
};

export default {
  getCarriers
};

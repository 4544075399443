import React from 'react';
import PropTypes from 'prop-types';
import Table from '../Table';

const UserTable = ({ user }) => (
  <Table
    spaceBetween={true}
    rows={[
      { label: 'Name', value: `${user.firstName} ${user.lastName}` },
      { label: 'DOB', value: user.dob },
      { label: 'Email', value: user.email },
      { label: 'Phone', value: user.phone }
    ]}
  />
);

UserTable.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserTable;

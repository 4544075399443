import React from 'react';
import PropTypes from 'prop-types';
import { FiExternalLink } from 'react-icons/fi';
import {
  getCoverageTypes,
  getAddressComposite,
  getPrimaryFirstName,
  getPrimaryLastName
} from 'woop-shared/utils';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import styles from './styles.css';
import { addIdToRoute, ROUTES } from '../../../../router/routes';
import Card from '../../../../components/Card';

const ResultCard = ({ journeyId, uiData, createdAt, lastUpdatedAt }) => {
  const firstName = getPrimaryFirstName(uiData);
  const lastName = getPrimaryLastName(uiData);
  const coverageTypes = getCoverageTypes(uiData).join(' + ');
  const addressComposite = getAddressComposite(uiData?.[PROMPT_NAMES.ADDRESS]);
  const created = createdAt && new Date(createdAt).toLocaleString();
  const updated = lastUpdatedAt && new Date(lastUpdatedAt).toLocaleString();

  return (
    <Card
      key={journeyId}
      className={styles.wrapper}
      onClick={() => window.open(addIdToRoute(ROUTES.APPLICANT_VIEW, journeyId), '_blank')}
    >
      <div className={styles.cardHeader}>
        <div className={styles.topRow}>{`${lastName}, ${firstName} | ${coverageTypes}`}</div>
        <div>{addressComposite}</div>
        {uiData[PROMPT_NAMES.UNIT_NUMBER] && (
          <div>{`Unit Number: ${uiData[PROMPT_NAMES.UNIT_NUMBER]}`}</div>
        )}
        <div className={styles.date}>{`Created: ${created}`}</div>
        <div className={styles.date}>{`Last Updated: ${updated}`}</div>
        <div className={styles.toggle}>
          <FiExternalLink />
        </div>
      </div>
    </Card>
  );
};

ResultCard.propTypes = {
  journeyId: PropTypes.string,
  uiData: PropTypes.object,
  createdAt: PropTypes.string,
  lastUpdatedAt: PropTypes.string
};

export default ResultCard;

import axios from 'axios';
import { concatUri } from '../utils/url.js';

const PARTNERS_URI = concatUri(process.env.DATA_URI, 'partners');

const getPartners = async () => {
  const { data } = await axios.get(PARTNERS_URI);
  return data;
};

const getPartner = async id => {
  const { data } = await axios.get(concatUri(PARTNERS_URI, id));
  return data;
};

export default {
  getPartners,
  getPartner
};

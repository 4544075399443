import { QA_QUOTESET_SUCCESS } from '../actions/quoteset';

function qa(state = { quotesReadyUrl: '' }, action) {
  switch (action.type) {
    case QA_QUOTESET_SUCCESS: {
      const { payload } = action;
      const { quotesReadyUrl } = payload;
      return { ...state, quotesReadyUrl };
    }
    default:
      return state;
  }
}

export default qa;

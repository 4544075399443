import { stripNonDigits } from 'woop-shared/quotes/utils';

/**
 * @param {string} input
 * @returns {string}
 */
function dashSSN(input) {
  let val = input;
  val = val.replace(/^(.{3})$/, '$1-');
  val = val.replace(/^(.{3})-(.{2})$/, '$1-$2-');
  val = val.replace(/(.{2})-(.{4})$/, '$1-$2');
  return val;
}

/**
 * @param {string} ssn
 * @param {boolean} leaveLastFour
 * @returns {string}
 */
function maskSSN(ssn, leaveLastFour) {
  var val = ssn;
  if (leaveLastFour) {
    // Mask only first two segments
    val = val.replace(/^(.{3})-/, 'XXX-');
    val = val.replace(/^(.{3})-(.{2})-/, 'XXX-XX-');
  } else {
    // Replace everything except '-' with 'X'
    val = val.replace(/(?!-)(.)/g, 'X');
  }
  return val;
}

/**
 * @param {string} keyInput
 * @param {string} currentValue
 * @returns {string}
 */
export function formatSSNMasked(keyInput, currentValue) {
  const dashedInput = formatSSN(keyInput, currentValue);
  const maskedInput = maskSSN(dashedInput, true);
  return maskedInput;
}

/**
 * @param {string} keyInput
 * @param {string} currentValue
 * @returns {string}
 */
export function formatSSN(keyInput, currentValue) {
  const cleanedKeyInput = stripNonDigits(keyInput);
  // Append cleaned input to current value
  const cleanedValue = currentValue + cleanedKeyInput;

  const dashedInput = dashSSN(cleanedValue);
  return dashedInput;
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import { FiPlus } from 'react-icons/fi';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import {
  includesAnnualOrSemi,
  validateNumOfPayments,
  validatePremiums
} from 'woop-shared/quotes/validation';
import { PAYMENT_METHODS, PAYMENT_PLANS as PLANS, PREMIUM_FIELDS } from 'woop-shared/quotes/enums';
import Select from '../Select';
import { getBillingCycleOptions, hasEqualAmounts, newPremiumTemplate } from './utils';
import Button from '../Button';
import styles from './Premiums.css';
import Prompt from '../Prompt';
import DecimalMoneyField from '../DecimalMoneyField';
import { PAYMENT_PLAN_OPTIONS, PAYMENT_METHODS_BY_COVERAGE_TYPE } from './constants';
import { createOptionsFromEnum } from '../Select/utils';
import LinkText from '../LinkText';
import NumberField from '../NumberField';

const PAYMENT_PLANS = Object.keys(PLANS);

const Premiums = ({ name, onChange, showValidation, premiums, coverageType, isCurrentPolicy }) => {
  const [showAllAmounts, setShowAllAmounts] = useState([]);
  const paymentMethods = PAYMENT_METHODS_BY_COVERAGE_TYPE[coverageType];
  const availablePlans = getBillingCycleOptions(premiums);

  const saveToQuote = premiums => {
    onChange(name, premiums);
  };

  const handleAdd = () => {
    const existingPremiums = premiums || [];
    const hasAnnual = !!existingPremiums.find(
      p => p[PREMIUM_FIELDS.PAYMENT_PLAN] === PLANS.ANNUALLY
    );
    const newPremiums = [
      ...existingPremiums,
      ...paymentMethods
        .filter(method => (hasAnnual ? method !== PAYMENT_METHODS.ESCROW : true))
        .map(method => newPremiumTemplate(method, availablePlans?.[0].value))
    ];
    saveToQuote(newPremiums);
  };

  const handleDelete = paymentPlan => {
    const newPremiums = premiums?.filter(p => p[PREMIUM_FIELDS.PAYMENT_PLAN] !== paymentPlan);
    saveToQuote(newPremiums);
  };

  const updateAllAmounts = paymentPlan => (fieldName, value) => {
    const copyOfPremiums = [...premiums];
    const newPremiums = copyOfPremiums.map(p => {
      const copyOfPremium = { ...p };
      if (p[PREMIUM_FIELDS.PAYMENT_PLAN] === paymentPlan) {
        copyOfPremium[fieldName] = +value;
      }
      return copyOfPremium;
    });
    saveToQuote(newPremiums);
  };

  const updateAmount = premium => (fieldName, value) => {
    const index = premiums.indexOf(premium);
    const updatedPremium = {
      ...premium,
      [fieldName]: +value
    };
    const updatedPremiums = [...premiums];
    updatedPremiums.splice(index, 1, updatedPremium);
    saveToQuote(updatedPremiums);
  };

  const updatePaymentPlan = paymentPlan => (name, value) => {
    const copyOfPremiums = [...premiums];
    const newPremiums = copyOfPremiums
      .map(p => {
        const copyOfPremium = { ...p };

        if (copyOfPremium[PREMIUM_FIELDS.PAYMENT_PLAN] === paymentPlan) {
          copyOfPremium[PREMIUM_FIELDS.PAYMENT_PLAN] = value;
          delete copyOfPremium[PREMIUM_FIELDS.DOWN_PAYMENT];
          delete copyOfPremium[PREMIUM_FIELDS.NUM_OF_PAYMENTS];
        }

        return copyOfPremium;
      })
      .filter(premium =>
        paymentPlan === PLANS.ANNUALLY && value !== PLANS.ANNUALLY
          ? premium[PREMIUM_FIELDS.PAYMENT_METHOD] !== PAYMENT_METHODS.ESCROW
          : true
      );
    if (showAllAmounts.includes(paymentPlan)) toggleShowAmounts(value);
    saveToQuote(newPremiums);
  };

  const rmPaymentMethod = paymentPlan => (_, newOptions, removedOption) => {
    if (!newOptions?.length) return; // Disable user from rm'ing all items.

    const filteredPremiums = premiums.filter(p => {
      return !(
        p[PREMIUM_FIELDS.PAYMENT_PLAN] === paymentPlan &&
        p[PREMIUM_FIELDS.PAYMENT_METHOD] === removedOption
      );
    });

    saveToQuote(filteredPremiums);
  };

  const addPaymentMethod = premium => (_, newOptions, addedOption) => {
    const paymentPlan = premium[PREMIUM_FIELDS.PAYMENT_PLAN];
    // Create template premium objects per allowed payment method,
    const templatePremium = {
      ...premium,
      [PREMIUM_FIELDS.PAYMENT_PLAN]: paymentPlan,
      [PREMIUM_FIELDS.PAYMENT_METHOD]: addedOption
    };
    saveToQuote([...premiums, templatePremium]);
  };

  function toggleShowAmounts(paymentPlan) {
    if (showAllAmounts.includes(paymentPlan)) return;
    setShowAllAmounts([...showAllAmounts, paymentPlan]);
  }

  const premiumsValid = showValidation ? validatePremiums(premiums, isCurrentPolicy) : true;
  const planCount = PAYMENT_PLANS.map(paymentPlan =>
    premiums.find(prem => prem[PREMIUM_FIELDS.PAYMENT_PLAN] === paymentPlan)
  ).filter(Boolean).length;

  // Get unique payment plans in premiums[]
  let plans = [];
  premiums.forEach(
    p =>
      !plans.includes(p[PREMIUM_FIELDS.PAYMENT_PLAN]) && plans.push(p[PREMIUM_FIELDS.PAYMENT_PLAN])
  );

  function renderPremiumFields(premium, separateAmounts) {
    const method = premium[PREMIUM_FIELDS.PAYMENT_METHOD];
    const paymentPlan = premium[PREMIUM_FIELDS.PAYMENT_PLAN];

    const onChange = separateAmounts ? updateAmount(premium) : updateAllAmounts(paymentPlan);
    const maybePrefix = label => (separateAmounts ? `${method} ${label}` : label);

    return (
      <div key={method} className={styles.amountForMethod}>
        {paymentPlan === PLANS.INSTALLMENT && (
          <>
            <NumberField
              label="Num. of payments"
              name={PREMIUM_FIELDS.NUM_OF_PAYMENTS}
              value={premium[PREMIUM_FIELDS.NUM_OF_PAYMENTS]}
              showValidation={showValidation}
              isValid={validateNumOfPayments(premium[PREMIUM_FIELDS.NUM_OF_PAYMENTS])}
              onChange={updateAllAmounts(paymentPlan)}
            />
            <DecimalMoneyField
              placeholder={`$`}
              label={maybePrefix(`Down payment`)}
              name={PREMIUM_FIELDS.DOWN_PAYMENT}
              value={premium[PREMIUM_FIELDS.DOWN_PAYMENT]}
              showValidation={showValidation}
              isValid={!!premium[PREMIUM_FIELDS.DOWN_PAYMENT]}
              onChange={onChange}
            />
          </>
        )}
        <DecimalMoneyField
          placeholder={`$`}
          label={maybePrefix(
            paymentPlan === PLANS.INSTALLMENT ? 'Installment amount' : 'Base amount'
          )}
          name={PREMIUM_FIELDS.AMOUNT}
          value={premium[PREMIUM_FIELDS.AMOUNT]}
          showValidation={showValidation}
          isValid={!!premium[PREMIUM_FIELDS.AMOUNT]}
          onChange={onChange}
        />
      </div>
    );
  }

  return (
    <section key={name} className={styles.wrapper}>
      <Prompt invalid={showValidation && !premiumsValid}>Premiums</Prompt>
      {showValidation && !includesAnnualOrSemi(premiums) && (
        <p className={styles.errorText}>Premiums must include an annual or semi-annual plan.</p>
      )}
      {plans.map(paymentPlan => {
        // Skip render if no premiums for this plan.
        const premiumsForPlan = premiums.filter(
          prem => prem[PREMIUM_FIELDS.PAYMENT_PLAN] === paymentPlan
        );
        if (!premiumsForPlan.length) return null;

        const paymentPlanOptions = createOptionsFromEnum(
          paymentMethods.filter(pmt =>
            pmt === PAYMENT_METHODS.ESCROW ? paymentPlan === PLANS.ANNUALLY : true
          )
        );

        const currentMethods = premiumsForPlan
          .filter(prem => !!prem[PREMIUM_FIELDS.PAYMENT_METHOD])
          .map(prem => ({
            label: prem[PREMIUM_FIELDS.PAYMENT_METHOD],
            value: prem[PREMIUM_FIELDS.PAYMENT_METHOD]
          }));

        if (!hasEqualAmounts(premiumsForPlan)) toggleShowAmounts(paymentPlan);

        const sourcePremium = premiumsForPlan[0]; // Copy amount from the first premium

        const showAmountsPerPremium = showAllAmounts.includes(paymentPlan);

        return (
          <div className={styles.premium} key={paymentPlan}>
            <div>
              <Select
                placeholder="Payment Plan"
                options={availablePlans}
                isClearable={false}
                value={{ label: PAYMENT_PLAN_OPTIONS[paymentPlan], value: paymentPlan }}
                name={PREMIUM_FIELDS.PAYMENT_PLAN}
                onChange={updatePaymentPlan(paymentPlan)}
              />
              <div className={styles.paymentMethods}>
                <Select
                  options={paymentPlanOptions}
                  isMulti
                  isClearable={false}
                  onAdd={addPaymentMethod(sourcePremium)}
                  onRemove={rmPaymentMethod(paymentPlan)}
                  value={currentMethods}
                />
              </div>
              <div className={styles.amounts}>
                {showAmountsPerPremium ? (
                  <>
                    {/* Render amount inputs per payment method */}
                    {premiums
                      .filter(p => p[PREMIUM_FIELDS.PAYMENT_PLAN] === paymentPlan)
                      .map(premium => {
                        return renderPremiumFields(premium, showAmountsPerPremium);
                      })}
                  </>
                ) : (
                  renderPremiumFields(sourcePremium)
                )}
              </div>
              <LinkText
                onClick={() => toggleShowAmounts(paymentPlan)}
                disabled={showAmountsPerPremium}
              >
                Separate amounts per payment method
              </LinkText>
            </div>

            <Button
              disabled={planCount === 1}
              className={styles.delete}
              onClick={() => handleDelete(paymentPlan)}
            >
              <FiX />
            </Button>
          </div>
        );
      })}
      <Button
        disabled={planCount === PAYMENT_PLANS.length}
        className={styles.add}
        onClick={handleAdd}
      >
        Add premium <FiPlus />
      </Button>
    </section>
  );
};

export default Premiums;

Premiums.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  showValidation: PropTypes.bool,
  premiums: PropTypes.arrayOf(PropTypes.object),
  coverageType: PropTypes.oneOf(Object.values(COVERAGE_TYPES)),
  isCurrentPolicy: PropTypes.bool
};

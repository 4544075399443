import React from 'react';
import PropTypes from 'prop-types';
import { validateFees } from 'woop-shared/quotes/validation';
import { FEE_FIELDS as FIELDS } from 'woop-shared/quotes/enums';
import styles from './CarrierFees.css';
import Prompt from '../Prompt';
import DecimalMoneyField from '../DecimalMoneyField';
import { newFeeTemplate } from './utils';

const CarrierFees = ({ name, onChange, fees, showValidation }) => {
  const handleFieldChange = (paymentMethod, feeType, value) => {
    const paymentMethodObj = { ...fees[paymentMethod] };
    const update = { ...paymentMethodObj, [feeType]: value };
    const newFees = { ...fees, [paymentMethod]: update };
    onChange(name, newFees);
  };

  if (!fees) fees = newFeeTemplate();

  // Invalid means there's a service charge and an installment fee at the same time.
  const feesAreValid = validateFees(fees);

  return (
    <section className={styles.wrapper}>
      <Prompt invalid={showValidation && !feesAreValid}>Service Charges</Prompt>
      <div className={styles.fees}>
        <DecimalMoneyField
          label="EFT Service Charge"
          value={fees[FIELDS.ACH]?.[FIELDS.SERVICE_CHARGE]}
          onChange={(_, value) => handleFieldChange(FIELDS.ACH, FIELDS.SERVICE_CHARGE, value)}
        />
        <DecimalMoneyField
          label="CC Service Charge"
          value={fees[FIELDS.CARD]?.[FIELDS.SERVICE_CHARGE]}
          onChange={(_, value) => handleFieldChange(FIELDS.CARD, FIELDS.SERVICE_CHARGE, value)}
        />
      </div>
      <Prompt invalid={showValidation && !feesAreValid}>Installment Fees</Prompt>
      <div className={styles.fees}>
        <DecimalMoneyField
          label="EFT Installment Fee"
          value={fees[FIELDS.ACH]?.[FIELDS.INSTALLMENT_FEE]}
          onChange={(_, value) => handleFieldChange(FIELDS.ACH, FIELDS.INSTALLMENT_FEE, value)}
        />
        <DecimalMoneyField
          label="CC Installment Fee"
          value={fees[FIELDS.CARD]?.[FIELDS.INSTALLMENT_FEE]}
          onChange={(_, value) => handleFieldChange(FIELDS.CARD, FIELDS.INSTALLMENT_FEE, value)}
        />
      </div>
    </section>
  );
};

export default CarrierFees;

CarrierFees.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  showValidation: PropTypes.bool,
  fees: PropTypes.shape({
    [FIELDS.ACH]: PropTypes.shape({
      [FIELDS.SERVICE_CHARGE]: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      [FIELDS.INSTALLMENT_FEE]: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    [FIELDS.CARD]: PropTypes.shape({
      [FIELDS.SERVICE_CHARGE]: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      [FIELDS.INSTALLMENT_FEE]: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  })
};

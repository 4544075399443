// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__tableHeader--RUOOy{display:flex;align-items:center;grid-gap:10px;gap:10px;margin-bottom:10px}.styles-module__tableHeader--RUOOy a{font-size:var(--fs-sm)}.styles-module__empty--naaVj{box-sizing:border-box;width:100%;padding:3rem 1rem;display:flex;align-items:center;justify-content:center;background:var(--color-grey-lightest);border:1px solid var(--color-grey-light);border-radius:4px}", ""]);
// Exports
exports.locals = {
	"tableHeader": "styles-module__tableHeader--RUOOy",
	"empty": "styles-module__empty--naaVj"
};
module.exports = exports;

import { APPLICANTS_FOUND } from '../actions/journeys';

function journeys(state = [], action) {
  switch (action.type) {
    case APPLICANTS_FOUND:
      return action.payload;
    default:
      return state;
  }
}

export default journeys;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { FiPlus } from 'react-icons/fi';
import ReactRouterProps from 'react-router-prop-types';
import { QUOTESET_FIELDS, GENERAL_FIELDS, QUOTE_FIELDS, FOREIGN_KEYS } from 'woop-shared/db/models';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import Card from '../../components/Card';
import Button from '../../components/Button';
import {
  createQuote,
  getQuoteId,
  copyQuote,
  alreadyHasPolicies,
  getConfirmMessage,
  getQuotesetErrors,
  validateSupplementaryInfo,
  compareQuotes
} from './utils';
import styles from './CreateQuoteset.css';
import { TOO_MANY_POLICIES_MESSAGE } from './constants';
import ErrorText from '../../components/ErrorText';
import SupplementaryInfo from '../../components/SupplementaryInfo';
import {
  createSupplementaryInfo,
  getCovTypeForSupplementaryFields
} from '../../components/SupplementaryInfo/utils';
import BundledQuotes from './components/BundledQuotes/container';
import QuotesetSummary from '../../components/QuotesetSummary';
import QuoteUploadModal from '../../components/QuoteUpload/container';
import Finalization from './components/Finalization';
import FinalizeQuotesetModal from '../../components/FinalizeQuotesetModal';
import MortgageInfo from './components/MortgageInfo';
import LinkText from '../../components/LinkText';
import PaymentModal from './components/PaymentModal';
import { useModalControls } from '../../hooks/modal-controls';
import QuoteModal from './components/QuoteModal';
import QuotesList from './components/QuotesList';
import AgentInfo from './components/AgentInfo';

const cx = classnames.bind(styles);

const CreateQuoteset = ({
  journeySummary,
  history,
  setQuotesetField,
  getQuoteset,
  saveQuoteset,
  finalizeQuoteset,
  loading,
  setError,
  error,
  quotes: allQuotes,
  addQuote,
  deleteQuote,
  updateQuote,
  quoteset,
  qaQuoteset,
  qa,
  bundledQuotes
}) => {
  const { quotesetId } = useParams();
  const [selectedQuote, setSelectedQuote] = useState();

  useEffect(() => {
    // If id in URL and quoteset is not loaded, load it
    if (quotesetId && !quoteset[GENERAL_FIELDS.ID]) getQuoteset(quotesetId);

    // If no id in URL or in quoteset, redirect to search page
    if (!(quotesetId || quoteset[GENERAL_FIELDS.ID])) history.replace(ROUTES.QUOTES_SEARCH);
  }, []);

  useEffect(() => {
    // Initialize supplementary info so validation works
    if (journeySummary?.coverageTypes?.length > 0) {
      const supplementaryInfo = createSupplementaryInfo(
        journeySummary.coverageTypes,
        quoteset[QUOTESET_FIELDS.SUPPLEMENTARY_INFO]
      );
      setQuotesetField(QUOTESET_FIELDS.SUPPLEMENTARY_INFO, supplementaryInfo);
    }
  }, [journeySummary]);

  const [finalizeModalVisible, finalizeModalClosing, toggleFinalizeModal] = useModalControls(false);
  const [paymentModalVisible, paymentModalClosing, togglePaymentModal] = useModalControls(false);
  const [editModalVisible, editModalClosing, toggleEditModal] = useModalControls(false);
  const [uploadModalVisible, uploadModalClosing, toggleUploadModal] = useModalControls(false);

  const handleAddQuote = isCurrentPolicy => () => {
    if (isCurrentPolicy && alreadyHasPolicies(allQuotes, journeySummary?.coverageTypes))
      return alert(TOO_MANY_POLICIES_MESSAGE);

    const newQuote = createQuote(journeySummary.coverageTypes, isCurrentPolicy);
    addQuote(newQuote);
    setSelectedQuote(newQuote);
    toggleEditModal();
  };

  const handleCopyQuote = (e, quote) => {
    e.stopPropagation();
    const newQuote = copyQuote(quote);
    addQuote(newQuote);
    saveQuoteset();
    setSelectedQuote(newQuote);
  };

  const handleDeleteQuote = (e, quote) => {
    e.stopPropagation();
    const id = getQuoteId(quote);
    deleteQuote({ id, saved: !!quote[GENERAL_FIELDS.ID] });
    setSelectedQuote(null);
  };

  const handleEditQuote = (e, quote) => {
    e.stopPropagation();
    setSelectedQuote(quote);
    toggleEditModal();
  };

  const handleSupplementaryFieldChange = (name, value) => {
    setQuotesetField(QUOTESET_FIELDS.SUPPLEMENTARY_INFO, {
      ...quoteset?.[QUOTESET_FIELDS.SUPPLEMENTARY_INFO],
      [name]: value
    });
  };

  const validateQuoteset = () => {
    const quotesetData = {
      ...quoteset,
      coverageTypes: journeySummary.coverageTypes,
      quotes: allQuotes,
      bundledQuotes
    };
    const errors = getQuotesetErrors(quotesetData);
    if (!errors.length) {
      setError();
      return true;
    }

    setError({ message: errors[0] });
  };

  const handleQa = () => validateQuoteset() && qaQuoteset();

  const validateAndFinalize = () => validateQuoteset() && toggleFinalizeModal();

  const handleFinalize = finalizeData => {
    const notifyConsumer = finalizeData?.notifyConsumer;
    if (confirm(getConfirmMessage(notifyConsumer, journeySummary))) {
      const id = quoteset[GENERAL_FIELDS.ID];
      const callback = () => window.location.reload(true);
      finalizeQuoteset({ id, ...finalizeData, callback });
    }
  };

  const handleSave = () => saveQuoteset();

  const hasErrorState = !!error?.message;
  const supplementaryFieldsCovType = getCovTypeForSupplementaryFields(journeySummary.coverageTypes);
  const showMortgage =
    journeySummary.coverageTypes.includes(COVERAGE_TYPES.CONDO) ||
    journeySummary.coverageTypes.includes(COVERAGE_TYPES.HOME);

  const policies = useMemo(
    () => allQuotes.filter(q => q[QUOTE_FIELDS.IS_CURRENT_POLICY]).sort(compareQuotes),
    [allQuotes]
  );

  const quotes = useMemo(
    () => allQuotes.filter(q => !q[QUOTE_FIELDS.IS_CURRENT_POLICY]).sort(compareQuotes),
    [allQuotes]
  );

  const currentPolicyTypes = policies.map(p => p[QUOTE_FIELDS.COVERAGE_TYPE]);

  return (
    <section className={cx('wrapper', { loading })}>
      <QuotesetSummary
        journeySummary={journeySummary}
        quoteset={quoteset}
        className={styles.form}
      />
      <SupplementaryInfo
        coverageType={supplementaryFieldsCovType}
        onChange={handleSupplementaryFieldChange}
        data={quoteset?.[QUOTESET_FIELDS.SUPPLEMENTARY_INFO]}
        showValidation={
          hasErrorState &&
          !validateSupplementaryInfo(
            quoteset?.[QUOTESET_FIELDS.SUPPLEMENTARY_INFO],
            journeySummary.coverageTypes
          )
        }
        className={cx('cardFields', 'form')}
      />
      {showMortgage && <MortgageInfo className={cx('cardFields', 'form')} />}

      <QuotesList quotes={policies} editQuote={handleEditQuote} deleteQuote={handleDeleteQuote}>
        <span>Current Policies</span>
        <LinkText onClick={handleAddQuote(true)} icon={<FiPlus />}>
          Add
        </LinkText>
      </QuotesList>

      <QuotesList
        quotes={quotes}
        currentPolicyTypes={currentPolicyTypes}
        editQuote={handleEditQuote}
        deleteQuote={handleDeleteQuote}
        copyQuote={handleCopyQuote}
      >
        <span>Quotes</span>
        <LinkText
          onClick={() => {
            handleSave(); // Save before allowing more quotes to be uploaded.
            toggleUploadModal();
          }}
          icon={<FiPlus />}
        >
          Add
        </LinkText>
      </QuotesList>

      <BundledQuotes />

      <AgentInfo
        quoteset={quoteset}
        setAgent={val => setQuotesetField(FOREIGN_KEYS.AGENT, val)}
        setAgentNotes={val => setQuotesetField(QUOTESET_FIELDS.AGENT_NOTES, val)}
        hasErrorState={hasErrorState}
      />

      {quoteset[QUOTESET_FIELDS.FINALIZATION] && (
        <Finalization
          finalization={quoteset[QUOTESET_FIELDS.FINALIZATION]}
          userId={quoteset[FOREIGN_KEYS.USER]}
          quotesetId={quotesetId}
          journeyId={journeySummary.journeyId}
        />
      )}

      <Card className={styles.form}>
        <div className={styles.errorWrapper}>
          {hasErrorState && <ErrorText>{error.message}</ErrorText>}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.paymentCaseButton}
            loading={loading}
            disabled={!quoteset[QUOTESET_FIELDS.FINALIZATION]}
            onClick={togglePaymentModal}
          >
            <span>Process Payment</span>
          </Button>
          <Button className={styles.primaryButton} loading={loading} onClick={handleSave}>
            <span>Save</span>
          </Button>
          <Button className={styles.primaryButton} loading={loading} onClick={handleQa}>
            <span>QA Quotes</span>
          </Button>
          <Button className={styles.finalizeButton} loading={loading} onClick={validateAndFinalize}>
            <span>Finalize ✅</span>
          </Button>
        </div>
      </Card>

      {qa.quotesReadyUrl && (
        <Card className={styles.qaWrapper}>
          <div className={styles.qaTitle}>
            <strong>Your QA Quoteset is ready ✅</strong>
          </div>
          <LinkText href={qa.quotesReadyUrl} external>
            View QA Quoteset
          </LinkText>
          <div className={styles.helperText}>
            {`If you make additional changes to this quoteset, you will need to click the "QA Quoteset" button again.`}
          </div>
        </Card>
      )}

      <FinalizeQuotesetModal
        visible={finalizeModalVisible}
        closing={finalizeModalClosing}
        toggleModal={toggleFinalizeModal}
        handleFinalize={handleFinalize}
        coverageTypes={journeySummary.coverageTypes}
        quotes={allQuotes}
        userId={quoteset[FOREIGN_KEYS.USER]}
      />
      <QuoteUploadModal
        visible={uploadModalVisible}
        closing={uploadModalClosing}
        toggleModal={toggleUploadModal}
        loading={loading}
        journeySummary={journeySummary}
        addManually={handleAddQuote(false)}
      />
      <PaymentModal
        visible={paymentModalVisible}
        closing={paymentModalClosing}
        toggleModal={togglePaymentModal}
      />
      <QuoteModal
        visible={editModalVisible}
        closing={editModalClosing}
        toggleModal={toggleEditModal}
        quote={selectedQuote || {}}
        journeySummary={journeySummary}
        updateQuote={updateQuote}
      />
    </section>
  );
};

export default CreateQuoteset;

CreateQuoteset.propTypes = {
  journeySummary: PropTypes.shape({
    coverageTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(COVERAGE_TYPES))),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    stateAbbrev: PropTypes.string,
    originationId: PropTypes.string,
    journeyId: PropTypes.string
  }),
  history: ReactRouterProps.history,
  finalizeQuoteset: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  setError: PropTypes.func,
  quotes: PropTypes.arrayOf(PropTypes.object),
  addQuote: PropTypes.func,
  deleteQuote: PropTypes.func,
  updateQuote: PropTypes.func,
  quoteset: PropTypes.object,
  setQuotesetField: PropTypes.func,
  getQuoteset: PropTypes.func,
  saveQuoteset: PropTypes.func,
  qaQuoteset: PropTypes.func,
  qa: PropTypes.shape({
    quotesReadyUrl: PropTypes.string
  }),
  bundledQuotes: PropTypes.object
};

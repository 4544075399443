import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { APPLICANT_FIELDS } from '../../constants/origination';
import { createSelectOption, createSelectOptions } from '../Select/utils';
import { getRoles } from '../../api/roles';

const Role = ({ value, industry, handleChange, id, validate = true }) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    (async () => {
      // fetch roles for industry
      const rolesArray = industry ? await getRoles(industry) : [];
      setRoles(rolesArray);

      // clear role field if industry changes
      if (!rolesArray.includes(value)) handleChange(APPLICANT_FIELDS.ROLE, null);

      // auto-fill role if industry has only one role
      if (rolesArray.length === 1) handleChange(APPLICANT_FIELDS.ROLE, rolesArray[0]);
    })();
  }, [industry]);

  return (
    <Select
      prompt="Role"
      placeholder="Search..."
      name={APPLICANT_FIELDS.ROLE}
      options={createSelectOptions(roles)}
      onChange={handleChange}
      value={createSelectOption(value)}
      showValidation={validate && !!value}
      isValid={validate ? value && roles.includes(value) : true}
      validationText="Please select a valid role."
      id={id}
    />
  );
};

export default Role;

Role.propTypes = {
  value: PropTypes.string,
  industry: PropTypes.string,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  validate: PropTypes.bool
};

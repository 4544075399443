import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../components/Card';
import Modal from '../../components/Modal';
import { useModalControls } from '../../hooks/modal-controls';
import Form from './components/Form';
import Table from './components/Table';
import styles from './styles.css';

const TransunionView = ({
  journeyId,
  uiData,
  originationId,
  vehicles,
  applicants,
  getJourney,
  getTransunion,
  postTransunion,
  loading
}) => {
  const { journeyId: journeyIdUrl } = useParams();
  const [visible, closing, toggleModal] = useModalControls(false);

  const submit = data => () => postTransunion({ oid: originationId, ...data });
  const submitAndClose = data => () => {
    postTransunion({ oid: originationId, ...data });
    toggleModal();
  };

  useEffect(() => {
    if (!uiData || journeyId !== journeyIdUrl) {
      getJourney(journeyIdUrl);
    } else if (originationId && !vehicles && !applicants) {
      getTransunion(originationId);
    }
  }, []);

  useEffect(() => {
    if (originationId && !vehicles && !applicants) {
      getTransunion(originationId);
    }
  }, [uiData]);

  return (
    <Card className={styles.wrapper}>
      {loading ? (
        <div>Loading...</div>
      ) : vehicles || applicants ? (
        <>
          <div className={styles.header}>
            Transunion Data
            <a className={styles.rerunLink} onClick={toggleModal}>
              Rerun Search
            </a>
          </div>
          <Table loading={loading} vehicles={vehicles} applicants={applicants} />
        </>
      ) : (
        <>
          <div className={styles.header}>Transunion Search</div>
          <Form uiData={uiData} loading={loading} submit={submit} />
        </>
      )}
      <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
        <div className={styles.header}>Transunion Search</div>
        <Form uiData={uiData} loading={loading} submit={submitAndClose} />
      </Modal>
    </Card>
  );
};

TransunionView.propTypes = {
  journeyId: PropTypes.string,
  uiData: PropTypes.object,
  originationId: PropTypes.string,
  vehicles: PropTypes.array,
  applicants: PropTypes.array,
  getJourney: PropTypes.func,
  getTransunion: PropTypes.func,
  postTransunion: PropTypes.func,
  loading: PropTypes.bool
};

export default TransunionView;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--28Og1 .styles__links--p__nv{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.styles__wrapper--28Og1 .styles__links--p__nv .styles__record--3Rjcr{min-height:30px;transition:.3s;display:flex;align-items:center;justify-content:space-between}.styles__wrapper--28Og1 .styles__links--p__nv .styles__record--3Rjcr div:last-of-type{flex-grow:0;margin-left:auto}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--28Og1",
	"links": "styles__links--p__nv",
	"record": "styles__record--3Rjcr"
};
module.exports = exports;

import { FOREIGN_KEYS } from 'woop-shared/db/models';
import { connect } from 'react-redux';
import { APPLICANT_TYPES } from 'woop-shared/origination/enums';
import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import FinalizeOriginationModal from '.';
import {
  finalizeOrigination,
  convertToQuoteset,
  validateOrigination
} from '../../actions/originations';
import { APPLICANT_FIELDS } from '../../constants/origination';

const mapStateToProps = ({ origination, applicants, loading, originators, error }) => {
  const accountId = origination[ORIGINATION_FIELDS.ACCOUNT_ID];
  const originator = originators?.find(
    o =>
      o.externOriginatorId === origination[ORIGINATION_FIELDS.EXTERN_ORIGINATOR_ID] &&
      o[FOREIGN_KEYS.PARTNER_ACCOUNT] === accountId
  );
  const primaryApplicant = Object.values(applicants).find(a =>
    a[APPLICANT_FIELDS.APPLICANT_TYPE]?.includes(APPLICANT_TYPES.APPLICANT)
  );

  return {
    originator,
    primaryApplicant,
    loading,
    error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    finalizeOrigination: payload => {
      dispatch(finalizeOrigination(payload));
    },
    validateOrigination: payload => dispatch(validateOrigination(payload)),
    convertToQuoteset: payload => dispatch(convertToQuoteset(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalizeOriginationModal);

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Checkbox } from '@mui/material';
import { formatMoney } from 'woop-shared/quotes/utils';
import { FOREIGN_KEYS, GENERAL_FIELDS } from 'woop-shared/db/models';
import { HiArrowRight, HiOutlineExclamation, HiRefresh } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from '../../components/Card';
import styles from './styles.module.css';
import { getQuoteApplication, getQuoteResult } from '../../api/quoteset.js';
import { setQuoteResult } from '../../actions/quote-result.js';
import { formatSectionTitle, getAnnualizedTotal } from './utils';
import Button from '../../components/Button';
import { getCarriers } from '../../actions/carriers';
import { ROUTES, addIdToRoute } from '../../router/routes';
import { setQuoteApplication } from '../../actions/quote-application';
import LinkText from '../../components/LinkText';
import QuotingNav from '../../components/QuotingNav';
import ErrorSection from './components/ErrorSection';

const cx = classNames.bind(styles);

const getResults = quoteResult =>
  _.omit(quoteResult, [FOREIGN_KEYS.QUOTE_APP, FOREIGN_KEYS.QUOTE_REQUEST]);

const QuoteResult = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { quoteResult, carriers, quoteApplication } = useSelector(state => state);
  const [selected, setSelected] = useState([]);
  const [errorsVisible, setErrorsVisible] = useState([]);

  useEffect(() => {
    getQuoteResult(id).then(res => {
      dispatch(setQuoteResult(res));
      dispatch(getCarriers());
      getQuoteApplication(res[FOREIGN_KEYS.QUOTE_APP]).then(quoteApp => {
        dispatch(setQuoteApplication(quoteApp));
      });
    });
  }, []);

  const handleChecked = (ids, state, setter) => {
    if (ids.every(id => state.includes(id))) setter(_.difference(state, ids));
    else setter([...state, ...ids]);
  };

  // const handleRequote = () => {};

  const openQuotesetTab = () => {
    const quotesetRoute = addIdToRoute(
      ROUTES.QUOTES_VIEW,
      quoteApplication[FOREIGN_KEYS.B_QUOTESET]
    );
    window.open(quotesetRoute, '_blank', 'noopener,noreferrer');
  };

  if (!carriers?.length) return null;
  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>
        <QuotingNav
          id={quoteApplication[GENERAL_FIELDS.ID]}
          reqId={quoteResult[FOREIGN_KEYS.QUOTE_REQUEST]}
        />
        <Card className={styles.card}>
          <div className={styles.header}>
            <h3>Indicative quotes</h3>
            <p>Use the “View Quote -&gt;” link to bridge to the carrier&apos;s site.</p>
          </div>

          <div className={styles.sections}>
            {quoteResult &&
              Object.entries(getResults(quoteResult)).map(([section, bundle]) => (
                <section className={styles.section} key={section}>
                  <h4>{formatSectionTitle(section)}</h4>

                  <div className={styles.rows}>
                    <div className={cx('row', 'header')}>
                      <div>Carrier</div>
                      <div>Annualized Premium</div>
                      <div>Auto Premium</div>
                      <div>Home Premium</div>
                      <div>Select</div>
                    </div>

                    {bundle.map((results, index) => {
                      const auto = results.find(result => result.lob === 'AUTO');
                      const home = results.find(result =>
                        ['HOME', 'RENTERS', 'CONDO'].includes(result.lob)
                      );

                      const carrier = carriers?.find(
                        carrier => carrier.code === results?.[0]?.carrier
                      );

                      const autoErrors = auto?.errors || [];
                      const autoWarnings = auto?.warnings || [];

                      const homeErrors = home?.errors || [];
                      const homeWarnings = home?.warnings || [];

                      const hasErrors =
                        autoErrors.length ||
                        autoWarnings.length ||
                        homeErrors.length ||
                        homeWarnings.length;

                      const showErrorList =
                        !!hasErrors && results.some(result => errorsVisible.includes(result.id));

                      const annualized = getAnnualizedTotal(results);

                      const renderPremium = result =>
                        result?.totalPremium ? (
                          <>
                            <span>
                              {formatMoney(Math.round(result.totalPremium))}{' '}
                              <span className={styles.term}>/ {result.termLength} mo.</span>
                            </span>
                            <a href={result.url} target="_blank" rel="noreferrer">
                              View quote -&gt;
                            </a>
                          </>
                        ) : (
                          <span className={styles.muted}>–</span>
                        );

                      return (
                        <div className={styles.row} key={index}>
                          <div className={styles.carrier}>
                            <div className={styles.logo}>
                              <img src={carrier?.logoUrl} alt={`${carrier?.name} logo`} />
                            </div>
                            <span>{carrier?.name}</span>
                          </div>
                          <div>
                            {annualized ? (
                              formatMoney(Math.round(annualized))
                            ) : (
                              <span className={styles.muted}>–</span>
                            )}
                          </div>
                          <div>{renderPremium(auto)}</div>
                          <div>{renderPremium(home)}</div>
                          <div>
                            {hasErrors ? (
                              <LinkText
                                className={styles.errorButton}
                                onClick={() =>
                                  handleChecked(
                                    results.map(result => result.id),
                                    errorsVisible,
                                    setErrorsVisible
                                  )
                                }
                              >
                                <span>{showErrorList ? 'Hide' : 'View'} Errors</span>
                                <HiOutlineExclamation />
                              </LinkText>
                            ) : (
                              <Checkbox
                                disabled
                                checked={results.every(result => selected.includes(result.id))}
                                onChange={() =>
                                  handleChecked(
                                    results.map(result => result.id),
                                    selected,
                                    setSelected
                                  )
                                }
                              />
                            )}
                          </div>
                          {showErrorList && (
                            <div className={styles.errors}>
                              {(!!autoWarnings.length || !!homeWarnings.length) && (
                                <div className={cx('list', 'yellow')}>
                                  {<ErrorSection items={autoWarnings} title="Warnings (Auto)" />}
                                  {<ErrorSection items={homeWarnings} title="Warnings (Home)" />}
                                </div>
                              )}

                              {(!!autoErrors.length || !!homeErrors.length) && (
                                <div className={cx('list', 'red')}>
                                  {<ErrorSection items={autoErrors} title="Errors (Auto)" />}
                                  {<ErrorSection items={homeErrors} title="Errors (Home)" />}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </section>
              ))}
          </div>

          <div className={styles.footer}>
            <strong>Carriers giving you trouble?</strong> We&apos;ll use ideal limits to get you
            into the carrier site. When getting a binding quote on the carrier site, be sure to
            return the customer&apos;s prior limits to their actual value.{' '}
            <a
              href="#"
              style={{ opacity: 0.5, cursor: 'not-allowed' }}
              onClick={e => {
                e.preventDefault();
                return alert('Not implemented yet! Coming soon!');
                // handleRequote();
              }}
            >
              Requote with ideal prior coverage limits <HiRefresh />
            </a>
          </div>
        </Card>
      </div>

      <div className={styles.btnWrapper}>
        <Button onClick={openQuotesetTab} className={styles.button}>
          Open quoteset <HiArrowRight />
        </Button>
      </div>
    </section>
  );
};

export default QuoteResult;

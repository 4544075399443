import React from 'react';
import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';
import styles from './styles.css';
import { getInvalidFields } from '../../utils';
import { QUOTE_FIELD_NAMES } from '../../constants';

const ValidationInfo = ({ quote, compare }) => {
  const invalidFields = getInvalidFields(quote, { compare });

  if (!invalidFields?.length) return null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <FiAlertTriangle /> Please fix the following required field
        {invalidFields.length > 1 ? 's' : ''}
      </div>
      {invalidFields.map((field, index) => (
        <div key={index} className={styles.item}>
          {QUOTE_FIELD_NAMES[field] || field}
        </div>
      ))}
    </div>
  );
};

export default ValidationInfo;

ValidationInfo.propTypes = {
  quote: PropTypes.object,
  compare: PropTypes.bool
};

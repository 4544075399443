import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ROUTES } from './routes';
import { history } from '../store/configure-store';
import CreateQuoteset from '../pages/CreateQuoteset/container';
import QuotesetSearch from '../pages/QuotesetSearch/container';
import Header from '../components/Header/';
import OriginationSearch from '../pages/OriginationSearch/container';
import Home from '../pages/Home';
import CreateOrigination from '../pages/CreateOrigination/container';
import ApplicantSearch from '../pages/ApplicantSearch/container';
import ApplicantsView from '../pages/ApplicantsView/container';
import PaymentsView from '../pages/PaymentsView/container';
import PaymentsSearch from '../pages/PaymentsSearch/container';
import ApplicantView from '../pages/ApplicantView/container';
import ReportsView from '../pages/ReportsView/container';
import Charts from '../pages/Charts';
import PolicyPage from '../pages/Policy';
import IvReportsView from '../pages/IvReportsView';
import TransunionView from '../pages/TransunionView/container';
import QuoteApplication from '../pages/QuoteApplication';
import QuoteRequest from '../pages/QuoteRequest';
import QuoteResult from '../pages/QuoteResult';

function AppRouter() {
  return (
    <ConnectedRouter history={history}>
      <Header />
      <Switch>
        <Route path={ROUTES.HOME} exact component={Home} />
        <Route path={ROUTES.QUOTE_APPLICATION} component={QuoteApplication} />
        <Route path={ROUTES.QUOTE_REQUEST} component={QuoteRequest} />
        <Route path={ROUTES.QUOTE_RESULT} component={QuoteResult} />
        <Route path={ROUTES.QUOTES_SEARCH} component={QuotesetSearch} />
        <Route path={ROUTES.QUOTES_VIEW} component={CreateQuoteset} />
        <Route path={ROUTES.ORIGINATIONS_SEARCH} component={OriginationSearch} />
        <Route path={ROUTES.ORIGINATIONS_CREATE} component={CreateOrigination} />
        <Route path={ROUTES.ORIGINATIONS_VIEW} component={CreateOrigination} />
        <Route path={ROUTES.APPLICANTS_SEARCH} component={ApplicantSearch} />
        <Route path={ROUTES.APPLICANTS_VIEW} component={ApplicantsView} />
        <Route path={ROUTES.APPLICANT_VIEW} component={ApplicantView} />
        <Route path={ROUTES.PAYMENTS_VIEW} component={PaymentsView} />
        <Route path={ROUTES.PAYMENTS_SEARCH} component={PaymentsSearch} />
        <Route path={ROUTES.REPORTS_VIEW} component={ReportsView} />
        <Route path={ROUTES.CHARTS} component={Charts} />
        <Route path={ROUTES.POLICY} component={PolicyPage} />
        <Route path={ROUTES.IV_REPORTS} component={IvReportsView} />
        <Route path={ROUTES.TRANSUNION} component={TransunionView} />
      </Switch>
    </ConnectedRouter>
  );
}

export default AppRouter;

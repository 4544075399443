import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { FiInfo } from 'react-icons/fi';
import dayjs from 'dayjs';
import React from 'react';
import Prompt from '../Prompt';
import styles from './DateField.css';
import Tooltip from '../Tooltip';

const cx = classnames.bind(styles);

function formatVal(value) {
  if (!value) return '';
  const year = value.includes('-')
    ? value.split('-')[0]
    : value.includes('/')
    ? value.split('/').at(-1)
    : null;
  const validYear = year >= 1000; // Year should be 4 digit before formatting.
  const parsedDate = dayjs(value);
  return parsedDate.isValid() && validYear ? dayjs(value).format('YYYY-MM-DD') : value;
}

const DateField = ({ prompt, value = '', id, isValid, showValidation, tooltip, ...rest }) => {
  const val = formatVal(value);
  return (
    <div>
      {prompt && (
        <Prompt invalid={showValidation && !isValid}>
          {prompt}
          {tooltip && (
            <Tooltip title={tooltip}>
              <FiInfo />
            </Tooltip>
          )}
        </Prompt>
      )}
      <input
        className={cx('date', { hasValue: !!value })}
        type="date"
        value={val}
        {...rest}
        id={id}
      />
    </div>
  );
};

DateField.propTypes = {
  id: PropTypes.string,
  isValid: PropTypes.bool,
  prompt: PropTypes.string,
  showValidation: PropTypes.bool,
  value: PropTypes.string,
  tooltip: PropTypes.string
};

export default DateField;

import React from 'react';
import { HiPencil } from 'react-icons/hi';
import PropTypes from 'prop-types';
import styles from './styles.css';
import { useModalControls } from '../../../../hooks/modal-controls';
import EditAddress from '../EditAddress';

const Address = ({ policy, refreshPolicy }) => {
  const [visible, closing, toggleModal] = useModalControls(false);

  const address = policy.dwellings?.[0]?.address;

  return (
    <div>
      <div className={styles.tableHeader}>
        Address{' '}
        {!address && (
          <a className={styles.edit} onClick={toggleModal}>
            + Add an address
          </a>
        )}
      </div>
      <div className={styles.table}>
        {address && (
          <div className={styles.row}>
            <div>{address.full_address}</div>
            <div className={styles.options}>
              <HiPencil onClick={toggleModal} />
            </div>
          </div>
        )}
      </div>
      <EditAddress
        policy={policy}
        address={address}
        refreshPolicy={refreshPolicy}
        visible={visible}
        closing={closing}
        toggleModal={toggleModal}
      />
    </div>
  );
};

Address.propTypes = {
  policy: PropTypes.object,
  address: PropTypes.object,
  refreshPolicy: PropTypes.func
};

export default Address;

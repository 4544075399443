import React from 'react';
import PropTypes from 'prop-types';
import { COVERAGES, COVERAGE_NAMES } from 'woop-shared/canopy/coverages';
import { FIELDS } from 'woop-shared/canopy/enums';
import { formatCents } from '../../../Policy/utils';
import styles from '../../styles.css';

const Coverages = ({ coverages, selectCoverage }) => {
  const coverageNames = [
    COVERAGES.BODILY_INJURY_LIABILITY,
    COVERAGES.PROPERTY_DAMAGE_LIABILITY,
    COVERAGES.COMPREHENSIVE,
    COVERAGES.COLLISION
  ];

  return (
    <div>
      <div className={styles.coverageTable}>
        <div className={styles.row}>
          <div className={styles.columnHeader}>Coverage</div>
          <div className={styles.columnHeader}>Per Person</div>
          <div className={styles.columnHeader}>Per Incident</div>
          <div className={styles.columnHeader}>Deductible</div>
          <div></div>
        </div>
        {coverageNames.map(name => {
          const coverage = coverages.find(c => c[FIELDS.COVERAGE.NAME] === name) || {};
          return (
            <div className={styles.row} key={name}>
              <div>{COVERAGE_NAMES[name]}</div>
              <div>{formatCents(coverage[FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS])}</div>
              <div>{formatCents(coverage[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS])}</div>
              <div>{formatCents(coverage[FIELDS.COVERAGE.DEDUCTIBLE_CENTS])}</div>
              <div>
                <a className={styles.edit} onClick={selectCoverage(coverage)}>
                  Edit
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Coverages.propTypes = {
  coverages: PropTypes.array,
  selectCoverage: PropTypes.func
};

export default Coverages;

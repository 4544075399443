// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--J9j3A>div{margin-bottom:40px}.styles__wrapper--J9j3A pre{padding-bottom:10px}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--J9j3A"
};
module.exports = exports;

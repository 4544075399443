import PropTypes from 'prop-types';
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { GENERAL_FIELDS, QUOTESET_FIELDS } from 'woop-shared/db/models';
import { IV_REPORT_FIELDS } from 'woop-shared/origination/fields';
import Card from '../../../../components/Card';
import LinkText from '../../../../components/LinkText';
import { addIdToRoute, ROUTES } from '../../../../router/routes';
import Badge from '../Badge';
import styles from './styles.css';

const RelatedRecords = ({
  ivReport,
  quoteset,
  originationId,
  quoteAppId,
  quoteResId,
  journeyId
}) => {
  const quotesetId = quoteset?.[GENERAL_FIELDS.ID];
  return (
    <Card className={styles.wrapper}>
      <h4>Related records</h4>
      <div className={styles.links}>
        {ivReport?.[GENERAL_FIELDS.ID] && (
          <div className={styles.record}>
            <LinkText href={addIdToRoute(ROUTES.IV_REPORTS, ivReport[GENERAL_FIELDS.ID])} external>
              IV Report <FiExternalLink />
            </LinkText>
            <Badge complete={!!ivReport[IV_REPORT_FIELDS.SENT]} />
          </div>
        )}
        {quotesetId && (
          <div className={styles.record}>
            <LinkText
              href={addIdToRoute(ROUTES.QUOTES_VIEW, quotesetId)}
              external
              icon={<FiExternalLink />}
            >
              Quoteset
            </LinkText>
            <Badge complete={!!quoteset[QUOTESET_FIELDS.FINALIZATION]} />
          </div>
        )}
        {quotesetId && (
          <div className={styles.record}>
            <LinkText
              href={addIdToRoute(ROUTES.PAYMENTS_VIEW, quotesetId)}
              external
              icon={<FiExternalLink />}
            >
              Payments
            </LinkText>
          </div>
        )}
        {originationId && (
          <div className={styles.record}>
            <LinkText
              href={addIdToRoute(ROUTES.ORIGINATIONS_VIEW, originationId)}
              external
              icon={<FiExternalLink />}
            >
              Origination
            </LinkText>
          </div>
        )}
        {quoteAppId && (
          <div className={styles.record}>
            <LinkText
              href={addIdToRoute(ROUTES.QUOTE_APPLICATION, quoteAppId)}
              external
              icon={<FiExternalLink />}
            >
              Quote Application
            </LinkText>
          </div>
        )}
        {quoteResId && (
          <div className={styles.record}>
            <LinkText href={addIdToRoute(ROUTES.QUOTE_RESULT, quoteResId)} external>
              Indicative quotes <FiExternalLink />
            </LinkText>
          </div>
        )}
        {journeyId && (
          <div className={styles.record}>
            <LinkText
              href={addIdToRoute(ROUTES.APPLICANT_VIEW, journeyId)}
              external
              icon={<FiExternalLink />}
            >
              Applicant
            </LinkText>
          </div>
        )}
      </div>
    </Card>
  );
};

RelatedRecords.propTypes = {
  ivReport: PropTypes.shape({
    id: PropTypes.string,
    sent: PropTypes.string
  }),
  originationId: PropTypes.string,
  quoteAppId: PropTypes.string,
  quoteResId: PropTypes.string,
  journeyId: PropTypes.string,
  quoteset: PropTypes.shape({
    id: PropTypes.string,
    finalization: PropTypes.object
  })
};

export default RelatedRecords;

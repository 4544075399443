import { stripNonDigits } from 'woop-shared/quotes/utils';

/**
 * @param {string} input
 * @returns {string} dasherized credit card
 */
function dashCard(input) {
  let val = input || '';
  val = val.replace(/^(\d{4})/, '$1-');
  val = val.replace(/^(\d{4})-(\d{4})/, '$1-$2-');
  val = val.replace(/^(\d{4})-(\d{4})-(\d{4})/, '$1-$2-$3-');
  val = val.replace(/^(\d{4})-(\d{4})-(\d{4})-(\d{4})$/, '$1-$2-$3-$4');
  return val;
}

function slashDate(input) {
  let val = input || '';
  val = val.replace(/^(\d{2})/, '$1/');
  return val;
}

/**
 * @param {string} rawInput
 * @returns {string} a formatted credit card num
 */
export function formatCard(rawInput) {
  const cleanedInput = stripNonDigits(rawInput);
  const dashedInput = dashCard(cleanedInput);
  return dashedInput;
}

/**
 * @param {string} rawInput
 * @returns {string} a formatted expiration date, i.e: "07/21"
 */
export function formatExpDate(rawInput) {
  const cleanedInput = stripNonDigits(rawInput);
  const slashedDate = slashDate(cleanedInput);
  return slashedDate;
}

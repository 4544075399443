import { getRequiredFields, validateField, validateQuote } from 'woop-shared/quotes/validation';
import { getPaidInFullPremium } from 'woop-shared/quotes/utils';
import { FOREIGN_KEYS, PDF_FIELDS, QUOTE_FIELDS } from 'woop-shared/db/models';
import { FEE_FIELDS, PREMIUM_FIELDS } from 'woop-shared/quotes/enums';
import { hasVal, capitalize } from 'woop-shared/utils';
import { COVERAGE_TAGS, COVERAGE_TYPES } from 'woop-shared/enums';
import { BUNDLED_QUOTE_FIELDS, GENERAL_FIELDS } from 'woop-shared/db/models';
import { QUOTESET_ERRORS } from './constants';
import { newFeeTemplate } from '../../components/CarrierFees/utils';
import {
  OPTIONAL_SUPPLEMENTARY_INFO_KEYS,
  SUPPLEMENTARY_FIELDS
} from '../../components/SupplementaryInfo/constants';
import { CREATE_DATE } from '../../constants/fields';
import { formatPremium } from '../../utils/quoteset';
import { uniqBy } from '../Policy/utils';
import { getCovTypeForSupplementaryFields } from '../../components/SupplementaryInfo/utils';

export function createQuote(coverageTypes, isCurrentPolicy) {
  const QUOTE_TEMPLATE = {
    [FOREIGN_KEYS.CARRIER_CODE]: undefined,
    [QUOTE_FIELDS.DEDUCTIBLE]: undefined,
    [QUOTE_FIELDS.CARRIER_QUOTE_ID]: undefined,
    [QUOTE_FIELDS.PDF]: {
      [PDF_FIELDS.URL]: undefined
    },
    [QUOTE_FIELDS.COVERAGE_TYPE]: coverageTypes.length === 1 ? coverageTypes[0] : undefined,
    [QUOTE_FIELDS.PREMIUMS]: [],
    [QUOTE_FIELDS.COVERAGES]: [],
    [QUOTE_FIELDS.FEES]: newFeeTemplate(),
    [QUOTE_FIELDS.IS_CURRENT_POLICY]: isCurrentPolicy
  };
  return { ...QUOTE_TEMPLATE, [CREATE_DATE]: generateCreateDate() };
}

export function createBundledQuote() {
  const QUOTE_TEMPLATE = {
    [BUNDLED_QUOTE_FIELDS.QUOTE_IDS]: []
  };
  return { ...QUOTE_TEMPLATE, [CREATE_DATE]: generateCreateDate() };
}

export function copyQuote(quote) {
  // Spread the original quote.
  const newQuote = { ...quote };

  // Delete the ID.
  delete newQuote[GENERAL_FIELDS.ID];

  // Give it a new createDate.
  newQuote[CREATE_DATE] = generateCreateDate();

  // Spread the complex fields.
  newQuote[QUOTE_FIELDS.COVERAGES] = newQuote[QUOTE_FIELDS.COVERAGES].map(covObj => ({
    ...covObj
  }));

  newQuote[QUOTE_FIELDS.FEES] = {
    [FEE_FIELDS.ACH]: { ...newQuote[QUOTE_FIELDS.FEES]?.[FEE_FIELDS.ACH] },
    [FEE_FIELDS.CARD]: { ...newQuote[QUOTE_FIELDS.FEES]?.[FEE_FIELDS.CARD] }
  };

  if (newQuote[QUOTE_FIELDS.ADDITIONAL_COVERAGES]) {
    newQuote[QUOTE_FIELDS.ADDITIONAL_COVERAGES] = newQuote[QUOTE_FIELDS.ADDITIONAL_COVERAGES].map(
      covObj => ({ ...covObj })
    );
  }

  newQuote[QUOTE_FIELDS.PREMIUMS] =
    newQuote[QUOTE_FIELDS.PREMIUMS] && newQuote[QUOTE_FIELDS.PREMIUMS].map(p => ({ ...p }));

  return newQuote;
}

/**
 * Remove the createDate field from each quote object.
 *
 * Note: need to destructure each quote object to remove mutability.
 *
 * @param {Array} quotes Array of quote objects.
 * @returns {Array} Array of quote objects with the createDate field removed.
 */
export function removeCreateDate(quotes) {
  return quotes.map(q => {
    const formattedQuote = { ...q };
    delete formattedQuote[CREATE_DATE];
    return formattedQuote;
  });
}

function generateCreateDate() {
  return new Date().getTime();
}

/**
 * Create a label for a quote.
 *
 * @param {object} quote An individual quote.
 * @param {object[]} carriers A list of carriers
 * @returns {number} The quote's label, e.g. "Travelers - Home ($1,436 / year)"
 */
export function getQuoteLabel(quote, carriers = []) {
  const carrier = carriers.find(c => quote[FOREIGN_KEYS.CARRIER_CODE] === c.code);
  const carrierLabel = carrier?.name || '(no carrier)';
  const covType = quote[QUOTE_FIELDS.COVERAGE_TYPE];
  const isPolicy = quote[QUOTE_FIELDS.IS_CURRENT_POLICY];
  const tag = quote[QUOTE_FIELDS.COVERAGE_TAG];
  const premium = formatPremium(quote?.[QUOTE_FIELDS.PREMIUMS] || []);

  return isPolicy
    ? `${carrierLabel} - ${capitalize(covType)} Policy`
    : `${carrierLabel} - ${capitalize(covType)} ${getTagIcon(tag)} (${premium})`;
}

export function getTagIcon(tag) {
  return (
    {
      [COVERAGE_TAGS.LOW]: '(LOW)',
      [COVERAGE_TAGS.MEDIUM]: '🍏',
      [COVERAGE_TAGS.HIGH]: '🛡'
    }[tag] || ''
  );
}

/**
 *
 * @param {object} field
 * @param {object} data input data to check against
 * @returns {boolean} indicates if show condition met
 */
export function conditionMet(field, data) {
  if (!Array.isArray(field.conditionals)) return true;
  return field.conditionals.every(conditional => {
    const dependentValue = data?.[conditional.fieldName];
    return conditional.showValues.includes(dependentValue);
  });
}

export function getInvalidFields(quote, { compare }) {
  const covType = quote[QUOTE_FIELDS.COVERAGE_TYPE];
  const isAuto = covType === COVERAGE_TYPES.AUTO;
  const isUmbrella = covType === COVERAGE_TYPES.UMBRELLA;
  const isCurrentPolicy = quote[QUOTE_FIELDS.IS_CURRENT_POLICY] || false;

  return getRequiredFields(quote).filter(
    key => !validateField(key, quote[key], { isAuto, isUmbrella, isCurrentPolicy, compare })
  );
}

/**
 * Indicates whether a supplementary info object has all necessary fields for its coverage types
 *
 * @param {object} supplementaryInfo
 * @param {string[]} coverageTypes
 * @returns {boolean}
 */
export function validateSupplementaryInfo(supplementaryInfo, coverageTypes = []) {
  const fields = SUPPLEMENTARY_FIELDS[getCovTypeForSupplementaryFields(coverageTypes)]?.map(
    field => field.key
  );

  if (!fields) return false;
  return fields.every(
    key => hasVal(supplementaryInfo[key]) || OPTIONAL_SUPPLEMENTARY_INFO_KEYS.includes(key)
  );
}

/**
 * Returns a list of errors that exist on a quoteset
 *
 * @param {object} quoteset
 * @param {object} quoteset.quotes
 * @param {object} quoteset.supplementaryInfo
 * @param {string[]} quoteset.coverageTypes
 * @param {string} quoteset.agentNotes
 * @param {string} quoteset.fk_agent
 * @param {object} quoteset.bundledQuotes
 * @returns {string[]}
 */
export function getQuotesetErrors({
  quotes,
  supplementaryInfo,
  coverageTypes,
  agentNotes,
  fk_agent,
  bundledQuotes = {}
}) {
  const hasQuotes = quotes.filter(quote => !quote[QUOTE_FIELDS.IS_CURRENT_POLICY]).length > 0;
  const validQuotes = quotes.every(quote => validateQuote(quote));
  const validSupplementaryInfo = validateSupplementaryInfo(supplementaryInfo, coverageTypes);

  const policyTypes = quotes
    .filter(q => q[QUOTE_FIELDS.IS_CURRENT_POLICY])
    .map(q => q[QUOTE_FIELDS.COVERAGE_TYPE]);
  const hasDuplicates = policyTypes.length !== uniqBy(policyTypes, t => t).length;
  const invalidBundle = Object.values(bundledQuotes).some(({ quoteIds }) => quoteIds?.length < 2);

  return [
    !hasQuotes && QUOTESET_ERRORS.NO_QUOTES,
    !validQuotes && QUOTESET_ERRORS.INVALID_QUOTES,
    !validSupplementaryInfo && QUOTESET_ERRORS.INVALID_SUPPLEMENTARY_INFO,
    !agentNotes && QUOTESET_ERRORS.NO_AGENT_NOTES,
    !fk_agent && QUOTESET_ERRORS.NO_AGENT,
    hasDuplicates && QUOTESET_ERRORS.DUPLICATE_POLICIES,
    invalidBundle && QUOTESET_ERRORS.INVALID_BUNDLE
  ].filter(Boolean);
}

export function getQuoteId(quote) {
  return quote[GENERAL_FIELDS.ID] || quote[CREATE_DATE];
}

export function getBundleId(bundle) {
  return bundle[GENERAL_FIELDS.ID] || bundle[CREATE_DATE];
}

export function hasUnsavedQuotes(quotes) {
  const quotesWithIds = quotes.filter(quote => quote?.[GENERAL_FIELDS.ID]);
  if (quotesWithIds.length < quotes.length) return true;
}

/**
 * Format agents for select component.
 *
 * @param {{ firstName: string, lastName: string }[]} agents
 */
export function formatAgentOptions(agents) {
  return agents.map(agent => ({
    label: `${agent.firstName} ${agent.lastName}`,
    value: agent[GENERAL_FIELDS.ID]
  }));
}

/**
 * Returns true if there are already current policies for all the coverage types
 *
 * @param {object[]} quotes
 * @param {string[]} coverageTypes
 * @returns {boolean}
 */
export function alreadyHasPolicies(quotes, coverageTypes) {
  const hasPolicyOfType = covType =>
    quotes
      .filter(q => q[QUOTE_FIELDS.IS_CURRENT_POLICY])
      .some(q => q[QUOTE_FIELDS.COVERAGE_TYPE] === covType);

  return coverageTypes?.every(hasPolicyOfType);
}

export function getConfirmMessage(notifyConsumer, { firstName, lastName }) {
  return `${
    notifyConsumer ? `These quotes will be sent to ${firstName} ${lastName}. ` : ''
  }Are you sure you want to finalize?`;
}

/**
 * Compares two quotes for .sort(), sorting first by coverage type and second by paid in full premium
 *
 * @param {object} a a quote
 * @param {object} b another quote
 * @returns {number}
 */
export function compareQuotes(a, b) {
  const compareCovType = (a, b) =>
    a?.[QUOTE_FIELDS.COVERAGE_TYPE] < b?.[QUOTE_FIELDS.COVERAGE_TYPE] ? -1 : 1;

  const getAmount = q =>
    getPaidInFullPremium(q?.[QUOTE_FIELDS.PREMIUMS] || [])?.[PREMIUM_FIELDS.AMOUNT] || 0;
  const comparePremiums = (a, b) => getAmount(a) - getAmount(b);

  return a[QUOTE_FIELDS.COVERAGE_TYPE] === b[QUOTE_FIELDS.COVERAGE_TYPE]
    ? comparePremiums(a, b)
    : compareCovType(a, b);
}

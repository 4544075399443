import React from 'react';
import PropTypes from 'prop-types';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import PropertyBasic from './PropertyBasic/container';
import PropertyProtection from './PropertyProtection';
import PropertyOwnership from './PropertyOwnership';
import PropertyUsage from './PropertyUsage';
import PropertyInsurance from './PropertyInsurance';
import { ORIGINATION_SECTIONS } from '../../constants/table-of-contents';

const PropertyInfo = ({ propertyInfo = {}, coverageTypes = [], updateOrigination }) => {
  const handleFieldChange = (name, value) => {
    const updates = { ...propertyInfo, [name]: value };
    updateOrigination(ORIGINATION_FIELDS.PROPERTY_INFO, updates);
  };

  return (
    <div id={ORIGINATION_SECTIONS.PROPERTY}>
      <PropertyBasic
        propertyInfo={propertyInfo}
        updateOrigination={handleFieldChange}
        coverageTypes={coverageTypes}
      />
      <PropertyProtection
        propertyInfo={propertyInfo}
        updateOrigination={handleFieldChange}
        coverageTypes={coverageTypes}
      />
      <PropertyOwnership propertyInfo={propertyInfo} updateOrigination={handleFieldChange} />
      <PropertyUsage propertyInfo={propertyInfo} updateOrigination={handleFieldChange} />

      <PropertyInsurance
        propertyInfo={propertyInfo}
        updateOrigination={handleFieldChange}
        coverageTypes={coverageTypes}
      />
    </div>
  );
};

PropertyInfo.propTypes = {
  propertyInfo: PropTypes.object,
  updateOrigination: PropTypes.func,
  coverageTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(COVERAGE_TYPES)))
};

export default PropertyInfo;

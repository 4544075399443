import { connect } from 'react-redux';
import Incidents from '.';
import { addIncident, deleteIncident, updateIncident } from '../../actions/incidents';
import { createDriverOptions } from '../../utils/options';

const mapStateToProps = ({ applicants, incidents }) => {
  return {
    incidents: Object.values(incidents),
    driverTags: createDriverOptions(applicants)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addIncident: incident => dispatch(addIncident(incident)),
    deleteIncident: id => dispatch(deleteIncident(id)),
    updateIncident: (id, updateObj) => dispatch(updateIncident({ id, updateObj }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);

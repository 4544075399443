import React from 'react';
import PropTypes from 'prop-types';
import { PROPERTY_TYPES } from 'woop-shared/origination/enums';
import { US_STATES } from 'woop-shared/data/states';
import Select from '../../Select';
import { createSelectOption, createOptionsFromEnum } from '../../Select/utils';
import { PROPERTY_FIELDS as FIELDS, YEARS, YES_NO_OPTIONS } from '../../../constants/origination';
import styles from '../../../pages/CreateOrigination/CreateOrigination.css';
import NumberField from '../../NumberField';
import TextField from '../../TextField';
import MoneyField from '../../MoneyField';
import DateField from '../../DateField';
import RadioGroup from '../../RadioGroup';
import { SUB_SECTIONS } from '../../../constants/table-of-contents';
import Card from '../../Card';

const sectionId = SUB_SECTIONS.OWNERSHIP;

const PropertyOwnership = ({ propertyInfo = {}, updateOrigination }) => {
  return (
    <Card id={sectionId}>
      <h3 className={styles.heading}>Property Ownership</h3>
      <div className={styles.fieldWrapper}>
        <Select
          prompt="Property Type"
          options={createOptionsFromEnum(PROPERTY_TYPES)}
          name={FIELDS.PROPERTY_TYPE}
          onChange={updateOrigination}
          isValid={true}
          value={createSelectOption(propertyInfo[FIELDS.PROPERTY_TYPE])}
        />
        <Select
          prompt="Ownership Year"
          name={FIELDS.OWNERSHIP_YEAR}
          options={YEARS}
          onChange={updateOrigination}
          isValid={true}
          value={createSelectOption(propertyInfo[FIELDS.OWNERSHIP_YEAR])}
        />
        <DateField
          name={FIELDS.MOVE_IN_DATE}
          prompt="Move-in Date"
          value={propertyInfo[FIELDS.MOVE_IN_DATE]}
          onChange={e => updateOrigination(e?.target?.name, e?.target?.value)}
        />
        <RadioGroup
          prompt="Insured owns the property"
          name={FIELDS.INSURED_IS_PROPERTY_OWNER}
          options={YES_NO_OPTIONS}
          value={propertyInfo[FIELDS.INSURED_IS_PROPERTY_OWNER]}
          onChange={updateOrigination}
        />
        <NumberField
          label="Number of Mortgages"
          name={FIELDS.NUMBER_OF_MORTGAGES}
          value={propertyInfo[FIELDS.NUMBER_OF_MORTGAGES]}
          onChange={updateOrigination}
        />
        <TextField
          label="Mortgage Company"
          name={FIELDS.PRIMARY_MORTGAGE_HOLDER}
          value={propertyInfo[FIELDS.PRIMARY_MORTGAGE_HOLDER]}
          onChange={e => updateOrigination(FIELDS.PRIMARY_MORTGAGE_HOLDER, e?.target?.value || '')}
        />
        <TextField
          label="Mortgage City"
          name={FIELDS.PRIMARY_MORTGAGE_CITY}
          value={propertyInfo[FIELDS.PRIMARY_MORTGAGE_CITY]}
          onChange={e => updateOrigination(FIELDS.PRIMARY_MORTGAGE_CITY, e?.target?.value || '')}
        />
        <Select
          prompt={'Mortgage State'}
          name={FIELDS.PRIMARY_MORTGAGE_STATE}
          options={US_STATES}
          onChange={updateOrigination}
          isValid={true}
          value={createSelectOption(propertyInfo[FIELDS.PRIMARY_MORTGAGE_STATE])}
        />
        <TextField
          label="Loan Number"
          name={FIELDS.LOAN_NUMBER}
          value={propertyInfo[FIELDS.LOAN_NUMBER]}
          onChange={e => updateOrigination(FIELDS.LOAN_NUMBER, e?.target?.value || '')}
        />
        <MoneyField
          label="Loan Amount"
          name={FIELDS.LOAN_AMOUNT}
          value={propertyInfo[FIELDS.LOAN_AMOUNT]}
          onChange={updateOrigination}
        />
      </div>
    </Card>
  );
};

PropertyOwnership.propTypes = {
  propertyInfo: PropTypes.object,
  updateOrigination: PropTypes.func
};

export default PropertyOwnership;

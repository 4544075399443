export const AUTO_FINANCING_INSTITUTIONS = [
  { value: '21st Mortgage Corporation', label: '21st Mortgage Corporation' },
  { value: 'ABNB Federal Credit Union', label: 'ABNB Federal Credit Union' },
  { value: 'ACAR Leasing', label: 'ACAR Leasing' },
  { value: 'Achieva Credit Union', label: 'Achieva Credit Union' },
  { value: 'Acura Financial Services', label: 'Acura Financial Services' },
  { value: 'Advia Credit Union', label: 'Advia Credit Union' },
  { value: 'AEA Federal Credit Union', label: 'AEA Federal Credit Union' },
  { value: 'Affinity Plus Federal Credit Union', label: 'Affinity Plus Federal Credit Union' },
  { value: 'Alabama Central Credit Union', label: 'Alabama Central Credit Union' },
  { value: 'Alaska USA Federal Credit Union', label: 'Alaska USA Federal Credit Union' },
  { value: 'Alcoa Tenn Federal Credit Union', label: 'Alcoa Tenn Federal Credit Union' },
  { value: 'Alexium Leasing Co', label: 'Alexium Leasing Co' },
  { value: 'Ally Bank and Its Assigns', label: 'Ally Bank and Its Assigns' },
  { value: 'Ally Capital and Its Assigns', label: 'Ally Capital and Its Assigns' },
  { value: 'Ally Financial', label: 'Ally Financial' },
  { value: 'Alphera Financial Services', label: 'Alphera Financial Services' },
  { value: 'Amarillo National Bank', label: 'Amarillo National Bank' },
  { value: 'American Credit Acceptance', label: 'American Credit Acceptance' },
  { value: 'American Express Consumer Loan Ctr', label: 'American Express Consumer Loan Ctr' },
  { value: 'American Honda Financial - AHFC', label: 'American Honda Financial - AHFC' },
  { value: 'American Suzuki Financial Services', label: 'American Suzuki Financial Services' },
  { value: 'American Trust', label: 'American Trust' },
  { value: 'AmeriServ Financial', label: 'AmeriServ Financial' },
  { value: 'Andrews Federal Credit Union', label: 'Andrews Federal Credit Union' },
  { value: 'APG Federal Credit Union', label: 'APG Federal Credit Union' },
  { value: 'Arizona Central Credit Union', label: 'Arizona Central Credit Union' },
  { value: 'Arizona State Credit Union', label: 'Arizona State Credit Union' },
  { value: 'ASI Federal Credit Union', label: 'ASI Federal Credit Union' },
  { value: 'Atlantic Finance', label: 'Atlantic Finance' },
  { value: 'Audi Financial Services', label: 'Audi Financial Services' },
  { value: 'Auto Finance', label: 'Auto Finance' },
  { value: 'Auto Now Acceptance', label: 'Auto Now Acceptance' },
  { value: 'AutoBank Acceptance Corp', label: 'AutoBank Acceptance Corp' },
  { value: 'Automotive Credit Corp', label: 'Automotive Credit Corp' },
  { value: 'Auto Trakk &/or Lyco Auto Trust', label: 'Auto Trakk &/or Lyco Auto Trust' },
  { value: 'Bangor Savings Bank', label: 'Bangor Savings Bank' },
  { value: 'Bank of America', label: 'Bank of America' },
  { value: 'Bank of America Auto Financial Corp', label: 'Bank of America Auto Financial Corp' },
  { value: 'Bank of The West', label: 'Bank of The West' },
  { value: 'Banterra Bank', label: 'Banterra Bank' },
  { value: 'Baxter Credit Union', label: 'Baxter Credit Union' },
  { value: 'Bay View Acceptance Corp', label: 'Bay View Acceptance Corp' },
  { value: 'BB&T', label: 'BB&T' },
  { value: 'BB&T Equipment Finance Corp', label: 'BB&T Equipment Finance Corp' },
  { value: 'Benchmark Community Bank', label: 'Benchmark Community Bank' },
  { value: 'Beneficial ISAOA', label: 'Beneficial ISAOA' },
  { value: 'Bering Financial Services', label: 'Bering Financial Services' },
  { value: 'BMO Harris Bank', label: 'BMO Harris Bank' },
  { value: 'BMW Financial Services Na LLC', label: 'BMW Financial Services Na LLC' },
  { value: 'Boeing Employees Credit Union', label: 'Boeing Employees Credit Union' },
  { value: 'Bridgecrest Acceptance Corporation', label: 'Bridgecrest Acceptance Corporation' },
  { value: 'Brookline Bank', label: 'Brookline Bank' },
  { value: 'Brothers Enterprise', label: 'Brothers Enterprise' },
  { value: 'C & F Finance', label: 'C & F Finance' },
  { value: 'Cab East LLC', label: 'Cab East LLC' },
  { value: 'Cab West LLC', label: 'Cab West LLC' },
  { value: 'Caliber Home Loans Inc ISAOA ATIMA', label: 'Caliber Home Loans Inc ISAOA ATIMA' },
  { value: 'Capital City Bank', label: 'Capital City Bank' },
  { value: 'Capital One Auto Finance', label: 'Capital One Auto Finance' },
  { value: 'CarMax Auto Finance', label: 'CarMax Auto Finance' },
  { value: 'Carolina Finance', label: 'Carolina Finance' },
  { value: 'Cars  Financial', label: 'Cars  Financial' },
  { value: 'CCAP Auto Lease Ltd', label: 'CCAP Auto Lease Ltd' },
  { value: 'CDC Federal Credit Union', label: 'CDC Federal Credit Union' },
  { value: 'Cefcu ISAOA', label: 'Cefcu ISAOA' },
  { value: 'Cenlar Fsb ISAOA ATIMA', label: 'Cenlar Fsb ISAOA ATIMA' },
  { value: 'Centier Bank ISAOA', label: 'Centier Bank ISAOA' },
  { value: 'Central Pacific Bank', label: 'Central Pacific Bank' },
  { value: 'Charter Lease', label: 'Charter Lease' },
  { value: 'Chartway Federal Credit Union', label: 'Chartway Federal Credit Union' },
  { value: 'Chase Auto Finance', label: 'Chase Auto Finance' },
  { value: 'Chevy Chase Bank', label: 'Chevy Chase Bank' },
  { value: 'Chrysler Capital', label: 'Chrysler Capital' },
  { value: 'CIT Group (Commercial Invest Trst)', label: 'CIT Group (Commercial Invest Trst)' },
  { value: 'CIT Group/Sales', label: 'CIT Group/Sales' },
  { value: 'Citizen Finance', label: 'Citizen Finance' },
  { value: 'Citizens Auto Finance (RBS)', label: 'Citizens Auto Finance (RBS)' },
  { value: 'Citizens Autolease', label: 'Citizens Autolease' },
  { value: 'City National Bank', label: 'City National Bank' },
  { value: 'Clearfield Bank & Trust Co', label: 'Clearfield Bank & Trust Co' },
  { value: 'Clearview Federal Credit Union', label: 'Clearview Federal Credit Union' },
  { value: 'Collins Community Credit Union', label: 'Collins Community Credit Union' },
  { value: 'Commerce Bank & Trust Co', label: 'Commerce Bank & Trust Co' },
  { value: 'Compass Bank (BBVA)', label: 'Compass Bank (BBVA)' },
  { value: 'Condor Capital Corporation', label: 'Condor Capital Corporation' },
  { value: 'Consumer Portfolio Service (CPS)', label: 'Consumer Portfolio Service (CPS)' },
  { value: 'Coral Community Federal Credit Union', label: 'Coral Community Federal Credit Union' },
  {
    value: 'Cornerstone Community Federal Credit Union',
    label: 'Cornerstone Community Federal Credit Union'
  },
  { value: 'Cornerstone Credit Union', label: 'Cornerstone Credit Union' },
  { value: 'Coulee Dam Federal Credit Union', label: 'Coulee Dam Federal Credit Union' },
  { value: 'Credit Acceptance', label: 'Credit Acceptance' },
  { value: 'Credit Union Loan Source LLC', label: 'Credit Union Loan Source LLC' },
  { value: 'Credit Union of Colorado', label: 'Credit Union of Colorado' },
  { value: 'Credit Union of Texas', label: 'Credit Union of Texas' },
  { value: 'Credit Union West', label: 'Credit Union West' },
  { value: 'Crescent Bank and Trust', label: 'Crescent Bank and Trust' },
  { value: 'CSB Bank', label: 'CSB Bank' },
  { value: 'Credit Union Factory Built Len', label: 'Credit Union Factory Built Len' },
  { value: 'Cyprus Credit Union', label: 'Cyprus Credit Union' },
  { value: 'Dealer Services Corp', label: 'Dealer Services Corp' },
  { value: 'Desert Financial Credit Union', label: 'Desert Financial Credit Union' },
  { value: 'Digital Federal Credit Union', label: 'Digital Federal Credit Union' },
  { value: 'Ditech Financial LLC ISAOA', label: 'Ditech Financial LLC ISAOA' },
  { value: 'Dupaco Credit Union', label: 'Dupaco Credit Union' },
  { value: 'DuPage Credit Union', label: 'DuPage Credit Union' },
  { value: 'DuTrac Community Credit Union', label: 'DuTrac Community Credit Union' },
  { value: 'Eaglemark Savings Bank', label: 'Eaglemark Savings Bank' },
  { value: 'Earthmover Credit Union', label: 'Earthmover Credit Union' },
  { value: 'Electric Service Credit Union', label: 'Electric Service Credit Union' },
  { value: 'Elko Federal Credit Union', label: 'Elko Federal Credit Union' },
  { value: 'Empower Federal Credit Union', label: 'Empower Federal Credit Union' },
  { value: 'Enrichment Federal Credit Union', label: 'Enrichment Federal Credit Union' },
  { value: 'Entex Credit Union', label: 'Entex Credit Union' },
  { value: 'Equity Auto Finance', label: 'Equity Auto Finance' },
  { value: 'Exeter Financial Corp', label: 'Exeter Financial Corp' },
  { value: 'Family Security', label: 'Family Security' },
  { value: 'Felco Auto Lease', label: 'Felco Auto Lease' },
  { value: 'Ferrari Financial Services', label: 'Ferrari Financial Services' },
  { value: 'Fifth Third Bank', label: 'Fifth Third Bank' },
  { value: 'Fifth Third Bank Copr ISAOA', label: 'Fifth Third Bank Copr ISAOA' },
  { value: 'Financial Services Vehicle Trust', label: 'Financial Services Vehicle Trust' },
  { value: 'Fireside Bank', label: 'Fireside Bank' },
  { value: 'First Bank of Ohio', label: 'First Bank of Ohio' },
  { value: 'First Florida Credit Union', label: 'First Florida Credit Union' },
  { value: 'First General Leasing', label: 'First General Leasing' },
  { value: 'First Investor Financial Services', label: 'First Investor Financial Services' },
  { value: 'First Merit Bank', label: 'First Merit Bank' },
  { value: 'First National Bank - Bemidji', label: 'First National Bank - Bemidji' },
  { value: 'First of America', label: 'First of America' },
  {
    value: 'First Ohio Community Federal Credit Union',
    label: 'First Ohio Community Federal Credit Union'
  },
  { value: 'First Premier Bank', label: 'First Premier Bank' },
  { value: 'First State Bank - OK', label: 'First State Bank - OK' },
  { value: 'Foothill Federal Credit Union', label: 'Foothill Federal Credit Union' },
  { value: 'Ford Motor Credit Company', label: 'Ford Motor Credit Company' },
  { value: 'Fort Madison Bank & Trust', label: 'Fort Madison Bank & Trust' },
  { value: 'Fox Communities Credit Union', label: 'Fox Communities Credit Union' },
  { value: 'Freedom Bank', label: 'Freedom Bank' },
  { value: 'Freedom Roads Finance', label: 'Freedom Roads Finance' },
  { value: 'Fresno County Federal Credit Union', label: 'Fresno County Federal Credit Union' },
  { value: 'Gate City Bank', label: 'Gate City Bank' },
  { value: 'Gateway One', label: 'Gateway One' },
  { value: 'GE Capital Auto Financial Svc', label: 'GE Capital Auto Financial Svc' },
  { value: 'GE Money Bank', label: 'GE Money Bank' },
  { value: 'GFC Lending', label: 'GFC Lending' },
  { value: 'Global Lending Services', label: 'Global Lending Services' },
  { value: 'GM Financial', label: 'GM Financial' },
  { value: 'GMAC', label: 'GMAC' },
  { value: 'Greater Suburban Acceptance', label: 'Greater Suburban Acceptance' },
  { value: 'Green Cap Financial', label: 'Green Cap Financial' },
  { value: 'Grow Financial Credit Union', label: 'Grow Financial Credit Union' },
  { value: 'GTE Financial Credit Union', label: 'GTE Financial Credit Union' },
  { value: 'Gulf Credit Union', label: 'Gulf Credit Union' },
  { value: 'Hann Auto Trust', label: 'Hann Auto Trust' },
  { value: 'Hann Financial Services Corp', label: 'Hann Financial Services Corp' },
  { value: 'Harley Davidson Credit', label: 'Harley Davidson Credit' },
  { value: 'Harris Bank', label: 'Harris Bank' },
  { value: 'Harris County Federal Credit Union', label: 'Harris County Federal Credit Union' },
  { value: 'Hitachi Capital America Corp', label: 'Hitachi Capital America Corp' },
  { value: 'HSBC Bank USA ISAOA', label: 'HSBC Bank USA ISAOA' },
  { value: 'Hudson Valley Federal Credit Union', label: 'Hudson Valley Federal Credit Union' },
  { value: 'Huntington Bank ISAOA ATIMA', label: 'Huntington Bank ISAOA ATIMA' },
  { value: 'Huntington Natl Bank', label: 'Huntington Natl Bank' },
  { value: 'Hyundai Lease Title Trust', label: 'Hyundai Lease Title Trust' },
  { value: 'Idaho Central Credit Union', label: 'Idaho Central Credit Union' },
  { value: 'Independent Bank', label: 'Independent Bank' },
  { value: 'Innovate Loan Servicing Corp', label: 'Innovate Loan Servicing Corp' },
  { value: 'Jaguar Credit', label: 'Jaguar Credit' },
  { value: 'Kemba Financial Credit Union', label: 'Kemba Financial Credit Union' },
  { value: 'Key Bank', label: 'Key Bank' },
  { value: 'Kia Motor Finance Co', label: 'Kia Motor Finance Co' },
  { value: 'Kitch Acceptance Corp', label: 'Kitch Acceptance Corp' },
  { value: 'Kitsap Bank', label: 'Kitsap Bank' },
  { value: 'Lake Michigan Credit Union', label: 'Lake Michigan Credit Union' },
  { value: 'Land Rover Capital Group', label: 'Land Rover Capital Group' },
  { value: 'Landmark Credit Un', label: 'Landmark Credit Un' },
  { value: 'Leaders Financial Company', label: 'Leaders Financial Company' },
  { value: 'Lexus Financial Services', label: 'Lexus Financial Services' },
  { value: 'M & T Bank', label: 'M & T Bank' },
  { value: 'Marine Credit Union', label: 'Marine Credit Union' },
  { value: 'Marine Federal Credit Union', label: 'Marine Federal Credit Union' },
  { value: 'Marquette Consumer Finance', label: 'Marquette Consumer Finance' },
  { value: 'Mazda American Credit', label: 'Mazda American Credit' },
  { value: 'Mb Financial Bk', label: 'Mb Financial Bk' },
  { value: 'Mercedes Benz Credit', label: 'Mercedes Benz Credit' },
  { value: 'Mercury Financial Co LLC', label: 'Mercury Financial Co LLC' },
  { value: 'Mid Atlantic Finance Company', label: 'Mid Atlantic Finance Company' },
  { value: 'Midwest Financial Credit Union', label: 'Midwest Financial Credit Union' },
  { value: 'Mini Financial Services', label: 'Mini Financial Services' },
  { value: 'Missoula Federal Credit Union', label: 'Missoula Federal Credit Union' },
  { value: 'MMCA (Mitsubishi Motor)', label: 'MMCA (Mitsubishi Motor)' },
  { value: 'MMCALL (Mitsubishi)', label: 'MMCALL (Mitsubishi)' },
  { value: 'Mountain America Credit Union', label: 'Mountain America Credit Union' },
  { value: 'Municipal Credit Union', label: 'Municipal Credit Union' },
  {
    value: 'Nassau Financial Federal Credit Union',
    label: 'Nassau Financial Federal Credit Union'
  },
  { value: 'National Auto Acceptance Corp', label: 'National Auto Acceptance Corp' },
  { value: 'Nationstar Mortgage', label: 'Nationstar Mortgage' },
  { value: 'Nationwide Acceptance LLC', label: 'Nationwide Acceptance LLC' },
  { value: 'Navistar Financial Corp', label: 'Navistar Financial Corp' },
  { value: 'Navy Federal Credit Union', label: 'Navy Federal Credit Union' },
  { value: 'NBT Bank', label: 'NBT Bank' },
  { value: 'Neighbors Federal Credit Union', label: 'Neighbors Federal Credit Union' },
  { value: 'New City Funding Corp', label: 'New City Funding Corp' },
  { value: 'New South Fsb', label: 'New South Fsb' },
  { value: 'Nextier Bank', label: 'Nextier Bank' },
  { value: 'Nicholas Financial', label: 'Nicholas Financial' },
  { value: 'Nissan Motor Acceptance', label: 'Nissan Motor Acceptance' },
  { value: 'Nissan-Infiniti Lt', label: 'Nissan-Infiniti Lt' },
  { value: 'North Shore Bank', label: 'North Shore Bank' },
  { value: 'Northwest Bank', label: 'Northwest Bank' },
  { value: 'Northwest Community Credit Union', label: 'Northwest Community Credit Union' },
  { value: 'Numerica Credit Union', label: 'Numerica Credit Union' },
  { value: 'Nuvell Financial Services, LLC', label: 'Nuvell Financial Services, LLC' },
  {
    value: 'Nuvision Financial Federal Credit Union',
    label: 'Nuvision Financial Federal Credit Union'
  },
  { value: 'Old National Bank', label: 'Old National Bank' },
  { value: 'Omni American Bank', label: 'Omni American Bank' },
  { value: 'OneMain Financial ISAOA', label: 'OneMain Financial ISAOA' },
  { value: "Orange County's Credit Union", label: "Orange County's Credit Union" },
  { value: 'Oregon Community Credit Union', label: 'Oregon Community Credit Union' },
  { value: 'Orrstown Bank', label: 'Orrstown Bank' },
  { value: 'Paccar Financial Corp', label: 'Paccar Financial Corp' },
  { value: 'Pacific Community Credit Union', label: 'Pacific Community Credit Union' },
  { value: 'PBC Credit Union', label: 'PBC Credit Union' },
  {
    value: 'Pennsylvania State Employee Credit Union',
    label: 'Pennsylvania State Employee Credit Union'
  },
  {
    value: 'Pentagon Federal Credit Union (PENFED)',
    label: 'Pentagon Federal Credit Union (PENFED)'
  },
  { value: 'Peoples Bank of Cuba Mo', label: 'Peoples Bank of Cuba Mo' },
  { value: 'PNC Bank', label: 'PNC Bank' },
  { value: 'PNC Bank Natl Assc ISAOA', label: 'PNC Bank Natl Assc ISAOA' },
  { value: 'Porsche Financial', label: 'Porsche Financial' },
  { value: 'Porsche Leasing Ltd', label: 'Porsche Leasing Ltd' },
  { value: 'Portage National Bank', label: 'Portage National Bank' },
  { value: 'Premier Auto Finance of Florida', label: 'Premier Auto Finance of Florida' },
  { value: 'Prestige Financial Services', label: 'Prestige Financial Services' },
  { value: 'Primus Auto Financial Services', label: 'Primus Auto Financial Services' },
  { value: 'Primus Financial Services', label: 'Primus Financial Services' },
  { value: 'Professional Financial Services', label: 'Professional Financial Services' },
  { value: 'Regional Accept Corp', label: 'Regional Accept Corp' },
  { value: 'Regions Bank', label: 'Regions Bank' },
  { value: 'Reliable Credit', label: 'Reliable Credit' },
  { value: 'Rolls-Royce Financial Services', label: 'Rolls-Royce Financial Services' },
  { value: 'Saab Financial Services LLC', label: 'Saab Financial Services LLC' },
  { value: 'San Antonio Federal Credit Union', label: 'San Antonio Federal Credit Union' },
  { value: 'Santander Consumer USA Inc', label: 'Santander Consumer USA Inc' },
  { value: 'Savings Financial Corp', label: 'Savings Financial Corp' },
  { value: 'Security Natl Auto Co (SNAAC)', label: 'Security Natl Auto Co (SNAAC)' },
  {
    value: 'Security Service Federal Credit Union',
    label: 'Security Service Federal Credit Union'
  },
  { value: 'SELCO Community Credit Union', label: 'SELCO Community Credit Union' },
  { value: 'Shell Federal Credit Union', label: 'Shell Federal Credit Union' },
  { value: 'Sierra Central Credit Union', label: 'Sierra Central Credit Union' },
  { value: 'Simmesport State Bank', label: 'Simmesport State Bank' },
  { value: 'Sonny Gerber Auto Sales', label: 'Sonny Gerber Auto Sales' },
  { value: 'Source One Financial', label: 'Source One Financial' },
  { value: 'Southern Auto Financial (SAFCO)', label: 'Southern Auto Financial (SAFCO)' },
  { value: 'South Side Credit Union', label: 'South Side Credit Union' },
  { value: 'Space Coast Credit Union', label: 'Space Coast Credit Union' },
  { value: 'Star Financial Bank', label: 'Star Financial Bank' },
  { value: 'State Employees Credit Union', label: 'State Employees Credit Union' },
  { value: 'Subaru American Credit', label: 'Subaru American Credit' },
  { value: 'Sun East Federal Credit Union', label: 'Sun East Federal Credit Union' },
  { value: 'Suncoast Credit Union', label: 'Suncoast Credit Union' },
  { value: 'Sunstate Federal Credit Union', label: 'Sunstate Federal Credit Union' },
  { value: 'SunTrust Bank', label: 'SunTrust Bank' },
  { value: 'System Services and Technologies', label: 'System Services and Technologies' },
  { value: 'TAPCO Credit Union', label: 'TAPCO Credit Union' },
  { value: 'Tarrant County Credit Union', label: 'Tarrant County Credit Union' },
  { value: 'Td Auto Finance', label: 'Td Auto Finance' },
  { value: 'Texans Credit Union', label: 'Texans Credit Union' },
  { value: 'Texas Dow Employees Credit Union', label: 'Texas Dow Employees Credit Union' },
  { value: 'Texas Trust Credit Union', label: 'Texas Trust Credit Union' },
  { value: 'Thor Credit Corp', label: 'Thor Credit Corp' },
  { value: 'Thrivent Financial Corp', label: 'Thrivent Financial Corp' },
  { value: 'Tidewater Motor Credit', label: 'Tidewater Motor Credit' },
  { value: 'Toyota Motor Credit Corp', label: 'Toyota Motor Credit Corp' },
  { value: 'Toyota Motor Credit - TMCC', label: 'Toyota Motor Credit - TMCC' },
  { value: 'Travis Credit Union', label: 'Travis Credit Union' },
  { value: 'Tri County Area Federal Credit Union', label: 'Tri County Area Federal Credit Union' },
  { value: 'Tri County Credit Union', label: 'Tri County Credit Union' },
  { value: 'Tropical Financial Credit Union', label: 'Tropical Financial Credit Union' },
  { value: 'Truliant Federal Credit Union', label: 'Truliant Federal Credit Union' },
  { value: 'Union Bank and Trust', label: 'Union Bank and Trust' },
  { value: 'United Auto Acceptance', label: 'United Auto Acceptance' },
  { value: 'United Auto Credit Corp', label: 'United Auto Credit Corp' },
  { value: 'United Federal Credit Union', label: 'United Federal Credit Union' },
  { value: 'United Quest Financial', label: 'United Quest Financial' },
  { value: 'United Sa Federal Credit Union', label: 'United Sa Federal Credit Union' },
  { value: 'US Bank', label: 'US Bank' },
  { value: 'US Bank Natl Assc ISAOA', label: 'US Bank Natl Assc ISAOA' },
  { value: 'USAA Bank', label: 'USAA Bank' },
  { value: 'Valley National Bank', label: 'Valley National Bank' },
  {
    value: 'Vanderbilt Mort & Financial Inc ISAOA',
    label: 'Vanderbilt Mort & Financial Inc ISAOA'
  },
  { value: 'Veridian Credit Union', label: 'Veridian Credit Union' },
  { value: 'Volvo Car Finance', label: 'Volvo Car Finance' },
  { value: 'Vt Inc as Trustee World Omni Lt', label: 'Vt Inc as Trustee World Omni Lt' },
  { value: 'VW Credit Inc', label: 'VW Credit Inc' },
  { value: 'VW Credit Leasing Ltd', label: 'VW Credit Leasing Ltd' },
  { value: 'WCC Federal Credit Union', label: 'WCC Federal Credit Union' },
  { value: 'Wells Fargo Auto Finance', label: 'Wells Fargo Auto Finance' },
  { value: 'Wells Fargo Bank', label: 'Wells Fargo Bank' },
  { value: 'Wells Fargo Dealer Services', label: 'Wells Fargo Dealer Services' },
  { value: 'Westbury Bank', label: 'Westbury Bank' },
  { value: 'Western States Bank', label: 'Western States Bank' },
  { value: 'Westlake Financial Services', label: 'Westlake Financial Services' },
  { value: 'WFS Financial Inc', label: 'WFS Financial Inc' },
  { value: 'Wilmar Leasing Inc', label: 'Wilmar Leasing Inc' },
  { value: 'Wings Financial Credit Union', label: 'Wings Financial Credit Union' },
  { value: 'Wisconsin Consumer Credit Inc', label: 'Wisconsin Consumer Credit Inc' },
  { value: "Workers' Credit Union", label: "Workers' Credit Union" },
  { value: 'World Omni', label: 'World Omni' },
  { value: "Zion's Bank", label: "Zion's Bank" }
];

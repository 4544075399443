import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GENERAL_FIELDS } from 'woop-shared/db/models';
import {
  DOG_BREEDS,
  FOUNDATION_TYPES,
  HEATING_SOURCES,
  HOME_BUSINESS_TYPES,
  PROPERTY_TYPES,
  ROOF_SHAPES,
  EXTERIOR_WALL_MATERIALS,
  ELECTRICAL_JUNCTION_TYPES,
  GARAGE_SHELTER_CONSTRUCTION_TYPES,
  LINE_OF_BUSINESS_TYPES,
  CONSTRUCTION_TYPES,
  DWELLING_TYPES,
  ROOF_TYPES
} from 'woop-shared/origination/enums';
import { OTHER } from 'woop-shared/canopy/carriers';
import _ from 'lodash';
import Select from '../../../components/Select';
import NumberField from '../../../components/NumberField';
import {
  createMultiSelectValue,
  createOptionsFromEnum,
  createSelectOption,
  createSelectOptions
} from '../../../components/Select/utils';
import { PROPERTY_FIELDS, YES_NO_OPTIONS } from '../constants';
import RadioGroup from '../../../components/RadioGroup';
import DateField from '../../../components/DateField';
import InlineAddressForm from './inline/InlineAddressForm';
import { UNDERGROUND_FUEL_TANK_HEATING_SOURCES } from '../constants';
import { CARRIER_OPTIONS } from '../../../constants/carriers';
import { getPolicyOptions } from '../../../utils/canopy';
import { fetchHazardHubPropertyData } from '../../../api/quoteset';
import Button from '../../../components/Button';
import styles from '../styles.module.css';
import Tooltip from '../../../components/Tooltip';

const propertyTypeOptions = createOptionsFromEnum(PROPERTY_TYPES);
const lineOfBusinessOptions = createOptionsFromEnum(LINE_OF_BUSINESS_TYPES);
const primaryHeatingSourceOptions = createOptionsFromEnum(HEATING_SOURCES);
const homeBusinessOptions = createOptionsFromEnum(HOME_BUSINESS_TYPES);
const constructionTypeOptions = createOptionsFromEnum(CONSTRUCTION_TYPES);
const exteriorWallMaterialOptions = createOptionsFromEnum(EXTERIOR_WALL_MATERIALS);
const yearOptions = createSelectOptions(_.range(new Date().getFullYear(), 1749, -1));
const dogBreedOptions = createOptionsFromEnum(DOG_BREEDS);
const foundationOptions = createOptionsFromEnum(FOUNDATION_TYPES);
const percentFinishedBasementOptions = _.range(0, 101, 25).map(n => ({
  label: `${n}%`,
  value: n
}));
const roofTypeOptions = createOptionsFromEnum(ROOF_TYPES);
const roofShapeOptions = createOptionsFromEnum(ROOF_SHAPES);
const electricalJunctionOptions = createOptionsFromEnum(ELECTRICAL_JUNCTION_TYPES);
const garageConstructionOptions = createOptionsFromEnum(GARAGE_SHELTER_CONSTRUCTION_TYPES);
const numStoriesOptions = createSelectOptions([1, 1.5, 2, 2.5, 3, 3.5, 4, 5]);
const dwellingTypeOptions = createOptionsFromEnum(DWELLING_TYPES);
const carrierOptions = [...CARRIER_OPTIONS, { label: 'Other', value: OTHER }];

const objectHasValues = obj =>
  !!Object.values(obj).filter(v => v !== null && v !== undefined).length;

const arrayFieldExists = (property, field) =>
  !!property?.[field]?.length && objectHasValues(property[field][0]);

const PropertyForm = ({
  entity,
  onInput,
  onChange,
  onReplace,
  onEntityUpdate,
  context: { quoteApplication, policies },
  validationState,
  onSaveCallback
}) => {
  const property = entity || {};
  const showValidation = validationState.allValid === false;

  const [hasGarage, setHasGarage] = useState(arrayFieldExists(property, PROPERTY_FIELDS.GARAGES));
  const [hasPool, setHasPool] = useState(arrayFieldExists(property, PROPERTY_FIELDS.POOLS));
  const currentPolicyOptions = getPolicyOptions(policies);

  const [hazardHubLoading, setHazardHubLoading] = useState(false);

  const handleMapHazardHub = () =>
    onSaveCallback(async () => {
      setHazardHubLoading(true);

      const data = await fetchHazardHubPropertyData({
        id: quoteApplication[GENERAL_FIELDS.ID].toString(),
        propertyId: property.id
      });

      setHazardHubLoading(false);

      if (!data) return alert('Could not fetch Hazard Hub data.');

      onReplace(data);

      alert(
        `Hazard Hub data fetched!\n\nThe following fields have been updated:\n${Object.entries(data)
          .map(([key, value], index) => `${index + 1}. ${key}: ${value}`)
          .join('\n')}`
      );
    });

  const canFetchHazardHub = !!property[PROPERTY_FIELDS.ADDRESS_ID];

  const yearBuilt = Number(property[PROPERTY_FIELDS.YEAR_BUILT]);
  const currentYear = new Date().getFullYear();
  const builtBeforeNumYears = num => (yearBuilt ? currentYear - num >= yearBuilt : true);

  return (
    <>
      <div className={styles.actions}>
        <Tooltip
          title={
            !canFetchHazardHub &&
            'Property must have an address before Hazard Hub data can be fetched.'
          }
        >
          <Button
            onClick={handleMapHazardHub}
            className={styles.button}
            disabled={!canFetchHazardHub || hazardHubLoading}
          >
            Fill Hazard Hub data
          </Button>
        </Tooltip>
      </div>

      <h5>General</h5>

      <InlineAddressForm
        idField={PROPERTY_FIELDS.ADDRESS_ID}
        selectedId={property[PROPERTY_FIELDS.ADDRESS_ID]}
        quoteApplication={quoteApplication}
        onChange={onChange}
        onEntityUpdate={onEntityUpdate}
        isValid={validationState[PROPERTY_FIELDS.ADDRESS_ID]?.isValid}
        showValidation={showValidation}
        autoFocus
      />

      <Select
        prompt="Line of Business"
        options={lineOfBusinessOptions}
        name={PROPERTY_FIELDS.LINE_OF_BUSINESS}
        onChange={onChange}
        value={createSelectOption(property[PROPERTY_FIELDS.LINE_OF_BUSINESS])}
        isValid={validationState[PROPERTY_FIELDS.LINE_OF_BUSINESS]?.isValid}
        showValidation={showValidation}
      />

      <Select
        prompt="Property Type"
        options={propertyTypeOptions}
        name={PROPERTY_FIELDS.PROPERTY_TYPE}
        onChange={onChange}
        value={createSelectOption(property[PROPERTY_FIELDS.PROPERTY_TYPE])}
        isValid={validationState[PROPERTY_FIELDS.PROPERTY_TYPE]?.isValid}
        showValidation={showValidation}
      />

      <Select
        prompt="Dwelling Type"
        options={dwellingTypeOptions}
        name={PROPERTY_FIELDS.DWELLING_TYPE}
        onChange={onChange}
        isValid={validationState[PROPERTY_FIELDS.DWELLING_TYPE]?.isValid}
        showValidation={showValidation}
        value={createSelectOption(property[PROPERTY_FIELDS.DWELLING_TYPE])}
      />

      <DateField
        prompt="Move-in Date"
        name={PROPERTY_FIELDS.MOVE_IN_DATE}
        value={property[PROPERTY_FIELDS.MOVE_IN_DATE]}
        onChange={onInput}
        isValid={validationState[PROPERTY_FIELDS.MOVE_IN_DATE]?.isValid}
        showValidation={showValidation}
      />

      <DateField
        prompt="Purchase Date*"
        name={PROPERTY_FIELDS.PURCHASE_DATE}
        value={property[PROPERTY_FIELDS.PURCHASE_DATE]}
        onChange={onInput}
        isValid={validationState[PROPERTY_FIELDS.PURCHASE_DATE]?.isValid}
        showValidation={showValidation}
        tooltip={`Only required if "Move-in Date" is not applicable`}
      />

      <NumberField
        label="# of Families on Premises"
        enforceInteger
        name={PROPERTY_FIELDS.FAMILIES_ON_PREMISES}
        value={property[PROPERTY_FIELDS.FAMILIES_ON_PREMISES]}
        onChange={onChange}
      />

      <NumberField
        label="# of Mortgages"
        enforceInteger
        name={PROPERTY_FIELDS.NUMBER_OF_MORTGAGES}
        value={property[PROPERTY_FIELDS.NUMBER_OF_MORTGAGES]}
        onChange={onChange}
        isValid={validationState[PROPERTY_FIELDS.NUMBER_OF_MORTGAGES]?.isValid}
        showValidation={showValidation}
      />

      {property[PROPERTY_FIELDS.PROPERTY_TYPE] === PROPERTY_TYPES.SECONDARY_RESIDENCE && (
        <NumberField
          label="Months Occupied"
          enforceInteger
          name={PROPERTY_FIELDS.MONTHS_OCCUPIED}
          value={property[PROPERTY_FIELDS.MONTHS_OCCUPIED]}
          onChange={onChange}
          isValid={validationState[PROPERTY_FIELDS.MONTHS_OCCUPIED]?.isValid}
          showValidation={showValidation}
        />
      )}

      <Select
        isMulti
        prompt="Home Businesses on Premises"
        options={homeBusinessOptions}
        name={PROPERTY_FIELDS.HOME_BUSINESSES}
        onChange={onChange}
        value={createMultiSelectValue(property[PROPERTY_FIELDS.HOME_BUSINESSES])}
      />

      <h5>Insurance</h5>

      <Select
        prompt={'Current property policy'}
        name={PROPERTY_FIELDS.CURRENT_POLICY_NUMBER}
        options={currentPolicyOptions}
        onChange={onChange}
        placeholder={currentPolicyOptions?.length ? '' : 'No Canopy policies found'}
        tooltip={'If the user has connected their insurance, select their current property policy.'}
        value={currentPolicyOptions.find(
          o => o.value === property[PROPERTY_FIELDS.CURRENT_POLICY_NUMBER]
        )}
      />

      <Select
        prompt={'Current property insurer'}
        name={PROPERTY_FIELDS.CURRENT_INSURER}
        options={carrierOptions}
        onChange={onChange}
        value={
          carrierOptions.find(o => o.value === property[PROPERTY_FIELDS.CURRENT_INSURER]) || null
        }
      />

      <DateField
        name={PROPERTY_FIELDS.CURRENT_POLICY_EXPIRATION}
        value={property[PROPERTY_FIELDS.CURRENT_POLICY_EXPIRATION]}
        prompt="Current property policy expiration"
        onChange={onInput}
      />

      <NumberField
        enforceInteger
        min={0}
        name={PROPERTY_FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER}
        value={property[PROPERTY_FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER]}
        prompt="Months with current insurer"
        onChange={onChange}
      />

      <h5>Basic details</h5>

      <Select
        prompt="Year Built"
        options={yearOptions}
        name={PROPERTY_FIELDS.YEAR_BUILT}
        onChange={onChange}
        value={createSelectOption(property[PROPERTY_FIELDS.YEAR_BUILT])}
        isValid={validationState[PROPERTY_FIELDS.YEAR_BUILT]?.isValid}
        showValidation={showValidation}
      />

      <NumberField
        label="Square Footage"
        enforceInteger
        name={PROPERTY_FIELDS.SQUARE_FOOTAGE}
        value={property[PROPERTY_FIELDS.SQUARE_FOOTAGE]}
        onChange={onChange}
        isValid={validationState[PROPERTY_FIELDS.SQUARE_FOOTAGE]?.isValid}
        showValidation={showValidation}
      />

      <Select
        prompt="# of Stories"
        name={PROPERTY_FIELDS.NUMBER_OF_STORIES}
        options={numStoriesOptions}
        value={createSelectOption(property[PROPERTY_FIELDS.NUMBER_OF_STORIES])}
        onChange={onChange}
        isValid={validationState[PROPERTY_FIELDS.NUMBER_OF_STORIES]?.isValid}
        showValidation={showValidation}
      />

      <NumberField
        label="# of Full Baths"
        enforceInteger
        name={PROPERTY_FIELDS.FULL_BATHS}
        value={property[PROPERTY_FIELDS.FULL_BATHS]}
        onChange={onChange}
        isValid={validationState[PROPERTY_FIELDS.FULL_BATHS]?.isValid}
        showValidation={showValidation}
      />

      <NumberField
        label="# of Half Baths"
        enforceInteger
        name={PROPERTY_FIELDS.HALF_BATHS}
        value={property[PROPERTY_FIELDS.HALF_BATHS]}
        onChange={onChange}
        isValid={validationState[PROPERTY_FIELDS.HALF_BATHS]?.isValid}
        showValidation={showValidation}
      />

      <h5>Full details</h5>

      <Select
        prompt="Primary Heating Source"
        options={primaryHeatingSourceOptions}
        name={PROPERTY_FIELDS.PRIMARY_HEATING_SOURCE}
        onChange={onChange}
        value={createSelectOption(property[PROPERTY_FIELDS.PRIMARY_HEATING_SOURCE])}
        isValid={validationState[PROPERTY_FIELDS.PRIMARY_HEATING_SOURCE]?.isValid}
        showValidation={showValidation}
      />

      {UNDERGROUND_FUEL_TANK_HEATING_SOURCES.includes(
        property[PROPERTY_FIELDS.PRIMARY_HEATING_SOURCE]
      ) && (
        <RadioGroup
          name={PROPERTY_FIELDS.HAS_UNDERGROUND_FUEL_TANK}
          prompt="Has underground fuel tank?"
          value={property[PROPERTY_FIELDS.HAS_UNDERGROUND_FUEL_TANK]}
          onChange={onChange}
          options={YES_NO_OPTIONS}
        />
      )}

      <RadioGroup
        name={PROPERTY_FIELDS.HAS_PET}
        prompt="Has animals on premises?"
        value={property[PROPERTY_FIELDS.HAS_PET]}
        onChange={onChange}
        options={YES_NO_OPTIONS}
      />

      {property[PROPERTY_FIELDS.HAS_PET] && (
        <>
          <Select
            isMulti
            prompt="Has following breeds of dogs:"
            options={dogBreedOptions}
            name={PROPERTY_FIELDS.BREEDS_OF_DOGS}
            onChange={onChange}
            value={createMultiSelectValue(
              property[PROPERTY_FIELDS.BREEDS_OF_DOGS],
              dogBreedOptions
            )}
            tooltip="Only select if applicable."
          />

          <RadioGroup
            name={PROPERTY_FIELDS.DANGEROUS_ANIMAL}
            prompt="Is the animal dangerous?"
            value={property[PROPERTY_FIELDS.DANGEROUS_ANIMAL]}
            onChange={onChange}
            options={YES_NO_OPTIONS}
            isValid={validationState[PROPERTY_FIELDS.DANGEROUS_ANIMAL]?.isValid}
            showValidation={showValidation}
          />
        </>
      )}

      <Select
        prompt="Construction Type"
        options={constructionTypeOptions}
        name={PROPERTY_FIELDS.CONSTRUCTION_TYPE}
        onChange={onChange}
        value={createSelectOption(property[PROPERTY_FIELDS.CONSTRUCTION_TYPE])}
        isValid={validationState[PROPERTY_FIELDS.CONSTRUCTION_TYPE]?.isValid}
        showValidation={showValidation}
      />

      <Select
        prompt="Exterior Wall Material"
        options={exteriorWallMaterialOptions}
        name={PROPERTY_FIELDS.EXTERIOR_WALL_MATERIAL}
        onChange={onChange}
        value={createSelectOption(property[PROPERTY_FIELDS.EXTERIOR_WALL_MATERIAL])}
        isValid={validationState[PROPERTY_FIELDS.EXTERIOR_WALL_MATERIAL]?.isValid}
        showValidation={showValidation}
      />

      <Select
        prompt="Foundation Type"
        options={foundationOptions}
        name={PROPERTY_FIELDS.FOUNDATION}
        onChange={onChange}
        value={createSelectOption(property[PROPERTY_FIELDS.FOUNDATION])}
        isValid={validationState[PROPERTY_FIELDS.FOUNDATION]?.isValid}
        showValidation={showValidation}
      />

      {property[PROPERTY_FIELDS.FOUNDATION] === FOUNDATION_TYPES.BASEMENT && (
        <Select
          prompt="Percent Finished Basement"
          options={percentFinishedBasementOptions}
          name={PROPERTY_FIELDS.PERCENT_FINISHED_BASEMENT}
          onChange={onChange}
          value={createSelectOption(property[PROPERTY_FIELDS.PERCENT_FINISHED_BASEMENT])}
          isValid={validationState[PROPERTY_FIELDS.PERCENT_FINISHED_BASEMENT]?.isValid}
          showValidation={showValidation}
        />
      )}

      {builtBeforeNumYears(5) && (
        <Select
          prompt="Roof Year"
          options={yearOptions}
          name={PROPERTY_FIELDS.ROOF_YEAR}
          onChange={onChange}
          value={createSelectOption(property[PROPERTY_FIELDS.ROOF_YEAR])}
        />
      )}

      <Select
        prompt="Roof Type"
        options={roofTypeOptions}
        name={PROPERTY_FIELDS.ROOF_TYPE}
        onChange={onChange}
        value={createSelectOption(property[PROPERTY_FIELDS.ROOF_TYPE])}
        isValid={validationState[PROPERTY_FIELDS.ROOF_TYPE]?.isValid}
        showValidation={showValidation}
      />

      <Select
        prompt="Roof Shape"
        options={roofShapeOptions}
        name={PROPERTY_FIELDS.ROOF_SHAPE}
        onChange={onChange}
        value={createSelectOption(property[PROPERTY_FIELDS.ROOF_SHAPE])}
        isValid={validationState[PROPERTY_FIELDS.ROOF_SHAPE]?.isValid}
        showValidation={showValidation}
      />

      <NumberField
        label="# of Solar Panels"
        enforceInteger
        name={PROPERTY_FIELDS.NUMBER_OF_SOLAR_PANELS}
        value={property[PROPERTY_FIELDS.NUMBER_OF_SOLAR_PANELS]}
        onChange={onChange}
        isValid={validationState[PROPERTY_FIELDS.NUMBER_OF_SOLAR_PANELS]?.isValid}
        showValidation={showValidation}
      />

      {builtBeforeNumYears(20) && (
        <Select
          prompt="Year heating was updated?"
          options={yearOptions}
          name={PROPERTY_FIELDS.YEAR_HEATING_UPDATED}
          onChange={onChange}
          value={createSelectOption(property[PROPERTY_FIELDS.YEAR_HEATING_UPDATED])}
        />
      )}

      {builtBeforeNumYears(50) && (
        <>
          <Select
            prompt="Year electric was updated?"
            options={yearOptions}
            name={PROPERTY_FIELDS.YEAR_ELECTRIC_UPDATED}
            onChange={onChange}
            value={createSelectOption(property[PROPERTY_FIELDS.YEAR_ELECTRIC_UPDATED])}
          />
          <Select
            prompt="Year plumbing was updated?"
            options={yearOptions}
            name={PROPERTY_FIELDS.YEAR_PLUMBING_UPDATED}
            onChange={onChange}
            value={createSelectOption(property[PROPERTY_FIELDS.YEAR_PLUMBING_UPDATED])}
          />
        </>
      )}

      {yearBuilt < 1965 && (
        <Select
          prompt="Electrical Junction"
          options={electricalJunctionOptions}
          name={PROPERTY_FIELDS.ELECTRICAL_JUNCTION}
          onChange={onChange}
          value={createSelectOption(property[PROPERTY_FIELDS.ELECTRICAL_JUNCTION])}
          isValid={validationState[PROPERTY_FIELDS.ELECTRICAL_JUNCTION]?.isValid}
          showValidation={showValidation}
          tooltip='Required only if "Year Built" is prior to 1965'
        />
      )}

      <NumberField
        label="Within how many feet of fire hydrant?"
        enforceInteger
        name={PROPERTY_FIELDS.WITHIN_FEET_OF_FIRE_HYDRANT}
        value={property[PROPERTY_FIELDS.WITHIN_FEET_OF_FIRE_HYDRANT]}
        onChange={onChange}
        isValid={validationState[PROPERTY_FIELDS.WITHIN_FEET_OF_FIRE_HYDRANT]?.isValid}
        showValidation={showValidation}
      />

      <NumberField
        label="Within how many miles of fire station?"
        enforceInteger
        name={PROPERTY_FIELDS.WITHIN_MILES_OF_FIRE_STATION}
        value={property[PROPERTY_FIELDS.WITHIN_MILES_OF_FIRE_STATION]}
        onChange={onChange}
        isValid={validationState[PROPERTY_FIELDS.WITHIN_MILES_OF_FIRE_STATION]?.isValid}
        showValidation={showValidation}
      />

      <h5>Garage</h5>

      <RadioGroup
        prompt="Has garage?"
        value={hasGarage}
        onChange={(_, val) => setHasGarage(val)}
        options={YES_NO_OPTIONS}
      />

      {hasGarage && (
        <>
          <Select
            prompt="Garage Construction"
            options={garageConstructionOptions}
            name={[PROPERTY_FIELDS.GARAGES, 0, PROPERTY_FIELDS.GARAGE_CONSTRUCTION].join('.')}
            onChange={onChange}
            value={createSelectOption(
              _.get(
                property,
                [PROPERTY_FIELDS.GARAGES, 0, PROPERTY_FIELDS.GARAGE_CONSTRUCTION].join('.')
              )
            )}
          />

          <NumberField
            label="How many car stalls in garage?"
            enforceInteger
            name={[PROPERTY_FIELDS.GARAGES, 0, PROPERTY_FIELDS.GARAGE_NUMBER_OF_CARS].join('.')}
            value={_.get(
              property,
              [PROPERTY_FIELDS.GARAGES, 0, PROPERTY_FIELDS.GARAGE_NUMBER_OF_CARS].join('.')
            )}
            onChange={onChange}
          />
        </>
      )}

      <h5>Pool</h5>

      <RadioGroup
        prompt="Has pool?"
        value={hasPool}
        onChange={(_, val) => setHasPool(val)}
        options={YES_NO_OPTIONS}
      />

      {hasPool && (
        <>
          <RadioGroup
            prompt="Is pool in-ground?"
            name={[PROPERTY_FIELDS.POOLS, 0, PROPERTY_FIELDS.POOL_IN_GROUND].join('.')}
            value={_.get(
              property,
              [PROPERTY_FIELDS.POOLS, 0, PROPERTY_FIELDS.POOL_IN_GROUND].join('.')
            )}
            onChange={onChange}
            options={YES_NO_OPTIONS}
          />

          <RadioGroup
            prompt="Pool access is restricted?"
            name={[PROPERTY_FIELDS.POOLS, 0, PROPERTY_FIELDS.POOL_ACCESS_RESTRICTED].join('.')}
            value={_.get(
              property,
              [PROPERTY_FIELDS.POOLS, 0, PROPERTY_FIELDS.POOL_ACCESS_RESTRICTED].join('.')
            )}
            onChange={onChange}
            options={YES_NO_OPTIONS}
          />
        </>
      )}

      <h5>Risks</h5>

      <RadioGroup
        name={PROPERTY_FIELDS.FLOOD_ZONE}
        prompt="Located in flood zone?"
        value={property[PROPERTY_FIELDS.FLOOD_ZONE]}
        onChange={onChange}
        options={YES_NO_OPTIONS}
      />

      <RadioGroup
        name={PROPERTY_FIELDS.HAS_FLOOD_POLICY}
        prompt="Has flood policy?"
        value={property[PROPERTY_FIELDS.HAS_FLOOD_POLICY]}
        onChange={onChange}
        options={YES_NO_OPTIONS}
      />
    </>
  );
};

PropertyForm.propTypes = {
  context: PropTypes.object,
  entity: PropTypes.object,
  onChange: PropTypes.func,
  onReplace: PropTypes.func,
  onEntityUpdate: PropTypes.func,
  onInput: PropTypes.func,
  validationState: PropTypes.object,
  onSaveCallback: PropTypes.func
};

export default PropertyForm;

import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { UPDATE_VEHICLE, ADD_VEHICLE, DELETE_VEHICLE } from '../actions/vehicles';
import { VEHICLE_FIELDS, VEHICLE_TAG_PREFIX } from '../constants/origination.js';
import { GET_ORIGINATION_SUCCESS } from '../actions/originations';
import { formatDateInputValue } from '../components/DateField/utils';

function vehicles(state = {}, action) {
  switch (action.type) {
    case ADD_VEHICLE: {
      const { payload } = action;
      return {
        ...state,
        [payload[VEHICLE_FIELDS.VEHICLE_TAG]]: payload
      };
    }
    case DELETE_VEHICLE: {
      const { payload: id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case UPDATE_VEHICLE: {
      const { id, updateObj } = action.payload;
      const vehicleToUpdate = state[id];
      return { ...state, [id]: { ...vehicleToUpdate, ...updateObj } };
    }
    case GET_ORIGINATION_SUCCESS: {
      const vehicles =
        action.payload?.originationData?.[ORIGINATION_FIELDS.AUTO_INFO]?.[
          ORIGINATION_FIELDS.VEHICLES
        ];
      const vehiclesWithTags = {};
      vehicles?.forEach((vehicle, index) => {
        const tag = `${VEHICLE_TAG_PREFIX}${index + 1}`; // We don't store vehicle tags so generate them here.
        vehiclesWithTags[tag] = {
          ...vehicle,
          [VEHICLE_FIELDS.VEHICLE_TAG]: tag,
          [VEHICLE_FIELDS.VEHICLE_PURCHASE_DATE]: formatDateInputValue(
            vehicle?.[VEHICLE_FIELDS.VEHICLE_PURCHASE_DATE]
          )
        };
      });
      return vehiclesWithTags;
    }
    default:
      return state;
  }
}

export default vehicles;

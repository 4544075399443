import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { FiExternalLink } from 'react-icons/fi';
import { GENERAL_FIELDS } from 'woop-shared/db/models';
import { useDispatch } from 'react-redux';
import styles from '../ApplicantsView/ApplicantsView.css';
import Card from '../../components/Card';
import JourneyCard from '../../components/JourneyCard';
import ErrorText from '../../components/ErrorText';
import SummaryCard from './components/SummaryCard';
import EditCard from './components/EditCard';
import RelatedRecords from './components/RelatedRecords';
import LinkText from '../../components/LinkText';
import { getPullsSuccess } from '../../actions/pulls';
import CanopySummary from '../../components/CanopySummary';

const cx = classnames.bind(styles);

const ApplicantView = ({
  loading,
  error,
  pulls,
  reports,
  getPulls,
  getReports,
  journey: {
    journeyId,
    uiData,
    canopy,
    hazardHub,
    originationId,
    quoteAppId,
    quoteset = {},
    ivReport = {},
    fk_user: userId,
    uploadsUrl,
    submitted
  },
  getJourney
}) => {
  const [edit, setEdit] = useState();
  const [showCanopy, setShowCanopy] = useState(true);
  const [showHazard, setShowHazard] = useState(true);
  let { journeyId: journeyIdUrl } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      getPulls(userId);
      getReports(userId);
    }
  }, [userId]);

  useEffect(() => {
    // If the id from the URL path doesn't match the ID in state.
    if (journeyIdUrl !== journeyId) {
      getJourney(journeyIdUrl);
    }
  }, []);

  useEffect(() => {
    if (!userId && canopy) {
      dispatch(getPullsSuccess(canopy));
    }
  }, [userId, canopy]);

  return (
    <section className={cx('wrapper', { loading })}>
      {!journeyId ? (
        <Card>
          <div>{loading && `Loading...`}</div>
          {error.message && <ErrorText>{error.message}</ErrorText>}
        </Card>
      ) : (
        <>
          <section className={styles.twoCol}>
            <SummaryCard
              journeyId={journeyId}
              uiData={uiData}
              quotesetId={quoteset[GENERAL_FIELDS.ID]}
              editable={true}
              edit={edit}
              setEdit={setEdit}
              canopy={canopy}
              submitted={submitted}
            ></SummaryCard>
            <RelatedRecords
              quoteset={quoteset}
              ivReport={ivReport}
              originationId={originationId}
              quoteAppId={quoteAppId}
            />
          </section>

          {edit && <EditCard uiData={uiData} journeyId={journeyId} setEdit={setEdit} />}

          <Card className={styles.card}>
            <h4>Connected Policies</h4>
            {pulls.length ? (
              <CanopySummary pulls={pulls} reports={reports} userId={userId} loading={loading} />
            ) : (
              <div style={{ marginBottom: 20 }}>N/A</div>
            )}
            <h4>Customer uploads</h4>
            {uploadsUrl ? (
              <LinkText href={uploadsUrl} external icon={<FiExternalLink />}>
                View uploads
              </LinkText>
            ) : (
              <span>N/A</span>
            )}
          </Card>

          <JourneyCard journeyId={journeyId} uiData={uiData} />

          <Card className={cx('card')}>
            <h4>Canopy</h4>
            {showCanopy ? (
              <pre>{JSON.stringify(canopy, undefined, 2)}</pre>
            ) : (
              <LinkText onClick={() => setShowCanopy(!!showCanopy)}>Show details</LinkText>
            )}
          </Card>
          <Card className={cx('card')}>
            <h4>Hazard Hub</h4>
            {showHazard ? (
              <pre>{JSON.stringify(hazardHub, undefined, 2)}</pre>
            ) : (
              <LinkText onClick={() => setShowHazard(!!showHazard)}>Show details</LinkText>
            )}
          </Card>
        </>
      )}
    </section>
  );
};

ApplicantView.propTypes = {
  error: PropTypes.object,
  getJourney: PropTypes.func,
  getPulls: PropTypes.func,
  getReports: PropTypes.func,
  journey: PropTypes.object,
  loading: PropTypes.bool,
  pulls: PropTypes.array,
  reports: PropTypes.array
};

export default ApplicantView;

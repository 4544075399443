import axios from 'axios';

function initBox() {
  // Create script tag
  const script = document.createElement('script');
  script.src = 'https://cdn01.boxcdn.net/platform/elements/11.0.2/en-US/uploader.js';
  script.async = true;
  document.body.appendChild(script);

  // Create css link
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'https://cdn01.boxcdn.net/platform/elements/11.0.2/en-US/uploader.css';
  document.head.appendChild(link);
}

function initAxios() {
  axios.interceptors.request.use(req => {
    if (req.url.toLowerCase().includes('woop') || req.url.includes('http://localhost:')) {
      req.headers['woop-api-key'] = process.env.WOOP_API_KEY;
    }
    return req;
  });
}

export function beforeRender() {
  initAxios();
}

export function afterRender() {
  initBox();
}

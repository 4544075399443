import React, { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { CHARTS, URL } from './constants';
import styles from './styles.css';

const Charts = () => {
  const container = useRef(null);
  const [chart, setChart] = useState();

  // Initialize the SDK and the chart.
  useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: URL,
      showAttribution: false
    });

    const _chart = sdk.createChart({
      chartId: CHARTS.JOURNEY_DROP_OFFS
    });

    setChart(_chart);
  }, []);

  // Render the chart.
  useEffect(() => {
    if (container?.current && chart) {
      chart.render(container.current);
    }
  }, [container, chart]);

  return (
    <div className={styles.wrapper}>
      <div ref={container} className={styles.chart} />
    </div>
  );
};

export default Charts;

import { FOREIGN_KEYS, QUOTE_FIELDS } from 'woop-shared/db/models';

export const TOO_MANY_POLICIES_MESSAGE = `A customer can only have one policy per coverage type. Check to make sure you haven't already created the policies you need.`;

export const QUOTE_FIELD_NAMES = {
  [FOREIGN_KEYS.CARRIER_CODE]: 'Carrier',
  [QUOTE_FIELDS.COVERAGES]: 'Coverages',
  [QUOTE_FIELDS.COVERAGE_TYPE]: 'Coverage Type',
  [QUOTE_FIELDS.PREMIUMS]: 'Premiums',
  [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: 'Additional Coverages',
  [QUOTE_FIELDS.DEDUCTIBLE]: 'Deductible',
  [QUOTE_FIELDS.PDF]: 'PDF URL',
  [QUOTE_FIELDS.COVERAGE_TAG]: 'Coverage Tag'
};

export const QUOTESET_ERRORS = {
  NO_QUOTES: 'The quoteset has no quotes',
  INVALID_QUOTES: 'Some quotes are missing required fields',
  INVALID_SUPPLEMENTARY_INFO: 'Supplementary information contains errors',
  NO_AGENT_NOTES: 'Missing agent notes',
  NO_AGENT: 'No agent selected',
  DUPLICATE_POLICIES: 'There are multiple policies with the same coverage type',
  INVALID_BUNDLE: 'Bundles must have at least 2 quotes.'
};

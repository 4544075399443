import { COVERAGES, FIELDS, POLICY_TYPES } from 'woop-shared/canopy/enums';
import { QUOTE_FIELDS, REPORT_FIELDS } from 'woop-shared/db/models';
import { COVERAGE_TAGS } from 'woop-shared/enums';
import { hasVal } from 'woop-shared/utils';
import { getSubtype, getType } from '../Policy/components/InsuranceHistory/utils';
import { getPolicyClaims } from '../Policy/utils';

export function getQuotesetRows(quotes, policyType, isQuotesetFinalized) {
  const iterableQuotes = Object.values(quotes);
  const validQuotes = iterableQuotes.filter(quote => !quote[QUOTE_FIELDS.IS_CURRENT_POLICY]);

  const hasMediumQuote = !!validQuotes.find(
    quote =>
      quote[QUOTE_FIELDS.COVERAGE_TAG] === COVERAGE_TAGS.MEDIUM &&
      quote[QUOTE_FIELDS.COVERAGE_TYPE] === policyType
  );

  const hasHighQuote = !!validQuotes.find(
    quote =>
      quote[QUOTE_FIELDS.COVERAGE_TAG] === COVERAGE_TAGS.HIGH &&
      quote[QUOTE_FIELDS.COVERAGE_TYPE] === policyType
  );

  const getEmoji = check => (check ? '✅' : '❌');

  return [
    { label: 'Apples to apples', value: getEmoji(hasMediumQuote) },
    { label: 'Professional recommendation', value: getEmoji(hasHighQuote) },
    { label: 'Quoteset finalized', value: getEmoji(isQuotesetFinalized) }
  ];
}

export const validateReportFields = report => [
  [
    'Years insured',
    report?.[REPORT_FIELDS.POLICY_TYPE] !== POLICY_TYPES.AUTO ||
      hasVal(report?.[REPORT_FIELDS.INSURANCE_HISTORY]?.[REPORT_FIELDS.YEARS_CONTINUOUSLY_INSURED])
  ],
  ['Address', hasVal(report?.[REPORT_FIELDS.ADDRESS])]
];

export const validatePolicyFields = policy => {
  const claims = getPolicyClaims(policy);

  return [
    ['Policy effective date', !!policy?.[FIELDS.POLICY.EFFECTIVE_DATE]],
    ['Policy expiry date', !!policy?.[FIELDS.POLICY.EXPIRY_DATE]],
    ['Policy premium', !!policy?.[FIELDS.POLICY.TOTAL_PREMIUM_CENTS]],
    ['Claim type', claims.every(item => hasVal(getType(item)))],
    ['Claim sub-type', claims.every(item => hasVal(getSubtype(item)))]
  ];
};

export const areFieldsInvalid = fields => fields?.some(([, isValid]) => !isValid);

export const findReplacementCost = policy => {
  const dwelling = policy?.[FIELDS.POLICY.DWELLINGS]?.[0] || {};
  const coverage =
    dwelling?.[FIELDS.COVERAGES]?.find(cov => cov[FIELDS.COVERAGE.NAME] === COVERAGES.DWELLING) ||
    {};
  return coverage[FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS] / 100 || null;
};

export const calculateRepCostDiff = (current, recommended) => {
  if (!current || !recommended) return;
  const percentage = (((recommended - current) / current) * 100).toFixed(2);
  if (percentage > 100) return '99.99';
  return percentage;
};

export const getRecommendationText = percentage => {
  if (percentage < 2) return 'None';
  return 'Increase';
};

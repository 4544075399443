import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles.css';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import MoneyField from '../../../../components/MoneyField';
import { convertToCents, formatCents } from '../../../Policy/utils';

const EditCoverage = ({ coverage, visible, closing, toggleModal, updateCoverage }) => {
  const [name, setName] = useState();
  const [perPersonLimit, setPerPersonLimit] = useState();
  const [perIncidentLimit, setPerIncidentLimit] = useState();
  const [deductible, setDeductible] = useState();

  useEffect(() => {
    setName(coverage?.friendly_name);
    setPerPersonLimit(formatCents(coverage?.per_person_limit_cents));
    setPerIncidentLimit(formatCents(coverage?.per_incident_limit_cents));
    setDeductible(formatCents(coverage?.deductible_cents));
  }, [coverage]);

  const save = () => {
    updateCoverage({
      ...coverage,
      per_person_limit_cents: convertToCents(perPersonLimit),
      per_incident_limit_cents: convertToCents(perIncidentLimit),
      deductible_cents: convertToCents(deductible)
    });
    toggleModal();
  };

  return (
    visible && (
      <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
        <div>Edit Coverage</div>
        <div className={styles.form}>
          <div className={styles.coverageNameLabel}>Coverage Name</div>
          <div className={styles.coverageName}>{name}</div>

          <MoneyField
            name="perPersonLimit"
            placeholder="$"
            label={'Per Person Limit'}
            value={perPersonLimit}
            onChange={(_, value) => setPerPersonLimit(value)}
          />
          <MoneyField
            name="perIncidentLimit"
            placeholder="$"
            label={'Per Incident Limit'}
            value={perIncidentLimit}
            onChange={(_, value) => setPerIncidentLimit(value)}
          />
          <MoneyField
            name="deductible"
            placeholder="$"
            label={'Deductible'}
            value={deductible}
            onChange={(_, value) => setDeductible(value)}
          />
        </div>
        <Button className={styles.modalButton} onClick={save}>
          Save
        </Button>
      </Modal>
    )
  );
};

EditCoverage.propTypes = {
  coverage: PropTypes.object,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateCoverage: PropTypes.func
};

export default EditCoverage;

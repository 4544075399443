import {
  HiOutlineLocationMarker,
  HiOutlineMail,
  HiOutlineOfficeBuilding,
  HiOutlinePhone,
  HiOutlineSave,
  HiOutlineTag,
  HiOutlineTruck,
  HiOutlineUsers
} from 'react-icons/hi';
import React from 'react';
import PropTypes from 'prop-types';
import {
  getDrivers,
  getPhone,
  getPrimaryAddress,
  getVehicles
} from 'woop-shared/origination/utils';
import dayjs from 'dayjs';
import { GENERAL_FIELDS, PARTNER_FIELDS } from 'woop-shared/db/models';
import { PROMPT_GROUP_NAMES, PROMPT_NAMES } from 'woop-shared/prompts/names';
import { getCoverageTypes } from 'woop-shared/utils/ui-data';
import { ADDRESS_FIELDS, ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import styles from './styles.css';
import Card from '../../../../components/Card';
import { getPrimaryApplicant } from '../../../../utils/origination';
import { APPLICANT_FIELDS } from '../../constants';

const SummaryCard = ({ quoteApp, uiData, partner }) => {
  if (!quoteApp._id) return null;
  const submittedDate = dayjs(quoteApp[GENERAL_FIELDS.CREATED_UTC]).format('MM/DD/YYYY');
  const applicant = getPrimaryApplicant(quoteApp[ORIGINATION_FIELDS.APPLICANTS]) || {};
  const phone = getPhone(applicant);
  const address = getPrimaryAddress(quoteApp) || {};
  const numVehicles = getVehicles(quoteApp)?.length;
  const numDrivers = getDrivers(quoteApp)?.length;
  const tradeInVIN = uiData?.[PROMPT_GROUP_NAMES.TRADE_INS]?.[0]?.[PROMPT_NAMES.VIN];
  const coverageTypes = getCoverageTypes(uiData).join(' + ');

  return (
    <>
      <Card className={styles.wrapper} size={'auto'}>
        <div className={styles.content}>
          <div className={styles.heading}>
            {applicant[APPLICANT_FIELDS.FIRST_NAME]} {applicant[APPLICANT_FIELDS.LAST_NAME]}
          </div>

          <div className={styles.data}>
            <div className={styles.title}>Applicant</div>

            <div className={styles.columns}>
              <div className={styles.column}>
                <div className={styles.row}>
                  <HiOutlineLocationMarker title="Full address" />
                  <div>
                    {address[ADDRESS_FIELDS.COMPOSITE]}{' '}
                    {address[ADDRESS_FIELDS.UNIT_NUMBER]
                      ? ' - Unit: ' + address[ADDRESS_FIELDS.UNIT_NUMBER]
                      : ''}
                  </div>
                </div>
              </div>

              <div className={styles.column}>
                <div className={styles.row}>
                  <HiOutlineMail title="Email" />
                  <div>{applicant[APPLICANT_FIELDS.EMAIL]}</div>
                </div>
                <div className={styles.row}>
                  <HiOutlinePhone title="Phone" />
                  <div>{phone}</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.data}>
            <div className={styles.title}>Coverage</div>

            <div className={styles.columns}>
              <div className={styles.column}>
                <div className={styles.row}>
                  <HiOutlineTag title="Requested LOBs" />
                  <div>{coverageTypes}</div>
                </div>
                <div className={styles.row}>
                  <HiOutlineSave title="Trade-in VIN" />
                  <div>{tradeInVIN ? `Trade-in: ${tradeInVIN}` : 'No trade-in'}</div>
                </div>
              </div>

              <div className={styles.column}>
                <div className={styles.row}>
                  <HiOutlineTruck title="Number of vehicles" />
                  <div>
                    {numVehicles} {numVehicles === 1 ? 'vehicle' : 'vehicles'}
                  </div>
                </div>
                <div className={styles.row}>
                  <HiOutlineUsers title="Number of drivers" />
                  <div>
                    {numDrivers} {numDrivers === 1 ? 'driver' : 'drivers'}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.data}>
            <div className={styles.title}>Source</div>

            <div className={styles.columns}>
              <div className={styles.column}>
                {partner?.[PARTNER_FIELDS.NAME] && (
                  <div className={styles.row}>
                    <HiOutlineOfficeBuilding title="Partner" />
                    <div>{partner[PARTNER_FIELDS.NAME]}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {(uiData?.[PROMPT_NAMES.FEEDBACK] || uiData?.[PROMPT_NAMES.COMMENTS]) && (
            <div className={styles.data}>
              <div className={styles.columns}>
                {uiData[PROMPT_NAMES.FEEDBACK] && (
                  <div className={styles.column}>
                    <div className={styles.title}>Feedback</div>
                    <div className={styles.box}>
                      <pre>{uiData[PROMPT_NAMES.FEEDBACK]}</pre>
                    </div>
                  </div>
                )}

                {uiData[PROMPT_NAMES.COMMENTS] && (
                  <div className={styles.column}>
                    <div className={styles.title}>Comments</div>
                    <div className={styles.box}>
                      <pre>{uiData[PROMPT_NAMES.COMMENTS]}</pre>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={styles.actions}>
          <div className={styles.section}>
            <div className={styles.dates}>
              {submittedDate && (
                <div>
                  Submitted: <span>{submittedDate}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

SummaryCard.propTypes = {
  quoteApp: PropTypes.object,
  uiData: PropTypes.object,
  partner: PropTypes.object
};

export default SummaryCard;

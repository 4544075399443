import React, { useEffect, useRef, useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { capitalize } from 'woop-shared/utils';
import { FOREIGN_KEYS } from 'woop-shared/db/models';
import { useDispatch, useSelector } from 'react-redux';
import { FiExternalLink } from 'react-icons/fi';
import Card from '../../components/Card';
import EditableSection from './components/EditableSection';
import { useModalControls } from '../../hooks/modal-controls';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { setQuoteApplication } from '../../actions/quote-application';
import { getQuoteApplication, patchQuoteApplication, postQuoteRequest } from '../../api/quoteset';
import { ENTITIES, ENTITY_CONFIG, SECTIONS } from './constants/config';
import styles from './styles.module.css';
import useScrollToTop from '../../hooks/scroll-to-top';
import { getErrorList } from './utils';
import SummaryCard from './components/SummaryCard';
import { getJourney } from '../../actions/journeys';
import RelatedRecords from '../ApplicantView/components/RelatedRecords';
import { getPulls, getPullsSuccess } from '../../actions/pulls';
import { getReports } from '../../actions/reports';
import LinkText from '../../components/LinkText';
import { getRecentPolicies } from '../../utils/canopy';
import { ROUTES, addIdToRoute } from '../../router/routes';
import { error } from '../../utils/logger';
import { getOrigination } from '../../actions/originations';
import { getPartner } from '../../actions/partners';
import { validateCriticalFields, validateQuoteApp } from './utils/validation';
import CanopySummary from '../../components/CanopySummary';

const QuoteApplication = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: appId } = useParams();
  const { quoteApplication, journey, pulls, reports, origination, partner } = useSelector(
    state => state
  );
  const [visible, closing, toggleModal] = useModalControls();
  const [validationState, setValidationState] = useState({});
  const [entityKey, setEntityKey] = useState();
  const [activeEntity, setActiveEntity] = useState();

  useEffect(() => {
    getQuoteApplication(appId).then(res => {
      dispatch(setQuoteApplication(res));
      dispatch(getOrigination({ id: res[FOREIGN_KEYS.ORIGINATION], preventRedirect: true }));

      if (res[FOREIGN_KEYS.JOURNEY]) {
        dispatch(getJourney(res[FOREIGN_KEYS.JOURNEY]));
      }

      if (res[FOREIGN_KEYS.USER]) {
        dispatch(getPulls(res[FOREIGN_KEYS.USER]));
        dispatch(getReports(res[FOREIGN_KEYS.USER]));
      }
    });
  }, [appId]);

  useEffect(() => {
    const accountId = origination[ORIGINATION_FIELDS.ACCOUNT_ID];
    if (!accountId) return;
    dispatch(getPartner(accountId));
  }, [origination]);

  useEffect(() => {
    const { canopy, userId } = journey;
    if (!userId && canopy && !pulls.length) {
      dispatch(getPullsSuccess(canopy));
    }
  }, [journey, pulls]);

  const patchUpdate = (update, callback = null) => {
    patchQuoteApplication(appId, update)
      .then(res => {
        dispatch(setQuoteApplication(res));
        callback && callback();
      })
      .catch(e => {
        error(e);
        alert('There was an issue saving this quote application. The tech team has been alerted.');
      });
  };

  const handleAdd = entityKey => {
    const uuid = uuidv4();
    const { defaults } = ENTITY_CONFIG[entityKey];
    const newEntity = { id: uuid, ...defaults(quoteApplication) };
    setActiveEntity(newEntity);
    setEntityKey(entityKey);

    setValidationState({});
    toggleModal();
  };

  const handleEdit = (row, entityKey) => {
    setActiveEntity(row);
    setEntityKey(entityKey);

    const { validator } = ENTITY_CONFIG[entityKey];
    setValidationState(validator(row, quoteApplication));

    toggleModal();
  };

  const handleDelete = (id, key) => {
    const entities = _.get(quoteApplication, key);
    const updatedEntities = entities.filter(e => e.id !== id);
    const update = _.cloneDeep(quoteApplication);

    // delete id from related entities
    const deleteId = obj =>
      _.forEach(obj, (v, k) => {
        if (k === key) return;
        if (typeof v === 'object') return deleteId(v);
        if (v === id) obj[k] = null;
      });

    deleteId(update);
    _.set(update, key, updatedEntities);
    patchUpdate(update);
  };

  const getUpdatedEntity = (key, value) => {
    const makeObjectUpdate = () => ({
      ...quoteApplication,
      [key]: { ...quoteApplication[key], ...value }
    });

    const makeArrayUpdate = () => {
      const entities = _.get(quoteApplication, key) || [];
      const indexToUpdate = entities.findIndex(e => e.id === value.id);
      const updatedEntities = [...entities];

      if (indexToUpdate >= 0) updatedEntities[indexToUpdate] = value;
      else updatedEntities.push(value);

      const update = { ...quoteApplication };
      _.set(update, key, updatedEntities);

      return update;
    };

    return entityKey === ENTITIES.AUTO_COVERAGE ? makeObjectUpdate() : makeArrayUpdate();
  };

  const handleEntitySave = (key, value) => {
    const update = getUpdatedEntity(key, value);
    patchUpdate(update);
  };

  const validateAndSave = (callback = null) => {
    const { key, validator } = ENTITY_CONFIG[entityKey];
    const update = getUpdatedEntity(key, activeEntity);
    setValidationState(validator(activeEntity, quoteApplication));

    patchUpdate(update, callback);
  };

  const handleSave = () => {
    validateAndSave();
    toggleModal();
  };

  const handleSaveCallback = callback => validateAndSave(callback);

  const handleChange = (key, value) => {
    const update = { ...activeEntity };
    _.set(update, key, value);
    setActiveEntity(update);
  };

  const handleInput = event => {
    if (event.target.tagName === 'INPUT') {
      const key = event.target.name;
      const val = event.target.value;
      handleChange(key, val);
    }
  };

  const handleReplace = update => setActiveEntity({ ...activeEntity, ...update });

  const goToQuoting = async () => {
    const errors = validateCriticalFields(quoteApplication);
    if (errors) return alert(errors);
    // Warn if quote application is incomplete, else continue
    if (
      validateQuoteApp(quoteApplication) ||
      confirm(
        'This quote application may be missing required information. Do you want to continue?'
      )
    ) {
      // Continue
      const { id } = await postQuoteRequest(appId);
      history.push(addIdToRoute(ROUTES.QUOTE_REQUEST, id));
    }
  };

  const resumeQuoting = async () => {
    history.push(addIdToRoute(ROUTES.QUOTE_REQUEST, quoteApplication.quoteReqId));
  };

  const ModalForm = ENTITY_CONFIG[entityKey]?.form;
  const modalRef = useRef(null);
  useScrollToTop([modalRef, visible], modalRef.current);

  useEffect(() => {
    if (!visible) return;

    const listener = document.addEventListener('keydown', e => {
      if (e.key === 'Escape') toggleModal();
    });

    return () => document.removeEventListener('keydown', listener);
  }, [visible]);

  const recentPolicies = pulls ? getRecentPolicies(pulls) : [];
  return (
    <section className={styles.wrapper}>
      <section className={styles.twoCol}>
        <SummaryCard quoteApp={quoteApplication} uiData={journey.uiData} partner={partner} />
        <RelatedRecords
          quoteset={journey?.quoteset}
          originationId={quoteApplication[FOREIGN_KEYS.ORIGINATION]}
          journeyId={quoteApplication[FOREIGN_KEYS.JOURNEY]}
          ivReport={journey?.ivReport}
          quoteReqId={quoteApplication.quoteReqId}
          quoteResId={quoteApplication.quoteResId}
        />
      </section>
      <section>
        <Card size={'auto'}>
          {pulls.length ? (
            <CanopySummary
              pulls={pulls}
              reports={reports}
              userId={quoteApplication[FOREIGN_KEYS.USER]}
            />
          ) : (
            <div style={{ marginBottom: 20 }}>
              <i>No connected policies.</i>
            </div>
          )}
          <h4>Customer uploads</h4>
          {journey?.uploadsUrl ? (
            <LinkText href={journey?.uploadsUrl} external icon={<FiExternalLink />}>
              View uploads
            </LinkText>
          ) : (
            <span>N/A</span>
          )}
        </Card>
      </section>

      <Card size="auto" className={styles.card}>
        {Object.values(SECTIONS).map(section => (
          <div className={styles.section} key={section}>
            <h3>{section}</h3>
            {Object.entries(ENTITY_CONFIG)
              .filter(([, config]) => config.section === section)
              .map(([entityKey, config]) => {
                const formErrors = config.tableValidator?.(
                  config.getter({ quoteApp: quoteApplication, pulls })
                );

                return (
                  <div key={entityKey} className={styles.inner}>
                    <EditableSection
                      title={config.title}
                      rows={config.getter({ quoteApp: quoteApplication, pulls }).map(row => {
                        // Augment validation
                        const { allValid, ...fields } =
                          config?.validator(row, quoteApplication) || {};
                        return {
                          ...row,
                          valid: allValid,
                          errors: getErrorList(fields)
                        };
                      })}
                      columns={config.columns}
                      onEdit={row => handleEdit(row, entityKey)}
                      onAdd={config.disableAdd ? null : () => handleAdd(entityKey)}
                      onDelete={
                        config.disableDelete ? null : index => handleDelete(index, config.key)
                      }
                    />

                    {!!formErrors?.length && (
                      <div className={styles.errors}>
                        <div className={styles.heading}>Invalid data</div>

                        <ul>
                          {formErrors.map((error, index) => (
                            <li key={index} className={styles.line}>
                              {error}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        ))}
        <Modal visible={visible} toggleModal={toggleModal} closing={closing}>
          <section>
            <h4>
              {activeEntity ? 'Edit ' : 'Add '}
              {capitalize(ENTITY_CONFIG[entityKey]?.entityName || entityKey)}
            </h4>
            <div className={styles.modalForm} ref={modalRef}>
              {ModalForm && visible && (
                <ModalForm
                  entity={activeEntity}
                  onInput={handleInput}
                  onChange={handleChange}
                  onReplace={handleReplace}
                  validationState={validationState}
                  context={{ quoteApplication, policies: recentPolicies }}
                  onEntityUpdate={handleEntitySave}
                  onSaveCallback={handleSaveCallback}
                />
              )}
            </div>
            <Button onClick={handleSave} className={styles.modalButton}>
              Save
            </Button>
          </section>
        </Modal>
      </Card>
      <div className={styles.btnWrapper}>
        {quoteApplication.quoteReqId && (
          <Button onClick={resumeQuoting} className={styles.resumeButton}>
            Resume <HiArrowRight />
          </Button>
        )}
        <Button onClick={goToQuoting} className={styles.quotesButton}>
          Start quotes <HiArrowRight />
        </Button>
      </div>
    </section>
  );
};

QuoteApplication.propTypes = {
  children: PropTypes.node
};

export default QuoteApplication;

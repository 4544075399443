import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../TextField';
import { FIELD } from '../constants';

const TestCommunication = ({ onChange, fields }) => {
  return (
    <>
      <TextField
        label="Email"
        name={FIELD.TEST_EMAIL}
        value={fields[FIELD.TEST_EMAIL]}
        onChange={e => onChange(FIELD.TEST_EMAIL, e.target.value)}
      />
    </>
  );
};

TestCommunication.propTypes = {
  onChange: PropTypes.func,
  fields: PropTypes.object
};

export default TestCommunication;

import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import {
  UPDATE_PROPERTY_INCIDENT,
  ADD_PROPERTY_INCIDENT,
  DELETE_PROPERTY_INCIDENT
} from '../actions/property-incidents';
import { PROPERTY_INCIDENT_FIELDS } from '../constants/origination';
import { GET_ORIGINATION_SUCCESS } from '../actions/originations';
import { formatDateInputValue } from '../components/DateField/utils';

function propertyIncidents(state = {}, action) {
  switch (action.type) {
    case ADD_PROPERTY_INCIDENT: {
      const { payload } = action;
      return {
        ...state,
        [payload.id]: payload
      };
    }
    case DELETE_PROPERTY_INCIDENT: {
      const { payload: id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case UPDATE_PROPERTY_INCIDENT: {
      const { id, updateObj } = action.payload;
      const incidentToUpdate = state[id];
      return { ...state, [id]: { ...incidentToUpdate, ...updateObj } };
    }
    case GET_ORIGINATION_SUCCESS: {
      const propertyIncidents =
        action.payload?.originationData?.[ORIGINATION_FIELDS.PROPERTY_INFO]?.[
          ORIGINATION_FIELDS.PROPERTY_INCIDENTS
        ];
      const propertyIncidentsWithId = {};
      propertyIncidents?.forEach((propertyIncident, index) => {
        const id = index + 1; // We don't store incident ids so generate them here.
        propertyIncidentsWithId[id] = {
          ...propertyIncident,
          [PROPERTY_INCIDENT_FIELDS.ID]: id,
          [PROPERTY_INCIDENT_FIELDS.INCIDENT_DATE]: formatDateInputValue(
            propertyIncident?.[PROPERTY_INCIDENT_FIELDS.INCIDENT_DATE]
          )
        };
      });
      return propertyIncidentsWithId;
    }
    default:
      return state;
  }
}

export default propertyIncidents;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { US_STATES } from 'woop-shared/data/states';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import {
  APPLICANT_TYPES,
  GENDER,
  MARITAL_STATUSES,
  DEFAULT_RELATIONSHIP,
  RELATIONSHIPS,
  EDUCATION,
  DRIVER_TYPE,
  RESIDENCE_TYPES,
  LICENSE_STATUS_OPTIONS,
  PHONE_TYPES,
  PREFERRED_COMMUNICATION_METHODS,
  YEARS_INSURED
} from 'woop-shared/origination/enums';
import { APPLICANT_FIELDS as FIELDS, YES_NO_OPTIONS } from '../../constants/origination';
import TextField from '../TextField';
import LinkText from '../LinkText';
import styles from '../../pages/CreateOrigination/CreateOrigination.css';
import Prompt from '../Prompt';
import Select from '../Select';
import PhoneField from '../PhoneField';
import { createSelectOption, createMultiSelectValue, createOptionsFromEnum } from '../Select/utils';
import NumberField from '../NumberField';
import DateField from '../DateField';
import SSNField from '../SSNField';
import DecimalField from '../DecimalField';
import RadioGroup from '../RadioGroup';
import Industry from '../Industry';
import Role from '../Role';
import { ORIGINATION_SECTIONS } from '../../constants/table-of-contents';
import { VALIDATION_MESSAGES } from '../../pages/CreateOrigination/constants';

const APPLICANT_PREFIX = 'APP';

const Applicants = ({
  applicants,
  isValid,
  addApplicant,
  deleteApplicant,
  updateApplicant,
  addressTags,
  origination
}) => {
  const [nextId, setNextId] = useState(applicants.length + 1);
  const coverageTypes = origination[ORIGINATION_FIELDS.COVERAGE_TYPES];

  const createApplicant = (defaults = {}) => {
    const applicant = {
      [FIELDS.TAG]: APPLICANT_PREFIX + nextId,
      [FIELDS.RELATIONSHIP]: DEFAULT_RELATIONSHIP,
      ...defaults
    };
    // Apply coverage type defaults
    if (coverageTypes?.includes(COVERAGE_TYPES.AUTO)) {
      applicant[FIELDS.APPLICANT_TYPE] = [APPLICANT_TYPES.DRIVER];
    }
    setNextId(nextId + 1);
    return applicant;
  };

  const handleDelete = applicant => deleteApplicant(applicant[FIELDS.TAG]);

  const handleAdd = () => addApplicant(createApplicant());

  const handleChangeEvent = applicant => e => {
    const { name, value } = e.target;
    const id = applicant[FIELDS.TAG];
    updateApplicant(id, { [name]: value });
  };

  const handleChange = applicant => (name, value) => {
    const id = applicant[FIELDS.TAG];
    updateApplicant(id, { [name]: value });
  };

  useEffect(() => {
    // Create first applicant on render
    if (applicants.length < 1)
      addApplicant(createApplicant({ [FIELDS.APPLICANT_TYPE]: [APPLICANT_TYPES.APPLICANT] }));
  }, []);

  useEffect(() => {
    // Apply coverage type defaults
    const isAuto = coverageTypes?.includes(COVERAGE_TYPES.AUTO);
    applicants.forEach(a => {
      const applicantTypes = a[FIELDS.APPLICANT_TYPE] || [];
      const filteredTypes = applicantTypes.filter(type => type !== APPLICANT_TYPES.DRIVER);
      const id = a[FIELDS.TAG];
      // If auto policy, safely append driver tag, else remove it
      const updatedTypes = isAuto ? [...filteredTypes, APPLICANT_TYPES.DRIVER] : filteredTypes;
      updateApplicant(id, { [FIELDS.APPLICANT_TYPE]: updatedTypes });
    });
  }, [coverageTypes]);

  return (
    <div id={ORIGINATION_SECTIONS.APPLICANTS}>
      <h3 className={styles.heading}>Applicants</h3>
      {applicants.map((applicant, index) => {
        const isDriver = applicant[FIELDS.APPLICANT_TYPE]?.includes(DRIVER_TYPE);
        const isPrimary = applicant[FIELDS.APPLICANT_TYPE]?.includes(APPLICANT_TYPES.APPLICANT);
        const showValidation = isPrimary && !isValid;
        return (
          <div key={applicant[FIELDS.TAG]} className={styles.fieldWrapper}>
            <div className={styles.labelWrapper}>
              <LinkText onClick={() => handleDelete(applicant)}>Delete</LinkText>
              <div className={styles.label}>
                {applicant[FIELDS.FIRST_NAME]} ({applicant[FIELDS.TAG]})
              </div>
            </div>
            <Select
              prompt={'Applicant type*'}
              name={FIELDS.APPLICANT_TYPE}
              options={createOptionsFromEnum(APPLICANT_TYPES)}
              onChange={handleChange(applicant)}
              isMulti={true}
              value={createMultiSelectValue(applicant[FIELDS.APPLICANT_TYPE])}
              id={`applicant-type-${index}`}
              inputId={`applicant-type-select-${index}`}
            />
            <Select
              prompt={'Relationship to insured'}
              name={FIELDS.RELATIONSHIP}
              options={createOptionsFromEnum(RELATIONSHIPS)}
              onChange={handleChange(applicant)}
              value={createSelectOption(applicant[FIELDS.RELATIONSHIP])}
              id={`relationship-to-main-applicant-${index}`}
              inputId={`relationship-to-main-applicant-select-${index}`}
            />
            <Select
              prompt={'Current Address'}
              name={FIELDS.ADDRESS_TAG}
              options={addressTags}
              onChange={handleChange(applicant)}
              value={addressTags.find(a => a.value === applicant[FIELDS.ADDRESS_TAG])}
              id={`current-address-${index}`}
              inputId={`current-address-select-${index}`}
            />
            <NumberField
              name={FIELDS.MONTHS_AT_ADDRESS}
              label={'Months Lived at Current Address'}
              onChange={handleChange(applicant)}
              value={applicant[FIELDS.MONTHS_AT_ADDRESS]}
            />
            <Select
              prompt={'Previous Address'}
              name={FIELDS.PREVIOUS_ADDRESS_TAG}
              options={addressTags}
              onChange={handleChange(applicant)}
              value={addressTags.find(a => a.value === applicant[FIELDS.PREVIOUS_ADDRESS_TAG])}
              id={`previous-address-${index}`}
              inputId={`previous-address-select-${index}`}
            />
            <TextField
              name={FIELDS.FIRST_NAME}
              label={`First Name${isPrimary ? '*' : ''}`}
              onChange={handleChangeEvent(applicant)}
              value={applicant[FIELDS.FIRST_NAME]}
              showValidation={showValidation}
              isValid={!!applicant[FIELDS.FIRST_NAME]}
              validationText={VALIDATION_MESSAGES.GENERAL}
              id={`first-name-${index}`}
            />
            <TextField
              name={FIELDS.LAST_NAME}
              label={`Last Name${isPrimary ? '*' : ''}`}
              onChange={handleChangeEvent(applicant)}
              value={applicant[FIELDS.LAST_NAME]}
              showValidation={showValidation}
              isValid={!!applicant[FIELDS.LAST_NAME]}
              validationText={VALIDATION_MESSAGES.GENERAL}
              id={`last-name-${index}`}
            />
            <TextField
              name={FIELDS.EMAIL}
              label={`Email`}
              onChange={handleChangeEvent(applicant)}
              value={applicant[FIELDS.EMAIL]}
              showValidation={showValidation}
              isValid={!!applicant[FIELDS.EMAIL] || !!applicant[FIELDS.PHONE]}
              validationText={VALIDATION_MESSAGES.PHONE_EMAIL}
              id={`email-${index}`}
            />
            <PhoneField
              name={FIELDS.PHONE}
              label={'Phone Number'}
              onChange={handleChange(applicant)}
              value={applicant[FIELDS.PHONE]}
              showValidation={showValidation}
              isValid={!!applicant[FIELDS.EMAIL] || !!applicant[FIELDS.PHONE]}
              validationText={VALIDATION_MESSAGES.PHONE_EMAIL}
              id={`phone-${index}`}
            />
            <Select
              name={FIELDS.PHONE_TYPE}
              placeholder={'Phone Type'}
              options={createOptionsFromEnum(PHONE_TYPES)}
              onChange={handleChange(applicant)}
              value={createSelectOption(applicant[FIELDS.PHONE_TYPE])}
              id={`phone-type-${index}`}
              inputId={`phone-type-select-${index}`}
            />
            <Select
              name={FIELDS.PREFERRED_COMMUNICATION_METHOD}
              placeholder={'Preferred Communication Method'}
              options={createOptionsFromEnum(PREFERRED_COMMUNICATION_METHODS)}
              onChange={handleChange(applicant)}
              value={createSelectOption(applicant[FIELDS.PREFERRED_COMMUNICATION_METHOD])}
              id={`communication-method-${index}`}
              inputId={`communication-method-select-${index}`}
            />
            <SSNField
              name={FIELDS.SSN}
              onChange={handleChange(applicant)}
              value={applicant[FIELDS.SSN]}
              id={`ssn-${index}`}
            />
            <DateField
              name={FIELDS.DOB}
              prompt={'Date of Birth'}
              onChange={handleChangeEvent(applicant)}
              value={applicant[FIELDS.DOB]}
              id={`dob-${index}`}
            />
            <Select
              prompt={'Gender'}
              name={FIELDS.GENDER}
              options={createOptionsFromEnum(GENDER)}
              onChange={handleChange(applicant)}
              value={createSelectOption(applicant[FIELDS.GENDER])}
              isValid={true}
              id={`gender-${index}`}
              inputId={`gender-select-${index}`}
            />
            <Select
              prompt={'Marital Status'}
              name={FIELDS.MARITAL_STATUS}
              options={createOptionsFromEnum(MARITAL_STATUSES)}
              onChange={handleChange(applicant)}
              value={createSelectOption(applicant[FIELDS.MARITAL_STATUS])}
              isValid={true}
              id={`marital-status-${index}`}
              inputId={`marital-status-select-${index}`}
            />
            <Select
              prompt={'Education'}
              name={FIELDS.EDUCATION}
              options={createOptionsFromEnum(EDUCATION)}
              onChange={handleChange(applicant)}
              value={createSelectOption(applicant[FIELDS.EDUCATION])}
              isValid={true}
              id={`education-${index}`}
              inputId={`education-select-${index}`}
            />
            <Industry
              value={applicant[FIELDS.INDUSTRY]}
              handleChange={handleChange(applicant)}
              id={`industry-${index}`}
            />
            <Role
              industry={applicant[FIELDS.INDUSTRY]}
              value={applicant[FIELDS.ROLE]}
              handleChange={handleChange(applicant)}
            />
            {isDriver && (
              <>
                <br />
                <Prompt>Driver Information 🚗</Prompt>
                <Select
                  placeholder={"Driver's License State"}
                  name={FIELDS.DRIVERS_LICENSE_STATE}
                  options={US_STATES}
                  onChange={handleChange(applicant)}
                  value={createSelectOption(applicant[FIELDS.DRIVERS_LICENSE_STATE])}
                />
                <TextField
                  name={FIELDS.DRIVERS_LICENSE_NUMBER}
                  label={"Driver's License Number"}
                  onChange={handleChangeEvent(applicant)}
                  value={applicant[FIELDS.DRIVERS_LICENSE_NUMBER]}
                />
                <Select
                  placeholder={"Driver's License Status"}
                  name={FIELDS.LICENSE_STATUS}
                  options={createOptionsFromEnum(LICENSE_STATUS_OPTIONS)}
                  onChange={handleChange(applicant)}
                  value={createSelectOption(applicant[FIELDS.LICENSE_STATUS])}
                />
                <DateField
                  name={FIELDS.DRIVERS_LICENSE_DATE}
                  prompt={'Date First Licensed'}
                  onChange={handleChangeEvent(applicant)}
                  value={applicant[FIELDS.DRIVERS_LICENSE_DATE]}
                />
                <RadioGroup
                  name={FIELDS.LICENSE_SUSPENDED_WITHIN_MIN_TIME}
                  prompt={'License been suspended or revoked in the last five years'}
                  value={applicant[FIELDS.LICENSE_SUSPENDED_WITHIN_MIN_TIME]}
                  onChange={handleChange(applicant)}
                  options={YES_NO_OPTIONS}
                />
                <RadioGroup
                  name={FIELDS.DRIVING_MONITOR_OK}
                  prompt={'Applicant would use a driving monitor'}
                  value={applicant[FIELDS.DRIVING_MONITOR_OK]}
                  onChange={handleChange(applicant)}
                  options={YES_NO_OPTIONS}
                />
                <RadioGroup
                  name={FIELDS.DEFENSIVE_DRIVING_COURSE}
                  prompt={'Applicant took a defensive driving course'}
                  value={applicant[FIELDS.DEFENSIVE_DRIVING_COURSE]}
                  onChange={handleChange(applicant)}
                  options={YES_NO_OPTIONS}
                />
                {applicant[FIELDS.DEFENSIVE_DRIVING_COURSE] && (
                  <>
                    <DateField
                      name={FIELDS.DRIVING_COURSE_DATE}
                      prompt={'Driving Course Date'}
                      value={applicant[FIELDS.DRIVING_COURSE_DATE]}
                      onChange={handleChangeEvent(applicant)}
                    />
                  </>
                )}
                <RadioGroup
                  name={FIELDS.MILITARY}
                  prompt={'Applicant is or was in the military'}
                  value={applicant[FIELDS.MILITARY]}
                  onChange={handleChange(applicant)}
                  options={YES_NO_OPTIONS}
                />
                <RadioGroup
                  name={FIELDS.STUDENT}
                  prompt={'Applicant is a student'}
                  value={applicant[FIELDS.STUDENT]}
                  onChange={handleChange(applicant)}
                  options={YES_NO_OPTIONS}
                />
                {applicant[FIELDS.STUDENT] && (
                  <DecimalField
                    name={FIELDS.GPA}
                    label={'GPA'}
                    value={applicant[FIELDS.GPA]}
                    onChange={handleChange(applicant)}
                  />
                )}
                <Select
                  prompt={'Years Continuously Insured as a Driver'}
                  name={FIELDS.YEARS_CONTINUOUSLY_INSURED_AUTO}
                  options={createOptionsFromEnum(YEARS_INSURED)}
                  onChange={handleChange(applicant)}
                  value={createSelectOption(applicant[FIELDS.YEARS_CONTINUOUSLY_INSURED_AUTO])}
                  isValid={true}
                />
                {isPrimary && (
                  <Select
                    prompt={'Residence Type'}
                    name={FIELDS.RESIDENCE_TYPE}
                    options={createOptionsFromEnum(RESIDENCE_TYPES)}
                    onChange={handleChange(applicant)}
                    value={createSelectOption(applicant[FIELDS.RESIDENCE_TYPE])}
                    isValid={true}
                  />
                )}
              </>
            )}
            <Select
              prompt={'Years Continuously Home/Condo/Renters Insured'}
              name={FIELDS.YEARS_CONTINUOUSLY_INSURED}
              options={createOptionsFromEnum(YEARS_INSURED)}
              onChange={handleChange(applicant)}
              value={createSelectOption(applicant[FIELDS.YEARS_CONTINUOUSLY_INSURED])}
              isValid={true}
            />
          </div>
        );
      })}
      <LinkText onClick={handleAdd}>Add Applicant</LinkText>
    </div>
  );
};

Applicants.propTypes = {
  isValid: PropTypes.bool,
  addApplicant: PropTypes.func,
  applicants: PropTypes.array,
  updateApplicant: PropTypes.func,
  deleteApplicant: PropTypes.func,
  addressTags: PropTypes.array,
  origination: PropTypes.object
};

export default Applicants;

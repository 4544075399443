import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from '../../css/SearchPage.css';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import Card from '../../components/Card';
import ErrorText from '../../components/ErrorText';
import { ROUTES, addIdToRoute } from '../../router/routes';
import { RECENT_ORIGINATIONS_KEY } from '../../constants/local-storage';
import { getRecentCollection } from '../../utils/local-storage';
import LinkText from '../../components/LinkText';
import CardLabel from '../../components/CardLabel';

const OriginationSearch = ({ getOrigination, error, loading, clearOrigination }) => {
  const [originationId, setOriginationId] = useState();
  const history = useHistory();
  const isValid = () => !!originationId;

  const handleSearch = () => {
    getOrigination(originationId);
  };

  const createOrigination = () => {
    clearOrigination();
    history.push(ROUTES.ORIGINATIONS_CREATE);
  };

  const handleEnter = e => {
    if (isValid() && e.key === 'Enter') handleSearch();
  };

  const recentOriginations = getRecentCollection(RECENT_ORIGINATIONS_KEY);

  return (
    <section onKeyUp={e => handleEnter(e)} className={styles.wrapper}>
      <Card>
        <CardLabel>Originations</CardLabel>
        <TextField
          name="oid"
          placeholder="5e18bb9f8f8b3e93a8157a84"
          label="Origination ID"
          value={originationId}
          onChange={e => setOriginationId(e.target.value)}
        />
        {error.message && <ErrorText>{error.message}</ErrorText>}
        <div className={styles.buttonWrapper}>
          <Button onClick={handleSearch} disabled={!isValid()} loading={loading}>
            Search
          </Button>
        </div>
      </Card>
      <Card>
        <div>Recent Originations</div>
        {recentOriginations?.map(o => (
          <div key={o.id} className={styles.recentList}>
            <LinkText
              href={addIdToRoute(ROUTES.ORIGINATIONS_VIEW, o.id)}
              onClick={() => getOrigination(o.id)}
            >
              {o.lastName} - {o.coverageTypes} ({o.id})
            </LinkText>
          </div>
        ))}
      </Card>
      <Button onClick={createOrigination} className={styles.create} disabled={isValid()}>
        Create Origination
      </Button>
    </section>
  );
};

OriginationSearch.propTypes = {
  clearOrigination: PropTypes.func,
  error: PropTypes.object,
  getOrigination: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default OriginationSearch;

import { GET_TRANSUNION_SUCCESS } from '../actions/transunion';

function transunion(state = {}, action) {
  switch (action.type) {
    case GET_TRANSUNION_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export default transunion;

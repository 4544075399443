import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RSelect from 'react-select';
import Prompt from '../Prompt';
import { formatOriginators } from './utils';

const OriginatorSelect = ({
  onChange,
  value,
  name,
  originators = [],
  getOriginators,
  isValid,
  showValidation
}) => {
  const [wasBlurred, setWasBlurred] = useState(false);

  useEffect(() => {
    if (originators.length === 0) getOriginators();
  }, []);

  const originatorOptions = formatOriginators(originators);
  const selected = originatorOptions.find(p => p.value === value) || null;
  return (
    <div>
      <Prompt invalid={(showValidation || wasBlurred) && !isValid}>Select Originator</Prompt>
      <RSelect
        name={name}
        options={originatorOptions}
        value={selected}
        onChange={o => onChange(name, o?.value)}
        onBlur={() => setWasBlurred(true)}
      />
    </div>
  );
};

export default OriginatorSelect;

OriginatorSelect.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  originators: PropTypes.array,
  getOriginators: PropTypes.func,
  showValidation: PropTypes.bool,
  isValid: PropTypes.bool
};

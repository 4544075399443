import { takeLatest, put } from 'redux-saga/effects';
import {
  GET_PARTNER,
  GET_PARTNERS,
  getPartnerSuccess,
  getPartnersSuccess
} from '../actions/partners';
import api from '../api/partners';
import { setError } from '../actions/error';

function* handleGetPartners() {
  try {
    const partners = yield api.getPartners();
    yield put(getPartnersSuccess(partners));
  } catch {
    yield put(setError({ message: 'Could not load partners.' }));
  }
}

export function* partnersWatchFinalizeQuoteset() {
  yield takeLatest(GET_PARTNERS, handleGetPartners);
}

function* handleGetPartner(action) {
  try {
    const id = action.payload;
    const partner = yield api.getPartner(id);
    yield put(getPartnerSuccess(partner));
  } catch {
    yield put(setError({ message: 'Could not load partner.' }));
  }
}

export function* watchGetPartner() {
  yield takeLatest(GET_PARTNER, handleGetPartner);
}

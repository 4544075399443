import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../../../../components/TextField';
import { ACH_FIELDS } from '../../constants';

const ACH = ({ data, update }) => {
  const handleChange = field => e => update({ ...data, [field]: e.target.value });

  return (
    <>
      {[ACH_FIELDS.BANK_NAME, ACH_FIELDS.ACCOUNT_NUMBER, ACH_FIELDS.ROUTING_NUMBER].map(
        ({ key, label, validator, maxLength }) => {
          return (
            <TextField
              key={key}
              name={key}
              label={label}
              value={data[key]}
              maxLength={maxLength}
              onChange={handleChange(key)}
              isValid={validator(data[key])}
            />
          );
        }
      )}
    </>
  );
};

ACH.propTypes = {
  data: PropTypes.object,
  update: PropTypes.func
};

export default ACH;

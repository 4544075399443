import { takeLatest, put } from 'redux-saga/effects';
import { getUserPulls } from '../api/users';
import { error } from '../utils/logger';
import { setLoading } from '../actions/loading';
import { setError } from '../actions/error';
import { getPullsSuccess, GET_PULLS } from '../actions/pulls';

function* handleGetPulls(action) {
  const id = action.payload;
  yield put(setLoading(true));
  try {
    const data = yield getUserPulls(id);
    yield put(getPullsSuccess(data));
  } catch (e) {
    yield put(setError({ message: `Couldn't find that user! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchGetPulls() {
  yield takeLatest(GET_PULLS, handleGetPulls);
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatCarriers } from './utils';
import { createSelectOption } from '../Select/utils';
import Select from '../Select';

const CarrierSelect = ({
  onChange,
  prompt = 'Select Carrier',
  value,
  name,
  carriers = [],
  getCarriers,
  createable,
  isValid,
  showValidation,
  isMulti,
  placeholder
}) => {
  useEffect(() => {
    if (carriers.length === 0) getCarriers();
  }, []);

  const carrierOptions = formatCarriers(carriers);

  const getVal = () => {
    if (isMulti) return carrierOptions.filter(c => value?.includes(c.value));
    return carrierOptions.find(c => c.value === value) || createSelectOption(value);
  };

  const onChangeWrapper = (name, val) => {
    if (isMulti) {
      onChange(
        name,
        carriers.filter(carrier => val?.includes(carrier.code))
      );
    } else {
      onChange(name, carriers.find(carrier => carrier.code === val) || { code: val });
    }
  };
  return (
    <div>
      <Select
        prompt={prompt}
        name={name}
        options={carrierOptions}
        showValidation={showValidation}
        placeholder={placeholder}
        isValid={isValid}
        createable={createable}
        isMulti={isMulti}
        value={getVal()}
        onChange={onChangeWrapper}
      />
    </div>
  );
};

export default CarrierSelect;

CarrierSelect.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  carriers: PropTypes.array,
  getCarriers: PropTypes.func,
  createable: PropTypes.bool,
  showValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  isMulti: PropTypes.bool,
  prompt: PropTypes.string,
  placeholder: PropTypes.string
};

import {
  patchPolicyCoverage,
  patchVehicleCoverage,
  postPolicyCoverage,
  postVehicleCoverage
} from '../../../../api/policy';

export const updateCoverage = ({ policy, coverage, vehicle, updates, isAuto }) => {
  const { post, patch } = vehicle
    ? {
        post: postVehicleCoverage,
        patch: patchVehicleCoverage
      }
    : {
        post: postPolicyCoverage,
        patch: patchPolicyCoverage
      };

  const params = {
    policyId: policy.policy_id,
    vehicleId: vehicle?.vehicle_id,
    coverageId: coverage?.vehicle_coverage_id,
    coverageSlug: updates?.name,
    updates
  };

  return coverage ||
    coverageAlreadyExists({ policy, vehicle, coverageSlug: params.coverageSlug, isAuto })
    ? patch(params)
    : post(params);
};

function coverageAlreadyExists({ policy, vehicle, coverageSlug, isAuto }) {
  if (vehicle) {
    return !!vehicle.coverages?.find(({ name }) => name === coverageSlug);
  }

  const items = isAuto ? policy?.vehicles : policy?.dwellings;
  return !!items?.find(({ coverages }) => coverages?.find(({ name }) => name === coverageSlug));
}

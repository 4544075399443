// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__card--2DG5J{padding:2rem;width:480px}.styles-module__modal--2bbmF{max-width:none;width:810px}.styles-module__header--2vH1q{display:flex;justify-content:space-between}.styles-module__btnWrapper--c5jv5{position:fixed;right:0;bottom:0;margin:2rem;display:flex;flex-direction:column;align-items:flex-end;grid-gap:10px;gap:10px}.styles-module__btnWrapper--c5jv5 a{font-weight:var(--fw-bold);text-transform:uppercase;font-size:var(--fs-xs)}.styles-module__saveButton--1RN-h{background:var(--color-blue-primary)}.styles-module__quotesButton--1dn7L,.styles-module__saveButton--1RN-h{grid-gap:.5rem;gap:.5rem;box-shadow:0 4px 4px rgba(0,0,0,.25);border-radius:8px;font-weight:var(--fw-bold);text-transform:uppercase}.styles-module__quotesButton--1dn7L{display:flex;align-items:center;background:var(--color-green-primary)}.styles-module__loadingModalContent--3vKGE{display:flex;flex-direction:column;justify-content:center;align-items:center;grid-gap:20px;gap:20px;text-align:center}.styles-module__loadingModalContent--3vKGE .styles-module__loader--1Lth_{color:var(--color-blue-dark);height:3.5rem;width:3.5rem}.styles-module__loadingModalContent--3vKGE .styles-module__loader--1Lth_:after,.styles-module__loadingModalContent--3vKGE .styles-module__loader--1Lth_:before{display:none}.styles-module__loadingModalContent--3vKGE p{margin-top:5px;color:var(--color-grey-dark)}.styles-module__loadingModalContent--3vKGE *{margin:0}", ""]);
// Exports
exports.locals = {
	"card": "styles-module__card--2DG5J",
	"modal": "styles-module__modal--2bbmF",
	"header": "styles-module__header--2vH1q",
	"btnWrapper": "styles-module__btnWrapper--c5jv5",
	"saveButton": "styles-module__saveButton--1RN-h",
	"quotesButton": "styles-module__quotesButton--1dn7L",
	"loadingModalContent": "styles-module__loadingModalContent--3vKGE",
	"loader": "styles-module__loader--1Lth_"
};
module.exports = exports;

import { hasVal } from 'woop-shared/utils';

/**
 * Take an array and turn it into an array of obj/val options.
 *
 * Note: this is for the options property of a Select field.
 * A Select field with no valid options should return an empty array.
 *
 * @param {Array} options An array of options.
 * @returns {Array} An array of {value, label} options.
 */
export function createSelectOptions(options) {
  if (!hasVal(options)) return [];
  return options.map(option => createSelectOption(option));
}

/**
 * Convert object to array of its values and make select options
 *
 * @param {object} obj
 * @returns {Array}
 */
export function createOptionsFromEnum(obj) {
  const values = Object.values(obj);
  return createSelectOptions(values);
}

/**
 * Take a string option and turn it into a value/label obj option.
 *
 * Note: React-Select doesn't handle undefined. Return null for cleared selects.
 *
 * @param {string} option
 * @returns {object} A value/label object, or null if undefined.
 */
export function createSelectOption(option) {
  if (!hasVal(option)) return null;
  return { value: option, label: option };
}

/**
 * Take an array and turn it into an array of obj/val options.
 *
 * Note: this is for the value property of a Select field.
 * A Select field without a value should return null.
 *
 * @param {Array} selectedOptions An array of options.
 * @param {Array} allOptions An array of option objects.
 * @returns {Array} An array of {value, label} options.
 */
export function createMultiSelectValue(selectedOptions, allOptions) {
  if (!hasVal(selectedOptions)) return null;
  return selectedOptions.map(option => {
    return allOptions?.find(opt => opt.value === option) || createSelectOption(option);
  });
}

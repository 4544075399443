import { HEATING_SOURCES } from 'woop-shared/origination/enums';
import {
  APPLICANT_FIELDS as OG_APPLICANT_FIELDS,
  PROPERTY_FIELDS as OG_PROPERTY_FIELDS,
  INCIDENT_FIELDS as OG_INCIDENT_FIELDS,
  CLAIM_FIELDS as OG_CLAIM_FIELDS,
  AUTO_FIELDS as OG_AUTO_FIELDS
} from 'woop-shared/origination/fields';

export const WITH_CANOPY_POLICY = {
  CANOPY_POLICY: 'canopyPolicy'
};

export const UNDERGROUND_FUEL_TANK_HEATING_SOURCES = [
  HEATING_SOURCES.OIL,
  HEATING_SOURCES.OIL_FORCED_AIR,
  HEATING_SOURCES.OIL_HOT_WATER
];

// These fields are not sent to the backend.
const APPLICANT_FIELDS_EXTRA = {
  EXCLUDED: 'excluded',
  ASSOCIATED_VEHICLE: 'associatedVehicle'
};

export const APPLICANT_FIELDS = {
  ...OG_APPLICANT_FIELDS,
  ...APPLICANT_FIELDS_EXTRA
};

// Extra fields not to be sent to backend
const PROPERTY_FIELDS_EXTRA = {
  DISPLAY_ADDRESS: 'displayAddress',
  DISPLAY_UNIT_NUMBER: 'displayUnitNumber'
};

export const PROPERTY_FIELDS = {
  ...OG_PROPERTY_FIELDS,
  ...PROPERTY_FIELDS_EXTRA,
  ...WITH_CANOPY_POLICY
};

export const AUTO_FIELDS = {
  ...OG_AUTO_FIELDS,
  ...WITH_CANOPY_POLICY
};

// These fields are not sent to the backend.
const INCIDENT_FIELDS_EXTRA = {
  DRIVER_NAME: 'driverName'
};

export const INCIDENT_FIELDS = {
  ...OG_INCIDENT_FIELDS,
  ...INCIDENT_FIELDS_EXTRA
};

const CLAIM_FIELDS_EXTRA = {
  LABEL: 'label'
};

export const CLAIM_FIELDS = {
  ...OG_CLAIM_FIELDS,
  ...CLAIM_FIELDS_EXTRA
};

const YES_OPTION = {
  label: 'Yes',
  value: true
};

const NO_OPTION = {
  label: 'No',
  value: false
};

export const YES_NO_OPTIONS = [YES_OPTION, NO_OPTION];

export const QUOTE_TYPES = {
  APPLES: 'APPLES',
  RECOMMENDATION: 'RECOMMENDATION',
  OTHER: 'OTHER'
};

export const QUOTE_TYPE_LABELS = {
  [QUOTE_TYPES.APPLES]: 'Apples to Apples 🍏',
  [QUOTE_TYPES.RECOMMENDATION]: 'Woop Recommendation 🛡'
};

import { GET_ORIGINATORS_SUCCESS } from '../actions/originators';

function originators(state = [], action) {
  switch (action.type) {
    case GET_ORIGINATORS_SUCCESS: {
      const sorted = action.payload.sort((a, b) => (a?.name > b?.name ? 1 : -1));
      return sorted;
    }
    default:
      return state;
  }
}

export default originators;

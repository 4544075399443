import { createAction } from 'redux-actions';

export const ADD_INCIDENT = 'ADD_INCIDENT';
export const addIncident = createAction(ADD_INCIDENT);

export const DELETE_INCIDENT = 'DELETE_INCIDENT';
export const deleteIncident = createAction(DELETE_INCIDENT);

export const UPDATE_INCIDENT = 'UPDATE_INCIDENT';
export const updateIncident = createAction(UPDATE_INCIDENT);

import React from 'react';
import PropTypes from 'prop-types';
import { HOME_BUSINESS_TYPES } from 'woop-shared/origination/enums';
import Select from '../../Select';
import { createMultiSelectValue, createOptionsFromEnum } from '../../Select/utils';
import {
  DOGS_WITH_NO,
  ANIMALS_WITH_NO,
  PROPERTY_FIELDS as FIELDS
} from '../../../constants/origination';
import styles from '../../../pages/CreateOrigination/CreateOrigination.css';
import NumberField from '../../NumberField';
import { SUB_SECTIONS } from '../../../constants/table-of-contents';
import Card from '../../Card';

const sectionId = SUB_SECTIONS.USAGE;

const PropertyUsage = ({ propertyInfo = {}, updateOrigination }) => {
  return (
    <Card id={sectionId}>
      <h3 className={styles.heading}>Property Usage</h3>
      <div className={styles.fieldWrapper}>
        <Select
          prompt="Home Businesses"
          isMulti={true}
          options={createOptionsFromEnum(HOME_BUSINESS_TYPES)}
          name={FIELDS.HOME_BUSINESSES}
          onChange={updateOrigination}
          isValid={true}
          value={createMultiSelectValue(propertyInfo[FIELDS.HOME_BUSINESSES])}
        />
        <Select
          prompt="Has one of the following animals"
          options={ANIMALS_WITH_NO}
          name={FIELDS.ANIMALS}
          value={createMultiSelectValue(propertyInfo[FIELDS.ANIMALS], ANIMALS_WITH_NO)}
          onChange={updateOrigination}
          isValid={true}
          isMulti
        />
        <Select
          prompt="Has one of the following dog breeds"
          options={DOGS_WITH_NO}
          name={FIELDS.BREEDS_OF_DOGS}
          onChange={updateOrigination}
          isValid={true}
          isMulti
          value={createMultiSelectValue(propertyInfo[FIELDS.BREEDS_OF_DOGS], DOGS_WITH_NO)}
        />
        <NumberField
          label="Number of Residents"
          name={FIELDS.NUMBER_OF_RESIDENTS}
          value={propertyInfo[FIELDS.NUMBER_OF_RESIDENTS]}
          onChange={updateOrigination}
        />
        <NumberField
          label="Number of Children"
          name={FIELDS.NUMBER_OF_CHILDREN}
          value={propertyInfo[FIELDS.NUMBER_OF_CHILDREN]}
          onChange={updateOrigination}
        />
        <NumberField
          label="Number of Non-Driving Children"
          name={FIELDS.NUMBER_OF_NON_DRIVING_CHILDREN}
          value={propertyInfo[FIELDS.NUMBER_OF_NON_DRIVING_CHILDREN]}
          onChange={updateOrigination}
        />
      </div>
    </Card>
  );
};

PropertyUsage.propTypes = {
  propertyInfo: PropTypes.object,
  updateOrigination: PropTypes.func
};

export default PropertyUsage;

import { createAction } from 'redux-actions';

export const ADD_QUOTE = 'ADD_QUOTE';
export const addQuote = createAction(ADD_QUOTE);

export const DELETE_QUOTE = 'DELETE_QUOTE';
export const deleteQuote = createAction(DELETE_QUOTE);

export const DELETE_QUOTE_SUCCESS = 'DELETE_QUOTE_SUCCESS';
export const deleteQuoteSuccess = createAction(DELETE_QUOTE_SUCCESS);

export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const updateQuote = createAction(UPDATE_QUOTE);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__section--3rvo-{display:flex;flex-direction:column;grid-gap:.5rem;gap:.5rem}.styles-module__section--3rvo- li,.styles-module__section--3rvo- ul{margin:0;padding:0}.styles-module__section--3rvo- ul{display:flex;flex-direction:column;grid-gap:.5rem;gap:.5rem;margin-left:1rem}.styles-module__heading--1IkLB{font-weight:var(--fw-semibold)}", ""]);
// Exports
exports.locals = {
	"section": "styles-module__section--3rvo-",
	"heading": "styles-module__heading--1IkLB"
};
module.exports = exports;

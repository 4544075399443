import { takeLatest, put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { FINALIZATION_TYPES } from 'woop-shared/origination/enums';
import { GENERAL_FIELDS } from 'woop-shared/db/models';
import {
  GET_ORIGINATION,
  getOriginationSuccess,
  SAVE_ORIGINATION,
  postOriginationSuccess,
  FINALIZE_ORIGINATION,
  finalizeOriginationSuccess,
  CONVERT_TO_QUOTESET,
  VALIDATE_ORIGINATION
} from '../actions/originations';
import api from '../api/originations';
import { error } from '../utils/logger';
import { formatOriginationData, getPrimaryApplicant } from '../utils/origination';
import { ROUTES, addIdToRoute } from '../router/routes';
import { setLoading } from '../actions/loading';
import { setError } from '../actions/error';
import { addToRecentOriginations } from '../utils/local-storage';
import { APPLICANT_FIELDS } from '../constants/origination';
import { handleSwaggerNull } from '../utils/api';
import { postQuoteset } from '../api/quoteset';
import { postJourney } from '../api/journeys';
import { isValid } from '../pages/CreateOrigination/utils';

function saveOriginationToRecents(id, originationData) {
  const applicant = getPrimaryApplicant(originationData?.[ORIGINATION_FIELDS.APPLICANTS]);
  const coverageTypes = originationData?.[ORIGINATION_FIELDS.COVERAGE_TYPES];
  addToRecentOriginations(id, applicant?.[APPLICANT_FIELDS.LAST_NAME], coverageTypes);
}

function* handleGetOrigination(action) {
  const { id, preventRedirect } = action.payload;
  yield put(setLoading(true));
  try {
    const data = yield api.getOrigination(id);
    saveOriginationToRecents(id, data.originationData);
    yield put(getOriginationSuccess(data));
    if (!preventRedirect) yield put(push(addIdToRoute(ROUTES.ORIGINATIONS_VIEW, id)));
  } catch (e) {
    yield put(setError({ message: `Couldn't find that origination! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchGetOrigination() {
  yield takeLatest(GET_ORIGINATION, handleGetOrigination);
}

function* handleValidateOrigination(action) {
  const { origination, applicants, addresses } = yield select(state => state);

  const { valid, message } = isValid({ origination, addresses, applicants });

  if (!valid) {
    yield put(setError({ message }));
  } else {
    yield put(setError());
    action?.payload?.callback();
  }
}

export function* watchValidateOrigination() {
  yield takeLatest(VALIDATE_ORIGINATION, handleValidateOrigination);
}

function* handleSaveOrigination(action) {
  yield put(setLoading(true));
  yield put(setError());

  const { origination, applicants, addresses, vehicles, incidents, propertyIncidents, pools } =
    yield select(state => state);

  const account = {
    [ORIGINATION_FIELDS.ACCOUNT_ID]: origination[ORIGINATION_FIELDS.ACCOUNT_ID],
    [ORIGINATION_FIELDS.EXTERN_ORIGINATOR_ID]: handleSwaggerNull(
      origination[ORIGINATION_FIELDS.EXTERN_ORIGINATOR_ID]
    )
  };
  const originationData = formatOriginationData({
    origination,
    applicants,
    addresses,
    vehicles,
    incidents,
    propertyIncidents,
    pools
  });

  try {
    const oid = origination[ORIGINATION_FIELDS.ID];
    if (oid) {
      // Edit
      yield api.patchOrigination(oid, originationData, account);
      saveOriginationToRecents(oid, originationData);
    } else {
      // Create
      const data = yield api.postOrigination(originationData, account);
      yield put(postOriginationSuccess(data));
      const { originationId } = data;
      yield put(push(addIdToRoute(ROUTES.ORIGINATIONS_VIEW, originationId)));
      saveOriginationToRecents(originationId, originationData);
    }
  } catch (e) {
    yield put(setError(e));
    error(e, action);
  }

  yield put(setLoading(false));
}

export function* watchSaveOrigination() {
  yield takeLatest(SAVE_ORIGINATION, handleSaveOrigination);
}

function* handleFinalizeOrigination(action) {
  yield put(setLoading(true));
  yield put(setError());
  try {
    // Save.
    yield call(handleSaveOrigination);
    yield put(setLoading(true)); // Saving turns off the loading flag, so reset that.

    // Then finalize.
    const id = yield select(state => state?.origination?.[ORIGINATION_FIELDS.ID]);
    const responseData = yield api.finalizeOrigination(id, action.payload);

    yield put(finalizeOriginationSuccess(responseData));
    action?.payload?.callback();
  } catch (e) {
    yield put(setError(e));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchFinalizeOrigination() {
  yield takeLatest(FINALIZE_ORIGINATION, handleFinalizeOrigination);
}

function* handleConvertToQuoteset(action) {
  yield put(setLoading(true));
  yield put(setError());
  try {
    // Save.
    yield call(handleSaveOrigination);
    yield put(setLoading(true)); // Saving turns off the loading flag, so reset that.

    // Then finalize.
    const originationId = yield select(state => state?.origination?.[ORIGINATION_FIELDS.ID]);

    // Finalize origination.
    yield api.finalizeOrigination(originationId, { type: FINALIZATION_TYPES.CONVERT_TO_QUOTE });

    // Create journey.
    const { journeyId, uiData } = yield postJourney(originationId);

    // Create quoteset.
    const response = yield postQuoteset({
      journeyId,
      originationId,
      uiData,
      notifyConsumer: false
    });

    action?.payload?.callback();
    if (response?.[GENERAL_FIELDS.ID]) {
      yield window.open(addIdToRoute(ROUTES.QUOTES_VIEW, response[GENERAL_FIELDS.ID]));
    }
  } catch (e) {
    yield put(setError(e));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchConvertToQuoteset() {
  yield takeLatest(CONVERT_TO_QUOTESET, handleConvertToQuoteset);
}

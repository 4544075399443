import { QUOTESET_FIELDS } from 'woop-shared/db/models';
import { SET_MORTGAGE_FIELD } from '../actions/mortgage';
import { SET_QUOTESET } from '../actions/quoteset';

function mortgage(state = {}, action) {
  switch (action.type) {
    case SET_MORTGAGE_FIELD: {
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    }
    case SET_QUOTESET: {
      return action.payload[QUOTESET_FIELDS.MORTGAGE] || {};
    }
    default:
      return state;
  }
}

export default mortgage;

import {
  PROPERTY_TYPES,
  VEHICLE_USES,
  INCIDENT_TYPES,
  CLAIMS_LOB,
  FOUNDATION_TYPES
} from 'woop-shared/origination/enums';
import { ADDRESS_FIELDS, AUTO_FIELDS, VEHICLE_FIELDS } from 'woop-shared/origination/fields';
import { APPLICANT_FIELDS, INCIDENT_FIELDS, CLAIM_FIELDS, PROPERTY_FIELDS } from '../../constants';

export const REQUIRED_FIELDS_DRIVER = [
  APPLICANT_FIELDS.FIRST_NAME,
  APPLICANT_FIELDS.LAST_NAME,
  APPLICANT_FIELDS.DOB,
  APPLICANT_FIELDS.MARITAL_STATUS,
  APPLICANT_FIELDS.GENDER,
  APPLICANT_FIELDS.RELATIONSHIP,
  APPLICANT_FIELDS.DRIVERS_LICENSE_STATE,
  APPLICANT_FIELDS.LICENSE_STATUS
];

export const REQUIRED_FIELDS_EXCLUDED_DRIVER = [
  APPLICANT_FIELDS.FIRST_NAME,
  APPLICANT_FIELDS.LAST_NAME,
  APPLICANT_FIELDS.DOB,
  APPLICANT_FIELDS.EXCLUDED_REASON,
  APPLICANT_FIELDS.RELATIONSHIP
];

export const REQUIRED_FIELDS_AUTO_COVERAGE = [
  AUTO_FIELDS.CURRENT_INSURER,
  AUTO_FIELDS.CURRENT_POLICY_EXPIRATION,
  AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_PERSON,
  AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_ACCIDENT
];

export const REQUIRED_FIELDS_VEHICLE = [
  VEHICLE_FIELDS.YEAR,
  VEHICLE_FIELDS.MAKE,
  VEHICLE_FIELDS.MODEL,
  VEHICLE_FIELDS.VIN,
  VEHICLE_FIELDS.ANNUAL_MILES,
  VEHICLE_FIELDS.VEHICLE_USE,
  VEHICLE_FIELDS.VEHICLE_OWNERSHIP_STATUS,
  VEHICLE_FIELDS.VEHICLE_PURCHASE_DATE,
  VEHICLE_FIELDS.DAYS_DRIVEN_PER_WEEK
];

export const REQUIRED_FIELDS_PROPERTY = [
  PROPERTY_FIELDS.ADDRESS_ID,
  PROPERTY_FIELDS.LINE_OF_BUSINESS,
  PROPERTY_FIELDS.PROPERTY_TYPE,
  PROPERTY_FIELDS.DWELLING_TYPE,
  PROPERTY_FIELDS.MOVE_IN_DATE,
  PROPERTY_FIELDS.PURCHASE_DATE,
  PROPERTY_FIELDS.NUMBER_OF_MORTGAGES,
  PROPERTY_FIELDS.MONTHS_OCCUPIED,
  PROPERTY_FIELDS.YEAR_BUILT,
  PROPERTY_FIELDS.SQUARE_FOOTAGE,
  PROPERTY_FIELDS.NUMBER_OF_STORIES,
  PROPERTY_FIELDS.FULL_BATHS,
  PROPERTY_FIELDS.PRIMARY_HEATING_SOURCE,
  PROPERTY_FIELDS.DANGEROUS_ANIMAL,
  PROPERTY_FIELDS.CONSTRUCTION_TYPE,
  PROPERTY_FIELDS.EXTERIOR_WALL_MATERIAL,
  PROPERTY_FIELDS.FOUNDATION,
  PROPERTY_FIELDS.ROOF_SHAPE,
  PROPERTY_FIELDS.ELECTRICAL_JUNCTION,
  PROPERTY_FIELDS.WITHIN_FEET_OF_FIRE_HYDRANT,
  PROPERTY_FIELDS.WITHIN_MILES_OF_FIRE_STATION,
  PROPERTY_FIELDS.PERCENT_FINISHED_BASEMENT
];

export const REQUIRED_FIELDS_ADDRESS = [
  ADDRESS_FIELDS.ADDRESS_TYPE,
  ADDRESS_FIELDS.STREET_NUMBER,
  ADDRESS_FIELDS.STREET_NAME,
  ADDRESS_FIELDS.MUNICIPALITY_LEVEL3,
  ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE,
  ADDRESS_FIELDS.POSTAL_CODE
];

export const REQUIRED_FIELDS_CLAIMS = [
  CLAIM_FIELDS.LINE_OF_BUSINESS,
  CLAIM_FIELDS.PROPERTY_ID,
  CLAIM_FIELDS.DATE,
  CLAIM_FIELDS.AMOUNT
];

export const REQUIRED_FIELDS_APPLICANT = [
  APPLICANT_FIELDS.FIRST_NAME,
  APPLICANT_FIELDS.LAST_NAME,
  APPLICANT_FIELDS.DOB,
  APPLICANT_FIELDS.GENDER,
  APPLICANT_FIELDS.MARITAL_STATUS,
  APPLICANT_FIELDS.PHONE,
  APPLICANT_FIELDS.EMAIL,
  APPLICANT_FIELDS.ADDRESS_ID,
  APPLICANT_FIELDS.RESIDENCE_TYPE
];

export const REQUIRED_FIELDS_CO_APPLICANT = [
  APPLICANT_FIELDS.APPLICANT_TYPE,
  APPLICANT_FIELDS.FIRST_NAME,
  APPLICANT_FIELDS.LAST_NAME,
  APPLICANT_FIELDS.DOB
];

export const REQUIRED_FIELDS_AUTO_INCIDENT = [
  INCIDENT_FIELDS.TYPE,
  INCIDENT_FIELDS.INCIDENT_DATE,
  INCIDENT_FIELDS.ACCIDENT_DESC
];

const APPLICANT_FIELD_VALIDATORS = {
  //
};

const VEHICLE_FIELD_VALIDATORS = {
  [VEHICLE_FIELDS.DAYS_DRIVEN_PER_WEEK]: vehicle =>
    vehicle[VEHICLE_FIELDS.VEHICLE_USE] === VEHICLE_USES.COMMUTE
      ? !!vehicle[VEHICLE_FIELDS.DAYS_DRIVEN_PER_WEEK]
      : true
};

const PROPERTY_FIELD_VALIDATORS = {
  [PROPERTY_FIELDS.MOVE_IN_DATE]: property =>
    property[PROPERTY_FIELDS.PURCHASE_DATE] ? true : !!property[PROPERTY_FIELDS.MOVE_IN_DATE],
  [PROPERTY_FIELDS.PURCHASE_DATE]: property =>
    property[PROPERTY_FIELDS.MOVE_IN_DATE] ? true : !!property[PROPERTY_FIELDS.PURCHASE_DATE],
  [PROPERTY_FIELDS.MONTHS_OCCUPIED]: property =>
    property[PROPERTY_FIELDS.PROPERTY_TYPE] === PROPERTY_TYPES.SECONDARY_RESIDENCE
      ? !!property[PROPERTY_FIELDS.MONTHS_OCCUPIED]
      : true,
  [PROPERTY_FIELDS.DANGEROUS_ANIMAL]: property =>
    property[PROPERTY_FIELDS.HAS_PET]
      ? property[PROPERTY_FIELDS.DANGEROUS_ANIMAL] !== undefined
      : true,
  [PROPERTY_FIELDS.ELECTRICAL_JUNCTION]: property =>
    Number(property[PROPERTY_FIELDS.YEAR_BUILT]) < 1965
      ? !!property[PROPERTY_FIELDS.ELECTRICAL_JUNCTION]
      : true,
  [PROPERTY_FIELDS.NUMBER_OF_MORTGAGES]: property =>
    property[PROPERTY_FIELDS.NUMBER_OF_MORTGAGES] !== null,
  [PROPERTY_FIELDS.PERCENT_FINISHED_BASEMENT]: property =>
    property[PROPERTY_FIELDS.FOUNDATION] === FOUNDATION_TYPES.BASEMENT
      ? parseInt(property[PROPERTY_FIELDS.PERCENT_FINISHED_BASEMENT]) >=0
      : true
};

const INCIDENT_FIELD_VALIDATORS = {
  [INCIDENT_FIELDS.INCIDENT_DATE]: incident =>
    incident[INCIDENT_FIELDS.TYPE] === INCIDENT_TYPES.ACCIDENT
      ? !!incident[INCIDENT_FIELDS.INCIDENT_DATE]
      : true,
  [INCIDENT_FIELDS.ACCIDENT_DESC]: incident =>
    incident[INCIDENT_FIELDS.TYPE] === INCIDENT_TYPES.ACCIDENT
      ? !!incident[INCIDENT_FIELDS.ACCIDENT_DESC]
      : true
};

const CLAIM_FIELD_VALIDATORS = {
  [CLAIM_FIELDS.PROPERTY_ID]: claim =>
    claim[CLAIM_FIELDS.LINE_OF_BUSINESS] === CLAIMS_LOB.PROPERTY
      ? !!claim[CLAIM_FIELDS.PROPERTY_ID]
      : true
};

export const FIELD_VALIDATORS = {
  ...APPLICANT_FIELD_VALIDATORS,
  ...VEHICLE_FIELD_VALIDATORS,
  ...PROPERTY_FIELD_VALIDATORS,
  ...INCIDENT_FIELD_VALIDATORS,
  ...CLAIM_FIELD_VALIDATORS,
  ...REQUIRED_FIELDS_ADDRESS
};

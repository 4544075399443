import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { BUNDLED_QUOTE_FIELDS, FOREIGN_KEYS, QUOTE_FIELDS } from 'woop-shared/db/models';
import { capitalize } from 'woop-shared/utils';
import { calcSavings, formatMoney } from 'woop-shared/quotes/utils';
import { DISCOUNT_TYPES } from 'woop-shared/quotes/enums';

import styles from './styles.css';
import Badge from '../../../../components/Badge';
import { formatPremium } from '../../../../utils/quoteset';
import { formatCoverages, getCarrierDetails } from './utils';
import { getQuoteId, getTagIcon } from '../../utils';

const QuoteSummary = ({ quote }) => {
  const { quotes, bundledQuoteIds, carriers } = useSelector(
    ({ quotes, bundledQuotes, carriers }) => ({
      quotes: Object.values(quotes),
      bundledQuoteIds: Object.values(bundledQuotes || {}).flatMap(
        bq => bq[BUNDLED_QUOTE_FIELDS.QUOTE_IDS]
      ),
      carriers
    })
  );

  const coverageType = capitalize(quote[QUOTE_FIELDS.COVERAGE_TYPE]);
  const { logoUrl, name } = getCarrierDetails(quote[FOREIGN_KEYS.CARRIER_CODE], carriers);
  const tag = getTagIcon(quote?.[QUOTE_FIELDS.COVERAGE_TAG]);
  const coverages = formatCoverages(quote);

  const currentPolicy =
    !quote[QUOTE_FIELDS.IS_CURRENT_POLICY] &&
    quotes?.find(
      q =>
        q[QUOTE_FIELDS.IS_CURRENT_POLICY] &&
        q[QUOTE_FIELDS.COVERAGE_TYPE] === quote[QUOTE_FIELDS.COVERAGE_TYPE]
    );
  const savings = currentPolicy ? calcSavings(quote, currentPolicy) : null;

  const hasTelematics = !!quote[QUOTE_FIELDS.DISCOUNTS]?.find(
    q => q.type === DISCOUNT_TYPES.TELEMATICS
  );

  const isBundled = bundledQuoteIds.includes(getQuoteId(quote));

  return (
    <div className={styles.wrapper}>
      <div className={styles.carrierInfo}>
        <div className={styles.carrierLogo}>
          <img src={logoUrl} />
        </div>
        <div>
          <div className={styles.carrierName}>
            {name || 'No carrier selected'} {coverageType && `- ${coverageType}`}{' '}
            {!quote[QUOTE_FIELDS.IS_CURRENT_POLICY] && tag}
            <div className={styles.badges}>
              {quote[QUOTE_FIELDS.IS_CURRENT_POLICY] && <Badge>Current Policy</Badge>}
              {!!savings && <Badge color={'green'}>Save {formatMoney(savings)}</Badge>}
              {hasTelematics && <Badge color={'purple'}>Telematics</Badge>}
              {isBundled && <Badge color={'orange'}>Bundled</Badge>}
            </div>
          </div>
          <div className={styles.premium}>
            {formatPremium(quote?.[QUOTE_FIELDS.PREMIUMS])}{' '}
            {coverages && <span className={styles.coverages}>- {coverages}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteSummary;

QuoteSummary.propTypes = {
  quote: PropTypes.object
};

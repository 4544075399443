import axios from 'axios';
import { getOriginationEndpoint } from '../utils/api';
import { concatUri } from '../utils/url';

const RESOURCES = {
  TRANSUNION: 'transunion'
};

/**
 * Fetches existing Transunion data for a given origination id
 *
 * @param {string} oid
 * @returns {object}
 */
export async function getTransunion(oid) {
  const uri = concatUri(getOriginationEndpoint(), [oid, RESOURCES.TRANSUNION]);
  const { data } = await axios.get(uri);
  return data;
}

/**
 * Calls Transunion with the name and address provided and returns the mapped data
 *
 * @param {object} params
 * @param {string} params.oid
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {object} params.address
 * @returns {object}
 */
export async function postTransunion({ oid, firstName, lastName, address }) {
  const uri = concatUri(getOriginationEndpoint(), [oid, RESOURCES.TRANSUNION]);
  const { data } = await axios.post(uri, { firstName, lastName, address });
  return data;
}

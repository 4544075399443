import { put, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { setError } from '../actions/error';

function* clearErrorState() {
  yield put(setError());
}

export function* watchLocationChange() {
  yield takeLatest(LOCATION_CHANGE, clearErrorState);
}

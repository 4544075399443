import { CARRIERS } from 'woop-shared/canopy/carriers';

const HOME_CARRIERS = [
  '21st Century',
  'AAA',
  'Acuity',
  'Adirondack Ins Exchange',
  'AIG',
  'Alfa Alliance',
  'Allianz of America',
  'Allianz of America - Jefferson',
  'Allied',
  'Allmerica',
  'Allstate',
  'America First',
  'American Commerce',
  'Ameriprise',
  'Amica',
  'ASI Lloyds',
  'Asurant',
  'Atlas General Agency',
  'Austin Mutual',
  'Auto-Owners',
  'Badger Manual',
  'Balboa',
  'Bamboo',
  'Beacon National',
  'Bunker Hill',
  'Capital Insurance Group',
  'Central Mutual of OH',
  'Chubb',
  'Cincinnati',
  'Citizens',
  'Colorado Casualty',
  'CSE',
  'Cumberland',
  'Cypress',
  'Dairyland',
  'Donegal',
  'Electric',
  'EMC',
  'Encompass',
  'Erie',
  'Esurance',
  'Excelsior Insurance Company',
  'Fair Plan',
  'Farm Bureau',
  'Farmers',
  'Fidelity',
  'Firemans Fund',
  'First American',
  'Foremost Insurance Company',
  'Franklin Mutual',
  'Geico',
  'General Casualty',
  'Germantown Mutual',
  'GMAC',
  'Grance',
  'Grinnell',
  'Guide One',
  'Hanover',
  'Harleysville',
  'Hartford',
  'Hartford OMNI',
  'Hawkeye Security',
  'Hippo Insurance Company',
  'Homeowners of America',
  'Horace Mann',
  'Houston General',
  'Indiana',
  'Indiana Farmers',
  'Integrity',
  'Jewelers',
  'Kemper',
  'Liberty Mutual',
  'Liberty Northwest',
  'Lloyds',
  'Main Street America',
  'Mapfre',
  'Merchants Group',
  'Mercury',
  'MetLife',
  'Midwestern Indemnity',
  'Montgomery',
  'MSA',
  'Mutual Benefit',
  'National General',
  'Nationwide',
  'Nationwide-Scottsdale',
  'New York Central Mutual',
  'NJ Skylands',
  'Northstar',
  'Ohio Casualty',
  'One Beacon',
  'Oregon Mutual',
  'Pacific',
  'Peerless/Montgomery',
  'Pekin',
  'Penn National',
  'Plymouth Rock',
  'Preferred Mutual',
  'Prudential',
  'Pure',
  'Republic',
  'Royal and Sun Alliance',
  'Safeco',
  'SafgeSure',
  'SECURA',
  'Selective',
  'Southern Mutual',
  'Southern Trust',
  'Standard Mutual',
  'State Auto',
  'State Farm',
  'Stillwater',
  'St. Paul/Travelers',
  'Travelers',
  'TWFG',
  'Unigard',
  'United Fire and Casualty',
  'Unitrin',
  'Universal',
  'Universal Property',
  'USAA',
  'Utica National',
  'Wellington',
  'West Bend',
  'Western National',
  'Western Reserve Group',
  'Westfield',
  'White Mountains',
  'Wilson Mutual',
  'Zurich'
];

const AUTO_CARRIERS = [
  '21st Century',
  'A. Central',
  'AAA',
  'AARP',
  'Acadia',
  'Ace',
  'Acuity',
  'Adirondack Ins Exchange',
  'Aegis',
  'AIC',
  'Alfa Alliance',
  'Allied',
  'Allstate',
  'America First',
  'American Commerce',
  'American Family',
  'Ameriprise',
  'Amica',
  'ASI Lloyds',
  'Austin Mutual',
  'Autoone',
  'Auto-Owners',
  'Badger Mutual',
  'Balboa',
  'Bankers',
  'Beacon National',
  'Brethern Mutual',
  'Bristol West',
  'Capital Insurance Group',
  'Celina',
  'Central Mutual of OH',
  'Chubb',
  'Cincinnati',
  'CNA',
  'Colonial Penn',
  'Colorado Casualty',
  'Columbia',
  'Commerce West',
  'Constitutional Casualty',
  'Cornerstone',
  'Countrywide',
  'CSE',
  'Cumberland',
  'Dairyland',
  'Deerbrook',
  'Direct',
  'Donegal',
  'Drive',
  'Electric',
  'EMC',
  'Encompass',
  'Erie',
  'Esurance',
  'Explorer',
  'Farm Bureau',
  'Farmers',
  'Fidelity',
  'Firemans Fund',
  'First American',
  'Foremost',
  'Geico',
  'General Casualty',
  'Germantown Mutual',
  'GMAC',
  'Grance',
  'Great American',
  'Grinnell',
  'Guide One',
  'Hanover',
  'Harbor',
  'Harleysville',
  'Hartford OMNI',
  'Hartford  ',
  'Hastings Mutual',
  'Hawkeye Security',
  'HDI',
  'Horace Mann',
  'Houston General',
  'IFA',
  'Imperial Casualty',
  'Indiana Farmers',
  'Indiana  ',
  'Infinity',
  'Insurequest',
  'Integon',
  'Integrity',
  'Jewelers',
  'Kemper',
  'Liberty Mutual',
  'Liberty Northwest',
  'Main Street America',
  'Mapfre',
  'Mendota',
  'Merchants Group',
  'Mercury',
  'MetLife',
  'Metropolitan',
  'Mid-Continent',
  'Midwestern Indemnity',
  'Montgomery',
  'MSA',
  'Mt. Washington',
  'Mutual Benefit',
  'Nationwide',
  'National General',
  'New York Central Mutual',
  'NJ Manufacturers',
  'NJ Skylards',
  'Northstar',
  'Occidental',
  'Ohio Casualty',
  'Omaha P/C',
  'One Beacon',
  'Oregon Mutual',
  'Palisades',
  'Peerless/Montgomery',
  'Pekin',
  'Pemco',
  'Penn National',
  'Plymouth Rock',
  'Preferred Mutual',
  'Proformance',
  'Progressive',
  'Prudential',
  'Pure',
  'Republic',
  'Rockford Mutual',
  'Royal and Sun Alliance',
  'Safeco',
  'Secura',
  'Selective',
  'Southern Country',
  'Southern Mutual',
  'Southern Trust',
  'St. Paul/Travelers',
  'Standard Mutual',
  'Star Casualty',
  'State Auto',
  'State Farm',
  'Travelers',
  'TWFG',
  'Unigard',
  'United Fire and Casualty',
  'Unitrin',
  'Universal',
  'USAA',
  'Utica National',
  'Victoria',
  'West Bend',
  'Western National',
  'Western Reserve Group',
  'Westfield',
  'White Mountains',
  'WIlson Mutual',
  'Windsor',
  'Zurich'
];

export const AUTO_OTHER_CARRIERS = [...AUTO_CARRIERS, 'Other'];

const CONDO_CARRIERS = [
  '21st Century',
  'AAA',
  'Acuity',
  'Adirondack Ins Exchange',
  'AIG',
  'Alfa Alliance',
  'Allianz of America',
  'Allianz of America-Jefferson',
  'Allied',
  'Allmerica',
  'Allstate',
  'America First',
  'American Commerce',
  'Ameriprise',
  'Amica',
  'ASI Lloyds',
  'Assurant',
  'Atlas General Agency',
  'Austin Mutual',
  'Auto-Owners',
  'Badger Manual',
  'Balboa',
  'Bamboo',
  'Beacon National',
  'Bunker Hill',
  'Capital Insurance Group',
  'Central Mutual of OH',
  'Chubb',
  'Cincinnati',
  'Citizens',
  'Colorado Casualty',
  'CSE',
  'Cumberland',
  'Cypress',
  'Dairyland',
  'Donegal',
  'Electric',
  'EMC',
  'Encompass',
  'Erie',
  'Esurance',
  'Excelsior Insurance Company',
  'Fair Plan',
  'Farm Bureau',
  'Farmers',
  'Fidelity',
  'Firemans Fund',
  'First American',
  'Foremost Insurance Company',
  'Franklin Mutual',
  'Geico',
  'General Casualty',
  'Germantown Mutual',
  'GMAC',
  'Grange',
  'Grinnell',
  'Guide One',
  'Hanover',
  'Harleysville',
  'Hartford',
  'Hartford OMNI',
  'Hawkeye Security',
  'Hippo Insurance Company',
  'Homeowners of America',
  'Horace Mann',
  'Houston General',
  'Indiana',
  'Indiana Farmers',
  'Integrity',
  'Jewelers',
  'Kemper',
  'Liberty Mutual',
  'Liberty Northwest',
  'Lloyds',
  'Main Street America',
  'Mapfre',
  'Merchants Group',
  'Mercury',
  'MetLife',
  'Midwestern Indemnity',
  'Montgomery',
  'MSA',
  'Mutual Benefit',
  'National General',
  'Nationwide',
  'Nationwide-Scottsdale',
  'New York Central Mutual',
  'NJ Skylands',
  'Northstar',
  'Ohio Casualty',
  'One Beacon',
  'Oregon Mutual',
  'Pacific',
  'Peerless/Montgomery',
  'Pekin',
  'Penn National',
  'Plymouth Rock',
  'Preferred Mutual',
  'Prudential',
  'Pure',
  'Republic',
  'Royal and Sun Alliance',
  'Safeco',
  'SageSure',
  'SECURA',
  'Selective',
  'Southern Mutual',
  'Southern Trust',
  'Standard Mutual',
  'State Auto',
  'State Farm',
  'Stillwater',
  'St. Paul/Travelers',
  'Travelers',
  'TWFG',
  'Unigard',
  'United Fire and Casualty',
  'Unitrin',
  'Universal',
  'Universal Property',
  'USAA',
  'Utica National',
  'Wellington',
  'West Bend',
  'Western National',
  'Western Reserve Group',
  'Westfield',
  'White Mountains',
  'Wilson Mutual',
  'Zurich'
];

const RENTERS_CARRIERS = [
  '21st Century',
  'AAA',
  'Acuity',
  'Adirondack Ins Exchange',
  'AIG',
  'Alfa Alliance',
  'Allianz of America',
  'Allianza of America-Jefferson',
  'Allied',
  'Allmerica',
  'Allstate',
  'America First',
  'American Commerce',
  'Ameriprise',
  'Amica',
  'ASI Lloyds',
  'Assurant',
  'Atlas General Agency',
  'Austin Mutual',
  'Auto-Owners',
  'Badger Mutual',
  'Balboa',
  'Bamboo',
  'Beacon National',
  'Bunker Hill',
  'Capital Insurance Group',
  'Central Mutual of OH',
  'Chubb',
  'Cincinnati',
  'Citizens',
  'Colorado Casualty',
  'CSE',
  'Cumberland',
  'Cypress',
  'Dairyland',
  'Donegal',
  'Electric',
  'EMC',
  'Encompass',
  'Erie',
  'Esurance',
  'Excelsior Insurance Company',
  'Fair Plan',
  'Farm Bureau',
  'Farmers',
  'Fidelity',
  'Firemans Fund',
  'First American',
  'Foremost Insurance Company',
  'Franklin Mutual',
  'Geico',
  'General Casualty',
  'Germantown Mutual',
  'GMAC',
  'Grange',
  'Grinnell',
  'Guide One',
  'Hanover',
  'Harleysville',
  'Hartford',
  'Hartford OMNI',
  'Hawkeye Security',
  'Hippo Insurance Company',
  'Homeowners of America',
  'Horace Mann',
  'Houston General',
  'Indiana',
  'Indiana Farmers',
  'Integrity',
  'Jewelers',
  'Kemper',
  'Liberty Mutual',
  'Liberty Northwest',
  'Lloyds',
  'Main Street America',
  'Mapfre',
  'Merchants Group',
  'Mercury',
  'MetLife',
  'Midwestern Indemnity',
  'Montgomery',
  'MSA',
  'Mutual Benefit',
  'National General',
  'Nationwide',
  'Nationwide-Scottsdale',
  'New York Central Mutual',
  'NJ Skylands',
  'Northstar',
  'Ohio Casualty',
  'One Beacon',
  'Oregon Mutual',
  'Pacific',
  'Peerless/Montgomery',
  'Pekin',
  'Penn National',
  'Plymouth Rock',
  'Preferred Mutual',
  'Prudential',
  'Republic',
  'Royal and Sun Alliance',
  'Safeco',
  'SageSure',
  'SECURA',
  'Selective',
  'Southern Mutual',
  'Southern Trust',
  'Standard Mutual',
  'State Auto',
  'State Farm',
  'Stillwater',
  'St. Paul/Travelers',
  'Travelers',
  'TWFG',
  'Unigard',
  'United Fire and Casualty',
  'Unitrin',
  'Universal',
  'Universal Property',
  'USAA',
  'Utica National',
  'Wellington',
  'West Bend',
  'Western National',
  'Western Reserve Group',
  'Westfield',
  'White Mountains',
  'Wilson Mutual',
  'Zurich',
  '21st Mortgage Corporation',
  'ABNB Federal Credit Union',
  'ACAR Leasing',
  'Achieva Credit Union',
  'Acura Financial Services',
  'Advia Credit Union',
  'AEA Federal Credit Union',
  'Affinity Plus Federal Credit Union',
  'Alabama Central Credit Union',
  'Alaska USA Federal Credit Union',
  'Alcoa Tenn Federal Credit Union',
  'Alexium Leasing Co',
  'Ally Bank and Its Assigns',
  'Ally Capital and Its Assigns',
  'Ally Financial',
  'Alphera Financial Services',
  'Amarillo National Bank',
  'American Credit Acceptance',
  'American Express Consumer Loan Ctr',
  'American Honda Financial - AHFC',
  'American Suzuki Financial Services',
  'American Trust',
  'AmeriServ Financial',
  'Andrews Federal Credit Union',
  'APG Federal Credit Union',
  'Arizona Central Credit Union',
  'Arizona State Credit Union',
  'ASI Federal Credit Union',
  'Atlantic Finance',
  'Audi Financial Services',
  'Auto Finance',
  'Auto Now Acceptance',
  'AutoBank Acceptance Corp',
  'Automotive Credit Corp',
  'Auto Trakk &/or Lyco Auto Trust',
  'Bangor Savings Bank',
  'Bank of America',
  'Bank of America Auto Financial Corp',
  'Bank of The West',
  'Banterra Bank',
  'Baxter Credit Union',
  'Bay View Acceptance Corp',
  'BB&T',
  'BB&T Equipment Finance Corp',
  'Benchmark Community Bank',
  'Beneficial ISAOA',
  'Bering Financial Services',
  'BMO Harris Bank',
  'BMW Financial Services Na LLC',
  'Boeing Employees Credit Union',
  'Bridgecrest Acceptance Corporation',
  'Brookline Bank',
  'Brothers Enterprise',
  'C & F Finance',
  'Cab East LLC',
  'Cab West LLC',
  'Caliber Home Loans Inc ISAOA ATIMA',
  'Capital City Bank',
  'Capital One Auto Finance',
  'CarMax Auto Finance',
  'Carolina Finance',
  'Cars  Financial',
  'CCAP Auto Lease Ltd',
  'CDC Federal Credit Union',
  'Cefcu ISAOA',
  'Cenlar Fsb ISAOA ATIMA',
  'Centier Bank ISAOA',
  'Central Pacific Bank',
  'Charter Lease',
  'Chartway Federal Credit Union',
  'Chase Auto Finance',
  'Chevy Chase Bank',
  'Chrysler Capital',
  'CIT Group (Commercial Invest Trst)',
  'CIT Group/Sales',
  'Citizen Finance',
  'Citizens Auto Finance (RBS)',
  'Citizens Autolease',
  'City National Bank',
  'Clearfield Bank & Trust Co',
  'Clearview Federal Credit Union',
  'Collins Community Credit Union',
  'Commerce Bank & Trust Co',
  'Compass Bank (BBVA)',
  'Condor Capital Corporation',
  'Consumer Portfolio Service (CPS)',
  'Coral Community Federal Credit Union',
  'Cornerstone Community Federal Credit Union',
  'Cornerstone Credit Union',
  'Coulee Dam Federal Credit Union',
  'Credit Acceptance',
  'Credit Union Loan Source LLC',
  'Credit Union of Colorado',
  'Credit Union of Texas',
  'Credit Union West',
  'Crescent Bank and Trust',
  'CSB Bank',
  'Credit Union Factory Built Len',
  'Cyprus Credit Union',
  'Dealer Services Corp',
  'Desert Financial Credit Union',
  'Digital Federal Credit Union',
  'Ditech Financial LLC ISAOA',
  'Dupaco Credit Union',
  'DuPage Credit Union',
  'DuTrac Community Credit Union',
  'Eaglemark Savings Bank',
  'Earthmover Credit Union',
  'Electric Service Credit Union',
  'Elko Federal Credit Union',
  'Empower Federal Credit Union',
  'Enrichment Federal Credit Union',
  'Entex Credit Union',
  'Equity Auto Finance',
  'Exeter Financial Corp',
  'Family Security',
  'Felco Auto Lease',
  'Ferrari Financial Services',
  'Fifth Third Bank',
  'Fifth Third Bank Copr ISAOA',
  'Financial Services Vehicle Trust',
  'Fireside Bank',
  'First Bank of Ohio',
  'First Florida Credit Union',
  'First General Leasing',
  'First Investor Financial Services',
  'First Merit Bank',
  'First National Bank - Bemidji',
  'First of America',
  'First Ohio Community Federal Credit Union',
  'First Premier Bank',
  'First State Bank - OK',
  'Foothill Federal Credit Union',
  'Ford Motor Credit Company',
  'Fort Madison Bank & Trust',
  'Fox Communities Credit Union',
  'Freedom Bank',
  'Freedom Roads Finance',
  'Fresno County Federal Credit Union',
  'Gate City Bank',
  'Gateway One',
  'GE Capital Auto Financial Svc',
  'GE Money Bank',
  'GFC Lending',
  'Global Lending Services',
  'GM Financial',
  'GMAC',
  'Greater Suburban Acceptance',
  'Green Cap Financial',
  'Grow Financial Credit Union',
  'GTE Financial Credit Union',
  'Gulf Credit Union',
  'Hann Auto Trust',
  'Hann Financial Services Corp',
  'Harley Davidson Credit',
  'Harris Bank',
  'Harris County Federal Credit Union',
  'Hitachi Capital America Corp',
  'HSBC Bank USA ISAOA',
  'Hudson Valley Federal Credit Union',
  'Huntington Bank ISAOA ATIMA',
  'Huntington Natl Bank',
  'Hyundai Lease Title Trust',
  'Idaho Central Credit Union',
  'Independent Bank',
  'Innovate Loan Servicing Corp',
  'Jaguar Credit',
  'Kemba Financial Credit Union',
  'Key Bank',
  'Kia Motor Finance Co',
  'Kitch Acceptance Corp',
  'Kitsap Bank',
  'Lake Michigan Credit Union',
  'Land Rover Capital Group',
  'Landmark Credit Un',
  'Leaders Financial Company',
  'Lexus Financial Services',
  'M & T Bank',
  'Marine Credit Union',
  'Marine Federal Credit Union',
  'Marquette Consumer Finance',
  'Mazda American Credit',
  'Mb Financial Bk',
  'Mercedes Benz Credit',
  'Mercury Financial Co LLC',
  'Mid Atlantic Finance Company',
  'Midwest Financial Credit Union',
  'Mini Financial Services',
  'Missoula Federal Credit Union',
  'MMCA (Mitsubishi Motor)',
  'MMCALL (Mitsubishi)',
  'Mountain America Credit Union',
  'Municipal Credit Union',
  'Nassau Financial Federal Credit Union',
  'National Auto Acceptance Corp',
  'Nationstar Mortgage',
  'Nationwide Acceptance LLC',
  'Navistar Financial Corp',
  'Navy Federal Credit Union',
  'NBT Bank',
  'Neighbors Federal Credit Union',
  'New City Funding Corp',
  'New South Fsb',
  'Nextier Bank',
  'Nicholas Financial',
  'Nissan Motor Acceptance',
  'Nissan-Infiniti Lt',
  'North Shore Bank',
  'Northwest Bank',
  'Northwest Community Credit Union',
  'Numerica Credit Union',
  'Nuvell Financial Services, LLC',
  'Nuvision Financial Federal Credit Union',
  'Old National Bank',
  'Omni American Bank',
  'OneMain Financial ISAOA',
  "Orange County's Credit Union",
  'Oregon Community Credit Union',
  'Orrstown Bank',
  'Paccar Financial Corp',
  'Pacific Community Credit Union',
  'PBC Credit Union',
  'Pennsylvania State Employee Credit Union',
  'Pentagon Federal Credit Union (PENFED)',
  'Peoples Bank of Cuba Mo',
  'PNC Bank',
  'PNC Bank Natl Assc ISAOA',
  'Porsche Financial',
  'Porsche Leasing Ltd',
  'Portage National Bank',
  'Premier Auto Finance of Florida',
  'Prestige Financial Services',
  'Primus Auto Financial Services',
  'Primus Financial Services',
  'Professional Financial Services',
  'Regional Accept Corp',
  'Regions Bank',
  'Reliable Credit',
  'Rolls-Royce Financial Services',
  'Saab Financial Services LLC',
  'San Antonio Federal Credit Union',
  'Santander Consumer USA Inc',
  'Savings Financial Corp',
  'Security Natl Auto Co (SNAAC)',
  'Security Service Federal Credit Union',
  'SELCO Community Credit Union',
  'Shell Federal Credit Union',
  'Sierra Central Credit Union',
  'Simmesport State Bank',
  'Sonny Gerber Auto Sales',
  'Source One Financial',
  'Southern Auto Financial (SAFCO)',
  'South Side Credit Union',
  'Space Coast Credit Union',
  'Star Financial Bank',
  'State Employees Credit Union',
  'Subaru American Credit',
  'Sun East Federal Credit Union',
  'Suncoast Credit Union',
  'Sunstate Federal Credit Union',
  'SunTrust Bank',
  'System Services and Technologies',
  'TAPCO Credit Union',
  'Tarrant County Credit Union',
  'Td Auto Finance',
  'Texans Credit Union',
  'Texas Dow Employees Credit Union',
  'Texas Trust Credit Union',
  'Thor Credit Corp',
  'Thrivent Financial Corp',
  'Tidewater Motor Credit',
  'Toyota Motor Credit Corp',
  'Toyota Motor Credit - TMCC',
  'Travis Credit Union',
  'Tri County Area Federal Credit Union',
  'Tri County Credit Union',
  'Tropical Financial Credit Union',
  'Truliant Federal Credit Union',
  'Union Bank and Trust',
  'United Auto Acceptance',
  'United Auto Credit Corp',
  'United Federal Credit Union',
  'United Quest Financial',
  'United Sa Federal Credit Union',
  'US Bank',
  'US Bank Natl Assc ISAOA',
  'USAA Bank',
  'Valley National Bank',
  'Vanderbilt Mort & Financial Inc ISAOA',
  'Veridian Credit Union',
  'Volvo Car Finance',
  'Vt Inc as Trustee World Omni Lt',
  'VW Credit Inc',
  'VW Credit Leasing Ltd',
  'WCC Federal Credit Union',
  'Wells Fargo Auto Finance',
  'Wells Fargo Bank',
  'Wells Fargo Dealer Services',
  'Westbury Bank',
  'Western States Bank',
  'Westlake Financial Services',
  'WFS Financial Inc',
  'Wilmar Leasing Inc',
  'Wings Financial Credit Union',
  'Wisconsin Consumer Credit Inc',
  "Workers' Credit Union",
  'World Omni',
  "Zion's Bank"
];

const HOME_RENTERS_CONDO_CARRIERS = Array.from(
  new Set([...HOME_CARRIERS, ...RENTERS_CARRIERS, ...CONDO_CARRIERS])
).sort();

export const HOME_RENTERS_CONDO_OTHER_CARRIERS = [...HOME_RENTERS_CONDO_CARRIERS, 'Other'];

/**
 * Canopy carrier select options
 */
export const CARRIER_OPTIONS = Object.entries(CARRIERS).map(([code, data]) => {
  return {
    label: data.name,
    value: code
  };
});

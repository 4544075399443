import PropTypes from 'prop-types';
import React from 'react';
import { QUOTE_FIELDS, DISCOUNT_FIELDS } from 'woop-shared/db/models';
import { DISCOUNT_TYPES } from 'woop-shared/quotes/enums';
import Checkbox from '../../../../components/Checkbox';
import { getQuoteId } from '../../utils';
import styles from './styles.css';

const Discounts = ({ quote, updateQuote, isAuto }) => {
  const handleChange = (quote, type) => (name, value) => {
    const id = getQuoteId(quote);
    const discounts = quote[QUOTE_FIELDS.DISCOUNTS] || [];
    const updatedDiscounts = value
      ? [...discounts, { [DISCOUNT_FIELDS.TYPE]: type }]
      : discounts.filter(d => d[DISCOUNT_FIELDS.TYPE] !== type);
    updateQuote(id, { [name]: updatedDiscounts });
  };

  return (
    <div className={styles.wrapper}>
      {isAuto && (
        <Checkbox
          prompt={'Discounts'}
          name={QUOTE_FIELDS.DISCOUNTS}
          label={`Telematics`}
          onChange={handleChange(quote, DISCOUNT_TYPES.TELEMATICS)}
          value={
            !!quote[QUOTE_FIELDS.DISCOUNTS]?.find(
              d => d[DISCOUNT_FIELDS.TYPE] === DISCOUNT_TYPES.TELEMATICS
            )
          }
        />
      )}
    </div>
  );
};

Discounts.propTypes = {
  isAuto: PropTypes.bool,
  quote: PropTypes.object,
  updateQuote: PropTypes.func
};

export default Discounts;

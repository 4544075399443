import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import configureStore from './store/configure-store';
import AppRouter from './router/AppRouter';
import './css/app.css';
import { afterRender, beforeRender } from './init';
import { MOUNT_NODE } from './constants/app';

const store = configureStore();

// Sentry error reporting.
if (process.env.ENV !== 'local') {
  Sentry.init({ dsn: process.env.SENTRY_DSN, environment: process.env.ENV });
}

beforeRender();

ReactDOM.render(
  <Provider store={store}>
    <AppRouter />
  </Provider>,
  MOUNT_NODE
);

afterRender();

// eslint-disable-next-line no-console
console.log('ENV', process.env.ENV);
// eslint-disable-next-line no-console
console.log('VERSION', process.env.VERSION);

import React from 'react';
import PropTypes from 'prop-types';
import BaseTextField from '../TextField';
import { formatPhone } from './utils';

const PhoneField = ({
  name,
  label,
  prompt,
  isValid,
  placeholder,
  validationText,
  showValidation,
  id,
  ...rest
}) => {
  const { value = '', onChange = () => {} } = rest;

  const handleInput = e => {
    // If removing character(s), do not format.
    if (e.target.value?.length < value?.length) {
      return onChange(name, e.target.value);
    }
    onChange(name, formatPhone(e.target.value));
  };

  return (
    <BaseTextField
      type="tel"
      isValid={isValid}
      prompt={prompt}
      label={label}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={handleInput}
      validationText={validationText}
      showValidation={showValidation}
      id={id}
    />
  );
};

export default PhoneField;

PhoneField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  prompt: PropTypes.string,
  isValid: PropTypes.bool,
  placeholder: PropTypes.string,
  validationText: PropTypes.string,
  showValidation: PropTypes.bool,
  id: PropTypes.string
};

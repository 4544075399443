import { connect } from 'react-redux';
import { getJourney } from '../../actions/journeys';
import TransunionView from '.';
import { getTransunion, postTransunion } from '../../actions/transunion';

const mapStateToProps = ({ journey, transunion, loading }) => {
  const { journeyId, uiData, originationId } = journey;

  const { vehicles, applicants } = transunion;

  return {
    journeyId,
    uiData,
    originationId,
    vehicles,
    applicants,
    loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getJourney: id => dispatch(getJourney(id)),
    getTransunion: id => dispatch(getTransunion(id)),
    postTransunion: data => dispatch(postTransunion(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransunionView);

import React, { useState } from 'react';
import { HiPencil, HiX } from 'react-icons/hi';
import PropTypes from 'prop-types';
import { isValidCoverage } from 'woop-shared/utils/canopy';
import styles from './styles.css';
import { useModalControls } from '../../../../hooks/modal-controls';
import { deleteVehicleCoverage } from '../../../../api/policy';
import EditCoverage from '../EditCoverage';
import { formatCents, formatVehicle, isAutoPolicyCoverage } from '../../utils';

const VehicleCoverages = ({ policy, vehicle, refreshPolicy }) => {
  const [visible, closing, toggleModal] = useModalControls(false);

  const coverages = (vehicle.coverages || []).filter(coverage => !isAutoPolicyCoverage(coverage));

  const [selectedVehicle, setSelectedVehicle] = useState();
  const [selectedCoverage, setSelectedCoverage] = useState();
  const toggleModalForCoverage = (vehicle, coverage) => () => {
    setSelectedVehicle(vehicle);
    setSelectedCoverage(coverage);
    toggleModal();
  };

  const handleDelete = coverage => () => {
    const confirmed = confirm(`Delete coverage ${coverage.friendly_name}?`);
    if (!confirmed) return;

    deleteVehicleCoverage({
      policyId: policy.policy_id,
      vehicleId: vehicle.vehicle_id,
      coverageId: coverage.vehicle_coverage_id
    }).then(() => refreshPolicy());
  };

  return (
    <div>
      <div className={styles.tableHeader}>
        {formatVehicle(vehicle).toUpperCase()}
        <a className={styles.edit} onClick={toggleModalForCoverage(vehicle)}>
          + Add a new vehicle coverage
        </a>
      </div>
      <div className={styles.table}>
        {!!coverages.length && (
          <div className={styles.row}>
            <div className={styles.columnHeader}>Coverage</div>
            <div className={styles.columnHeader}>Per Person</div>
            <div className={styles.columnHeader}>Per Incident</div>
            <div className={styles.columnHeader}>Per Day</div>
            <div className={styles.columnHeader}>Deductible</div>
            <div></div>
          </div>
        )}
        {coverages
          .filter(isValidCoverage)
          .sort((a, b) => a.friendly_name.localeCompare(b.friendly_name))
          .map(coverage => {
            return (
              <div className={styles.row} key={`coverage-${coverage.vehicle_coverage_id}`}>
                <div>{coverage.friendly_name}</div>
                <div>{formatCents(coverage.per_person_limit_cents)}</div>
                <div>{formatCents(coverage.per_incident_limit_cents)}</div>
                <div>{formatCents(coverage.per_day_limit_cents)}</div>
                <div>{formatCents(coverage.deductible_cents)}</div>
                <div className={styles.options}>
                  <HiPencil
                    onClick={toggleModalForCoverage(vehicle, coverage)}
                    className={styles.edit}
                  />
                  <HiX onClick={handleDelete(coverage)} className={styles.delete} />
                </div>
              </div>
            );
          })}
      </div>
      <EditCoverage
        policy={policy}
        vehicle={selectedVehicle}
        coverage={selectedCoverage}
        refreshPolicy={refreshPolicy}
        visible={visible}
        closing={closing}
        toggleModal={toggleModal}
      />
    </div>
  );
};

VehicleCoverages.propTypes = {
  policy: PropTypes.object,
  vehicle: PropTypes.object,
  refreshPolicy: PropTypes.func
};

export default VehicleCoverages;

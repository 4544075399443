// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__wrapper--MwFQJ{display:flex;justify-content:center;align-items:center;border-radius:.5rem;padding:.5rem;cursor:pointer}.styles-module__wrapper--MwFQJ svg{font-size:20px;transition:.3s}.styles-module__wrapper--MwFQJ.styles-module__green--B7gDa{background:var(--color-green-light)}.styles-module__wrapper--MwFQJ.styles-module__yellow--3pMkw{background:var(--color-yellow-light)}.styles-module__wrapper--MwFQJ.styles-module__neutral--PievU{background:var(--color-grey-lighter)}.styles-module__wrapper--MwFQJ.styles-module__red--286QD{background:var(--color-red-lightest)}.styles-module__wrapper--MwFQJ:hover svg{transform:scale(1.1)}.styles-module__wrapper--MwFQJ.styles-module__disabled--39Qrh{opacity:.4}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles-module__wrapper--MwFQJ",
	"green": "styles-module__green--B7gDa",
	"yellow": "styles-module__yellow--3pMkw",
	"neutral": "styles-module__neutral--PievU",
	"red": "styles-module__red--286QD",
	"disabled": "styles-module__disabled--39Qrh"
};
module.exports = exports;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaRegCheckCircle } from 'react-icons/fa';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import styles from './styles.css';
import Card from '../../../../components/Card';
import { FINALIZATION_OPTIONS } from '../../../../constants/quoteset';
import Button from '../../../../components/Button';
import { getUser } from '../../../../api/users';
import { triggerQuotesReady } from '../../../../api/klaviyo';
import { getJourney } from '../../../../api/journeys';
import { error } from '../../../../utils/logger';

const formatDate = date =>
  new Date(date).toLocaleString({ dateStyle: 'medium', timeStyle: 'short' });

const Finalization = ({ finalization, userId, quotesetId, journeyId }) => {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [quotesReadyError, setQuotesReadyError] = useState();

  const typeOption = FINALIZATION_OPTIONS.find(o => o.value === finalization?.type);

  const { date, emailData, sentToClient, quotesReadyUrl } = finalization || {};

  const handleSendEmail = async () => {
    setLoading(true);
    const [{ uiData }, user] = await Promise.all([
      getJourney(journeyId),
      userId ? getUser(userId) : null
    ]);

    if (!uiData) {
      setQuotesReadyError('Failed to send quotes ready email');
      error('Failed to send quotes ready email, no uiData', { journeyId });
      return setLoading(false);
    }

    triggerQuotesReady({
      email: uiData[PROMPT_NAMES.EMAIL],
      isPortalUser: !!user,
      quotesetId
    })
      .then(() => setEmailSent(true))
      .catch(() => setQuotesReadyError('Failed to send quotes ready email'))
      .finally(() => setLoading(false));
  };

  return (
    <Card className={styles.wrapper}>
      <h3>{`This quoteset has been finalized`}</h3>

      <p>{`Date: ${date && formatDate(date)}`}</p>

      <p>{`Type: ${typeOption?.label || ''}`}</p>

      {emailData && (
        <>
          <p>{`Savings: ${emailData?.savings || ''}`}</p>
          <p>{`Carriers: ${emailData?.carriers?.join(', ') || ''}`}</p>
        </>
      )}
      {sentToClient && <p>{`Sent to Client: ${formatDate(sentToClient)}`}</p>}

      <p>{`Quotes URL: ${quotesReadyUrl || ''}`}</p>

      {quotesReadyError && <p className={styles.errorText}>{quotesReadyError}</p>}
      <Button onClick={handleSendEmail} loading={loading} disabled={emailSent}>
        {quotesReadyError
          ? 'Try again'
          : emailSent
          ? 'Email has been sent'
          : 'Re-send quotes ready email'}
      </Button>

      <FaRegCheckCircle />
    </Card>
  );
};

Finalization.propTypes = {
  finalization: PropTypes.object,
  userId: PropTypes.string,
  quotesetId: PropTypes.string,
  journeyId: PropTypes.string
};

export default Finalization;

import { COVERAGE_TYPES } from 'woop-shared/enums';
import { COVERAGE_FIELDS } from 'woop-shared/quotes/enums';

const AUTO_COVERAGES = [
  COVERAGE_FIELDS.PER_PERSON_LIABILITY,
  COVERAGE_FIELDS.PER_ACCIDENT_LIABILITY,
  COVERAGE_FIELDS.PROPERTY_DAMAGE_LIABILITY,
  COVERAGE_FIELDS.UNDERINSURED_PER_PERSON,
  COVERAGE_FIELDS.UNDERINSURED_PER_ACCIDENT,
  COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE
];

const COVERAGE_SETS = {
  [COVERAGE_TYPES.HOME]: [
    COVERAGE_FIELDS.DWELLING,
    COVERAGE_FIELDS.OTHER_STRUCTURES,
    COVERAGE_FIELDS.PERSONAL_PROPERTY,
    COVERAGE_FIELDS.LIABILITY,
    COVERAGE_FIELDS.MEDICAL_PAYMENTS
  ],
  [COVERAGE_TYPES.AUTO]: [...AUTO_COVERAGES],
  [COVERAGE_TYPES.RENTERS]: [
    COVERAGE_FIELDS.PERSONAL_PROPERTY,
    COVERAGE_FIELDS.LIABILITY,
    COVERAGE_FIELDS.MEDICAL_PAYMENTS
  ],
  [COVERAGE_TYPES.CONDO]: [
    COVERAGE_FIELDS.DWELLING,
    COVERAGE_FIELDS.PERSONAL_PROPERTY,
    COVERAGE_FIELDS.LIABILITY,
    COVERAGE_FIELDS.MEDICAL_PAYMENTS
  ],
  [COVERAGE_TYPES.UMBRELLA]: [COVERAGE_FIELDS.LIABILITY]
};

const AUTO_COVERAGES_WITHOUT_UM_PD = AUTO_COVERAGES.filter(
  cov => cov !== COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE
);

/**
 * A coverage lookup based on state and coverage type
 */
export const STATE_COVERAGE_SETS = {
  PA: {
    [COVERAGE_TYPES.AUTO]: [...AUTO_COVERAGES_WITHOUT_UM_PD]
  },
  NY: {
    [COVERAGE_TYPES.AUTO]: [...AUTO_COVERAGES_WITHOUT_UM_PD]
  },
  MA: {
    [COVERAGE_TYPES.AUTO]: [...AUTO_COVERAGES_WITHOUT_UM_PD]
  },
  AZ: {
    [COVERAGE_TYPES.AUTO]: [...AUTO_COVERAGES_WITHOUT_UM_PD]
  }
};

/**
 * Based on coverage type and state abbrev, return default set of coverages
 *
 * @param {string} coverageType
 * @param {string} stateAbbrev
 * @returns {string[]} An array of coverages
 */
export function getCoverageFields(coverageType, stateAbbrev) {
  const standardCoverages = COVERAGE_SETS[coverageType];
  const stateCoverages = STATE_COVERAGE_SETS[stateAbbrev]?.[coverageType];
  return stateCoverages || standardCoverages || [];
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  ORIGINATION_FIELDS as ORIGINATION_MODEL,
  FINALIZATION_FIELDS
} from 'woop-shared/db/models';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { ORIGINATION_FIELDS as FIELDS } from 'woop-shared/origination/fields';
import { hasVal } from 'woop-shared/utils';
import { useParams } from 'react-router-dom';
import styles from './CreateOrigination.css';
import Card from '../../components/Card';
import Addresses from '../../components/Addresses/container';
import Applicants from '../../components/Applicants/container';
import Select from '../../components/Select';
import { createMultiSelectValue, createOptionsFromEnum } from '../../components/Select/utils';
import Button from '../../components/Button';
import PropertyInfo from '../../components/PropertyInfo';
import PropertyIncidents from '../../components/PropertyIncidents/container';
import Pools from '../../components/Pools/container';
import Incidents from '../../components/Incidents/container';
import Vehicles from '../../components/Vehicles/container.js';
import AutoInfo from '../../components/AutoInfo';
import DateField from '../../components/DateField';
import FinalizeOrigination from '../../components/FinalizeOrigination/container';
import ErrorText from '../../components/ErrorText';
import PartnerSelect from '../../components/PartnerSelect/container';
import OriginatorSelect from '../../components/OriginatorSelect/container';
import { ORIGINATION_SECTIONS } from '../../constants/table-of-contents';
import TableOfContents from '../../components/TableOfContents';
import Prompt from '../../components/Prompt';
import Finalized from '../../components/FinalizeOrigination/Finalized';
import { addIdToRoute, ROUTES } from '../../router/routes';
import LinkText from '../../components/LinkText';

const cx = classNames.bind(styles);

const CreateOrigination = ({
  origination = {},
  isValid,
  updateOrigination,
  getOrigination,
  saveOrigination,
  errorMessage,
  loading
}) => {
  let { oid } = useParams();
  const originationId = origination[FIELDS.ID];

  useEffect(() => {
    if (oid && !originationId) getOrigination(oid);
  }, []);

  const handleFieldChange = (name, value) => {
    updateOrigination({ [name]: value });
  };

  const handlePartnerChange = (name, value) => {
    // Clear originator on partner change.
    if (origination[name] !== value && origination[FIELDS.EXTERN_ORIGINATOR_ID]) {
      handleFieldChange(FIELDS.EXTERN_ORIGINATOR_ID, '');
    }

    // Update partner.
    handleFieldChange(name, value);
  };

  const isAutoBundle =
    origination[FIELDS.COVERAGE_TYPES]?.length > 1 &&
    origination[FIELDS.COVERAGE_TYPES]?.includes(COVERAGE_TYPES.AUTO);

  return (
    <section className={cx('wrapper', { loading })} id={ORIGINATION_SECTIONS.ORIGINATION}>
      <TableOfContents
        sections={ORIGINATION_SECTIONS}
        coverageTypes={origination[FIELDS.COVERAGE_TYPES]}
      />
      <Card className={styles.cardFields}>
        {originationId ? (
          <h3 className={styles.heading}>
            Origination: <span className={styles.oid}>{originationId}</span>
          </h3>
        ) : (
          <h3 className={styles.heading}>New Origination</h3>
        )}
        {origination[ORIGINATION_MODEL.COMMENTS] && (
          <div className={styles.uploadNotes}>
            <Prompt>Comments</Prompt>
            <div>{origination[ORIGINATION_MODEL.COMMENTS]}</div>
          </div>
        )}
        <div className={styles.fieldWrapper}>
          <Select
            isMulti={true}
            prompt="Line(s) of Business"
            options={createOptionsFromEnum(COVERAGE_TYPES)}
            name={FIELDS.COVERAGE_TYPES}
            onChange={handleFieldChange}
            value={createMultiSelectValue(origination[FIELDS.COVERAGE_TYPES])}
            id={'lines-of-business'}
            inputId={'lines-of-business-select'}
          />
          <PartnerSelect
            name={FIELDS.ACCOUNT_ID}
            onChange={handlePartnerChange}
            isValid={hasVal(origination[FIELDS.ACCOUNT_ID])}
            showValidation={isValid}
            value={origination[FIELDS.ACCOUNT_ID]}
            prompt={'Select Partner*'}
            id={'select-partner'}
            inputId={'select-partner-select'}
          />
          <OriginatorSelect
            name={FIELDS.EXTERN_ORIGINATOR_ID}
            onChange={handleFieldChange}
            isValid={true}
            partnerAccountId={origination[FIELDS.ACCOUNT_ID]}
            value={origination[FIELDS.EXTERN_ORIGINATOR_ID]}
          />
          <DateField
            name={FIELDS.EFFECTIVE_DATE}
            prompt={'Effective Date'}
            onChange={e => handleFieldChange(e.target.name, e.target.value)}
            value={origination[FIELDS.EFFECTIVE_DATE]}
            id={'effective-date'}
          />
          {isAutoBundle && (
            <DateField
              name={FIELDS.AUTO_EFFECTIVE_DATE}
              prompt={'Auto Effective Date'}
              onChange={e => handleFieldChange(e.target.name, e.target.value)}
              value={origination[FIELDS.AUTO_EFFECTIVE_DATE]}
              id={'auto-effective-date'}
            />
          )}
        </div>
        {!!(origination.quotesets?.length || origination.journeys?.length) && (
          <div className={styles.fieldWrapper}>
            Related Applications:
            {origination.journeys.map(journey => (
              <div key={journey.id}>
                <LinkText href={addIdToRoute(ROUTES.APPLICANT_VIEW, journey.id)} external>
                  Application{' '}
                  {journey.createdUtc &&
                    `(${new Date(journey.createdUtc).toLocaleString().replace(',', '')})`}
                </LinkText>
              </div>
            ))}
            Related Quotesets:
            {origination.quotesets.map(quoteset => (
              <div key={quoteset.id}>
                {' '}
                <LinkText href={addIdToRoute(ROUTES.QUOTES_VIEW, quoteset.id)} external>
                  Quoteset{' '}
                  {quoteset.createdUtc &&
                    `(${new Date(quoteset.createdUtc).toLocaleString().replace(',', '')})`}
                </LinkText>
              </div>
            ))}
          </div>
        )}
      </Card>
      <Card>
        <Addresses />
      </Card>
      <Card>
        <Applicants />
      </Card>
      <PropertyInfo
        propertyInfo={origination[FIELDS.PROPERTY_INFO]}
        coverageTypes={origination[FIELDS.COVERAGE_TYPES]}
        updateOrigination={handleFieldChange}
      />
      <Pools coverageTypes={origination[FIELDS.COVERAGE_TYPES]} />
      <PropertyIncidents />
      <Card>
        <AutoInfo updateOrigination={handleFieldChange} autoInfo={origination[FIELDS.AUTO_INFO]} />
      </Card>
      <Card>
        <Vehicles />
      </Card>
      <Card>
        <Incidents />
      </Card>
      {origination?.[FIELDS.EXTRA_FIELDS] && (
        <Card>
          <pre>{JSON.stringify(origination[FIELDS.EXTRA_FIELDS], null, 2)}</pre>
        </Card>
      )}
      {origination?.[ORIGINATION_MODEL.FINALIZATION]?.[FINALIZATION_FIELDS.DATE] && (
        <Finalized finalizationData={origination[ORIGINATION_MODEL.FINALIZATION]} />
      )}
      <div className={styles.buttons}>
        <Button onClick={saveOrigination} className={styles.save} loading={loading}>
          Save
        </Button>
        <FinalizeOrigination />
      </div>
      <div className={styles.error}>
        <ErrorText>{errorMessage}</ErrorText>
      </div>
    </section>
  );
};

CreateOrigination.propTypes = {
  origination: PropTypes.object,
  isValid: PropTypes.bool,
  getOrigination: PropTypes.func,
  updateOrigination: PropTypes.func,
  saveOrigination: PropTypes.func,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool
};

export default CreateOrigination;

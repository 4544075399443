import { connect } from 'react-redux';
import Applicants from '.';
import { addApplicant, deleteApplicant, updateApplicant } from '../../actions/applicants';
import { createAddressOptions } from '../../utils/options';

const mapStateToProps = ({ applicants, addresses, origination, error }) => {
  return {
    applicants: Object.values(applicants),
    addressTags: createAddressOptions(addresses),
    origination,
    isValid: !error?.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addApplicant: applicant => dispatch(addApplicant(applicant)),
    deleteApplicant: id => dispatch(deleteApplicant(id)),
    updateApplicant: (id, updateObj) => dispatch(updateApplicant({ id, updateObj }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Applicants);

import React from 'react';
import PropTypes from 'prop-types';
import {
  APPLICANT_FIELDS,
  ORIGINATION_FIELDS,
  PHONE_FIELDS,
  PROPERTY_FIELDS
} from 'woop-shared/origination/fields';
import {
  APPLICANT_TYPES,
  EDUCATION,
  UNINSURED_REASONS,
  EXCLUDED_DRIVER_REASONS,
  GENDER,
  LICENSE_STATUS_OPTIONS,
  MARITAL_STATUSES,
  RELATIONSHIPS,
  RESIDENCE_TYPES,
  YEARS_INSURED
} from 'woop-shared/origination/enums';
import { US_STATES } from 'woop-shared/data/states';
import { getPhone, isDriver, isPrimaryDriver } from 'woop-shared/origination/utils';
import TextField from '../../../components/TextField';
import Select from '../../../components/Select';
import {
  createMultiSelectValue,
  createOptionsFromEnum,
  createSelectOption
} from '../../../components/Select/utils';
import DateField from '../../../components/DateField';
import PhoneField from '../../../components/PhoneField';
import Industry from '../../../components/Industry';
import Role from '../../../components/Role';
import RadioGroup from '../../../components/RadioGroup';
import { YES_NO_OPTIONS } from '../constants';
import {
  getApplicantProperty,
  getPrimaryApplicant,
  isEarlyMoveInDate
} from '../../../utils/origination';
import InlineAddressForm from './inline/InlineAddressForm';

// Constants for options
const applicantTypeOptions = createOptionsFromEnum(APPLICANT_TYPES);
const relationshipOptions = createOptionsFromEnum(RELATIONSHIPS);
const genderOptions = createOptionsFromEnum(GENDER);
const maritalStatusOptions = createOptionsFromEnum(MARITAL_STATUSES);
const educationOptions = createOptionsFromEnum(EDUCATION);
const licenseStatusOptions = createOptionsFromEnum(LICENSE_STATUS_OPTIONS);
const excludedDriverOptions = createOptionsFromEnum(EXCLUDED_DRIVER_REASONS);
const yearsInsuredOptions = createOptionsFromEnum(YEARS_INSURED);
const uninsuredOptions = createOptionsFromEnum(UNINSURED_REASONS);
const residenceOptions = createOptionsFromEnum(RESIDENCE_TYPES);

const ApplicantForm = ({
  entity: _entity,
  onInput,
  onChange,
  onEntityUpdate,
  validationState,
  context: { quoteApplication }
}) => {
  const entity = _entity || {};
  const showValidation = validationState.allValid === false;

  // Dependency fields
  const property = getApplicantProperty(quoteApplication, entity);
  const primaryApplicant = getPrimaryApplicant(quoteApplication[ORIGINATION_FIELDS.APPLICANTS]);
  const moveInDate = property?.[PROPERTY_FIELDS.MOVE_IN_DATE];

  const appType = entity[APPLICANT_FIELDS.APPLICANT_TYPE];
  const isOnlyCoApplicant =
    appType?.length === 1 && appType?.includes(APPLICANT_TYPES.CO_APPLICANT);
  const isPrimaryApplicant = appType?.includes(APPLICANT_TYPES.APPLICANT);

  return (
    <>
      <Select
        autoFocus
        prompt={'Applicant type'}
        name={APPLICANT_FIELDS.APPLICANT_TYPE}
        options={applicantTypeOptions.filter(a =>
          a.value === APPLICANT_TYPES.APPLICANT && !!primaryApplicant ? false : true
        )}
        onChange={onChange}
        isMulti={true}
        value={createMultiSelectValue(appType)}
      />

      {!!appType?.length && (
        <>
          <Select
            prompt={'Relationship to insured'}
            name={APPLICANT_FIELDS.RELATIONSHIP}
            options={relationshipOptions}
            onChange={onChange}
            value={createSelectOption(entity[APPLICANT_FIELDS.RELATIONSHIP])}
          />
          <TextField
            label={'First name'}
            name={APPLICANT_FIELDS.FIRST_NAME}
            value={entity[APPLICANT_FIELDS.FIRST_NAME]}
            onChange={onInput}
            isValid={validationState[APPLICANT_FIELDS.FIRST_NAME]?.isValid}
            showValidation={showValidation}
          />
          <TextField
            label={'Last name'}
            name={APPLICANT_FIELDS.LAST_NAME}
            value={entity[APPLICANT_FIELDS.LAST_NAME]}
            onChange={onInput}
            isValid={validationState[APPLICANT_FIELDS.LAST_NAME]?.isValid}
            showValidation={showValidation}
          />
          <DateField
            name={APPLICANT_FIELDS.DOB}
            prompt={'Date of birth'}
            onChange={onInput}
            value={entity[APPLICANT_FIELDS.DOB]}
            isValid={validationState[APPLICANT_FIELDS.DOB]?.isValid}
            showValidation={showValidation}
          />

          {!isOnlyCoApplicant && (
            <>
              <TextField
                name={APPLICANT_FIELDS.EMAIL}
                label={`Email`}
                onChange={onInput}
                value={entity[APPLICANT_FIELDS.EMAIL]}
                isValid={validationState[APPLICANT_FIELDS.EMAIL]?.isValid}
                showValidation={showValidation}
              />
              <PhoneField
                name={`${APPLICANT_FIELDS.PHONE}.0.${PHONE_FIELDS.NUMBER}`}
                label={'Phone number'}
                onChange={onChange}
                value={getPhone(entity)}
                isValid={validationState[APPLICANT_FIELDS.PHONE]?.isValid}
                showValidation={showValidation}
              />
              <Select
                prompt={'Gender'}
                name={APPLICANT_FIELDS.GENDER}
                options={genderOptions}
                onChange={onChange}
                value={createSelectOption(entity[APPLICANT_FIELDS.GENDER])}
                isValid={validationState[APPLICANT_FIELDS.GENDER]?.isValid}
                showValidation={showValidation}
              />
              <Select
                prompt={'Marital status'}
                name={APPLICANT_FIELDS.MARITAL_STATUS}
                options={maritalStatusOptions}
                onChange={onChange}
                value={createSelectOption(entity[APPLICANT_FIELDS.MARITAL_STATUS])}
                isValid={validationState[APPLICANT_FIELDS.MARITAL_STATUS]?.isValid}
                showValidation={showValidation}
              />
              <InlineAddressForm
                idField={APPLICANT_FIELDS.ADDRESS_ID}
                selectedId={entity[APPLICANT_FIELDS.ADDRESS_ID]}
                quoteApplication={quoteApplication}
                onChange={onChange}
                onEntityUpdate={onEntityUpdate}
              />
              {isEarlyMoveInDate(moveInDate) && (
                <InlineAddressForm
                  prompt="Previous address"
                  idField={APPLICANT_FIELDS.PREVIOUS_ADDRESS_ID}
                  selectedId={entity[APPLICANT_FIELDS.PREVIOUS_ADDRESS_ID]}
                  quoteApplication={quoteApplication}
                  onChange={onChange}
                  onEntityUpdate={onEntityUpdate}
                />
              )}
              {isPrimaryApplicant && (
                <Select
                  prompt={'Residence Type'}
                  name={APPLICANT_FIELDS.RESIDENCE_TYPE}
                  options={residenceOptions}
                  onChange={onChange}
                  value={createSelectOption(entity[APPLICANT_FIELDS.RESIDENCE_TYPE])}
                  isValid={validationState[APPLICANT_FIELDS.RESIDENCE_TYPE]?.isValid}
                  showValidation={showValidation}
                />
              )}
              <Select
                prompt={'Education'}
                name={APPLICANT_FIELDS.EDUCATION}
                options={educationOptions}
                onChange={onChange}
                value={createSelectOption(entity[APPLICANT_FIELDS.EDUCATION])}
              />
              <Industry value={entity[APPLICANT_FIELDS.INDUSTRY]} handleChange={onChange} />
              <Role
                industry={entity[APPLICANT_FIELDS.INDUSTRY]}
                value={entity[APPLICANT_FIELDS.ROLE]}
                handleChange={onChange}
              />
            </>
          )}

          {isDriver(entity) && (
            <>
              <Select
                prompt={"Driver's license state"}
                placeholder={"Driver's license state"}
                name={APPLICANT_FIELDS.DRIVERS_LICENSE_STATE}
                options={US_STATES}
                onChange={onChange}
                value={createSelectOption(entity[APPLICANT_FIELDS.DRIVERS_LICENSE_STATE])}
                isValid={validationState[APPLICANT_FIELDS.DRIVERS_LICENSE_STATE]?.isValid}
                showValidation={showValidation}
              />
              <TextField
                name={APPLICANT_FIELDS.DRIVERS_LICENSE_NUMBER}
                label={"Driver's license number"}
                onChange={onInput}
                value={entity[APPLICANT_FIELDS.DRIVERS_LICENSE_NUMBER]}
                isValid={validationState[APPLICANT_FIELDS.DRIVERS_LICENSE_NUMBER]?.isValid}
                showValidation={showValidation}
              />
              <Select
                prompt={"Driver's license status"}
                placeholder={"Driver's license status"}
                name={APPLICANT_FIELDS.LICENSE_STATUS}
                options={licenseStatusOptions}
                onChange={onChange}
                value={createSelectOption(entity[APPLICANT_FIELDS.LICENSE_STATUS])}
                isValid={validationState[APPLICANT_FIELDS.LICENSE_STATUS]?.isValid}
                showValidation={showValidation}
              />
              <DateField
                name={APPLICANT_FIELDS.DRIVERS_LICENSE_DATE}
                prompt={'Date first licensed'}
                onChange={onInput}
                value={entity[APPLICANT_FIELDS.DRIVERS_LICENSE_DATE]}
                isValid={validationState[APPLICANT_FIELDS.DRIVERS_LICENSE_DATE]?.isValid}
                showValidation={showValidation}
                tooltip="Optional. Default value is 16.5 years old if left empty."
              />
              <RadioGroup
                name={APPLICANT_FIELDS.STUDENT}
                prompt={'Student'}
                value={entity[APPLICANT_FIELDS.STUDENT]}
                onChange={onChange}
                options={YES_NO_OPTIONS}
              />
              {entity[APPLICANT_FIELDS.STUDENT] && (
                <>
                  <RadioGroup
                    name={APPLICANT_FIELDS.GOOD_STUDENT}
                    prompt={'Good student'}
                    value={entity[APPLICANT_FIELDS.GOOD_STUDENT]}
                    onChange={onChange}
                    options={YES_NO_OPTIONS}
                  />
                  <RadioGroup
                    name={APPLICANT_FIELDS.STUDENT_AWAY}
                    prompt={'Student away at school'}
                    value={entity[APPLICANT_FIELDS.STUDENT_AWAY]}
                    onChange={onChange}
                    options={YES_NO_OPTIONS}
                  />
                </>
              )}
              <Select
                prompt={'Excluded driver reason'}
                placeholder={'Select reason'}
                name={APPLICANT_FIELDS.EXCLUDED_REASON}
                options={excludedDriverOptions}
                onChange={onChange}
                value={createSelectOption(entity[APPLICANT_FIELDS.EXCLUDED_REASON])}
              />
              <RadioGroup
                name={APPLICANT_FIELDS.IMPAIRMENT}
                prompt={'Physical / medical impairment'}
                value={entity[APPLICANT_FIELDS.IMPAIRMENT]}
                onChange={onChange}
                options={YES_NO_OPTIONS}
              />
            </>
          )}

          {isPrimaryApplicant && (
            <Select
              prompt="Years continuously insured for property"
              name={APPLICANT_FIELDS.YEARS_CONTINUOUSLY_INSURED}
              options={yearsInsuredOptions}
              onChange={onChange}
              value={createSelectOption(entity[APPLICANT_FIELDS.YEARS_CONTINUOUSLY_INSURED])}
            />
          )}

          {isPrimaryDriver(entity) && (
            <>
              <Select
                prompt={'Years continuously insured as a driver'}
                name={APPLICANT_FIELDS.YEARS_CONTINUOUSLY_INSURED_AUTO}
                options={yearsInsuredOptions}
                onChange={onChange}
                value={createSelectOption(entity[APPLICANT_FIELDS.YEARS_CONTINUOUSLY_INSURED_AUTO])}
              />
              <Select
                prompt={'Uninsured reason'}
                placeholder={'Select reason'}
                name={APPLICANT_FIELDS.UNINSURED_REASON}
                options={uninsuredOptions}
                onChange={onChange}
                value={createSelectOption(entity[APPLICANT_FIELDS.UNINSURED_REASON])}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

ApplicantForm.propTypes = {
  context: PropTypes.object,
  entity: PropTypes.object,
  onChange: PropTypes.func,
  onEntityUpdate: PropTypes.func,
  onInput: PropTypes.func,
  validationState: PropTypes.object
};

export default ApplicantForm;

import { SET_QUOTE_APPLICATION } from '../actions/quote-application';

function quoteApplication(state = {}, action) {
  switch (action.type) {
    case SET_QUOTE_APPLICATION: {
      const { payload } = action;
      return payload;
    }
    default:
      return state;
  }
}

export default quoteApplication;

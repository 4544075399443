import React, { useState } from 'react';
import { HiPencil, HiX } from 'react-icons/hi';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { POLICY_TYPES } from 'woop-shared/canopy/enums';
import { isValidCoverage } from 'woop-shared/utils/canopy';
import styles from './styles.css';
import { useModalControls } from '../../../../hooks/modal-controls';
import EditCoverage from '../EditCoverage';
import { deletePolicyCoverage } from '../../../../api/policy';
import { formatCents, isAutoPolicyCoverage, uniqBy } from '../../utils';

const cx = classNames.bind(styles);

const Coverages = ({ policy, refreshPolicy }) => {
  const [visible, closing, toggleModal] = useModalControls(false);

  const [selectedCoverage, setSelectedCoverage] = useState();
  const toggleModalForCoverage = coverage => () => {
    setSelectedCoverage(coverage);
    toggleModal();
  };

  const isAuto = policy?.policy_type === POLICY_TYPES.AUTO;
  const items = (isAuto ? policy?.vehicles : policy?.dwellings) ?? [];
  const idLabel = isAuto ? 'vehicle_coverage_id' : 'dwelling_coverage_id';
  const coverages = uniqBy(
    items.flatMap(item => item?.coverages || []),
    coverage => coverage.name
  ).filter(coverage => !isAuto || isAutoPolicyCoverage(coverage));

  const handleDelete = coverage => () => {
    const confirmed = confirm(`Delete coverage ${coverage.friendly_name}?`);
    if (!confirmed) return;

    deletePolicyCoverage({
      policyId: policy.policy_id,
      coverageSlug: coverage.name
    }).then(() => refreshPolicy());
  };

  return (
    <div>
      <div className={styles.tableHeader}>
        Policy Coverages{' '}
        <a className={styles.edit} onClick={toggleModalForCoverage()}>
          + Add a new policy coverage
        </a>
      </div>
      {isAuto && (
        <p className={styles.explanation}>
          These coverages apply to all vehicles. If you add, update, or delete a coverage here, the
          changes will be applied to all vehicles.
        </p>
      )}
      <div className={styles.table}>
        <div className={cx('row', { autoRow: isAuto })}>
          <div className={styles.columnHeader}>Coverage</div>
          <div className={styles.columnHeader}>Per Person</div>
          <div className={styles.columnHeader}>Per Incident</div>
          {isAuto && <div className={styles.columnHeader}>Per Day</div>}
          <div className={styles.columnHeader}>Deductible</div>
          <div></div>
        </div>
        {coverages.filter(isValidCoverage).map(coverage => {
          return (
            <div className={cx('row', { autoRow: isAuto })} key={`coverage-${coverage[idLabel]}`}>
              <div>{coverage.friendly_name}</div>
              <div>{formatCents(coverage.per_person_limit_cents)}</div>
              <div>{formatCents(coverage.per_incident_limit_cents)}</div>
              {isAuto && <div>{formatCents(coverage.per_day_limit_cents)}</div>}
              <div>{formatCents(coverage.deductible_cents)}</div>
              <div className={styles.options}>
                <HiPencil onClick={toggleModalForCoverage(coverage)} className={styles.edit} />
                <HiX onClick={handleDelete(coverage)} className={styles.delete} />
              </div>
            </div>
          );
        })}
      </div>
      <EditCoverage
        policy={policy}
        refreshPolicy={refreshPolicy}
        coverage={selectedCoverage}
        visible={visible}
        closing={closing}
        toggleModal={toggleModal}
      />
    </div>
  );
};

Coverages.propTypes = {
  policy: PropTypes.object,
  refreshPolicy: PropTypes.func
};

export default Coverages;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CoverageTags.css';
import RadioGroup from '../RadioGroup';
import { COMPARE_TAG_OPTIONS, COVERAGE_TAG_OPTIONS } from './constants';

const CoverageTags = ({ prompt = 'Coverage Tag', value, name, onChange, isValid, compare }) => {
  const options = compare ? COMPARE_TAG_OPTIONS : COVERAGE_TAG_OPTIONS;
  const [val, setVal] = useState(value || null);

  useEffect(() => {
    if (val !== value) setVal(value);
  }, [val, value]);

  const updateCoverageType = (name, val = null) => {
    setVal(val);
    onChange(name, val);
  };

  return (
    <div className={styles.wrapper}>
      <RadioGroup
        prompt={prompt}
        name={name}
        options={options}
        value={val}
        onChange={updateCoverageType}
        isValid={isValid}
      />
    </div>
  );
};

CoverageTags.propTypes = {
  prompt: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  compare: PropTypes.bool
};

export default CoverageTags;

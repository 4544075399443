import React, { useEffect, useState } from 'react';
import { POLICY_TYPES } from 'woop-shared/canopy/enums';
import { COVERAGE_NAMES, INCLUDED_COVERAGES } from 'woop-shared/canopy/coverages';
import PropTypes from 'prop-types';
import Select from '../../../../components/Select';
import { createSelectOption } from '../../../../components/Select/utils';
import styles from './styles.css';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import MoneyField from '../../../../components/MoneyField';
import { convertToCents, formatCents } from '../../utils';
import { updateCoverage } from './utils';
import Checkbox from '../../../../components/Checkbox';
import {
  AUTO_COVERAGE_OPTIONS,
  HOME_COVERAGE_OPTIONS,
  VEHICLE_COVERAGE_OPTIONS
} from '../../../../constants/canopy';

const EditCoverage = ({
  policy,
  vehicle,
  coverage,
  visible,
  closing,
  toggleModal,
  refreshPolicy
}) => {
  const [loading, setLoading] = useState();

  const [coverageName, setCoverageName] = useState(coverage?.friendly_name);
  const [coverageSlug, setCoverageSlug] = useState(coverage?.name);
  const [perPersonLimit, setPerPersonLimit] = useState(
    formatCents(coverage?.per_person_limit_cents)
  );
  const [perIncidentLimit, setPerIncidentLimit] = useState(
    formatCents(coverage?.per_incident_limit_cents)
  );
  const [perDayLimit, setPerDayLimit] = useState(formatCents(coverage?.per_day_limit_cents));
  const [deductible, setDeductible] = useState(formatCents(coverage?.deductible_cents));
  const [isValid, setIsValid] = useState();
  const [isIncluded, setIsIncluded] = useState(false);

  const isAuto = policy?.policy_type === POLICY_TYPES.AUTO;
  const validate = () => {
    const hasValues = [perPersonLimit, perIncidentLimit, perDayLimit, deductible].some(Boolean);
    return coverageName && (isIncluded || hasValues);
  };

  // a coverage is only valid if it has a name and either has some truthy values or is in the set
  // of coverages that don't require values (INCLUDED_COVERAGES)
  useEffect(() => {
    setIsValid(validate());
  }, [coverageName, perPersonLimit, perIncidentLimit, perDayLimit, deductible, isIncluded]);

  // clears all of the modal's fields
  const clearFields = () =>
    [
      setCoverageName,
      setCoverageSlug,
      setPerPersonLimit,
      setPerIncidentLimit,
      setPerDayLimit,
      setDeductible
    ].forEach(f => f());

  // set/clear all fields when coverage prop changes
  useEffect(() => {
    setCoverageName(coverage?.friendly_name);
    setCoverageSlug(coverage?.name);
    setPerPersonLimit(formatCents(coverage?.per_person_limit_cents));
    setPerIncidentLimit(formatCents(coverage?.per_incident_limit_cents));
    setPerDayLimit(formatCents(coverage?.per_day_limit_cents));
    setDeductible(formatCents(coverage?.deductible_cents));
  }, [coverage]);

  const handleSetCoverage = (_, value) => {
    setCoverageName(COVERAGE_NAMES[value]);
    setCoverageSlug(value);
    setIsIncluded(false);
  };

  const save = () => {
    setLoading(true);

    const updates = {
      name: coverageSlug,
      per_person_limit_cents: convertToCents(perPersonLimit),
      per_incident_limit_cents: convertToCents(perIncidentLimit),
      per_day_limit_cents: convertToCents(perDayLimit),
      deductible_cents: convertToCents(deductible)
    };

    updateCoverage({ policy, coverage, vehicle, updates, isAuto })
      .then(() => refreshPolicy())
      .then(() => {
        setLoading(false);
        toggleModal();
        clearFields();
      });
  };

  return (
    visible && (
      <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
        <div>{coverage ? 'Edit' : 'Create'} Coverage</div>
        <div className={styles.form}>
          {coverage ? (
            <>
              <div className={styles.coverageNameLabel}>Coverage Name</div>
              <div className={styles.coverageName}>{coverage.friendly_name}</div>
            </>
          ) : (
            <Select
              prompt={'Coverage name'}
              name={'coverageName'}
              placeholder={'Coverage Name'}
              value={createSelectOption(coverageName)}
              options={
                isAuto
                  ? vehicle
                    ? VEHICLE_COVERAGE_OPTIONS
                    : AUTO_COVERAGE_OPTIONS
                  : HOME_COVERAGE_OPTIONS
              }
              onChange={handleSetCoverage}
            />
          )}
          <MoneyField
            name="perPersonLimit"
            placeholder="$"
            label={'Per Person Limit'}
            value={perPersonLimit}
            onChange={(_, value) => setPerPersonLimit(value)}
          />
          <MoneyField
            name="perIncidentLimit"
            placeholder="$"
            label={'Per Incident Limit'}
            value={perIncidentLimit}
            onChange={(_, value) => setPerIncidentLimit(value)}
          />
          {isAuto && (
            <MoneyField
              name="perDayLimit"
              placeholder="$"
              label={'Per Day Limit'}
              value={perDayLimit}
              onChange={(_, value) => setPerDayLimit(value)}
            />
          )}
          <MoneyField
            name="deductible"
            placeholder="$"
            label={'Deductible'}
            value={deductible}
            onChange={(_, value) => setDeductible(value)}
          />
          {INCLUDED_COVERAGES.has(coverageSlug) && (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                label={'This is an included coverage'}
                name={'isIncluded'}
                value={isIncluded}
                onChange={() => setIsIncluded(!isIncluded)}
              />
            </div>
          )}
        </div>
        <Button className={styles.modalButton} loading={loading} onClick={save} disabled={!isValid}>
          Save
        </Button>
      </Modal>
    )
  );
};

EditCoverage.propTypes = {
  policy: PropTypes.object,
  vehicle: PropTypes.object,
  coverage: PropTypes.object,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  refreshPolicy: PropTypes.func
};

export default EditCoverage;

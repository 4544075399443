import React, { useState } from 'react';
import { HiPencil, HiX } from 'react-icons/hi';
import PropTypes from 'prop-types';
import styles from './styles.css';
import { useModalControls } from '../../../../hooks/modal-controls';
import EditDriver from '../EditDriver';
import { deleteDriver } from '../../../../api/policy';
import { getDrivers } from '../../utils';
import MaybeError from '../../../../components/MaybeError';

const Drivers = ({ policy, refreshPolicy }) => {
  const [visible, closing, toggleModal] = useModalControls(false);

  const drivers = getDrivers(policy);

  const [selectedDriver, setSelectedDriver] = useState();
  const toggleModalForDriver = driver => () => {
    setSelectedDriver(driver);
    toggleModal();
  };

  const handleDelete = driver => () => {
    const confirmed = confirm(
      `Delete driver ${[driver.first_name, driver.last_name].filter(Boolean).join(' ')}?`
    );
    if (!confirmed) return;

    deleteDriver({
      policyId: policy.policy_id,
      driverId: driver.driver_id
    }).then(() => refreshPolicy());
  };

  return (
    <div>
      <div className={styles.tableHeader}>
        Drivers{' '}
        <a className={styles.edit} onClick={toggleModalForDriver()}>
          + Add a new driver
        </a>
      </div>
      <div className={styles.table}>
        {!drivers.length && (
          <div className={styles.row}>
            <MaybeError isValid={false} errorText="Missing Drivers" />
          </div>
        )}
        {drivers.map(driver => {
          return (
            <div className={styles.row} key={`driver-${driver.driver_id}`}>
              <div>{`${[driver.first_name, driver.last_name].filter(Boolean).join(' ')}`}</div>
              <div>
                <MaybeError isValid={!!driver.date_of_birth_str} errorText="Missing DOB">
                  {driver.date_of_birth_str}
                </MaybeError>
              </div>
              <div>{driver.marital_status}</div>
              <div>{driver.gender}</div>
              <div className={styles.options}>
                <HiPencil onClick={toggleModalForDriver(driver)} className={styles.edit} />
                <HiX onClick={handleDelete(driver)} className={styles.delete} />
              </div>
            </div>
          );
        })}
      </div>
      <EditDriver
        policy={policy}
        driver={selectedDriver}
        refreshPolicy={refreshPolicy}
        visible={visible}
        closing={closing}
        toggleModal={toggleModal}
      />
    </div>
  );
};

Drivers.propTypes = {
  policy: PropTypes.object,
  refreshPolicy: PropTypes.func
};

export default Drivers;

import { getPrimaryApplicant } from '../../utils/origination';

const { ADDRESS_TYPES } = require('woop-shared/origination/enums');
const {
  ORIGINATION_FIELDS,
  ADDRESS_FIELDS,
  APPLICANT_FIELDS
} = require('woop-shared/origination/fields');

/**
 * Check if a required field is missing.
 *
 * @param {object} params
 * @param {object} params.origination
 * @param {Array} params.addresses
 * @param {Array} params.applicants
 * @returns {object} { valid: false, message: '...' }
 */
export function isValid({ origination, addresses, applicants }) {
  if (!origination[ORIGINATION_FIELDS.ACCOUNT_ID]) {
    return {
      valid: false,
      message: 'Please select a partner.'
    };
  }

  if (!hasInsuredAddress(addresses)) {
    return {
      valid: false,
      message: 'Please enter an insured address.'
    };
  }

  const primaryApplicant = getPrimaryApplicant(Object.values(applicants));

  if (!primaryApplicant) {
    return {
      valid: false,
      message: 'Please create a primary applicant.'
    };
  }

  if (
    !primaryApplicant[APPLICANT_FIELDS.LAST_NAME] ||
    !primaryApplicant[APPLICANT_FIELDS.FIRST_NAME]
  ) {
    return {
      valid: false,
      message: "Please enter the primary applicant's first & last name."
    };
  }

  if (!primaryApplicant[APPLICANT_FIELDS.EMAIL] && !primaryApplicant[APPLICANT_FIELDS.PHONE]) {
    return {
      valid: false,
      message: "Please enter the primary applicant's email address or phone."
    };
  }

  return {
    valid: true
  };
}

function hasInsuredAddress(addresses) {
  if (!addresses) return false;
  const insuredAddress = Object.values(addresses).find(
    address => address[ADDRESS_FIELDS.ADDRESS_TYPE] === ADDRESS_TYPES.INSURE
  );
  return !!insuredAddress && !!insuredAddress[ADDRESS_FIELDS.COMPOSITE];
}

import axios from 'axios';
import { concatUri } from '../utils/url';

const BASE_URI = `${process.env.DATA_URI}vehicles`;
const YEARS_ENDPOINT = 'years';
const MAKES_ENDPOINT = 'makes';
const MODELS_ENDPOINT = 'models';

const constructYearsUri = () => concatUri(BASE_URI, YEARS_ENDPOINT);
const constructMakesUri = year => concatUri(BASE_URI, `${MAKES_ENDPOINT}/${year}`);
const constructModelsUri = (year, make) =>
  concatUri(BASE_URI, `${MODELS_ENDPOINT}/${year}/${make}`);

export const getYears = async () => {
  const { data } = await axios.get(constructYearsUri());
  return data?.map(y => parseInt(y));
};

export const getMakes = async year => {
  const { data } = await axios.get(constructMakesUri(year));
  return data?.map(m => m.toString());
};

export const getModels = async (year, make) => {
  const { data } = await axios.get(constructModelsUri(year, make));
  return data?.map(m => m.toString());
};

import { GET_JOURNEY_SUCCESS } from '../actions/journeys';
import { MAP_VERTAFORE_SUCCESS } from '../actions/quoteset';

function journey(state = {}, action) {
  switch (action.type) {
    case GET_JOURNEY_SUCCESS:
      return action.payload;
    case MAP_VERTAFORE_SUCCESS: {
      const { vertaforeUrl } = action.payload;
      return { ...state, vertaforeUrl };
    }
    default:
      return state;
  }
}

export default journey;

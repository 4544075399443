import React from 'react';
import PropTypes from 'prop-types';
import { formatMoneyWithDecimals } from 'woop-shared/quotes/utils';
import Table from '../../../../components/Table';
import styles from '../../styles.css';
import { convertPremium } from '../../utils';
import { useModalControls } from '../../../../hooks/modal-controls';
import EditPolicyDetails from '../EditPolicyDetails';
import { prettifyDateUTC } from '../../../../utils/date';

const PolicyDetails = ({ policy, refreshPolicy }) => {
  const [visible, closing, toggleModal] = useModalControls(false);
  return (
    <div>
      <div className={styles.tableHeader}>
        Policy Information{' '}
        <a className={styles.edit} onClick={() => toggleModal()}>
          Edit
        </a>
      </div>
      <Table
        spaceBetween
        className={styles.table}
        rows={[
          {
            label: 'Carrier',
            value: `${policy.carrier_name}`
          },
          {
            label: 'Effective Date',
            required: true,
            value: `${policy.effective_date ? prettifyDateUTC(policy.effective_date) : ''}`
          },
          {
            label: 'Expiration Date',
            required: true,
            value: `${policy.expiry_date ? prettifyDateUTC(policy.expiry_date) : ''}`
          },
          {
            label: 'Premium',
            required: true,
            value: `${
              policy.total_premium_cents
                ? formatMoneyWithDecimals(convertPremium(policy.total_premium_cents))
                : ''
            }`
          }
        ]}
      />
      <EditPolicyDetails
        policy={policy}
        refreshPolicy={refreshPolicy}
        visible={visible}
        closing={closing}
        toggleModal={toggleModal}
      />
    </div>
  );
};

PolicyDetails.propTypes = {
  policy: PropTypes.object,
  refreshPolicy: PropTypes.func
};

export default PolicyDetails;

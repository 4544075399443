import { createAction } from 'redux-actions';

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const addAddress = createAction(ADD_ADDRESS);

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const deleteAddress = createAction(DELETE_ADDRESS);

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const updateAddress = createAction(UPDATE_ADDRESS);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { COVERAGE_FIELDS, COVERAGE_KEYS } from 'woop-shared/quotes/enums';
import { COVERAGE_TAGS, COVERAGE_TYPES } from 'woop-shared/enums';
import { FOREIGN_KEYS, GENERAL_FIELDS, QUOTE_FIELDS } from 'woop-shared/db/models';
import styles from '../../CreateQuoteset.css';
import Modal from '../../../../components/Modal';
import Select from '../../../../components/Select';
import Button from '../../../../components/Button';
import { getCoverages } from './utils';
import { createSelectOption, createSelectOptions } from '../../../../components/Select/utils';
import MoneyField from '../../../../components/MoneyField';

const TemplateModal = ({ visible, closing, toggleModal, quote, stateAbbrev, updateQuote }) => {
  const dwelling = quote?.[QUOTE_FIELDS.COVERAGES]?.find(
    cov => cov?.[COVERAGE_KEYS.TYPE] === COVERAGE_FIELDS.DWELLING
  )?.[COVERAGE_KEYS.AMOUNT];

  const [template, setTemplate] = useState(quote?.[QUOTE_FIELDS.COVERAGE_TAG]);
  const [replacementCost, setReplacementCost] = useState(dwelling);
  const [isValid, setIsValid] = useState(false);

  const replacementCostRequired =
    quote?.[QUOTE_FIELDS.COVERAGE_TYPE] === COVERAGE_TYPES.HOME ||
    quote?.[QUOTE_FIELDS.COVERAGE_TYPE] === COVERAGE_TYPES.CONDO;

  useEffect(
    () => setIsValid(!!template && (!!replacementCost || !replacementCostRequired)),
    [template, replacementCost]
  );

  const applyTemplate = () => {
    const hasCoverages =
      quote?.[QUOTE_FIELDS.COVERAGES]?.filter(cov => !!cov?.[COVERAGE_KEYS.AMOUNT])?.length > 0;
    // If we already have coverages, ask them if they want to overwrite.
    if (hasCoverages) {
      const override = confirm(`This quote already has coverages. Do you want to overwrite them?`);
      if (!override) return;
    }

    const { coverages, additionalCoverages, deductible } = getCoverages(
      template,
      quote?.[QUOTE_FIELDS.COVERAGE_TYPE],
      quote?.[FOREIGN_KEYS.CARRIER_CODE],
      stateAbbrev,
      replacementCost
    );

    updateQuote(quote?.[GENERAL_FIELDS.ID], {
      [QUOTE_FIELDS.COVERAGES]: coverages,
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: additionalCoverages,
      [QUOTE_FIELDS.DEDUCTIBLE]: deductible
    });

    toggleModal();
  };

  return (
    <Modal visible={visible} closing={closing} toggleModal={toggleModal} className={styles.wrapper}>
      <div className={styles.modalContent}>
        <h2>Apply template</h2>
        <Select
          placeholder="Select template..."
          options={createSelectOptions(Object.values(COVERAGE_TAGS))}
          name={'quotes'}
          onChange={(_, value) => setTemplate(value)}
          value={createSelectOption(template)}
        />

        {replacementCostRequired && (
          <MoneyField
            name="replacement-cost"
            placeholder="$"
            label={`Replacement Cost`}
            value={replacementCost}
            onChange={(_, value) => setReplacementCost(value)}
          />
        )}

        <div className={styles.modalButtonWrapper}>
          <Button
            className={styles.submitPaymentButton}
            onClick={applyTemplate}
            disabled={!isValid}
          >
            Apply template
          </Button>
        </div>
      </div>
    </Modal>
  );
};

TemplateModal.propTypes = {
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  quote: PropTypes.object,
  updateQuote: PropTypes.func,
  stateAbbrev: PropTypes.string
};

export default TemplateModal;

import { combineReducers } from 'redux';
import { UI_ADDRESS_FIELDS } from 'woop-shared/enums';
import { SET_JOURNEY_SUMMARY } from '../actions/journey-summary';
import { getStateFromCompositeAddress } from '../utils/quoteset';

function firstName(state = null, action) {
  switch (action.type) {
    case SET_JOURNEY_SUMMARY:
      return action.payload.consumerInfo?.firstName || state;
    default:
      return state;
  }
}

function lastName(state = null, action) {
  switch (action.type) {
    case SET_JOURNEY_SUMMARY:
      return action.payload.consumerInfo?.lastName || state;
    default:
      return state;
  }
}

function coverageTypes(state = [], action) {
  switch (action.type) {
    case SET_JOURNEY_SUMMARY: {
      return action.payload.coverageTypes?.map(covType => covType?.toUpperCase()) || state;
    }
    default:
      return state;
  }
}

function stateAbbrev(state = null, action) {
  switch (action.type) {
    case SET_JOURNEY_SUMMARY: {
      const address = action.payload.consumerInfo?.address;
      const stateAbbrev = address?.[UI_ADDRESS_FIELDS.STATE];
      if (stateAbbrev) return stateAbbrev;

      // Handling the case where the user force enters an address and we only have a composite.
      const fromComposite = getStateFromCompositeAddress(address?.[UI_ADDRESS_FIELDS.COMPOSITE]);
      return fromComposite || state;
    }
    default:
      return state;
  }
}

function originationId(state = null, action) {
  switch (action.type) {
    case SET_JOURNEY_SUMMARY: {
      return action.payload?.originationId || state;
    }
    default:
      return state;
  }
}

function journeyId(state = null, action) {
  switch (action.type) {
    case SET_JOURNEY_SUMMARY: {
      return action.payload?.journeyId || state;
    }
    default:
      return state;
  }
}

export default combineReducers({
  firstName,
  lastName,
  coverageTypes,
  stateAbbrev,
  originationId,
  journeyId
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FINALIZATION_TYPES } from 'woop-shared/origination/enums';
import { formatMoneyWithDecimals } from 'woop-shared/quotes/utils';
import styles from './FinalizeOrigination.css';
import Button from '../Button';
import { APPLICANT_FIELDS } from '../../constants/origination';
import RadioGroup from '../RadioGroup';
import Select from '../Select';
import { FIELD, TYPE_OPTIONS, TEST_COMMUNICATION_OPTIONS } from './constants';
import TestCommunication from './TestCommunication';
import RealCommunication from './RealCommunication';
import CanImprove from './CanImprove';
import { useModalControls } from '../../hooks/modal-controls';
import Modal from '../Modal';
import ErrorText from '../ErrorText';
import { createSelectOption } from '../Select/utils';

const getButtonText = (test, type) => {
  if (test) return `Send Test Communication`;
  if (type === FINALIZATION_TYPES.CONVERT_TO_QUOTE) return 'Finalize →';
  return `Finalize and Send Notifications →`;
};

const FinalizeOrigination = ({
  originator,
  primaryApplicant = {},
  loading,
  error,
  validateOrigination,
  finalizeOrigination,
  convertToQuoteset
}) => {
  const [visible, closing, toggleModal] = useModalControls(false);
  const [fields, setFields] = useState({});
  const isConvertToQuote = fields[FIELD.TYPE] === FINALIZATION_TYPES.CONVERT_TO_QUOTE;

  const applicantEmail = primaryApplicant[APPLICANT_FIELDS.EMAIL];
  const originatorEmail = originator?.email;

  const handleChange = (name, val) => {
    setFields({ ...fields, [name]: val });
  };

  const handleToggleModal = () => {
    toggleModal();
    if (closing) {
      setFields({});
    }
  };

  const handleButtonClick = () => {
    validateOrigination({ callback: handleToggleModal });
  };

  const handleClick = () => {
    const email = fields[FIELD.TEST]
      ? fields[FIELD.TEST_EMAIL]
      : fields[FIELD.EMAIL_APPLICANT]
      ? applicantEmail
      : undefined;

    const payload = {
      email,
      originatorEmail: fields[FIELD.EMAIL_ORIGINATOR] ? originatorEmail : undefined,
      emailData: {
        [FIELD.SAVINGS]: formatMoneyWithDecimals(fields[FIELD.SAVINGS]),
        [FIELD.CARRIERS]: fields[FIELD.CARRIERS]
      },
      test: fields[FIELD.TEST],
      type: fields[FIELD.TYPE],
      callback: handleToggleModal
    };

    if (isConvertToQuote) {
      convertToQuoteset({ callback: handleToggleModal });
    } else finalizeOrigination(payload);
  };

  // Valid if we have a communication type, test flag is T/F, and at least one email to send to.
  const valid =
    fields[FIELD.TYPE] &&
    ((fields[FIELD.TEST] === true && fields[FIELD.TEST_EMAIL]) ||
      (fields[FIELD.TEST] === false &&
        (fields[FIELD.EMAIL_APPLICANT] || fields[FIELD.EMAIL_ORIGINATOR])));

  return (
    <>
      <Button onClick={handleButtonClick} loading={loading} className={styles.button}>
        Finalize →
      </Button>
      {visible && (
        <Modal visible={visible} closing={closing} toggleModal={handleToggleModal}>
          <div className={styles.header}>
            <h2>Finalize origination</h2>
          </div>

          <div className={styles.fieldWrapper}>
            <Select
              options={TYPE_OPTIONS}
              onChange={handleChange}
              prompt={'Finalization type'}
              name={FIELD.TYPE}
              isValid={true}
              value={createSelectOption(fields[FIELD.TYPE])}
            />

            {fields[FIELD.TYPE] === FINALIZATION_TYPES.CAN_IMPROVE && (
              <CanImprove onChange={handleChange} savings={fields[FIELD.SAVINGS]} />
            )}

            {!isConvertToQuote && (
              <>
                <RadioGroup
                  name={FIELD.TEST}
                  value={fields[FIELD.TEST]}
                  onChange={handleChange}
                  options={TEST_COMMUNICATION_OPTIONS}
                />
                {fields[FIELD.TEST] && (
                  <TestCommunication onChange={handleChange} fields={fields} />
                )}

                {fields[FIELD.TEST] === false && (
                  <RealCommunication
                    onChange={handleChange}
                    fields={fields}
                    applicantEmail={applicantEmail}
                    originatorEmail={originatorEmail}
                  />
                )}
              </>
            )}
          </div>

          <Button
            className={styles.button}
            loading={loading}
            onClick={handleClick}
            disabled={!valid && !isConvertToQuote}
          >
            {getButtonText(fields[FIELD.TEST], fields[FIELD.TYPE])}
          </Button>
          <div className={styles.error}>
            <ErrorText>{error?.message}</ErrorText>
          </div>
        </Modal>
      )}
    </>
  );
};

FinalizeOrigination.propTypes = {
  originator: PropTypes.object,
  validateOrigination: PropTypes.func,
  finalizeOrigination: PropTypes.func,
  loading: PropTypes.bool,
  primaryApplicant: PropTypes.object,
  error: PropTypes.object,
  convertToQuoteset: PropTypes.func
};

export default FinalizeOrigination;

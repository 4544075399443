import { ADDRESS_FIELDS, ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { UPDATE_ADDRESS, ADD_ADDRESS, DELETE_ADDRESS } from '../actions/addresses';
import { GET_ORIGINATION_SUCCESS } from '../actions/originations';

function addresses(state = {}, action) {
  switch (action.type) {
    case ADD_ADDRESS: {
      const { payload } = action;
      return {
        ...state,
        [payload[ADDRESS_FIELDS.ADDRESS_TAG]]: payload
      };
    }
    case DELETE_ADDRESS: {
      const { payload: id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case UPDATE_ADDRESS: {
      const { id, updateObj } = action.payload;
      const addressToUpdate = state[id];
      return { ...state, [id]: { ...addressToUpdate, ...updateObj } };
    }
    case GET_ORIGINATION_SUCCESS: {
      const addresses = action.payload?.originationData?.[ORIGINATION_FIELDS.ADDRESSES] || [];
      const addressesWithTags = {};
      addresses?.forEach(addr => {
        addressesWithTags[addr[ADDRESS_FIELDS.ADDRESS_TAG]] = addr;
      });
      return addressesWithTags;
    }
    default:
      return state;
  }
}

export default addresses;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { GENDER, MARITAL_STATUSES } from 'woop-shared/origination/enums';
import styles from '../../styles.css';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import TextField from '../../../../components/TextField';
import DateField from '../../../../components/DateField';
import Select from '../../../../components/Select';
import { createOptionsFromEnum, createSelectOption } from '../../../../components/Select/utils';
import { formatDateInputValue } from '../../../../components/DateField/utils';
import { patchDriver, postDriver } from '../../../../api/policy';

const MARITAL_STATUS_OPTIONS = createOptionsFromEnum(MARITAL_STATUSES);
const GENDER_OPTIONS = createOptionsFromEnum(GENDER);

const EditDriver = ({ policy, driver, visible, closing, toggleModal, refreshPolicy }) => {
  const [loading, setLoading] = useState();

  const [firstName, setFirstName] = useState(driver?.first_name);
  const [lastName, setLastName] = useState(driver?.last_name);
  const [dob, setDob] = useState(
    driver?.date_of_birth_str && formatDateInputValue(driver.date_of_birth_str)
  );
  const [maritalStatus, setMaritalStatus] = useState(driver?.marital_status);
  const [gender, setGender] = useState(driver?.gender);

  const handleSetMaritalStatus = (_, value) => setMaritalStatus(value);
  const handleSetGender = (_, value) => setGender(value);

  useEffect(() => {
    setFirstName(driver?.first_name);
    setLastName(driver?.last_name);
    setDob(driver?.date_of_birth_str && formatDateInputValue(driver.date_of_birth_str));
    setMaritalStatus(driver?.marital_status);
    setGender(driver?.gender);
  }, [driver]);

  const clearFields = () =>
    [setFirstName, setLastName, setDob, setMaritalStatus, setGender].forEach(f => f());

  const save = () => {
    setLoading(true);

    const updates = {
      first_name: firstName,
      last_name: lastName,
      date_of_birth_str: dob && dayjs(dob).format('MM/DD/YYYY'),
      marital_status: maritalStatus || null,
      gender
    };

    if (!driver) {
      postDriver({
        policyId: policy.policy_id,
        updates
      })
        .then(() => refreshPolicy())
        .then(() => {
          setLoading(false);
          toggleModal();
          clearFields();
        });
    } else {
      patchDriver({
        policyId: policy.policy_id,
        driverId: driver.driver_id,
        updates
      })
        .then(() => refreshPolicy())
        .then(() => {
          setLoading(false);
          toggleModal();
          clearFields();
        });
    }
  };

  return (
    visible && (
      <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
        <div>{driver ? 'Edit' : 'Create'} Driver</div>
        <div className={styles.form}>
          <TextField
            name={'firstName'}
            label={'First Name'}
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <TextField
            name={'lastName'}
            label={'Last Name'}
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
          <DateField
            name={'dob'}
            prompt={'Date of Birth'}
            onChange={e => setDob(e.target.value)}
            value={dob}
          />
          <Select
            prompt={'Marital Status'}
            name={'maritalStatus'}
            placeholder={'Marital Status'}
            value={createSelectOption(maritalStatus)}
            options={MARITAL_STATUS_OPTIONS}
            onChange={handleSetMaritalStatus}
          />
          <Select
            prompt={'Gender'}
            name={'gender'}
            placeholder={'Gender'}
            value={createSelectOption(gender)}
            options={GENDER_OPTIONS}
            onChange={handleSetGender}
          />
        </div>
        <Button className={styles.modalButton} loading={loading} onClick={save}>
          Save
        </Button>
      </Modal>
    )
  );
};

EditDriver.propTypes = {
  policy: PropTypes.object,
  driver: PropTypes.object,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  refreshPolicy: PropTypes.func
};

export default EditDriver;

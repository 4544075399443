import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles.css';
import Button from '../../../../../components/Button';
import { saveJourney } from '../../../../../actions/journeys';
import { getUiDataUpdates } from '../utils';
import { syncQuoteset } from '../../../../../actions/quoteset';

const Buttons = ({ uiData, journeyId, updates, setEdit }) => {
  const quotesetId = useSelector(state => state.journey?.quoteset?._id);
  const dispatch = useDispatch();
  const cancel = () => setEdit(false);
  const save = () => {
    const callback = () => {
      const refresh = () => window.location.reload(true);
      if (quotesetId) dispatch(syncQuoteset({ quotesetId, callback: refresh }));
      else refresh();
    };
    const _uiData = getUiDataUpdates(uiData, updates);
    dispatch(saveJourney({ journeyId, uiData: _uiData, callback }));
  };

  return (
    <div className={styles.wrapper}>
      <Button className={styles.save} onClick={save}>
        Save
      </Button>
      <Button className={styles.cancel} onClick={cancel}>
        Cancel
      </Button>
    </div>
  );
};

Buttons.propTypes = {
  uiData: PropTypes.object,
  journeyId: PropTypes.string,
  updates: PropTypes.object,
  setEdit: PropTypes.func
};

export default Buttons;

export const YES_OPTION = {
  label: 'Yes',
  value: 'Yes'
};

export const NO_OPTION = {
  label: 'No',
  value: 'No'
};

export const YES_NO_UNKNOWN_OPTIONS = [
  YES_OPTION,
  NO_OPTION,
  {
    label: 'Unknown',
    value: 'Unknown'
  }
];

export const POOL_TYPE_OPTIONS = [
  { label: 'Inground', value: 'Inground' },
  { label: 'Above Ground', value: 'Above Ground' }
];

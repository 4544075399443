// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__wrapper--3WeJT{margin-top:1rem;flex-direction:column}.styles-module__wrapper--3WeJT,.styles-module__wrapper--3WeJT .styles-module__header--1ouFM{display:flex;grid-gap:.5rem;gap:.5rem}.styles-module__wrapper--3WeJT .styles-module__header--1ouFM{align-items:center;font-size:var(--fs-sm)}.styles-module__wrapper--3WeJT .styles-module__header--1ouFM p{margin:0}.styles-module__wrapper--3WeJT .styles-module__row--SgtYK{width:100%;display:flex;align-items:center;grid-gap:.5rem;gap:.5rem}.styles-module__wrapper--3WeJT .styles-module__row--SgtYK>div:first-of-type{flex:1}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles-module__wrapper--3WeJT",
	"header": "styles-module__header--1ouFM",
	"row": "styles-module__row--SgtYK"
};
module.exports = exports;

import { combineReducers } from 'redux';
import { SET_ERROR } from '../actions/error';
import { SET_QUOTESET, FINALIZE_QUOTESET_SUCCESS } from '../actions/quoteset';
import { GET_CARRIERS_SUCCESS } from '../actions/carriers';
import { FINALIZE_ORIGINATION_SUCCESS, POST_ORIGINATION_SUCCESS } from '../actions/originations';
import { GET_PARTNERS_SUCCESS, GET_PARTNER_SUCCESS } from '../actions/partners';
import { GET_ORIGINATORS_SUCCESS } from '../actions/originators';
import { APPLICANTS_FOUND } from '../actions/journeys';

function message(state = null, action) {
  switch (action.type) {
    case SET_ERROR:
      return action.payload?.message || null;
    case SET_QUOTESET:
    case FINALIZE_QUOTESET_SUCCESS:
    case GET_CARRIERS_SUCCESS:
    case FINALIZE_ORIGINATION_SUCCESS:
    case POST_ORIGINATION_SUCCESS:
    case GET_PARTNERS_SUCCESS:
    case GET_PARTNER_SUCCESS:
    case GET_ORIGINATORS_SUCCESS:
    case APPLICANTS_FOUND:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  message
});

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineXCircle } from 'react-icons/hi';
import { QUOTE_REQUEST_FIELDS } from 'woop-shared/db/models';
import styles from './styles.module.css';
import { setQuoteRequest } from '../../../../actions/quote-request';
import Prompt from '../../../../components/Prompt';
import LinkText from '../../../../components/LinkText';
import { getQuoteRequestLabel } from '../../utils';
import Select from '../../../../components/Select';
import BadgeButton from '../../../../components/BadgeButton';
import { createMultiSelectValue } from '../../../../components/Select/utils';

const BundleManager = () => {
  const dispatch = useDispatch();
  const { quoteRequest } = useSelector(state => state);

  const updateBundle = index => (_, val) => {
    const update = [...quoteRequest[QUOTE_REQUEST_FIELDS.BUNDLED_REQUESTS]];
    val ? update.splice(index, 1, val) : update.splice(index, 1); // Update or delete
    dispatch(setQuoteRequest({ ...quoteRequest, bundledRequests: update }));
  };

  const addBundle = () => {
    const update = [...quoteRequest[QUOTE_REQUEST_FIELDS.BUNDLED_REQUESTS]];
    update.push([]);
    dispatch(setQuoteRequest({ ...quoteRequest, bundledRequests: update }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Prompt>
          Bundles<LinkText onClick={addBundle}>Add +</LinkText>
        </Prompt>
      </div>

      {quoteRequest?.[QUOTE_REQUEST_FIELDS.BUNDLED_REQUESTS]?.map((requestIds, index) => {
        const options = quoteRequest[QUOTE_REQUEST_FIELDS.REQUESTS]
          .filter(req => !req[QUOTE_REQUEST_FIELDS.SKIP])
          .map(r => {
            return {
              label: getQuoteRequestLabel(r),
              value: r.id
            };
          });

        return (
          <div key={index} className={styles.row}>
            <Select
              isMulti={true}
              options={options}
              onChange={updateBundle(index)}
              isOptionDisabled={() => requestIds.length >= 3}
              value={createMultiSelectValue(requestIds, options)}
              isClearable={false}
            ></Select>

            <BadgeButton color={'red'} onClick={updateBundle(index)}>
              <HiOutlineXCircle />
            </BadgeButton>
          </div>
        );
      })}
    </div>
  );
};

export default BundleManager;

import { connect } from 'react-redux';
import ProcessPaymentModal from './';
import { processPayment } from '../../../actions/payments';

const mapStateToProps = ({ loading }) => {
  return {
    loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    processPayment: payload => dispatch(processPayment(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessPaymentModal);

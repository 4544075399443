const { createAction } = require('redux-actions');

export const SET_QUOTE_REQUEST = 'SET_QUOTE_REQUEST';
export const setQuoteRequest = createAction(SET_QUOTE_REQUEST);

/**
 * Accepts a subset of updated quoteRequest.requests
 */
export const UPDATE_REQUESTS = 'UPDATE_REQUESTS';
export const updateRequests = createAction(UPDATE_REQUESTS);

export function prettifyDatetime(dateString) {
  return new Date(dateString).toLocaleString();
}

export function prettifyDateUTC(dateString) {
  return new Date(dateString).toLocaleDateString('en-US', {
    timeZone: 'UTC'
  });
}

export function prettifyDate(dateString) {
  const str = prettifyDatetime(dateString);
  const endOfDate = str.indexOf(',');
  return str.slice(0, endOfDate);
}

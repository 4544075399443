import React, { useState } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { ADDRESS_TYPES } from 'woop-shared/origination/enums';
import { ADDRESS_FIELDS } from 'woop-shared/origination/fields';
import { formatAddress, getPrimaryAddress } from 'woop-shared/origination/utils';
import PropTypes from 'prop-types';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';
import { createOptionsFromEnum, createSelectOption } from '../../../components/Select/utils';
import AddressField from '../../../components/AddressField';

const addressTypeOptions = createOptionsFromEnum(ADDRESS_TYPES);

const AddressForm = ({
  entity,
  onChange,
  onReplace,
  validationState,
  context: { quoteApplication }
}) => {
  const address = entity || {};
  const showValidation = validationState.allValid === false;
  const [showManualFields, setShowManualFields] = useState(false);
  const primaryAddress = getPrimaryAddress(quoteApplication);

  const handleInput = event => {
    const update = {
      ...address,
      [event.target.name]: event.target.value
    };
    onReplace({ ...update, [ADDRESS_FIELDS.COMPOSITE]: formatAddress(update) });
  };

  return (
    <>
      <Select
        autoFocus
        name={ADDRESS_FIELDS.ADDRESS_TYPE}
        prompt="Address Type"
        onChange={onChange}
        value={createSelectOption(address[ADDRESS_FIELDS.ADDRESS_TYPE])}
        options={addressTypeOptions.filter(option =>
          primaryAddress && option.value === ADDRESS_TYPES.INSURE ? false : true
        )}
        showValidation={showValidation}
        isValid={!!address[ADDRESS_FIELDS.ADDRESS_TYPE]}
      />
      <AddressField
        prompt="Address"
        onChange={onReplace}
        value={
          address[ADDRESS_FIELDS.COMPOSITE] && createSelectOption(address[ADDRESS_FIELDS.COMPOSITE])
        }
        showValidation={showValidation}
        isValid={!!address[ADDRESS_FIELDS.COMPOSITE]}
      />

      {showManualFields && (
        <>
          <TextField
            name={ADDRESS_FIELDS.STREET_NUMBER}
            label="Street Number"
            onChange={handleInput}
            value={address[ADDRESS_FIELDS.STREET_NUMBER]}
            showValidation={showValidation}
            isValid={!!address[ADDRESS_FIELDS.STREET_NUMBER]}
          />
          <TextField
            name={ADDRESS_FIELDS.STREET_NAME}
            label="Street"
            onChange={handleInput}
            value={address[ADDRESS_FIELDS.STREET_NAME]}
            showValidation={showValidation}
            isValid={!!address[ADDRESS_FIELDS.STREET_NAME]}
          />
          <TextField
            name={ADDRESS_FIELDS.MUNICIPALITY_LEVEL3}
            label="City"
            onChange={handleInput}
            value={address[ADDRESS_FIELDS.MUNICIPALITY_LEVEL3]}
            showValidation={showValidation}
            isValid={!!address[ADDRESS_FIELDS.MUNICIPALITY_LEVEL3]}
          />
          <TextField
            name={ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE}
            label="State Abbreviation"
            maxLength={2}
            onChange={handleInput}
            value={address[ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE]}
            showValidation={showValidation}
            isValid={!!address[ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE]}
          />
          <TextField
            name={ADDRESS_FIELDS.POSTAL_CODE}
            label="Zip code"
            maxLength={5}
            onChange={handleInput}
            value={address[ADDRESS_FIELDS.POSTAL_CODE]}
            showValidation={showValidation}
            isValid={!!address[ADDRESS_FIELDS.POSTAL_CODE]}
          />
        </>
      )}

      <TextField
        name={ADDRESS_FIELDS.UNIT_NUMBER}
        label="Unit Number"
        onChange={handleInput}
        value={address[ADDRESS_FIELDS.UNIT_NUMBER]}
      />

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={showManualFields}
              onChange={e => setShowManualFields(e.target.checked)}
            />
          }
          label="Show all fields"
        />
      </FormGroup>
    </>
  );
};

AddressForm.propTypes = {
  context: PropTypes.object,
  entity: PropTypes.object,
  onChange: PropTypes.func,
  onReplace: PropTypes.func,
  onInput: PropTypes.func,
  validationState: PropTypes.object
};

export default AddressForm;

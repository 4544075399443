import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './styles.css';
import Modal from '../../../../components/Modal';
import LinkText from '../../../../components/LinkText';

const VertaforeModal = ({ visible, closing, toggleModal }) => {
  const vertaforeUrl = useSelector(state => state.journey?.vertaforeUrl);
  return (
    <Modal visible={visible} closing={closing} toggleModal={toggleModal} className={styles.wrapper}>
      <div>
        <h2>Vertafore Mapping URL</h2>
        <LinkText href={vertaforeUrl} external>
          {vertaforeUrl}
        </LinkText>
      </div>
    </Modal>
  );
};

VertaforeModal.propTypes = {
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func
};

export default VertaforeModal;

import { formatMoneyWithDecimals, stripNonFloatChars } from 'woop-shared/quotes/utils';
import { QUOTE_FIELDS } from 'woop-shared/db/models';
import { COVERAGE_KEYS, ADDITIONAL_COVERAGES } from 'woop-shared/quotes/enums';
import {
  CARRIER_COVERAGE_TEMPLATES,
  COVERAGE_TEMPLATES,
  STATE_COVERAGE_TEMPLATES,
  GENERIC_WIND_HAIL_HURRICANE_LABEL,
  WIND_HURRICANE_HAIL_MAP
} from '../constants/coverage-templates';
import { STATE_COVERAGE_SETS } from '../components/CoverageFields/constants';

export function getCoverageTemplate(covType, usState, covTag, carrierCode) {
  const defaultTemplate = getDefaultCoverageTemplate(covType, covTag);
  const stateTemplate = getStateCoverageTemplate(covType, usState, covTag) || {};
  const carrierTemplate = getCarrierCoverageTemplate(covType, carrierCode, covTag) || {};

  let coverages = {
    ...defaultTemplate[QUOTE_FIELDS.COVERAGES],
    ...stateTemplate[QUOTE_FIELDS.COVERAGES],
    ...carrierTemplate[QUOTE_FIELDS.COVERAGES]
  };

  const stateCoverages = STATE_COVERAGE_SETS[usState]?.[covType];
  if (stateCoverages) {
    Object.keys(coverages).forEach(coverage => {
      if (!stateCoverages.includes(coverage)) {
        delete coverages[coverage];
      }
    });
  }

  const additionalCoverages = handleTowingAndLabor(
    replaceStateSpecificHurricaneHailLabel(
      mergeAdditionalCoverages(
        defaultTemplate[QUOTE_FIELDS.ADDITIONAL_COVERAGES],
        stateTemplate[QUOTE_FIELDS.ADDITIONAL_COVERAGES],
        carrierTemplate[QUOTE_FIELDS.ADDITIONAL_COVERAGES]
      ),
      usState
    )
  );

  const mergedTemplate = {
    [QUOTE_FIELDS.DEDUCTIBLE]:
      carrierTemplate[QUOTE_FIELDS.DEDUCTIBLE] ||
      stateTemplate[QUOTE_FIELDS.DEDUCTIBLE] ||
      defaultTemplate[QUOTE_FIELDS.DEDUCTIBLE],
    [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: additionalCoverages,
    [QUOTE_FIELDS.COVERAGES]: coverages
  };

  return mergedTemplate;
}

export function getDefaultCoverageTemplate(coverageType, coverageTag) {
  return COVERAGE_TEMPLATES?.[coverageType]?.[coverageTag];
}

export function getStateCoverageTemplate(coverageType, usState, coverageTag) {
  const template = STATE_COVERAGE_TEMPLATES[coverageType] || [];
  return template.find(t => t?.states?.includes(usState))?.[coverageTag] || {};
}

function getCarrierCoverageTemplate(coverageType, carrier, coverageTag) {
  return CARRIER_COVERAGE_TEMPLATES?.[coverageType]?.[carrier]?.[coverageTag];
}

export function mergeAdditionalCoverages(
  defaultCoverages = [],
  stateCoverages = [],
  carrierCoverages = []
) {
  // Combine all the coverages. In reverse priority order
  const allCoverages = [...defaultCoverages, ...stateCoverages, ...carrierCoverages];
  const coverageMap = {};
  allCoverages.forEach(cov => (coverageMap[cov.label] = cov.value));
  return Object.keys(coverageMap).map(label => ({ label, value: coverageMap[label] }));
}

export function replaceStateSpecificHurricaneHailLabel(additionalCoverages, state) {
  return additionalCoverages.map(({ label, value }) => {
    if (WIND_HURRICANE_HAIL_MAP[state] && label === GENERIC_WIND_HAIL_HURRICANE_LABEL) {
      return {
        label: WIND_HURRICANE_HAIL_MAP[state],
        value
      };
    }
    return { label, value };
  });
}

/**
 * Remove redundant Towing & Labor entries.
 *
 * @param {Array<object>} additionalCoverages array of additional coverage objects.
 * @returns {Array<object>} additionalCoverages, maybe filtered.
 */
function handleTowingAndLabor(additionalCoverages) {
  const hasRoadsideAssistance = additionalCoverages.find(
    ({ label }) => label === ADDITIONAL_COVERAGES.ROADSIDE_ASSISTANCE
  );
  if (hasRoadsideAssistance) {
    return additionalCoverages.filter(
      ({ label }) => label !== ADDITIONAL_COVERAGES.TOWING_AND_LABOR
    );
  }
  return additionalCoverages;
}

/**
 * Format additional coverages for a quote.
 *
 * @param {Array} templateCoverages An array of additional coverages, from the template.
 * @param {number} replacementCost The replacement cost.
 * @returns {Array} Array of additional coverages, formatted.
 */
export function formatAdditionalCoverages(templateCoverages, replacementCost) {
  return templateCoverages.map(({ label, value }) => ({
    [COVERAGE_KEYS.TYPE]: label,
    [COVERAGE_KEYS.AMOUNT]: maybeFormatMoneyWithDecimals(calculateAmount(value, replacementCost))
  }));
}

/**
 * Format coverages for a quote.
 *
 * @param {object} templateCoverages An object of coverages, from the template.
 * @param {number} replacementCost The replacement cost.
 * @returns {object} Coverages object, formatted.
 */
export function formatCoverages(templateCoverages, replacementCost) {
  const mappedCoverages = Object.entries(templateCoverages).map(([key, amount]) => {
    return {
      [COVERAGE_KEYS.TYPE]: key,
      [COVERAGE_KEYS.AMOUNT]: calculateAmount(amount, replacementCost)
    };
  });
  return mappedCoverages;
}

/**
 * Calculate a coverage amount given a replacement cost.
 *
 * @param {string|number} amount String if a percentage, e.g. '1%', otherwise a number.
 * @param {number} replacementCost The replacement cost.
 * @returns {string|number} String if formatMoney is true, otherwise a number
 */
export function calculateAmount(amount, replacementCost) {
  if (!replacementCost) return amount;
  const isPercentage = typeof amount === 'string' && amount.includes('%');
  if (isPercentage) {
    return Math.round(replacementCost * 0.01 * stripNonFloatChars(amount));
  }
  return amount;
}

function maybeFormatMoneyWithDecimals(amount) {
  if (typeof amount === 'string') return amount;
  return formatMoneyWithDecimals(amount);
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './ApplicantsView.css';
import ResultCard from './components/ResultCard';

const cx = classnames.bind(styles);

const ApplicantsView = ({ journeys, loading, searchApplicants }) => {
  useEffect(() => {
    if (journeys?.length > 0) return;
    let searchCriteria = {};
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.forEach((val, key) => (searchCriteria[key] = val));
    searchApplicants(searchCriteria);
  }, []);

  return (
    <section className={cx('wrapper', { loading })}>
      <div>{loading ? `Loading` : journeys.length} Results</div>

      {journeys.map(({ journeyId, uiData, createdAt, lastUpdatedAt }) => {
        return (
          <ResultCard
            key={journeyId}
            journeyId={journeyId}
            uiData={uiData}
            createdAt={createdAt}
            lastUpdatedAt={lastUpdatedAt}
          />
        );
      })}
    </section>
  );
};

ApplicantsView.propTypes = {
  journeys: PropTypes.array,
  loading: PropTypes.bool,
  searchApplicants: PropTypes.func
};

export default ApplicantsView;

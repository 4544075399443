import { GENERAL_FIELDS } from 'woop-shared/db/models';
import { SET_QUOTESET, SET_QUOTESET_FIELD } from '../actions/quoteset';
import { translateQuotesetStatus } from '../utils/quoteset';

function quoteset(state = {}, action) {
  switch (action.type) {
    case SET_QUOTESET: {
      const { status, supplementaryInfo, agentNotes, finalization, fk_user, fk_agent } =
        action.payload;
      const id = action.payload[GENERAL_FIELDS.ID];
      return {
        agentNotes,
        status: translateQuotesetStatus(status),
        [GENERAL_FIELDS.ID]: id,
        supplementaryInfo,
        finalization,
        fk_user,
        fk_agent
      };
    }
    case SET_QUOTESET_FIELD: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
}

export default quoteset;

import { US_STATES } from 'woop-shared/data/states';
import { PAYMENT_PLANS, PREMIUM_FIELDS } from 'woop-shared/quotes/enums';
import { formatMoney, getPaidInFullPremium } from 'woop-shared/quotes/utils';

export function translateQuotesetStatus(status) {
  return status?.toString().replace('_', ' ').toLowerCase();
}

/**
 * Look for a state abbreviation in a composite address string.
 *
 * @param {string} composite An address string, e.g. 22 E Oxford St, Philadelphia, PA, 19125
 * @returns {string} The state abbreviation.
 */
export function getStateFromCompositeAddress(composite) {
  return US_STATES.map(state => state.value).find(abbrev => {
    const searchTerm = ` ${abbrev}, `; // A state abbrev is always followed by a comma.
    return composite?.includes(searchTerm);
  });
}

export function formatPremium(premiums) {
  const premium = getPaidInFullPremium(premiums);
  if (!premium?.[PREMIUM_FIELDS.PAYMENT_PLAN] || !premium?.[PREMIUM_FIELDS.AMOUNT])
    return 'No paid-in-full premium';
  switch (premium[PREMIUM_FIELDS.PAYMENT_PLAN]) {
    case PAYMENT_PLANS.ANNUALLY:
      return `${formatMoney(premium[PREMIUM_FIELDS.AMOUNT])} / year`;
    case PAYMENT_PLANS.SEMI_ANNUALLY:
      return `${formatMoney(premium[PREMIUM_FIELDS.AMOUNT])} / 6 months`;
    default:
      return 'No paid-in-full premium';
  }
}

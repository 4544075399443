import { takeLatest, put } from 'redux-saga/effects';
import { getUser } from '../api/users';
import { error } from '../utils/logger';
import { setLoading } from '../actions/loading';
import { setError } from '../actions/error';
import { getUserSuccess, GET_USER } from '../actions/users';

function* handleGetUser(action) {
  const id = action.payload;
  yield put(setLoading(true));
  try {
    const data = yield getUser(id);
    yield put(getUserSuccess(data));
  } catch (e) {
    yield put(setError({ message: `Couldn't find that user! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchGetUser() {
  yield takeLatest(GET_USER, handleGetUser);
}

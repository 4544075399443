import React from 'react';
import PropTypes from 'prop-types';
import { HiExclamationCircle } from 'react-icons/hi';
import styles from './styles.css';

const MaybeError = ({ isValid, errorText, children }) =>
  isValid ? (
    <>{children}</>
  ) : (
    <div className={styles.wrapper}>
      <HiExclamationCircle className={styles.icon} />
      {errorText}
    </div>
  );

MaybeError.propTypes = {
  children: PropTypes.any,
  errorText: PropTypes.string,
  isValid: PropTypes.bool.isRequired
};

export default MaybeError;

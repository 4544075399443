import { createAction } from 'redux-actions';

export const GET_QUOTESET = 'GET_QUOTESET';
export const getQuoteset = createAction(GET_QUOTESET);

export const SET_QUOTESET = 'SET_QUOTESET';
export const setQuoteset = createAction(SET_QUOTESET);

export const FINALIZE_QUOTESET = 'FINALIZE_QUOTESET';
export const finalizeQuoteset = createAction(FINALIZE_QUOTESET);

export const FINALIZE_QUOTESET_SUCCESS = 'FINALIZE_QUOTESET_SUCCESS';
export const finalizeQuotesetSuccess = createAction(FINALIZE_QUOTESET_SUCCESS);

export const SAVE_QUOTESET = 'SAVE_QUOTESET';
export const saveQuoteset = createAction(SAVE_QUOTESET);

export const SET_QUOTESET_FIELD = 'SET_QUOTESET_FIELD';
export const setQuotesetField = createAction(SET_QUOTESET_FIELD);

export const QA_QUOTESET = 'QA_QUOTESET';
export const qaQuoteset = createAction(QA_QUOTESET);

export const QA_QUOTESET_SUCCESS = 'QA_QUOTESET_SUCCESS';
export const qaQuotesetSuccess = createAction(QA_QUOTESET_SUCCESS);

export const UPLOAD_QUOTES = 'UPLOAD_QUOTES';
export const uploadQuotes = createAction(UPLOAD_QUOTES);

export const SYNC_QUOTESET = 'SYNC_QUOTESET';
export const syncQuoteset = createAction(SYNC_QUOTESET);

export const MAP_VERTAFORE = 'MAP_VERTAFORE';
export const mapVertafore = createAction(MAP_VERTAFORE);

export const MAP_VERTAFORE_SUCCESS = 'MAP_VERTAFORE_SUCCESS';
export const mapVertaforeSuccess = createAction(MAP_VERTAFORE_SUCCESS);

export const POST_QUOTESET = 'POST_QUOTESET';
export const postQuoteset = createAction(POST_QUOTESET);

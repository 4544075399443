import { connect } from 'react-redux';
import Vehicles from '.';
import { addVehicle, deleteVehicle, updateVehicle } from '../../actions/vehicles';
import { createAddressOptions, createDriverOptions } from '../../utils/options';

const mapStateToProps = ({ vehicles, addresses, applicants }) => {
  return {
    vehicles: Object.values(vehicles),
    addressTags: createAddressOptions(addresses),
    driverTags: createDriverOptions(applicants)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addVehicle: vehicle => dispatch(addVehicle(vehicle)),
    deleteVehicle: id => dispatch(deleteVehicle(id)),
    updateVehicle: (id, updateObj) => dispatch(updateVehicle({ id, updateObj }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);

import PropTypes from 'prop-types';
import React from 'react';
import { PREMIUM_FIELDS } from 'woop-shared/quotes/enums';
import { formatMoney } from 'woop-shared/quotes/utils';
import { prettifyDate, prettifyDatetime } from '../../utils/date';
import Button from '../Button';
import Card from '../Card';
import ErrorText from '../ErrorText';
import styles from './PaymentCard.css';
import CardLabel from '../CardLabel';
import ProcessPaymentModal from './ProcessPaymentModal/container';

function getQuoteSummaryText({ carrierCode, coverageType, coverageTag }) {
  return [carrierCode, coverageType, coverageTag].filter(Boolean).join(', ');
}

const PaymentCard = ({ payment, failPayment, loading, error }) => {
  const { premium, quote } = payment;
  const shouldDisable = !!payment.processedDate;
  return (
    <Card className={styles.wrapper}>
      <CardLabel className={styles.cardLabel}>{payment.status || 'No Status'}</CardLabel>
      <div className={styles.cardLabel}></div>
      <div className={styles.heading}>{payment.label}</div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div>Quote</div>
          <div>{getQuoteSummaryText(quote)}</div>
        </div>
        <div className={styles.row}>
          <div>Effective Date</div>
          <div>{quote.effectiveDate ? prettifyDate(quote.effectiveDate) : null}</div>
        </div>
        <div className={styles.row}>
          <div>Payment Method</div>
          <div>{payment.method}</div>
        </div>
        <div className={styles.row}>
          <div>Premium</div>
          <div>{formatMoney(premium.amount)}</div>
        </div>
        <div className={styles.row}>
          <div>Billing Cycle</div>
          <div>{premium[PREMIUM_FIELDS.PAYMENT_PLAN]}</div>
        </div>
        <div className={styles.row}>
          <div>Created Date</div>
          <div>{prettifyDatetime(payment.createDate)}</div>
        </div>
        <div className={styles.row}>
          <div>Processed Date</div>
          <div>{payment.processedDate ? prettifyDatetime(payment.processedDate) : 'N/A'}</div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.fail}
          onClick={() => failPayment(payment.id)}
          loading={loading}
          disabled={shouldDisable}
        >
          Mark as Failed
        </Button>
        <ProcessPaymentModal paymentId={payment.id} disabled={shouldDisable} />
      </div>
      {error?.message && <ErrorText>{error.message}</ErrorText>}
    </Card>
  );
};

PaymentCard.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  failPayment: PropTypes.func,
  loading: PropTypes.bool,
  payment: PropTypes.shape({
    createDate: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    method: PropTypes.string,
    premium: PropTypes.shape({
      amount: PropTypes.number,
      billingCycle: PropTypes.string
    }),
    processedDate: PropTypes.string,
    quote: PropTypes.shape({
      effectiveDate: PropTypes.any
    }),
    status: PropTypes.string
  }),
  processPayment: PropTypes.func
};

export default PaymentCard;

import axios from 'axios';
import { getOriginationEndpoint } from '../utils/api';
import { concatUri } from '../utils/url';

const FINALIZE_ENDPOINT = 'finalize';

const getOrigination = async id => {
  const URI = concatUri(getOriginationEndpoint(), id);
  const { data } = await axios.get(URI);
  return data;
};

const patchOrigination = async (id, originationData, account) => {
  const URI = concatUri(getOriginationEndpoint(), id);
  const { data } = await axios.patch(URI, { originationData, account });
  return data;
};

const postOrigination = async (originationData, account) => {
  const URI = `${getOriginationEndpoint()}`;
  const { data } = await axios.post(URI, { originationData, account });
  return data;
};

const finalizeOrigination = async (id, payload) => {
  const { originatorEmail, email, emailData, test, type } = payload;
  const URI = concatUri(getOriginationEndpoint(), [id, FINALIZE_ENDPOINT]);
  const { data } = await axios.post(URI, {
    email,
    originatorEmail,
    emailData,
    test,
    type
  });
  return data;
};

const INITIALIZE_ENDPOINT = 'init-box';
const INITIALIZE_URI = concatUri(getOriginationEndpoint(), INITIALIZE_ENDPOINT);

export const getToken = async () => {
  const { data } = await axios.get(INITIALIZE_URI);
  return data.token;
};

export default {
  getOrigination,
  patchOrigination,
  postOrigination,
  finalizeOrigination
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--DHTP5 .styles__chart--3ztNV{width:98vw;margin:0 auto;height:600px;resize:both;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--DHTP5",
	"chart": "styles__chart--3ztNV"
};
module.exports = exports;

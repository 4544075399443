import { createAction } from 'redux-actions';

export const ADD_PROPERTY_INCIDENT = 'ADD_PROPERTY_INCIDENT';
export const addPropertyIncident = createAction(ADD_PROPERTY_INCIDENT);

export const DELETE_PROPERTY_INCIDENT = 'DELETE_PROPERTY_INCIDENT';
export const deletePropertyIncident = createAction(DELETE_PROPERTY_INCIDENT);

export const UPDATE_PROPERTY_INCIDENT = 'UPDATE_PROPERTY_INCIDENT';
export const updatePropertyIncident = createAction(UPDATE_PROPERTY_INCIDENT);

import { isProd } from './env';
import { concatUri } from './url';

const RESOURCES = {
  JOURNEY: 'journey',
  OPS_JOURNEY: 'ops',
  ORIGINATION: 'originations',
  QUOTESET: 'bquoteset',
  QUOTE: 'quote',
  IV_REPORTS: 'iv-reports'
};

export function getJourneyUri() {
  return concatUri(process.env.JOURNEY_URI, RESOURCES.JOURNEY);
}

export function getOpsJourneyUri() {
  return concatUri(process.env.JOURNEY_URI, [RESOURCES.OPS_JOURNEY, RESOURCES.JOURNEY]);
}

export function getOriginationEndpoint() {
  return concatUri(process.env.ORIGINATIONS_URI, RESOURCES.ORIGINATION);
}

export function getIvReportsEndpoint() {
  return concatUri(process.env.ORIGINATIONS_URI, RESOURCES.IV_REPORTS);
}

export function getQuotesetEndpoint() {
  return concatUri(process.env.QUOTESET_URI, RESOURCES.QUOTESET);
}

export function getDemoQuotesetEndpoint() {
  return concatUri(process.env.DEMO_QUOTESET_URI, RESOURCES.QUOTESET);
}

export function getQuoteEndpoint() {
  return concatUri(process.env.QUOTESET_URI, RESOURCES.QUOTE);
}

export function getIvSetsEndpoint() {
  return isProd()
    ? 'https://prod-168.westus.logic.azure.com/workflows/cce6703fa3ab470d9dac9b796b973567/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2ftriggers%2fmanual%2frun&sv=1.0&sig=5xgTzx-NOvLGR-O6tLmsek0K1LcclfuVz4ZkfOcLZCY'
    : 'https://prod-56.westus.logic.azure.com/workflows/47d97a86a78c49acb486992fd22bd7d5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2ftriggers%2fmanual%2frun&sv=1.0&sig=j7pJaRiyrXWRvuRhk_Xhe8zCY2KdfvJBS5_bW-lWutU';
}

/**
 * If null, return undefined.
 *
 * Swagger doesn't handle null values. If we have a null, don't send it.
 *
 * @param {*} value
 * @returns {*} value if not null, otherwise undefined
 */
export const handleSwaggerNull = value => {
  return value === null ? undefined : value;
};

/**
 * Parse a list of validation errors into a list of objects with element and message properties.
 *
 * e.g. [{ errorCode: 'type.openapi.requestValidation', location: 'body', message: 'must not be null', path: 'applicant.firstName' }] => [{ element: 'firstName', message: 'must not be null' }]
 *
 * @param {{ errorCode: string, location: string, message: string, path: string }[]} [errors]
 */
export const parseValidationErrors = errors => {
  if (!errors?.length) return [];

  return errors?.map(({ path, message }) => {
    const element = path.split('.').pop();
    return { element, message };
  });
};

/**
 * Format a list of validation errors into a list of strings.
 *
 * e.g. [{ element: 'firstName', message: 'is required' }] => ['Field "firstName" is required']
 *
 * @param {{ element: string, message: string }[]} errors
 */
export const formatValidationErrors = errors => {
  return errors.map(({ element, message }) => {
    return `Field "${element}" ${message}`;
  });
};

import { connect } from 'react-redux';
import CreateOrigination from '.';
import { updateOrigination, saveOrigination, getOrigination } from '../../actions/originations';

const mapStateToProps = ({ origination, loading, error }) => {
  return {
    origination,
    loading,
    isValid: !error?.message,
    errorMessage: error?.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOrigination: payload => dispatch(updateOrigination(payload)),
    getOrigination: id => {
      dispatch(getOrigination({ id }));
    },
    saveOrigination: () => {
      dispatch(saveOrigination());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrigination);

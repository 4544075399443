import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BoxUploader from '../BoxUploader';
import Button from '../Button';
import CarrierSelect from '../CarrierSelect/container';
import ErrorText from '../ErrorText';
import LinkText from '../LinkText';
import Modal from '../Modal';
import Select from '../Select';
import { createSelectOption, createSelectOptions } from '../Select/utils';
import styles from './styles.css';

const QuoteUploadModal = ({
  loading,
  journeySummary,
  uploadQuotes,
  error,
  saveQuoteset,
  visible,
  closing,
  toggleModal,
  addManually
}) => {
  const [coverageType, setCoverageType] = useState();
  const [carrierCode, setCarrierCode] = useState();
  const [fileIds, setFileIds] = useState([]);

  const defaultCoverageType =
    journeySummary.coverageTypes?.length === 1 ? journeySummary.coverageTypes[0] : null;

  useEffect(() => setCoverageType(defaultCoverageType), [defaultCoverageType]);

  const handleGenerate = () => {
    const payload = {
      carrierCode,
      coverageType,
      fileIds,
      callback: () => setFileIds([])
    };
    // Handle custom address scenario
    if (journeySummary.stateAbbrev) payload.stateAbbrev = journeySummary.stateAbbrev;
    uploadQuotes(payload);
  };

  const handleToggleModal = () => {
    toggleModal();
    if (closing) {
      setFileIds([]);
      setCarrierCode();
    } else {
      saveQuoteset();
    }
  };

  const handleAddManually = () => {
    toggleModal();
    addManually();
  };

  const afterUpload = files => {
    const newIds = files.map(file => file.id);
    setFileIds(prevState => [...prevState, ...newIds]);
  };

  const hasErrorState = false; //@todo
  const validForm = fileIds.length && coverageType && carrierCode;
  return (
    <Modal visible={visible} closing={closing} toggleModal={handleToggleModal}>
      <div className={styles.wrapper}>
        <h2>Upload Quotes</h2>
        <p>
          <small>
            Upload carrier quote PDFs here. We will scan them and add them to this quoteset,
            auto-magically ✨
          </small>
        </p>
        <Select
          prompt="Coverage Type"
          options={createSelectOptions(journeySummary.coverageTypes)}
          onChange={(name, val) => setCoverageType(val)}
          isValid={!!coverageType}
          value={createSelectOption(coverageType)}
          showValidation={hasErrorState}
        />
        <br />
        <CarrierSelect
          onChange={(name, val) => setCarrierCode(val.code)}
          value={carrierCode}
          isValid={!!carrierCode}
          showValidation={hasErrorState}
          prompt="Carrier"
        />
        <br />
        {visible && <BoxUploader afterUpload={afterUpload} />}
        <footer className={styles.buttonWrapper}>
          <Button
            className="primary"
            onClick={handleGenerate}
            disabled={!validForm}
            loading={loading}
          >
            Generate Quote(s)
          </Button>
          <LinkText onClick={handleAddManually}>Enter Quote Manually</LinkText>
        </footer>
        {error.message && <ErrorText>❗️{error.message}</ErrorText>}
      </div>
    </Modal>
  );
};

QuoteUploadModal.propTypes = {
  buttonClassName: PropTypes.string,
  loading: PropTypes.bool,
  uploadQuotes: PropTypes.func,
  journeySummary: PropTypes.object,
  error: PropTypes.object,
  saveQuoteset: PropTypes.func,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  addManually: PropTypes.func
};

export default QuoteUploadModal;

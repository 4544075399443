import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../Checkbox';
import { FIELD } from '../constants';

const RealCommunication = ({ onChange, fields, applicantEmail, originatorEmail }) => {
  return (
    <>
      <Checkbox
        name={FIELD.EMAIL_APPLICANT}
        value={fields[FIELD.EMAIL_APPLICANT]}
        onChange={onChange}
        disabled={!applicantEmail}
      >
        Email Applicant ({applicantEmail || 'none'})
      </Checkbox>
      <Checkbox
        name={FIELD.EMAIL_ORIGINATOR}
        value={fields[FIELD.EMAIL_ORIGINATOR]}
        onChange={onChange}
        disabled={!originatorEmail}
      >
        Email Originator ({originatorEmail || 'none'})
      </Checkbox>
    </>
  );
};

RealCommunication.propTypes = {
  onChange: PropTypes.func,
  fields: PropTypes.object,
  applicantEmail: PropTypes.string,
  originatorEmail: PropTypes.string
};

export default RealCommunication;

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { FIELDS, POLICY_TYPES } from 'woop-shared/canopy/enums';
import { HiLockClosed, HiOutlineCalendar } from 'react-icons/hi';
import _ from 'lodash';
import { POLICY_TYPE_MAP } from 'woop-shared/canopy/maps';
import { GENERAL_FIELDS, REPORT_FIELDS } from 'woop-shared/db/models';
import styles from './styles.css';
import LinkText from '../LinkText';
import { ROUTES, addIdToRoute } from '../../router/routes';
import { generateReport } from '../../actions/reports';
import { getRecentPolicies } from '../../utils/canopy';
import {
  getEffectiveRange,
  formatPolicyType,
  findReport,
  getVehicleAndDriverCount,
  getCarrierName,
  getDecPageDate
} from './utils';
import Badge from '../../pages/ApplicantView/components/Badge';
import Tooltip from '../Tooltip';

const POLICY_COLUMNS = [
  'Type',
  'Carrier',
  'Effective',
  'Veh. / Driv.',
  'Connected',
  'Dec. Page Added',
  'Report'
];

const CanopySummary = ({ pulls, reports, userId }) => {
  const [policies, setPolicies] = useState([]);
  const { loading } = useSelector(state => state);
  const dispatch = useDispatch();

  const createReport = policy => {
    if (loading) return;
    dispatch(
      generateReport({
        id: userId,
        policyId: policy[FIELDS.POLICY.POLICY_ID],
        policyType: POLICY_TYPE_MAP[policy[FIELDS.POLICY.POLICY_TYPE]],
        carrierPolicyId: policy[FIELDS.POLICY.CARRIER_POLICY_NUMBER]
      })
    );
  };

  useEffect(() => {
    if (pulls?.length) setPolicies(getRecentPolicies(pulls));
  }, [pulls]);

  const lastPull = _.orderBy(pulls, [GENERAL_FIELDS.CREATED_UTC], ['asc']).pop();

  return (
    <section>
      <div className={styles.summary}>
        <h4>Canopy Policies</h4>
        <div className={styles.summaryBadge}>
          <HiOutlineCalendar /> Updated{' '}
          {dayjs(lastPull[GENERAL_FIELDS.CREATED_UTC]).format('MM/DD/YYYY')}
        </div>
        {lastPull.encountered_mfa && (
          <div className={styles.summaryBadge}>
            <HiLockClosed /> MFA Enabled{' '}
          </div>
        )}
      </div>
      <div className={styles.policyTable}>
        <div className={styles.headerRow}>
          {POLICY_COLUMNS.map(c => (
            <span key={c}>{c}</span>
          ))}
        </div>
        {policies?.map(policy => {
          const report = findReport(reports, policy.policy_id);
          const canCreateReport =
            [POLICY_TYPES.AUTO, POLICY_TYPES.HOME].includes(policy.policy_type) && userId;
          const isAuto = POLICY_TYPES.AUTO === policy.policy_type;
          const { totalVehicles, totalDrivers } = getVehicleAndDriverCount(policy);
          const pull = pulls.find(p => policy.pull_id === p.pull_id);

          return (
            <>
              <div className={styles.policyRow} key={policy.policy_id}>
                <span className={styles.lobCell} title={policy[FIELDS.POLICY.POLICY_TYPE]}>
                  <span>{formatPolicyType(policy[FIELDS.POLICY.POLICY_TYPE])}</span>
                </span>
                <span>{getCarrierName(policy)}</span>
                <span>{getEffectiveRange(policy)}</span>
                <span>
                  {isAuto ? (
                    <>
                      <div>
                        {`${totalVehicles}`}&nbsp;{`vehicle(s)`}
                      </div>
                      <div>
                        {`${totalDrivers}`}&nbsp;{`driver(s)`}
                      </div>
                    </>
                  ) : (
                    '-'
                  )}
                </span>
                <span>{pull?.created_at ? dayjs(pull.created_at).format('MM/DD/YYYY') : '-'}</span>
                <span>{dayjs(getDecPageDate(pull, policy.policy_id)).format('MM/DD/YYYY')}</span>
                {userId && (
                  <div className={styles.reportCell}>
                    {report ? (
                      <LinkText
                        href={addIdToRoute(ROUTES.REPORTS_VIEW, report[GENERAL_FIELDS.ID])}
                        external
                      >
                        View report
                      </LinkText>
                    ) : (
                      canCreateReport && (
                        <LinkText onClick={() => createReport(policy)} disabled={loading}>
                          Create report
                        </LinkText>
                      )
                    )}
                    {canCreateReport && (
                      <Tooltip
                        title={report?.[REPORT_FIELDS.FINALIZATION] ? 'Complete' : 'Incomplete'}
                      >
                        <Badge complete={!!report?.[REPORT_FIELDS.FINALIZATION]} iconMode />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.linkFooter}>
                <LinkText
                  href={addIdToRoute(ROUTES.POLICY, policy.policy_id)}
                  external
                  icon={<FiExternalLink />}
                >
                  Details
                </LinkText>
              </div>
            </>
          );
        })}
      </div>
    </section>
  );
};

export default CanopySummary;

CanopySummary.propTypes = {
  pulls: PropTypes.any,
  reports: PropTypes.array,
  userId: PropTypes.any,
  submitted: PropTypes.any
};

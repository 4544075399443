import { hasVal } from 'woop-shared/utils';
import { BINDINGS_FIELDS } from 'woop-shared/db/models';
import {
  isValidAccountNumber,
  isValidRoutingNumber,
  isCardValid,
  isValidExpirationDate,
  isValidCvv
} from 'woop-shared/payments/utils';
import { UI_ADDRESS_FIELDS } from 'woop-shared/enums';
import { MORTGAGE_FIELDS } from 'woop-shared/db/models';

export const ACH_FIELDS = {
  BANK_NAME: {
    key: BINDINGS_FIELDS.BANK_NAME,
    label: 'Bank Name',
    validator: hasVal
  },
  ACCOUNT_NUMBER: {
    key: BINDINGS_FIELDS.ACCOUNT_NUMBER,
    label: 'Checking Account Number',
    validator: isValidAccountNumber
  },
  ROUTING_NUMBER: {
    key: BINDINGS_FIELDS.ROUTING_NUMBER,
    label: 'Routing Number',
    validator: isValidRoutingNumber,
    maxLength: 9
  }
};

export const CARD_FIELDS = {
  NAME_ON_CARD: {
    key: BINDINGS_FIELDS.NAME_ON_CARD,
    label: 'Name on Card',
    validator: hasVal
  },
  CARD_NUMBER: {
    key: BINDINGS_FIELDS.CARD_NUMBER,
    label: 'Card Number',
    placeholder: 'xxxx-xxxx-xxxx-xxxx',
    validationText: 'Only Mastercard and VISA card numbers are accepted.',
    validator: isCardValid,
    maxLength: 19 // to include dashes
  },
  CARD_EXPIRATION_DATE: {
    key: 'cardExpirationDate',
    label: 'Expiration Date',
    placeholder: 'MM/YY',
    validationText: 'Please enter the date in the format MM/YY',
    validator: isValidExpirationDate,
    maxLength: 5
  },
  CARD_CVV: {
    key: BINDINGS_FIELDS.CVV,
    label: 'CVV',
    validator: isValidCvv,
    maxLength: 3
  },
  BILLING_ADDRESS: {
    key: BINDINGS_FIELDS.BILLING_ADDRESS,
    label: 'Billing Address',
    validator: val => !!(val?.[UI_ADDRESS_FIELDS.COMPOSITE]?.length > 4)
  }
};

export const ESCROW_FIELDS = {
  MORTGAGE_COMPANY_NAME: {
    key: MORTGAGE_FIELDS.COMPANY_NAME,
    label: 'Mortgage Company Name',
    validator: hasVal
  },
  MORTGAGE_COMPANY_CITY: {
    key: MORTGAGE_FIELDS.COMPANY_CITY,
    label: 'Mortgage Company City',
    validator: hasVal
  },
  MORTGAGE_COMPANY_STATE: {
    key: MORTGAGE_FIELDS.COMPANY_STATE,
    label: 'Mortgage Company State',
    validator: hasVal
  },
  LOAN_NUMBER: {
    key: MORTGAGE_FIELDS.LOAN_NUMBER,
    label: 'Loan Number',
    validator: hasVal
  }
};

export function camelToTitleCase(camelStr) {
  return camelStr
    .replace(/([A-Z])/g, ' $1') // Insert space before each capital letter
    .replace(/^./, firstChar => firstChar.toUpperCase()); // Capitalize the first letter
}

export function snakeToTitleCase(snakeStr) {
  return snakeStr
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/(?:^|\s)\S/g, match => match.toUpperCase()); // Capitalize the first letter of each word
}

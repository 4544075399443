import React from 'react';
import { formatMoneyWithDecimals, stripNonFloatChars } from 'woop-shared/quotes/utils';
import NumberField from '../NumberField';

const DecimalMoneyField = props => (
  <NumberField
    {...props}
    displayFormatter={formatMoneyWithDecimals}
    saveFormatter={stripNonFloatChars}
  />
);

export default DecimalMoneyField;

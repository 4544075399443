import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { AiFillMinusCircle } from 'react-icons/ai';
import { FiAlertTriangle } from 'react-icons/fi';
import { hasVal } from 'woop-shared/utils';
import {
  SUPPLEMENTARY_FIELDS,
  COMPONENT_MAPPING,
  OPTIONAL_SUPPLEMENTARY_INFO_KEYS
} from './constants';
import { mapFieldToProps, getVal } from './utils';
import { conditionMet } from '../../pages/CreateQuoteset/utils';
import styles from './SupplementaryInfo.css';
import Card from '../Card';

const cx = classnames.bind(styles);

const SupplementaryInfo = ({ coverageType, onChange, data, showValidation, className }) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleCollapse = e => {
    e.stopPropagation();
    setCollapsed(true);
  };

  const fields = SUPPLEMENTARY_FIELDS?.[coverageType] || null;
  return (
    fields && (
      <Card className={cx(className, 'wrapper', { collapsed })} onClick={() => setCollapsed(false)}>
        <div className={styles.buttons}>
          <AiFillMinusCircle onClick={e => handleCollapse(e)} />
        </div>
        <h3 className={cx({ showValidation })}>
          {showValidation && <FiAlertTriangle />} <span>Supplementary Information</span>
        </h3>
        {fields
          .filter(field => !field.conditionals || conditionMet(field, data))
          .map(field => {
            const ComponentTag = COMPONENT_MAPPING[field.type];
            const props = mapFieldToProps(field);
            const value = getVal(data?.[field.key], field);
            const hasValue = hasVal(value);
            return (
              <ComponentTag
                key={field.key}
                onChange={onChange}
                value={value}
                {...props}
                isValid={hasValue || OPTIONAL_SUPPLEMENTARY_INFO_KEYS.includes(field.key)}
                showValidation={showValidation}
              />
            );
          })}
      </Card>
    )
  );
};

export default SupplementaryInfo;

SupplementaryInfo.propTypes = {
  coverageType: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.object,
  showValidation: PropTypes.bool
};

import { COVERAGE_TYPES } from 'woop-shared/enums';
import { COMPONENT_NAMES, SUPPLEMENTARY_FIELDS } from './constants';

/**
 * Map a field object to component props.
 *
 * @param {object} field A field object.
 * @returns {object} Object of props to pass into a component.
 */
export function mapFieldToProps(field) {
  const { key, label, options, isMulti } = field;
  const props = { name: key };
  switch (field.type) {
    case COMPONENT_NAMES.SELECT:
      props.options = options;
      props.prompt = label;
      props.isMulti = isMulti;
      break;
    default:
      props.label = label;
      break;
  }

  return props;
}

/**
 * Get the supplementary fields for this quoteset.
 *
 * @param {Array} coverageTypes An array of coverage types for this quoteset.
 * @param {object} supplementaryInfo The current supplementary info fields.
 * @returns {object} A supplementary fields object.
 */
export function createSupplementaryInfo(coverageTypes, supplementaryInfo) {
  const fields = SUPPLEMENTARY_FIELDS[getCovTypeForSupplementaryFields(coverageTypes)];
  const template = {};
  if (fields)
    fields.forEach(f => (template[f.key] = supplementaryInfo?.[f.key] || f?.default || null));
  return template;
}

/**
 * Get the coverage type that corresponds to supplementary fields.
 *
 * @param {Array} covTypes An array of coverage types.
 * @returns {string|void} A coverage type string.
 */
export function getCovTypeForSupplementaryFields(covTypes) {
  if (covTypes.includes(COVERAGE_TYPES.HOME)) return COVERAGE_TYPES.HOME;
  if (covTypes.includes(COVERAGE_TYPES.RENTERS)) return COVERAGE_TYPES.RENTERS;
  if (covTypes.includes(COVERAGE_TYPES.CONDO)) return COVERAGE_TYPES.CONDO;
  if (covTypes.includes(COVERAGE_TYPES.AUTO)) return COVERAGE_TYPES.AUTO;
}

/**
 * Get the value of the field based on the component type.
 *
 * @param {*} value The field's raw value.
 * @param {object} field The field object.
 * @param {string} field.type The component type.
 * @param {Array} field.options The array options for this field.
 * @returns {*} The formatted value.
 */
export function getVal(value, { type, options }) {
  if (type === COMPONENT_NAMES.SELECT) return options.find(opt => opt.value === value);
  return value;
}

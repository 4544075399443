/**
 * Concat two strings with exactly one / between them.
 *
 * @param {string} base The first string.
 * @param {string|Array} pieces An array of subsequent strings to append to the first string.
 * @returns {string}
 */
export function concatUri(base, pieces) {
  const resources = Array.isArray(pieces)
    ? pieces.map(p => trimSlashes(p)).join('/')
    : trimSlashes(pieces);
  return `${removeTrailingSlash(base)}/${resources}`;
}

/**
 * Remove leading and trailing slashes from a string.
 *
 * @param {string} str The string to remove slashes from.
 * @returns {string}
 */
function trimSlashes(str) {
  return removeTrailingSlash(removeLeadingSlash(str));
}

/**
 * Add a slash after a string if there isn't one.
 *
 * @param {string} str The string to add a slash to.
 * @returns {string}
 */
function removeTrailingSlash(str) {
  if (typeof str !== 'string') return '';
  if (str[str.length - 1] === '/') return str.slice(0, -1);
  return str;
}

/**
 * Remove a slash at the beginning of a string.
 *
 * @param {string} str The string to remove a slash from.
 * @returns {string}
 */
function removeLeadingSlash(str) {
  if (typeof str !== 'string') return '';
  if (str[0] === '/') return str.substring(1);
  return str;
}

/**
 * Safely add query parameters to a string.
 *
 * @param {string} route A route w/o a query string, e.g. applicants/view.
 * @param {object} params An object of queryStringKey: queryStringVal
 * @returns {string}
 */
export function addQueryStringParamsToRoute(route, params) {
  let queryString = '';
  Object.entries(params).forEach(([key, val]) => (queryString += `${key}=${val}&`));
  return `${route}?${queryString.slice(0, -1)}`;
}

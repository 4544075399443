import axios from 'axios';

export async function getPolicy({ policyId }) {
  const uri = `${process.env.AUTH_URI}policies/${policyId}`;
  const { data } = await axios.get(uri);
  return data;
}

export async function patchPolicy({ policyId, updates }) {
  const uri = `${process.env.AUTH_URI}policies/${policyId}`;
  const { data } = await axios.patch(uri, updates);
  return data;
}

// Drivers
const getDriverUri = (policyId, driverId) =>
  driverId
    ? `${process.env.AUTH_URI}policies/${policyId}/drivers/${driverId}`
    : `${process.env.AUTH_URI}policies/${policyId}/drivers`;

export const postDriver = ({ policyId, updates }) => axios.post(getDriverUri(policyId), updates);
export const patchDriver = ({ policyId, driverId, updates }) =>
  axios.patch(getDriverUri(policyId, driverId), updates);
export const deleteDriver = ({ policyId, driverId }) =>
  axios.delete(getDriverUri(policyId, driverId));

// Vehicles
const getVehicleUri = (policyId, vehicleId) =>
  vehicleId
    ? `${process.env.AUTH_URI}policies/${policyId}/vehicles/${vehicleId}`
    : `${process.env.AUTH_URI}policies/${policyId}/vehicles`;

export const postVehicle = ({ policyId, updates }) => axios.post(getVehicleUri(policyId), updates);
export const patchVehicle = ({ policyId, vehicleId, updates }) =>
  axios.patch(getVehicleUri(policyId, vehicleId), updates);
export const deleteVehicle = ({ policyId, vehicleId }) =>
  axios.delete(getVehicleUri(policyId, vehicleId));

// Policy-level coverages
const getPolicyCoverageUri = (policyId, coverageSlug) =>
  `${process.env.AUTH_URI}policies/${policyId}/coverages/${coverageSlug}`;

export const postPolicyCoverage = ({ policyId, coverageSlug, updates }) =>
  axios.post(getPolicyCoverageUri(policyId, coverageSlug), updates);
export const patchPolicyCoverage = ({ policyId, coverageSlug, updates }) =>
  axios.patch(getPolicyCoverageUri(policyId, coverageSlug), updates);
export const deletePolicyCoverage = ({ policyId, coverageSlug }) =>
  axios.delete(getPolicyCoverageUri(policyId, coverageSlug));

// Vehicle-level coverages
const getVehicleCoverageUri = (policyId, vehicleId, coverageId) =>
  coverageId
    ? `${process.env.AUTH_URI}policies/${policyId}/vehicles/${vehicleId}/coverages/${coverageId}`
    : `${process.env.AUTH_URI}policies/${policyId}/vehicles/${vehicleId}/coverages`;

export const postVehicleCoverage = ({ policyId, vehicleId, updates }) =>
  axios.post(getVehicleCoverageUri(policyId, vehicleId), updates);
export const patchVehicleCoverage = ({ policyId, vehicleId, coverageId, updates }) =>
  axios.patch(getVehicleCoverageUri(policyId, vehicleId, coverageId), updates);
export const deleteVehicleCoverage = ({ policyId, vehicleId, coverageId }) =>
  axios.delete(getVehicleCoverageUri(policyId, vehicleId, coverageId));

// Addresses
const getAddressUri = (policyId, addressId) =>
  addressId
    ? `${process.env.AUTH_URI}policies/${policyId}/addresses/${addressId}`
    : `${process.env.AUTH_URI}policies/${policyId}/addresses`;

export const postAddress = ({ policyId, updates }) => axios.post(getAddressUri(policyId), updates);
export const patchAddress = ({ policyId, addressId, updates }) =>
  axios.patch(getAddressUri(policyId, addressId), updates);
export const deleteAddress = ({ policyId, addressId }) =>
  axios.delete(getAddressUri(policyId, addressId));

// Claims
const getClaimsUri = (pullId, claimId) =>
  claimId
    ? `${process.env.AUTH_URI}pull/${pullId}/claims/${claimId}`
    : `${process.env.AUTH_URI}pull/${pullId}/claims`;

export const postClaim = ({ pullId, updates }) => axios.post(getClaimsUri(pullId), updates);
export const patchClaim = ({ pullId, claimId, updates }) =>
  axios.patch(getClaimsUri(pullId, claimId), updates);
export const deleteClaim = ({ pullId, claimId }) => axios.delete(getClaimsUri(pullId, claimId));

// Incidents
const getIncidentsUri = (pullId, incidentId) =>
  incidentId
    ? `${process.env.AUTH_URI}pull/${pullId}/incidents/${incidentId}`
    : `${process.env.AUTH_URI}pull/${pullId}/incidents`;

export const postIncident = ({ pullId, updates }) => axios.post(getIncidentsUri(pullId), updates);
export const patchIncident = ({ pullId, incidentId, updates }) =>
  axios.patch(getIncidentsUri(pullId, incidentId), updates);
export const deleteIncident = ({ pullId, incidentId }) =>
  axios.delete(getIncidentsUri(pullId, incidentId));

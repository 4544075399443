import axios from 'axios';

export async function getReport(reportId) {
  const uri = `${process.env.AUTH_URI}reports/${reportId}`;
  const { data } = await axios.get(uri);
  return data;
}

export async function patchReport(reportId, reportData) {
  const uri = `${process.env.AUTH_URI}reports/${reportId}`;
  const { data } = await axios.patch(uri, reportData);
  return data;
}

export async function finalizeReport(reportId) {
  const uri = `${process.env.AUTH_URI}reports/${reportId}/finalize`;
  const { data } = await axios.patch(uri);
  return data;
}

/**
 * Finds a report by id, copies it, and associates the copied report to the given user.
 *
 * @param {string} reportId
 * @param {string} userId
 * @returns {string} The id of newly created report
 */
export async function previewReport(reportId, userId = process.env.OPS_PORTAL_UID) {
  const uri = `${process.env.AUTH_URI}reports/${reportId}/copy`;
  const { data } = await axios.post(uri, { clearExisting: true, userId });
  return data.reportId;
}

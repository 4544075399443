import { connect } from 'react-redux';
import PaymentsView from './';
import { getPayments } from '../../actions/payments';

const mapStateToProps = ({ payments: paymentsResponse, loading, error }) => {
  return {
    ...paymentsResponse,
    loading,
    error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPayments: quotesetId => dispatch(getPayments(quotesetId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsView);

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FocusTrap } from '@mui/base';
import classnames from 'classnames/bind';
import { FiX } from 'react-icons/fi';
import styles from './Modal.css';
import { MOUNT_NODE } from '../../constants/app';

const cx = classnames.bind(styles);

const Modal = ({
  children,
  visible,
  closing,
  animationMode,
  toggleModal,
  fullHeight,
  showCloseBtn = true,
  className
}) => {
  const innerRef = useRef(null);

  return ReactDOM.createPortal(
    <FocusTrap open={!!visible}>
      <div
        className={cx('wrapper', animationMode, className, {
          fullHeight,
          visible,
          closing
        })}
        tabIndex={-1}
      >
        {showCloseBtn && toggleModal && (
          <button role="button" className={styles.closeButton} onClick={toggleModal}>
            <FiX />
          </button>
        )}
        <div ref={innerRef}>{children}</div>
        {ReactDOM.createPortal(
          <div className={cx('overlay', { visible, closing })}></div>,
          MOUNT_NODE
        )}
      </div>
    </FocusTrap>,
    MOUNT_NODE
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  animationMode: PropTypes.oneOf(['fadeIn']),
  toggleModal: PropTypes.func,
  showCloseBtn: PropTypes.bool,
  fullHeight: PropTypes.bool,
  className: PropTypes.string
};

import { createAction } from 'redux-actions';

export const GET_PARTNERS = 'GET_PARTNERS';
export const getPartners = createAction(GET_PARTNERS);

export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const getPartnersSuccess = createAction(GET_PARTNERS_SUCCESS);

export const GET_PARTNER = 'GET_PARTNER';
export const getPartner = createAction(GET_PARTNER);

export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
export const getPartnerSuccess = createAction(GET_PARTNER_SUCCESS);

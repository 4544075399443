import { connect } from 'react-redux';
import ReportsView from '.';
import {
  finalizeReport,
  getReport,
  saveReport,
  setReportField,
  previewReport
} from '../../actions/reports';

const mapStateToProps = ({ report, user, policy, quoteset, quotes, error, loading }) => {
  return {
    report,
    user,
    policy,
    quoteset,
    quotes,
    error,
    loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReport: reportId => dispatch(getReport(reportId)),
    saveReport: () => dispatch(saveReport()),
    finalizeReport: () => dispatch(finalizeReport()),
    previewReport: () => dispatch(previewReport()),
    setReportField: ({ key, value }) => dispatch(setReportField({ key, value }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsView);

import { takeLatest, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  GET_PAYMENTS,
  getPaymentsSuccess,
  FAIL_PAYMENT,
  PROCESS_PAYMENT,
  getPayments
} from '../actions/payments';
import api from '../api/payments';
import { setError } from '../actions/error';
import { setLoading } from '../actions/loading';
import { addIdToRoute, ROUTES } from '../router/routes';
import { addToRecentPayments } from '../utils/local-storage';

function* handleGetPayments(action) {
  const id = action.payload;
  yield put(setLoading(true));
  try {
    const response = yield api.getPayments(id);
    yield put(getPaymentsSuccess({ ...response, quotesetId: id }));
    yield put(push(addIdToRoute(ROUTES.PAYMENTS_VIEW, id)));
    const lastName = response?.consumerInfo?.lastName;
    const coverageTypes = response?.consumerInfo?.coverageTypes;
    addToRecentPayments(id, lastName, coverageTypes);
  } catch {
    yield put(setError({ message: 'Could not find payments for that quoteset id' }));
  }
  yield put(setLoading(false));
}

export function* watchGetPayment() {
  yield takeLatest(GET_PAYMENTS, handleGetPayments);
}

function* handleFailPayment(action) {
  const id = action.payload;
  yield put(setLoading(true));
  try {
    yield api.failPayment(id);
    const { quotesetId } = yield select(({ payments }) => payments);
    yield put(getPayments(quotesetId));
  } catch {
    yield put(setError({ message: `Could update binding record (${id})` }));
  }
  yield put(setLoading(false));
}

export function* watchFailPayment() {
  yield takeLatest(FAIL_PAYMENT, handleFailPayment);
}

function* handleProcessPayment(action) {
  const { paymentId: id, effectiveDate, policyNumber, callback } = action.payload;
  yield put(setLoading(true));
  try {
    yield api.processPayment({ id, effectiveDate, policyNumber });
    const { quotesetId } = yield select(({ payments }) => payments);
    yield put(getPayments(quotesetId));
    if (callback) callback();
  } catch {
    yield put(setError({ message: `Could update binding record (${id})` }));
  }
  yield put(setLoading(false));
}

export function* watchProcessPayment() {
  yield takeLatest(PROCESS_PAYMENT, handleProcessPayment);
}

import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './Header.css';
import {
  ROUTES,
  QUOTES_ROUTE,
  ORIGINATIONS_ROUTE,
  APPLICANTS_ROUTE,
  PAYMENTS_ROUTE
} from '../../router/routes';
import { CLOUDINARY_IMAGES } from '../../assets/constants';
import LinkText from '../LinkText';
import { maybeShowCharts } from '../../pages/Charts/utils';

const logo = CLOUDINARY_IMAGES.WOOP_BLACK;
const cx = classNames.bind(styles);

const Header = () => {
  const location = useLocation();
  return (
    <header className={styles.wrapper}>
      <LinkText href={ROUTES.HOME} noUnderline>
        <img className={styles.logo} src={logo.URL} alt={logo.ALT} />
      </LinkText>
      <div className={styles.links}>
        <LinkText
          href={ROUTES.QUOTES_SEARCH}
          className={cx({ active: location.pathname.includes(QUOTES_ROUTE) })}
        >
          Quotes
        </LinkText>
        <LinkText
          href={ROUTES.ORIGINATIONS_SEARCH}
          className={cx({ active: location.pathname.includes(ORIGINATIONS_ROUTE) })}
        >
          Originations
        </LinkText>
        <LinkText
          href={ROUTES.APPLICANTS_SEARCH}
          className={cx({ active: location.pathname.includes(APPLICANTS_ROUTE) })}
        >
          Applicants
        </LinkText>
        <LinkText
          href={ROUTES.PAYMENTS_SEARCH}
          className={cx({ active: location.pathname.includes(PAYMENTS_ROUTE) })}
        >
          Payments
        </LinkText>
        {maybeShowCharts() && (
          <LinkText
            href={ROUTES.CHARTS}
            className={cx({ active: location.pathname.includes(ROUTES.CHARTS) })}
          >
            Drop Offs
          </LinkText>
        )}
      </div>
    </header>
  );
};

export default Header;

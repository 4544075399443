// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__header--2oGUO{text-transform:uppercase;color:var(--color-grey-dark);font-size:var(--fs-sm)}.styles-module__header--2oGUO:focus{outline:none!important}.styles-module__row--1TRQ1:hover{background-color:inherit!important}.styles-module__row--1TRQ1:nth-of-type(odd){background-color:var(--color-grey-lightest)}.styles-module__row--1TRQ1:nth-of-type(odd):hover{background-color:var(--color-grey-lightest)!important}.styles-module__cell--19aI9:focus{outline:none!important}.styles-module__actions--7KfWw{display:flex;grid-gap:.5rem;gap:.5rem}", ""]);
// Exports
exports.locals = {
	"header": "styles-module__header--2oGUO",
	"row": "styles-module__row--1TRQ1",
	"cell": "styles-module__cell--19aI9",
	"actions": "styles-module__actions--7KfWw"
};
module.exports = exports;

import { SET_QUOTE_RESULT } from '../actions/quote-result';

function quoteResult(state = {}, action) {
  switch (action.type) {
    case SET_QUOTE_RESULT: {
      const { payload } = action;
      return payload;
    }
    default:
      return state;
  }
}

export default quoteResult;

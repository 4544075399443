import { BUNDLED_QUOTE_FIELDS, GENERAL_FIELDS } from 'woop-shared/db/models';
import {
  ADD_BUNDLED_QUOTE,
  UPDATE_BUNDLED_QUOTE,
  DELETE_BUNDLED_QUOTE_SUCCESS
} from '../actions/bundled-quotes';
import { SET_QUOTESET } from '../actions/quoteset';
import { DELETE_QUOTE_SUCCESS } from '../actions/quotes';
import { CREATE_DATE } from '../constants/fields';

function bundledQuotes(state = {}, action) {
  switch (action.type) {
    case ADD_BUNDLED_QUOTE: {
      const { payload: bundledQuote } = action;
      return {
        ...state,
        [bundledQuote[CREATE_DATE]]: bundledQuote
      };
    }
    case DELETE_QUOTE_SUCCESS: {
      const id = action.payload;
      const newState = Object.fromEntries(
        Object.entries(state).map(([key, val]) => {
          return [key, { ...val, quoteIds: val.quoteIds.filter(quoteId => quoteId !== id) }];
        })
      );
      return newState;
    }
    case UPDATE_BUNDLED_QUOTE: {
      const { id, quoteIndex, value } = action.payload;
      const existingBundle = state[id];
      let quoteIds = [...existingBundle[BUNDLED_QUOTE_FIELDS.QUOTE_IDS]];
      quoteIds[quoteIndex] = value;
      quoteIds = quoteIds.filter(Boolean);
      return { ...state, [id]: { ...existingBundle, quoteIds } };
    }
    case SET_QUOTESET: {
      const { bundledQuotes } = action.payload;
      if (!bundledQuotes) return state;
      const bundledQuotesObj = {};
      bundledQuotes.forEach(bundledQuote => {
        bundledQuotesObj[bundledQuote[GENERAL_FIELDS.ID]] = bundledQuote;
      });
      return bundledQuotesObj;
    }
    case DELETE_BUNDLED_QUOTE_SUCCESS: {
      const { payload: id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    default:
      return state;
  }
}

export default bundledQuotes;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../css/SearchPage.css';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import Card from '../../components/Card';
import ErrorText from '../../components/ErrorText';
import LinkText from '../../components/LinkText';
import { getRecentCollection } from '../../utils/local-storage';
import { RECENT_APPLICANTS_KEY } from '../../constants/local-storage';
import { addQueryStringParamsToRoute } from '../../utils/url';
import { ROUTES } from '../../router/routes';
import CardLabel from '../../components/CardLabel';
import PhoneField from '../../components/PhoneField';

const ApplicantSearch = ({ searchApplicants, error, loading }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');

  const handleClick = () => {
    const searchCriteria = {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      email: email?.trim(),
      zip: zip?.trim(),
      phone: phone?.trim()
    };
    searchApplicants(searchCriteria);
  };

  const handleEnter = e => {
    if (e.key === 'Enter') handleClick();
  };

  const recentApplicants = getRecentCollection(RECENT_APPLICANTS_KEY);

  return (
    <section onKeyUp={e => handleEnter(e)} className={styles.wrapper}>
      <Card>
        <CardLabel>Applicants</CardLabel>
        <div className={styles.searchFields}>
          <TextField
            name="firstName"
            label="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <TextField
            name="lastName"
            label="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
          <TextField
            name="email"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            name="zip"
            label="Zip Code"
            value={zip}
            onChange={e => setZip(e.target.value)}
          />
          <PhoneField
            name="phone"
            label="Phone"
            value={phone}
            onChange={(_, value) => setPhone(value)}
          />
        </div>
        {error.message && <ErrorText>{error.message}</ErrorText>}
        <div className={styles.buttonWrapper}>
          <Button onClick={handleClick} loading={loading}>
            Search
          </Button>
        </div>
      </Card>
      <br />
      <Card>
        <div>Recent Searches</div>
        {recentApplicants?.map(({ id, lastName, firstName, zip, email, phone }) => (
          <div key={id} className={styles.recentList}>
            <LinkText
              href={addQueryStringParamsToRoute(ROUTES.APPLICANTS_VIEW, {
                lastName,
                firstName,
                zip,
                email,
                phone
              })}
            >
              {lastName || ''} {firstName || ''} {zip || ''} {email || ''} {phone || ''}
            </LinkText>
          </div>
        ))}
      </Card>
    </section>
  );
};

ApplicantSearch.propTypes = {
  searchApplicants: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool
};

export default ApplicantSearch;

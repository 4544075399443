import { stripNonDigits } from 'woop-shared/quotes/utils';

const PHONE_NUMBER_LENGTH = 10;

/**
 * @param {string} input
 * @returns {string} dasherized phone number
 */
function dashPhone(input) {
  let val = input;
  val = val.replace(/^(\d{3})/, '$1-');
  val = val.replace(/^(\d{3})-(\d{3})/, '$1-$2-');
  val = val.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
  return val;
}

/**
 * @param {string} rawInput
 * @returns {string} a formatted phone number
 */
export function formatPhone(rawInput) {
  const cleanedInput = stripNonDigits(rawInput);
  const trimmedInput = cleanedInput.substring(0, PHONE_NUMBER_LENGTH);
  const dashedInput = dashPhone(trimmedInput);
  return dashedInput;
}

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Checkbox } from '@mui/material';
import { VEHICLE_OWNERSHIP_STATUS, VEHICLE_USES } from 'woop-shared/origination/enums';
import { VEHICLE_FIELDS } from 'woop-shared/origination/fields';
import { US_STATES } from 'woop-shared/data/states';
import VehiclePicker from '../../../components/VehiclePicker';
import NumberField from '../../../components/NumberField';
import TextField from '../../../components/TextField';
import Select from '../../../components/Select';
import {
  createOptionsFromEnum,
  createSelectOption,
  createSelectOptions
} from '../../../components/Select/utils';
import Prompt from '../../../components/Prompt';
import DateField from '../../../components/DateField';
import InlineAddressForm from './inline/InlineAddressForm';
import { getDriverOptions } from '../utils';
import styles from '../styles.module.css';

const vehicleUseOptions = createOptionsFromEnum(VEHICLE_USES);
const ownershipStatusOptions = createOptionsFromEnum(VEHICLE_OWNERSHIP_STATUS);
const daysPerWeekOptions = createSelectOptions(_.range(1, 8));

const VehicleForm = ({
  entity,
  onInput,
  onChange,
  onEntityUpdate,
  context: { quoteApplication },
  validationState
}) => {
  const vehicle = entity || {};
  const showValidation = validationState.allValid === false;

  const driverOptions = getDriverOptions(quoteApplication);

  const handleVehiclePickerChange = update => {
    const [[key, val]] = Object.entries(update);
    onChange(key, val);
  };

  return (
    <>
      <VehiclePicker
        autoFocus
        year={vehicle[VEHICLE_FIELDS.YEAR]}
        make={vehicle[VEHICLE_FIELDS.MAKE]}
        model={vehicle[VEHICLE_FIELDS.MODEL]}
        onChange={handleVehiclePickerChange}
        showValidation={showValidation}
      />
      <div className={styles.checkboxWrapper}>
        <Checkbox
          name={VEHICLE_FIELDS.EXCLUDED}
          checked={vehicle[VEHICLE_FIELDS.EXCLUDED] ?? false}
          onChange={(_, val) => onChange(VEHICLE_FIELDS.EXCLUDED, val)}
          className={styles.checkboxWrapper}
        />
        <Prompt>Excluded</Prompt>
      </div>
      <TextField
        label={'VIN'}
        name={VEHICLE_FIELDS.VIN}
        value={vehicle[VEHICLE_FIELDS.VIN]}
        onChange={onInput}
        isValid={validationState[VEHICLE_FIELDS.VIN]?.isValid}
        showValidation={showValidation}
      />
      <NumberField
        label={'Annual Mileage'}
        enforceInteger
        name={VEHICLE_FIELDS.ANNUAL_MILES}
        onChange={onChange}
        value={vehicle[VEHICLE_FIELDS.ANNUAL_MILES]}
        isValid={validationState[VEHICLE_FIELDS.ANNUAL_MILES]?.isValid}
        showValidation={showValidation}
        max={99999}
      />
      <Select
        prompt={'Ownership Status'}
        options={ownershipStatusOptions}
        name={VEHICLE_FIELDS.VEHICLE_OWNERSHIP_STATUS}
        onChange={onChange}
        value={createSelectOption(vehicle[VEHICLE_FIELDS.VEHICLE_OWNERSHIP_STATUS])}
        isValid={validationState[VEHICLE_FIELDS.VEHICLE_OWNERSHIP_STATUS]?.isValid}
        showValidation={showValidation}
      />
      <InlineAddressForm
        prompt="Primary Garage"
        idField={VEHICLE_FIELDS.GARAGE_ADDRESS_ID}
        selectedId={vehicle[VEHICLE_FIELDS.GARAGE_ADDRESS_ID]}
        quoteApplication={quoteApplication}
        onChange={onChange}
        onEntityUpdate={onEntityUpdate}
        tooltip="Only required if the vehicle is parked at a separate address."
      />
      <Select
        prompt={'Primary Driver'}
        options={driverOptions}
        name={VEHICLE_FIELDS.APPLICANT_ID}
        onChange={onChange}
        value={
          driverOptions.find(({ value }) => value === vehicle[VEHICLE_FIELDS.APPLICANT_ID]) || null
        }
      />
      <Select
        prompt={'Occasional Drivers'}
        options={driverOptions}
        name={VEHICLE_FIELDS.OTHER_DRIVERS}
        isMulti={true}
        onChange={onChange}
        value={
          driverOptions?.filter(({ value }) =>
            vehicle[VEHICLE_FIELDS.OTHER_DRIVERS]?.includes(value)
          ) || []
        }
      />
      <Select
        prompt={'Registration State'}
        name={VEHICLE_FIELDS.REGISTERED_STATE}
        options={US_STATES}
        onChange={onChange}
        value={createSelectOption(vehicle[VEHICLE_FIELDS.REGISTERED_STATE])}
        tooltip={
          'Only required if the vehicle is registered to a different state than the primary address.'
        }
      />
      <Select
        prompt={'Vehicle Usage'}
        options={vehicleUseOptions}
        name={VEHICLE_FIELDS.VEHICLE_USE}
        onChange={onChange}
        value={createSelectOption(vehicle[VEHICLE_FIELDS.VEHICLE_USE])}
        isValid={validationState[VEHICLE_FIELDS.VEHICLE_USE]?.isValid}
        showValidation={showValidation}
      />
      {vehicle[VEHICLE_FIELDS.VEHICLE_USE] === VEHICLE_USES.COMMUTE && (
        <>
          <Select
            prompt={'Days Driven per Week for Commuting'}
            name={VEHICLE_FIELDS.DAYS_DRIVEN_PER_WEEK}
            options={daysPerWeekOptions}
            onChange={onChange}
            value={createSelectOption(vehicle[VEHICLE_FIELDS.DAYS_DRIVEN_PER_WEEK])}
            isValid={validationState[VEHICLE_FIELDS.DAYS_DRIVEN_PER_WEEK]?.isValid}
            showValidation={showValidation}
          />
          <NumberField
            label={'Commute Distance in Miles (one-way)'}
            enforceInteger
            name={VEHICLE_FIELDS.COMMUTE_DISTANCE}
            onChange={onChange}
            value={vehicle[VEHICLE_FIELDS.COMMUTE_DISTANCE]}
          />
        </>
      )}
      <DateField
        prompt={'Vehicle Purchase Date'}
        name={VEHICLE_FIELDS.VEHICLE_PURCHASE_DATE}
        onChange={onInput}
        value={vehicle[VEHICLE_FIELDS.VEHICLE_PURCHASE_DATE]}
        isValid={validationState[VEHICLE_FIELDS.VEHICLE_PURCHASE_DATE]?.isValid}
        showValidation={showValidation}
      />
    </>
  );
};

VehicleForm.propTypes = {
  context: PropTypes.object,
  entity: PropTypes.object,
  onChange: PropTypes.func,
  onEntityUpdate: PropTypes.func,
  onInput: PropTypes.func,
  validationState: PropTypes.object
};

export default VehicleForm;

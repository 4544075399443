import { COVERAGE_TYPES } from 'woop-shared/enums';
import { PROMPT_GROUP_NAMES, PROMPT_NAMES } from 'woop-shared/prompts/names';
import { getCoverageTypes, getPrimaryFirstName, getPrimaryLastName } from 'woop-shared/utils';

export function getEditableFields(uiData) {
  return {
    [PROMPT_NAMES.FIRST_NAME]: getPrimaryFirstName(uiData),
    [PROMPT_NAMES.LAST_NAME]: getPrimaryLastName(uiData),
    [PROMPT_NAMES.COVERAGE_TYPE]: getCoverageTypes(uiData),
    [PROMPT_NAMES.PHONE]: uiData?.[PROMPT_NAMES.PHONE],
    [PROMPT_NAMES.EMAIL]: uiData?.[PROMPT_NAMES.EMAIL],
    [PROMPT_NAMES.ADDRESS]: uiData?.[PROMPT_NAMES.ADDRESS],
    [PROMPT_NAMES.EFFECTIVE_DATE]: uiData?.[PROMPT_NAMES.EFFECTIVE_DATE],
    [PROMPT_NAMES.AUTO_EFFECTIVE_DATE]: uiData?.[PROMPT_NAMES.AUTO_EFFECTIVE_DATE]
  };
}

export function getUiDataUpdates(_uiData, updates) {
  let uiData = { ..._uiData, ...updates };
  const hasAuto = updates[PROMPT_NAMES.COVERAGE_TYPE]?.includes(COVERAGE_TYPES.AUTO);
  if (hasAuto) {
    // Apply primary applicant changes to the primary driver.
    const primaryDriver = uiData[PROMPT_GROUP_NAMES.DRIVERS]?.[0] || {};
    const driverUpdates = {
      [PROMPT_NAMES.FIRST_NAME]: uiData[PROMPT_NAMES.FIRST_NAME],
      [PROMPT_NAMES.LAST_NAME]: uiData[PROMPT_NAMES.LAST_NAME],
      [PROMPT_NAMES.PHONE]: uiData[PROMPT_NAMES.PHONE],
      [PROMPT_NAMES.EMAIL]: uiData[PROMPT_NAMES.EMAIL]
    };
    uiData[PROMPT_GROUP_NAMES.DRIVERS] = uiData[PROMPT_GROUP_NAMES.DRIVERS] || [];
    uiData[PROMPT_GROUP_NAMES.DRIVERS][0] = { ...primaryDriver, ...driverUpdates };
  }
  return uiData;
}

export function verifyPw(pw) {
  return pw === 'Sunshine123';
}

import { GET_PAYMENTS_SUCCESS } from '../actions/payments';

function payments(state = {}, action) {
  switch (action.type) {
    case GET_PAYMENTS_SUCCESS: {
      const sortedPayments = action.payload.payments?.sort((a, b) =>
        a?.createDate > b?.createDate ? 1 : -1
      );
      return { ...action.payload, payments: sortedPayments };
    }
    default:
      return state;
  }
}

export default payments;

import React from 'react';
import { AiOutlineLineChart } from 'react-icons/ai';
import styles from './styles.css';
import Card from '../../components/Card';
import Policies from '../../assets/icons/policies.svg';
import Rocket from '../../assets/icons/rocket.svg';
import PeopleSearch from '../../assets/icons/people-search.svg';
import Money from '../../assets/icons/money.svg';
import { ROUTES } from '../../router/routes';
import LinkText from '../../components/LinkText';
import { maybeShowCharts } from '../Charts/utils';

const Home = () => {
  return (
    <section className={styles.wrapper}>
      <Card className={styles.card}>
        <LinkText href={ROUTES.QUOTES_SEARCH} noUnderline>
          <Policies />
          <div>Quotes</div>
        </LinkText>
      </Card>

      <Card className={styles.card}>
        <LinkText href={ROUTES.ORIGINATIONS_SEARCH} noUnderline>
          <Rocket />
          <div>Originations</div>
        </LinkText>
      </Card>

      <Card className={styles.card}>
        <LinkText href={ROUTES.APPLICANTS_SEARCH} noUnderline>
          <PeopleSearch />
          <div>Applicants</div>
        </LinkText>
      </Card>

      <Card className={styles.card}>
        <LinkText href={ROUTES.PAYMENTS_SEARCH} noUnderline>
          <Money />
          <div>Payments</div>
        </LinkText>
      </Card>

      {maybeShowCharts() && (
        <Card className={styles.card}>
          <LinkText href={ROUTES.CHARTS} noUnderline>
            <AiOutlineLineChart />
            <div>Drop Offs</div>
          </LinkText>
        </Card>
      )}
    </section>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import { COVERAGE_NAMES, FIELDS } from 'woop-shared/canopy/enums';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ADDRESS_FIELDS } from 'woop-shared/origination/fields';
import { QUOTE_REQUEST_FIELDS } from 'woop-shared/db/models';
import { getVehicleName, getVehicles } from 'woop-shared/origination/utils';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import Button from '../../../../components/Button';
import styles from './styles.module.css';
import Select from '../../../../components/Select';
import { QUOTE_TYPES, QUOTE_TYPE_LABELS } from '../../constants';
import LinkText from '../../../../components/LinkText';
import { dollarsToCents } from '../../../../utils/money';
import { getCoverageTemplate } from '../../../../api/coverage-templates';
import CoverageTable from '../CoverageTable';
import { splitCoverages } from '../../utils';
import { getCurrentPolicy } from '../../../QuoteApplication/utils';
import { VEHICLE_FIELDS } from '../../../../constants/origination';
import { getCurrentPolicyNumber } from '../../../../utils/origination';

const QUOTE_TYPE_OPTIONS = Object.entries(QUOTE_TYPE_LABELS).map(([key, val]) => ({
  label: val,
  value: key
}));

const getTarget = (request, vehicleIndex) =>
  Number.isInteger(vehicleIndex) ? request[QUOTE_REQUEST_FIELDS.VEHICLES][vehicleIndex] : request;

const RequestModal = ({ request: _request, onSave, primaryAddress }) => {
  const [request, setRequest] = useState(_request);
  const { quoteApplication, pulls } = useSelector(state => state);
  useEffect(() => {
    setRequest(_request);
  }, [_request]);

  if (!request) return null;

  const isAuto = request[QUOTE_REQUEST_FIELDS.LOB] === COVERAGE_TYPES.AUTO;

  const onLimitChange = (index, vehicleIndex) => (key, val) => {
    const newRequest = _.cloneDeep(request);
    const target = getTarget(newRequest, vehicleIndex);
    target.coverages[index][key] = dollarsToCents(val);
    setRequest(newRequest);
  };

  const clearLimits = (index, vehicleIndex) => () => {
    const newRequest = _.cloneDeep(request);
    const target = getTarget(newRequest, vehicleIndex);
    target.coverages[index][FIELDS.COVERAGE.PER_DAY_LIMIT_CENTS] = null;
    target.coverages[index][FIELDS.COVERAGE.PER_INCIDENT_LIMIT_CENTS] = null;
    target.coverages[index][FIELDS.COVERAGE.PER_PERSON_LIMIT_CENTS] = null;
    setRequest(newRequest);
  };

  const onCoverageSelect = (index, vehicleIndex) => (key, val) => {
    const newRequest = _.cloneDeep(request);
    const target = getTarget(newRequest, vehicleIndex);
    target.coverages[index][FIELDS.COVERAGE.NAME] = val;
    target.coverages[index][FIELDS.COVERAGE.FRIENDLY_NAME] = COVERAGE_NAMES[val];
    setRequest(newRequest);
  };

  const onAdd = vehicleIndex => () => {
    const newRequest = _.cloneDeep(request);
    const target = getTarget(newRequest, vehicleIndex);
    target.coverages.push({});
    setRequest(newRequest);
  };

  const onChange = (key, val) => {
    const newRequest = _.cloneDeep(request);
    newRequest[key] = val;
    setRequest(newRequest);
  };

  const onDelete = (coverageIndex, vehicleIndex) => () => {
    const newRequest = _.cloneDeep(request);
    const target = getTarget(newRequest, vehicleIndex);
    _.pullAt(target.coverages, coverageIndex);
    setRequest(newRequest);
  };

  const applyCoverages = (coverages, vehicleId = null) => {
    const [vehicleCoverages, policyCoverages] = splitCoverages(coverages);

    // Set policy-wide coverages
    const newRequest = _.cloneDeep(request);
    newRequest.coverages = _.uniqBy(policyCoverages, FIELDS.COVERAGE.NAME);

    // Maybe set vehicle coverages
    if (vehicleCoverages.length && request[QUOTE_REQUEST_FIELDS.VEHICLES]?.length) {
      newRequest[QUOTE_REQUEST_FIELDS.VEHICLES] = request[QUOTE_REQUEST_FIELDS.VEHICLES].map(
        vehicle => ({
          ...vehicle,
          coverages:
            !vehicleId || vehicleId === vehicle.id
              ? _.uniqBy(vehicleCoverages, FIELDS.COVERAGE.NAME)
              : vehicle[QUOTE_REQUEST_FIELDS.COVERAGES]
        })
      );
    }

    setRequest(newRequest);
  };

  const onApplyTemplate = async () => {
    // Get state abbrev.
    const state =
      request[QUOTE_REQUEST_FIELDS.ADDRESS]?.[ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE] ||
      primaryAddress?.[ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE];
    // Fetch coverages
    const { data: coverages } = await getCoverageTemplate({
      state,
      policyType: request[QUOTE_REQUEST_FIELDS.LOB]
    });
    applyCoverages(coverages);
  };

  const applyCurrentCoverages = () => {
    const policyNumber = getCurrentPolicyNumber(quoteApplication, {
      propertyId: request[QUOTE_REQUEST_FIELDS.PROPERTY_ID],
      isAuto
    });
    // Get current policy
    const currentPolicy = getCurrentPolicy(policyNumber, pulls);
    if (!currentPolicy) return;
    if (isAuto) {
      // Apply current vehicle coverages to matching vehicles in this request.
      const policyVehicles = currentPolicy[FIELDS.POLICY.VEHICLES];
      const reqVehicles = request[QUOTE_REQUEST_FIELDS.VEHICLES];
      const appVehicles = getVehicles(quoteApplication);
      reqVehicles.forEach(reqVehicle => {
        const fullVehicle = appVehicles.find(av => reqVehicle.id === av.id);
        const policyVehicle = policyVehicles.find(
          pv => pv[FIELDS.VEHICLE.VIN] === fullVehicle[VEHICLE_FIELDS.VIN]
        );
        if (policyVehicle) applyCoverages(policyVehicle[FIELDS.COVERAGES], reqVehicle.id);
      });
    } else {
      const propertyCoverages = currentPolicy[FIELDS.POLICY.DWELLINGS][0][FIELDS.COVERAGES];
      propertyCoverages?.length && applyCoverages(propertyCoverages);
    }
  };

  return (
    <section>
      <h4>Quote request details</h4>
      <div className={styles.modalForm}>
        <div className={styles.quoteType}>
          <div>
            <Select
              autoFocus
              name={QUOTE_REQUEST_FIELDS.TYPE}
              prompt={'quote type'}
              options={QUOTE_TYPE_OPTIONS}
              onChange={onChange}
              value={QUOTE_TYPE_OPTIONS.find(o => o.value === request?.type)}
            />
          </div>
          <div>
            {request[QUOTE_REQUEST_FIELDS.TYPE] === QUOTE_TYPES.RECOMMENDATION && (
              <LinkText onClick={onApplyTemplate}>Add recommended coverages</LinkText>
            )}
            {request[QUOTE_REQUEST_FIELDS.TYPE] === QUOTE_TYPES.APPLES && (
              <LinkText onClick={applyCurrentCoverages}>Add current coverages</LinkText>
            )}
          </div>
        </div>
        <CoverageTable
          coverages={request[QUOTE_REQUEST_FIELDS.COVERAGES]}
          isAuto={isAuto}
          title={'Policy Coverages'}
          onAdd={onAdd}
          onCoverageSelect={onCoverageSelect}
          onDelete={onDelete}
          onLimitChange={onLimitChange}
          clearLimits={clearLimits}
        />
        {request[QUOTE_REQUEST_FIELDS.VEHICLES]?.map((vehicle, i) => {
          const vehicles = getVehicles(quoteApplication);
          const fullVehicle = vehicles?.find(v => v.id === vehicle.id);
          return (
            <CoverageTable
              key={vehicle.id}
              vehicleIndex={i}
              coverages={vehicle.coverages}
              isAuto={true}
              title={`${getVehicleName(fullVehicle) || `Vehicle ${i + 1}`}`}
              onAdd={onAdd}
              onCoverageSelect={onCoverageSelect}
              onDelete={onDelete}
              onLimitChange={onLimitChange}
              clearLimits={clearLimits}
            />
          );
        })}
      </div>
      <div className={styles.footer}>
        <Button onClick={() => onSave(request)} className={styles.modalButton}>
          Save
        </Button>
      </div>
    </section>
  );
};

RequestModal.propTypes = {
  onSave: PropTypes.func,
  primaryAddress: PropTypes.object,
  request: PropTypes.shape({
    coverages: PropTypes.array,
    lob: PropTypes.string,
    type: PropTypes.string
  })
};

export default RequestModal;

import { useEffect } from 'react';

function useScrollToTop(dependencies = [], element = window) {
  useEffect(() => {
    if (element) {
      element.scrollTo(0, 0);
    }
  }, dependencies);

  return null;
}

export default useScrollToTop;

import {
  APPLICANT_FIELDS as OG_APPLICANT_FIELDS,
  PROPERTY_INCIDENT_FIELDS as OG_PROPERTY_INCIDENT_FIELDS,
  POOL_FIELDS as OG_POOL_FIELDS,
  PROPERTY_FIELDS as OG_PROPERTY_FIELDS,
  VEHICLE_FIELDS as OG_VEHICLE_FIELDS,
  INCIDENT_FIELDS as OG_INCIDENT_FIELDS,
  AUTO_FIELDS
} from 'woop-shared/origination/fields';
import { ANIMALS, DOG_BREEDS } from 'woop-shared/origination/enums';
import { createOptionsFromEnum } from '../components/Select/utils';
import { createYearsOptions } from '../utils/options';

// These fields are not sent to the backend.
export const APPLICANT_FIELDS_EXTRA = {
  PHONE_TYPE: 'phoneType'
};

export const APPLICANT_FIELDS = {
  ...OG_APPLICANT_FIELDS,
  ...APPLICANT_FIELDS_EXTRA
};

export const APPLICANT_NUMBER_FIELDS = [APPLICANT_FIELDS.MONTHS_AT_ADDRESS, APPLICANT_FIELDS.GPA];

export const PROPERTY_INCIDENT_FIELDS_EXTRA = {
  ID: 'id'
};

export const PROPERTY_INCIDENT_FIELDS = {
  ...OG_PROPERTY_INCIDENT_FIELDS,
  ...PROPERTY_INCIDENT_FIELDS_EXTRA
};

export const POOL_FIELDS_EXTRA = {
  ID: 'id'
};

export const POOL_FIELDS = {
  ...OG_POOL_FIELDS,
  ...POOL_FIELDS_EXTRA
};

export const PROPERTY_FIELDS = {
  ...OG_PROPERTY_FIELDS
};

export const PROPERTY_NUMBER_FIELDS = [
  [PROPERTY_FIELDS.OWNERSHIP_YEAR],
  [PROPERTY_FIELDS.YEAR_BUILT],
  [PROPERTY_FIELDS.NUMBER_OF_STORIES],
  [PROPERTY_FIELDS.NUMBER_OF_RESIDENTS],
  [PROPERTY_FIELDS.NUMBER_OF_CHILDREN],
  [PROPERTY_FIELDS.NUMBER_OF_NON_DRIVING_CHILDREN],
  [PROPERTY_FIELDS.NUMBER_OF_MORTGAGES],
  [PROPERTY_FIELDS.SQUARE_FOOTAGE],
  [PROPERTY_FIELDS.ROOF_YEAR],
  [PROPERTY_FIELDS.FUEL_TANKS_ON_PREMISES],
  [PROPERTY_FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER],
  [PROPERTY_FIELDS.LOAN_AMOUNT]
];

export const AUTO_NUMBER_FIELDS = [AUTO_FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER];

// These fields are not sent to the backend.
export const VEHICLE_FIELDS_EXTRA = {
  VEHICLE_TAG: 'vehicleTag'
};

export const VEHICLE_FIELDS = {
  ...OG_VEHICLE_FIELDS,
  ...VEHICLE_FIELDS_EXTRA
};

export const VEHICLE_NUMBER_FIELDS = [VEHICLE_FIELDS.YEAR, VEHICLE_FIELDS.MILEAGE];

export const VEHICLE_TAG_PREFIX = 'AUTO';

// These fields are not sent to the backend.
export const INCIDENT_FIELDS_EXTRA = {
  ID: 'id'
};

export const INCIDENT_FIELDS = {
  ...OG_INCIDENT_FIELDS,
  ...INCIDENT_FIELDS_EXTRA
};

export const YEARS = createYearsOptions();

const YES_OPTION = {
  label: 'Yes',
  value: true
};

const NO_OPTION = {
  label: 'No',
  value: false
};

export const YES_NO_OPTIONS = [YES_OPTION, NO_OPTION];

export const ANIMALS_WITH_NO = [NO_OPTION, ...createOptionsFromEnum(ANIMALS)];

export const DOGS_WITH_NO = [NO_OPTION, ...createOptionsFromEnum(DOG_BREEDS)];

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './RadioGroup.css';
import Prompt from '../Prompt';
import Radio from '../Radio';

const cx = classNames.bind(styles);

const RadioGroup = ({
  options,
  name,
  prompt,
  showValidation,
  isValid = true,
  value,
  onChange,
  className
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    // Keep internal state synced with external prop
    if (selected !== value) setSelected(value);
  }, [value]);

  const handleClick = (_, value) => {
    setSelected(value);
    onChange(name, value);
  };

  return (
    <div>
      <Prompt invalid={!isValid && showValidation}>{prompt}</Prompt>
      <div className={cx('radio-group', className)}>
        {options.map(option => (
          <Radio
            label={option.label}
            key={option.label}
            onChange={handleClick}
            value={option.value}
            selected={selected === option.value}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string])
    })
  ),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
  prompt: PropTypes.string,
  showValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

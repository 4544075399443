import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';
import { getToken } from '../../api/originations';
import { isProd } from '../../utils/env';

const PROD_BOX_FOLDER_ID = '130050248103';
const DEV_BOX_FOLDER_ID = '130049595069';

const timestamp = new Date().getTime();
const requestInterceptor = req => {
  if (req.method !== 'post') return req;

  const appendTimestamp = filename => {
    const pieces = filename?.split('.') || [];
    const name = pieces.slice(0, -1);
    const ext = pieces.at(-1);

    return `${name.join('.')}-${timestamp}.${ext}`;
  };

  const alterName = data => {
    const attributes = JSON.parse(data.attributes);
    return {
      ...data,
      attributes: JSON.stringify({ ...attributes, name: appendTimestamp(attributes.name) })
    };
  };

  return {
    ...req,
    data: alterName(req.data)
  };
};

const BoxUploader = ({ afterUpload }) => {
  const container = useRef(null);

  useEffect(() => {
    const getTokenWrapper = async () => await getToken();
    getTokenWrapper().then(token => {
      if (!window.Box) return;
      const { ContentUploader } = window.Box;
      const uploader = new ContentUploader();
      const folderId = isProd() ? PROD_BOX_FOLDER_ID : DEV_BOX_FOLDER_ID;
      if (afterUpload) uploader.addListener('complete', afterUpload);
      uploader.show(folderId, token, {
        container: '#box-uploader',
        onClose: null,
        requestInterceptor
      });
    });
  }, [container]);

  return <div className={styles.wrapper} id="box-uploader" ref={container} />;
};

BoxUploader.propTypes = {
  afterUpload: PropTypes.func
};

export default BoxUploader;

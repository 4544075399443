import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Card.css';

const cx = classnames.bind(styles);

const Card = ({ id, className, children, onClick, size }) => (
  <div id={id} className={cx('wrapper', className, size)} onClick={onClick}>
    {children}
  </div>
);

export default Card;

Card.propTypes = {
  id: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func
};

import React from 'react';
import PropTypes from 'prop-types';
import { getAddressComposite, getPrimaryDriver } from 'woop-shared/utils';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import styles from './styles.css';
import Card from '../../components/Card';
import { DISPLAY_SECTIONS, OTHER_PROMPTS } from './constants';
import Table from '../Table';

const JourneyCard = ({ journeyId, uiData }) => {
  const ADDRESS_PROMPTS = [
    PROMPT_NAMES.ADDRESS,
    PROMPT_NAMES.PRIOR_ADDRESS,
    PROMPT_NAMES.CAR_ADDRESS,
    PROMPT_NAMES.PRIMARY_RESIDENCE_ADDRESS
  ];

  const getVal = (promptName, source, fallbackSource) => {
    let rawVal = source?.[promptName];
    if (rawVal === undefined) return fallbackSource?.[promptName];
    if (Array.isArray(rawVal)) return rawVal.join(', ');
    if (ADDRESS_PROMPTS.includes(promptName)) return getAddressComposite(rawVal);
    return rawVal;
  };

  const mapToRows = (section, source, fallbackSource) => {
    return section.prompts.map(promptName => {
      return { label: promptName, value: getVal(promptName, source, fallbackSource) };
    });
  };

  const renderPromptGroup = (section, source) => {
    const promptGroupName = section.promptGroupName;
    const items = source?.[promptGroupName]?.map((item, index) => {
      return (
        <div key={index}>
          <div>
            {promptGroupName} {index + 1}
          </div>
          <Table key={index} rows={mapToRows(section, item)} />
        </div>
      );
    });

    return items?.length ? (
      items
    ) : (
      <div>
        <i>None to display</i>
      </div>
    );
  };

  return (
    <Card key={journeyId} className={styles.result}>
      <h4>Application Data</h4>
      <div className={styles.cardBody}>
        <div className={styles.tableHeader}>Policy</div>
        <Table rows={mapToRows(DISPLAY_SECTIONS.POLICY, uiData)} />

        <div className={styles.tableHeader}>Primary Applicant</div>
        <Table
          rows={mapToRows(DISPLAY_SECTIONS.PRIMARY_APPLICANT, uiData, getPrimaryDriver(uiData))}
        />

        <div className={styles.tableHeader}>Drivers</div>
        {renderPromptGroup(DISPLAY_SECTIONS.DRIVERS, uiData)}

        <div className={styles.tableHeader}>Excluded Drivers</div>
        {renderPromptGroup(DISPLAY_SECTIONS.EXCLUDED_DRIVERS, uiData)}

        <div className={styles.tableHeader}>Co-applicants</div>
        {renderPromptGroup(DISPLAY_SECTIONS.CO_APPLICANTS, uiData)}

        <div className={styles.tableHeader}>Property</div>
        <Table rows={mapToRows(DISPLAY_SECTIONS.PROPERTY, uiData)} />

        <div className={styles.tableHeader}>Property Usage</div>
        <Table rows={mapToRows(DISPLAY_SECTIONS.PROPERTY_USAGE, uiData)} />

        <div className={styles.tableHeader}>Vehicles</div>
        {renderPromptGroup(DISPLAY_SECTIONS.VEHICLES, uiData)}

        <div className={styles.tableHeader}>Desired Vehicles</div>
        {renderPromptGroup(DISPLAY_SECTIONS.DESIRED_VEHICLES, uiData)}

        <div className={styles.tableHeader}>Trade-Ins</div>
        {renderPromptGroup(DISPLAY_SECTIONS.TRADE_INS, uiData)}

        <div className={styles.tableHeader}>Insurance History</div>
        <Table rows={mapToRows(DISPLAY_SECTIONS.INSURANCE_HISTORY_HOME, uiData)} />
        <Table rows={mapToRows(DISPLAY_SECTIONS.INSURANCE_HISTORY_AUTO, uiData)} />
        <Table rows={mapToRows(DISPLAY_SECTIONS.INSURANCE_HISTORY_RENTERS, uiData)} />
        <Table rows={mapToRows(DISPLAY_SECTIONS.INSURANCE_HISTORY_CONDO, uiData)} />

        <div className={styles.tableHeader}>Property Incidents</div>
        {renderPromptGroup(DISPLAY_SECTIONS.PROPERTY_INCIDENTS, uiData)}

        <div className={styles.tableHeader}>Vehicle Incidents</div>
        {renderPromptGroup(DISPLAY_SECTIONS.VEHICLE_INCIDENTS, uiData)}

        <div className={styles.tableHeader}>Umbrella Coverage</div>
        <Table rows={mapToRows(DISPLAY_SECTIONS.UMBRELLA, uiData)} />

        <div className={styles.tableHeader}>Other Fields</div>
        <Table rows={mapToRows(OTHER_PROMPTS, uiData)} />
      </div>
    </Card>
  );
};

JourneyCard.propTypes = {
  createdAt: PropTypes.string,
  journeyId: PropTypes.string,
  lastUpdatedAt: PropTypes.string,
  uiData: PropTypes.object
};

export default JourneyCard;

import { connect } from 'react-redux';
import { searchApplicants } from '../../actions/journeys';
import ApplicantsView from '.';

const mapStateToProps = ({ journeys, loading }) => ({ journeys, loading });

const mapDispatchToProps = dispatch => {
  return {
    searchApplicants: params => dispatch(searchApplicants(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantsView);

import React from 'react';
import PropTypes from 'prop-types';
import { FiPlus, FiX } from 'react-icons/fi';
import { ADDITIONAL_COVERAGES } from 'woop-shared/quotes/enums';
import { hasVal } from 'woop-shared/utils';
import TextField from '../TextField';
import { newAdditionalCoverageTemplate } from './utils';
import { ADDITIONAL_COVERAGE_KEYS } from './constants';
import Button from '../Button';
import styles from './AdditionalCoverages.css';
import Prompt from '../Prompt';
import Select from '../Select';
import { createOptionsFromEnum, createSelectOption } from '../Select/utils';

const COVERAGE_OPTIONS = createOptionsFromEnum(ADDITIONAL_COVERAGES).sort((a, b) =>
  a.label > b.label ? 1 : -1
);

const AdditionalCoverages = ({ name, onChange, showValidation, coverages = [] }) => {
  const removeId = newCoverages => {
    return newCoverages.map(additionalCoverages => ({
      [ADDITIONAL_COVERAGE_KEYS.TYPE]: additionalCoverages[ADDITIONAL_COVERAGE_KEYS.TYPE],
      [ADDITIONAL_COVERAGE_KEYS.AMOUNT]: additionalCoverages[ADDITIONAL_COVERAGE_KEYS.AMOUNT]
    }));
  };

  const saveToQuote = newCoverages => {
    onChange(name, removeId(newCoverages));
  };

  const handleAdd = () => {
    const newCoverages = [...coverages, newAdditionalCoverageTemplate()];
    saveToQuote(newCoverages);
  };

  const handleDelete = index => {
    const newCoverages = coverages.filter((_, i) => i !== index);
    saveToQuote(newCoverages);
  };

  const handleFieldChange = (e, fieldName, index) => {
    const value = e.target.value;
    const newCoverages = [...coverages];
    const coverage = newCoverages[index];
    const newCoverage = { ...coverage, [fieldName]: value };
    newCoverages.splice(index, 1, newCoverage);
    saveToQuote(newCoverages);
  };

  const handleSelectChange = (fieldName, val, index) => {
    const newCoverages = [...coverages];
    const coverage = newCoverages[index];
    const newCoverage = { ...coverage, [fieldName]: val };
    newCoverages.splice(index, 1, newCoverage);
    saveToQuote(newCoverages);
  };

  const isValid =
    coverages.length &&
    coverages.every(
      coverage =>
        hasVal(coverage[ADDITIONAL_COVERAGE_KEYS.TYPE]) &&
        hasVal(coverage[ADDITIONAL_COVERAGE_KEYS.AMOUNT])
    );

  return (
    <section>
      <Prompt invalid={showValidation && !isValid}>Additional Coverages</Prompt>
      {coverages.map((cov, i) => (
        <div key={cov[ADDITIONAL_COVERAGE_KEYS.ID] || i} className={styles.additionalCoverage}>
          <Select
            name={ADDITIONAL_COVERAGE_KEYS.TYPE}
            placeholder="Coverage Name"
            value={
              COVERAGE_OPTIONS.find(o => o.value === cov[ADDITIONAL_COVERAGE_KEYS.TYPE]) ||
              createSelectOption(cov[ADDITIONAL_COVERAGE_KEYS.TYPE])
            }
            options={COVERAGE_OPTIONS}
            onChange={(name, val) => handleSelectChange(name, val, i)}
            createable
          />
          <TextField
            type="text"
            name={ADDITIONAL_COVERAGE_KEYS.TYPE}
            label="Coverage Amount"
            value={cov[ADDITIONAL_COVERAGE_KEYS.AMOUNT]}
            onChange={e => handleFieldChange(e, ADDITIONAL_COVERAGE_KEYS.AMOUNT, i)}
          />
          <Button className={styles.delete} onClick={() => handleDelete(i)}>
            <FiX />
          </Button>
        </div>
      ))}
      <Button className={styles.add} onClick={handleAdd}>
        Add coverage <FiPlus />
      </Button>
    </section>
  );
};

export default AdditionalCoverages;

AdditionalCoverages.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  coverages: PropTypes.arrayOf(PropTypes.object),
  showValidation: PropTypes.bool
};

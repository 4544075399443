import axios from 'axios';
import { getDemoQuotesetEndpoint, getQuotesetEndpoint, handleSwaggerNull } from '../utils/api';
import { concatUri } from '../utils/url.js';
import * as qaUtils from '../utils/quote-app';
import * as qrUtils from '../utils/quote-request';
import { error } from '../utils/logger';

const RESOURCES = {
  QUOTE_APPLICATION: 'quote-application',
  QUOTE_REQUEST: 'quote-request',
  QUOTE_RESULT: 'quote-result',
  ZYWAVE_CARRIERS: 'zywave/carriers'
};

const ENDPOINTS = {
  SUMMARY: 'summary',
  FINALIZE: 'finalize',
  QA: 'qa',
  UPLOAD: 'upload',
  SYNC: 'sync',
  VERTAFORE: 'vertafore',
  POLICY: 'policy',
  SUBMIT: 'submit'
};

export const getQuoteset = async id => {
  const uri = concatUri(getQuotesetEndpoint(), [ENDPOINTS.SUMMARY, id]);
  const { data } = await axios.get(uri);
  return data;
};

/**
 * Send the finalizeQuoteset request.
 *
 * @param {object} params
 * @param {string} params.id The quoteset ID.
 * @param {string[]} params.compareQuoteIds The IDs of the quotes
 * @param {string} params.type The finalization type.
 * @param {object} params.emailData { agentNotes, carriers, savings }
 * @param {boolean} params.sendLegacy Should send legacy quotes-ready comms
 * @returns {boolean} Success.
 */
export const finalizeQuoteset = async ({ id, compareQuoteIds, type, emailData, sendLegacy }) => {
  const uri = concatUri(getQuotesetEndpoint(), [id, ENDPOINTS.FINALIZE]);
  const { data } = await axios.put(uri, {
    compareQuoteIds,
    type,
    emailData,
    sendLegacy
  });
  return data.success;
};

export const patchQuoteset = async ({
  quotes,
  bundledQuotes,
  supplementaryInfo,
  id,
  mortgage,
  agentNotes,
  agentId
}) => {
  const uri = concatUri(getQuotesetEndpoint(), id);
  const body = {
    supplementaryInfo,
    quotes,
    bundledQuotes,
    mortgage,
    agentNotes: handleSwaggerNull(agentNotes),
    agentId
  };
  const { data } = await axios.patch(uri, body);
  return data;
};

export const qaQuoteset = async ({
  quotes,
  bundledQuotes,
  supplementaryInfo,
  mortgage,
  coverageTypes,
  agentNotes
}) => {
  const uri = concatUri(getDemoQuotesetEndpoint(), ENDPOINTS.QA);
  const body = {
    supplementaryInfo,
    coverageTypes,
    quotes,
    mortgage,
    bundledQuotes,
    agentNotes: handleSwaggerNull(agentNotes)
  };
  const { data } = await axios.post(uri, body);
  return data;
};

/**
 * Calls the upload endpoint
 *
 * @param {string} id A quoteset id
 * @param {object} payload
 * @param {string[]} payload.fileIds
 * @param {string} payload.carrierCode
 * @param {string} payload.coverageType
 * @param {string} payload.stateAbbrev
 * @returns {object} An updated quoteset
 */
export const upload = async (id, { fileIds, carrierCode, coverageType, stateAbbrev }) => {
  const uri = concatUri(getQuotesetEndpoint(), [id, ENDPOINTS.UPLOAD]);
  const body = {
    fileIds,
    carrierCode,
    coverageType,
    stateAbbrev
  };
  const { data } = await axios.patch(uri, body);
  return data;
};

/**
 * Create a quoteset.
 *
 * @param {object} params
 * @param {string} params.journeyId
 * @param {string} params.originationId
 * @param {object} params.uiData
 * @param {boolean} params.notifyConsumer
 * @returns {object} An updated quoteset
 */
export const postQuoteset = async ({ journeyId, originationId, uiData, notifyConsumer }) => {
  const uri = getQuotesetEndpoint();
  const body = { journeyId, originationId, uiData, notifyConsumer };
  const { data } = await axios.post(uri, body);
  return data;
};

export const syncQuoteset = async id => {
  const uri = concatUri(getQuotesetEndpoint(), [id, ENDPOINTS.SYNC]);
  return axios.patch(uri);
};

export const mapVertafore = async id => {
  const uri = concatUri(getQuotesetEndpoint(), [id, ENDPOINTS.VERTAFORE]);
  const { data } = await axios.post(uri);
  return data;
};

export const convertPolicy = async ({ policyId, quotesetId }) => {
  const uri = concatUri(getQuotesetEndpoint(), [quotesetId, ENDPOINTS.POLICY, policyId]);
  const { data } = await axios.post(uri);
  return data;
};

export const getQuoteApplication = async id => {
  const uri = concatUri(process.env.QUOTESET_URI, [RESOURCES.QUOTE_APPLICATION, id]);
  const { data } = await axios.get(uri);
  return data;
};

export const patchQuoteApplication = async (id, updates) => {
  const uri = concatUri(process.env.QUOTESET_URI, [RESOURCES.QUOTE_APPLICATION, id]);
  const cleaned = qaUtils.removeExtraFields(updates);
  const { data } = await axios.patch(uri, cleaned);
  return data;
};

export const postQuoteRequest = async quoteAppId => {
  const uri = concatUri(process.env.QUOTESET_URI, [RESOURCES.QUOTE_REQUEST]);
  const { data } = await axios.post(uri, { quoteAppId });
  return data;
};

export const getQuoteRequest = async id => {
  const uri = concatUri(process.env.QUOTESET_URI, [RESOURCES.QUOTE_REQUEST, id]);
  const { data } = await axios.get(uri);
  return data;
};

export const patchQuoteRequest = async (id, updates) => {
  const uri = concatUri(process.env.QUOTESET_URI, [RESOURCES.QUOTE_REQUEST, id]);
  const cleaned = qrUtils.removeExtraFields(updates);
  const { data } = await axios.patch(uri, cleaned);
  return data;
};

export const submitQuoteRequest = async id => {
  const uri = concatUri(process.env.QUOTESET_URI, [RESOURCES.QUOTE_REQUEST, id, ENDPOINTS.SUBMIT]);
  const { data } = await axios.post(uri);
  return data;
};

export const getQuoteResult = async id => {
  const uri = concatUri(process.env.QUOTESET_URI, [RESOURCES.QUOTE_RESULT, id]);
  const { data } = await axios.get(uri);
  return data;
};

export const getZywaveCarriers = async ({ state, lob }) => {
  const uri = new URL(RESOURCES.ZYWAVE_CARRIERS, process.env.QUOTESET_URI);
  uri.searchParams.append('state', state);
  if (lob) uri.searchParams.append('lob', lob);
  const { data } = await axios.get(uri.toString());
  return data;
};

export const fetchHazardHubPropertyData = async ({ id, propertyId }) => {
  try {
    const uri = concatUri(process.env.QUOTESET_URI, [
      RESOURCES.QUOTE_APPLICATION,
      id,
      'hazard-hub',
      'property',
      propertyId
    ]);
    const { data } = await axios.get(uri);
    return data;
  } catch (e) {
    if (e?.response?.status !== 404) error(e);
    return null;
  }
};

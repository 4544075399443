import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../css/SearchPage.css';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import Card from '../../components/Card';
import ErrorText from '../../components/ErrorText';
import LinkText from '../../components/LinkText';
import { addIdToRoute, ROUTES } from '../../router/routes';
import { getRecentCollection } from '../../utils/local-storage';
import { RECENT_QUOTES_KEY } from '../../constants/local-storage';
import CardLabel from '../../components/CardLabel';

const QuotesetSearch = ({ getQuoteset, error, loading }) => {
  const [quotesetId, setQuotesetId] = useState('');

  const isValid = () => !!quotesetId;

  const handleClick = () => {
    getQuoteset(quotesetId);
  };

  const handleEnter = e => {
    if (isValid() && e.key === 'Enter') handleClick();
  };

  const recentQuotes = getRecentCollection(RECENT_QUOTES_KEY);

  return (
    <section onKeyUp={e => handleEnter(e)} className={styles.wrapper}>
      <Card>
        <CardLabel>Quotesets</CardLabel>
        <TextField
          name="quotesetId"
          placeholder="5e18bb9f8f8b3e93a8157a84"
          label="Quoteset ID"
          value={quotesetId}
          onChange={e => setQuotesetId(e.target.value)}
        />
        {error.message && <ErrorText>{error.message}</ErrorText>}
        <div className={styles.buttonWrapper}>
          <Button onClick={handleClick} disabled={!isValid()} loading={loading}>
            Search
          </Button>
        </div>
      </Card>
      <br />
      <Card>
        <div>Recent Quotesets</div>
        {recentQuotes?.map(q => (
          <div key={q.id} className={styles.recentList}>
            <LinkText
              href={addIdToRoute(ROUTES.QUOTES_VIEW, q.id)}
              onClick={() => getQuoteset(q.id)}
            >
              {q.lastName} - {q.coverageTypes} ({q.id})
            </LinkText>
          </div>
        ))}
      </Card>
    </section>
  );
};

QuotesetSearch.propTypes = {
  getQuoteset: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool
};

export default QuotesetSearch;

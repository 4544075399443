// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--b5fqQ{margin:1rem;background-color:var(--color-red-lightest);color:var(--color-red-darkest);padding:1rem;font-size:var(--fs-sm)}.styles__wrapper--b5fqQ .styles__header--2y3m8{font-weight:var(--fw-medium)}.styles__wrapper--b5fqQ .styles__header--2y3m8 svg{margin-right:.25rem}.styles__wrapper--b5fqQ .styles__item--3Qxhd{margin-left:1.5rem;margin-top:.25rem}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--b5fqQ",
	"header": "styles__header--2y3m8",
	"item": "styles__item--3Qxhd"
};
module.exports = exports;

import React, { forwardRef } from 'react';
import { formatMoney } from 'woop-shared/quotes/utils';
import NumberField from '../NumberField';

// eslint-disable-next-line react/display-name
const MoneyField = forwardRef((props, ref) => (
  <NumberField ref={ref} {...props} displayFormatter={formatMoney} />
));

export default MoneyField;

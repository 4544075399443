import { createAction } from 'redux-actions';

export const ADD_APPLICANT = 'ADD_APPLICANT';
export const addApplicant = createAction(ADD_APPLICANT);

export const DELETE_APPLICANT = 'DELETE_APPLICANT';
export const deleteApplicant = createAction(DELETE_APPLICANT);

export const UPDATE_APPLICANT = 'UPDATE_APPLICANT';
export const updateApplicant = createAction(UPDATE_APPLICANT);

import { takeLeading, put } from 'redux-saga/effects';
import { GET_CARRIERS, getCarriersSuccess } from '../actions/carriers';
import api from '../api/carriers';
import { setError } from '../actions/error';

function* handleGetCarriers() {
  try {
    const carriers = yield api.getCarriers();
    yield put(getCarriersSuccess(carriers));
  } catch {
    yield put(setError({ message: 'Could not load carriers.' }));
  }
}

export function* carriersWatchFinalizeQuoteset() {
  yield takeLeading(GET_CARRIERS, handleGetCarriers);
}

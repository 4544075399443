import React from 'react';
import PropTypes from 'prop-types';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import styles from './styles.module.css';
import LinkText from '../LinkText';
import { ROUTES, addIdToRoute } from '../../router/routes';

const QuotingNav = ({ id, reqId }) => {
  return (
    <div className={styles.nav}>
      {reqId && (
        <LinkText href={addIdToRoute(ROUTES.QUOTE_REQUEST, reqId)} icon={<HiOutlinePencilAlt />}>
          Edit quote request
        </LinkText>
      )}
      <LinkText href={addIdToRoute(ROUTES.QUOTE_APPLICATION, id)} icon={<HiOutlinePencilAlt />}>
        Edit application
      </LinkText>
    </div>
  );
};

QuotingNav.propTypes = {
  id: PropTypes.string,
  reqId: PropTypes.string
};

export default QuotingNav;

import axios from 'axios';
import { concatUri } from '../utils/url';

const SUGGEST_URI = concatUri(process.env.GEOCODER_URI, 'suggest.json');

const defaultParams = {
  apiKey: process.env.GEOCODER_API_KEY,
  country: 'USA',
  language: 'en'
};

export const getAddressSuggestions = async query => {
  const { data } = await axios.get(SUGGEST_URI, {
    params: {
      ...defaultParams,
      query
    }
  });
  return data.suggestions;
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.css';

const cx = classNames.bind(styles);

const Badge = ({ children, color, className }) => {
  return <div className={cx('wrapper', color, className)}>{children}</div>;
};

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string
};

export default Badge;

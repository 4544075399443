import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles.css';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import TextField from '../../../../components/TextField';
import { postAddress, patchAddress } from '../../../../api/policy';

const EditAddress = ({ policy, address, visible, closing, toggleModal, refreshPolicy }) => {
  const [loading, setLoading] = useState();

  const [streetNumber, setStreetNumber] = useState(address?.number);
  const [streetName, setStreetName] = useState(address?.street);
  const [streetType, setStreetType] = useState(address?.type);
  const [unitType, setUnitType] = useState(address?.sec_unit_type);
  const [unitNumber, setUnitNumber] = useState(address?.sec_unit_num);
  const [city, setCity] = useState(address?.city);
  const [state, setState] = useState(address?.state);
  const [zip, setZip] = useState(address?.zip);

  const save = () => {
    setLoading(true);

    const updates = {
      number: streetNumber,
      street: streetName,
      type: streetType,
      sec_unit_type: unitType,
      sec_unit_num: unitNumber,
      city,
      state,
      zip
    };

    if (!address) {
      postAddress({
        policyId: policy.policy_id,
        updates
      })
        .then(() => refreshPolicy())
        .then(() => {
          setLoading(false);
          toggleModal();
        });
    } else {
      patchAddress({
        policyId: policy.policy_id,
        addressId: address.address_id,
        updates
      })
        .then(() => refreshPolicy())
        .then(() => {
          setLoading(false);
          toggleModal();
        });
    }
  };

  return (
    visible && (
      <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
        <div>{address ? 'Edit' : 'Create'} Address</div>
        <div className={styles.form}>
          <TextField
            name={'streetNumber'}
            label={'Street Number'}
            value={streetNumber}
            onChange={e => setStreetNumber(e.target.value)}
          />
          <TextField
            name={'streetName'}
            label={'Street Name'}
            value={streetName}
            onChange={e => setStreetName(e.target.value)}
          />
          <TextField
            name={'streetType'}
            label={'Street Type (Rd, St, Ave, etc)'}
            value={streetType}
            onChange={e => setStreetType(e.target.value)}
          />
          <TextField
            name={'unitType'}
            label={'Unit Type'}
            value={unitType}
            onChange={e => setUnitType(e.target.value)}
          />
          <TextField
            name={'unitNumber'}
            label={'Unit Number'}
            value={unitNumber}
            onChange={e => setUnitNumber(e.target.value)}
          />
          <TextField
            name={'city'}
            label={'City'}
            value={city}
            onChange={e => setCity(e.target.value)}
          />
          <TextField
            name={'state'}
            label={'State'}
            value={state}
            maxLength={2}
            onChange={e => setState(e.target.value)}
          />
          <TextField
            name={'zip'}
            label={'Zip Code'}
            value={zip}
            maxLength={5}
            onChange={e => setZip(e.target.value)}
          />
        </div>
        <Button className={styles.modalButton} loading={loading} onClick={save}>
          Save
        </Button>
      </Modal>
    )
  );
};

EditAddress.propTypes = {
  policy: PropTypes.object,
  address: PropTypes.object,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  refreshPolicy: PropTypes.func
};

export default EditAddress;

import { createAction } from 'redux-actions';

export const GET_TRANSUNION = 'GET_TRANSUNION';
export const getTransunion = createAction(GET_TRANSUNION);

export const POST_TRANSUNION = 'POST_TRANSUNION';
export const postTransunion = createAction(POST_TRANSUNION);

export const GET_TRANSUNION_SUCCESS = 'GET_TRANSUNION_SUCCESS';
export const getTransunionSuccess = createAction(GET_TRANSUNION_SUCCESS);

import { CARRIERS, FIELDS, POLICY_TYPES } from 'woop-shared/canopy/enums';
import dayjs from 'dayjs';
import _ from 'lodash';

/**
 * A policy is recent if it was active within the 3 months
 *
 * @param {object} policy
 * @returns {boolean}
 */
function isRecentPolicy(policy) {
  const endDate = policy[FIELDS.POLICY.EXPIRY_DATE];
  if (!endDate) return false;
  return dayjs().subtract(3, 'months').isBefore(dayjs(endDate));
}

export function getPolicies(pulls) {
  return pulls.flatMap(p => p[FIELDS.POLICIES].map(policy => ({ ...policy, pull_id: p.pull_id })));
}

export function getRecentPolicies(pulls) {
  const policies = getPolicies(pulls);
  return policies.filter(isRecentPolicy);
}

export function getPolicyOptions(policies, autoOnly) {
  return _.uniqBy(policies, FIELDS.POLICY.CARRIER_POLICY_NUMBER)
    .filter(policy => {
      const isAutoPolicy = policy[FIELDS.POLICY.POLICY_TYPE] === POLICY_TYPES.AUTO;
      return autoOnly ? isAutoPolicy : !isAutoPolicy;
    })
    .map(policy => {
      return { label: getPolicyLabel(policy), value: policy[FIELDS.POLICY.CARRIER_POLICY_NUMBER] };
    });
}

function getPolicyLabel(policy) {
  const policyType = _.get(policy, FIELDS.POLICY.POLICY_TYPE, 'Unknown');
  const carrierName = CARRIERS[policy[FIELDS.POLICY.CARRIER_NAME]]?.name || 'Unknown';
  const expiryDate = policy[FIELDS.POLICY.EXPIRY_DATE]
    ? dayjs(policy[FIELDS.POLICY.EXPIRY_DATE]).format('YYYY-MM-DD')
    : 'Unknown';

  return `${policyType} - ${carrierName} - Expiry: ${expiryDate}`;
}

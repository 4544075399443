import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FINALIZATION_TYPES } from 'woop-shared/quotes/enums';
import { formatMoneyWithDecimals } from 'woop-shared/quotes/utils';
import {
  EMAIL_DATA_FIELDS,
  FOREIGN_KEYS,
  GENERAL_FIELDS,
  QUOTE_FIELDS
} from 'woop-shared/db/models';
import styles from './styles.css';
import Button from '../Button';
import Select from '../Select';
import Checkbox from '../Checkbox';
import Modal from '../Modal';
import { FINALIZATION_OPTIONS } from '../../constants/quoteset';
import ErrorText from '../ErrorText';
import CanImprove from '../FinalizeOrigination/CanImprove';
import { uniqBy } from '../../pages/Policy/utils';
import {
  getBestQuote,
  getSavings,
  getType,
  getCarrierNames,
  getSendQuotesCopy,
  createQuoteOption
} from './utils';
import { getReports } from '../../actions/reports';
import { getUser } from '../../actions/users';

const FinalizeQuotesetModal = ({
  visible,
  closing,
  toggleModal,
  handleFinalize,
  userId = null,
  quotes,
  coverageTypes
}) => {
  const [loading, error, carriers, reports, user] = useSelector(state => [
    state.loading,
    state.error,
    state.carriers,
    state.reports,
    state.user
  ]);
  const dispatch = useDispatch();

  const [type, setType] = useState();
  const [sendLegacy, setSendLegacy] = useState(true);
  const [isPortalUser, setIsPortalUser] = useState(false);

  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const isSelected = q => selectedQuotes.includes(q[GENERAL_FIELDS.ID]);
  const [quoteOptions, setQuoteOptions] = useState(
    quotes.filter(q => !q[QUOTE_FIELDS.IS_CURRENT_POLICY]).map(q => createQuoteOption(q, carriers))
  );
  const [savings, setSavings] = useState(0);
  const [currentPolicies, setCurrentPolicies] = useState([]);
  const [carrierCodes, setCarrierCodes] = useState([]);

  useEffect(() => {
    if (userId) dispatch(getUser(userId));
  }, [userId]);

  useEffect(() => {
    if (userId && !reports?.length) dispatch(getReports(userId));
  }, [userId]);

  useEffect(() => {
    if (user) {
      setIsPortalUser(true);
      setSendLegacy(false);
    }
  }, [user]);

  useEffect(() => {
    if (!selectedQuotes.length)
      setSelectedQuotes(
        coverageTypes.map(t => getBestQuote(quotes, t)?.[GENERAL_FIELDS.ID]).filter(Boolean)
      );

    setCurrentPolicies(quotes.filter(q => q[QUOTE_FIELDS.IS_CURRENT_POLICY]));
  }, [quotes]);

  useEffect(() => {
    setSavings(getSavings(currentPolicies, quotes.filter(isSelected), coverageTypes));

    setCarrierCodes(
      uniqBy(
        quotes.filter(isSelected).map(q => q[FOREIGN_KEYS.CARRIER_CODE]),
        code => code
      )
    );

    const selectedTypes = quotes.filter(isSelected).map(q => q[QUOTE_FIELDS.COVERAGE_TYPE]);
    const isSelectable = q =>
      !q[QUOTE_FIELDS.IS_CURRENT_POLICY] && !selectedTypes.includes(q[QUOTE_FIELDS.COVERAGE_TYPE]);

    setQuoteOptions(
      quotes.filter(q => isSelected(q) || isSelectable(q)).map(q => createQuoteOption(q, carriers))
    );
  }, [currentPolicies, selectedQuotes]);

  useEffect(() => setType(getType(currentPolicies, savings)), [savings]);

  const handleClick = () => {
    const payload = {
      type,
      compareQuoteIds: selectedQuotes,
      emailData: {
        [EMAIL_DATA_FIELDS.SAVINGS]:
          type === FINALIZATION_TYPES.CAN_BEAT_PRICE ? formatMoneyWithDecimals(savings) : null,
        [EMAIL_DATA_FIELDS.CARRIERS]: getCarrierNames(carrierCodes, carriers)
      },
      sendLegacy,
      callback: toggleModal
    };

    return handleFinalize(payload);
  };

  // Valid if we have a finalization type.
  const valid = !!type && (type !== FINALIZATION_TYPES.CAN_BEAT_PRICE || !!selectedQuotes.length);

  return (
    <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
      <div className={styles.header}>
        <h2>Finalize quoteset</h2>
      </div>

      <div className={styles.fields}>
        <Select
          options={quoteOptions}
          value={quoteOptions.filter(({ value }) => selectedQuotes.includes(value))}
          onChange={(_, val) => setSelectedQuotes(val)}
          prompt={'Quotes'}
          name={'quotes'}
          isMulti={true}
        />

        <Select
          options={FINALIZATION_OPTIONS}
          value={FINALIZATION_OPTIONS.find(({ value }) => value === type)}
          onChange={(_, val) => setType(val)}
          prompt={'Finalization type'}
          name={'type'}
          isValid={valid}
        />

        {type === FINALIZATION_TYPES.CAN_BEAT_PRICE && (
          <CanImprove savings={savings} carrierCodes={carrierCodes} />
        )}

        {isPortalUser ? (
          <div className={styles.notice}>{getSendQuotesCopy(reports)}</div>
        ) : (
          <Checkbox value={sendLegacy} onChange={() => setSendLegacy(!sendLegacy)}>
            Send email to customer
          </Checkbox>
        )}
      </div>

      <Button className={styles.button} loading={loading} onClick={handleClick} disabled={!valid}>
        Finalize ✓
      </Button>
      <div className={styles.error}>
        <ErrorText>{error?.message}</ErrorText>
      </div>
    </Modal>
  );
};

FinalizeQuotesetModal.propTypes = {
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleFinalize: PropTypes.func,
  validateQuoteset: PropTypes.func,
  userId: PropTypes.string,
  coverageTypes: PropTypes.arrayOf(PropTypes.string),
  quotes: PropTypes.arrayOf(PropTypes.object)
};

export default FinalizeQuotesetModal;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__modalForm--zAZWw{overflow-y:auto}.styles-module__modalForm--zAZWw>div{margin-bottom:1rem}.styles-module__modalForm--zAZWw{padding-bottom:2rem}.styles-module__quoteType--1Z7xJ{display:grid;grid-template-columns:1fr 1fr;grid-gap:10px;gap:10px;align-items:center}.styles-module__quoteType--1Z7xJ>div:last-child{margin-top:25px}.styles-module__footer--AEuuC{display:flex}.styles-module__footer--AEuuC .styles-module__modalButton--3zlnv{margin-left:auto;background-color:var(--color-blue-dark)}.styles-module__footer--AEuuC .styles-module__modalButton--3zlnv:focus{background-color:var(--color-blue-darkest)}", ""]);
// Exports
exports.locals = {
	"modalForm": "styles-module__modalForm--zAZWw",
	"quoteType": "styles-module__quoteType--1Z7xJ",
	"footer": "styles-module__footer--AEuuC",
	"modalButton": "styles-module__modalButton--3zlnv"
};
module.exports = exports;

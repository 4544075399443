import { connect } from 'react-redux';
import Pools from '.';
import { addPool, deletePool, updatePool } from '../../actions/pools';

const mapStateToProps = ({ pools }) => {
  return {
    pools: Object.values(pools)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addPool: pool => dispatch(addPool(pool)),
    deletePool: id => dispatch(deletePool(id)),
    updatePool: (id, updateObj) => dispatch(updatePool({ id, updateObj }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pools);

import dayjs from 'dayjs';
import { CARRIERS, FIELDS, POLICY_TYPES } from 'woop-shared/canopy/enums';
import { FOREIGN_KEYS } from 'woop-shared/db/models';
import { getDrivers } from '../../pages/Policy/utils';

export function getEffectiveRange(policy) {
  const format = date => (date ? dayjs(date).format('MM/DD/YYYY') : 'Unknown');
  const expiry = format(policy[FIELDS.POLICY.EXPIRY_DATE]);
  const effective = format(policy[FIELDS.POLICY.EFFECTIVE_DATE]);

  return `${effective} – ${expiry}`;
}

export function getCarrierName(policy) {
  return CARRIERS[policy[FIELDS.POLICY.CARRIER_NAME]]?.name || 'Unknown';
}

export function getVehicleAndDriverCount(policy) {
  const totalVehicles = policy?.vehicles?.length ?? 0;
  const totalDrivers = getDrivers(policy).length ?? 0;

  return {
    totalVehicles,
    totalDrivers
  };
}

export function getDecPageDate(pull, policyId) {
  const doc = pull[FIELDS.DOCUMENTS]?.find(d => d[FIELDS.DOCUMENT.POLICY_ID] === policyId);
  return doc?.[FIELDS.DOCUMENT.DATE_ADDED];
}

export const findReport = (reports, policyId) =>
  reports?.find(r => r[FOREIGN_KEYS.POLICY] === policyId);

export function formatPolicyType(policyType) {
  return policyType === POLICY_TYPES.HOME ? 'HOME' : policyType;
}

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import LinkText from '../../../../components/LinkText';
import Table2 from '../../../../components/Table2';

const EditableSection = ({ title, rows, columns, onAdd, onEdit, onDelete }) => {
  return (
    <section id={title}>
      <div className={styles.tableHeader}>
        <b>{title}</b>
        {onAdd && <LinkText onClick={onAdd}>Add +</LinkText>}
      </div>
      {rows.length ? (
        <Table2 rows={rows} columns={columns} onEdit={onEdit} onDelete={onDelete} />
      ) : (
        <div className={styles.empty}>No records.</div>
      )}
    </section>
  );
};

EditableSection.propTypes = {
  columns: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  rows: PropTypes.array,
  title: PropTypes.string
};

export default EditableSection;

export const QUOTES_ROUTE = '/quotes';
export const ORIGINATIONS_ROUTE = '/originations';
export const APPLICANTS_ROUTE = '/applicants';
const APPLICANT_ROUTE = '/applicant';
export const PAYMENTS_ROUTE = '/payments';
const CHARTS = '/charts';
const POLICY_ROUTE = '/policy';
const USERS_ROUTE = '/users';
const REPORTS_ROUTE = '/reports';
const IV_REPORTS_ROUTE = '/iv-reports';
const TRANSUNION_ROUTE = '/transunion';

export const ROUTES = {
  HOME: '/',
  QUOTE_APPLICATION: `/quote-application/:id`,
  QUOTE_REQUEST: `/quote-request/:id`,
  QUOTE_RESULT: `/quote-result/:id`,
  QUOTES_SEARCH: `${QUOTES_ROUTE}/search`,
  QUOTES_VIEW: `${QUOTES_ROUTE}/view/:quotesetId`,
  ORIGINATIONS_SEARCH: `${ORIGINATIONS_ROUTE}/search`,
  ORIGINATIONS_CREATE: `${ORIGINATIONS_ROUTE}/create`,
  ORIGINATIONS_VIEW: `${ORIGINATIONS_ROUTE}/view/:oid`,
  APPLICANTS_SEARCH: `${APPLICANTS_ROUTE}/search`,
  APPLICANTS_VIEW: `${APPLICANTS_ROUTE}/view`,
  APPLICANT_VIEW: `${APPLICANT_ROUTE}/view/:journeyId`,
  PAYMENTS_VIEW: `${PAYMENTS_ROUTE}/view/:quotesetId`,
  PAYMENTS_SEARCH: `${PAYMENTS_ROUTE}/search/`,
  USERS_VIEW: `${USERS_ROUTE}/view/:userId/`,
  REPORTS_VIEW: `${REPORTS_ROUTE}/view/:reportId/`,
  CHARTS,
  POLICY: `${POLICY_ROUTE}/:policyId`,
  IV_REPORTS: `${IV_REPORTS_ROUTE}/:reportId`,
  TRANSUNION: `${TRANSUNION_ROUTE}/:journeyId`
};

export function addIdToRoute(path, id) {
  return path.replace(/:[^/]*/, id);
}

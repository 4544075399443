import React from 'react';
import PropTypes from 'prop-types';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import styles from './styles.css';
import TextField from '../../../../../components/TextField';
import Select from '../../../../../components/Select';
import {
  createMultiSelectValue,
  createOptionsFromEnum
} from '../../../../../components/Select/utils';
import PhoneField from '../../../../../components/PhoneField';
import Address from './Components/Address';
import EffectiveDates from './Components/EffectiveDates';

const Fields = ({ updates, setUpdates }) => {
  const updateHandler = (name, value) => {
    const _updates = { ...updates, [name]: value };
    setUpdates(_updates);
  };

  const address = updates[PROMPT_NAMES.ADDRESS] || {};

  return (
    <div className={styles.wrapper}>
      <Select
        isMulti={true}
        prompt="Line(s) of business"
        options={createOptionsFromEnum(COVERAGE_TYPES)}
        name={PROMPT_NAMES.COVERAGE_TYPE}
        onChange={updateHandler}
        value={createMultiSelectValue(updates[PROMPT_NAMES.COVERAGE_TYPE])}
      />
      <TextField
        name={PROMPT_NAMES.FIRST_NAME}
        label={`First name`}
        onChange={e => updateHandler(e.target.name, e.target.value)}
        value={updates[PROMPT_NAMES.FIRST_NAME]}
      />
      <TextField
        name={PROMPT_NAMES.LAST_NAME}
        label={`Last name`}
        onChange={e => updateHandler(e.target.name, e.target.value)}
        value={updates[PROMPT_NAMES.LAST_NAME]}
      />
      <TextField
        name={PROMPT_NAMES.EMAIL}
        label={`Email`}
        onChange={e => updateHandler(e.target.name, e.target.value)}
        value={updates[PROMPT_NAMES.EMAIL]}
      />
      <PhoneField
        name={PROMPT_NAMES.PHONE}
        label={`Phone`}
        onChange={updateHandler}
        value={updates[PROMPT_NAMES.PHONE]}
      />
      <EffectiveDates updates={updates} updateHandler={updateHandler} />
      <Address address={address} updateHandler={updateHandler} />
    </div>
  );
};

Fields.propTypes = {
  updates: PropTypes.object,
  setUpdates: PropTypes.func
};

export default Fields;

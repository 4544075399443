import { connect } from 'react-redux';
import PaymentCard from './';
import { failPayment } from '../../actions/payments';

const mapStateToProps = ({ loading, error }) => {
  return {
    loading,
    error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    failPayment: paymentId => dispatch(failPayment(paymentId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCard);

import axios from 'axios';
import { getJourneyUri, getOpsJourneyUri } from '../utils/api.js';
import { concatUri } from '../utils/url.js';

const RESOURCES = {
  CANOPY: 'canopy'
};

const ENDPOINTS = {
  SEARCH: 'search',
  SAVE: 'save',
  MAP: 'map'
};

export const searchApplicants = async params => {
  const { data } = await axios.get(concatUri(getJourneyUri(), ENDPOINTS.SEARCH), { params });
  return data;
};

export const getJourney = async id => {
  const { data } = await axios.get(`${getJourneyUri()}/${id}`);
  return data;
};

export const postJourney = async originationId => {
  const { data } = await axios.post(getOpsJourneyUri(), { originationId });
  return data;
};

export const saveJourney = async (id, uiData) => {
  const uri = concatUri(getJourneyUri(), [id, ENDPOINTS.SAVE]);
  const body = { uiData };
  const { data } = await axios.patch(uri, body);
  return data;
};

export const mapCanopy = async id => {
  const uri = concatUri(getJourneyUri(), [id, RESOURCES.CANOPY, ENDPOINTS.MAP]);
  await axios.patch(uri);
};

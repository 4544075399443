import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { formatInt, stripNonDigits } from 'woop-shared/quotes/utils';
import TextField from '../TextField';

const saveAsInteger = val => {
  const num = parseInt(stripNonDigits(val));
  return num || num === 0 ? num : null;
};

// eslint-disable-next-line react/display-name
const NumberField = forwardRef(
  (
    {
      name,
      value,
      isValid,
      showValidation,
      label,
      prompt,
      placeholder,
      readOnly,
      enforceInteger = false,
      onChange = () => {},
      displayFormatter = formatInt,
      saveFormatter = enforceInteger ? saveAsInteger : stripNonDigits,
      max = null
    },
    ref
  ) => {
    const [formattedValue, setFormattedValue] = useState(displayFormatter(value));

    useEffect(() => {
      setFormattedValue(displayFormatter(value));
    }, [value]);

    const handleInput = e => {
      if (max && parseInt(stripNonDigits(e.target.value)) > max) return;

      const formattedVal = displayFormatter(e.target.value);
      setFormattedValue(formattedVal);
      onChange(name, saveFormatter(formattedVal));
    };

    return (
      <TextField
        ref={ref}
        type="text"
        name={name}
        label={label}
        value={formattedValue}
        isValid={isValid}
        showValidation={showValidation}
        placeholder={placeholder}
        prompt={prompt}
        onChange={handleInput}
        readOnly={readOnly}
      />
    );
  }
);

export default NumberField;

NumberField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isValid: PropTypes.bool,
  showValidation: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  prompt: PropTypes.string,
  onChange: PropTypes.func,
  displayFormatter: PropTypes.func,
  saveFormatter: PropTypes.func,
  enforceInteger: PropTypes.bool,
  max: PropTypes.number
};

import classNames from 'classnames/bind';
import { HiExclamationCircle } from 'react-icons/hi';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';
import { IV_REPORT_FIELDS, VEHICLE_FIELDS } from 'woop-shared/origination/fields';
import { US_STATES } from 'woop-shared/data/states';
import { VEHICLE_OWNERSHIP_STATUS } from 'woop-shared/origination/enums';
import { FOREIGN_KEYS } from 'woop-shared/db/models';
import Card from '../../components/Card';
import styles from './styles.css';
import Summary from './components/Summary';
import Coverages from './components/Coverages';
import Select from '../../components/Select';
import { createSelectOption, createSelectOptions } from '../../components/Select/utils';
import { getFinancingCompanies } from '../../api/financing-companies';
import TextField from '../../components/TextField';
import RadioGroup from '../../components/RadioGroup';
import LinkText from '../../components/LinkText';
import Button from '../../components/Button';
import EditCoverage from './components/EditCoverage';
import { useModalControls } from '../../hooks/modal-controls';
import { getIvReport, patchIvReport, sendIvReport } from '../../api/iv-reports';
import { getJourney } from '../../api/journeys';
import { getName, hasRequiredCoverages, hasRequiredFields, getRequiredCoverages } from './utils';
import { prettifyDatetime } from '../../utils/date';
import { addIdToRoute, ROUTES } from '../../router/routes';
import { CARRIER_OPTIONS } from '../../constants/carriers';

const cx = classNames.bind(styles);

const vinOnPolicyOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

const validityOptions = [
  { label: 'True', value: true },
  { label: 'False', value: false },
  { label: 'Unsure', value: null }
];

const IvReportsView = () => {
  const { reportId } = useParams();
  const [uiData, setUiData] = useState();
  const [reportState, setReportState] = useState();
  const [financingCompanies, setFinancingCompanies] = useState([]);
  const [selectedCoverage, setSelectedCoverage] = useState();
  const [visible, closing, toggleModal] = useModalControls(false);
  const [filledCoverages, setFilledCoverages] = useState(false);
  const [showReqCovsError, setShowReqCovsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const refreshReport = async () =>
    getIvReport({ reportId })
      .then(r => {
        setReportState(r);
        return getJourney(r[FOREIGN_KEYS.JOURNEY]);
      })
      .then(j => setUiData(j?.uiData));

  useEffect(() => {
    if (!reportState || filledCoverages || hasRequiredCoverages(reportState)) return;

    setLoading(true);

    setFilledCoverages(true);
    getRequiredCoverages(reportState)
      .then(covs => {
        if (!covs) return setShowReqCovsError(true);
        setShowReqCovsError(false);
        setReportState({ ...reportState, [IV_REPORT_FIELDS.REQUIRED_COVERAGES]: covs });
      })
      .catch(() => setShowReqCovsError(true))
      .finally(() => setLoading(false));
  }, [reportState, filledCoverages]);

  useEffect(() => {
    refreshReport();
    getFinancingCompanies().then(companies => {
      const names = companies.map(c => c.name);
      setFinancingCompanies(createSelectOptions(names));
    });
  }, []);

  const setField = field => (_, value) => setReportState({ ...reportState, [field]: value });

  const setVehicleField = vehicleField => (_, value) => {
    const vehicle = { ...reportState[IV_REPORT_FIELDS.VEHICLE], [vehicleField]: value };
    setReportState({ ...reportState, [IV_REPORT_FIELDS.VEHICLE]: vehicle });
  };

  const handleSetVin = event => {
    const vehicle = {
      ...reportState[IV_REPORT_FIELDS.VEHICLE],
      [VEHICLE_FIELDS.VIN]: event.target.value
    };
    setReportState({ ...reportState, [IV_REPORT_FIELDS.VEHICLE]: vehicle });
  };

  const handleSelectCoverage = coverage => () => {
    setSelectedCoverage(coverage);
    toggleModal();
  };

  const handleUpdateCoverage = updates =>
    Object.entries(updates).forEach(([k, v]) => (selectedCoverage[k] = v));

  const saveReport = async () => {
    patchIvReport({
      reportId,
      reportData: reportState
    })
      .then(() => refreshReport())
      .then(() => window.scrollTo(0, 0));
  };

  const sendReport = async () => {
    if (reportState[IV_REPORT_FIELDS.VALID] !== null && !hasRequiredFields(reportState))
      return alert(
        `Report is missing at least one of the following required fields and cannot be sent:

          - Required coverages
          - Financing company
          - State
          - Ownership type
          - VIN on policy
          - Comp and collision cannot be $0
        `
      );

    if (!confirm('Send this IV report to the customer?')) return;

    patchIvReport({
      reportId,
      reportData: reportState
    })
      .then(() => sendIvReport({ reportId }))
      .then(() => refreshReport())
      .then(() => window.scrollTo(0, 0));
  };

  return reportState ? (
    <Card className={styles.wrapper}>
      <header className={styles.pageHeader}>
        IV Report for <b>{getName(uiData)}</b>
      </header>
      {reportState[IV_REPORT_FIELDS.SENT] && (
        <section>
          <div className={styles.sent}>
            <div>
              <FiCheckCircle className={styles.checkIcon} />
            </div>
            <div>
              IV Report sent on <b>{prettifyDatetime(reportState?.[IV_REPORT_FIELDS.SENT])}</b>.
            </div>
          </div>
        </section>
      )}
      <div className={styles.tableHeader}>
        <div>Summary Information</div>
        <LinkText
          href={addIdToRoute(ROUTES.APPLICANT_VIEW, reportState[FOREIGN_KEYS.JOURNEY])}
          external
        >
          View Applicant
        </LinkText>
      </div>
      <Summary report={reportState} uiData={uiData} />
      <div className={styles.tableHeader}>Current Coverages</div>
      <Coverages
        coverages={reportState[IV_REPORT_FIELDS.COVERAGES]}
        selectCoverage={handleSelectCoverage}
      />
      <div className={cx('tableHeader', 'justified')}>
        Required Coverages{' '}
        {filledCoverages && showReqCovsError && (
          <span className={styles.error}>
            <HiExclamationCircle /> Could not pre-fill required coverages
          </span>
        )}
      </div>
      {loading ? (
        <Card className={styles.loading}>{<div>{`Loading...`}</div>}</Card>
      ) : (
        <Coverages
          coverages={reportState[IV_REPORT_FIELDS.REQUIRED_COVERAGES]}
          selectCoverage={handleSelectCoverage}
        />
      )}
      <div className={styles.inputSection}>
        <Select
          prompt={'Financing Company'}
          name={'financingCompany'}
          placeholder={'Select Financing Company'}
          value={createSelectOption(
            reportState[IV_REPORT_FIELDS.VEHICLE]?.[VEHICLE_FIELDS.FINANCING_LIEN_HOLDER]
          )}
          options={financingCompanies}
          onChange={setVehicleField(VEHICLE_FIELDS.FINANCING_LIEN_HOLDER)}
          createable={true}
        />

        <Select
          prompt={'State'}
          name={'state'}
          placeholder={'Select State'}
          value={createSelectOption(reportState[IV_REPORT_FIELDS.STATE])}
          options={US_STATES}
          onChange={setField(IV_REPORT_FIELDS.STATE)}
        />

        <Select
          prompt={'Insurance Company'}
          name={'insuranceCompany'}
          placeholder={'Select Insurance Company'}
          value={createSelectOption(reportState[IV_REPORT_FIELDS.INSURANCE_COMPANY])}
          options={CARRIER_OPTIONS}
          onChange={setField(IV_REPORT_FIELDS.INSURANCE_COMPANY)}
          createable={true}
        />

        <Select
          prompt={'Ownership Type'}
          name={'ownershipType'}
          placeholder={'Select Ownership Type'}
          value={createSelectOption(
            reportState[IV_REPORT_FIELDS.VEHICLE]?.[VEHICLE_FIELDS.VEHICLE_OWNERSHIP_STATUS]
          )}
          options={createSelectOptions(Object.keys(VEHICLE_OWNERSHIP_STATUS))}
          onChange={setVehicleField(VEHICLE_FIELDS.VEHICLE_OWNERSHIP_STATUS)}
        />

        <TextField
          prompt={'New VIN'}
          name={'newVin'}
          placeholder={'Enter New VIN'}
          value={reportState[IV_REPORT_FIELDS.VEHICLE]?.[VEHICLE_FIELDS.VIN]}
          onChange={handleSetVin}
        />

        <RadioGroup
          name={'vinOnPolicy'}
          prompt={'New VIN is on the policy'}
          value={reportState[IV_REPORT_FIELDS.VIN_ON_POLICY]}
          options={vinOnPolicyOptions}
          onChange={setField(IV_REPORT_FIELDS.VIN_ON_POLICY)}
        />

        <RadioGroup
          name={'coveragesMeetRequirements'}
          prompt={'The current coverages meet the required coverages'}
          value={reportState[IV_REPORT_FIELDS.VALID]}
          options={validityOptions}
          onChange={setField(IV_REPORT_FIELDS.VALID)}
        />
      </div>
      <div className={styles.buttons}>
        <Button className={styles.saveButton} onClick={saveReport}>
          Save
        </Button>
        <Button className={styles.sendButton} onClick={sendReport}>
          Send
        </Button>
      </div>
      <EditCoverage
        coverage={selectedCoverage}
        visible={visible}
        closing={closing}
        toggleModal={toggleModal}
        updateCoverage={handleUpdateCoverage}
      />
    </Card>
  ) : (
    <Card className={styles.wrapper} />
  );
};

export default IvReportsView;

import { ADDRESS_FIELDS, ORIGINATION_FIELDS } from 'woop-shared/origination/fields';
import { APPLICANT_TYPES } from 'woop-shared/origination/enums';
import dayjs from 'dayjs';
import {
  APPLICANT_FIELDS,
  APPLICANT_NUMBER_FIELDS,
  APPLICANT_FIELDS_EXTRA,
  PROPERTY_NUMBER_FIELDS,
  AUTO_NUMBER_FIELDS,
  VEHICLE_NUMBER_FIELDS,
  INCIDENT_FIELDS_EXTRA,
  VEHICLE_FIELDS_EXTRA,
  PROPERTY_INCIDENT_FIELDS_EXTRA,
  POOL_FIELDS_EXTRA,
  PROPERTY_FIELDS
} from '../constants/origination';
import { handleSwaggerNull } from './api';
import { AUTO_FIELDS } from '../pages/QuoteApplication/constants';

/**
 * Format Property data for Origination svc.
 *
 * @param {object} property
 * @returns {object}
 */
function formatPropertyData(property) {
  const propertyCopy = { ...property };

  PROPERTY_NUMBER_FIELDS.forEach(key => {
    propertyCopy[key] = formatNumField(propertyCopy[key]);
  });

  // Handle swagger nulls.
  Object.entries(propertyCopy).forEach(([key, val]) => {
    propertyCopy[key] = handleSwaggerNull(val);
  });

  return propertyCopy;
}

/**
 * Format auto data for Origination svc.
 *
 * @param {object} auto
 * @returns {object}
 */
function formatAutoData(auto = {}) {
  const autoCopy = { ...auto };

  AUTO_NUMBER_FIELDS.forEach(key => {
    autoCopy[key] = formatNumField(autoCopy[key]);
  });

  // Handle swagger nulls.
  Object.entries(autoCopy).forEach(([key, val]) => {
    autoCopy[key] = handleSwaggerNull(val);
  });

  return autoCopy;
}

/**
 * Format Vehicle for Origination svc.
 *
 * @param {object} vehicle
 * @returns {object}
 */
function formatVehicle(vehicle) {
  const vehicleCopy = { ...vehicle };

  VEHICLE_NUMBER_FIELDS.forEach(key => {
    vehicleCopy[key] = formatNumField(vehicleCopy[key]);
  });

  // Remove any UI-only fields
  Object.values(VEHICLE_FIELDS_EXTRA).forEach(key => {
    delete vehicleCopy[key];
  });

  // Handle swagger nulls.
  Object.entries(vehicleCopy).forEach(([key, val]) => {
    vehicleCopy[key] = handleSwaggerNull(val);
  });

  return vehicleCopy;
}

/**
 * Format auto incidents for Origination svc.
 *
 * @param {object} incident
 * @returns {object}
 */
function formatIncident(incident) {
  const incidentCopy = { ...incident };

  // Remove any UI-only fields
  Object.values(INCIDENT_FIELDS_EXTRA).forEach(key => {
    delete incidentCopy[key];
  });

  // Handle swagger nulls.
  Object.entries(incidentCopy).forEach(([key, val]) => {
    incidentCopy[key] = handleSwaggerNull(val);
  });

  return incidentCopy;
}

/**
 * Format Property Incident for Origination svc.
 *
 * @param {object} propertyIncident
 * @returns {object}
 */
function formatPropertyIncident(propertyIncident) {
  const propertyIncidentCopy = { ...propertyIncident };

  // Remove any UI-only fields
  Object.values(PROPERTY_INCIDENT_FIELDS_EXTRA).forEach(key => {
    delete propertyIncidentCopy[key];
  });

  // Handle swagger nulls.
  Object.entries(propertyIncidentCopy).forEach(([key, val]) => {
    propertyIncidentCopy[key] = handleSwaggerNull(val);
  });

  return propertyIncidentCopy;
}

/**
 * Format Pool for Origination svc.
 *
 * @param {object} pool
 * @returns {object}
 */
function formatPool(pool) {
  const poolCopy = { ...pool };

  // Remove any UI-only fields
  Object.values(POOL_FIELDS_EXTRA).forEach(key => {
    delete poolCopy[key];
  });

  // Handle swagger nulls.
  Object.entries(poolCopy).forEach(([key, val]) => {
    poolCopy[key] = handleSwaggerNull(val);
  });

  return poolCopy;
}

/**
 * Format Applicant for Origination
 *
 * @param {object} applicant
 * @returns {object}
 */
function formatApplicant(applicant) {
  const applicantCopy = { ...applicant };
  const phoneNumber = applicantCopy[APPLICANT_FIELDS.PHONE];
  const phoneType = applicantCopy[APPLICANT_FIELDS.PHONE_TYPE];

  // Format Number Fields
  APPLICANT_NUMBER_FIELDS.forEach(key => {
    applicantCopy[key] = formatNumField(applicantCopy[key]);
  });

  // Remove any UI-only fields
  Object.values(APPLICANT_FIELDS_EXTRA).forEach(key => {
    delete applicantCopy[key];
  });

  // Handle swagger nulls.
  Object.entries(applicantCopy).forEach(([key, val]) => {
    applicantCopy[key] = handleSwaggerNull(val);
  });

  return {
    ...applicantCopy,
    [APPLICANT_FIELDS.PHONE]: [{ phoneType, primaryPhone: true, number: phoneNumber }]
  };
}

/**
 * Format addresss object.
 *
 * @param {object} address Address object.
 * @returns {object}
 */
function formatAddress(address) {
  const addressCopy = { ...address };

  // Handle swagger nulls.
  Object.entries(addressCopy).forEach(([key, val]) => {
    addressCopy[key] = handleSwaggerNull(val);
  });

  return addressCopy;
}

export function formatOriginationData({
  origination,
  applicants,
  addresses,
  vehicles,
  incidents,
  propertyIncidents,
  pools
}) {
  const autoData = origination[ORIGINATION_FIELDS.AUTO_INFO];
  const propertyData = origination[ORIGINATION_FIELDS.PROPERTY_INFO];

  const originationData = {
    [ORIGINATION_FIELDS.COVERAGE_TYPES]: origination[ORIGINATION_FIELDS.COVERAGE_TYPES],
    [ORIGINATION_FIELDS.EFFECTIVE_DATE]: origination[ORIGINATION_FIELDS.EFFECTIVE_DATE],
    [ORIGINATION_FIELDS.AUTO_EFFECTIVE_DATE]: origination[ORIGINATION_FIELDS.AUTO_EFFECTIVE_DATE],
    [ORIGINATION_FIELDS.APPLICANTS]: Object.values(applicants).map(a => formatApplicant(a)),
    [ORIGINATION_FIELDS.ADDRESSES]: Object.values(addresses).map(formatAddress),
    [ORIGINATION_FIELDS.PROPERTY_INFO]: {
      ...formatPropertyData(propertyData),
      [ORIGINATION_FIELDS.PROPERTY_INCIDENTS]:
        Object.values(propertyIncidents).map(formatPropertyIncident),
      [ORIGINATION_FIELDS.POOLS]: Object.values(pools).map(formatPool)
    },
    [ORIGINATION_FIELDS.AUTO_INFO]: {
      ...formatAutoData(autoData),
      [ORIGINATION_FIELDS.VEHICLES]: Object.values(vehicles).map(formatVehicle),
      [ORIGINATION_FIELDS.INCIDENTS]: Object.values(incidents).map(formatIncident)
    }
  };

  return originationData;
}

export function getPrimaryApplicant(applicants) {
  if (!applicants) return;
  return applicants.find(a =>
    a?.[APPLICANT_FIELDS.APPLICANT_TYPE]?.includes(APPLICANT_TYPES.APPLICANT)
  );
}

/**
 * Cast strings to numbers, handling falsy-zeroes.
 *
 * @param {string|number} val A raw value.
 * @returns {number}
 */
function formatNumField(val) {
  if (val || val?.toString() === '0') return parseFloat(val);
  return undefined;
}

/**
 *
 * @param {object} originationData
 * @returns {object[]} React Select Option Array
 */
export function getAddressOptions(originationData) {
  return (
    originationData[ORIGINATION_FIELDS.ADDRESSES]
      ?.map(a => ({ value: a[ORIGINATION_FIELDS.ID], label: a[ADDRESS_FIELDS.COMPOSITE] }))
      .filter(Boolean) || []
  );
}

export function isEarlyMoveInDate(date) {
  const lessThan6MonthsAgo = dayjs(date).isAfter(dayjs().subtract(6, 'month'));
  return lessThan6MonthsAgo;
}

export function getApplicantProperty(quoteApp, applicant) {
  return quoteApp[ORIGINATION_FIELDS.PROPERTIES]?.find(
    p => p[PROPERTY_FIELDS.ADDRESS_ID] === applicant[APPLICANT_FIELDS.ADDRESS_ID]
  );
}

export function getCurrentPolicyNumber(quoteApplication, { propertyId, isAuto }) {
  return isAuto
    ? quoteApplication[ORIGINATION_FIELDS.AUTO_INFO]?.[AUTO_FIELDS.CURRENT_POLICY_NUMBER]
    : quoteApplication[ORIGINATION_FIELDS.PROPERTIES]?.find(p => p.id === propertyId)?.[
        PROPERTY_FIELDS.CURRENT_POLICY_NUMBER
      ];
}

import React from 'react';
import PropTypes from 'prop-types';
import { PROPERTY_INCIDENT_DESCS } from 'woop-shared/origination/enums';
import styles from '../../pages/CreateOrigination/CreateOrigination.css';
import LinkText from '../LinkText';
import DateField from '../DateField';
import Select from '../Select';
import MoneyField from '../MoneyField';
import { createSelectOption, createOptionsFromEnum } from '../Select/utils';
import { PROPERTY_INCIDENT_FIELDS as FIELDS } from '../../constants/origination';
import { SUB_SECTIONS } from '../../constants/table-of-contents';
import Card from '../Card';

const sectionId = SUB_SECTIONS.PROPERTY_INCIDENTS;

const PROPERTY_INCIDENT_DESCS_OPTIONS = createOptionsFromEnum(PROPERTY_INCIDENT_DESCS);

const PropertyIncidents = ({
  propertyIncidents,
  addPropertyIncident,
  deletePropertyIncident,
  updatePropertyIncident
}) => {
  const createPropertyIncident = () => {
    const lastIncidentId = propertyIncidents?.[propertyIncidents.length - 1]?.[FIELDS.ID] || 0;
    const propertyIncident = { [FIELDS.ID]: lastIncidentId + 1 };
    return propertyIncident;
  };
  const handleDelete = propertyIncident => deletePropertyIncident(propertyIncident[FIELDS.ID]);
  const handleAdd = () => addPropertyIncident(createPropertyIncident());

  const handleChangeEvent = propertyIncident => e => {
    const { name, value } = e.target;
    const id = propertyIncident[FIELDS.ID];
    updatePropertyIncident(id, { [name]: value });
  };
  const handleChange = propertyIncident => (name, value) => {
    const id = propertyIncident[FIELDS.ID];
    updatePropertyIncident(id, { [name]: value });
  };

  return (
    <Card id={sectionId}>
      <h3 className={styles.heading}>Property Incidents</h3>
      {propertyIncidents.map(propertyIncident => {
        return (
          <div key={propertyIncident[FIELDS.ID]} className={styles.fieldWrapper}>
            <div className={styles.labelWrapper}>
              <LinkText onClick={() => handleDelete(propertyIncident)}>Delete</LinkText>
              <div className={styles.label}>{propertyIncident[FIELDS.ID]}</div>
            </div>
            <DateField
              name={FIELDS.INCIDENT_DATE}
              prompt={'Property Incident Date'}
              value={propertyIncident[FIELDS.INCIDENT_DATE]}
              onChange={handleChangeEvent(propertyIncident)}
            />
            <Select
              prompt={'Property Incident Description'}
              name={FIELDS.INCIDENT_DESC}
              options={PROPERTY_INCIDENT_DESCS_OPTIONS}
              onChange={handleChange(propertyIncident)}
              value={createSelectOption(propertyIncident[FIELDS.INCIDENT_DESC])}
              isValid={true}
            />
            <MoneyField
              label="Property Incident Amount"
              name={FIELDS.INCIDENT_AMOUNT}
              value={propertyIncident[FIELDS.INCIDENT_AMOUNT]}
              onChange={handleChange(propertyIncident)}
            />
          </div>
        );
      })}
      <LinkText onClick={handleAdd}>Add Property Incident</LinkText>
    </Card>
  );
};

PropertyIncidents.propTypes = {
  addPropertyIncident: PropTypes.func,
  deletePropertyIncident: PropTypes.func,
  propertyIncidents: PropTypes.array,
  updatePropertyIncident: PropTypes.func
};

export default PropertyIncidents;

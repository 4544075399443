// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--1yG1R{display:inline-flex;align-items:center}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--1yG1R"
};
module.exports = exports;

import { COVERAGE_SYNONYM_MAP } from 'woop-shared/canopy/maps';
import {
  AUTO_COVERAGES,
  COVERAGES,
  COVERAGE_NAMES,
  HOME_COVERAGES
} from 'woop-shared/canopy/coverages';

/**
 * Create selection options for a given set of canopy coverages. Filters out synonym coverages.
 *
 * @param {object} coverages A set of canopy coverages
 * @returns {object[]} [{ label: 'Collision', value: 'COLLISION' }, ...]
 */
const createCoverageOptions = coverages => {
  const options = Object.values(coverages)
    .map(coverageKey => ({ value: coverageKey, label: COVERAGE_NAMES[coverageKey] }))
    .filter(({ value, label }) => label && !COVERAGE_SYNONYM_MAP[value]);
  return options;
};

export const HOME_COVERAGE_OPTIONS = createCoverageOptions(HOME_COVERAGES);
export const AUTO_COVERAGE_OPTIONS = createCoverageOptions(AUTO_COVERAGES);

export const AUTO_POLICY_COVERAGES = new Set([
  COVERAGES.BODILY_INJURY_LIABILITY,
  COVERAGES.PROPERTY_DAMAGE_LIABILITY,
  COVERAGES.UNDERINSURED_MOTORIST_BODILY_INJURY_LIABILITY,
  COVERAGES.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE_LIABILITY,
  COVERAGES.UNINSURED_MOTORIST_BODILY_INJURY_LIABILITY,
  COVERAGES.UNINSURED_MOTORIST_PROPERTY_DAMAGE_LIABILITY,
  COVERAGES.UNINSURED_AND_UNDERINSURED_MOTORISTS,
  COVERAGES.MEDICAL_PAYMENTS,
  COVERAGES.FULL_TORT,
  COVERAGES.LIMITED_TORT
]);

export const VEHICLE_COVERAGES = Object.fromEntries(
  Object.entries(AUTO_COVERAGES).filter(([key]) => !AUTO_POLICY_COVERAGES.has(key))
);

export const VEHICLE_COVERAGE_OPTIONS = createCoverageOptions(VEHICLE_COVERAGES);

import { GET_CARRIERS_SUCCESS } from '../actions/carriers';

function carriers(state = [], action) {
  switch (action.type) {
    case GET_CARRIERS_SUCCESS: {
      return action?.payload
        ?.map(c => ({
          ...c,
          name: c.name,
          code: c.code
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    }
    default:
      return state;
  }
}

export default carriers;

import React from 'react';
import PropTypes from 'prop-types';
import { QUOTESET_FIELDS, GENERAL_FIELDS } from 'woop-shared/db/models';
import styles from './QuotesetSummary.css';
import Card from '../Card';
import { addIdToRoute, ROUTES } from '../../router/routes';
import LinkText from '../LinkText';
import Table from '../Table';

const QuotesetSummary = ({
  className,
  quoteset,
  journeySummary: { firstName, lastName, stateAbbrev, coverageTypes, originationId, journeyId }
}) => (
  <Card className={className}>
    <h3>Quoteset Summary</h3>
    <Table
      rows={[
        {
          label: 'Applicant Name',
          value: `${firstName} ${lastName}`
        },
        {
          label: 'Applicant State',
          value: stateAbbrev
        },
        {
          label: 'Coverage Type(s)',
          value: coverageTypes.join(' + ')
        },
        {
          label: 'Quoteset ID',
          value: quoteset[GENERAL_FIELDS.ID]
        },
        {
          label: 'Quoteset Status',
          value: quoteset[QUOTESET_FIELDS.STATUS]
        }
      ]}
    />
    <div className={styles.links}>
      {journeyId && (
        <LinkText href={addIdToRoute(ROUTES.APPLICANT_VIEW, journeyId)} external>
          View Application Data
        </LinkText>
      )}
      {originationId && (
        <LinkText href={addIdToRoute(ROUTES.ORIGINATIONS_VIEW, originationId)} external>
          View Origination
        </LinkText>
      )}
      <LinkText href={addIdToRoute(ROUTES.PAYMENTS_VIEW, quoteset[GENERAL_FIELDS.ID])} external>
        View Payments
      </LinkText>
    </div>
  </Card>
);

QuotesetSummary.propTypes = {
  className: PropTypes.string,
  journeySummary: PropTypes.shape({
    coverageTypes: PropTypes.array,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    originationId: PropTypes.string,
    journeyId: PropTypes.string,
    stateAbbrev: PropTypes.string
  }),
  quoteset: PropTypes.object
};

export default QuotesetSummary;

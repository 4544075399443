import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  SEARCH_APPLICANTS,
  applicantsFound,
  GET_JOURNEY,
  getJourneySuccess,
  SAVE_JOURNEY,
  MAP_CANOPY
} from '../actions/journeys';
import { searchApplicants, getJourney, saveJourney, mapCanopy } from '../api/journeys';
import { setError } from '../actions/error';
import { setLoading } from '../actions/loading';
import { ROUTES } from '../router/routes';
import { error } from '../utils/logger';
import { addToRecentApplicantSearches } from '../utils/local-storage';
import { addQueryStringParamsToRoute } from '../utils/url';

function maybeAddToRecentApplicantSearches(applicants, searchCriteria) {
  if (applicants?.length > 0) addToRecentApplicantSearches(searchCriteria);
}

function* handleSearchApplicants(action) {
  yield put(setLoading(true));
  try {
    const applicants = yield call(searchApplicants, action.payload);
    maybeAddToRecentApplicantSearches(applicants, action.payload);
    const applicantsRoute = addQueryStringParamsToRoute(ROUTES.APPLICANTS_VIEW, action.payload);
    yield put(applicantsFound(applicants));
    yield put(push(applicantsRoute));
  } catch (e) {
    error(e);
    yield put(setError({ message: 'Could not find any applicants.' }));
  }
  yield put(setLoading(false));
}

export function* watchSearchApplicants() {
  yield takeLatest(SEARCH_APPLICANTS, handleSearchApplicants);
}

function* handleGetJourney(action) {
  yield put(setLoading(true));
  try {
    const journey = yield call(getJourney, action.payload);
    yield put(getJourneySuccess(journey));
  } catch (e) {
    error(e, action);
    yield put(setError({ message: 'Could not find the journey.' }));
  }
  yield put(setLoading(false));
}

export function* watchGetJourney() {
  yield takeLatest(GET_JOURNEY, handleGetJourney);
}

function* handleSaveJourney(action) {
  yield put(setLoading(true));
  try {
    const { journeyId, uiData, callback } = action.payload;
    yield call(saveJourney, journeyId, uiData);
    if (callback) callback();
  } catch (e) {
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchSaveJourney() {
  yield takeLatest(SAVE_JOURNEY, handleSaveJourney);
}

function* handleMapCanopy(action) {
  yield put(setLoading(true));
  try {
    const { journeyId, callback } = action.payload;
    yield call(mapCanopy, journeyId);
    if (callback) callback();
  } catch (e) {
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchMapCanopy() {
  yield takeLatest(MAP_CANOPY, handleMapCanopy);
}

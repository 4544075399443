import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames/bind';
import styles from './Loader.css';

const cx = classnames.bind(styles);

const Loader = ({ className }) => <div className={cx('loader', className)}></div>;

Loader.propTypes = {
  className: PropTypes.string
};

export default Loader;

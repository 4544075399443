import React from 'react';
import PropTypes from 'prop-types';
import { OTHER } from 'woop-shared/canopy/carriers';
import { ADDRESS_FIELDS, AUTO_FIELDS } from 'woop-shared/origination/fields';
import { TORT_LIMITATION_OPTIONS } from 'woop-shared/origination/enums';
import { getPrimaryAddress } from 'woop-shared/origination/utils';
import Select from '../../../components/Select';
import DateField from '../../../components/DateField';
import { CARRIER_OPTIONS } from '../../../constants/carriers';
import MoneyField from '../../../components/MoneyField';
import { getPolicyOptions } from '../../../utils/canopy';
import NumberField from '../../../components/NumberField';
import { createOptionsFromEnum } from '../../../components/Select/utils';

const tortOptions = createOptionsFromEnum(TORT_LIMITATION_OPTIONS);
const carrierOptions = [...CARRIER_OPTIONS, { label: 'Other', value: OTHER }];

const AutoCoverageForm = ({
  entity,
  onInput,
  onChange,
  validationState,
  context: { policies, quoteApplication }
}) => {
  const showValidation = validationState.allValid === false;
  const currentPolicyOptions = getPolicyOptions(policies, true);

  const primaryAddress = getPrimaryAddress(quoteApplication);
  const primaryState = primaryAddress?.[ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE];

  return (
    <>
      <Select
        autoFocus
        prompt={'Current auto insurer'}
        name={AUTO_FIELDS.CURRENT_INSURER}
        showValidation={showValidation}
        isValid={validationState?.[AUTO_FIELDS.CURRENT_INSURER]?.isValid}
        options={carrierOptions}
        onChange={onChange}
        value={carrierOptions.find(o => o.value === entity[AUTO_FIELDS.CURRENT_INSURER])}
      />
      <DateField
        name={AUTO_FIELDS.CURRENT_POLICY_EXPIRATION}
        showValidation={showValidation}
        isValid={validationState?.[AUTO_FIELDS.CURRENT_POLICY_EXPIRATION]?.isValid}
        value={entity[AUTO_FIELDS.CURRENT_POLICY_EXPIRATION]}
        prompt="Current auto policy expiration"
        onChange={onInput}
      />
      <NumberField
        enforceInteger
        min={0}
        name={AUTO_FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER}
        value={entity[AUTO_FIELDS.MIN_MONTHS_WITH_CURRENT_INSURER]}
        prompt="Months with current insurer"
        onChange={onChange}
      />
      <MoneyField
        label="Bodily Injury Per Person Limit"
        enforceInteger
        name={AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_PERSON}
        showValidation={showValidation}
        isValid={validationState?.[AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_PERSON]?.isValid}
        value={entity[AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_PERSON]}
        onChange={onChange}
      />
      <MoneyField
        label="Bodily Injury Per Incident Limit"
        enforceInteger
        name={AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_ACCIDENT}
        showValidation={showValidation}
        isValid={validationState?.[AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_ACCIDENT]?.isValid}
        value={entity[AUTO_FIELDS.CURRENT_BODILY_INJURY_PER_ACCIDENT]}
        onChange={onChange}
      />
      {primaryState === 'PA' && (
        <Select
          prompt={'Tort limitation'}
          name={AUTO_FIELDS.TORT_LIMITATION}
          options={tortOptions}
          onChange={onChange}
          value={tortOptions.find(o => o.value === entity[AUTO_FIELDS.TORT_LIMITATION])}
        />
      )}
      <Select
        prompt={'Current Canopy Auto Policy'}
        name={AUTO_FIELDS.CURRENT_POLICY_NUMBER}
        options={currentPolicyOptions}
        onChange={onChange}
        placeholder={currentPolicyOptions?.length ? '' : 'No Canopy policies found'}
        tooltip={'If the user has connected their insurance, select their current auto policy.'}
        value={currentPolicyOptions.find(
          o => o.value === entity[AUTO_FIELDS.CURRENT_POLICY_NUMBER]
        )}
      />
    </>
  );
};

AutoCoverageForm.propTypes = {
  entity: PropTypes.object,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  validationState: PropTypes.object,
  context: PropTypes.object
};

export default AutoCoverageForm;

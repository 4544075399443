import dayjs from 'dayjs';
import { formatDriver, getDrivers } from '../../utils';
import { FAULT_TYPES, INCIDENT_TYPES } from './constants';

/**
 * Determine the type of a claim, accident, or violation object
 *
 * @param {object} item claim, accident, or violation object
 * @returns {string} claim_id or incident_type ('ACCIDENT' | 'VIOLATION')
 */
export const getType = item => (item?.claim_id ? INCIDENT_TYPES.CLAIM : item?.incident_type || '');

/**
 * Determine the subtype of a claim/accident/violation
 *
 * @param {object} item claim, accident, or violation object
 * @returns {string} claim type, fault type (accident), or violation type
 */
export const getSubtype = item => {
  switch (getType(item)) {
    case INCIDENT_TYPES.CLAIM:
      return item?.type || '';
    case INCIDENT_TYPES.ACCIDENT:
      return item?.is_at_fault === false
        ? FAULT_TYPES.NOT_AT_FAULT
        : item?.is_at_fault
        ? FAULT_TYPES.AT_FAULT
        : '';
    case INCIDENT_TYPES.VIOLATION:
      return item?.violation_type || '';
  }
};

/**
 * Find and format the date_occurred of a claim or the incident_date of an accident or violation
 *
 * @param {object} item claim, accident, or violation object
 * @returns {string} date in MM/DD/YYYY format
 */
export const getDate = item => {
  const dateString = item?.date_occurred || item?.incident_date;
  if (!dateString) return '';
  return dayjs(dateString).format('MM/DD/YYYY');
};

/**
 * Searches a policy for the driver associated with an accident or violation
 *
 * @param {object} policy
 * @param {object} item accident or violation object
 * @returns {object?}
 */
export const getDriver = (policy, item) => {
  if (!item?.driver_id) return null;
  const drivers = getDrivers(policy);
  return drivers.find(driver => driver?.driver_id === item.driver_id);
};

/**
 * Searches a policy for the driver associated with an accident or violation and returns their formatted first and last name
 *
 * @param {object} policy
 * @param {object} item accident or violation object
 * @returns {string} formatted driver name
 */
export const getDriverName = (policy, item) => {
  const driver = getDriver(policy, item);
  return formatDriver(driver);
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__policyTable--2se3b{overflow-x:auto;margin-bottom:1rem}.styles__headerRow--1j3lc,.styles__policyRow--2hBqJ{display:inline-grid;grid-template-columns:100px 120px 120px 90px 120px 140px 125px;grid-column-gap:1rem;column-gap:1rem;align-items:center}.styles__headerRow--1j3lc{padding:0 .5rem;align-items:flex-end}.styles__headerRow--1j3lc>span{font-size:var(--fs-sm);font-weight:var(--fw-semibold);color:var(--color-grey-dark);text-transform:uppercase;letter-spacing:.5px}.styles__policyRow--2hBqJ{margin-top:.5rem;background-color:var(--color-grey-lightest);border-radius:.5rem;padding:.5rem;box-shadow:var(--shadow-xs);font-size:var(--fs-sm)}.styles__linkFooter--2l5Vw{display:flex;padding:.5rem 0}.styles__linkFooter--2l5Vw a{font-size:var(--fs-sm)}.styles__reportCell--MnscX{display:flex;align-items:center;justify-content:space-between}.styles__reportCell--MnscX a{font-size:var(--fs-sm);margin:0}.styles__lobCell--3H924{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;height:auto;padding:.5rem 0}.styles__lobCell--3H924 span{padding:.5rem 1rem;border-radius:1rem;font-size:var(--fs-xs);text-transform:uppercase;font-weight:var(--fw-semibold);color:var(--color-grey-dark);background-color:var(--color-white)}.styles__summary--24PfC{display:flex;grid-gap:1rem;gap:1rem;align-items:center;margin-bottom:1rem}.styles__summary--24PfC h4{margin-bottom:0}.styles__summary--24PfC .styles__summaryBadge--24j4L{font-size:var(--fs-sm);color:var(--color-grey-dark);font-weight:var(--fw-semibold);padding:.25rem .5rem;background-color:var(--color-grey-lighter);border-radius:1rem}", ""]);
// Exports
exports.locals = {
	"policyTable": "styles__policyTable--2se3b",
	"headerRow": "styles__headerRow--1j3lc",
	"policyRow": "styles__policyRow--2hBqJ",
	"linkFooter": "styles__linkFooter--2l5Vw",
	"reportCell": "styles__reportCell--MnscX",
	"lobCell": "styles__lobCell--3H924",
	"summary": "styles__summary--24PfC",
	"summaryBadge": "styles__summaryBadge--24j4L"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--1mg_N{padding:0 1rem}.styles__carrierInfo--1GE1Z{display:flex}.styles__carrierInfo--1GE1Z .styles__carrierName--3TS-f{display:flex;font-size:var(--fs-lg);font-weight:var(--fw-semibold);margin:.15rem 0 .35rem;align-items:center}.styles__carrierInfo--1GE1Z .styles__premium--2SSRY{font-size:var(--fs-sm)}.styles__carrierInfo--1GE1Z .styles__coverages--38kuR{color:var(--color-grey-primary)}.styles__carrierInfo--1GE1Z .styles__carrierLogo--33jeF{position:relative;flex-shrink:0;border:1px solid var(--color-grey-light);width:2.5rem;height:2.5rem;overflow:hidden}.styles__carrierInfo--1GE1Z .styles__carrierLogo--33jeF img,.styles__carrierInfo--1GE1Z .styles__carrierLogo--33jeF svg{padding:.1rem}.styles__carrierInfo--1GE1Z .styles__carrierLogo--33jeF img{width:100%;height:100%;object-fit:contain;box-sizing:border-box}.styles__carrierInfo--1GE1Z .styles__carrierLogo--33jeF{border-radius:9999px;margin:.25rem 1rem .25rem .25rem}.styles__carrierInfo--1GE1Z .styles__badges--2Zyu9{display:flex;align-items:flex-start;margin-left:.5rem}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--1mg_N",
	"carrierInfo": "styles__carrierInfo--1GE1Z",
	"carrierName": "styles__carrierName--3TS-f",
	"premium": "styles__premium--2SSRY",
	"coverages": "styles__coverages--38kuR",
	"carrierLogo": "styles__carrierLogo--33jeF",
	"badges": "styles__badges--2Zyu9"
};
module.exports = exports;

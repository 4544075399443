import { ORIGINATION_FIELDS, PHONE_FIELDS } from 'woop-shared/origination/fields';
import { UPDATE_APPLICANT, ADD_APPLICANT, DELETE_APPLICANT } from '../actions/applicants';
import { APPLICANT_FIELDS } from '../constants/origination';
import { GET_ORIGINATION_SUCCESS } from '../actions/originations';
import { formatDateInputValue } from '../components/DateField/utils';

function applicants(state = {}, action) {
  switch (action.type) {
    case ADD_APPLICANT: {
      const { payload } = action;
      return {
        ...state,
        [payload[APPLICANT_FIELDS.TAG]]: payload
      };
    }
    case DELETE_APPLICANT: {
      const { payload: id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case UPDATE_APPLICANT: {
      const { id, updateObj } = action.payload;
      const appToUpdate = state[id];
      return { ...state, [id]: { ...appToUpdate, ...updateObj } };
    }
    case GET_ORIGINATION_SUCCESS: {
      const applicants = action.payload?.originationData?.[ORIGINATION_FIELDS.APPLICANTS] || [];
      const applicantsWithTags = {};
      applicants?.forEach(app => {
        applicantsWithTags[app[APPLICANT_FIELDS.TAG]] = {
          ...app,
          [APPLICANT_FIELDS.DOB]: formatDateInputValue(app?.[APPLICANT_FIELDS.DOB]),
          [APPLICANT_FIELDS.PHONE_TYPE]: app.phoneNumbers?.[0]?.[PHONE_FIELDS.PHONE_TYPE],
          [APPLICANT_FIELDS.PHONE]: app.phoneNumbers?.[0]?.[PHONE_FIELDS.NUMBER],
          [APPLICANT_FIELDS.DRIVING_COURSE_DATE]: formatDateInputValue(
            app?.[APPLICANT_FIELDS.DRIVING_COURSE_DATE]
          )
        };
      });
      return applicantsWithTags;
    }
    default:
      return state;
  }
}

export default applicants;

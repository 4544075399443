import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import {
  INCIDENT_TYPES,
  VIOLATION_DESCRIPTIONS,
  ACCIDENT_DESCRIPTIONS
} from 'woop-shared/origination/enums';
import Select from '../../../components/Select';
import DateField from '../../../components/DateField';
import { createOptionsFromEnum, createSelectOptions } from '../../../components/Select/utils';
import { INCIDENT_FIELDS } from '../constants';
import styles from '../styles.module.css';
import Prompt from '../../../components/Prompt';
import { getDriverOptions } from '../utils';

const incidentTypeOptions = createSelectOptions([
  INCIDENT_TYPES.ACCIDENT,
  INCIDENT_TYPES.VIOLATION
]);
const autoAccidentDescOptions = createOptionsFromEnum(ACCIDENT_DESCRIPTIONS);
const autoViolationDescOptions = createOptionsFromEnum(VIOLATION_DESCRIPTIONS);

const AutoIncidentsForm = ({
  entity,
  onInput,
  onChange,
  context: { quoteApplication },
  validationState
}) => {
  const incident = entity || {};

  const driverOptions = getDriverOptions(quoteApplication);

  const showValidation = validationState.allValid === false;
  return (
    <>
      <Select
        autoFocus
        prompt={'Incident type'}
        name={INCIDENT_FIELDS.TYPE}
        showValidation={showValidation}
        isValid={validationState?.[INCIDENT_FIELDS.TYPE]?.isValid}
        options={incidentTypeOptions}
        onChange={onChange}
        value={
          incidentTypeOptions.find(({ value }) => value === incident[INCIDENT_FIELDS.TYPE]) || null
        }
      />

      <Select
        prompt={'Driver'}
        name={INCIDENT_FIELDS.APPLICANT_ID}
        options={driverOptions}
        onChange={onChange}
        value={
          driverOptions.find(({ value }) => value === incident[INCIDENT_FIELDS.APPLICANT_ID]) ||
          null
        }
      />

      <DateField
        name={INCIDENT_FIELDS.INCIDENT_DATE}
        showValidation={showValidation}
        isValid={validationState?.[INCIDENT_FIELDS.INCIDENT_DATE]?.isValid}
        value={incident[INCIDENT_FIELDS.INCIDENT_DATE]}
        prompt="Incident date"
        onChange={onInput}
      />

      {incident[INCIDENT_FIELDS.TYPE] === INCIDENT_TYPES.ACCIDENT && (
        <Select
          prompt={'Accident description'}
          name={INCIDENT_FIELDS.ACCIDENT_DESC}
          showValidation={showValidation}
          isValid={validationState?.[INCIDENT_FIELDS.ACCIDENT_DESC]?.isValid}
          options={autoAccidentDescOptions}
          onChange={onChange}
          value={autoAccidentDescOptions.find(
            ({ value }) => value === incident[INCIDENT_FIELDS.ACCIDENT_DESC]
          )}
        />
      )}

      {incident[INCIDENT_FIELDS.TYPE] === INCIDENT_TYPES.VIOLATION && (
        <Select
          prompt={'Violation description'}
          name={INCIDENT_FIELDS.VIOLATION_DESC}
          options={autoViolationDescOptions}
          onChange={onChange}
          value={
            autoViolationDescOptions.find(
              ({ value }) => value === incident[INCIDENT_FIELDS.VIOLATION_DESC]
            ) || null
          }
        />
      )}

      <div className={styles.checkboxWrapper}>
        <Checkbox
          name={INCIDENT_FIELDS.RESULTED_IN_BI_LOSS}
          checked={incident[INCIDENT_FIELDS.RESULTED_IN_BI_LOSS] ?? false}
          onChange={(_, val) => onChange(INCIDENT_FIELDS.RESULTED_IN_BI_LOSS, val)}
          className={styles.checkboxWrapper}
        />
        <Prompt>Resulted in BI loss</Prompt>
      </div>

      <div className={styles.checkboxWrapper}>
        <Checkbox
          name={INCIDENT_FIELDS.RESULTED_IN_DEATH}
          checked={incident[INCIDENT_FIELDS.RESULTED_IN_DEATH] ?? false}
          onChange={(_, val) => onChange(INCIDENT_FIELDS.RESULTED_IN_DEATH, val)}
          className={styles.checkboxWrapper}
        />
        <Prompt>Resulted in death</Prompt>
      </div>

      <div className={styles.checkboxWrapper}>
        <Checkbox
          name={INCIDENT_FIELDS.RESULTED_IN_PIP_LOSS}
          checked={incident[INCIDENT_FIELDS.RESULTED_IN_PIP_LOSS] ?? false}
          onChange={(_, val) => onChange(INCIDENT_FIELDS.RESULTED_IN_PIP_LOSS, val)}
          className={styles.checkboxWrapper}
        />
        <Prompt>Resulted in PIP loss</Prompt>
      </div>
    </>
  );
};

AutoIncidentsForm.propTypes = {
  entity: PropTypes.object,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  context: PropTypes.object,
  validationState: PropTypes.object
};

export default AutoIncidentsForm;

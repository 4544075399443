import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { QUOTE_FIELDS, PDF_FIELDS, FOREIGN_KEYS } from 'woop-shared/db/models';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { hasVal } from 'woop-shared/utils';
import { getQuoteId } from '../../utils';
import Premiums from '../../../../components/Premiums';
import styles from '../../CreateQuoteset.css';
import CarrierSelect from '../../../../components/CarrierSelect/container';
import Select from '../../../../components/Select';
import TextField from '../../../../components/TextField';
import MoneyField from '../../../../components/MoneyField';
import CoverageFields from '../../../../components/CoverageFields';
import AdditionalCoverages from '../../../../components/AdditionalCoverages';
import { createSelectOptions } from '../../../../components/Select/utils';
import CarrierFees from '../../../../components/CarrierFees';
import CoverageTags from '../../../../components/CoverageTags';
import Checkbox from '../../../../components/Checkbox';
import Discounts from '../Discounts';
import { getFees } from '../../../../components/CarrierFees/utils';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { saveQuoteset } from '../../../../actions/quoteset';

const QuoteModal = ({
  quote: originalQuote,
  hasErrorState,
  journeySummary,

  visible,
  closing,
  toggleModal,
  loading,

  updateQuote
}) => {
  const id = originalQuote && getQuoteId(originalQuote);
  const dispatch = useDispatch();
  const [quote, setQuote] = useState({ ...originalQuote });
  const quotes = useSelector(({ quotes }) => Object.values(quotes || {}));

  useEffect(() => {
    setQuote({ ...originalQuote });
  }, [originalQuote]);

  const covType = quote[QUOTE_FIELDS.COVERAGE_TYPE];
  const hasCovType = !!covType;
  const isAuto = covType === COVERAGE_TYPES.AUTO;
  const isUmbrella = covType === COVERAGE_TYPES.UMBRELLA;

  const isPolicy = !!quote[QUOTE_FIELDS.IS_CURRENT_POLICY];
  const hasPolicyOfType =
    isPolicy ||
    quotes.some(
      q => q[QUOTE_FIELDS.COVERAGE_TYPE] === covType && q[QUOTE_FIELDS.IS_CURRENT_POLICY]
    );

  const handleFieldChange = (name, value) => {
    setQuote({ ...quote, [name]: value });
  };

  const handleNestedFieldChange = (subObj, name, value) => {
    const subObject = quote[subObj] || {};
    const updates = { ...subObject, [name]: value };
    setQuote({ ...quote, [subObj]: updates });
  };

  const handleCarrierChange = (name, carrierObject) => {
    const { stateAbbrev } = journeySummary;
    const fees = getFees(carrierObject, stateAbbrev);
    setQuote({
      ...quote,
      [QUOTE_FIELDS.FEES]: fees,
      [name]: carrierObject.code
    });
  };

  const handleCoverageChange = (index, value) => {
    const coverages = [...(quote[QUOTE_FIELDS.COVERAGES] || [])];
    coverages[index] = value;
    handleFieldChange(QUOTE_FIELDS.COVERAGES, coverages);
  };

  const handleSave = () => {
    updateQuote(id, quote);
    dispatch(saveQuoteset());
    toggleModal();
  };

  const handleClose = () => {
    setQuote({ ...originalQuote });
    toggleModal();
  };

  return (
    <Modal
      visible={visible}
      closing={closing}
      toggleModal={handleClose}
      className={styles.editModal}
    >
      <section className={styles.modalContent}>
        <CarrierSelect
          name={FOREIGN_KEYS.CARRIER_CODE}
          onChange={handleCarrierChange}
          value={quote[FOREIGN_KEYS.CARRIER_CODE]}
          createable={isPolicy}
          isValid={hasVal(quote[FOREIGN_KEYS.CARRIER_CODE])}
          showValidation={hasErrorState}
        />
        {!hasCovType && (
          <Select
            prompt="Coverage Type"
            options={createSelectOptions(journeySummary.coverageTypes)}
            name={QUOTE_FIELDS.COVERAGE_TYPE}
            onChange={handleFieldChange}
            isValid={hasVal(quote[QUOTE_FIELDS.COVERAGE_TYPE])}
            showValidation={hasErrorState}
          />
        )}
        {hasCovType && (
          <>
            <div className={styles.cardFields}>
              {!isUmbrella && !isPolicy && (
                <CoverageTags
                  name={QUOTE_FIELDS.COVERAGE_TAG}
                  value={quote[QUOTE_FIELDS.COVERAGE_TAG]}
                  onChange={handleFieldChange}
                  isValid={hasVal(quote[QUOTE_FIELDS.COVERAGE_TAG])}
                  compare={hasPolicyOfType}
                />
              )}
              <TextField
                prompt="Quote PDF URL"
                name={PDF_FIELDS.URL}
                placeholder="https://..."
                value={quote[QUOTE_FIELDS.PDF]?.[PDF_FIELDS.URL]}
                showValidation={hasErrorState}
                validationText={'This field is required'}
                isValid={isPolicy || !!quote[QUOTE_FIELDS.PDF]?.[PDF_FIELDS.URL]}
                onChange={e =>
                  handleNestedFieldChange(QUOTE_FIELDS.PDF, e.target.name, e.target.value)
                }
              />
              {!isAuto && !isUmbrella && (
                <MoneyField
                  prompt="Deductible"
                  name={QUOTE_FIELDS.DEDUCTIBLE}
                  value={quote[QUOTE_FIELDS.DEDUCTIBLE]}
                  onChange={handleFieldChange}
                  isValid={hasVal(quote[QUOTE_FIELDS.DEDUCTIBLE])}
                  showValidation={hasErrorState}
                />
              )}
              <CoverageFields
                name={QUOTE_FIELDS.COVERAGES}
                quote={quote}
                onChange={handleCoverageChange}
                updateQuote={(_, updates) => setQuote({ ...quote, ...updates })}
                showValidation={hasErrorState}
                stateAbbrev={journeySummary.stateAbbrev}
              />
            </div>
            <div className={styles.cardFields}>
              <AdditionalCoverages
                name={QUOTE_FIELDS.ADDITIONAL_COVERAGES}
                onChange={handleFieldChange}
                showValidation={hasErrorState}
                coverages={quote[QUOTE_FIELDS.ADDITIONAL_COVERAGES]}
              />
            </div>
            <Premiums
              name={QUOTE_FIELDS.PREMIUMS}
              onChange={handleFieldChange}
              premiums={quote[QUOTE_FIELDS.PREMIUMS] || []}
              coverageType={quote[QUOTE_FIELDS.COVERAGE_TYPE]}
              showValidation={hasErrorState}
              isCurrentPolicy={quote[QUOTE_FIELDS.IS_CURRENT_POLICY] || false}
            />
            {!isPolicy && (
              <CarrierFees
                name={QUOTE_FIELDS.FEES}
                onChange={handleFieldChange}
                fees={quote[QUOTE_FIELDS.FEES]}
                showValidation={hasErrorState}
              />
            )}
            <Discounts
              quote={quote}
              updateQuote={(_, updates) => setQuote({ ...quote, ...updates })}
              isAuto={isAuto}
            />
            <div className={styles.cardFields}>
              {!isPolicy && !isUmbrella && (
                <Checkbox
                  name={QUOTE_FIELDS.RECOMMENDED}
                  prompt={'Agent Recommended'}
                  label={'Mark this quote as "Agent Recommended"'}
                  onChange={handleFieldChange}
                  value={!!quote[QUOTE_FIELDS.RECOMMENDED]}
                />
              )}
            </div>
          </>
        )}
      </section>
      <Button className={styles.floatingSaveButton} loading={loading} onClick={handleSave}>
        Save
      </Button>
    </Modal>
  );
};

export default QuoteModal;

QuoteModal.propTypes = {
  quote: PropTypes.object,
  hasErrorState: PropTypes.bool,
  journeySummary: PropTypes.object,
  updateQuote: PropTypes.func,
  visible: PropTypes.bool,
  closing: PropTypes.bool,
  toggleModal: PropTypes.func,
  loading: PropTypes.bool
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--3ipeX{cursor:pointer;transition:.3s}.styles__wrapper--3ipeX:hover{transform:scale(1.05)}.styles__cardHeader--1H3Zr .styles__toggle--3hpYX{position:absolute;padding:10px;top:0;font-size:20px;right:0}.styles__cardHeader--1H3Zr .styles__topRow--26o0u{font-weight:700}.styles__cardHeader--1H3Zr div{margin-bottom:10px}.styles__cardHeader--1H3Zr .styles__date--16EXq{font-size:14px}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--3ipeX",
	"cardHeader": "styles__cardHeader--1H3Zr",
	"toggle": "styles__toggle--3hpYX",
	"topRow": "styles__topRow--26o0u",
	"date": "styles__date--16EXq"
};
module.exports = exports;

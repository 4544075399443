import { GET_USER_SUCCESS } from '../actions/users';

function user(state = {}, action) {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export default user;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import journeySummary from '../reducers/journey-summary';
import quoteset from '../reducers/quoteset';
import quotes from '../reducers/quotes';
import bundledQuotes from '../reducers/bundledQuotes';
import error from '../reducers/error';
import loading from '../reducers/loading';
import carriers from '../reducers/carriers';
import origination from '../reducers/origination';
import applicants from '../reducers/applicants';
import addresses from '../reducers/addresses';
import propertyIncidents from '../reducers/property-incidents';
import pools from '../reducers/pools';
import incidents from '../reducers/incidents';
import vehicles from '../reducers/vehicles';
import partner from '../reducers/partner';
import partners from '../reducers/partners';
import originators from '../reducers/originators';
import journeys from '../reducers/journeys';
import journey from '../reducers/journey';
import user from '../reducers/user';
import quoteApplication from '../reducers/quote-application';
import quoteRequest from '../reducers/quote-request';
import quoteResult from '../reducers/quote-result';
import pulls from '../reducers/pulls';
import reports from '../reducers/reports';
import report from '../reducers/report';
import policy from '../reducers/policy';
import payments from '../reducers/payments';
import qa from '../reducers/qa';
import mortgage from '../reducers/mortgage';
import transunion from '../reducers/transunion';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    journeySummary,
    quoteset,
    quotes,
    bundledQuotes,
    error,
    loading,
    carriers,
    origination,
    propertyIncidents,
    pools,
    applicants,
    incidents,
    addresses,
    vehicles,
    partner,
    partners,
    originators,
    journeys,
    payments,
    journey,
    user,
    pulls,
    report,
    reports,
    policy,
    qa,
    mortgage,
    transunion,
    quoteApplication,
    quoteRequest,
    quoteResult
  });

export default createRootReducer;

import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './CardLabel.css';

const cx = classnames.bind(styles);

const CardLabel = ({ className, children }) => (
  <div className={cx('wrapper', className)}>{children}</div>
);

export default CardLabel;

CardLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

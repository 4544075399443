import { connect } from 'react-redux';
import PartnerSelect from '.';
import { getPartners } from '../../actions/partners';

const mapStateToProps = ({ partners }) => ({ partners });

const mapDispatchToProps = dispatch => ({
  getPartners: () => dispatch(getPartners())
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSelect);

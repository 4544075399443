import { COVERAGE_TYPES, COVERAGE_TAGS } from 'woop-shared/enums';
import { QUOTE_FIELDS } from 'woop-shared/db/models';
import { COVERAGE_FIELDS, ADDITIONAL_COVERAGES } from 'woop-shared/quotes/enums';

export const GENERIC_WIND_HAIL_HURRICANE_LABEL = 'GENERIC_WIND_HAIL_HURRICANE';

export const COVERAGE_TEMPLATES = {
  [COVERAGE_TYPES.RENTERS]: {
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.DEDUCTIBLE]: 500,
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: 10000,
        [COVERAGE_FIELDS.LIABILITY]: 100000,
        [COVERAGE_FIELDS.MEDICAL_PAYMENTS]: 1000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '$4,000' },
        { label: ADDITIONAL_COVERAGES.THEFT_DEDUCTIBLE, value: '$500' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.DEDUCTIBLE]: 500,
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: 20000,
        [COVERAGE_FIELDS.LIABILITY]: 300000,
        [COVERAGE_FIELDS.MEDICAL_PAYMENTS]: 1000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '$8,000' },
        { label: ADDITIONAL_COVERAGES.THEFT_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.DEDUCTIBLE]: 250,
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: 40000,
        [COVERAGE_FIELDS.LIABILITY]: 500000,
        [COVERAGE_FIELDS.MEDICAL_PAYMENTS]: 5000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '$16,000' },
        { label: ADDITIONAL_COVERAGES.THEFT_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.INCREASED_JEWELRY_LIMIT, value: '$5,000' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: '$5,000' }
      ]
    }
  },
  [COVERAGE_TYPES.HOME]: {
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.DEDUCTIBLE]: 1000,
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.DWELLING]: '100%',
        [COVERAGE_FIELDS.OTHER_STRUCTURES]: '10%',
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '50%',
        [COVERAGE_FIELDS.LIABILITY]: 300000,
        [COVERAGE_FIELDS.MEDICAL_PAYMENTS]: 1000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '20%' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.DEDUCTIBLE]: 1000,
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.DWELLING]: '100%',
        [COVERAGE_FIELDS.OTHER_STRUCTURES]: '10%',
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '75%',
        [COVERAGE_FIELDS.LIABILITY]: 300000,
        [COVERAGE_FIELDS.MEDICAL_PAYMENTS]: 1000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '20%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: '$10,000' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.DEDUCTIBLE]: 1000,
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.DWELLING]: '100%',
        [COVERAGE_FIELDS.OTHER_STRUCTURES]: '10%',
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '100%',
        [COVERAGE_FIELDS.LIABILITY]: 500000,
        [COVERAGE_FIELDS.MEDICAL_PAYMENTS]: 5000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '20%' },
        { label: ADDITIONAL_COVERAGES.INCREASED_REPLACEMENT_COST, value: '25%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: '$10,000' }
      ]
    }
  },
  [COVERAGE_TYPES.CONDO]: {
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.DEDUCTIBLE]: 1000,
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.DWELLING]: '100%',
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '',
        [COVERAGE_FIELDS.LIABILITY]: 300000,
        [COVERAGE_FIELDS.MEDICAL_PAYMENTS]: 1000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '20%' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.DEDUCTIBLE]: 1000,
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.DWELLING]: '100%',
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '',
        [COVERAGE_FIELDS.LIABILITY]: 300000,
        [COVERAGE_FIELDS.MEDICAL_PAYMENTS]: 1000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '20%' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: '$10,000' },
        { label: ADDITIONAL_COVERAGES.LOSS_ASSESSMENT, value: '$10,000' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.DEDUCTIBLE]: 1000,
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.DWELLING]: '100%',
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '',
        [COVERAGE_FIELDS.LIABILITY]: 500000,
        [COVERAGE_FIELDS.MEDICAL_PAYMENTS]: 5000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '20%' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: '$10,000' },
        { label: ADDITIONAL_COVERAGES.LOSS_ASSESSMENT, value: '$25,000' }
      ]
    }
  },
  [COVERAGE_TYPES.AUTO]: {
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PER_PERSON_LIABILITY]: 50000,
        [COVERAGE_FIELDS.PER_ACCIDENT_LIABILITY]: 100000,
        [COVERAGE_FIELDS.PROPERTY_DAMAGE_LIABILITY]: 50000,
        [COVERAGE_FIELDS.UNDERINSURED_PER_PERSON]: 50000,
        [COVERAGE_FIELDS.UNDERINSURED_PER_ACCIDENT]: 100000,
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 50000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.COLLISION_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.COMPREHENSIVE_DEDUCTIBLE, value: '$500' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PER_PERSON_LIABILITY]: 100000,
        [COVERAGE_FIELDS.PER_ACCIDENT_LIABILITY]: 300000,
        [COVERAGE_FIELDS.PROPERTY_DAMAGE_LIABILITY]: 100000,
        [COVERAGE_FIELDS.UNDERINSURED_PER_PERSON]: 100000,
        [COVERAGE_FIELDS.UNDERINSURED_PER_ACCIDENT]: 300000,
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 100000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.COLLISION_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.COMPREHENSIVE_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.TOWING_AND_LABOR, value: '$75' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PER_PERSON_LIABILITY]: 250000,
        [COVERAGE_FIELDS.PER_ACCIDENT_LIABILITY]: 500000,
        [COVERAGE_FIELDS.PROPERTY_DAMAGE_LIABILITY]: 250000,
        [COVERAGE_FIELDS.UNDERINSURED_PER_PERSON]: 250000,
        [COVERAGE_FIELDS.UNDERINSURED_PER_ACCIDENT]: 500000,
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 250000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.COLLISION_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.COMPREHENSIVE_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.TOWING_AND_LABOR, value: 'Unlimited' },
        { label: ADDITIONAL_COVERAGES.FULL_GLASS, value: 'Included' }
      ]
    }
  }
};

const HOME_COVERAGE_BY_STATE = [
  {
    states: ['IA', 'KS', 'NE', 'OK', 'MO', 'SD', 'ND', 'NH', 'WI', 'VT', 'WY'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '.5%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1,000' }
      ]
    }
  },
  {
    states: ['AK', 'AZ', 'CA', 'CO', 'MI', 'MN', 'NM', 'NV', 'OR', 'WA', 'UT']
  },
  {
    states: ['CT', 'DC', 'DE', 'HI', 'MA', 'MD', 'ME', 'NJ', 'RI'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '.5%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1,000' }
      ]
    }
  },
  {
    states: ['GA', 'LA', 'MS', 'SC', 'VA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '3%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '2%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' }
      ]
    }
  },
  {
    states: ['AL'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '2%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: GENERIC_WIND_HAIL_HURRICANE_LABEL,
          value: '$1,000'
        }
      ]
    }
  },
  {
    states: ['AR'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '.5%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1,000' }
      ]
    }
  },
  {
    states: ['FL'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '5%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '3%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' }
      ]
    }
  },
  {
    states: ['IL', 'IN'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '.5%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    }
  },
  {
    states: ['KY'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    }
  },
  {
    states: ['NC'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '3%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '2%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1' }
      ]
    }
  },
  {
    states: ['NY'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' },
        { label: ADDITIONAL_COVERAGES.OFF_PREMISES_THEFT_EXCLUSION, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.OFF_PREMISES_THEFT_EXCLUSION, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.PERMITTED_INCIDENTAL_BUSINESS_OCCUPANCY, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.PERMITTED_INCIDENTAL_BUSINESS_OCCUPANCY, value: 'Included' }
      ]
    }
  },
  {
    states: ['OH'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: []
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    }
  },
  {
    states: ['TX'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '3%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '2%' },
        { label: ADDITIONAL_COVERAGES.FOUNDATION_COVERAGE, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' },
        { label: ADDITIONAL_COVERAGES.FOUNDATION_COVERAGE, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.RESIDENCE_GLASS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: 'Included' }
      ]
    }
  },
  {
    states: ['TN'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.SINKHOLE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.SINKHOLE, value: 'Included' }
      ]
    }
  },
  {
    states: ['WV'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    }
  },
  {
    states: ['PA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '.5%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: GENERIC_WIND_HAIL_HURRICANE_LABEL, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.SINKHOLE, value: 'Included' }
      ]
    }
  }
];

const CONDO_COVERAGE_BY_STATE = [
  {
    states: ['IA', 'ID', 'KS', 'NE', 'OK', 'MO', 'SD', 'ND', 'NH', 'WI', 'VT', 'WY'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '0.5%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '$1,000' }
      ]
    }
  },
  {
    states: ['AK', 'AZ', 'CA', 'CO', 'MI', 'MN', 'MT', 'NM', 'NV', 'OR', 'WA', 'UT'],
    [COVERAGE_TAGS.LOW]: {},
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    }
  },
  {
    states: ['CT', 'DC', 'DE', 'HI', 'MA', 'MD', 'ME', 'NJ', 'RI'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    }
  },
  {
    states: ['GA', 'LA', 'MS', 'SC', 'VA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '3%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '2%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '1%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    }
  },
  {
    states: ['AL'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '2%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '1%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    }
  },
  {
    states: ['AR'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    }
  },
  {
    states: ['FL'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '5%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '75%' // weird state only
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '3%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '100%' // weird state only
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '1%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    }
  },
  {
    states: ['IL', 'IN'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    }
  },
  {
    states: ['KY'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE, value: '1%' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    }
  },
  {
    states: ['NC'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '3%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '2%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '$1' }, // weird!
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    }
  },
  {
    states: ['NY'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '1%' },
        { label: ADDITIONAL_COVERAGES.OFF_PREMISES_THEFT_EXCLUSION, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.OFF_PREMISES_THEFT_EXCLUSION, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.PERMITTED_INCIDENTAL_BUSINESS_OCCUPANCY, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.PERMITTED_INCIDENTAL_BUSINESS_OCCUPANCY, value: 'Included' }
      ]
    }
  },
  {
    states: ['OH'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: []
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    }
  },
  {
    states: ['TX'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE, value: '3%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE, value: '2%' },
        { label: ADDITIONAL_COVERAGES.FOUNDATION_COVERAGE, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE, value: '1%' },
        { label: ADDITIONAL_COVERAGES.FOUNDATION_COVERAGE, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.RESIDENCE_GLASS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.WATER_BACKUP, value: 'Included' }
      ]
    }
  },
  {
    states: ['TN'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.SINKHOLE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.SINKHOLE, value: 'Included' }
      ]
    }
  },
  {
    states: ['WV'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.MINE_SUBSIDENCE, value: 'Included' }
      ]
    }
  },
  {
    states: ['PA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE, value: '.5%' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.REPLACEMENT_COST_CONTENTS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.SINKHOLE, value: 'Included' }
      ]
    }
  }
];

const AUTO_COVERAGE_BY_STATE = [
  {
    states: ['AK', 'AL', 'AZ', 'IA', 'ME', 'MO', 'MT', 'NE', 'NH', 'NV'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['OK', 'RI', 'SD', 'VT', 'WI', 'WY'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['AR'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['CA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$3,500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$3,500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$3,500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['CO'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$2,500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$2,500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$2,500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['CT'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: []
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.BASIC_REPARATION_BENEFITS, value: 'Basic' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.BASIC_REPARATION_BENEFITS, value: 'Basic + $50,000' }
      ]
    }
  },
  {
    states: ['DC'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['DE'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_PER_PERSON, value: '$15,000' },
        { label: ADDITIONAL_COVERAGES.PIP_PER_ACCIDENT, value: '$30,000' },
        { label: ADDITIONAL_COVERAGES.APIP_PER_PERSON, value: '$20,000' },
        { label: ADDITIONAL_COVERAGES.APIP_PER_ACCIDENT, value: '$40,000' },
        { label: ADDITIONAL_COVERAGES.PIP_APPLIES, value: 'Named insured and relatives' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_PER_PERSON, value: '$15,000' },
        { label: ADDITIONAL_COVERAGES.PIP_PER_ACCIDENT, value: '$30,000' },
        { label: ADDITIONAL_COVERAGES.APIP_PER_PERSON, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.APIP_PER_ACCIDENT, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.PIP_APPLIES, value: 'Named insured and relatives' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_PER_PERSON, value: '$15,000' },
        { label: ADDITIONAL_COVERAGES.PIP_PER_ACCIDENT, value: '$30,000' },
        { label: ADDITIONAL_COVERAGES.APIP_PER_PERSON, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.APIP_PER_ACCIDENT, value: '$300,000' },
        { label: ADDITIONAL_COVERAGES.PIP_APPLIES, value: 'Named insured and relatives' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' }
      ]
    }
  },
  {
    states: ['FL'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$2,500' },
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: 'Basic' },
        { label: ADDITIONAL_COVERAGES.PIP_APPLIES, value: 'Named insured and Relatives' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$2,500' },
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: 'Basic' },
        { label: ADDITIONAL_COVERAGES.PIP_APPLIES, value: 'Named insured and Relatives' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$2,500' },
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: 'Extended' },
        { label: ADDITIONAL_COVERAGES.PIP_APPLIES, value: 'Named insured and Relatives' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' }
      ]
    }
  },
  {
    states: ['GA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$2,500' },
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$2,500' },
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$2,500' },
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['HI'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_PER_PERSON, value: '$10,000' },
        { label: ADDITIONAL_COVERAGES.PIP_PER_ACCIDENT, value: '$20,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_PER_PERSON, value: '$20,000' },
        { label: ADDITIONAL_COVERAGES.PIP_PER_ACCIDENT, value: '$40,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_PER_PERSON, value: '$20,000' },
        { label: ADDITIONAL_COVERAGES.PIP_PER_ACCIDENT, value: '$40,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' }
      ]
    }
  },
  {
    states: ['ID'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['IL', 'IN'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['KS'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$20,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$20,000' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$27,500' }
      ]
    }
  },
  {
    states: ['KY'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.APIP, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.TORT_LIMITATION, value: 'Limitation not rejected' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$75,000' },
        { label: ADDITIONAL_COVERAGES.APIP, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.TORT_LIMITATION, value: 'Limitation not rejected' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.APIP, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.TORT_LIMITATION, value: 'Limitation not rejected' }
      ]
    }
  },
  {
    states: ['LA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['MA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PER_PERSON_LIABILITY]: 20000,
        [COVERAGE_FIELDS.PER_ACCIDENT_LIABILITY]: 40000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.OPTIONAL_BI_PER_PERSON, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.OPTIONAL_BI_PER_ACCIDENT, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$8000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PER_PERSON_LIABILITY]: 20000,
        [COVERAGE_FIELDS.PER_ACCIDENT_LIABILITY]: 40000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.OPTIONAL_BI_PER_PERSON, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.OPTIONAL_BI_PER_ACCIDENT, value: '$300,000' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$8000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.ROADSIDE_ASSISTANCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PER_PERSON_LIABILITY]: 20000,
        [COVERAGE_FIELDS.PER_ACCIDENT_LIABILITY]: 40000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.OPTIONAL_BI_PER_PERSON, value: '$250,000' },
        { label: ADDITIONAL_COVERAGES.OPTIONAL_BI_PER_ACCIDENT, value: '$500,000' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$8000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.ROADSIDE_ASSISTANCE, value: 'Included' }
      ]
    }
  },
  {
    states: ['MD'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$10,000 Full' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$10,000 Full' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DEDUCTIBLE, value: '$100,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$10,000 Full' }
      ]
    }
  },
  {
    states: ['MI'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$10,000 Full' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$10,000 Full' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$10,000 Full' }
      ]
    }
  },
  {
    states: ['MN'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: 'Basic' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$100' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: 'Basic+55k Med Exp/15' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$100' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: 'Basic+55k Med Exp/15' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$100' }
      ]
    }
  },
  {
    states: ['ND'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$80,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['MS', 'NC', 'NM', 'SC', 'TN'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['OH'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  },
  {
    states: ['NJ'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 25000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_COVERAGE, value: 'Primary' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.EXTENDED_MEDICAL, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.THRESHOLD, value: 'Verbal Threshold' },
        { label: ADDITIONAL_COVERAGES.APIP, value: '$100 -$10,400 / Limited' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_COVERAGE, value: 'Primary' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$75,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.EXTENDED_MEDICAL, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.THRESHOLD, value: 'Verbal Threshold' },
        { label: ADDITIONAL_COVERAGES.APIP, value: '$250 -$75,000 / Limited' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$100,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_COVERAGE, value: 'Primary' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$250,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$500' },
        { label: ADDITIONAL_COVERAGES.EXTENDED_MEDICAL, value: '$1,000' },
        { label: ADDITIONAL_COVERAGES.THRESHOLD, value: 'Verbal Threshold' },
        { label: ADDITIONAL_COVERAGES.APIP, value: '$500 Unlimited' }
      ]
    }
  },
  {
    states: ['NY'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_APIP, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.PIP_OPTIONS, value: 'Basic' },
        { label: ADDITIONAL_COVERAGES.WORK_LOSS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.OPTIONAL_BASIC_ECONOMIC_LOSS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_APIP, value: '$200,000' },
        { label: ADDITIONAL_COVERAGES.PIP_OPTIONS, value: 'Basic $50,000' },
        { label: ADDITIONAL_COVERAGES.WORK_LOSS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.OPTIONAL_BASIC_ECONOMIC_LOSS, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_APIP, value: '$250,000' },
        { label: ADDITIONAL_COVERAGES.PIP_OPTIONS, value: 'Basic $100,000' },
        { label: ADDITIONAL_COVERAGES.WORK_LOSS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.OPTIONAL_BASIC_ECONOMIC_LOSS, value: 'Included' },
        { label: ADDITIONAL_COVERAGES.STATE_SPONSORED_LIFE_INSURANCE, value: 'Included' }
      ]
    }
  },
  {
    states: ['OR'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.PIP_COVERAGE, value: 'Primary' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$250' },
        { label: ADDITIONAL_COVERAGES.PIP_APPLICATION, value: 'Named Insured & Relatives' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_COVERAGE, value: 'Primary' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$250' },
        { label: ADDITIONAL_COVERAGES.PIP_APPLICATION, value: 'Named Insured & Relatives' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$100,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_COVERAGE, value: 'Primary' },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.PIP_DEDUCTIBLE, value: '$250' },
        { label: ADDITIONAL_COVERAGES.PIP_APPLICATION, value: 'Named Insured & Relatives' }
      ]
    }
  },
  {
    states: ['PA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.FIRST_PARTY_MEDICAL_BENEFITS, value: '$5,000' },
        { label: ADDITIONAL_COVERAGES.TORT_LIMITATION, value: 'Limited' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.FIRST_PARTY_MEDICAL_BENEFITS, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.TORT_LIMITATION, value: 'Limited' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.FIRST_PARTY_MEDICAL_BENEFITS, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.TORT_LIMITATION, value: 'Limited' }
      ]
    }
  },
  {
    states: ['TX'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 25000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_COVERAGE, value: '$50,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 50000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_COVERAGE, value: '$100,000' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 100000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' }
      ]
    }
  },
  {
    states: ['UT'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 25000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.APIP, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 50000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.APIP, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 100000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.APIP, value: '$5,000' }
      ]
    }
  },
  {
    states: ['VA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 25000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 50000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 100000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' }
      ]
    }
  },
  {
    states: ['WA'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 25000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$35,000' },
        { label: ADDITIONAL_COVERAGES.APIP, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 50000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$35,000' },
        { label: ADDITIONAL_COVERAGES.APIP, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.UNDERINSURED_PROPERTY_DAMAGE]: 100000
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.PIP_LIMIT, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' }
      ]
    }
  },
  {
    states: ['WV'],
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$25,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$50,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$25,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.UNINSURED_PROPERTY_DAMAGE, value: '$100,000' },
        { label: ADDITIONAL_COVERAGES.MEDICAL_PAYMENTS, value: '$50,000' },
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        }
      ]
    }
  }
];

export const STATE_COVERAGE_TEMPLATES = {
  [COVERAGE_TYPES.HOME]: HOME_COVERAGE_BY_STATE,
  [COVERAGE_TYPES.CONDO]: CONDO_COVERAGE_BY_STATE,
  [COVERAGE_TYPES.AUTO]: AUTO_COVERAGE_BY_STATE
};

const AUTO_COVERAGE_BY_CARRIER = {
  'W2-TRAV': {
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.ROADSIDE_ASSISTANCE, value: 'Basic' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.ROADSIDE_ASSISTANCE, value: 'Premier' }
      ]
    }
  },
  'W2-NWIDE': {
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$30 per day & $900 per accident'
        },
        { label: ADDITIONAL_COVERAGES.ROADSIDE_ASSISTANCE, value: 'Basic' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.ROADSIDE_ASSISTANCE, value: 'Plus' }
      ]
    }
  },
  'D-PROG': {
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$40 per day & $1,200 per accident'
        },
        { label: ADDITIONAL_COVERAGES.ROADSIDE_ASSISTANCE, value: 'Included' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$50 per day & $1,500 per accident'
        },
        { label: ADDITIONAL_COVERAGES.ROADSIDE_ASSISTANCE, value: 'Included' }
      ]
    }
  },
  'W3-SAFECO': {
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        {
          label: ADDITIONAL_COVERAGES.RENTAL_REIMBURSEMENT,
          value: '$35 per day & $1,050 per accident'
        }
      ]
    }
  }
};

const HOME_COVERAGE_BY_CARRIER = {
  'W2-TRAV': {
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '$5,000' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '$2,500' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '$1,500' }
      ]
    }
  },
  'D-PROCK': {
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '50%'
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '70%'
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '70%'
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    }
  },
  'W2-NWIDE': {
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '55%'
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: 'Actual Loss Sustained' },
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '75%'
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: 'Actual Loss Sustained' },
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '.5%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.PERSONAL_PROPERTY]: '100%'
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: 'Actual Loss Sustained' },
        { label: ADDITIONAL_COVERAGES.INCREASED_REPLACEMENT_COST, value: '50%' },
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '$1,500' }
      ]
    }
  },
  'W2-HIPPO': {
    [COVERAGE_TAGS.LOW]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.OTHER_STRUCTURES]: '20%'
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '30%' },
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.MEDIUM]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.OTHER_STRUCTURES]: '20%'
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '30%' },
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    },
    [COVERAGE_TAGS.HIGH]: {
      [QUOTE_FIELDS.COVERAGES]: {
        [COVERAGE_FIELDS.OTHER_STRUCTURES]: '20%'
      },
      [QUOTE_FIELDS.ADDITIONAL_COVERAGES]: [
        { label: ADDITIONAL_COVERAGES.LOSS_OF_USE, value: '30%' },
        { label: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE, value: '1%' }
      ]
    }
  }
};

export const CARRIER_COVERAGE_TEMPLATES = {
  [COVERAGE_TYPES.HOME]: HOME_COVERAGE_BY_CARRIER,
  [COVERAGE_TYPES.AUTO]: AUTO_COVERAGE_BY_CARRIER
};

export const WIND_HURRICANE_HAIL_MAP = {
  AL: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  GA: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  LA: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  MS: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  SC: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  VA: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  CT: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  DC: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  DE: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  HI: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  MA: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  MD: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  ME: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  NJ: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  RI: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  FL: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  NC: ADDITIONAL_COVERAGES.WIND_HURRICANE_DEDUCTIBLE,
  PA: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE,
  TX: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE,
  KY: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE,
  NY: ADDITIONAL_COVERAGES.WIND_HAIL_HURRICANE_DEDUCTIBLE,
  WV: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  TN: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  IA: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  KS: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  NE: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  OK: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  MO: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  SD: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  ND: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  NH: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  WI: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  VT: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  WY: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  AR: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  IL: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE,
  IN: ADDITIONAL_COVERAGES.WINDSTORM_HAIL_DEDUCTIBLE
};

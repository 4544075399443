import React from 'react';
import PropTypes from 'prop-types';
import {
  INCIDENT_TYPES,
  CLAIM_DESCRIPTIONS,
  VIOLATION_DESCRIPTIONS,
  ACCIDENT_DESCRIPTIONS
} from 'woop-shared/origination/enums';
import styles from '../../pages/CreateOrigination/CreateOrigination.css';
import LinkText from '../LinkText';
import DateField from '../DateField';
import Select from '../Select';
import { createSelectOption, createOptionsFromEnum } from '../Select/utils';
import { INCIDENT_FIELDS as FIELDS } from '../../constants/origination';
import { SUB_SECTIONS } from '../../constants/table-of-contents';

const sectionId = SUB_SECTIONS.AUTO_INCIDENTS;

const INCIDENT_TYPE_OPTIONS = createOptionsFromEnum(INCIDENT_TYPES);

const Incidents = ({ incidents, addIncident, deleteIncident, updateIncident, driverTags }) => {
  const createIncident = () => {
    const lastIncidentId = incidents?.[incidents.length - 1]?.[FIELDS.ID] || 0;
    const incident = { [FIELDS.ID]: lastIncidentId + 1 };
    return incident;
  };
  const handleDelete = incident => deleteIncident(incident[FIELDS.ID]);
  const handleAdd = () => addIncident(createIncident());

  const handleChangeEvent = incident => e => {
    const { name, value } = e.target;
    const id = incident[FIELDS.ID];
    updateIncident(id, { [name]: value });
  };

  const handleChange = incident => (name, value) => {
    const id = incident[FIELDS.ID];
    updateIncident(id, { [name]: value });
  };

  const handleDescriptionChange = incident => (name, value) => {
    const id = incident[FIELDS.ID];
    const updateObj = {
      [FIELDS.VIOLATION_DESC]: undefined,
      [FIELDS.CLAIM_DESC]: undefined,
      [FIELDS.ACCIDENT_DESC]: undefined,
      [name]: value
    };
    updateIncident(id, updateObj);
  };

  return (
    <div id={sectionId}>
      <h3 className={styles.heading}>Auto Incidents</h3>
      {incidents.map(incident => {
        return (
          <div key={incident[FIELDS.ID]} className={styles.fieldWrapper}>
            <div className={styles.labelWrapper}>
              <LinkText onClick={() => handleDelete(incident)}>Delete</LinkText>
              <div className={styles.label}>{incident[FIELDS.ID]}</div>
            </div>
            <Select
              prompt={'Driver'}
              name={FIELDS.DRIVER}
              options={driverTags}
              onChange={handleChange(incident)}
              value={driverTags.find(tag => tag.value === incident[FIELDS.DRIVER])}
              isValid={true}
            />
            <Select
              prompt={'Incident Type'}
              name={FIELDS.TYPE}
              options={INCIDENT_TYPE_OPTIONS}
              onChange={handleChange(incident)}
              value={createSelectOption(incident[FIELDS.TYPE])}
              isValid={true}
            />
            <DateField
              name={FIELDS.INCIDENT_DATE}
              prompt={'Incident Date'}
              value={incident[FIELDS.INCIDENT_DATE]}
              onChange={handleChangeEvent(incident)}
            />
            {incident[FIELDS.TYPE] === INCIDENT_TYPES.ACCIDENT && (
              <Select
                prompt={'Incident Description'}
                name={FIELDS.ACCIDENT_DESC}
                options={createOptionsFromEnum(ACCIDENT_DESCRIPTIONS)}
                onChange={handleDescriptionChange(incident)}
                isValid={true}
                value={createSelectOption(incident[FIELDS.ACCIDENT_DESC])}
              />
            )}
            {incident[FIELDS.TYPE] === INCIDENT_TYPES.CLAIM && (
              <Select
                prompt={'Incident Description'}
                name={FIELDS.CLAIM_DESC}
                options={createOptionsFromEnum(CLAIM_DESCRIPTIONS)}
                onChange={handleDescriptionChange(incident)}
                isValid={true}
                value={createSelectOption(incident[FIELDS.CLAIM_DESC])}
              />
            )}
            {incident[FIELDS.TYPE] === INCIDENT_TYPES.VIOLATION && (
              <Select
                prompt={'Incident Description'}
                name={FIELDS.VIOLATION_DESC}
                options={createOptionsFromEnum(VIOLATION_DESCRIPTIONS)}
                onChange={handleDescriptionChange(incident)}
                isValid={true}
                value={createSelectOption(incident[FIELDS.VIOLATION_DESC])}
              />
            )}
          </div>
        );
      })}
      <LinkText onClick={handleAdd}>Add Incident</LinkText>
    </div>
  );
};

Incidents.propTypes = {
  addIncident: PropTypes.func,
  deleteIncident: PropTypes.func,
  driverTags: PropTypes.array,
  incidents: PropTypes.array,
  updateIncident: PropTypes.func
};

export default Incidents;

const { COVERAGES } = require('woop-shared/canopy/coverages');

export const UNMAPPED_COVERAGES = [
  // Auto
  COVERAGES.COMBINED_SINGLE_LIMIT,
  COVERAGES.CAR_REPLACEMENT_ASSISTANCE,
  COVERAGES.RIDESHARE_GAP_PROTECTION,
  COVERAGES.MECHANICAL_BREAKDOWN,
  COVERAGES.SINGLE_LIMIT_LIABILITY,
  COVERAGES.SUPPLEMENTAL_SPOUSAL_LIABILITY,

  // Home
  COVERAGES.HURRICANE,
  COVERAGES.VACATION_LIABILITY_COVERAGE,
  COVERAGES.REPLACEMENT_COST_FULL_VALUE,
  COVERAGES.ADDITIONAL_LIVING_EXPENSE,
  COVERAGES.REPLACEMENT_COST_PERSONAL_EFFECTS,
  COVERAGES.BUILDING_CODES
];

const { UI_ADDRESS_FIELDS } = require('woop-shared/enums');
const { ADDRESS_FIELDS } = require('woop-shared/origination/fields');

/**
 * Maps origination address fields to UI address fields
 *
 * @param {object} address an originations address object
 * @returns {object} a UI address object
 */
export function mapAddress(address) {
  return {
    [UI_ADDRESS_FIELDS.COMPOSITE]: address?.[ADDRESS_FIELDS.COMPOSITE],
    [UI_ADDRESS_FIELDS.STREET]: [
      address?.[ADDRESS_FIELDS.STREET_NUMBER],
      address?.[ADDRESS_FIELDS.STREET_NAME]
    ]
      .filter(Boolean)
      .join(' '),
    [UI_ADDRESS_FIELDS.COUNTY]: address?.[ADDRESS_FIELDS.MUNICIPALITY_LEVEL2],
    [UI_ADDRESS_FIELDS.CITY]: address?.[ADDRESS_FIELDS.MUNICIPALITY_LEVEL3],
    [UI_ADDRESS_FIELDS.STATE]: address?.[ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE],
    [UI_ADDRESS_FIELDS.POSTAL_CODE]: address?.[ADDRESS_FIELDS.POSTAL_CODE]
  };
}

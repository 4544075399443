import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  VEHICLE_OWNERSHIP_STATUS,
  VEHICLE_ACTIVITES,
  VEHICLE_USES,
  ANNUAL_MILEAGE_RANGES
} from 'woop-shared/origination/enums';
import TextField from '../TextField';
import LinkText from '../LinkText';
import styles from '../../pages/CreateOrigination/CreateOrigination.css';
import Select from '../Select';
import {
  VEHICLE_FIELDS as FIELDS,
  VEHICLE_TAG_PREFIX,
  YES_NO_OPTIONS
} from '../../constants/origination';
import {
  createSelectOptions,
  createSelectOption,
  createMultiSelectValue,
  createOptionsFromEnum
} from '../Select/utils';
import NumberField from '../NumberField';
import { AUTO_FINANCING_INSTITUTIONS } from '../../constants/auto-financing-institutions';
import VehiclePicker from '../VehiclePicker';
import DateField from '../DateField';
import RadioGroup from '../RadioGroup';
import { SUB_SECTIONS } from '../../constants/table-of-contents';

const sectionId = SUB_SECTIONS.VEHICLES;

const Vehicles = ({
  vehicles,
  addVehicle,
  deleteVehicle,
  updateVehicle,
  addressTags,
  driverTags
}) => {
  const [nextId, setNextId] = useState(vehicles.length + 1);
  const createVehicle = () => {
    const vehicle = {
      [FIELDS.VEHICLE_TAG]: VEHICLE_TAG_PREFIX + nextId
    };
    setNextId(nextId + 1);
    return vehicle;
  };
  const handleDelete = vehicle => deleteVehicle(vehicle[FIELDS.VEHICLE_TAG]);
  const handleAdd = () => addVehicle(createVehicle());

  const handleFieldChange = vehicle => (name, value) => {
    const id = vehicle[FIELDS.VEHICLE_TAG];
    updateVehicle(id, { [name]: value });
  };

  const handleVehicleChange = vehicle => payload => {
    const id = vehicle[FIELDS.VEHICLE_TAG];
    updateVehicle(id, payload);
  };

  return (
    <div id={sectionId}>
      <h3 className={styles.heading}>Vehicles</h3>
      {vehicles.map((vehicle, index) => {
        return (
          <div key={vehicle[FIELDS.VEHICLE_TAG]} className={styles.fieldWrapper}>
            <div className={styles.labelWrapper}>
              <LinkText onClick={() => handleDelete(vehicle)}>Delete</LinkText>
              <div className={styles.label}>{vehicle[FIELDS.VEHICLE_TAG]}</div>
            </div>
            <TextField
              label="VIN"
              name={FIELDS.VIN}
              value={vehicle[FIELDS.VIN]}
              onChange={e => handleFieldChange(vehicle)(FIELDS.VIN, e?.target?.value || '')}
              id={`vin-${index}`}
            />
            <VehiclePicker
              onChange={handleVehicleChange(vehicle)}
              year={vehicle[FIELDS.YEAR]}
              make={vehicle[FIELDS.MAKE]}
              model={vehicle[FIELDS.MODEL]}
              index={index}
            />
            <Select
              prompt="Primary Driver"
              options={driverTags}
              name={FIELDS.PRIMARY_DRIVER}
              onChange={handleFieldChange(vehicle)}
              isValid={true}
              value={driverTags.find(tag => tag.value === vehicle[FIELDS.PRIMARY_DRIVER])}
              id={`primary-driver-${index}`}
              inputId={`primary-driver-select-${index}`}
            />
            <Select
              prompt="Ownership Status"
              options={createOptionsFromEnum(VEHICLE_OWNERSHIP_STATUS)}
              name={FIELDS.VEHICLE_OWNERSHIP_STATUS}
              onChange={handleFieldChange(vehicle)}
              isValid={true}
              value={createSelectOption(vehicle[FIELDS.VEHICLE_OWNERSHIP_STATUS])}
            />
            <DateField
              name={FIELDS.VEHICLE_PURCHASE_DATE}
              prompt="Vehicle Purchase Date"
              value={vehicle[FIELDS.VEHICLE_PURCHASE_DATE]}
              onChange={e => handleFieldChange(vehicle)(e?.target?.name, e?.target?.value)}
            />
            <Select
              prompt="Vehicle Usage"
              options={createOptionsFromEnum(VEHICLE_USES)}
              name={FIELDS.VEHICLE_USE}
              onChange={handleFieldChange(vehicle)}
              isValid={true}
              value={createSelectOption(vehicle[FIELDS.VEHICLE_USE])}
            />
            <NumberField
              label="Mileage"
              name={FIELDS.MILEAGE}
              value={vehicle[FIELDS.MILEAGE]}
              onChange={handleFieldChange(vehicle)}
            />
            <Select
              prompt="Estimated annual mileage"
              options={createOptionsFromEnum(ANNUAL_MILEAGE_RANGES)}
              name={FIELDS.MILES_DRIVEN_PER_YEAR}
              value={createSelectOption(vehicle[FIELDS.MILES_DRIVEN_PER_YEAR])}
              onChange={handleFieldChange(vehicle)}
              isValid={true}
            />
            <Select
              prompt="Vehicle Activities"
              isMulti={true}
              options={createSelectOptions(VEHICLE_ACTIVITES)}
              name={FIELDS.VEHICLE_ACTIVITES}
              onChange={handleFieldChange(vehicle)}
              isValid={true}
              value={createMultiSelectValue(vehicle[FIELDS.VEHICLE_ACTIVITES])}
            />
            <RadioGroup
              prompt="Vehicle is parked at the primary address"
              name={FIELDS.PARKED_AT_PRIMARY_ADDRESS}
              value={vehicle[FIELDS.PARKED_AT_PRIMARY_ADDRESS]}
              onChange={handleFieldChange(vehicle)}
              options={YES_NO_OPTIONS}
            />
            {!vehicle[FIELDS.PARKED_AT_PRIMARY_ADDRESS] && (
              <Select
                prompt="Alternate Vehicle Address (e.g. garage)"
                options={addressTags}
                name={FIELDS.PRIMARY_GARAGE}
                onChange={handleFieldChange(vehicle)}
                isValid={true}
                value={addressTags.find(a => a.value === vehicle[FIELDS.PRIMARY_GARAGE])}
              />
            )}
            <Select
              prompt="Financing Institution/Lienholder"
              options={AUTO_FINANCING_INSTITUTIONS}
              createable={true}
              name={FIELDS.FINANCING_LIEN_HOLDER}
              onChange={handleFieldChange(vehicle)}
              isValid={true}
              value={createSelectOption(vehicle[FIELDS.FINANCING_LIEN_HOLDER])}
              id={`financing-institution-${index}`}
              inputId={`financing-institution-select-${index}`}
            />
          </div>
        );
      })}
      <LinkText onClick={handleAdd}>Add Vehicle</LinkText>
    </div>
  );
};

Vehicles.propTypes = {
  addVehicle: PropTypes.func,
  vehicles: PropTypes.array,
  updateVehicle: PropTypes.func,
  deleteVehicle: PropTypes.func,
  addressTags: PropTypes.array,
  driverTags: PropTypes.array
};

export default Vehicles;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__wrapper--3kHYW{padding:2rem;width:480px;display:flex;flex-direction:column;grid-gap:20px;gap:20px}.styles-module__wrapper--3kHYW.styles-module__skipped--386YA{background:transparent;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}.styles-module__wrapper--3kHYW.styles-module__skipped--386YA .styles-module__header--2Fq3R{opacity:.5}.styles-module__header--2Fq3R{display:flex;flex-direction:column}.styles-module__header--2Fq3R h3{margin-bottom:0;text-transform:uppercase}.styles-module__header--2Fq3R span{color:var(--color-grey-dark);font-size:var(--fs-sm)}.styles-module__form--1lw1j{display:flex;flex-direction:column;grid-gap:1.5rem;gap:1.5rem}.styles-module__toggle--zG2RX{position:absolute;top:0;right:0;padding:10px}.styles-module__coverageRows--3SIe6 .styles-module__rowHeader--3jvsY{display:flex;grid-gap:.5rem;gap:.5rem;align-items:center;font-size:var(--fs-sm)}.styles-module__coverageRows--3SIe6 .styles-module__rowHeader--3jvsY p{margin:0}.styles-module__coverageRows--3SIe6{display:flex;flex-direction:column;grid-gap:1rem;gap:1rem}.styles-module__row--3HrRZ{display:flex;align-items:center;grid-gap:.5rem;gap:.5rem}.styles-module__row--3HrRZ .styles-module__label--1v5py{width:200px}.styles-module__row--3HrRZ .styles-module__label--1v5py div:first-of-type{font-weight:var(--fw-medium);color:var(--color-grey-darker)}.styles-module__row--3HrRZ .styles-module__label--1v5py div:last-of-type{color:var(--color-grey-dark)}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles-module__wrapper--3kHYW",
	"skipped": "styles-module__skipped--386YA",
	"header": "styles-module__header--2Fq3R",
	"form": "styles-module__form--1lw1j",
	"toggle": "styles-module__toggle--zG2RX",
	"coverageRows": "styles-module__coverageRows--3SIe6",
	"rowHeader": "styles-module__rowHeader--3jvsY",
	"row": "styles-module__row--3HrRZ",
	"label": "styles-module__label--1v5py"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__wrapper--2zPmq{display:flex;margin:.2rem;flex-direction:column;justify-content:center;border-radius:.5rem;padding:.25rem .5rem;font-weight:var(--fw-medium);font-size:var(--fs-xs);background:var(--color-grey-lighter);color:var(--color-grey-darker)}.styles__wrapper--2zPmq.styles__orange--3vp-u{background:var(--color-orange-lightest);color:var(--color-orange-darkest)}.styles__wrapper--2zPmq.styles__red--2gMQn{background:var(--color-red-lightest);color:var(--color-red-darkest)}.styles__wrapper--2zPmq.styles__green--2sh4m{background:var(--color-green-lightest);color:var(--color-green-darkest)}.styles__wrapper--2zPmq.styles__purple--2b6F4{background:var(--color-purple-lightest);color:var(--color-purple-darkest)}", ""]);
// Exports
exports.locals = {
	"wrapper": "styles__wrapper--2zPmq",
	"orange": "styles__orange--3vp-u",
	"red": "styles__red--2gMQn",
	"green": "styles__green--2sh4m",
	"purple": "styles__purple--2b6F4"
};
module.exports = exports;

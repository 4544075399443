import React, { useState } from 'react';
import RSelect from 'react-select';
import CreateableSelect from 'react-select/creatable';
import { FiInfo } from 'react-icons/fi';
import PropTypes from 'prop-types';
import Prompt from '../Prompt';
import Tooltip from '../Tooltip';
import styles from './styles.css';

const Select = ({
  prompt,
  placeholder,
  value,
  options,
  onChange,
  onRemove,
  onAdd,
  name,
  showValidation,
  isValid,
  isMulti,
  createable,
  id,
  inputId,
  isClearable = true,
  tooltip,
  isOptionDisabled,
  autoFocus,
  appearOnTop = true,
  validationText,
  ...rest
}) => {
  const [wasBlurred, setWasBlurred] = useState(false);
  const SelectComponent = createable ? CreateableSelect : RSelect;

  const maybeAppearOnTopProps = appearOnTop
    ? {
        menuPortalTarget: document.body,
        menuShouldScrollIntoView: false,
        styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) }
      }
    : {};

  return (
    <div>
      {prompt && (
        <Prompt invalid={(showValidation || wasBlurred) && !isValid}>
          {prompt}
          {tooltip && (
            <Tooltip title={tooltip}>
              <FiInfo />
            </Tooltip>
          )}
        </Prompt>
      )}
      <SelectComponent
        autoFocus={autoFocus}
        classNamePrefix="react-select"
        value={value ?? null}
        name={name}
        isMulti={isMulti}
        placeholder={placeholder}
        options={options}
        isClearable={isClearable}
        isOptionDisabled={isOptionDisabled}
        onBlur={() => setWasBlurred(showValidation)}
        onChange={(selection, actionMeta) => {
          const val = isMulti ? selection?.map(o => o?.value) || [] : selection?.value;

          if (actionMeta.action === 'remove-value' && onRemove) {
            return onRemove(name, val, actionMeta.removedValue.value);
          }

          if (actionMeta.action === 'select-option' && onAdd) {
            return onAdd(name, val, actionMeta.option.value);
          }

          onChange(name, val, actionMeta);
        }}
        id={id || `${name}-container`}
        inputId={inputId || `${name}-select`}
        {...maybeAppearOnTopProps}
        {...rest}
      />
      {!isValid && showValidation && validationText && (
        <p className={styles.error}>{validationText}</p>
      )}
    </div>
  );
};

Select.propTypes = {
  createable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isValid: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  name: PropTypes.string,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  prompt: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showValidation: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  ]),
  id: PropTypes.string,
  inputId: PropTypes.string,
  tooltip: PropTypes.string,
  appearOnTop: PropTypes.bool,
  validationText: PropTypes.string,
  autoFocus: PropTypes.bool
};

export default Select;

import { COVERAGE_TAGS } from 'woop-shared/enums';

export const COVERAGE_TAG_OPTIONS = [
  {
    label: 'Low',
    value: COVERAGE_TAGS.LOW
  },
  {
    label: 'Medium',
    value: COVERAGE_TAGS.MEDIUM
  },
  {
    label: 'High',
    value: COVERAGE_TAGS.HIGH
  }
];

export const COMPARE_TAG_OPTIONS = [
  {
    label: 'Apples to Apples 🍏',
    value: COVERAGE_TAGS.MEDIUM
  },
  {
    label: 'Our recommendation 🛡',
    value: COVERAGE_TAGS.HIGH
  }
];

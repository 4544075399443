import _ from 'lodash';
import {
  APPLICANT_FIELDS,
  PROPERTY_FIELDS,
  VEHICLE_FIELDS,
  INCIDENT_FIELDS,
  CLAIM_FIELDS,
  ADDRESS_FIELDS,
  AUTO_FIELDS,
  ORIGINATION_FIELDS
} from 'woop-shared/origination/fields';

export function removeExtraFields(quoteApp = {}) {
  const pick = fields => entity => _.pick(entity, [...Object.values(fields), 'id']);

  return {
    ...quoteApp,
    [ORIGINATION_FIELDS.APPLICANTS]: quoteApp[ORIGINATION_FIELDS.APPLICANTS]?.map(
      pick(APPLICANT_FIELDS)
    ),
    [ORIGINATION_FIELDS.PROPERTIES]: quoteApp[ORIGINATION_FIELDS.PROPERTIES]?.map(
      pick(PROPERTY_FIELDS)
    ),
    [ORIGINATION_FIELDS.ADDRESSES]: quoteApp[ORIGINATION_FIELDS.ADDRESSES]?.map(
      pick(ADDRESS_FIELDS)
    ),
    [ORIGINATION_FIELDS.AUTO_INFO]: {
      ...pick(AUTO_FIELDS)(quoteApp[ORIGINATION_FIELDS.AUTO_INFO]),
      [ORIGINATION_FIELDS.VEHICLES]: quoteApp[ORIGINATION_FIELDS.AUTO_INFO]?.[
        ORIGINATION_FIELDS.VEHICLES
      ]?.map(pick(VEHICLE_FIELDS)),
      [ORIGINATION_FIELDS.INCIDENTS]: quoteApp[ORIGINATION_FIELDS.AUTO_INFO]?.[
        ORIGINATION_FIELDS.INCIDENTS
      ]?.map(pick(INCIDENT_FIELDS))
    },
    [ORIGINATION_FIELDS.CLAIMS]: quoteApp[ORIGINATION_FIELDS.CLAIMS]?.map(pick(CLAIM_FIELDS))
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './LinkText.css';

const cx = classnames.bind(styles);

const LinkText = ({
  children,
  className,
  disabled = false,
  external = false,
  icon,
  noUnderline,
  ...rest
}) => {
  // Fake a button click when the user presses enter or space
  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.click();
    }
  };

  return (
    <a
      tabIndex={0}
      className={cx('link', className, { disabled, noUnderline })}
      target={external ? '_blank' : undefined}
      rel={external ? 'noreferrer' : undefined}
      onKeyDown={handleKeyDown}
      {...rest}
    >
      {children}
      {icon && <span className={cx('icon', className)}>{icon}</span>}
    </a>
  );
};

LinkText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.node,
  noUnderline: PropTypes.bool
};

export default LinkText;

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../pages/CreateOrigination/CreateOrigination.css';
import LinkText from '../LinkText';
import RadioGroup from '../RadioGroup';
import Card from '../Card';
import { POOL_FIELDS as FIELDS, YES_NO_OPTIONS } from '../../constants/origination';
import { SUB_SECTIONS } from '../../constants/table-of-contents';
import { shouldHideSection } from '../../utils/table-of-contents';

const sectionId = SUB_SECTIONS.POOLS;

const Pools = ({ pools, addPool, deletePool, updatePool, coverageTypes }) => {
  const createPool = () => {
    const lastlPoolId = pools?.[pools.length - 1]?.[FIELDS.ID] || 0;
    const pool = { [FIELDS.ID]: lastlPoolId + 1 };
    return pool;
  };
  const handleDelete = pool => deletePool(pool[FIELDS.ID]);
  const handleAdd = () => addPool(createPool());
  const handleChange = pool => (name, value) => {
    const id = pool[FIELDS.ID];
    updatePool(id, { [name]: value });
  };
  const shouldHide = shouldHideSection(sectionId, coverageTypes);

  return (
    !shouldHide && (
      <Card id={sectionId}>
        <h3 className={styles.heading}>Pools</h3>
        {pools.map(pool => {
          return (
            <div key={pool[FIELDS.ID]} className={styles.fieldWrapper}>
              <div className={styles.labelWrapper}>
                <LinkText onClick={() => handleDelete(pool)}>Delete</LinkText>
                <div className={styles.label}>{pool[FIELDS.ID]}</div>
              </div>
              <RadioGroup
                prompt="Is this an inground pool?"
                name={FIELDS.INGROUND}
                value={pool[FIELDS.INGROUND]}
                onChange={handleChange(pool)}
                options={YES_NO_OPTIONS}
              />
              <RadioGroup
                prompt="Is the access restricted (e.g. has a fence)?"
                name={FIELDS.ACCESS_RESTRICTED}
                value={pool[FIELDS.ACCESS_RESTRICTED]}
                onChange={handleChange(pool)}
                options={YES_NO_OPTIONS}
              />
              <RadioGroup
                prompt="Has a slide?"
                name={FIELDS.SLIDE}
                value={pool[FIELDS.SLIDE]}
                onChange={handleChange(pool)}
                options={YES_NO_OPTIONS}
              />
              <RadioGroup
                prompt="Has a diving board?"
                name={FIELDS.DIVING_BOARD}
                value={pool[FIELDS.DIVING_BOARD]}
                onChange={handleChange(pool)}
                options={YES_NO_OPTIONS}
              />
            </div>
          );
        })}
        <LinkText onClick={handleAdd}>Add Pool</LinkText>
      </Card>
    )
  );
};

Pools.propTypes = {
  addPool: PropTypes.func,
  deletePool: PropTypes.func,
  updatePool: PropTypes.func,
  pools: PropTypes.array,
  coverageTypes: PropTypes.array
};

export default Pools;

import React from 'react';
import PropTypes from 'prop-types';
import { FiX, FiCopy, FiEdit } from 'react-icons/fi';
import { QUOTE_FIELDS } from 'woop-shared/db/models';
import Card from '../../../../components/Card';
import { getQuoteId } from '../../utils';
import styles from './styles.css';
import QuoteSummary from '../QuoteSummary';
import ValidationInfo from '../ValidationInfo';
import LinkText from '../../../../components/LinkText';

const QuotesList = ({
  quotes,
  currentPolicyTypes = [],
  editQuote = () => {},
  deleteQuote = () => {},
  copyQuote = () => {},
  children
}) => {
  return (
    <Card className={styles.wrapper}>
      <header>{children}</header>
      {quotes.map(quote => (
        <div className={styles.item} key={getQuoteId(quote)}>
          <header>
            <FiEdit onClick={e => editQuote(e, quote)} />
          </header>

          <QuoteSummary quote={quote} />

          <ValidationInfo
            quote={quote}
            compare={currentPolicyTypes.includes(quote[QUOTE_FIELDS.COVERAGE_TYPE])}
          />

          <footer>
            {!quote?.[QUOTE_FIELDS.IS_CURRENT_POLICY] && (
              <LinkText icon={<FiCopy />} onClick={e => copyQuote(e, quote)}>
                Duplicate
              </LinkText>
            )}
            <LinkText icon={<FiX />} onClick={e => deleteQuote(e, quote)}>
              Delete
            </LinkText>
          </footer>
        </div>
      ))}
    </Card>
  );
};

export default QuotesList;

QuotesList.propTypes = {
  quotes: PropTypes.array,
  currentPolicyTypes: PropTypes.arrayOf(PropTypes.string),
  editQuote: PropTypes.func,
  deleteQuote: PropTypes.func,
  copyQuote: PropTypes.func,
  children: PropTypes.node
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiCheckCircle } from 'react-icons/fi';
import Button from '../../Button';
import { useModalControls } from '../../../hooks/modal-controls';
import Modal from '../../Modal';
import DateField from '../../DateField';
import TextField from '../../TextField';
import styles from './styles.css';

const ProcessPaymentModal = ({ processPayment, paymentId, loading, disabled }) => {
  const [visible, closing, toggleModal] = useModalControls(false);
  const [effectiveDate, setEffectiveDate] = useState();
  const [policyNumber, setPolicyNumber] = useState();

  const formatEffectiveDate = date => {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  };

  const handleProcessPayment = () => {
    const formattedEffectiveDate = formatEffectiveDate(effectiveDate);
    processPayment({
      paymentId,
      effectiveDate: formattedEffectiveDate,
      policyNumber,
      callback: toggleModal
    });
  };

  const valid = effectiveDate?.length === 10 && policyNumber;

  return (
    <>
      <Button
        className={styles.process}
        onClick={toggleModal}
        loading={loading}
        disabled={disabled}
      >
        Process →
      </Button>
      {visible && (
        <Modal visible={visible} closing={closing} toggleModal={toggleModal}>
          <h2>Process payment</h2>

          <div className={styles.form}>
            <DateField
              name="effectiveDate"
              value={effectiveDate}
              onChange={e => setEffectiveDate(e.target.value)}
              prompt="Effective date"
            />
            <TextField
              name="policyNumber"
              value={policyNumber}
              onChange={e => setPolicyNumber(e.target.value)}
              label="Policy number"
            />
          </div>

          <Button
            className={styles.success}
            onClick={handleProcessPayment}
            loading={loading}
            disabled={!valid}
          >
            Mark as Processed <FiCheckCircle />
          </Button>
        </Modal>
      )}
    </>
  );
};

ProcessPaymentModal.propTypes = {
  loading: PropTypes.bool,
  paymentId: PropTypes.string,
  processPayment: PropTypes.func,
  disabled: PropTypes.bool
};

export default ProcessPaymentModal;

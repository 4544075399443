import { takeLatest, put } from 'redux-saga/effects';
import { error } from '../utils/logger';
import { setLoading } from '../actions/loading';
import { setError } from '../actions/error';
import { getPolicy } from '../api/policy';
import { getPolicySuccess, GET_POLICY } from '../actions/policies';

function* handleGetPolicy(action) {
  const policyId = action.payload;
  yield put(setLoading(true));
  try {
    const data = yield getPolicy({ policyId });
    yield put(getPolicySuccess(data));
  } catch (e) {
    yield put(setError({ message: `Couldn't find that policy! (${e})` }));
    error(e, action);
  }
  yield put(setLoading(false));
}

export function* watchGetPolicy() {
  yield takeLatest(GET_POLICY, handleGetPolicy);
}

import React from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { HiOutlinePencilAlt, HiOutlineXCircle } from 'react-icons/hi';
import { COVERAGE_TYPES } from 'woop-shared/enums';
import { ADDRESS_FIELDS } from 'woop-shared/origination/fields';
import { QUOTE_REQUEST_FIELDS } from 'woop-shared/db/models';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Switch } from '@mui/material';
import Card from '../../../../components/Card';
import { getCoveragesLabel, getRequestIcon } from '../../utils';
import LinkText from '../../../../components/LinkText';
import BadgeButton from '../../../../components/BadgeButton';
import styles from './styles.module.css';
import DateField from '../../../../components/DateField';
import Prompt from '../../../../components/Prompt';
import { updateRequests } from '../../../../actions/quote-request';
import RadioGroup from '../../../../components/RadioGroup';
import ZywaveCarrierSelect from '../CarrierSelect';

const cx = classNames.bind(styles);

const RequestGroupCard = ({ title, requests, setActiveRequest, toggleModal, saveRequest }) => {
  const request = requests?.[0];
  if (!request) return null;
  const { lob, propertyId } = request; // Group level data.
  const isAuto = lob === COVERAGE_TYPES.AUTO;

  const dispatch = useDispatch();
  const { quoteRequest } = useSelector(state => state);

  const onChange = updates => {
    const updatedRequests = quoteRequest[QUOTE_REQUEST_FIELDS.REQUESTS]
      .map(req => {
        const autoMatch = isAuto && req[QUOTE_REQUEST_FIELDS.LOB] === COVERAGE_TYPES.AUTO;
        const propertyMatch = propertyId && req[QUOTE_REQUEST_FIELDS.PROPERTY_ID] === propertyId;
        return autoMatch || propertyMatch ? { ...req, ...updates } : null;
      })
      .filter(Boolean);
    dispatch(updateRequests({ requests: updatedRequests }));
  };

  const onAdd = () => {
    const newRequest = {
      ...request,
      id: uuidv4(),
      [QUOTE_REQUEST_FIELDS.COVERAGES]: [],
      [QUOTE_REQUEST_FIELDS.TYPE]: null,
      [QUOTE_REQUEST_FIELDS.TERM_LENGTH]: isAuto ? 6 : 12
    };
    if (isAuto)
      newRequest[QUOTE_REQUEST_FIELDS.VEHICLES] = request[QUOTE_REQUEST_FIELDS.VEHICLES]?.map(
        v => ({
          ...v,
          coverages: []
        })
      );
    setActiveRequest(newRequest);
    toggleModal();
  };

  const onEdit = request => {
    setActiveRequest(request);
    toggleModal();
  };

  const onDelete = id => {
    const update = quoteRequest[QUOTE_REQUEST_FIELDS.REQUESTS].filter(r => r.id !== id);
    saveRequest({ ...quoteRequest, requests: update });
  };

  const skipped = !!request[QUOTE_REQUEST_FIELDS.SKIP];

  return (
    <Card className={cx('wrapper', { skipped })}>
      <div className={styles.header}>
        <h3>{title}</h3>
        {!isAuto && (
          <span>{request[QUOTE_REQUEST_FIELDS.ADDRESS]?.[ADDRESS_FIELDS.COMPOSITE]}</span>
        )}
      </div>

      {!skipped && (
        <div className={styles.form}>
          <DateField
            value={request[QUOTE_REQUEST_FIELDS.EFFECTIVE_DATE]}
            onChange={e => onChange({ effectiveDate: e.target.value })}
            prompt="Effective date"
            min={dayjs().format('YYYY-MM-DD')}
            tooltip="Optional. Defaults to 15 days from today."
          />
          {request[QUOTE_REQUEST_FIELDS.LOB] === COVERAGE_TYPES.AUTO && (
            <RadioGroup
              name={QUOTE_REQUEST_FIELDS.TERM_LENGTH}
              prompt={'Term length'}
              options={[
                { label: '6 months', value: 6 },
                { label: '12 months', value: 12 }
              ]}
              onChange={(key, val) => onChange({ [key]: val })}
              value={request[QUOTE_REQUEST_FIELDS.TERM_LENGTH]}
            />
          )}

          <div>
            <ZywaveCarrierSelect
              name={QUOTE_REQUEST_FIELDS.CARRIERS}
              state={
                request?.[QUOTE_REQUEST_FIELDS.ADDRESS]?.[ADDRESS_FIELDS.MUNICIPALITY_LEVEL1_CODE]
              }
              lob={isAuto ? COVERAGE_TYPES.AUTO : COVERAGE_TYPES.HOME}
              onChange={(key, carriers) => {
                onChange({ [QUOTE_REQUEST_FIELDS.CARRIERS]: carriers });
              }}
              value={request[QUOTE_REQUEST_FIELDS.CARRIERS]}
            />
          </div>
          <div className={styles.coverageRows}>
            <div className={styles.rowHeader}>
              <Prompt>
                Quote requests<LinkText onClick={onAdd}>Add +</LinkText>
              </Prompt>
            </div>

            {requests.map((request, index) => {
              const { coverages, type } = request;
              return (
                <div key={type + index}>
                  <div className={styles.row}>
                    <div className={styles.label}>
                      <div>
                        {type} {getRequestIcon(type)}
                      </div>
                      <div>{getCoveragesLabel(coverages)}</div>
                    </div>
                    <BadgeButton onClick={() => onEdit(request)}>
                      <HiOutlinePencilAlt />
                    </BadgeButton>
                    <BadgeButton
                      color={'red'}
                      disabled={requests.length === 1}
                      onClick={() => onDelete(request.id)}
                    >
                      <HiOutlineXCircle />
                    </BadgeButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <FormControlLabel
        className={styles.toggle}
        control={
          <Switch
            color={'success'}
            sx={{
              '&.MuiSwitch-root .MuiSwitch-switchBase': {
                color: 'var(--color-red-primary)'
              },

              '&.MuiSwitch-root .Mui-checked': {
                color: 'var(--color-green-primary)'
              }
            }}
            checked={!request[QUOTE_REQUEST_FIELDS.SKIP]}
            onChange={e => onChange({ [QUOTE_REQUEST_FIELDS.SKIP]: !e.target.checked })}
            title={'Controls whether to request quotes for this category.'}
          />
        }
      />
    </Card>
  );
};

RequestGroupCard.propTypes = {
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  title: PropTypes.string,
  requests: PropTypes.array,
  saveRequest: PropTypes.func,
  setActiveRequest: PropTypes.func,
  toggleModal: PropTypes.func
};

export default RequestGroupCard;

import React, { useState } from 'react';
import { HiPencil, HiX } from 'react-icons/hi';
import PropTypes from 'prop-types';
import styles from './styles.css';
import { useModalControls } from '../../../../hooks/modal-controls';
import EditVehicle from '../EditVehicle';
import { deleteVehicle } from '../../../../api/policy';
import { formatVehicle } from '../../utils';
import MaybeError from '../../../../components/MaybeError';

const Vehicles = ({ policy, refreshPolicy }) => {
  const [visible, closing, toggleModal] = useModalControls(false);

  const [selectedVehicle, setSelectedVehicle] = useState();
  const toggleModalForVehicle = vehicle => () => {
    setSelectedVehicle(vehicle);
    toggleModal();
  };

  const vehicles = policy?.vehicles || [];

  const handleDelete = vehicle => () => {
    const confirmed = confirm(`Delete ${formatVehicle(vehicle)}?`);
    if (!confirmed) return;

    deleteVehicle({
      policyId: policy.policy_id,
      vehicleId: vehicle.vehicle_id
    }).then(() => refreshPolicy());
  };

  return (
    <div>
      <div className={styles.tableHeader}>
        Vehicles{' '}
        <a className={styles.edit} onClick={toggleModalForVehicle()}>
          + Add a new vehicle
        </a>
      </div>
      <div className={styles.table}>
        {vehicles.map(vehicle => {
          return (
            <div className={styles.row} key={`vehicle-${vehicle.vehicle_id}`}>
              <div>{formatVehicle(vehicle)}</div>
              <div>
                <MaybeError isValid={!!vehicle?.vin} errorText="Missing VIN">
                  {vehicle?.vin}
                </MaybeError>
              </div>
              <div className={styles.options}>
                <HiPencil onClick={toggleModalForVehicle(vehicle)} className={styles.edit} />
                <HiX onClick={handleDelete(vehicle)} className={styles.delete} />
              </div>
            </div>
          );
        })}
      </div>
      <EditVehicle
        policy={policy}
        vehicle={selectedVehicle}
        refreshPolicy={refreshPolicy}
        visible={visible}
        closing={closing}
        toggleModal={toggleModal}
      />
    </div>
  );
};

Vehicles.propTypes = {
  policy: PropTypes.object,
  refreshPolicy: PropTypes.func
};

export default Vehicles;

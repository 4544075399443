import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UI_ADDRESS_FIELDS } from 'woop-shared/enums';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import Prompt from '../../../../../../components/Prompt';
import TextField from '../../../../../../components/TextField';

function createComposite(address) {
  return `${address[UI_ADDRESS_FIELDS.HOUSE_NUMBER]} ${address[UI_ADDRESS_FIELDS.STREET]}, ${
    address[UI_ADDRESS_FIELDS.CITY]
  }, ${address[UI_ADDRESS_FIELDS.STATE]} ${address[UI_ADDRESS_FIELDS.POSTAL_CODE]}`;
}

function getAddress1(address) {
  if (address[UI_ADDRESS_FIELDS.HOUSE_NUMBER] || address[UI_ADDRESS_FIELDS.STREET]) {
    return `${address[UI_ADDRESS_FIELDS.HOUSE_NUMBER] || ''} ${
      address[UI_ADDRESS_FIELDS.STREET] || ''
    }`;
  }
  return '';
}

const Address = ({ address, updateHandler }) => {
  const [address1, setAddress1] = useState(getAddress1(address));

  const updateWrapper = updates => {
    updateHandler(PROMPT_NAMES.ADDRESS, {
      ...updates,
      [UI_ADDRESS_FIELDS.COMPOSITE]: createComposite(updates)
    });
  };

  const handleSetAddress1 = val => {
    setAddress1(val);
    const [houseNumber, ...rest] = val.split(' ');
    const street = rest ? rest.join(' ') : null;
    const updates = {
      ...address,
      [UI_ADDRESS_FIELDS.HOUSE_NUMBER]: houseNumber,
      [UI_ADDRESS_FIELDS.STREET]: street
    };
    updateWrapper(updates);
  };

  const handleUpdates = (name, value) => {
    const updates = { ...address, [name]: value };
    updateWrapper(updates);
  };

  return (
    <>
      <Prompt>Primary Address</Prompt>
      <pre>{`${address[UI_ADDRESS_FIELDS.COMPOSITE]}`}</pre>
      <TextField
        name={'address1'}
        label={'Address 1'}
        onChange={e => handleSetAddress1(e.target.value)}
        value={address1}
      />
      <TextField
        name={UI_ADDRESS_FIELDS.CITY}
        label={'City'}
        onChange={e => handleUpdates(e.target.name, e.target.value)}
        value={address[UI_ADDRESS_FIELDS.CITY]}
      />
      <TextField
        name={UI_ADDRESS_FIELDS.STATE}
        label={'State abbreviation'}
        maxLength={2}
        onChange={e => handleUpdates(e.target.name, e.target.value)}
        value={address[UI_ADDRESS_FIELDS.STATE]}
      />
      <TextField
        name={UI_ADDRESS_FIELDS.POSTAL_CODE}
        label={'Zip code'}
        maxLength={5}
        onChange={e => handleUpdates(e.target.name, e.target.value)}
        value={address[UI_ADDRESS_FIELDS.POSTAL_CODE]}
      />
    </>
  );
};

Address.propTypes = {
  address: PropTypes.object,
  updateHandler: PropTypes.func
};

export default Address;

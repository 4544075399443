import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAddressComposite } from 'woop-shared/utils';
import Card from '../../components/Card';
import ErrorText from '../../components/ErrorText';
import LinkText from '../../components/LinkText';
import PaymentCard from '../../components/PaymentCard/container';
import Table from '../../components/Table';
import { addIdToRoute, ROUTES } from '../../router/routes';
import styles from './PaymentsView.css';

const PaymentsView = ({ quotesetId, consumerInfo, payments, getPayments, loading, error }) => {
  let { quotesetId: qsId } = useParams();

  useEffect(() => {
    // If the id from the URL path doesn't match the ID in state.
    if (qsId !== quotesetId) {
      getPayments(qsId);
    }
  }, []);

  const noData = !(consumerInfo && payments);

  const rows = [
    {
      label: 'Applicant Name',
      value: `${consumerInfo?.firstName} ${consumerInfo?.lastName}`
    },
    {
      label: 'Address',
      value: getAddressComposite(consumerInfo?.address)
    },
    {
      label: 'Coverage Type(s)',
      value: consumerInfo?.coverageTypes?.join('+')
    }
  ];

  return noData ? (
    <Card>
      <div>{loading ? `Loading...` : `No data to display`}</div>
      {error.message && <ErrorText>{error.message}</ErrorText>}
    </Card>
  ) : (
    <section className={styles.wrapper}>
      <Card className={styles.card}>
        <div className={styles.heading}>Customer Summary</div>
        <Table rows={rows} />
        <div className={styles.links}>
          <LinkText href={addIdToRoute(ROUTES.QUOTES_VIEW, qsId)} external>
            View Quoteset
          </LinkText>
        </div>
      </Card>
      {payments.map(payment => {
        return <PaymentCard key={payment.id} payment={payment} />;
      })}
    </section>
  );
};

PaymentsView.propTypes = {
  consumerInfo: PropTypes.shape({
    address: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    coverageTypes: PropTypes.array,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  error: PropTypes.object,
  getPayments: PropTypes.func,
  loading: PropTypes.bool,
  payments: PropTypes.array,
  quotesetId: PropTypes.string
};

export default PaymentsView;

import { FINALIZATION_TYPES } from 'woop-shared/quotes/enums';
import { calcBundledSavings, calcSavings, getCheapestQuote } from 'woop-shared/quotes/utils';
import { CARRIER_FIELDS, GENERAL_FIELDS, QUOTE_FIELDS, REPORT_FIELDS } from 'woop-shared/db/models';
import { getQuoteLabel } from '../../pages/CreateQuoteset/utils';

const quotesByType = (quotes, coverageType) =>
  quotes?.filter(
    q => q[QUOTE_FIELDS.COVERAGE_TYPE] === coverageType && !q[QUOTE_FIELDS.IS_CURRENT_POLICY]
  ) || [];

export const getBestQuote = (quotes, coverageType) =>
  getCheapestQuote(quotesByType(quotes, coverageType));

export const getSavings = (currentPolicies, bestQuotes, coverageTypes) => {
  if (!currentPolicies.length || !bestQuotes.length) return null;
  if (coverageTypes.length > 1)
    return calcBundledSavings(bestQuotes, currentPolicies, { allowMissingPolicy: true });
  return calcSavings(bestQuotes[0], currentPolicies[0]);
};

export const getType = (currentPolicies, savings) => {
  if (!currentPolicies.length) return FINALIZATION_TYPES.QUOTES_READY;
  if (savings) return FINALIZATION_TYPES.CAN_BEAT_PRICE;
  return FINALIZATION_TYPES.CANT_BEAT_PRICE;
};

export const getCarrierNames = (carrierCodes, carriers) => {
  return carrierCodes?.map(code => {
    const carrier = carriers.find(carrier => carrier[CARRIER_FIELDS.CODE] === code);
    return carrier[CARRIER_FIELDS.NAME];
  });
};

function hasFinalizedReport(reports) {
  return !!reports?.some(report => !!report?.[REPORT_FIELDS.FINALIZATION]);
}

export function getSendQuotesCopy(reports) {
  // If a report has been finalized already, we're going to send a quotes-ready email to the consumer.
  if (hasFinalizedReport(reports)) {
    return `These quotes will be sent to the customer via the portal.`;
  }

  // If no report has been finalized, the quotes will be sent with the report.
  return `Quotes will be sent when a report for this user is finalized.`;
}

export function createQuoteOption(quote, carriers) {
  return {
    value: quote[GENERAL_FIELDS.ID],
    label: getQuoteLabel(quote, carriers)
  };
}
